<template>
  <a-modal
    width="420px"
    v-model="show"
    title="上传音乐"
    destroyOnClose
    :maskClosable="false"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    class="submit-music"
  >
    <a-form :form="form">
      <div class="font-xs m-bottom-base">
        向曲库批量上传音乐，单次上线500首，请参照
        <a href="">入库规范</a> 进行操作：
      </div>
      <a-steps direction="vertical" size="small">
        <a-step status="process">
          <b slot="title">填写音乐字段表</b>
          <div class="description" slot="description">
            <span>请</span>&nbsp;
            <a :href="$config.SubmitMusicTemplateUrl" download>
              下载音乐信息模板
            </a>
            <span>，规范填写音乐信息</span>
          </div>
        </a-step>
        <a-step status="process">
          <b slot="title">填写曲库地址</b>
          <a-form-item slot="description" class="w-100">
            <a-input
              placeholder="请输入曲库地址"
              v-decorator="[
                'kodo_url',
                { rules: [$validate.required], trigger: 'blur' },
              ]"
            />
          </a-form-item>
        </a-step>
        <a-step status="process">
          <b slot="title">上传音乐字段表</b>
          <a-form-item slot="description">
            <a-upload
              :multiple="false"
              :file-list="fileList"
              :remove="handleRemove"
              :before-upload="beforeUpload"
              v-decorator="['file', { rules: [$validate.required] }]"
            >
              <a-button> <a-icon type="upload" /> 上传文件 </a-button>
            </a-upload>
          </a-form-item>
        </a-step>
      </a-steps>
    </a-form>
  </a-modal>
</template>

<script>
  import {Steps as ASteps, Upload as AUpload} from 'ant-design-vue';
  import {dialogMixins, tableMixins} from '@/mixins/index';

  export default {
    data() {
      return {
        show: false,
        confirmLoading: false,
        form: this.$form.createForm(this, {name: ''}),
        fileList: []
      }
    },

    props: {
      task_id: String
    },

    components: {
      ASteps, AStep: ASteps.Step, AUpload
    },

    watch: {
      show(newVal) {
        if (newVal) {
          this.fileList = []
        }
      }
    },

    mixins: [dialogMixins, tableMixins],

    methods: {

      beforeUpload(file) {
        this.fileList = [file];
        return false;
      },

      handleRemove() {
        this.fileList = [];
        this.form.setFieldsValue({file: {}});
      },

      handleOk() {
        this.form.validateFieldsAndScroll((errors, values) => {

          if (!errors) {
            const {fileList} = this;
            const formData = new FormData();

            formData.append('file', fileList[0]);
            formData.append('kodo_url', values.kodo_url);
            formData.append('task_id', this.task_id);

            this.confirmLoading = true;

            this.$axios.EnterlibraryTask_importMusic(formData).then(res => {
              const data = res.data;

              if (data && data.code == 0) {
                let {errors} = data.data;

                if (errors && errors.length > 0) {
                  this.errorsTips(errors);
                } else {
                  this.$message.success('上传成功');

                  this.$emit('update:visible', false);

                  this.$emit('success');
                }
              }

              this.confirmLoading = false;
            }).catch(() => {
              this.confirmLoading = false;
            })

          }
        });
      },

      errorsTips(errors) {
        const h = this.$createElement;

        this.$warning({
          title: '上传失败',
          content: h('div', {}, errors.map(item => h('div', item)))
          // content: (
          //   errors.map(item => (<div>{item}</div>))
          // ),
        })
      },

      // getData() {
      //   let params = {batch_id: this.batch_id};

      //   this.$axios.Enterlibrary_storeProgress(params).then(res => {
      //     const data = res.data;

      //     if (data && data.code == 0) {
      //       // something
      //     }
      //   })
      // },
    }
  }
</script>

<style lang="scss" scoped>
  .submit-music {
    /deep/ {
      .ant-steps-item-description {
        padding-bottom: 0;
      }

      .description {
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }
  }
</style>
