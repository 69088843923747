<template>
  <a-result status="success" title="恭喜！曲库标注任务完成！" sub-title>
    <div class="detail-box">
      <a-descriptions title :column="2">
        <a-descriptions-item label="曲库名称" :span="2">{{data.lib_name}}</a-descriptions-item>

        <a-descriptions-item label="任务数量">{{data.music_num}}</a-descriptions-item>
        <a-descriptions-item>
          <span slot="label" class="font-brown">上报难例</span>
          <span class="font-brown">{{data.hardcase_num || 0}} 首</span>
        </a-descriptions-item>

        <a-descriptions-item label="任务开始时间">{{data.start_time}}</a-descriptions-item>
        <a-descriptions-item>
          <span slot="label" class="font-black-brown">任务被驳回</span>
          <span class="font-black-brown">{{data.undo_num || 0}} 首</span>
        </a-descriptions-item>

        <a-descriptions-item label="任务结束时间">{{data.end_time}}</a-descriptions-item>
        <a-descriptions-item>
          <span slot="label" class="font-black-brown">驳回任务处理</span>
          <span class="font-black-brown">{{data.undo_dealt_num || 0}} 首</span>
        </a-descriptions-item>

        <a-descriptions-item label="任务用时" :span="2">{{data.use_days || 0}} 天</a-descriptions-item>
      </a-descriptions>
    </div>

    <template #extra>
      <router-link to="/librarymark/task/serving" class="ant-btn" key="back">返回任务列表</router-link>
      <router-link
        class="ant-btn"
        key="look"
        :to="`/librarymark/musicmanage/${task_id}?from=${$var.Common.first}`"
      >查看任务</router-link>
      <a-button key="buy" type="primary" @click="goDeliever">交付任务</a-button>
    </template>
  </a-result>
</template>

<script>
  import {Result as AResult, Descriptions as ADescriptions} from 'ant-design-vue';

  export default {
    data() {
      return {
      }
    },

    props: {
      task_id: String,
      type: String,
      data: Object,
    },

    created() {
    },

    components: {
      AResult, ADescriptions, ADescriptionsItem: ADescriptions.Item
    },
    methods: {

      goDeliever() {
        let vm = this;

        this.$confirm({
          width: '480px',
          title: '确认要交付这个任务吗？',
          content: '任务交付后不可撤回，请谨慎操作！',
          onOk() {
            return new Promise((resolve, reject) => {
              vm.deliever(resolve, reject);
            });
          }
        });
      },

      deliever(next, reject) {
        this.$axios.MarklibraryTask_pushTask({task_id: this.task_id}).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('交付成功');
            this.$router.push('/librarymark/task/serving');
            next();
          } else {
            reject();
          }
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .detail-box {
    display: flex;
    justify-content: center;
    width: 500px;
    height: 200px;
    margin: 0 auto;

    .font-brown {
      color: #f7b500;
    }

    .font-black-brown {
      color: #ff4d4f;
    }
  }
</style>
