<template>
  <div class="marked-tool">
    <a-button
      class="tool-btn m-right-base w-px-100 ant-btn-self"
      type="warning"
      size="large"
      @click="isUncertain = true"
      :class="{'ant-btn-disabled': isUncertain}"
    >{{isUncertain ? '已上报' : '上报难例'}}</a-button>

    <template v-if="!isEdit">
      <a-button
        class="tool-btn m-right-base w-px-100 ant-btn-self"
        type="danger"
        size="large"
        :loading="loading && params.status === '0'"
        @click="goReject"
        :class="{'ant-btn-disabled': isEdit && handleInfo.status == '0'}"
      >拒绝</a-button>

      <a-button
        class="tool-btn m-right-base w-px-100 ant-btn-self"
        type="primary"
        size="large"
        :loading="loading && params.status === '1'"
        @click="submit"
        :class="{'ant-btn-disabled': isEdit && handleInfo.status == '1'}"
      >提交</a-button>
    </template>
    <template v-else>
      <a-popconfirm title="确定要更改标注结果吗" ok-text="确定" cancel-text="取消" @confirm="goReject">
        <a-button
          class="tool-btn m-right-base w-px-100 ant-btn-self"
          type="danger"
          size="large"
          :loading="loading && params.status === '0'"
          :class="{'ant-btn-disabled': isEdit && (handleInfo.status == '0' || submitStatus == SUBMIT_STATUS.REJECT)}"
        >拒绝</a-button>
      </a-popconfirm>

      <a-popconfirm title="确定要更改标注结果吗" ok-text="确定" cancel-text="取消" @confirm="submit">
        <a-button
          class="tool-btn m-right-base w-px-100 ant-btn-self"
          type="primary"
          size="large"
          :loading="loading && params.status === '1'"
          :class="{'ant-btn-disabled': isEdit && (handleInfo.status == '1' || submitStatus == SUBMIT_STATUS.AGREE)}"
        >通过</a-button>
      </a-popconfirm>
    </template>

    <dialog-reject :visible.sync="showDialog" :submit="onReject"></dialog-reject>
  </div>
</template>

<script>
  import {Popconfirm as APopconfirm} from 'ant-design-vue';
  import DialogReject from '../dialog/DialogReject.vue';

  const SUBMIT_STATUS = {
    AGREE: '1',
    REJECT: '0'
  }

  export default {
    data() {
      return {
        isUncertain: false,
        loading: false,
        params: {},
        showDialog: false,
        submitStatus: '',
        SUBMIT_STATUS
      }
    },

    props: {
      list: Array,
      music_id: String,
      task_id: String,
      type: String,
      typeList: {
        type: Array,
        default() {
          return []
        }
      },
      // 存放编辑时 难例 状态初始化数据
      handleInfo: Object,
      isEdit: Boolean
    },

    computed: {
      // 驳回任务
      isReject() {
        return this.task_id === this.$var.Handle.reject
      },

      // 即时任务
      isImme() {
        return this.task_id === this.$var.Handle.imme
      },

      // 音乐管理
      isMusicManage() {
        return this.task_id === 'musicmanage';
      },

      request() {
        if (this.isReject) return this.$axios.MarkMusic_addMark_reject;
        else if (this.isImme) return this.$axios.MarkMusic_addMark_imme;
        else if (this.isMusicManage) return this.$axios.MusicManage_changeTags;
        else if (this.isEdit) return this.$axios.MarkMusic_editMark;
        else return this.$axios.MarkMusic_addMark;
      },

      requestParams() {
        if (this.isImme) return {music_id: this.music_id};
        else if (this.isMusicManage) return {music_id: this.$route.query.music_id};
        else if (this.isEdit || this.isReject) return {log_id: this.handleInfo.log_id};
        else return {music_id: this.music_id, task_id: this.task_id};
      }
    },

    watch: {
      handleInfo: {
        handler(newVal) {
          this.isUncertain = !!parseInt(newVal.is_hardcase);
        },
        immediate: true
      }
    },

    components: {
      DialogReject, APopconfirm
    },

    methods: {
      goReject() {
        this.showDialog = true;
      },

      onReject(reason, loading) {
        loading(true);

        this.reject(reason).then(() => {
          loading(false);
          this.showDialog = false;
        })
      },

      submit() {
        let params = {};

        params.status = SUBMIT_STATUS.AGREE;
        params.label_ids = this.list.flat(2).join(',') || '';

        if (!this.checkRequest(this.list)) return false;

        this.requestData(params).then((data) => {
          if (data && data.code == 0) {
            this.$message.success('成功提交！已为您加载新的音乐标注任务！');
            this.$emit('success', data)
          }
        })
      },

      reject(reason) {
        let params = {};

        params.status = SUBMIT_STATUS.REJECT;
        params.reject_reason = reason || '';

        return new Promise(resolve => {
          this.requestData(params).then((data) => {
            if (data && data.code == 0) {
              this.$message.success('成功拒绝！已为您加载新的音乐标注任务！');
              this.$emit('success', data)
            }

            resolve();
          })
        })
      },

      checkRequest(list) {
        if (!list || !list.flat(2).length) {
          this.$message.error('请选择标签');
          return false;
        }
        if (this.type != "3"){
          let res = list.some((item, index) => {
            if (!item || !item.length) {
              this.$message.error(`请标注${this.typeList[index] || ''}类别`);
              return true;
            }
          })

          if (res) return false;
        }
        

        return true;
      },

      requestData(params) {
        params.is_hardcase = this.isUncertain ? '1' : '0';

        params = Object.assign(params, this.requestParams);

        this.params = params;

        this.loading = true;

        return new Promise(resolve => {

          if (typeof this.request === 'function') {
            this.request(params).then(res => {
              const data = res.data;

              resolve(data);

              this.loading = false;
            })
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
