<template>
  <ng-table
    class="ng-table"
    :columns="columns"
    :params="params"
    :rowKey="row => row.apply_id"
    :request="$axios.Drawing_List"
    :load.sync="loadTable"
    :reload.sync="reloadTable"
    :scroll="{y: scrollyHeight - 20}"
  >
    <template slot="name" slot-scope="text, scope">
      <router-link
        class="word-break order-no"
        :to="`/order/${scope.order_type === $val.orderType.music ? 'channel' : 'package'}/${scope.order_id || '-1'}?from=${$route.name}`"
      >{{text}}</router-link>
    </template>

    <template slot="operation" slot-scope="text, scope">
      <a-button
        v-if="$root.$checkAuth($auth[authName] && $auth[authName].look)"
        class="m-right-ms"
        type="link"
        @click="$emit('detail', scope)"
      >详情</a-button>
    </template>
  </ng-table>
</template>

<script>
  import {tableMixins} from '@/mixins';
  import * as TabCol from '@/config/columns';

  const columns = [
    TabCol.colId,
    TabCol.commonKey('提现记录ID', 'apply_id'),
    TabCol.commonKey('申请人', 'apply_name'),
    TabCol.commonKey('提现状态', 'apply_status_str'),
    TabCol.commonKey('支付类型', 'apply_type_str'),
    TabCol.commonKey('申请时间', 'apply_time'),
    TabCol.commonKey('审核时间', 'audit_time'),
    TabCol.commonKey('转账时间', 'transfer_time'),
    TabCol.commonKey('提现金额', 'apply_amount'),
    TabCol.operation('80px'),
  ];

  export default {
    data() {
      return {
        columns,
        params: {},
      }
    },

    props: {
      authName: String,
      scrollyHeight: {
        type: Number,
        default: 400
      },
      reload: Boolean,
      tableParams: Object,
    },

    watch: {
      tableParams(newVal) {
        this.$nextTick(() => {
          this.params = Object.assign({}, this.params, newVal || {});
        })
      }
    },

    mounted() {
    },

    mixins: [
      tableMixins
    ],

    methods: {
    }
  }
</script>

<style lang="scss" scoped>
</style>
