<template>
  <ng-table
    class="ng-table"
    :columns="columns"
    :params="params"
    :rowKey="row => row.order_detail_id"
    :request="$axios.Earnings_List"
    :load.sync="loadTable"
    :reload.sync="reloadTable"
    :scroll="{y: scrollyHeight - 20}"
  >
  <template slot="operation" slot-scope="text, scope">
    <a-button
      v-if="$root.$checkAuth($auth[authName] && $auth[authName].look)"
      class="m-right-ms"
      type="link"
      @click="onInfoClick($var.Handle.look, scope)"
    >查看详情</a-button>
  </template>
  </ng-table>
</template>

<script>
  import {tableMixins} from '@/mixins';
  import * as TabCol from '@/config/columns';

  const columns = [
    TabCol.colId,
    TabCol.commonKey('收益ID', 'earnings_id'),
    TabCol.commonKey('产生时间', 'create_at'),
    TabCol.commonKey('音乐名称', 'music_name'),
    TabCol.commonKey('供应商ID', 'scm_user_id'),
    TabCol.commonKey('供应商名称', 'scm_name'),
    TabCol.commonKey('收益类型', 'earnings_type_str'),
    TabCol.commonKey('授权场景&使用方式', 'use_mode'),
    TabCol.commonKey('客户名称', 'main'),
    TabCol.commonKey('售卖价格', 'sell_amount'),
    TabCol.commonKey('音乐人收益', 'earnings_amount'),
    TabCol.operation('100px'),
  ];

  export default {
    data() {
      return {
        columns,
        params: {},
      }
    },

    props: {
      authName: String,
      scrollyHeight: {
        type: Number,
        default: 400
      },
      reload: Boolean,
      tableParams: Object,
    },

    watch: {
      tableParams(newVal) {
        this.$nextTick(() => {
          this.params = Object.assign({}, this.params, newVal || {});
        })
      }
    },

    mounted() {
    },

    mixins: [
      tableMixins
    ],

    methods: {
      onInfoClick(key, scope) {
        this.$emit('handle', Object.assign(scope, {
          handleName: key
        }))
      },

    }
  }
</script>
<style lang="scss" scoped>
</style>
