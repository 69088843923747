<template>
  <div>
    <good-base-info ref="coopBaseInfo" :goodInfo="goodInfo"></good-base-info>

    <a-divider>商品权益</a-divider>

    <good-auth-info ref="coopAuthInfo"></good-auth-info>
  </div>
</template>

<script>
  import GoodAuthInfo from './GoodAuthInfo.vue';
  import GoodBaseInfo from './GoodBaseInfo.vue';

  export default {
    data() {
      return {

      }
    },

    props: {
      goodInfo: Object,
    },

    components: {
      GoodAuthInfo, GoodBaseInfo
    },

    methods: {

      setDefaultValue(data) {
        let coopBaseInfo = this.$refs.coopBaseInfo ? this.$refs.coopBaseInfo : null;
        let coopAuthInfo = this.$refs.coopAuthInfo ? this.$refs.coopAuthInfo : null;

        data = {...data};

        this.setSingleDefaultValue(coopBaseInfo, {
          name: data.name,
          desc: data.desc,
          // auth_time: data.auth_time,
          // auth_area: data.auth_area,
        });

        this.setSingleDefaultValue(coopAuthInfo, {
          type: data.type,
          valid_time: data.valid_time,
          auth_num: data.auth_num,
          download_num: data.download_num,
          discount_ratio: data.discount_ratio / 100
        });

        this.$nextTick(() => {
          this.setSingleDefaultValue(coopBaseInfo, {
            auth_time: data.auth_time,
            auth_area: data.auth_area,
            auth_scene: data.auth_scene,
          }, false);

          this.$nextTick(() => {
            this.setSingleDefaultValue(coopBaseInfo, {
              use_mode: data.use_mode,
            }, false);
          })
        })

        if (data.auth_scene && data.auth_scene.length > 0) {
          coopBaseInfo.authSceneDefaultValue = data.auth_scene
        }
      },

      setSingleDefaultValue(ref, value, bool = true) {
        if (ref) {
          let form = ref ? ref.form : null;

          if (form) {
            bool && form.resetFields();

            form.setFieldsValue(value);
          }
        }
      },

      checkCoopBase() {
        return new Promise(resolve => {
          this.$refs.coopBaseInfo.form.validateFieldsAndScroll((errors, values) => {

            // values.auth_scene = Object.values(values.auth_scene);

            console.log(values);

            if (!errors) {
              // this.$emit('change', values);
              resolve(values);
            }
          })
        })
      },

      checkCoopAuth() {
        return new Promise(resolve => {
          this.$refs.coopAuthInfo.form.validateFieldsAndScroll((errors, values) => {

            console.log(values);

            if (!errors) {
              // this.$emit('change', values);
              resolve(values);
            }
          })
        })
      },

      handleOk() {
        return new Promise(resolve => {
          Promise.all([this.checkCoopBase(), this.checkCoopAuth()]).then(res => {
            let value = res.reduce((a, b) => {
              return Object.assign(a, b);
            }, {})

            resolve(value);
          })
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
