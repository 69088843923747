<template>
  <main-structure class="ng-verify-detail ng-detail" dir="ver" :loading="loading">
    <template slot="header">
      <ng-breadcrumb :data="breadData"></ng-breadcrumb>
    </template>
    <div class="info-content">
      <ng-detail title="基础信息">
        <div class="flex order-info">
          <ul class="flex-1">
            <li>订单编号：{{orderInfo.order_no}}</li>
            <li>订单渠道：{{orderInfo.channel | toChannel}}</li>
            <li>下单时间：{{orderInfo.create_at}}</li>
            <li>付款方式：{{orderInfo.pay_way | toPayWay}}</li>
            <li>订单状态：{{orderInfo.status | toOrderStatus}}</li>
            <li>订单金额：￥{{orderInfo.order_amount}}</li>
            <li>实付金额：￥{{orderInfo.pay_amount}}</li>
            <li>优惠金额：￥{{orderInfo.discount_amount}}</li>
          </ul>
          <ul class="flex-1">
            <li>用户名：{{orderInfo.user_name}}</li>
            <li>用户ID：{{orderInfo.user_id}}</li>

            <a-button
              v-if="orderInfo.status === $val.UndoPayOrderType.paying"
              type="primary"
              @click="onSettelOrder"
            >线下结单</a-button>
          </ul>
        </div>
      </ng-detail>

      <ng-detail class="m-top-base" title="商品信息">
        <channel-order-detail-list :status="orderInfo.status" :list="orderInfo.detail"></channel-order-detail-list>
      </ng-detail>
    </div>

    <dialog-settle-order
      :visible.sync="showDialog"
      :order_id="orderInfo.order_id"
      :order_type="$val.orderType.music"
      @success="onSuccess"
    ></dialog-settle-order>
  </main-structure>
</template>

<script>
  import {NgBreadcrumb} from '@/components/index';
  import {OrderStatusOptionsAdmin, OrderChannelOptions} from '@/config/options'
  import {toOptionName} from '@/utils/function';
  import ChannelOrderDetailList from './detail/ChannelOrderDetailList.vue'
  import DialogSettleOrder from './package/DialogSettleOrder.vue'

  const PathNameDir = {
    buyContract: {
      path: '/buy/contract',
      name: '合同管理'
    },
    buyReceipt: {
      path: '/buy/receipt',
      name: '发票管理'
    }
  }

  const breadData = ($route) => {
    let query = $route.query;
    let from = query.from;
    let cur = PathNameDir[from];

    return [
      {breadcrumbName: cur ? cur.name : `渠道订单`, path: cur ? cur.path : `/order/channel`},
      {breadcrumbName: `订单详情`}
    ]
  }

  export default {
    data() {
      return {
        name: `channelorder_info`,
        loading: false,
        breadData: breadData(this.$route),
        orderInfo: {},
        showDialog: false
      }
    },
    components: {
      NgBreadcrumb, ChannelOrderDetailList,
      DialogSettleOrder
    },

    created() {
      this.getInfo(this.$route.params.order_id)
    },

    filters: {
      toOrderStatus(value) {
        return toOptionName(OrderStatusOptionsAdmin, value);
      },
      toChannel(value) {
        return toOptionName(OrderChannelOptions, value);
      }
    },

    methods: {
      getInfo(order_id) {
        let params = {order_id};

        this.$axios.OrderManage_info(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.orderInfo = data.data;
          }
        })
      },

      onSettelOrder() {
        this.showDialog = true;
      },

      handleFunc() {

      },

      onSuccess() {
        this.getInfo(this.$route.params.order_id)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .info-content {
    padding: 20px;
  }

  .order-info {
    line-height: 3;
  }
</style>
