<template>
  <a-card title="标签类别" size="small" class="tags-type h-100 tag-card">
    <svg
      v-if="$root.$checkAuth($auth[authName] && $auth[authName].addCate)"
      class="iconfont extra-icon"
      slot="extra"
      @click="onClickExtra"
    >
      <use xlink:href="#icontianjiabiaoqian" />
    </svg>

    <div class="content main-card-content">
      <ul class="list">
        <template v-for="item in typeData">
          <li
            :class="['list-item', selectedKey === item.cate_id ? 'active' : '']"
            :key="item.cate_id"
            :data-key="item.cate_id"
            @click="(event) => onClickItem(event, item)"
          >
            <span class="m-right-xs">{{ item.name }}</span>

            <a-icon
              v-if="item.child && item.child.length > 0"
              class="arrow-icon m-right-xs"
              slot="icon"
              type="caret-left"
            />

            <template v-if="item.amount > 0 && searchValue">
              <a-badge
                :count="item.amount"
                :number-style="{
                  backgroundColor: '#fadb14',
                }"
              />
            </template>
          </li>
        </template>
      </ul>
    </div>
  </a-card>
</template>

<script>

  export default {
    inject: ['authName'],
    data() {
      return {
        // selectedKey: '',
        typeData: [],
      }
    },

    props: {
      data: {
        type: Array,
        default() {
          return []
        }
      },
      searchValue: String,
      selectedKey: String
    },

    watch: {
      searchValue(newVal) {
        this.typeData = this.setSearchData(this.data, newVal, 'child', 'name');
      },

      data: {
        handler(newVal) {
          this.typeData = newVal;
        },
        immediate: true
      }
    },

    components: {
    },

    methods: {
      clearData() {
        this.$emit('update:selectedKey', '');
        this.selectedKey = [];
      },

      onClickExtra() {
        this.$emit('clickExtra', {
          key: 'type'
        });
      },

      // handleSuccess() {

      // },

      setSearchData(data, value, childrenName = 'children', textName = 'title') {
        let amount = 0;

        return data.map(item => {
          amount = 0;

          calcOne(item[childrenName], value);

          return Object.assign({}, item, {
            amount
          })
        })

        function calcOne(data, value) {
          return data.forEach(item => {
            let children = item[childrenName], text = item[textName];

            if (children && children.length > 0) {
              calcOne(children, value)
            }

            if (~text.indexOf(value)) {
              amount++;
            }
          })
        }
      },

      // onSelect(value) {
      //   this.selectedKey = value[0];
      // },

      onClickItem(event, item) {
        const currentTarget = event.currentTarget, dataset = currentTarget.dataset, key = dataset.key;

        if (key) {
          // this.selectedKey = key;

          this.$emit('update:selectedKey', key);

          this.$emit('change', item);
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .tags-type {
    .extra-icon {
      position: absolute;
      top: 6px;
      right: 8px;
    }

    .list {
      .list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 50px;
        padding: 0 40px;
        cursor: pointer;

        .arrow-icon {
          transform: rotate(0);
        }

        &.active {
          color: #fff;
          background-color: #0061f3;

          .arrow-icon {
            transform: rotate(-180deg);
            transition: transform linear 0.2s;
          }
        }
      }
    }
  }
</style>
