<template>
  <div>
    <ng-table
      class="ng-table"
      :columns="columns"
      :params="params"
      :rowKey="row => row.batch_id"
      :request="$axios.Marklibrary_waitServeList"
      :load.sync="loadTable"
      :reload.sync="reloadTable"
      :scroll="{y: scrollyHeight - 20}"
    >
      <!-- 头像 -->
      <template slot="lib_logo_url" slot-scope="text, scope">
        <ng-image
          class="table-header"
          :src="scope.lib_logo_url ? `${scope.lib_logo_url}?x-oss-process=style/thumb_10` : $var.defaultHeader"
          @error="({target}) => target.src = $var.defaultHeader"
        />
      </template>

      <template slot="number" slot-scope="text">
        <span>{{`批次${text}`}}</span>
      </template>

      <template slot="priority" slot-scope="text">
        <span v-html="$options.filters.toLevelName(text)"></span>
      </template>

      <template slot="operation" slot-scope="text, scope">
        <a-button
          v-if="$root.$checkAuth($auth[authName] && $auth[authName].direction)"
          class="m-right-ms"
          type="link"
          @click="goDeliever(scope)"
          :disabled="!scope.op_assign"
        >指派任务</a-button>
      </template>
    </ng-table>

    <dialog-direct-member
      :visible.sync="showDialog"
      @success="handleSuccess"
      :default-value="handleData"
      :batch_id="handleData.batch_id"
    ></dialog-direct-member>
  </div>
</template>

<script>
  import {tableMixins} from '@/mixins';
  import DialogDirectMember from './DialogDirectMember.vue';

  import * as TabCol from '@/config/columns';

  const columns = [
    TabCol.colId,
    TabCol.portrait('封面', 'lib_logo_url'),
    TabCol.commonKey('曲库名称', 'lib_name', {ellipsis: true}),
    TabCol.commonKey('入库批次', 'number', '', true),
    TabCol.commonKey('音乐数量', 'music_num'),
    TabCol.commonKey('可指派数量', 'unassign_music_num'),
    TabCol.commonKey('优先级', 'priority', '', true),
    TabCol.operation('100px')
  ]

  export default {
    data() {
      return {
        columns,
        loading: false,
        // params: {},
        showDialog: false,
        handleData: {}
      }
    },

    props: {
      authName: String,
      scrollyHeight: {
        type: Number,
        default: 400
      },
      params:Object
    },

    mounted() {
      // this.params = {page: this.$route.query.page || 1};
    },

    components: {
      DialogDirectMember
    },

    mixins: [
      tableMixins
    ],

    methods: {

      handleSuccess() {
        this.showDialog = false;
        this.loadTableData();
      },

      goDeliever(scope) {
        this.showDialog = true;
        this.handleData = scope;
      },
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .ant-table td {
    white-space: nowrap;
  }
</style>
