<template>
  <a-spin :spinning="loading">
    <a-row :gutter="16">
      <a-col class="text-center" :span="8">
        <a-statistic title="待审核提现" :value="drawingInfo.wait_audit_count || 0" />
      </a-col>
      <a-col class="text-center" :span="8">
        <a-statistic title="待转账提现" :value="drawingInfo.wait_transfer_count || 0" />
      </a-col>
      <a-col class="text-center" :span="8">
        <a-statistic title="本月提现" :value="drawingInfo.month_success_count || 0" />
      </a-col>
    </a-row>
  </a-spin>
</template>

<script>
  import {Statistic} from 'ant-design-vue'
  export default {
    data() {
      return {
        drawingInfo: {},
        loading: false,
      }
    },

    components: {
      AStatistic: Statistic
    },

    created() {
      this.getData()
    },

    methods: {
      getData() {
        let params = {};

        this.loading = true;

        this.$axios.Drawing_Static(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            // something
            this.drawingInfo = data.data ? data.data : {};
          }

          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
