<template>
  <a-modal
    :title="title"
    :width="$config.DialogWidth"
    v-model="show"
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form
      :form="form"
      layout="horizontal"
      :wrapper-col="{ xs: {span: 20, offset: 2}}"
      class="ng-form m-top-md"
    >
      <a-row>
        <a-col :span="20" :offset="2">
          <p class="font-grey font-xs">{{titleContent}}</p>
        </a-col>
      </a-row>

      <a-form-item class="m-bottom-0">
        <a-form-item style="display: inline-block; width: calc(100% - 180px); margin-right: 20px">
          <a-input
            placeholder="请输入验证码"
            v-decorator="['vfcode', { rules: [$validate.required], initialValue: '', trigger: 'blur' }]"
          />
          <span class="success-tip font-grey" v-if="showSuccessTip">{{sendSuccessTips}}</span>
        </a-form-item>

        <a-form-item :style="{display: 'inline-block', width: '160px'}">
          <form-send-code-button
            type="sub"
            :url-func="$axios.SendSelfCode"
            @click="onClickSend"
            @sendSuccess="onSendSuccess"
            @countDownFinished="onCountDownFinished"
          ></form-send-code-button>
        </a-form-item>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  // import {FormPhoneArea} from '@/components/index';
  import {FormSendCodeButton} from '@/components/index';

  import {omitAccount} from '@/utils/function';

  export default {
    data() {
      return {
        show: false,

        title: '身份验证',

        confirmLoading: false,

        // identifyCodeParam: {},

        showSuccessTip: false,
      }
    },

    props: {
      // phone email
      type: {
        type: String,
        default: 'phone'
      },

      number: String,
      data: {
        type: Object,
        default() {
          return {}
        }
      },
    },

    computed: {

      titleContent() {
        let dirFunc = (type, number = '') => {
          number = omitAccount(number.toString());

          let dir = {
            phone: `为了你的帐户安全，我们将向手机号 ${number || '**'} 发送验证码，验证成功可进行下一步。`,
            email: `为了你的帐户安全，我们将向邮箱 ${number || '**'} 发送验证码，验证成功可进行下一步。`
          };

          return dir[type];
        }

        return dirFunc(this.type || 'phone', this.number);
      },

      sendSuccessTips() {
        let dir = {
          phone: '已发送短信验证码到绑定手机',
          email: '已发送验证码到绑定邮箱'
        }

        return dir[this.type || 'phone'];
      },

      codeType() {
        return this.type === 'email' ? '2' : '1';
      }
    },

    mixins: [dialogMixins],

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        name: 'linkman'
      })
    },

    components: {
      FormSendCodeButton
    },

    methods: {

      onClickSend(next) {
        let param = {type: this.codeType};

        next(param);
      },

      onSendSuccess(data) {
        if (data && data.code == 0) {
          this.showSuccessTip = true;
        }
      },

      onCountDownFinished() {
        this.showSuccessTip = false;
      },

      handleOk() {
        this.form.validateFieldsAndScroll((errors, values) => {

          if (!errors) {
            let param = Object.assign(values, {
              type: this.codeType
            })

            this.confirmLoading = true;

            this.$axios.VerifySelfCode(param).then(res => {
              const data = res.data;

              if (data && data.code == 0) {
                this.$emit('change', values);
                this.$emit('update:visible', false);
              }

              this.confirmLoading = false;
            })
          }
        })
      },

      handleCancel() {

      },
    }
  }
</script>

<style lang="scss" scoped>
  .success-tip {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -56px;
  }
</style>
