 import {
   Axios
 } from '@/utils/from-common';

 // 提现记录
 export const Drawing_List = (param) => Axios('POST', '/bms/withdraw/applyList', param);

 // 提现 统计
 export const Drawing_Static = (param) => Axios('GET', '/bms/withdraw/applyCount', param);

 // 提现 详情
 export const Drawing_Info = (param) => Axios('POST', '/bms/withdraw/applyInfo', param);

 // 提现 审核
 export const Drawing_Audit = (param) => Axios('POST', '/bms/withdraw/applyAudit', param);

 // 提现 确认转账
 export const Drawing_Transfer = (param) => Axios('POST', '/bms/withdraw/applyTransfer', param);