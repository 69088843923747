<template>
  <a-form class="ng-form-style ng-filter" :form="form" layout="inline">
    <a-form-item label="申请状态">
      <a-select
        :options="ApplyOptions"
        v-decorator="['is_operated', {initialValue: defaultData.is_operated || ''}]"
        :style="{width: '120px'}"
      ></a-select>
    </a-form-item>

    <a-form-item label="发票类型">
      <a-select
        :options="ReceiptTypeOptions"
        v-decorator="['invoice_type', {initialValue: defaultData.invoice_type || ''}]"
        :style="{width: '120px'}"
      ></a-select>
    </a-form-item>

    <a-form-item label="抬头类型">
      <a-select
        :options="ReceiptHeaderTypeOptions"
        v-decorator="['title_type', {initialValue: defaultData.title_type || ''}]"
        :style="{width: '120px'}"
      ></a-select>
    </a-form-item>

    <a-form-item label="申请时间">
      <a-range-picker
        class="w-100"
        valueFormat="YYYY-MM-DD"
        v-decorator="['time', { initialValue: defaultData.time}]"
        :style="{width: '240px'}"
      >
        <a-icon slot="suffixIcon" type="calendar" />
      </a-range-picker>
    </a-form-item>
  </a-form>
</template>

<script>
  import {DatePicker} from 'ant-design-vue';

  import {
    ApplyOptions,
    AddDefaultToOptions,
    ReceiptHeaderTypeOptions,
    ReceiptTypeOptions
  } from '@/config/options'

  export default {
    data() {
      return {
        ReceiptHeaderTypeOptions: AddDefaultToOptions(ReceiptHeaderTypeOptions, '全部'),
        ReceiptTypeOptions: AddDefaultToOptions(ReceiptTypeOptions, '全部'),
        ApplyOptions: AddDefaultToOptions(ApplyOptions, '全部'),
        labelList: [],
      }
    },

    props: {
      type: String,
      defaultData: {
        type: Object,
        default() {
          return {}
        }
      },
    },

    components: {
      ARangePicker: DatePicker.RangePicker,
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        onValuesChange: (props, values) => {
          values = Object.assign(values, {page: 1});

          this.$root.$goSearch(values);

          this.$emit('change', values);
        }
      });
    },

    methods: {
    }
  }
</script>

<style lang="scss" scoped>
  .ng-filter {
  }
</style>
