<template>
  <a-form class="w-100 ng-form" :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }">
    <!-- 授权地域 -->
    <a-form-item required class="form-item-lh-20" label="商品类型">
      <a-radio-group
        :options="GoodDefineTypeOptions"
        v-decorator="['type', { initialValue: $val.Good_define_type.common }]"
      ></a-radio-group>
    </a-form-item>

    <a-form-item label="有效期限">
      <a-input-number
        class="w-100"
        :min="0"
        placeholder="请输入商品有效期限，以天为单位"
        v-decorator="['valid_time', { rules: [$validate.required]}]"
      ></a-input-number>
    </a-form-item>

    <a-form-item label="商品权益">
      <a-input-number
        class="w-100"
        :min="0"
        placeholder="请输入授权次数，如无上限，请输入n"
        v-decorator="['auth_num', { rules: [$validate.required]}]"
      ></a-input-number>
    </a-form-item>

    <a-form-item label="下载权益">
      <a-input-number
        class="w-100"
        :min="0"
        placeholder="请输入下载次数"
        v-decorator="['download_num', { rules: [$validate.required]}]"
      ></a-input-number>
    </a-form-item>

    <a-form-item label="折扣系数">
      <a-input-number
        class="w-100"
        :min="0"
        :step="0.01"
        placeholder="请输入折扣系数"
        v-decorator="['discount_ratio', { rules: [$validate.required]}]"
      ></a-input-number>
    </a-form-item>
  </a-form>
</template>
<script>
  import {GoodDefineTypeOptions} from '@/config/options'

  export default {
    data() {
      return {
        form: this.$form.createForm(this, "nodify"),
        GoodDefineTypeOptions
      };
    },

    props: {
      defaultValue: Object,
      isLook: Boolean
    },

    created() {
      this.uploadParams = {
        upload_type: 1,
        field_name: this.uploadName
      }
    },

    methods: {
    }
  };
</script>
<style lang="scss" scoped>
  .ng-form-text {
    margin-left: 10px;
    font-size: 16px;
  }
</style>