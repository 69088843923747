const PriceManage = () => import('@/pages/goods/Goods.vue');
const PriceConfig = () => import('@/pages/goods/PriceConfig.vue');

export default [
  // 标注管理 start
  {
    path: '/goods/manage',
    name: 'goodsMange',
    component: PriceManage,
  },
  {
    path: '/goods/sellway',
    name: 'goodsSellWay',
    component: PriceConfig,
  },
]