<template>
  <ng-table
    class="ng-table"
    :columns="columns"
    :params="params"
    :rowKey="row => row.task_id"
    :request="$axios.MarklibraryTask_waitingList"
    :load.sync="loadTable"
    :reload.sync="reloadTable"
    :scroll="{y: scrollyHeight - 20}"
  >
    <!-- 头像 -->
    <template slot="lib_logo_url" slot-scope="text, scope">
      <ng-image
        class="table-header"
        :src="scope.lib_logo_url ? `${scope.lib_logo_url}?x-oss-process=style/thumb_10` : $var.defaultHeader"
        @error="({target}) => target.src = $var.defaultHeader"
      />
    </template>

    <template slot="number" slot-scope="text">
      <span>{{`批次${text}`}}</span>
    </template>

    <template slot="priority" slot-scope="text">
      <span v-html="$options.filters.toLevelName(text)"></span>
    </template>

    <template slot="operation" slot-scope="text, scope">
      <a-button
        v-if="$root.$checkAuth($auth[authName] && $auth[authName].getTask)"
        class="m-right-ms"
        type="link"
        @click="getTask(scope)"
        :disabled="!scope.op_claim"
      >开始标注</a-button>
    </template>
  </ng-table>
</template>

<script>
  import {tableMixins} from '@/mixins';

  import * as TabCol from '@/config/columns';

  const columns = [
    TabCol.colId,
    TabCol.portrait('封面', 'lib_logo_url'),
    TabCol.commonKey('曲库名称', 'lib_name', {ellipsis: true}),
    TabCol.commonKey('入库批次', 'number', '', true),
    TabCol.commonKey('音乐数量', 'music_num'),
    TabCol.commonKey('优先级', 'priority', '', true),
    TabCol.operation('100px')
  ]

  export default {
    data() {
      return {
        columns,
        loading: false,
        // params: {},
      }
    },

    props: {
      authName: String,
      scrollyHeight: {
        type: Number,
        default: 400
      },
      params:Object
    },

    mounted() {
      // this.params = {page: this.$route.query.page || 1};
    },

    mixins: [
      tableMixins
    ],

    methods: {
      getTask(scope) {
        let vm = this;

        this.$confirm({
          width: '480px',
          title: '确认要开始标注吗？',
          onOk() {
            return new Promise((resolve, reject) => {
              vm.confirmTask(scope, resolve, reject);
            });
          }
        });
      },

      confirmTask(scope, next, reject) {
        this.$axios.MarklibraryTask_getTask({task_id: scope.task_id}).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('标注任务已开始');
            next();

            setTimeout(() => {
              this.loadTableData();
            }, 500);
          } else {
            reject()
          }
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .ant-table td {
    white-space: nowrap;
  }
</style>
