<template>
  <a-form class="w-100 ng-form" :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }">
    <a-form-item label="曲库封面">
      <form-upload
        class="upload-box"
        accept="image/jpg, image/jpeg, image/png"
        list-type="picture-card"
        :action="$axios.UploadFile"
        :data="uploadParams"
        :name="uploadName"
        :remove-icon="true"
        @uploading="onUploading"
        :max-size="$config.UploadLicenseMaxSize"
        :init-header-url="profile_photo_url"
        v-decorator="['logo', { rules: [$validate.required] }]"
      ></form-upload>
      <template v-slot:extra>
        <span class="font-grey font-xs">仅支持 .jpg .png .jpeg .gif 格式，文件大小不超过 5M</span>
      </template>
    </a-form-item>

    <!-- 曲库名称 -->
    <a-form-item label="曲库名称">
      <ng-input
        placeholder="请输入曲库名称"
        :maxLength="50"
        show-counter
        size="large"
        v-decorator="['name', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur' }]"
      ></ng-input>
    </a-form-item>

    <!-- 曲库简称 -->
    <a-form-item label="曲库简称">
      <ng-input
        placeholder="请输入曲库简称~"
        :maxLength="30"
        show-counter
        size="large"
        v-decorator="['short_name', { initialValue: '', validateTrigger: 'blur' }]"
      ></ng-input>
    </a-form-item>

    <!-- 曲库简介 -->
    <a-form-item label="曲库简介">
      <ng-textarea
        placeholder="请输入曲库简介，有助于用户更好地了解音乐"
        style="height: 106px"
        :maxLength="300"
        show-counter
        size="large"
        v-decorator="['desc', { initialValue: '' }]"
      ></ng-textarea>
    </a-form-item>

    <!-- 曲库简介 -->
    <a-form-item v-if="!user_id" label="所属供应商">
      <form-suppliers
        show-search
        placeholder="请输入供应商名称以检索"
        size="large"
        v-decorator="['user_id', {rules: [$validate.required], initialValue: '' }]"
      ></form-suppliers>
    </a-form-item>

    <!-- 曲库简介 -->
    <a-form-item label="合作方案">
      <form-contracts
        v-if="user_id || !user_id && form.getFieldValue('user_id')"
        :user_id="user_id ? user_id : form.getFieldValue('user_id')"
        v-decorator="['contract_id', { rules: [$validate.required]}]"
      ></form-contracts>
      <span v-else class="font-grey">请选择供应商</span>
    </a-form-item>

    <!-- 音乐类型 -->
    <a-form-item label="音乐类型">
      <a-radio-group
        :options="allOption.MusicTypeOptions"
        :disabled="isEdit"
        v-decorator="['music_type', { rules: [$validate.required], initialValue: $root.getFirstAsDefault(allOption.MusicTypeOptions)  }]"
      ></a-radio-group>
    </a-form-item>
  </a-form>
</template>
<script>
  import {NgTextarea} from '@/components/index';
  import {FormUpload} from '@/components/index';
  import FormContracts from '@/components/form/FormContracts.vue'
  import FormSuppliers from '@/components/form/FormSuppliers.vue'
  import * as allOption from '@/config/options'

  export default {
    data() {
      return {
        form: this.$form.createForm(this, "nodify"),

        uploadParams: {},
        uploadName: 'file',
        uploading: false,

        profile_photo_url: '',

        allOption,
      };
    },

    props: {
      defaultValue: Object,
      user_id: [String, Number],
      isEdit: Boolean
    },

    components: {
      NgTextarea, FormUpload, FormContracts, FormSuppliers
    },

    created() {
      this.uploadParams = {
        upload_type: 1,
        field_name: this.uploadName
      }
    },

    methods: {
      onUploading(uploading) {
        this.uploading = uploading;
      },
    }
  };
</script>
<style lang="scss" scoped>
  .upload-box {
    display: inline;
    width: auto;

    /deep/ {
      .ant-upload {
        width: 80px;
        height: 80px;
        margin-bottom: 0;
        padding: 0;
        background-color: #f7f4f8;
      }
    }
  }
</style>