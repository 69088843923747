/**
 * 供应商管理
 */
import {
  Axios
} from '@/utils/from-common';

// 音乐人列表
export const Supplier_musicianList = (param) => Axios('POST', '/bms/supplier/musicianList', param);

// 音乐人详情
export const Supplier_musicianInfo = (param) => Axios('POST', '/bms/supplier/musicianInfo', param);

// 音乐人新增
export const Supplier_musicianAdd = (param) => Axios('POST', '/bms/supplier/addMusBaseInfo', param);

// 音乐人编辑
export const Supplier_musicianEdit = (param) => Axios('POST', '/bms/supplier/updateMusician', param);

// 音乐人启用/禁用
export const Supplier_musicianStatus = (param) => Axios('POST', '/bms/supplier/upMusicianStatus', param);

// 音乐人删除
export const Supplier_musicianDel = (param) => Axios('POST', '/bms/supplier/deleteMusician', param);

// 音乐人配置权限
export const Supplier_musicianAuth = (param) => Axios('POST', '/bms/supplier/setMusicianAccess', param);

// 音乐人国籍列表（成为供应商的）
export const Supplier_musicianAddress = (param) => Axios('POST', '/bms/supplier/musicianAddrs', param);



// 音乐团体列表
export const Supplier_teamList = (param) => Axios('POST', '/bms/supplier/teamList', param);

// 音乐团体详情
export const Supplier_teamInfo = (param) => Axios('POST', '/bms/supplier/teamInfo', param);

// 音乐团体新增
export const Supplier_teamAdd = (param) => Axios('POST', '/bms/supplier/addTeamBaseInfo', param);

// 音乐团体编辑
export const Supplier_teamEdit = (param) => Axios('POST', '/bms/supplier/updateTeam', param);

// 音乐团体启用/禁用
export const Supplier_teamStatus = (param) => Axios('POST', '/bms/supplier/upTeamStatus', param);

// 音乐团体删除
export const Supplier_teamDel = (param) => Axios('POST', '/bms/supplier/deleteTeam', param);

// 音乐团体配置权限
export const Supplier_teamAuth = (param) => Axios('POST', '/bms/supplier/setTeamAccess', param);

// 音乐团体籍列表（成为供应商的）
export const Supplier_teamAddress = (param) => Axios('POST', '/bms/supplier/teamAddrs', param);



// 音乐公司列表
export const Supplier_companyList = (param) => Axios('POST', '/bms/supplier/companyList', param);

// 音乐公司详情
export const Supplier_companyInfo = (param) => Axios('POST', '/bms/supplier/companyInfo', param);

// 音乐公司新增
export const Supplier_companyAdd = (param) => Axios('POST', '/bms/supplier/addCompBaseInfo', param);

// 音乐公司编辑
export const Supplier_companyEdit = (param) => Axios('POST', '/bms/supplier/updateCompany', param);

// 音乐公司启用/禁用
export const Supplier_companyStatus = (param) => Axios('POST', '/bms/supplier/upCompanyStatus', param);

// 音乐公司删除
export const Supplier_companyDel = (param) => Axios('POST', '/bms/supplier/deleteCompany', param);

// 音乐公司配置权限
export const Supplier_companyAuth = (param) => Axios('POST', '/bms/supplier/setCompanyAccess', param);

// 音乐公司籍列表（成为供应商的）
export const Supplier_companyAddress = (param) => Axios('POST', '/bms/supplier/companyAddrs', param);

//给音乐人发送邮件
export const send_invate_email = (param) => Axios('POST', '/bms/supplier/sendInvateMail', param);
