/**
 * 套餐
 */
import {
  Axios
} from '@/utils/from-common';

// 添加套餐
export const Package_add = (param) => Axios('POST', '/bms/vipgoods/add', param);

// 套餐列表 
export const Package_list = (param) => Axios('POST', '/bms/vipgoods/list', param);

// 用户套餐列表
export const Package_listByUser = (param) => Axios('POST', '/bms/vipgoods/selfList', param);

//获取用户套餐下载记录
export const Package_downloadRecord = (param) => Axios('POST', '/bms/vip/downloadRecord', param);

//获取用户套餐授权记录
export const Package_authRecord = (param) => Axios('POST', '/bms/vip/authRecord', param);

// 获取用户所有套餐下载记录
export const Package_all_downloadRecord = (param) => Axios('POST', '/bms/vip/downloadAllRecord', param);

// 套餐详情
export const Package_info = (param) => Axios('POST', '/bms/vipgoods/info', param);

