<template>
  <div class="h-100">
    <ng-table
      class="ng-table mark-music-table"
      childrenColumnName="child"
      :repeat-data="1"
      :columns="columns"
      :params="requestParams"
      :rowKey="row => row.log_id"
      :request="request"
      :load.sync="loadTable"
      :reload.sync="reloadTable"
      :transformData="(data) => onHandleDataFunc(data, 'log_id')"
      @dataChange="(data) => onTableDataChange(data, {name: 'music_name'})"
      :rowClassName="(r, i) => i % 2 ? 'even-row' : 'odd-row'"
      :scroll="{y: scrollyHeight - 20}"
    ></ng-table>
  </div>
</template>

<script>
  import TagsShow from '../components/TagsShow.vue';
  import {tableMixins} from '@/mixins';
  import {toOptionName} from '@/utils/function';
  import MusicTableMixins from '../musicTableMixins'
  import * as TabCol from '@/config/columns';
  import * as AllOptions from '@/config/options';
  import * as AllFunc from '@/utils/function';

  export default {
    data() {
      let {onClickMusic, onDiaprove} = this;
      let toPath = '', toQuery = {};
      let {id} = this.$route.params;
      // let  $root.$checkAuth($auth[authName] && $auth[authName].info

      const filterEle = (label, text, scope) => {
        let checkAuth = this.$root.$checkAuth;
        let auth = this.$auth[this.authName];
        let editMusic = checkAuth(auth && checkAuth.editMusic);
        let rejectMusic = checkAuth(auth && checkAuth.reject);

        switch (label) {
          case 'music_name':
            return <a href="javascript:;" title={text} onclick={() => {onClickMusic.call(this, scope)}}>{text}</a>
          case 'music_type':
            return <span>{toOptionName(AllOptions.MusicTypeOptions, text)}</span>
          case 'status':
            return <span>{toOptionName(AllOptions.MarkResultOptions, text)}</span>
          case 'operation':
            toPath = `/markmusic/${id}`;
            toQuery = {
              from: this.$var.Common.second,
              prevfrom: this.$route.query.from,
              log_id: scope.log_id,
              previd: id,
            };

            if (editMusic && rejectMusic) {
              return (
                <span>
                  <router-link class="m-right-ms" to={{path: toPath, query: toQuery}}>修改</router-link>
                  <a-button class="ant-btn-error" type="link" disabled={!scope.op_undo} onclick={() => {onDiaprove.call(this, scope)}}>驳回</a-button>
                </span >
              )
            } else if (!editMusic) {
              return <a-button class="ant-btn-error" type="link" disabled={!scope.op_undo} onclick={() => {onDiaprove.call(this, scope)}}>驳回</a-button>
            } else {
              return <router-link class="m-right-ms" to={{path: toPath, query: toQuery}}>修改</router-link>
            }
          default:
            return text;
        }
      }

      const renderContent = (value, row, index, item) => {
        const obj = {children: value, attrs: {}};

        (index % 2) && (obj.attrs.colSpan = 0);

        obj.children = filterEle(item.key, value, row)

        return obj;
      };

      const renderName = (text, scope) => {
        return <a href="javascript:;" title={text} onclick={() => {onClickMusic.call(this, scope)}}>{text}</a>
      }

      const tagshow = (data) => {
        if (data.status == '0') {
          let reason = AllFunc.getItemWithValue(AllOptions.MarkRejectReason, 'value', data.reject_reason, 'child');
          return <span class="font-red">拒绝原因：{reason ? reason.label : ''}</span>
        } else {
          return <TagsShow data={data.labels}></TagsShow>
        }
      }

      const columns = [
        {
          dataIndex: 'ID',
          width: 50,
          customRender(value, row, index) {
            let children = index % 2 ? '.' : (Math.floor(index / 2) + 1)
            return {children};
          },
        },
        {
          dataIndex: 'music_name',
          title: '音乐名称',
          customRender(value, row, index) {
            let colSpan = index % 2 ? 8 : 1;

            let children = index % 2 ? tagshow.call(this, row) : renderName(value, row);

            return {attrs: {colSpan}, children};
          },
        },
        TabCol.commonKey('音乐原名', 'music_original_name', {ellipsis: true, customRender: renderContent.bind(this)}),
        TabCol.commonKey('供应商', 'suplr_name', {ellipsis: true, customRender: renderContent.bind(this)}),
        TabCol.commonKey('音乐类型', 'music_type', {isSlot: true, customRender: renderContent.bind(this)}),
        TabCol.commonKey('标注人', 'charger_name', {isSlot: true, customRender: renderContent.bind(this)}),
        TabCol.commonKey('标注时间', 'create_at', {isSlot: true, customRender: renderContent.bind(this), width: '147px'}),
        TabCol.commonKey('标注结果', 'status', {isSlot: true, customRender: renderContent.bind(this)}),
        TabCol.commonKey('操作', 'operation', {isSlot: true, customRender: renderContent.bind(this), width: '100px'}),
      ]

      return {
        columns,
        requestParams: {},
        showDialog: false,
        currentData: {},
      }
    },

    props: {
      authName: String,
      scrollyHeight: {
        type: Number,
        default: 400
      },
      from: String,
      params: Object,
    },

    computed: {
      isByBatch() {
        return this.from === this.$var.Common.fourth
      },

      request() {
        return this.isByBatch ? this.$axios.MarkTask_musicListByBatch : this.$axios.MarkTask_musicListByTask
      },
    },

    watch: {
      params: {
        handler(newVal) {
          this.requestParams = Object.assign({}, newVal)
        },
      },
    },

    components: {
    },

    mounted() {
      // this.initParams();
    },

    mixins: [
      tableMixins, MusicTableMixins
    ],

    methods: {
      onClickMusic(scope) {
        let {music_url: url, music_name: name} = scope;

        this.$store.dispatch('onPlayGlobalVideo', Object.assign(scope, {url, name}));
      },

      onSuccess() {
        this.loadTableData();
      },

      onDiaprove(scope) {
        let vm = this;

        this.$confirm({
          width: '480px',
          title: '确认要驳回该音乐吗？',
          onOk() {
            return new Promise((resolve, reject) => {
              vm.deleteFunc(scope, resolve, reject);
            });
          }
        });
      },

      deleteFunc(scope, next, reject) {
        this.$axios.MarkMusic_rejectMarked({log_id: scope.log_id}).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('已成功驳回该音乐标注！');
            next();

            this.loadTableData();
          } else {
            reject()
          }
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .mark-music-table {
    /deep/ {
      .ant-table td {
        white-space: nowrap;
      }

      .ant-table-tbody {
        .ant-table-row {
          &:hover {
            > td {
              background: transparent;
            }
          }
        }
      }

      .odd-row {
        > td {
          border-bottom: 0;
        }
      }

      .even-row {
        > td {
          padding-top: 0;
          padding-bottom: 5px;
        }
      }
    }
  }
</style>
