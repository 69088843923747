<template>
  <a-form class="w-100 ng-form" :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
    <a-form-item class="m-bottom-0 line-height-inherit" required layout="inline" label="授权场景">
      <form-add
        add-is-icon
        icon-top="17px"
        v-slot:default="scopes"
        :default-value="authSceneDefaultValue"
        :disabled="isLook"
        @update="onAuthSceneUpdate"
        ref="authSceneUpdateAdd"
      >
        <a-row>
          <a-col :span="14">
            <a-form-item>
              <a-select
                :options="BusinessSceneOptions"
                placeholder="请选择业务场景"
                :disabled="isLook"
                v-decorator="[`auth_scene[${scopes.index}][type]`,  { rules: [$validate.required] }]"
              ></a-select>
            </a-form-item>
          </a-col>

          <a-col :span="1" class="text-center" style="line-height: 30px;">-</a-col>

          <a-col :span="9">
            <a-form-item>
              <a-input-number
                :min="0"
                :max="100"
                class="w-100"
                :formatter="value => `${value}%`"
                :parser="value => value.replace('%', '')"
                placeholder="供应商占比"
                v-decorator="[`auth_scene[${scopes.index}][ratio]`, { rules: [$validate.required], initialValue: '0' }]"
                :disabled="isLook"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </form-add>
    </a-form-item>

    <!-- 授权地域 -->
    <a-form-item required class="form-item-lh-20" label="授权地域">
      <a-radio-group
        :options="AuthAreaOptions"
        v-decorator="['auth_area', { initialValue: '1' }]"
        :disabled="isLook"
      ></a-radio-group>
    </a-form-item>

    <!-- 授权类型 -->
    <a-form-item required class="form-item-lh-20" label="授权类型">
      <a-radio-group
        :options="AuthTypeOptions"
        v-decorator="['auth_type', { initialValue: '1' }]"
        :disabled="isLook"
      ></a-radio-group>
    </a-form-item>

    <!-- 维权授权 -->
    <a-form-item required :class="{'m-bottom-0': isLegalRight}" label="维权授权">
      <a-switch
        :disabled="isLook"
        v-decorator="['is_maintain_rights', { valuePropName: 'checked', initialValue: isLegalRight, getValueFromEvent: onChangeSwitch}]"
      />
    </a-form-item>

    <a-form-item
      v-if="isLegalRight"
      :label-col="{ offset: 4, span: 4  }"
      :wrapper-col="{ span: 10 }"
      label="分成比例"
    >
      <a-input-number
        :min="0"
        :max="100"
        :formatter="value => `${value}%`"
        :parser="value => value.replace('%', '')"
        placeholder="供应商比例"
        :disabled="isLook"
        v-decorator="[`suplr_ratio`, { rules: [$validate.required], initialValue: '0' }]"
      />
      <!-- <form-rate
          auto-fill
          :placeHolders="['我方比例', '他方比例']"
          v-decorator="['share_ratio', { rules: [$validate.required], initialValue: [] }]"
      ></form-rate>-->
    </a-form-item>

    <!-- 授权类型 -->
    <a-form-item label="合同附件">
      <form-upload-files
        text="上传文件"
        accept="application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        @uploading="onUploading"
        :disabled="isLook"
        :multiple="false"
        :default-value="uploadFilesDefaultValue"
        v-decorator="['contract_file', { initialValue: [] }]"
      >

        <div class="font-grey">支持扩展名：.doc .docx .pdf</div>
      </form-upload-files>
    </a-form-item>
  </a-form>
</template>
<script>
  import {FormAdd} from '@/components/index';
  import {AuthAreaOptions, AuthTypeOptions, BusinessSceneOptions} from '@/config/options'
  import * as FunctionCommon from '@/utils/function'

  export default {
    data() {
      return {
        form: this.$form.createForm(this, "nodify"),

        uploadParams: {},
        uploadName: 'file',
        uploading: false,

        BusinessSceneOptions,
        AuthAreaOptions,
        AuthTypeOptions,

        // onUploading: false,

        isLegalRight: true,

        authSceneDefaultValue: [],
        uploadFilesDefaultValue: [],
      };
    },

    props: {
      defaultValue: Object,
      isLook: Boolean
    },

    components: {
      FormAdd
    },

    created() {
      this.uploadParams = {
        upload_type: 1,
        field_name: this.uploadName
      }
    },

    methods: {
      onChangeSwitch(checked) {
        this.isLegalRight = checked;
        return checked;
      },

      onUploading(uploading) {
        this.uploading = uploading;
      },

      onAuthSceneUpdate(data) {
        if (data && data.length > 0) {
          let value = FunctionCommon.transferParamFromObjToStr({auth_scene: data});
          this.form.setFieldsValue(value);
        }
      }
    }
  };
</script>
<style lang="scss" scoped>
  .upload-box {
    width: auto;

    /deep/ {
      .ant-upload {
        width: 80px;
        height: 80px;
        margin-bottom: 0;
        padding: 0;
        background-color: #f7f4f8;
      }
    }
  }
</style>