<template>
  <a-radio-group class="raido-group" v-bind="$props" v-on="$listeners" :options="listOptions">
    <span class="font-grey">暂无合同信息</span>
  </a-radio-group>
</template>

<script>
  import {Radio} from 'ant-design-vue';

  let props = Object.assign({}, Radio.props);

  delete props.open;

  export default {
    data() {
      return {
        list: []
      }
    },

    props: {
      ...props,
      user_id: {
        type: [Number, String],
        default: ''
      },
      checkedFirst: Boolean
    },

    computed: {
      listOptions() {
        return this.list.map(({contract_id, name}) => ({label: name, value: contract_id}))
      }
    },

    watch: {
      user_id: {
        handler(newVal) {
          if (newVal) {
            this.getData(newVal)
          }
        },
        immediate: true
      }
    },

    methods: {
      getData(user_id) {
        this.$axios.Library_ContractList({user_id}).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            let options = data.data ? data.data.list : [];

            this.list = options;

            this.$emit('onChangeData', this.list);
          }
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .raido-group {
    /deep/ {
      .ant-radio-wrapper {
        margin-bottom: 10px;
      }
    }
  }
</style>

