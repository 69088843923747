<template>
  <a-form class="ng-form-style ng-filter" :form="form" layout="inline">

    <a-form-item label="搜索时间">
      <a-range-picker
        class="w-100"
        valueFormat="YYYY-MM-DD"
        v-decorator="['time', { initialValue: defaultData.time}]"
        :style="{width: '240px'}"
      >
        <a-icon slot="suffixIcon" type="calendar" />
      </a-range-picker>
    </a-form-item>

    <slot name="extra"></slot>
  </a-form>
</template>

<script>
  import {DatePicker} from 'ant-design-vue';

  export default {
    data() {
      return {
    
      }
    },

    props: {
      type: String,
      defaultData: {
        type: Object,
        default() {
          return {}
        }
      },
    },

    components: {
      ARangePicker: DatePicker.RangePicker,
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        onValuesChange: (props, values) => {
          values = Object.assign(values, {page: 1});

          this.$root.$goSearch(values);

          this.$emit('change', values);
        }
      });
    },

    methods: {
    }
  }
</script>

<style lang="scss" scoped>
  .ng-filter {
  }
</style>
