<template>
  <a-modal width="500px" v-model="show" title="成本价配置" destroyOnClose :maskClosable="false">
    <p>请配置成本价格，后续定价流程将基于此价格进行配置。</p>

    <a-input-number :min="0" v-model="price" placeholder="成本价格" style="width: 300px;" />

    <template slot="footer">
      <a-button key="back" @click="handleCancel">取消</a-button>

      <a-popconfirm
        :visible="visiblePopConfirm"
        title="确定保存本次修改吗？保存后将应用在所有价格方案中。"
        ok-text="确定"
        cancel-text="取消"
        @confirm="handleOk"
        @cancel="visiblePopConfirm = false;"
        :confirm-loading="confirmLoading"
      >
        <a-button
          key="submit"
          type="primary"
          @click="visiblePopConfirm = true;"
          :loading="confirmLoading"
        >确定</a-button>
      </a-popconfirm>
      <!-- <a-button key="submit" type="primary" @click="handleOk" :loading="confirmLoading">确定</a-button> -->
    </template>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import {Popconfirm} from 'ant-design-vue';

  export default {
    data() {
      return {
        form: this.$form.createForm(this, "nodify"),

        show: false,
        visiblePopConfirm: false,

        price: 0,

        confirmLoading: false,
      }
    },

    watch: {
      show(newVal) {
        if (newVal) {
          this.getData();
        }
      }
    },

    components: {
      APopconfirm: Popconfirm
    },

    mixins: [dialogMixins],

    methods: {
      handleOk() {
        let params = {cost: this.price};

        this.confirmLoading = true;
        this.visiblePopConfirm = false;

        this.$axios.Price_SetCost(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('设置成功');
            this.$emit('success', data);
            this.show = false;
          }
          this.confirmLoading = false;
        }).catch(() => {
          this.confirmLoading = false;
        })
      },

      getData() {
        let params = {};

        this.$axios.Price_GetCost(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.price = data.data.cost || 0;
          }
        })
      },

      handleCancel() {
        this.show = false;
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
