const SongList = () => import('@/pages/songList/SongList.vue');
const SongListDetail = () => import('@/pages/songList/SongListDetail.vue');
const Discount = () => import('@/pages/songList/Discount.vue');

export default [{
    path: '/songlist',
    name: 'songlist',
    component: SongList,
  },
  {
    path: '/songlistdetail/:sid', // 歌单id
    name: 'songListDetail',
    component: SongListDetail,
  },
  {
    path: '/songlistDiscount/:sid', // 歌单id
    name: 'songlistDiscount',
    component: Discount,
  },
]