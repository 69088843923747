<template>
        <a-modal
          width="700px"
          v-model="show"
          :title="modalTitle"
          :maskClosable="false"
          destroyOnClose
          @ok="handleOk"
          @cancel="handleCancel"
          :bodyStyle="{
            maxHeight: `${dialogBodyMaxHeight ? dialogBodyMaxHeight : '500'}px`,
            overflow: 'auto',
          }"
        >
          <a-form class="w-100 ng-form" :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }">
            <a-form-item label="热搜词">
                <ng-input
                    placeholder="请输入热搜词"
                    :maxLength="50"
                    show-counter
                    size="large"
                    v-decorator="['title', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur' }]"
                ></ng-input>
            </a-form-item>
            
          
          </a-form>
        </a-modal>
      </template>
      
      <script>
        import {dialogMixins} from '@/mixins/index';
        export default {
            data() {
                return {
                    form: this.$form.createForm(this, "nodify"),
                    show: false,
            
                }
            },
            components: {
            },
            props: {
                defaultValue: {
                    type: Object,
                    default() {
                        return {}
                    }
                },
                isEdit : Boolean,
            },
      
            computed: {
                modalTitle() {
                    return this.isEdit ? '修改热搜词' : '添加热搜词'
                },
                request() {
                    return this.isEdit ? this.$axios.TopicSave : this.$axios.TopicAdd
                },
                successMessage() {
                    return this.isEdit ? '修改成功' : '添加成功';
                }
            },
        
            watch: {
                defaultValue:function () {
                    this.$nextTick(() => {
                        this.form.setFieldsValue(this.defaultValue);
                        this.profile_photo_url = this.defaultValue.logo_url;
                    })
                },
                deep:true

            },
            created() {

            },
            mixins: [dialogMixins],
            methods: {

        
                handleOk() {
                    this.form.validateFieldsAndScroll((errors, values) => {
                        console.log(values)
                        if (!errors) {
                            this.submitLibrary(values);
                        }
                    })
                },
        
                submitLibrary(params) {
                    let method = this.request;
                    if (typeof method !== 'function') return;
                    this.confirmLoading = true;
                    this.isEdit && (params.topic_id = this.defaultValue.topic_id)
                    
                    method(params).then(res => {
                        const data = res.data;
            
                        if (data && data.code == 0) {
                            this.$message.success(this.successMessage);
                            this.$emit('success', data);
                        }
                        this.confirmLoading = false;
                    }).catch(() => {
                        this.confirmLoading = false;
                    })
                },
        
                handleCancel() {
                    this.$emit('success', false);
                    this.show = false;
                },
        
              
            }
        }
      </script>
      
      <style lang="scss" scoped>
        .upload-box {
          display: inline;
          width: auto;
      
          /deep/ {
            .ant-upload {
              width: 80px;
              height: 80px;
              margin-bottom: 0;
              padding: 0;
              background-color: #f7f4f8;
            }
          }
        }
      </style>
      