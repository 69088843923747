const Contract = () => import('@/pages/buyManage/Contract.vue');
const Receipt = () => import('@/pages/buyManage/Receipt.vue');

export default [{
    path: '/buy/contract',
    name: 'buyContract',
    component: Contract,
  },
  {
    path: '/buy/receipt',
    name: 'buyReceipt',
    component: Receipt,
  },
]