<template>
    <main-structure class="ng-library" dir="ver">
        <template slot="header">
            <span class="title">轮播图配置</span>
            <div class="header-content header-search-content">
                <a-input-search class="ng-input-search" placeholder="请输入活动标题" :default-value="$route.query.name || ''"
                    @search="onSearch" allowClear v-model="keyword" >
                    <a-button slot="enterButton" type="sub" @click="onSearch">
                        <a-icon type="search" />
                    </a-button>
                </a-input-search>

                <a-button v-if="$root.$checkAuth($auth[name] && $auth[name].add)" type="sub"
                    @click="handleFunc($var.Handle.add)">添加轮播图</a-button>
            </div>
        </template>
        <a-form class="ng-form-style ng-filter" :form="form"  layout="inline" >
            <a-radio-group class="f-l" v-model="value" button-style="solid" @change="onChange">
                <a-radio-button value="1" style="margin-top: 4px">上线</a-radio-button>
                <a-radio-button value="0">下线</a-radio-button>
            </a-radio-group>
        
            <a-form-item label="排序方式：" style="margin-left: 20px">
                <a-select
                  :options="SortOptions"
                  :style="{width: '120px'}"
                  v-decorator="['sort']"
                  @change="onChangeSort"
                ></a-select>
              </a-form-item>
            </a-form>
        <div class="flex-1 table-box" ref="ngTableBox">
            <p></p>
            <banner-list v-if="$root.$checkAuth($auth[name] && $auth[name].list)"
                :scrolly-height="mainContentScrollMaxHeight" :auth-name="name" :params="tableParams"
                 @operate="onOperate" ref="ngTable"></banner-list>
        </div>
        <banner-add
        :visible.sync="showHandleDialog"
        :is-edit="isEdit"
        :default-value="handleData"
        @success="handleSuccess"
        ></banner-add>
    </main-structure>
</template>
<script>
    import BannerList from './BannerList.vue';
    import BannerAdd from './BannerAdd.vue';
    import { mainContentMixin } from '@/mixins';
    import {AddDefaultToOptions,SortOptions} from '@/config/options'
    export default {
        data() {
            return {
                form: this.$form.createForm(this, "nodify"),
                name: `home_manage`,
                value: '1',
                showHandleDialog: false,
                isEdit: false,
                tableParams:{},
                keyword : "",
                handleData: {},
                SortOptions: AddDefaultToOptions(SortOptions),
                sort:"DESC",
            }
        },
        created() {
            
        },
        mounted() {
            let {query} = this.$route;
            this.tableParams = Object.assign({}, this.tableParams, {
                page: query.page || 1
            });
            this.tableParams = Object.assign({}, this.tableParams, {status:this.value,sort:this.sort});
            this.$nextTick(() => {
                this.form.setFieldsValue({ sort : this.sort })
            })
        },
        components: {
            BannerList,
            BannerAdd
        },
        mixins: [
            mainContentMixin
        ],
        methods: {
            onChange() {
                this.tableParams = Object.assign({}, this.tableParams, {status:this.value});
                this.$refs.ngTable.loadTableData();
            },
            handleFunc() {
                this.isEdit = false;
                this.handleData = {};
                this.showHandleDialog = true;
            },
            onSearch() {
                this.tableParams = Object.assign({}, this.tableParams, {title:this.keyword});
                this.$refs.ngTable.loadTableData();
            },
            onChangeSort(value){
                // console.log(value)
                this.sort = value;
                // console.log(this.form.getFieldsValue());
                this.tableParams = Object.assign({}, this.tableParams, {sort:this.sort});
                this.$refs.ngTable.loadTableData();
            },
            onOperate(scope) {
                if (scope.type == 1){
                    this.isEdit = true;
                    this.handleData = scope;
                    this.showHandleDialog = true;
                    
                }else if(scope.type == 2){
                    this.$axios.BannerDel({banner_id : scope.banner_id}).then(res => {
                        const data = res.data;
            
                        if (data && data.code == 0) {
                            this.$message.success("删除成功");
                            this.$refs.ngTable.loadTableData();
                        }
                    });
                }else if(scope.type == 3){
                    this.$axios.BannerOnline({banner_id : scope.banner_id,status:scope.status}).then(res => {
                        const data = res.data;
            
                        if (data && data.code == 0) {
                            this.$message.success("更新成功");
                            this.$refs.ngTable.loadTableData();
                        }
                    });
                }else if(scope.type == 4){
                    this.$axios.BannerSaveSort({banner_id : scope.banner_id,sort:scope.sort}).then(res => {
                        const data = res.data;
            
                        if (data && data.code == 0) {
                            this.$message.success("更新成功");
                            this.$refs.ngTable.loadTableData();
                        }
                    });
                }
            },
            handleSuccess(isLoad) {
                this.showHandleDialog = false;
                this.showCoopHandleDialog = false;
                if (isLoad){
                    this.$refs.ngTable.reloadTableData();
                }else{
                    this.isEdit = false;
                    this.handleData = {};
                }
                // isLoad !== false ? this.$refs.ngTable.reloadTableData() : this.$refs.ngTable.loadTableData();
            },
        }
    }
</script>
<style lang="scss" scoped>
</style>