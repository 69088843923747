var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{staticClass:"ng-form-style ng-filter",attrs:{"form":_vm.form,"layout":"inline"}},[_c('a-form-item',{staticStyle:{"margin-left":"20px"},attrs:{"label":"认领时间："}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sort']),expression:"['sort']"}],style:({ width: '120px' }),attrs:{"options":_vm.SortOptions},on:{"change":_vm.onChangeSort}})],1),_c('span',{staticStyle:{"float":"right"}},[_c('a-badge',{staticClass:"m-right-base",attrs:{"count":_vm.tipInfo.reject_num}},[(
            _vm.$root.$checkAuth(_vm.$auth[_vm.authName] && _vm.$auth[_vm.authName].markReject)
          )?_c('a-button',{staticClass:"ant-btn-self",class:{ 'ant-btn-disabled': !_vm.tipInfo.reject_num },attrs:{"size":"large","type":"warning"},on:{"click":function($event){return _vm.goTask(_vm.$var.Handle.reject)}}},[_vm._v("驳回待处理")]):_vm._e()],1),_c('a-badge',{attrs:{"count":_vm.tipInfo.imme_num}},[(
            _vm.$root.$checkAuth(_vm.$auth[_vm.authName] && _vm.$auth[_vm.authName].markInstance)
          )?_c('a-button',{staticClass:"ant-btn-self",class:{ 'ant-btn-disabled': !_vm.tipInfo.imme_num },attrs:{"size":"large","type":"primary"},on:{"click":function($event){return _vm.goTask(_vm.$var.Handle.imme)}}},[_vm._v("即时任务")]):_vm._e()],1)],1)],1),_c('div',{staticClass:"m-bottom-base text-right"}),_c('ng-table',{staticClass:"ng-table",attrs:{"columns":_vm.columns,"params":_vm.params,"rowKey":function (row) { return row.task_id; },"request":_vm.$axios.MarklibraryTask_servingList,"load":_vm.loadTable,"reload":_vm.reloadTable,"scroll":{ y: _vm.scrollyHeight - 20 }},on:{"update:load":function($event){_vm.loadTable=$event},"update:reload":function($event){_vm.reloadTable=$event},"dataChange":_vm.onDataChange},scopedSlots:_vm._u([{key:"lib_logo_url",fn:function(text, scope){return [_c('ng-image',{staticClass:"table-header",attrs:{"src":scope.lib_logo_url
            ? ((scope.lib_logo_url) + "?x-oss-process=style/thumb_10")
            : _vm.$var.defaultHeader},on:{"error":function (ref) {
                      var target = ref.target;

                      return (target.src = _vm.$var.defaultHeader);
          }}})]}},{key:"priority",fn:function(text){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.toLevelName(text))}})]}},{key:"current_progress",fn:function(text, scope){return [_c('span',[_vm._v(_vm._s(scope.done_music_num)+" / "+_vm._s(scope.music_num))])]}},{key:"operation",fn:function(text, scope){return [(_vm.$root.$checkAuth(_vm.$auth[_vm.authName] && _vm.$auth[_vm.authName].info))?_c('router-link',{staticClass:"m-right-ms",attrs:{"to":("/markmusic/" + (scope.task_id) + "?from=" + (_vm.$var.Common.first)),"disabled":!scope.op_tag}},[_vm._v("进入任务")]):_vm._e(),(_vm.$root.$checkAuth(_vm.$auth[_vm.authName] && _vm.$auth[_vm.authName].deliver))?_c('a-button',{staticClass:"m-right-ms",attrs:{"type":"link","disabled":!scope.op_deliver},on:{"click":function($event){return _vm.goDeliever(scope)}}},[_vm._v("交付")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }