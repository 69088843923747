<template>
  <a-modal
    width="600px"
    v-model="show"
    title="开通定制会员"
    destroyOnClose
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form
      class="w-100 ng-form"
      :form="form"
      hideRequiredMark
      :label-col="{ span: 4}"
      :wrapper-col="{ span: 18 }"
    >
      <a-form-item label="授权类型">
        <a-select
          :options="authTypeOptions"
          v-decorator="[`auth_type`, {rules: [$validate.required] }]"
          placeholder="请选择授权类型"
        >
        </a-select>
      </a-form-item>
      <a-form-item label="下载次数">
        <a-input
          v-decorator="[`download_num`, {rules: [$validate.required] }]"
          placeholder="请输入下载次数(不限制输入-1)"
        />
      </a-form-item>
      <a-form-item label="订单金额">
        <a-input
          v-decorator="[`vip_money`, {rules: [$validate.required] }]"
          placeholder="请输入订单金额"
        />
      </a-form-item>
      <a-form-item label="开通期限">
        <a-range-picker
            class="w-100"
            valueFormat="YYYY-MM-DD"
            v-decorator="['coop_time', { rules: [$validate.required], initialValue: []}]"
            :ranges="getDateRange()"
            :disabled="isLook"
        >
            <a-icon slot="suffixIcon" type="calendar" />
        </a-range-picker>
    </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import {DatePicker} from 'ant-design-vue';

  export default {
    data() {
      return {
        form: this.$form.createForm(this, { name: 'vip-add' }),
        show: false,
        confirmLoading: false,
        authTypeOptions:[
            {
                'value': 1,
                'label': '企业VIP',
            },
            {
                'value': 2,
                'label': '个人VIP',
            }
        ],
        isLook: false,
      }
    },

    props: {
      user_id: String
    },

    created() {
    },
    components: {
      ARangePicker: DatePicker.RangePicker
    },

    mixins: [dialogMixins],

    methods: {
      getDateRange() {
        let moment = this.$moment;
        let current = moment();
        let currYear = moment().year();
        let currMonth = moment().month();
        let oneYearLater = moment().set('year', currYear + 1).subtract(1, 'days');
        let oneMonthLater = moment().set('month', currMonth + 1).subtract(1, 'days');
        let threeMonthLater = moment().set('month', currMonth + 3).subtract(1, 'days');   
        return {'1个月': [current, oneMonthLater],'3个月': [current, threeMonthLater],'一年': [current, oneYearLater]};
      },
      handleOk() {
        this.form.validateFieldsAndScroll((errors, values) => {
          if (!errors) {
            this.submit(values);
          }
        })
      },

      submit(params) {
        this.confirmLoading = true;
        params.user_id = this.user_id;
        this.$axios.Vip_add(params).then(res => {
          const data = res.data;
          if (data && data.code == 0) {
            this.$message.success('添加成功')
            this.show = false;
            this.$emit('success');
          }
          this.confirmLoading = false;
        }).catch(() => {
          this.confirmLoading = false;
        })
      },
      handleCancel() {
        this.show = false;
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
