const AccountMembers = () => import('@/pages/account/members.vue');
const AccountDepartment = () => import('@/pages/account/department.vue');
const AccountDepartmentDetail = () => import('@/pages/account/departmentDetail.vue');
const AccountRoles = () => import('@/pages/account/roles.vue');
const AccountRolesAdd = () => import('@/pages/account/rolesAdd.vue');

export default [{
    path: '/account/members',
    name: 'accountMembers',
    component: AccountMembers
  },
  {
    path: '/account/department',
    name: 'accountDepartment',
    component: AccountDepartment
  },
  {
    path: '/account/departmentDetail/:dept_id',
    name: 'accountDepartmentDetail',
    component: AccountDepartmentDetail
  },
  {
    path: '/account/roles/:dept_id',
    name: 'accountRoles',
    component: AccountRoles,
  },
  {
    path: '/account/roles/:dept_id/add/:role_id/:hid', // hid 同$var.Handle
    name: 'accountRolesAdd',
    component: AccountRolesAdd
  }
];