export * from '~/config/options.c'
import * as $val from "@/config/options.value";


// 优惠券促销方式
export const Promotion = [
  {
    label: '满减券',
    value: '2'
  },
  {
  label: '打折券',
  value:  '1'
},

]

// 优惠券领取状态
export const CouponBindPromotion = [
{
  label: '未领取',
  value:  '1'
},
{
  label: '已领取',
  value: '2'
}
]
// 优惠券使用状态
export const CouponUsePromotion = [{
  label: '未使用',
  value:  '1'
},
{
  label: '已使用',
  value: '2'
}
]

// 账号来源
export const AccountOrigin = [{
    label: '官方自建',
    value: $val.SystemOrigin.Admin
  },
  {
    label: '入驻申请',
    value: $val.SystemOrigin.Web
  },
]

// 任务优先级
export const LevelOptions = [{
    label: '低',
    value: '1',
    color: '#000000'
  },
  {
    label: '较高',
    value: '2',
    color: '#B620E0'
  },
  {
    label: '紧急',
    value: '3',
    color: '#FF4D4F'
  }
]

// 标注对象
export const MarkTarget = [{
    label: '音乐',
    value: $val.MarkTargetVal.music
  },
  {
    label: '歌单',
    value: $val.MarkTargetVal.songlist
  },
  {
    label: '提案',
    value: $val.MarkTargetVal.proposal
  },
  {
    label: '音乐人',
    value: $val.MarkTargetVal.musician
  }
]

// 服务进度
export const ServerProgress = [{
    label: '待服务',
    value: '0',
  },
  {
    label: '服务中',
    value: '1',
  },
  {
    label: '已完成',
    value: '2',
  }
]

// 定价状态
export const PriceStatue = [{
    label: '未定价',
    value: '0',
  },
  {
    label: '已定价',
    value: '1',
  },
]

// 审核状态
export const VerifyStatus = [{
    label: '待审核',
    value: '0',
  },
  {
    label: '已审核',
    value: '1',
  },
]

// 商品定义类型
export const GoodDefineTypeOptions = [{
    label: '通用',
    value: $val.Good_define_type.common,
  },
  {
    label: '自定义',
    value: $val.Good_define_type.custome,
  },
]

// 未支付订单 类型 后台管理系统中的
export const UndoPayOrderOptionsAdmin = [{
    value: $val.UndoPayOrderType.wait,
    label: '待支付'
  },
  // {
  //   value: $val.UndoPayOrderType.error,
  //   label: '支付失败'
  // },
  {
    value: $val.UndoPayOrderType.overtime,
    label: '已超时'
  },
  {
    value: $val.UndoPayOrderType.cancel,
    label: '已取消'
  },
]

// 订单状态
export const OrderStatusOptionsAdmin = [{
    value: $val.UndoPayOrderType.payed,
    label: '已支付'
  },
  {
    value: $val.UndoPayOrderType.paying,
    label: '支付中'
  },
  ...UndoPayOrderOptionsAdmin
]

// 标注音乐拒绝理由
export const MarkRejectReason = [{
    label: '理由一',
    value: '0',
    child: [{
        label: '理由子一1',
        value: '9'
      },
      {
        label: '理由子二2',
        value: '7'
      }
    ]
  },
  {
    label: '理由二',
    value: '1',
    child: [{
      label: '理由二子一',
      value: '5'
    }]
  }
]

// 收益类型
export const EarningsOptions = [
  {
    label: '线下项目收益',
    value: '2'
  },
  {
  label: '官网零售收益',
  value:  '1'
},

]
export const SortOptions = [
  {
    label: '升序',
    value: 'ASC'
  },
  {
  label: '降序',
  value:  'DESC'
}]

export const OnlineOptions = [
  {
    label: '已上线',
    value: '1'
  },
  {
  label: '待上线',
  value:  '0'
}]