<template>
  <div class="detail-main flex flex-column cen-start">
    <template v-if="list && list.length > 0">
      <template v-for="(item, index) in list">
        <ng-card :key="index">
          <div class="flex cen-center detail-content">
            <div class="flex flex-column">
              <!-- 个人 -->
              <template v-if="item.type == $val.ReceiptTitleType.person">
                <span class="title font-xs">{{item.name || '-'}}</span>
              </template>
              <!-- 公司 -->
              <template v-else>
                <div class="detail-item">
                  <span class="title font-xs">{{item.name || '-'}}</span>
                  <a-tag v-if="item.is_default == $val.CommonCheck.correct" color="black">默认</a-tag>
                  <!-- 状态 -->
                  <!-- <template>
                    <a-tag v-if="item.status == '1'" color="green">通过</a-tag>
                    <a-tag v-else-if="item.status == '2'" color="green">审核中</a-tag>
                    <template v-else>
                      <a-popover v-if="item.reason" placement="bottom">
                        <div slot="content">{{item.reason}}</div>
                        <a-tag color="red">
                          <span>拒绝</span>
                          <a-icon type="down" />
                        </a-tag>
                      </a-popover>
                      <a-tag v-else color="red">拒绝</a-tag>
                    </template>
                  </template> -->
                </div>
                <div class="detail-item">
                  <span class="sub-title font-xs">{{item.taxer_number || '-'}}</span>
                  <a-tag v-if="item.is_special == $val.CommonCheck.correct" color="black">专</a-tag>
                </div>
              </template>
            </div>
          </div>

          <div v-if="editable" class="handle-btns flex" slot="right">
            <a-button v-if="item.is_default !== '1'" type="link" @click="onDefault(item)">设为默认发票抬头</a-button>
            <a-button type="link" @click="handleItem($var.Handle.edit, item)">修改</a-button>
            <a-button type="link" class="ant-btn-error" @click="handleItem($var.Handle.del, item)">删除</a-button>
          </div>
        </ng-card>
      </template>
    </template>
    <template v-else>
      <div class="empty flex cen-center">
        暂无发票抬头信息
        <br />请根据开票需求，提交真实有效的抬头信息
      </div>
    </template>
  </div>
</template>
<script>
  import {NgCard} from '@/components/index';
  import {SettleWayValues} from "@/config/options.value";

  const PayLogoDir = {
    [SettleWayValues.Alipay]: require('~/assets/images/Alipay.png'),
    [SettleWayValues.PayPal]: require('~/assets/images/PayPal.png'),
    [SettleWayValues.Payoneer]: require('~/assets/images/Payoneer.png'),
    [SettleWayValues.CToC]: require('~/assets/images/CToC.png'),
  }

  export default {
    data() {
      return {
        PayLogoDir,
      }
    },

    props: {
      list: Array,
      editable: {
        type: Boolean,
        default: false,
      }
    },

    components: {
      NgCard
    },
    methods: {
      handleItem(type, item) {
        let that = this;

        if (type === this.$var.Handle.del) {
          this.$confirm({
            width: '480px',
            title: '确认要该发票抬头吗？',
            content: '删除该发票抬头',
            onOk() {
              return new Promise(resolve => {
                that.onDel(item, resolve);
              });
            }
          });
        } else if (type === this.$var.Handle.edit) {
          this.$emit('onEdit', item);
        }
      },

      onDefault(item) {
        let that = this;

        this.$confirm({
          width: '480px',
          title: '确认设置默认发票抬头吗？',
          // content: '删除该地址信息',
          onOk() {
            return new Promise(resolve => {
              that.setDefault(item, resolve);
            });
          }
        });
      },

      setDefault(item, next) {
        let params = {
          invoice_id: item.invoice_id
        };

        this.$axios.Recipt_setDefault(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('设置成功');

            next();

            this.$emit('success', data.data);
          }
        })
      },

      onDel(item, next) {
        let params = {
          invoice_id: item.invoice_id
        };

        this.$axios.Recipt_del(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('删除成功');

            next();

            this.$emit('success', data.data);
          }
        })
      },
    }
  }
</script>
<style lang="scss" scoped>
  .logo {
    height: 30px;
    margin: 20px 20px 20px 0;
  }

  .detail-main {
    .title {
      font-size: 16px;
      font-weight: bold;
    }

    .detail-item {
      display: flex;
      align-items: center;
    }

    .title,
    .sub-title {
      margin-right: 20px;
      line-height: 2;
    }
  }
</style>