<template>
  <a-modal
    width="500px"
    v-model="show"
    title="确认要拒绝该音乐吗？"
    destroyOnClose
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    @ok="handleOk"
  >
    <div>
      <p class="font-grey">
        <span>请提交拒绝理由，帮助供应商更好地改进~</span>
      </p>

      <a-form :form="form" class="w-100 ng-form">
        <a-form-item>
          <a-radio-group v-decorator="[`reject_reason_first`]">
            <template v-for="item in MarkRejectReason">
              <a-radio
                :style="radioStyle"
                :value="item.value"
                :key="item.value + '1'"
              >{{item.label}}</a-radio>

              <a-form-item class="m-bottom-0" :key="item.value + '3'">
                <a-radio-group
                  v-if="item.value === form.getFieldValue('reject_reason_first')"
                  v-decorator="[`reject_reason_second`]"
                >
                  <template v-for="cItem in item.child">
                    <a-radio
                      :value="cItem.value"
                      :key="cItem.value + '100'"
                      :style="{...radioStyle, marginLeft: '20px'}"
                    >{{cItem.label}}</a-radio>
                  </template>
                </a-radio-group>
              </a-form-item>
            </template>
          </a-radio-group>
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import {MarkRejectReason} from '@/config/options'

  export default {
    data() {
      return {
        form: this.$form.createForm(this, "nodify"),

        show: false,

        confirmLoading: false,

        MarkRejectReason,

        radioStyle: {
          display: 'block',
          height: '30px',
          lineHeight: '30px',
        }
      }
    },

    props: {
      submit: {
        type: Function,
        default: () => {}
      }
    },

    components: {
    },

    mixins: [dialogMixins],

    methods: {

      handleOk() {
        this.form.validateFieldsAndScroll((errors, values) => {
          let {reject_reason_second} = values;

          if (!reject_reason_second) {
            this.$message.error('请选择拒绝原因')
            return false;
          }

          if (!errors) {
            this.submit(reject_reason_second, this.onLoading)
          }
        })
      },

      onLoading(loading) {
        this.confirmLoading = loading;
      }

      //   submit(params) {
      //     this.confirmLoading = true;

      //     this.$axios.Marklibrary_taskToAnyone(params).then(res => {
      //       const data = res.data;

      //       if (data && data.code == 0) {
      //         this.$message.success('指派成功');
      //         this.$emit('success', data);
      //       }

      //       this.confirmLoading = false;
      //     }).catch(() => {
      //       this.confirmLoading = false;
      //     })
      //   },

      //   handleCancel() {
      //     this.show = false;
      //   },
    }
  }
</script>

<style lang="scss" scoped>
  .ng-supplier-add {
    /deep/ .ant-form-item-label {
      width: 130px;
    }
  }

  .dynamic-delete-button {
    position: absolute;
    top: 50%;
    right: -0;
    transform: translate(120%, -50%);
    font-size: 24px;
    color: #999;
    transition: all 0.3s;
    cursor: pointer;
  }

  .upload-box {
    /deep/ {
      .ant-upload {
        width: 148px;
        height: 104px;
        margin-bottom: 0;
      }
    }
  }
</style>
