<template>
  <a-modal
    :title="title"
    :width="$config.DialogWidth"
    v-model="show"
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form
      :form="form"
      layout="horizontal"
      :label-col="{ xs: {span: 6}}"
      :wrapper-col="{ xs: {span: 16}}"
      class="ng-form m-top-base"
    >
      <a-form-item label="收款方式">
        <form-pay-ways
          size="large"
          :user-type="$route.params.type"
          @change="onChange"
          v-decorator="['type', { rules: [$validate.required], initialValue: defaultWay}]"
        ></form-pay-ways>
      </a-form-item>

      <component
        :is="payWay === $val.SettleWayValues.CToC ? 'PayCToC' : 'PayUToC'"
        :default-data="defaultData"
        ref="payInfos"
        :key="payWay"
      ></component>

      <a-form-item :wrapper-col="{ xs: {offset: 6}}">
        <a-checkbox
          v-decorator="['is_default', { initialValue: false, valuePropName: 'checked'}]"
        >设为默认收件地址</a-checkbox>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import {FormPayWays} from '@/components/index';
  import PayCToC from './PayCToC.vue';
  import PayUToC from './PayUToC.vue';

  export default {
    data() {
      return {
        show: false,

        confirmLoading: false,

        defaultData: {},

        defaultWay: this.$val.SettleWayValues.Alipay,

        payWay: this.defaultWay,
      }
    },

    props: {
      user_id: String,
      handle: String,
      options: Object,
      defaultValue: {
        type: Object,
        default() {
          return {}
        }
      },
    },

    computed: {
      title() {
        return this.handle === 'edit' ? '修改收款信息' : '添加收款信息'
      },

      isEdit() {
        return this.handle === this.$var.Handle.edit;
      },
    },

    watch: {
      visible(newVal) {
        if (newVal && this.form) {

          this.$nextTick(() => {
            this.form.resetFields();
            this.$refs.payInfos.form.resetFields();

            if (this.isEdit) {
              this.form.setFieldsValue(this.defaultValue);
              this.$refs.payInfos.form.setFieldsValue(this.defaultValue);
            }
          })

          this.payWay = this.defaultValue.type || this.defaultWay;
        }
      },
    },

    mixins: [dialogMixins],

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        name: 'pay'
      })
    },

    created() {
      this.payWay = this.defaultWay;
    },

    components: {
      FormPayWays, PayCToC, PayUToC
    },

    methods: {

      handleOk() {
        this.form.validateFieldsAndScroll((errors, values) => {

          if (!errors) {
            this.$refs.payInfos.form.validateFieldsAndScroll((errors, innervalues) => {

              if (!errors) {
                values = Object.assign(values, innervalues);

                this.onSubmit(values).then((data) => {
                  this.$emit('update:visible', false);
                  this.$emit('success', data);
                })
              }
            })
          }
        })
      },

      // Address_add  Address_edit
      onSubmit(param) {
        let url = this.isEdit ? 'Sup_Cash_editAccount' : 'Sup_Cash_addAccount';

        // 公对公付款
        // if (param.type === this.$val.SettleWayValues.CToC) {
        //   url = this.isEdit ? 'Sup_Cash_editBTBAccount' : 'Sup_Cash_addBTBAccount';
        // }

        this.confirmLoading = true;

        param.user_id = this.user_id;
        param.is_default = +param.is_default;

        if (this.isEdit) {
          param = Object.assign(param, {receipt_id: this.defaultValue.receipt_id})
        }

        return new Promise(resolve => {
          this.$axios[url] && this.$axios[url](param).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              resolve(data);
              this.$message.success(this.handle === this.$var.Handle.edit ? '编辑成功' : '新增成功');
            }

            this.confirmLoading = false;
          }).catch(() => {
            this.confirmLoading = false;
          })
        })
      },

      onChange(value) {
        this.payWay = value;
      },

      handleCancel() {

      },
    }
  }
</script>

<style lang="scss" scoped>
  .ng-supplier-add {
    /deep/ .ant-form-item-label {
      width: 130px;
    }
  }

  .dynamic-delete-button {
    position: absolute;
    top: 50%;
    right: -0;
    transform: translate(120%, -50%);
    font-size: 24px;
    color: #999;
    transition: all 0.3s;
    cursor: pointer;
  }

  .upload-box {
    /deep/ {
      .ant-upload {
        width: 148px;
        height: 104px;
        margin-bottom: 0;
      }
    }
  }
</style>
