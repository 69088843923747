<template>
  <a-modal
    class="license-preview"
    type="confirm"
    width="480px"
    title="查看证件信息"
    okText="关闭"
    v-model="show"
    :maskClosable="false"
    @ok="handleOk"
  >
    <a-descriptions layout="horizontal" :column="1" bordered class="no-border">
      <a-descriptions-item label="姓名">{{data && data.name || '--'}}</a-descriptions-item>

      <a-descriptions-item label="证件号码">{{data.id_type | toLicenseName}} - {{data && data.id_number}}</a-descriptions-item>

      <a-descriptions-item label="证件照片">
        <div class="img-content">
          <ng-image :src="data && data.id_photo && `${data.id_photo}?x-oss-process=style/thumb_80`" />
        </div>
      </a-descriptions-item>

      <!-- <a-descriptions-item label="正面照">
        <div class="img-content">
          <ng-image :src="data && data.id_photos && `${data.id_photos[0]}?x-oss-process=style/thumb_80`" />
        </div>
      </a-descriptions-item>
      <a-descriptions-item label="反面照">
        <div class="img-content">
          <ng-image :src="data && data.id_photos && `${data.id_photos[1]}?x-oss-process=style/thumb_80`" />
        </div>
      </a-descriptions-item>-->
    </a-descriptions>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';

  export default {
    data() {
      return {
        show: false,
      }
    },

    mixins: [dialogMixins],

    props: {
      data: Object
    },

    methods: {
      handleOk() {
        this.show = false;
      },
    }
  }
</script>

<style lang="scss" scoped>
  .license-preview {
    vertical-align: top;

    .img-content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 303px;
      height: 198px;
      border: 1px solid #efefef;

      > img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    /deep/ .ant-modal-footer {
      .ant-btn:first-child {
        display: none;
      }
    }
  }
</style>
