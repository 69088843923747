<template>
  <main-structure class="ng-account-members" dir="ver">
    <template slot="header">
      <span class="title">优惠券详情</span>

      <div class="header-content">
        <!-- <a-button class="m-right-base" type="sub">配置折扣</a-button> -->
        <!-- <a-button type="sub" @click="$router.push('/musicmanage/bgm')">添加音乐</a-button> -->
      </div>
    </template>

    <template>

       <div class="m-top-ms flex-0">
        <ng-breadcrumb :data="breadData"></ng-breadcrumb>
      </div>

      <div class=" p-20">
        <ng-detail title="基础信息">
          <base-info :requestData="requestData" :couponInfo='couponInfo'></base-info>
        </ng-detail>
        
        <ng-detail class="m-top-md" title="">
          <div class="flex cen-space m-bottom-md">
            <filter-table @change="onFilterChange" :defaultData="$route.query" class="m-top-base"></filter-table>
            <div>  
              <a-button @click="onDownload()" icon="download"> 下载数据</a-button>
            </div>
          </div>
          <list :sid="$route.params.sid" :params='{coupon_id:$route.params.sid,...$route.query}'></list>
        </ng-detail>
      </div>
    </template>
  </main-structure>
</template>

<script>
  import BaseInfo from './detail/BaseInfo.vue'
  import List from './detail/List.vue'
  import FilterTable from './detail/FilterTable'
  import * as $config from '~/config/config.c';
  import $storage from '~/utils/storage'
  import * as $var from '~/utils/variables.c'

   const breadData = [
    {breadcrumbName: `优惠券`, path: `/eventManage/coupon`},
    {breadcrumbName: '详情'}
  ]
  let baseURL = $config.ServeBaseHost;

  export default {
    data() {
      return {
        breadData,
        data: {},
        requestData: {},
        couponInfo:{}
      }
    },
    components: {
      BaseInfo,
      List,
      FilterTable
    },
    created() {
      this.getData();
    },
    methods: {
      onFilterChange(values) {
       
      },
       onDownload() {
        let token =encodeURIComponent($storage.get($var.STORAGE.TOKEN));
        let url = `${baseURL}/bms/coupon/downCouponCode?token=${token}&coupon_id=${this.$route.params.sid}`
        window.open( url)
      },

      getData() {
        let param = {coupon_id: this.$route.params.sid};

        this.$axios.CouponStatistics(param).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.requestData = data.data;
          }
        })
        this.$axios.CouponInfo(param).then(({data})=>{
          if(data.code == 0){
            this.couponInfo = data.data
          }
          // let

        })
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
