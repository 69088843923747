<template>
  <a-tree
    :tree-data="tagsData"
    :expanded-keys="expandedKeys"
    blockNode
    :selectedKeys.sync="checkedTagsKeys"
    @select="onSelect"
    @expand="onExpand"
    :replaceFields="{ children: 'child', title: 'name', key: 'label_id' }"
  >
    <template slot="name" slot-scope="{ name }">
      <span v-if="~name.indexOf(searchValue)">
        <span>{{ name.substr(0, name.indexOf(searchValue)) }}</span>
        <span style="color: #fadb14">{{ searchValue }}</span>
        <span>{{
          name.substr(name.indexOf(searchValue) + searchValue.length)
        }}</span>
      </span>
      <span v-else>{{ name }}</span>
    </template>
  </a-tree>
</template>

<script>
  import {Tree} from 'ant-design-vue';
  import * as globalFunction from '@/utils/function';

  const getParentKey = (tree, key) => {
    let parentKeyArr = [];
    let children = 'child', title = 'name', keyId = 'label_id';

    tree.forEach(item => {
      if (item[children] && item[children].length > 0) {
        loop(item[children], key, [item[keyId]]);
      }
    })

    return [...(new Set(parentKeyArr))];

    function loop(data, value, parentKeys) {
      data.forEach(item => {
        if (item[children] && item[children].length > 0) {
          loop(item[children], value, parentKeys.concat(item[keyId]));
        }

        if (item[title] && ~item[title].indexOf(value)) {
          parentKeyArr = parentKeyArr.concat(parentKeys);
        }
      })
    }
  };

  export default {
    data() {
      return {
        tagsData: [],
        expandedKeys: [],
        autoExpandParent: true,
        checkedTagsKeys: []
      }
    },

    props: {
      data: {
        type: Array,
        default() {
          return []
        }
      },
      currType: String,
      searchValue: String
    },

    components: {
      ATree: Tree,
      // ADirectoryTree: Tree.DirectoryTree, ATreeNode: Tree.TreeNode
    },

    watch: {
      currType: {
        handler(newVal) {
          this.$emit('select', '')

          if (newVal) {
            this.setTagData();
            this.searchValue && this.setExpanded(this.searchValue);
          } else {
            this.clearData();
          }

          this.checkedTagsKeys = []
        }
      },

      data: {
        handler(newVal) {
          if (newVal && newVal.length > 0) {
            this.loadData(newVal);
          }
        },
        immediate: true,
      },

      searchValue(newVal) {
        if (newVal) {
          this.setExpanded(newVal);
        } else {
          this.expandedKeys = [];
        }
      },
    },

    methods: {
      reloadData() {
        this.loadData(this.data);
      },

      loadData(data) {
        if (data && data.length > 0) {
          this.getTagsDir(data);

          if (this._tagsChildrenDir.size > 0) {
            this.setTagData();
          }
        }
      },

      clearData() {
        this._tagsChildrenDir = new Map();
        this.tagsData = [];
        this.checkedTagsKeys = [];
        this.expandedKeys = [];
      },

      setTagData() {
        this.tagsData = this._tagsChildrenDir.get(this.currType) || [];
      },

      setExpanded(newVal) {
        let expandedKeys = getParentKey(this.tagsData, newVal);

        Object.assign(this, {
          expandedKeys,
          autoExpandParent: true,
        });
      },

      onExpand(expandedKeys) {
        this.expandedKeys = expandedKeys;
        this.autoExpandParent = false;
      },

      onSelect(value) {
        if (!value || value.length === 0) {
          this.checkedTagsKeys = this._copySelectValue;
          return false;
        }

        this.$emit('select', value[0] || '')

        this._copySelectValue = value;
      },

      getTagsDir(treeData) {
        if (!this._tagsChildrenDir) {
          this._tagsChildrenDir = new Map();
        }

        treeData.forEach(({cate_id, child}) => {
          this._tagsChildrenDir.set(cate_id, globalFunction.loopDeepArr(child, (item) => Object.assign({}, item, {scopedSlots: {title: 'name'}}), 'child'));
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
