const Banner = () => import('@/pages/home/Banner.vue');
const Topic = () => import('@/pages/home/Topic.vue');
const Playlist = () => import('@/pages/home/Playlist.vue');
export default [
  // 标注管理 start
  {
    path: '/home/banner',
    name: 'homeBanner',
    component: Banner,
  },
  {
    path: '/topic/list',
    name: 'topic',
    component: Topic,
  },
  {
    path: '/playlist',
    name: 'playlist',
    component: Playlist,
  },
]