<template>
  <main-structure class="ng-account-members" dir="ver">
    <template slot="header">
      <span class="title">供应商签约审核</span>
    </template>

    <template>
      <div class="m-top-base flex-1" ref="ngTableBox">
        <manage-table
          v-if="$root.$checkAuth($auth[name] && $auth[name].list)"
          :scrolly-height="mainContentScrollMaxHeight"
          :auth-name="name"
          @handle="onTableHandleSuccess"
          ref="ngTable"
        ></manage-table>
      </div>
    </template>
  </main-structure>
</template>

<script>
  import ManageTable from './library/ManageTable.vue';

  import {mainContentMixin} from '@/mixins';

  export default {
    data() {
      return {
        name: `verify_library`,

        showHandleDialog: false,
        showCoopHandleDialog: false,

        handleType: this.$var.Handle.add,

        handleData: {},

        isReloadTable: false,
      }
    },
    components: {
      ManageTable,
    },

    mixins: [
      mainContentMixin
    ],

    methods: {
      handleSuccess(isLoad) {
        this.showHandleDialog = false;
        this.showCoopHandleDialog = false;
        isLoad !== false ? this.$refs.ngTable.reloadTableData() : this.$refs.ngTable.loadTableData();
      },

      onTableHandleSuccess(scope) {
        let handle = scope.handleName;
        let varHandle = this.$var.Handle;

        if (handle === 'sign') {
          handle = (scope.op_sign == '0' ? varHandle.look : varHandle.add);

          this.goSign(handle, scope);
        }
      },

      goSign(key, scope) {
        this.handleData = scope || {};
        this.handleType = key;
        this.showCoopHandleDialog = true;
      },

      handleFunc(key, scope) {
        this.handleData = scope || {};
        this.handleType = key;
        this.showHandleDialog = true;
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
