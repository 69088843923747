<template>
  <a-modal
    width="640px"
    title="收益详情"
    v-model="show"
    :maskClosable="true"
    @ok="show = false;"
    :bodyStyle="bodyStyle"
  >

    <a-descriptions :column="1">
        <a-descriptions-item label="收益ID">{{info.earnings_id}}</a-descriptions-item>
        <a-descriptions-item label="产生时间">{{info.create_at}}</a-descriptions-item>
        <a-descriptions-item label="收益类型">{{info.earnings_type_str}}</a-descriptions-item>
        <a-descriptions-item label="售卖价格">￥{{info.sell_amount}}</a-descriptions-item>
        <a-descriptions-item label="分成金额">￥{{info.earnings_amount}}</a-descriptions-item>
        <a-descriptions-item label="音乐名称">{{info.music_name}}</a-descriptions-item>
        <a-descriptions-item label="音乐ID"> {{info.music_id}}</a-descriptions-item>
        <a-descriptions-item label="供应商ID"> {{info.scm_user_id}}</a-descriptions-item>
        <a-descriptions-item label="供应商名称">{{info.scm_name}}</a-descriptions-item>
        <a-descriptions-item label="被授权方">{{info.main}}</a-descriptions-item>
        <a-descriptions-item label="项目名称">{{info.project}}</a-descriptions-item>
        <a-descriptions-item label="授权范围">{{info.use_mode}}</a-descriptions-item>
        <a-descriptions-item label="授权地域">{{info.auth_area}}</a-descriptions-item>
        <a-descriptions-item label="授权期限">{{info.auth_time}}</a-descriptions-item>
    </a-descriptions>

  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  
  export default {
    data() {
      return {
        show: false,
        info: {}
      }
    },

    props: {
      earnings_id: String,
    },

    watch: {
      visible(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.getData(this.earnings_id);
          })
        }
      }
    },

    mixins: [dialogMixins],
    components: {
    },

    methods: {
      getData(earnings_id) {
        let params = {earnings_id};
        this.$axios.Earnings_Info(params).then(res => {
          const data = res.data;
          if (data && data.code == 0) {
            this.info = data.data ? data.data : {}
          }
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .info-list {
    line-height: 2.2;
  }
</style>
