/**
 * 订单管理
 */
import {
  Axios
} from '@/utils/from-common';

// 渠道订单列表
export const OrderManage_list = (param) => Axios('POST', '/bms/order/list', param);

// 渠道订单详情
export const OrderManage_info = (param) => Axios('POST', '/bms/order/info', param);

// 渠道订单 结算
export const OrderManage_settle = (param) => Axios('POST', '/bms/order/settle', param);

// 套餐订单列表
export const OrderPackage_list = (param) => Axios('POST', '/bms/goodsorder/list', param);

// 套餐订单详情
export const OrderPackage_info = (param) => Axios('POST', '/bms/goodsorder/info', param);

// 套餐订单 结算 公对公付款
export const OrderPackage_settle = (param) => Axios('POST', '/bms/goodsorder/settle', param);

// 订阅订单列表
export const VipManage_list = (param) => Axios('POST', '/bms/subscribe/list', param);

// 订阅订单详情
export const VipManage_info = (param) => Axios('POST', '/bms/subscribe/info', param);

//添加定制会员
export const Vip_add = (param) => Axios('POST', '/bms/subscribe/addVipOrder', param);

//定制会员结算 公对公付款
export const OrderVip_settle = (param) => Axios('POST', '/bms/subscribe/settleOrder', param);