
<template>
  <span>
    <a-tooltip
      v-if="hasLyric && scope.lyrics_text"
      overlayClassName="music-tooltip"
      placement="left"
      trigger="click"
    >
      <template v-if="typeof scope.lyrics_text === 'string'" slot="title">
        <span v-html="scope.lyrics_text.replace(/\n/g, '<br/>')"></span>
      </template>
      <a-button class="m-right-ms" type="link">歌词</a-button>
    </a-tooltip>
    <a-button v-else-if="hasLyric" disabled class="m-right-ms" type="link">歌词</a-button>

    <router-link class="m-right-ms" :to="{path: path, query: query}">修改</router-link>

    <a-dropdown>
      <a-button type="link" @click="e => e.preventDefault()">
        <a-icon type="more" :rotate="90" :style="{fontSize: '28px', verticalAlign: 'middle'}" />
      </a-button>
      <a-menu slot="overlay">
        <a-menu-item>
          <!-- <a-button class="m-right-ms" type="link">下载原版音频</a-button> -->
          <div class="btn-box">
            <a-button class="m-right-ms" type="link" @click="addToMusicList">加入歌单</a-button>
          </div>
        </a-menu-item>

        <a-menu-item>
          <!-- <a-button class="m-right-ms" type="link">下载原版音频</a-button> -->
          <div class="btn-box">
            <a
              :href="scope.origin_url"
              class="m-right-ms ant-btn ant-btn-link"
              :download="scope.original_name"
              target="_blank"
            >下载原版音频</a>
          </div>
        </a-menu-item>
        <a-menu-item>
          <div class="btn-box">
            <a
              :href="scope.audition_url"
              class="m-right-ms ant-btn ant-btn-link"
              :download="scope.name"
              target="_blank"
            >下载试听音频</a>
          </div>
          <!-- <a-button class="m-right-ms" type="link">下载试听音频</a-button> -->
        </a-menu-item>
        <a-menu-item>
          <a-button
            class="ant-btn-error"
            type="link"
            @click="onDelete"
            :disabled="scope.op_delete == 0"
          >删除</a-button>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </span>
</template>

<script>
  import Observe from '~/utils/observe.c.js'

  export default {
    data() {
      return {

      }
    },
    props: {
      scope: Object,
      path: String,
      query: Object,
      hasLyric: Boolean
    },
    components: {

    },
    methods: {
      addToMusicList() {
        Observe.publish('addMusicToSongList', this.scope);
      },

      onDelete() {
        let vm = this;
        let scope = this.scope;

        this.$confirm({
          width: '480px',
          title: '确认要删除该音乐吗？',
          // content: '删除该角色，关联该角色的成员账号，会失去对应的权限；',
          onOk() {
            return new Promise((resolve, reject) => {
              vm.deleteFunc(scope, resolve, reject);
            });
          }
        });
      },

      deleteFunc(scope, next, reject) {
        this.$axios.Music_del({music_id: scope.music_id}).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('已成功删除该音乐');
            next();

            this.$emit('delSuccess')
          } else {
            reject()
          }

        })
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>

