<template>
  <a-card title="标签名称" size="small" class="tags-type h-100 tag-card">
    <svg
      v-if="$root.$checkAuth($auth[authName] && $auth[authName].addTag)"
      class="iconfont extra-icon ant-button-icon"
      slot="extra"
      :disabled="!currType"
      @click="onClickExtra"
    >
      <use xlink:href="#icontianjiabiaoqian" />
    </svg>

    <div class="content main-card-content">
      <tags-sub-tree
        v-if="reloadContent"
        :data="data"
        :curr-type="currType"
        :search-value="searchValue"
        @select="onSelect"
        ref="subTree"
      ></tags-sub-tree>
    </div>
  </a-card>
</template>

<script>
  import TagsSubTree from './TagsSubTree.vue'

  export default {
    inject: ['authName'],
    data() {
      return {
      }
    },

    props: {
      data: {
        type: Array,
        default() {
          return []
        }
      },
      currType: String,
      searchValue: String,
      reloadContent: Boolean,
      currId: String,
    },

    components: {
      TagsSubTree
    },

    methods: {
      reloadData() {
        this.$refs.subTree.reloadData();
      },

      onClickExtra() {
        this.$emit('clickExtra', {
          key: 'tag',
          cateId: this.currType,
          tagId: this.currId
        })
      },

      onSelect(value) {
        this.$emit('update:currId', value)
      },
    }
  }
</script>

<style lang="scss" scoped>
  .tags-type {
    .extra-icon {
      position: absolute;
      top: 6px;
      right: 8px;
    }
  }
</style>
