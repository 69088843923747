/**
 * 计算主内容空间最大高度，添加滚动区域
 * 可以实现底部的一些内容，如分页等，可以不随滚动消失
 * 
 * 目前主要用来计算table外框的高度，实现table的scroll不能超出可视范围
 */
export default {
  data() {
    return {
      mainContentScrollMaxHeight: 0,
    }
  },

  mounted() {
    // 如果需要传入一些参数，那么先阻止执行，之后再传入参数
    if (!this.notInitCalcMaxHeight) {
      this.calcMainContentMaxHeight();
    }
  },

  methods: {
    /**
     * @param {*} mainContent 需要计算的元素
     * @param {*} needMinusBottomHeight 需要减去的底部高度 默认是底部分页的高度
     */
    calcMainContentMaxHeight(mainContent, needMinusBottomHeight = 120) {
      let pos;
      let clientHeight = document.body.clientHeight;

      mainContent = mainContent || this.$refs.ngTableBox;

      pos = mainContent.getBoundingClientRect();

      this.mainContentScrollMaxHeight = clientHeight - pos.top - (needMinusBottomHeight || 0);
    },
  }
}