<template>
  <a-form
    class="ng-verify-filter ng-filter"
    :form="form"
    :labelCol="{ span: 2 }"
    :wrapperCol="{ span: 19 }"
  >
    <a-form-item label="审核状态">
      <a-radio-group
        v-decorator="['check_status', { initialValue: defaultData.check_status || ''}]"
        :options="VerifyStatusOptions"
      ></a-radio-group>
    </a-form-item>

    <a-form-item label="审核结果">
      <a-radio-group
        v-decorator="['check_result', { initialValue: defaultData.check_result || ''}]"
        :options="VerifyResultOptions"
        :disabled="disabledResult"
      ></a-radio-group>
    </a-form-item>
  </a-form>
</template>

<script>
  import {VerifyStatusOptions, VerifyResultOptions} from '@/config/options'

  const allOptions = {label: '全部', value: ''};

  export default {
    data() {
      return {
        VerifyStatusOptions: [{...allOptions}, ...VerifyStatusOptions],
        VerifyResultOptions: [{...allOptions}, ...VerifyResultOptions],

        disabledResult: false,
      }
    },

    props: {
      defaultData: Object,
      statusDisabledResult: {
        type: String,
        default: '0'
      }
    },

    watch: {
      defaultData: {
        handler(newVal) {
          if (newVal && newVal.check_status === this.statusDisabledResult) {
            this.disabledResult = true;
          }
        },
        immediate: true
      }
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        onValuesChange: (props, values) => {
          let check_status = values.check_status;

          // 待审核
          if (check_status === '0') {
            this.disabledResult = true;
            this.form.resetFields(['check_result']);
            this.$root.$goSearch({check_result: ''});
          } else {
            this.disabledResult = false;
          }

          this.$root.$goSearch(values);

          this.$emit('change', values);
        }
      });
    },

    methods: {
    }
  }
</script>

<style lang="scss" scoped>
  .ng-filter {
    /deep/ {
      .ant-form-item-label {
        width: 100px;
      }

      .ant-form-item {
        margin-bottom: 0;
      }

      .ant-radio-wrapper {
        width: 80px;
      }
    }
  }
</style>
