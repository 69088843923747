<template>
  <div class="music-list">
    <template v-for="(item, index) in listData">
      <mark-music-list-item
        :item="item"
        :key="item.music_id"
        :index="index"
        :current="curr_index"
        :music_id="item.music_id"
        @delsuccess="onDelSuccess"
        @editsuccess="onEditSuccess"
      >
        <div slot="left" v-if="index === 0 && item.type !== $val.MusicType.YX" class="main-tip">
          <span class="tip-text">主</span>
        </div>
      </mark-music-list-item>
    </template>
  </div>
</template>

<script>
  // import {NgVideoTag} from '@/components/index'
  // import VueDraggable from "vuedraggable"
  import MarkMusicListItem from './MarkMusicListItem.vue';

  export default {
    data() {
      return {
        // list: arr
        curr_index: -1,
        listData: [],
      }
    },

    props: {
      list: Array
    },

    watch: {
      list: {
        handler(newVal) {
          this.listData = newVal;
          this.$store.commit('changeVideoList', newVal.map(item => Object.assign(item, {url: item.music_url})));
        },
        immediate: true
      }
    },

    components: {
      // NgVideoTag
      MarkMusicListItem
    },
    methods: {
      onDelSuccess(music_id) {
        this.listData = this.listData.filter(item => item.music_id !== music_id);

        if (this.listData && this.listData.length === 0) {
          this.$emit('empty')
        }
      },

      onEditSuccess({music_id, value}) {
        this.listData = this.listData.map(item => item.music_id !== music_id ? item : Object.assign({}, item, {name: value}))
      },

      onChange() {

      }
    }
  }
</script>

<style lang="scss" scoped>
  .music-list {
    .video-tag-s {
      padding-left: 30px;
    }
  }
</style>
