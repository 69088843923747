import {
  toOptionName
} from '@/utils/function';
import * as AllOptions from '@/config/options';
import * as $config from '@/config/config';

const enterStatusDir = [{
    label: '未开始',
    value: '0',
    color: '#000000',
  },
  {
    label: '进行中',
    value: '1',
    color: '#FF4D4F'
  },
  {
    label: '已完成',
    value: '2',
    color: ''
  }
]

// 全局函数

export default {
  install(Vue) {

    // 任务优先级
    Vue.filter('toLevelName', (value) => {
      let options = AllOptions.LevelOptions;
      let final = options.filter(item => value == item.value);

      return final && final.length > 0 ? `<span style="color: ${final[0].color}">${final[0].label}</span>` : $config.emptyText;
    })

    // 任务优先级
    Vue.filter('enterLibraryStatus', (value) => {
      return toOptionName(enterStatusDir, value);
    })

    // 账户来源
    Vue.filter('toAccountOriginCnName', (value) => {
      return toOptionName(AllOptions.AccountOrigin, value);
    })

    // 服务进度
    Vue.filter('toServerProgressName', (value) => {
      return toOptionName(AllOptions.ServerProgress, value);
    })

    // 音乐类型
    Vue.filter('toMusicType', (value) => {
      return toOptionName(AllOptions.MusicTypeOptions, value);
    })

    // 音效
    Vue.filter('toMusicEffect', (value) => {
      return toOptionName(AllOptions.MusicEffectOptions, value);
    })

    // 标注状态
    Vue.filter('toMarkStatus', (value) => {
      return toOptionName(AllOptions.MarkResultOptions, value);
    })

    // 支付方式
    Vue.filter('toPayWay', (value) => {
      return toOptionName(AllOptions.PayWaysOptions, value);
    })

    // 授权地域
    Vue.filter('toArea', (value) => {
      return toOptionName(AllOptions.AuthAreaOptions, value);
    })

    // 授权期限
    Vue.filter('toAuthPeriod', (value) => {
      return toOptionName(AllOptions.AuthTimeOptions, value);
    })

    // 使用场景
    Vue.filter('toAuthScene', (value) => {
      return toOptionName(AllOptions.BusinessSceneOptions, value);
    })

    // 整数转为小数
    Vue.filter('toDecimals', (value) => {
      value = value + '';
      
      if (value && !~value.indexOf('.')) {
        return (value / 100).toFixed(2);
      }

      return value;
    })
  }
}