<template>
  <main-structure class="ng-verify-detail ng-detail" dir="ver" :loading="loading">
    <template slot="header">
      <ng-breadcrumb :data="breadData"></ng-breadcrumb>

      <div class="header-content">
        <a-input-search
          class="ng-input-search"
          placeholder="请输入订单号"
          :default-value="$route.query.order_no || ''"
          @search="onSearch"
          allowClear
          style="width: 350px;"
        >
          <template v-slot:enterButton>
            <a-button type="sub">
              <a-icon type="search" />
            </a-button>
          </template>
        </a-input-search>
      </div>
    </template>
    <div class :key="curTabId">
      <a-tabs v-model="currentTabIndex" :default-active-key="$val.OrderStatus.undoPay">
        <a-tab-pane :key="$val.OrderStatus.didPay" tab="已支付">
          <pay-order :type="$val.OrderStatus.didPay" :params="tableParams"></pay-order>
        </a-tab-pane>

        <a-tab-pane :key="$val.OrderStatus.undoPay" tab="未支付">
          <pay-order :type="$val.OrderStatus.undoPay" :params="tableParams"></pay-order>
        </a-tab-pane>
      </a-tabs>
    </div>
  </main-structure>
</template>

<script>
  import {NgBreadcrumb} from '@/components/index';

  const breadData = [
    {breadcrumbName: `用户管理`, path: `/user/manage`},
    {breadcrumbName: `用户订单`}
  ]

  export default {
    data() {
      return {
        name: `user_order`,
        breadData,
        user_id: '0',
        loading: false,
        currentTabIndex: this.$val.OrderStatus.didPay,

        reload: true,

        curTabId: 0,

        tableParams: {}
      }
    },
    components: {
      NgBreadcrumb,
      PayOrder: () => import('./order/PayOrder.vue'),
    },

    created() {
      let user_id = this.$route.params.user_id || '0';

      this.user_id = user_id;
      
      let {page = 1, order_no} = this.$route.query;

      let params = {page, order_no};

      this.tableParams = params;
    },

    methods: {
      onSearch(order_no) {
        typeof order_no === 'string' && (order_no = order_no.trim());

        this.$root.$goSearch({order_no});

        this.tableParams = Object.assign({}, this.tableParams, {order_no});
      },

      handleFunc() {

      },

      reloadTab() {
        this.curTabId++;
      },
    }
  }
</script>

<style lang="scss" scoped>
  .ng-verify-detail {
    .tab-box {
      padding: 0 20px;

      /deep/ .ant-tabs-content {
        // padding: 40px 0;
      }
    }
  }
</style>
