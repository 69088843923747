/**
 * 账号权限管理
 */
import {
  Axios
} from '@/utils/from-common';

// 标签列表
export const Tags_list = (param) => Axios('POST', '/bms/label/list', param);

// 添加类别
export const Tags_cgy_add = (param) => Axios('POST', '/bms/label/addCate', param);

// 编辑类别
export const Tags_cgy_edit = (param) => Axios('POST', '/bms/label/saveCate', param);

// 删除类别
export const Tags_cgy_del = (param) => Axios('POST', '/bms/label/delCate', param);

// 类别详情
export const Tags_cgy_info = (param) => Axios('POST', '/bms/label/cateInfo', param);

// 修改类别状态
export const Tags_cgy_status = (param) => Axios('POST', '/bms/label/upCateState', param);

// 添加标签
export const Tags_add = (param) => Axios('POST', '/bms/label/addLabel', param);

// 编辑标签
export const Tags_edit = (param) => Axios('POST', '/bms/label/saveLabel', param);

// 标签详情
export const Tags_info = (param) => Axios('POST', '/bms/label/labelInfo', param);

// 删除标签
export const Tags_del = (param) => Axios('POST', '/bms/label/delLabel', param);