<template>
  <div>
    <!-- <div
      slot="right"
      class="flex cen-start add-btn font-primary"
      @click="onHandle($var.Handle.add)"
    >
      <span class="font-sm">添加收件信息</span>
    </div> -->

    <a-spin :spinning="spinning" class="w-100">
      <!-- <Detail :list="list" @onEdit="onEditHandle" @success="onSuccess"></Detail> -->
      <Detail :list="list"></Detail>
    </a-spin>

    <!-- <dialog-add
      :default-value="dialogFormDefault"
      :visible.sync="showHandleDialog"
      :handle="handleType"
      @success="onSuccess"
    ></dialog-add>-->
  </div>
</template>

<script>
  // import {CommonPage} from '@/components/index';
  import Detail from "./components/Detail.vue";
  // import DialogAdd from './components/DialogAdd.vue'

  export default {
    data() {
      return {
        title: "收件信息",

        showHandleDialog: false,
        dialogFormDefault: {},
        handleType: this.$var.Handle.add,

        list: [],
        spinning: false,
      };
    },

    props: {
      user_id: String,
    },

    created() {
      this.getData({user_id: this.user_id});
    },

    components: {
      // CommonPage,
      Detail,
      // DialogAdd,
    },

    methods: {
      onSuccess() {
        this.getData();
      },

      // onEditHandle(item) {
      //   item = Object.assign({}, item, {
      //     is_default: !!+item.is_default
      //   })

      //   this.dialogFormDefault = item;

      //   this.onHandle(this.$var.Handle.edit);
      // },

      getData(params) {
        this.spinning = true;

        this.$axios.User_Address_list(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            // something
            this.list = data.data.list.map(item => Object.assign(item, {
              phone_code: item.phone_code.id,
              phone_code_item: item.phone_code,
            }));
          }

          this.spinning = false;
        })
      },

      // onHandle(type) {
      //   this.handleType = type;
      //   this.showHandleDialog = true;
      // },
    }
  };
</script>

<style lang="scss" scoped>
  .add-btn {
    cursor: pointer;

    .iconfont {
      width: 20px;
      height: 20px;
      margin-right: 2px;
    }
  }
</style>
