<template>
  <main-structure class="ng-account-detail" dir="ver" :loading="loading">
    <template slot="header">
      <ng-breadcrumb :data="breadData"></ng-breadcrumb>
    </template>

    <template>
      <div class="dept-info">
        <h4 class="content-title">基础信息</h4>
        <table class="content table-layout" style="margin-top: -10px">
          <tr v-for="item in contentKeys" :key="item.key">
            <td>{{item.name}}</td>
            <td>{{data[item.key] || '-'}}</td>
          </tr>
        </table>
      </div>

      <div class="m-top-base flex-1" ref="ngTableBox">
        <h4 class="content-title dept-members">
          部门成员
          <a-badge :count="data.dept_user_num || 0" :number-style="{ backgroundColor: '#1890ff' }" />
        </h4>
        <a-table
          class="ng-table"
          :columns="columns"
          :data-source="data.user_list"
          :rowKey="row => row.uid"
          :scroll="{y: mainContentScrollMaxHeight}"
        >
          <template slot="phone" slot-scope="text, scope">
            <span v-if="text">{{`+${scope.phone_code.prefix} ${text}`}}</span>
          </template>

          <template slot="status" slot-scope="text, scope">
            <table-switch
              v-if="$root.$checkAuth($auth.account_member.status)"
              :options="memberStatusOptions"
              :default-value="parseInt(text)"
              :change="function() { onChangeSwitch(...arguments, scope) }"
            ></table-switch>
            <span
              v-else
              :class="text == 0 ? 'font-error' : 'font-success'"
            >{{$root.$getItemWithKey(memberStatusOptions, 'id', text).name || ''}}</span>
          </template>
        </a-table>
      </div>
    </template>

    <div class="op-btns-box">
      <a-button
        type="sub"
        size="large"
        style="width: 100px;float:right"
        @click="showAddMembersDialog = true;"
      >编辑</a-button>
    </div>

    <add-department
      :visible.sync="showAddMembersDialog"
      :handle="handleType"
      :data="handleData"
      @success="handleSuccess"
    ></add-department>
  </main-structure>
</template>

<script>
  import {NgBreadcrumb} from '@/components/index';

  import {Badge as ABadge, Table as ATable} from 'ant-design-vue';

  import {OpenAndCloseStatus} from '@/config/options'

  import {mainContentMixin} from '@/mixins';

  const columns = [
    {
      title: 'ID',
      // dataIndex: 'uid',
      customRender(text, record, index) {
        return index + 1;
      },
      width: '100px'
    },
    {
      title: '姓名',
      dataIndex: 'name',
    },
    {
      title: '电话',
      dataIndex: 'phone',
      scopedSlots: {customRender: 'phone'},
    },
    {
      title: '子部门',
      dataIndex: 'child_dept',
    },
    {
      title: '角色',
      dataIndex: 'user_list',
    },
    {
      title: '状态',
      dataIndex: 'is_enabled',
      scopedSlots: {customRender: 'status'},
    },
  ];

  const breadData = function () {
    return [
      {breadcrumbName: '部门角色管理', path: '/account/department'},
      {breadcrumbName: '部门详情'}
    ]
  }

  const contentKeys = [
    {key: 'name', name: '部门名称'},
    {key: 'dept_user', name: '部门负责人'},
    {key: 'desc', name: '部门描述'},
    {key: 'child_dept', name: '子部门'},
  ]

  export default {
    data() {
      return {
        columns,
        data: [],
        loading: false,
        memberStatusOptions: OpenAndCloseStatus,
        showAddMembersDialog: false,

        breadData: breadData(),
        contentKeys,

        handleType: this.$var.Handle.edit,
        handleData: {},
      }
    },
    components: {
      NgBreadcrumb,
      ABadge,
      ATable,
      AddDepartment: () => import('./dialog/AddDepartment')
    },

    mounted() {
      this.param = {dept_id: this.$route.params.dept_id || 0};

      this.getData(this.param);
    },

    mixins: [
      mainContentMixin
    ],

    methods: {

      handleSuccess() {
        this.getData(this.param);
      },

      onChangeSwitch(key, options, next, loading, scope) {
        loading(true);

        let param = {
          uid: scope.uid,
          is_enabled: key
        }

        this.$axios.Account_membersStatus(param).then(res => {
          let data = res.data;

          loading(false);

          if (data && data.code == 0) {
            next();
            key == 1 ? this.$message.success('已启用') : this.$message.error('已停用');

            this.data = this.$changeDataItem(this.data, scope.uid, item => Object.assign({}, item, {
              is_enabled: key
            }));
          }
        })
      },

      getData(param) {
        this.loading = true;

        this.$axios.Account_department_info(param).then(res => {
          let data = res.data;

          if (data && data.code == 0) {
            this.data = data.data ? data.data : [];

            this.handleData = this.data;
          }

          this.loading = false;
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .ng-account-detail {
    .content-title {
      font-weight: $font-weight-bold;
      margin: 20px 0 30px;
    }

    .dept-members {
      display: flex;
      flex-direction: row;
      align-items: center;

      > span {
        margin-left: 10px;
      }
    }
  }
</style>
