<template>
  <div class="detail-main flex flex-column cen-start">
    <div class="user-img">
      <ng-image :src="data.profile_photo_url" :default-value="$var.defaultHeader" />
    </div>
    <div class="detail">
      <template v-for="(item, index) in formList">
        <div v-if="item.key === 'address_id'" class="flex cen-start m-top-base" :key="index">
          <span class="left flex cen-end">{{item.name}}</span>
          <span class="right">{{data.address}}</span>
        </div>
        <div v-else-if="item.key === 'sex'" class="flex cen-start m-top-base" :key="index">
          <span class="left flex cen-end">{{item.name}}</span>
          <span class="right">{{data[item.key] | toSexName}}</span>
        </div>
        <div v-else-if="item.key !== 'profile_photo'" class="flex cen-start m-top-base" :key="index">
          <span class="left flex cen-end">{{item.name}}</span>
          <span class="right">{{data[item.key] || '--'}}</span>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  import FormDir from './keyDir'

  export default {
    data() {
      return {
        // data: {}
      }
    },

    computed: {
      formList() {
        return FormDir[this.type] || [];
      }
    },

    props: {
      data: Object,
      type: String
    },

    created() {
      // this.getData();
    },

    methods: {

    }
  }
</script>
<style lang="scss" scoped>
  .detail-main {
    width: 100%;
    .user-img {
      position: relative;
      border-radius: 50%;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);

      img {
        display: inline-block;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.04);
      }

      span {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 12px;
        font-weight: 300;
        color: rgba(0, 0, 0, 0.65);
        text-align: center;
      }
    }
    .detail {
      width: 100%;
      padding: 0 80px 0 0;
      .left {
        flex: 0 0 136px;
        font-size: 13px;
        font-weight: 300;
        color: rgba(84, 91, 102, 1);
      }
      .right {
        flex: 1;
        margin-left: 20px;
        font-size: 14px;
        font-weight: 300;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
</style>