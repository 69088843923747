<template>
  <a-modal
    :title="title"
    :width="$config.DialogWidth"
    v-model="show"
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form
      :form="form"
      layout="horizontal"
      :wrapper-col="{ xs: {span: 18, offset: 3}}"
      class="ng-form m-top-base"
    >
      <!-- 电话 -->
      <a-form-item v-if="type === 'phone'" class="m-bottom-0">
        <a-form-item style="display: inline-block; width: calc(100% - 300px); margin-right: 20px">
          <form-phone-area
            show-type="both"
            v-decorator="['phone_code', { rules: [$validate.required], initialValue: $config.PhoneCodeDefault}]"
          ></form-phone-area>
        </a-form-item>

        <a-form-item :style="{display: 'inline-block', width: '280px'}">
          <a-input
            type="number"
            v-decorator="['phone', { rules: [$validate.required, $validate.phone], initialValue: '', trigger: 'blur' }]"
            :placeholder="type === $var.Handle.add ? '请输入手机号码' : '请输入新手机号码'"
          />
        </a-form-item>
      </a-form-item>

      <!-- 邮箱 -->
      <a-form-item v-else>
        <a-input
          v-decorator="['email', { rules: [$validate.required, $validate.email], initialValue: '', trigger: 'blur' }]"
          :placeholder="type === $var.Handle.add ? '请输入邮箱' : '请输入新邮箱'"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import {FormPhoneArea} from '@/components/index';

  export default {
    data() {
      return {
        show: false,

        confirmLoading: false,

        defaultData: {},

        identifyCodeParam: {},

        showSuccessTip: false,
      }
    },

    props: {
      user_id: String,
      // phone email
      type: {
        type: String,
        default: 'phone'
      },
      handle: String,
      // options: Object,

      defaultValue: {
        type: Object,
        default() {
          return {}
        }
      },
    },

    computed: {
      isEdit() {
        return this.handle === this.$var.Handle.edit;
      },

      title() {
        let dir = {
          phone: {
            add: '绑定手机号',
            edit: '变更手机号'
          },
          email: {
            add: '绑定邮箱',
            edit: '变更邮箱'
          }
        }

        return dir[this.type || 'phone'][this.handle];
      },

      sendSuccessTips() {
        let dir = {
          phone: '已发送短信验证码到绑定手机',
          email: '已发送验证码到绑定邮箱'
        }

        return dir[this.type || 'phone'];
      }
    },

    watch: {
      visible(newVal) {
        if (newVal && this.form) {

          this.$nextTick(() => {
            this.form.resetFields();

            this.isEdit && this.form.setFieldsValue(this.defaultValue);
          })
        }
      },
    },

    mixins: [dialogMixins],

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        name: 'linkman'
      })
    },

    components: {
      FormPhoneArea
    },

    methods: {
      // getParam(values) {
      //   let param = {};

      //   param[this.type] = values[this.type];

      //   return param;
      // },

      // onClickSend(next) {
      //   this.form.validateFields(['phone', 'email'], (errors, values) => {

      //     if (!errors) {
      //       next(this.getParam(values));
      //     }
      //   })
      // },

      // onSendSuccess(data) {
      //   if (data && data.code == 0) {
      //     this.showSuccessTip = true;
      //   }
      // },

      // onCountDownFinished() {
      //   this.showSuccessTip = false;
      // },

      handleOk() {
        this.form.validateFieldsAndScroll((errors, values) => {

          if (!errors) {
            this.onSubmit(values).then(() => {
              this.$emit('success', values);
              this.$emit('update:visible', false);
            })
          }
        })
      },

      // Address_add  Address_edit
      onSubmit(param) {
        let url = this.type === 'phone' ? 'Sup_Account_editPhone' : 'Sup_Account_editEmail';

        this.confirmLoading = true;

        param.user_id = this.user_id;

        return new Promise(resolve => {
          this.$axios[url] && this.$axios[url](param).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              resolve(data);
              this.$message.success('修改成功');
            }

            this.confirmLoading = false;
          }).catch(() => {
            this.confirmLoading = false;
          })
        })
      },

      handleCancel() {

      },
    }
  }
</script>

<style lang="scss" scoped>
  .success-tip {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -56px;
  }
</style>
