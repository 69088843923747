import {
  SupplierType
} from '@/utils/variables'

const Supplier = () => import('@/pages/supplier/supplier.vue');
const SupplierDetail = () => import('@/pages/supplier/supplierDetail.vue');
const SupplierContract = () => import('@/pages/supplier/SupplierContract.vue');
// const SupplierAdd = () => import('@/pages/supplier/supplierAdd.vue');

export default [{
    path: '/supplier/musician',
    name: 'supplierMusician',
    meta: {
      type: SupplierType.MUSICIAN
    },
    component: Supplier,
  },
  {
    path: '/supplier/team',
    name: 'supplierTeam',
    meta: {
      type: SupplierType.TEAM
    },
    component: Supplier,
  },
  {
    path: '/supplier/company',
    name: 'supplierCompany',
    meta: {
      type: SupplierType.COMPANY
    },
    component: Supplier,
  },
  {
    path: '/supplier/:type/detail/:user_id/:handle', // type 供应商类型 user_id 供应商id  handle 操作类型 编辑，新增
    name: 'supplierDetail',
    component: SupplierDetail
  },
  {
    path: '/supplier/contract/:type/:user_id', // type 供应商类型 user_id 供应商id
    name: 'SupplierContract',
    component: SupplierContract
  },
]