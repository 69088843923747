<template>
  <a-modal
    width="600px"
    v-model="show"
    title="开通套餐"
    destroyOnClose
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form
      class="w-100 ng-form"
      :form="form"
      hideRequiredMark
      :label-col="{ span: 4}"
      :wrapper-col="{ span: 18 }"
    >
      <a-form-item label="套餐类别">
        <a-select
          :options="packageCateOptions"
          v-decorator="[`cate_id`, {rules: [$validate.required] }]"
          placeholder="请选择套餐类别"
          :loading="packageCateLoading"
        ></a-select>
      </a-form-item>

      <a-form-item label="套餐名称">
        <a-select
          :options="packageOptions"
          v-decorator="[`goods_id`, {rules: [$validate.required]}]"
          placeholder="选择要开通的套餐"
          :loading="packageLoading"
        ></a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import {AuthAreaOptions, AuthTimeOptions, BusinessSceneOptions} from '@/config/options';
  import {toOptionName} from '@/utils/function';

  export default {
    data() {
      return {
        show: false,
        confirmLoading: false,

        packageCateOptions: [],
        packageOptions: [],

        packageLoading: false,
        packageCateLoading: false,
      }
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        onValuesChange: (props, values) => {
          if (values.cate_id) {
            this.getPackageList(values.cate_id);
          }
        }
      });
    },

    props: {
      user_id: String
    },

    created() {
      this.getGoodType();
    },

    mixins: [dialogMixins],

    methods: {
      handleOk() {
        this.form.validateFieldsAndScroll((errors, values) => {
          if (!errors) {
            this.submit(values);
          }
        })
      },

      submit(params) {
        // let goods_id = params.goods_id;
        // let list = this.packList;

        // list.filter(item => item.status > 0).some(item => {
        //   if (item.goods_id === goods_id) {
        //     let {auth_area, auth_time, auth_scene, use_mode} = item;

        //     params = Object.assign({}, params, {auth_area, auth_time, auth_scene, use_mode})

        //     return true;
        //   }
        // })

        this.confirmLoading = true;

        params.user_id = this.user_id;

        this.$axios.Package_add(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('添加成功')
            this.show = false;
            this.$emit('success');
          }

          this.confirmLoading = false;
        }).catch(() => {
          this.confirmLoading = false;
        })
      },

      getPackageList(cate_id) {
        let params = {cate_id};

        this.packageLoading = true;

        this.$axios.Good_list(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            let list = data.data ? data.data.list : [];

            list = list.filter(item => item.status > 0);

            this.packList = list;

            this.packageOptions = list.map(({name, goods_id, auth_area, auth_time, use_mode, auth_scene, auth_num}) => {
              auth_area = toOptionName(AuthAreaOptions, auth_area);
              auth_time = toOptionName(AuthTimeOptions, auth_time);
              auth_scene = toOptionName(BusinessSceneOptions, auth_scene);

              return {value: goods_id, label: `${name}-${auth_scene}-${use_mode}-${auth_area}-${auth_time}-${auth_num}`};
            });
          }

          this.packageLoading = false;
        }).catch(() => {
          this.packageLoading = false;
        })
      },

      getGoodType() {
        let params = {};

        this.packageCateLoading = true;

        return new Promise(resolve => {
          this.$axios.Good_categ_list(params).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              let list = data.data ? data.data.list : [];
              this.packageCateOptions = list.map(({name, cate_id}) => ({value: cate_id, label: name}));
            }

            resolve(data)

            this.packageCateLoading = false;
          }).catch(() => {
            this.packageCateLoading = false;
          })
        })
      },

      handleCancel() {
        this.show = false;
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
