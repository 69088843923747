const User = () => import('@/pages/user/User.vue');
const UserDetail = () => import('@/pages/user/UserDetail.vue');
const UserOrder = () => import('@/pages/user/UserOrder.vue');
const UserAuth = () => import('@/pages/user/auth.vue');
const UserDeeds = () => import('@/pages/user/UserDeeds.vue');
const UserPackage = () => import('@/pages/user/UserPackage.vue');

export default [
  {
    path: '/user/manage',
    name: 'userManage',
    component: User,
  },
  {
    path: '/user/auth',
    name: 'userAuth',
    component: UserAuth,
  },
  {
    path: '/user/detail/:user_id',
    name: 'userDetail',
    component: UserDetail,
  },
  {
    path: '/user/order/:user_id',
    name: 'userOrder',
    component: UserOrder,
  },
  {
    path: '/user/deeds/:user_id',
    name: 'userDeeds',
    component: UserDeeds,
  },
  {
    path: '/user/package/:user_id',
    name: 'userPackage',
    component: UserPackage,
  },
]