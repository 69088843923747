<template>
  <div class="h-100 mark-music-box">
    <ng-table
      class="ng-table mark-music-table"
      :columns="columns"
      :request="$axios.MusicManage_list"
      :params="params"
      :rowKey="row => row.music_id"
      :load.sync="loadTable"
      :reload.sync="reloadTable"
      @dataChange="(data) => {onTableDataChange(data, {url: 'origin_url'})}"
      :transformData="(data) => onHandleDataFunc(data, 'music_id')"
      :rowClassName="(r, i) => i % 2 ? 'even-row' : 'odd-row'"
      :scroll="{y: scrollyHeight, x: 1950}"
    ></ng-table>
  </div>
</template>

<script>
  import TagsShow from '../components/TagsShow.vue';
  import Operation from './Operation.vue'
  import MusicTableMixins from '../musicTableMixins'
  import * as TabCol from '@/config/columns';
  import * as AllOptions from '@/config/options';
  // import * as AllFunc from '@/utils/function';
  import {tableMixins} from '@/mixins';
  import {toOptionName} from '@/utils/function';
  import {MusicTypeText} from '@/utils/variables'
  import {MusicType} from '@/config/options.value';
  import _mixins from './_mixins';

  export default {
    data() {
      let that = this;
      let {onClickMusic, onChangeSwitch, onSuccess, onBlurWeight} = this;

      const filterEle = (label, text, scope) => {
        let toPath = '', toQuery = {};
        let options = [...AllOptions.SellStatusOptions];

        switch (label) {
          case 'sound_type':
            return <span>{toOptionName(AllOptions.MusicTypeOptions, text)}</span>
          case 'coop_date':
            return <span>{scope.coop_start_date} -<br /> {scope.coop_end_date}</span>
          case 'auth_type':
            return <span>{toOptionName(AllOptions.AuthTypeOptions, text)}</span>
          case 'auth_area':
            return <span>{toOptionName(AllOptions.AuthAreaOptions, text)}</span>
          case 'auth_scene':
            text = text || [];
            return text.map(item => (
              <div>
                <span>{item.name}：</span>
                <span>{item.ratio ? `${item.ratio}%` : this.$config.emptyText}</span>
              </div>
            ))
          case 'status':
            if (text > 1) options.shift();
            return <table-switch
              options={text >= 0 ? options.filter(item => item.value >= 0) : options}
              default-value={parseInt(text) || 0}
              format={{id: 'value', name: 'label'}}
              change={(...arr) => {onChangeSwitch(scope, ...arr)}}
            ></table-switch>
          case 'sort':
            return (
              <a-input-number default-value={scope.sort} on={{blur: (event) => {onBlurWeight(scope, event.target.value)}}} style="width: 100px;" />
            )
          case 'operation':
            toPath = `/markmusic/musicmanage`;
            toQuery = {from: this.$var.Common.fourth, music_id: scope.music_id, mtype: this.type};

            return <Operation
              scope={scope}
              query={toQuery}
              path={toPath}
              hasLyric={this.type === MusicTypeText[MusicType.RS]}
              onDelSuccess={onSuccess}
            ></Operation>
          default:
            return text;
        }
      }

      const renderContent = (value, row, index, item) => {
        const obj = {children: value, attrs: {}};

        (index % 2) && (obj.attrs.colSpan = 0);

        obj.children = filterEle(item.key, value, row)

        return obj;
      };

      const renderFixed = (value, row, index, item) => {
        const obj = {children: value, attrs: {}};

        obj.children = index % 2 ? <td></td> : filterEle(item.key, value, row)

        return obj;
      };

      const renderName = (text, scope) => {
        return <a href="javascript:;" title={text} onclick={() => {onClickMusic.call(that, scope)}}>{text}</a>
      }

      const tagshow = (data) => {
        // if (data.status === '0') {
        //   let reason = AllFunc.getItemWithValue(AllOptions.MarkRejectReason, 'value', data.reject_reason, 'child');
        //   return <span class="font-red">拒绝原因：{reason ? reason.label : ''}</span>
        // } else {
        //   return <TagsShow data={data.labels}></TagsShow>
        // }
        return <TagsShow data={data.labels}></TagsShow>
      }

      const columns = [
        {
          dataIndex: 'ID',
          width: 50,
          fixed: 'left',
          customRender(value, row, index) {
            let children = index % 2 ? '.' : (Math.floor(index / 2) + 1)
            return {children};
          },
        },
        {
          dataIndex: 'name',
          title: '音乐名称',
          width: 150,
          ellipsis: true,
          customRender(value, row, index) {
            let colSpan = index % 2 ? 16 : 1;

            let children = index % 2 ? tagshow.call(this, row) : renderName(value, row);

            return {attrs: {colSpan}, children};
          },
        },
        TabCol.commonKey('音乐原名', 'original_name', {ellipsis: true, isSlot: true, width: 100, customRender: renderContent.bind(this)}),
        TabCol.commonKey('所属曲库', 'lib_name', {isSlot: true, width: 100, customRender: renderContent.bind(this)}),
        TabCol.commonKey('所属供应商', 'suplr_name', {ellipsis: true, isSlot: true, width: 150, customRender: renderContent.bind(this)}),
        TabCol.commonKey('音效类型', 'sound_type', {ellipsis: true, isSlot: true, width: 100, customRender: renderContent.bind(this)}),
        TabCol.commonKey('音效描述', 'sound_desc', {ellipsis: true, width: 200, customRender: renderContent.bind(this)}),

        TabCol.commonKey('合作期限', 'coop_date', {isSlot: true, width: 100, customRender: renderContent.bind(this)}),
        TabCol.commonKey('授权地域', 'auth_area', {isSlot: true, width: 100, customRender: renderContent.bind(this)}),
        TabCol.commonKey('授权类型', 'auth_type', {isSlot: true, width: 100, customRender: renderContent.bind(this)}),
        TabCol.commonKey('授权场景&分成比例', 'auth_scene', {isSlot: true, width: 200, customRender: renderContent.bind(this)}),

        TabCol.commonKey('时长(s)', 'duration', {isSlot: true, width: 100, customRender: renderContent.bind(this)}),
        TabCol.commonKey('文件大小(M)', 'size', {isSlot: true, width: 120, customRender: renderContent.bind(this)}),
        TabCol.commonKey('入库时间', 'create_at', {isSlot: true, width: 200, customRender: renderContent.bind(this)}),

        TabCol.commonKey('状态', 'status', {isSlot: true, width: 120, fixed: 'right', customRender: renderFixed.bind(this)}),
        TabCol.commonKey('排序', 'sort', {isSlot: true, width: 120, fixed: 'right', customRender: renderFixed.bind(this)}),
        TabCol.commonKey('操作', 'operation', {isSlot: true, width: 100, fixed: 'right', customRender: renderFixed.bind(this), }),
      ]

      return {
        columns,
        // requestParams: {},
        showDialog: false,
        currentData: {},
      }
    },

    props: {
      authName: String,
      scrollyHeight: {
        type: Number,
        default: 400
      },
      from: String,
      params: Object,
      type: String
    },

    // watch: {
    //   params: {
    //     handler(newVal) {
    //       this.requestParams = Object.assign({}, this.requestParams, newVal, {page: 1})
    //     },
    //   },
    // },

    components: {
    },

    // mounted() {
    //   let {query} = this.$route;

    //   query = {...query};

    //   query = initParams(query, this.$route.meta.type);

    //   this.requestParams = Object.assign({}, query, {
    //     page: query.page || 1
    //   });
    // },

    mixins: [
      tableMixins, MusicTableMixins, _mixins
    ],

    methods: {
      // initParams() {
      //   let {query} = this.$route;

      //   query = {...query};


      //   Object.entries(query).forEach(([key, [min, max]]) => {
      //     query[`${key}[min]`] = min || '';
      //     query[`${key}[max]`] = max || '';

      //     delete query[key];
      //   })

      //   query.type = this.$route.meta.type;

      //   this.requestParams = Object.assign({}, query, {page: query.page || 1});
      // },
    }
  }
</script>

<style lang="scss" scoped>
  .mark-music-box {
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #dddddd;
    }
  }
</style>
