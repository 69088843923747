/**
 * 全局业务方法
 */
import {
  Handle
} from '@/utils/variables'
import {
  getItemWithKey
} from '@/utils/function'

/**
 * 根据不同操作获取对应文本
 * options.add 可以是函数
 * 
 * @param {*} handle 'add'/'edit'
 * @param {*} options {add: '新增xxx', edit: '编辑xxx'}
 * @return add => '新增xxx'
 */
export const $getContentAccHandle = function (handle, options) {
  let addItem = options.add;

  switch (handle) {
    case Handle.add:
      if (typeof addItem === 'function') {
        return addItem(handle, Handle);
      }

      return addItem;
    case Handle.edit:
      return options.edit;

    case Handle.look:
      return options.look;

    default:
      return '';
  }
}

/**
 * 添加搜索参数
 */
export const $goSearch = function (params) {
  let query = this.$route.query;

  params = Object.assign({}, query, params);

  this.$router.push({
    query: params
  });
}

/**
 * 检查权限
 * 如果此权限存在那么返回true
 * 
 * @param {*} authname 
 */
export const $checkAuth = function (authname) {
  let authList = this.$store.state.authList;

  return authList && authList.length > 0 && authname ? authList.includes(authname) : true;
}

export const $getItemWithKey = getItemWithKey;