<template>
  <a-card class="scene-item" :title="title || '添加场景'" :scopedSlots="$scopedSlots">
    <span class="close-icon" slot="extra" @click="$emit('close', index)">&#9587;</span>

    <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }">
      <a-row>
        <a-col :sm="24" :md="12">
          <a-form-item>
            <a-input type="hidden" v-decorator="[`is_enabled`]" />
          </a-form-item>

          <a-form-item label="场景名称">
            <a-select
              :options="BusinessSceneOptions.filter(item => item.value !== '0')"
              placeholder="请选择场景名称"
              size="large"
              v-decorator="[`type`, {rules: [$validate.required] }]"
            ></a-select>
          </a-form-item>

          <!-- <a-form-item label="场景权重">
        <a-input-number
          class="w-100"
          :min="0"
          :max="1"
          :step="0.01"
          size="large"
          v-decorator="[`ratio`, {rules: [$validate.required], initialValue: 0 }]"
        ></a-input-number>
          </a-form-item>-->
          <!-- :parser="value => value.replace('%', '')" -->

          <a-form-item label="渠道信息" required>
            <form-add size="large" add-text="添加使用方式" :defaultValue="formAddDefault">
              <template v-slot:default="scopes">
                <a-form-item class="m-bottom-0" layout="inline">
                  <a-form-item
                    class="inline-block"
                    :style="{width: 'calc(60% - 12px)', marginRight: '24px' }"
                  >
                    <a-input
                      size="large"
                      placeholder="请输入使用方式名称"
                      v-decorator="[`use_mode[${scopes.index}][name]`, {rules: [$validate.required]}]"
                    ></a-input>
                  </a-form-item>
                  <a-form-item class="inline-block" :style="{width: 'calc(40% - 12px)' }">
                    <a-input-number
                      class="w-100"
                      :min="0"
                      :step="1"
                      size="large"
                      placeholder="请输入售价"
                      v-decorator="[`use_mode[${scopes.index}][price]`, {rules: [$validate.required]}]"
                    ></a-input-number>
                  </a-form-item>
                </a-form-item>
                <a-form-item>
                  <ng-textarea
                    :maxLength="50"
                    show-counter
                    size="large"
                    placeholder="请输入描述，长度50个字以内"
                    v-decorator="[`use_mode[${scopes.index}][desc]`, {rules: [$validate.required]}]"
                  ></ng-textarea>
                </a-form-item>
              </template>
            </form-add>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :md="12">
          <template v-for="item in AuthRenderData">
            <ng-detail :title="item.name" :key="item.key">
              <template v-for="(cItem, index) in item.list">
                <a-form-item
                  :label="cItem.label"
                  class="ant-form-item-self flex m-bottom-0"
                  :key="`${item.key}_${cItem.value}`"
                >
                  <a-form-item class="m-right-0">
                    <input
                      type="hidden"
                      size="large"
                      v-decorator="[`auth[${item.key}][${index}][type]`, {initialValue: cItem.value }]"
                    />
                  </a-form-item>

                  <a-form-item>
                    <a-input-number
                      class="w-100 m-right-ms"
                      :min="0"
                      :step="0.01"
                      size="large"
                      placeholder="请设置权重值"
                      v-decorator="[`auth[${item.key}][${index}][ratio]`, {rules: [$validate.required], initialValue: 0 }]"
                    ></a-input-number>
                  </a-form-item>

                  <a-form-item>
                    <a-switch
                      checked-children="启用"
                      un-checked-children="停用"
                      size="large"
                      v-decorator="[`auth[${item.key}][${index}][is_enabled]`, {rules: [$validate.required], initialValue: false, valuePropName: 'checked' }]"
                    />
                  </a-form-item>
                </a-form-item>
              </template>
            </ng-detail>
          </template>
        </a-col>
      </a-row>
      <!-- <div class="left flex-1"></div> -->

      <div class="right flex-1"></div>
    </a-form>
  </a-card>
</template>

<script>
  import {Card} from 'ant-design-vue';
  import {FormAdd} from '@/components/index'
  import {BusinessSceneOptions} from '@/config/options'
  import {transferParamFromObjToStr} from '@/utils/function'
  import {AuthAreaOfMusicOptions, AuthTimeOptions} from '@/config/options';

  const AuthRenderData = [
    {
      key: 'time',
      name: '设置授权期限权重',
      list: AuthTimeOptions
    },
    {
      key: 'area',
      name: '设置授权地域权重',
      list: AuthAreaOfMusicOptions
    }
  ]

  export default {
    data() {
      return {
        form: this.$form.createForm(this, 'scene'),
        BusinessSceneOptions,
        formAddDefault: [],

        AuthRenderData
      }
    },
    props: {
      title: String,
      index: Number,
      defaultData: Object,
    },

    watch: {
      defaultData: {
        handler(newVal) {
          if (newVal && Object.keys(newVal).length > 0) {
            let copyVal = {...newVal};

            this.$nextTick(() => {
              this.formAddDefault = copyVal && copyVal.use_mode ? copyVal.use_mode : [];

              copyVal = transferParamFromObjToStr(copyVal)

              Object.keys(copyVal).forEach(item => {
                const isRatio = /ratio/.test(item);
                const isEnabled = /\[is_enabled\]/.test(item);

                if (isRatio) {
                  copyVal[item] = copyVal[item] / 100
                }
                else if (isEnabled) {
                  copyVal[item] = copyVal[item] == '1' ? true : false;
                }
              })

              setTimeout(() => {
                this.form.setFieldsValue(copyVal);
              }, 300);
            })
          }
        },
        immediate: true
      }
    },

    components: {
      ACard: Card,
      FormAdd,
    },
    methods: {
    }
  }
</script>

<style lang="scss" scoped>
  .scene-item {
    /deep/ {
      .iconfont-trash {
        top: 20px !important;
      }

      .ant-form-item-with-help {
        margin-bottom: 5px;
      }
    }

    .ant-form-item-self {
      display: flex;
      // align-items: center;

      /deep/ {
        .ant-form-item-label {
          width: 130px;
          flex-basis: 1;
        }

        .ant-form-item-control-wrapper {
          flex: 1;
        }

        .ant-form-item-children {
          display: flex;
          align-items: center;

          .ant-form-item {
            &:nth-child(2) {
              flex: 1;
            }

            .ant-form-item-control-wrapper {
              width: 100%;
            }

            &:last-child {
              width: 60px;
            }
          }
        }
      }
    }
  }

  .close-icon {
    font-size: 20px;
    cursor: pointer;
  }
</style>
