<template>
  <ng-table
    class="ng-table"
    :columns="columns"
    :params="params"
    :rowKey="row => row.task_id"
    :request="$axios.MarklibraryTask_servedList"
    :load.sync="loadTable"
    :reload.sync="reloadTable"
    :scroll="{y: scrollyHeight - 20}"
  >
    <!-- 头像 -->
    <template slot="lib_logo_url" slot-scope="text, scope">
      <ng-image
        class="table-header"
        :src="scope.lib_logo_url ? `${scope.lib_logo_url}?x-oss-process=style/thumb_10` : $var.defaultHeader"
        @error="({target}) => target.src = $var.defaultHeader"
      />
    </template>

    <template slot="priority" slot-scope="text">
      <span v-html="$options.filters.toLevelName(text)"></span>
    </template>

    <template slot="current_progress" slot-scope="text, scope">
      <span>{{scope.done_music_num}} / {{scope.music_num}}</span>
    </template>

    <template slot="operation" slot-scope="text, scope">
      <router-link
          v-if="$root.$checkAuth($auth[authName] && $auth[authName].lookMusic)"
        :to="`/librarymark/musicmanage/${scope.task_id}?from=${$var.Common.second}`"
        class="m-right-ms"
      >查看</router-link>
    </template>
  </ng-table>
</template>

<script>
  import {tableMixins} from '@/mixins';

  import * as TabCol from '@/config/columns';

  const columns = [
    TabCol.colId,
    TabCol.portrait('封面', 'lib_logo_url'),
    TabCol.commonKey('曲库名称', 'lib_name', {ellipsis: true}),

    TabCol.commonKey('认领时间', 'claim_time'),
    TabCol.commonKey('交付时间', 'done_time'),
    TabCol.commonKey('优先级', 'priority', '', true),
    TabCol.commonKey('当前进度', 'current_progress', '', true),

    TabCol.operation('100px')
  ]

  export default {
    data() {
      return {
        columns,
        tableList: [],
        // params: {},
      }
    },

    props: {
      authName: String,
      scrollyHeight: {
        type: Number,
        default: 400
      },
      params:Object
    },

    mounted() {
      // this.params = {page: this.$route.query.page || 1};
    },

    mixins: [
      tableMixins
    ],

    methods: {
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .ant-table td {
    white-space: nowrap;
  }
</style>
