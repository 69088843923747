const PackageList = () => import('@/pages/package/PackageList.vue');
const PackageInfo = () => import('@/pages/package/PackageInfo.vue');

export default [{
    path: '/package/list',
    name: 'packageList',
    component: PackageList,
  },
  {
    path: '/package/info/:goods_id',
    name: 'packageInfo',
    component: PackageInfo,
  },
]