<template>
  <div class="auth-config">
    <a-form :form="form" layout="inline" class="p-20" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
      <template v-for="([key, value], index) in Object.entries(renderData)">
        <ng-detail :title="value.name" class="m-top-md" :key="index">

          <template v-for="(cItem, index) in value.list">
            <!--  v-if="!isCustom || isCustom && data && Array.isArray(data) && data.some((dItem) => dItem.type === cItem.type)"-->
            <a-form-item
              :label="cItem.name"
              :key="`${key}_${cItem.type}`"
              style="display: block; margin-bottom: 20px"
            >
              <a-form-item class="m-right-0">
                <input
                  type="hidden"
                  v-decorator="[`${key}[${index}][type]`, {initialValue: cItem.type }]"
                />
              </a-form-item>

              <a-form-item>
                <a-input-number
                  class="w-px-200"
                  :min="0"
                  :step="0.01"
                  placeholder="请设置权重值"
                  v-decorator="[`${key}[${index}][ratio]`, {initialValue: '' }]"
                ></a-input-number>
              </a-form-item>

              <a-form-item>
                <a-tooltip :title="statusTips" placement="top">
                  <a-switch
                    checked-children="启用"
                    un-checked-children="停用"
                    :disabled="!fromCustomData"
                    :checked="cItem.is_enabled == '1'"
                    :loading="statusLoading && curHandleIndex === `${key}_${cItem.type}`"
                    v-decorator="[`${key}[${index}][is_enabled]`, {initialValue: '' }]"
                    @change="(status) => {onChangeAuthStatus(cItem, `${key}_${cItem.type}`, key)}"
                  />
                </a-tooltip>
              </a-form-item>
            </a-form-item>
          </template>
        </ng-detail>
      </template>
    </a-form>
  </div>
</template>

<script>
  import {InputNumber} from 'ant-design-vue';

  import {AuthAreaOptions, AuthTimeOptions} from '@/config/options'

  import {transferParamFromObjToStr} from '@/utils/function'

  import Observe from '~/utils/observe.c.js'

  let AuthTimeOptionsNew = [...AuthTimeOptions].map(({label, value}) => ({name: label, type: value}));
  let AuthAreaOptionsNew = [...AuthAreaOptions].map(({label, value}) => ({name: label, type: value}));

  const AuthKeys = {
    time: '1',
    area: '2'
  }


  export default {
    data() {
      return {
        form: this.$form.createForm(this, "nodify"),
        // AuthArr,
        statusLoading: false,
        curHandleIndex: '',

        renderData: {},

        // 数据来自元数据，不是来自默认的通用数据
        fromCustomData: true,

        changeStatusAble: true,
      }
    },

    computed: {
      isCustom() {
        return !!this.goods_id
      },

      statusRequest() {
        return this.isCustom ? this.$axios.Price_ChangeCustomAuthStatus : this.$axios.Price_ChangeCommonAuthStatus;
      },

      // 需要先保存，生成对应id，才能单独操作改变状态
      statusTips() {
        return this.fromCustomData ? '启用表示该因素所在的定价方案，将调用此因素及权重' : '请先保存'
      }
    },

    props: {
      goods_id: String,
      data: [Array, Object],
      customData: [Array, Object],
    },

    watch: {
      data: {
        handler(newVal) {
          this.$nextTick(() => {
            this.setRenderData(newVal);

            if (newVal && Object.keys(newVal).length > 0) {
              this.$nextTick(() => {
                this.setDefaultData(newVal);
              })
            }
          })
        },
        immediate: true
      },
      customData: {
        handler(newVal) {
          this.fromCustomData = newVal && Object.keys(newVal).length > 0;
        },
        immediate: true
      }
    },

    components: {
      AInputNumber: InputNumber
    },

    methods: {
      onChangeAuthStatus(scope = {}, statusKey, key) {
        let {auth_id = '', is_enabled} = scope;
        let params = {
          auth_id, type: AuthKeys[key] || '', is_enabled: 1 - is_enabled
        }

        this.curHandleIndex = statusKey;

        this.isCustom && (params.goods_id = this.goods_id);

        this.statusLoading = true;

        this.statusRequest && typeof this.statusRequest === 'function' && this.statusRequest(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('修改成功');

            Observe.publish('reloadSellWayInfo');
          }

          this.statusLoading = false;
        })
      },

      setRenderData(data) {
        let {time = [], area = []} = data;
        let AuthTimeOptionsCopy = [...AuthTimeOptionsNew];
        let AuthAreaOptionsCopy = [...AuthAreaOptionsNew];

        // 如果自定义，需要过滤掉没有选中的
        if (this.isCustom) {
          AuthAreaOptionsCopy = AuthAreaOptionsCopy.filter(item => area.some(aItem => aItem.type === item.type))
        }

        if (time && time.length > 0) {
          AuthTimeOptionsCopy = AuthTimeOptionsCopy.map(item => {
            let filFinal = time.filter(tItem => tItem.type === item.type);

            return filFinal && filFinal.length > 0 ? Object.assign(item, filFinal[0]) : item;
          })
        }

        if (area && area.length > 0) {
          AuthAreaOptionsCopy = AuthAreaOptionsCopy.map(item => {
            let filFinal = area.filter(tItem => tItem.type === item.type);

            return filFinal && filFinal.length > 0 ? Object.assign(item, filFinal[0]) : item;
          })
        }

        this.renderData = {
          time: {
            name: '设置授权期限权重',
            list: AuthTimeOptionsCopy
          },
          area: {
            name: '设置授权地域权重',
            list: AuthAreaOptionsCopy
          }
        }
      },

      setDefaultData(newVal) {
        let copyVal = {...newVal};
        if (copyVal && Object.values(copyVal).length > 0) {

          try {
            Object.entries(copyVal).forEach(([item, value]) => {
              copyVal[item] = [];

              value && value.length > 0 && value.forEach((cItem, index) => {
                // let type = cItem.type;
                copyVal[item][index] = cItem
              })
            })

            copyVal = transferParamFromObjToStr(copyVal);

            Object.keys(copyVal).forEach(item => {
              if (/ratio/.test(item)) copyVal[item] = copyVal[item] / 100
              else if (/is_enabled/.test(item)) copyVal[item] = copyVal[item] == '1'
            })

            setTimeout(() => {
              this.form.setFieldsValue(copyVal);
            }, 300);
          } catch (error) {
            //
          }

        }
      },

      saveData() {
        return new Promise(resolve => {
          this.form.validateFields((err, values) => {
            if (err) return;

            resolve(values)
          })
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .auth-config {
    /deep/ {
      .ng-detail {
        margin-left: 30px;
      }
    }
  }
</style>
