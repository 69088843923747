<template>
  <config-provider :locale="locale" :transformCellText="onTransformCellText">
    <router-view class="app"></router-view>
  </config-provider>
</template>

<script>
  import {ConfigProvider} from 'ant-design-vue';
  import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
  // import moment from 'moment';

  // import 'moment/locale/zh-cn';

  // moment.locale('zh-cn');

  export default {
    name: 'App',
    data() {
      return {
        locale: zhCN,
        // departmentList: [], // 缓存
      }
    },

    created() {
      this.$store.dispatch('checkLogin', {
        router: this.$router
      });

      this.$store.dispatch('getUserInfo', true);

      this.$store.dispatch('getUserAuthList', true);
    },
    components: {
      ConfigProvider
    },
    methods: {
      // eslint-disable-next-line
      onTransformCellText({text, column, record, index}) {
        return !((!text && text != '0') && column.key !== 'expand') ? text : '--';
      }
    }
  }
</script>

<style lang="scss">
  // @import "@/scss/main.scss";

  body {
    background-color: #edeff2 !important;
  }

  .iconfont {
    width: 2em;
    height: 2em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
  }
</style>
