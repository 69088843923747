<template>
  <a-spin :spinning="loading" class="h-100 tag-info">
    <a-card title="描述" size="small" class="tags-type h-100 tag-card">
      <div v-if="showContent" class="info-content main-card-content">
        <template v-for="(item, index) in infoList">
          <div class="desc-item" :key="index">
            <b class>{{ item.label }}</b>
            <!-- 标注方式 -->
            <template v-if="item.name === 'tag_type'">
              <div class="text">
                {{
                infoData[item.name]
                ? infoData[item.name] == "1"
                ? "单选"
                : "多选"
                : "--"
                }}
              </div>
            </template>
            <!-- 标签颜色 -->
            <template v-else-if="item.name === 'color'">
              <div>
                <a-tag
                  v-if="infoData[item.name]"
                  :color="infoData[item.name]"
                  :style="{
                    color: darken(infoData[item.name]),
                    borderColor: darken(infoData[item.name]),
                  }"
                >标签</a-tag>
                <span v-else>--</span>
              </div>
            </template>
            <!-- 子标签 -->
            <template v-else-if="item.name === 'label_num' || item.name === 'child_num'">
              <div>{{ `${infoData[item.name] || 0}个` }}</div>
            </template>
            <!-- 其他 -->
            <template v-else>
              <div class="text">
                {{
                typeof infoData[item.name] !== "undefined" &&
                infoData[item.name] !== ""
                ? infoData[item.name]
                : "--"
                }}
              </div>
            </template>
          </div>
        </template>
        <div class="desc-item">
          <b class>操作</b>
          <div class="operation">
            <a-button
              v-if="isType && $root.$checkAuth($auth[authName] && $auth[authName].editCate) 
                || !isType && $root.$checkAuth($auth[authName] && $auth[authName].editTag)"
              class="p-left-0 font-grey"
              type="link"
              icon="edit"
              @click="onEdit"
            >编辑</a-button>
            <br />
            <a-button
              v-if="isType && $root.$checkAuth($auth[authName] && $auth[authName].delCate) 
                || !isType && $root.$checkAuth($auth[authName] && $auth[authName].delTag)"
              class="p-left-0 font-grey"
              type="link"
              icon="delete"
              @click="onDel"
            >删除</a-button>
            <br />

            <a-switch
              v-if="isType && $root.$checkAuth($auth[authName] && $auth[authName].editCateSatus)"
              :checked="checkedStatus"
              checked-children="启用"
              un-checked-children="禁用"
              @change="onChange"
              :disabled="!infoData.op_enable"
            />
          </div>
        </div>
      </div>
    </a-card>
  </a-spin>
</template>

<script>
  import {darken} from '@/utils/function'

  const getList = (isTag) => {
    let childName = isTag ? 'child_num' : 'label_num';

    let arr = [
      {label: '类别描述', name: 'desc'},
      {label: '英文名称', name: 'name_en'},
      {label: '标注对象', name: 'tag_target'},
      {label: '标注方式', name: 'tag_type'},
    ]
    if (!isTag) {
      arr = arr.concat([
        {label: '标注上限', name: 'tag_limit'},
        {label: '标签配色', name: 'color'},
      ])
    }

    arr = arr.concat([
      {label: '子标签', name: childName},
      {label: '创建人', name: 'creator_name'},
      {label: '创建时间', name: 'create_at'},
    ])

    return arr;
  }

  export default {
    inject: ['authName'],

    data() {
      return {
        infoData: '',
        // infoList: getList(true),
        loading: false,
        checkedStatus: false,
      }
    },

    props: {
      activeKey: String,
      activeTagKey: String,
    },

    watch: {
      activeKey: {
        handler(newVal) {
          if (newVal) {
            setTimeout(() => {
              this.getTagsInfo(newVal);
            }, 200);
          } else {
            this.infoData = {}
          }
        },
        immediate: true
      },
      activeTagKey: {
        handler(newVal) {
          if (newVal) {
            setTimeout(() => {
              this.getTagsInfo(newVal);
            }, 200);
          } else {
            this.infoData = {}
          }
        },
        immediate: true
      }
    },

    computed: {
      infoList() {
        return getList(this.activeTagKey);
      },

      currentId() {
        return this.isTag ? this.activeTagKey : this.isType ? this.activeKey : '';
      },

      key_id() {
        return this.isTag ? 'label_id' : this.isType ? 'cate_id' : '';
      },

      // 类别
      isType() {
        return this.activeKey && !this.activeTagKey
      },

      // 标签
      isTag() {
        return this.activeKey && this.activeTagKey;
      },

      fromPage() {
        return this.isTag ? 'tag' : this.isType ? 'type' : '';
      },

      showContent() {
        return this.activeKey || this.activeTagKey
      },

      delFunc() {
        return this.isTag ? 'Tags_del' : this.isType ? 'Tags_cgy_del' : '';
      },

      infoFunc() {
        return this.isTag ? 'Tags_info' : this.isType ? 'Tags_cgy_info' : '';
      },
    },

    methods: {
      darken,
      reloadData() {
        this.getTagsInfo(this.currentId)
      },

      getTagsInfo(id) {
        let params = {[this.key_id]: id};

        if (this.$axios[this.infoFunc]) {
          this.loading = true;

          this.$axios[this.infoFunc](params).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              this.infoData = data.data;
              this.checkedStatus = Boolean(Number(this.infoData.is_enabled));
            }

            this.loading = false;
          })
        }
      },

      onChange(value) {
        this.checkedStatus = value;

        this.changeStatus(value);
      },

      changeStatus(is_enabled) {
        let params = {
          cate_id: this.currentId,
          is_enabled: Number(is_enabled)
        };

        this.$axios.Tags_cgy_status(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success(is_enabled ? '启用成功' : '禁用成功');
          }
        })
      },

      onEdit() {
        this.$emit('edit', {
          from: this.fromPage,
          id: this.currentId,
          data: this.infoData
        })
      },

      onDel() {
        let that = this;
        let message = this.isTag ? '确认要删除该标签吗？' : this.isType ? '确认要删除该标签类别吗？' : '';

        this.$confirm({
          width: '480px',
          title: message,
          // content: '删除该角色，关联该角色的成员账号，会失去对应的权限；',
          onOk() {
            return new Promise((resolve, reject) => {
              that.deleteFunc(that.infoData, resolve, reject);
            });
          }
        });
      },

      deleteFunc(scope, next, reject) {
        let key_id = this.key_id;

        if (typeof this.$axios[this.delFunc] === 'function') {
          this.$axios[this.delFunc]({[key_id]: scope[key_id]}).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              this.$message.success('已删除');
              next();

              this.$emit('del', {
                from: this.fromPage,
                data: this.infoData
              });
            } else {
              reject()
            }
          })
        } else {
          reject();
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .tag-info {
    .info-content {
      padding: 20px 10px;
    }

    .desc-item {
      margin-bottom: 20px;
    }

    /deep/ {
      .ant-spin-container {
        height: 100%;
      }
    }
  }
</style>
