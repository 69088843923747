<template>
  <main-structure class="ng-library" dir="ver">
    <template slot="header">
      <span class="title">优惠券列表</span>

      <div class="header-content header-search-content">
        <a-input-search
          class="ng-input-search"
          placeholder="请输入优惠券ID或名称"
          :default-value="$route.query.keyword || ''"
          @search="onSearch"
          allowClear
        >
          <a-button slot="enterButton" type="sub">
            <a-icon type="search" />
          </a-button>
        </a-input-search>

        <a-button
          v-if="$root.$checkAuth($auth[name] && $auth[name].add)"
          type="sub"
          @click="handleFunc($var.Handle.add)"
        >新建优惠券</a-button>
      </div>
    </template>

    <template>
      <div class="flex cen-space">
        <filter-table @change="onFilterChange" :defaultData="$route.query" class="m-top-base"></filter-table>
        <div>  <a-button
              v-if="$root.$checkAuth($auth[name] && $auth[name].download)"
              @click="onDownload()" icon="download"> 下载数据</a-button></div>
      </div>

      <div class="m-top-base flex-1" ref="ngTableBox">
        <manage-table
          v-if="$root.$checkAuth($auth[name] && $auth[name].list)"
          :scrolly-height="mainContentScrollMaxHeight"
          :auth-name="name"
          :table-params="tableParams"
          @handle="onTableHandleSuccess"
          ref="ngTable"
        ></manage-table>
      </div>
    </template>

    <coupon-add
      :visible.sync="showHandleDialog"
      :handle="handleType"
      :default-value="handleData"
      :user_id="$route.params.user_id"
      @success="handleAddSuccess"
    ></coupon-add>

    <coupon-generate
      :coupon_id='generateCodeCouponId'
      :visible.sync="showGenerateDialog"
      :handle="handleGenerate"
      @success="handleGenerate"
    ></coupon-generate>

    <coupon-send
      :sendCouponData='sendCouponData'
      :visible.sync="showSendDialog"
      :handle="handleSend"
      @success="handleSendSuccess"
    ></coupon-send>
  </main-structure>
</template>

<script>
  import ManageTable from './components/ManageTable.vue';
  import FilterTable from './components/FilterTable'

  import {mainContentMixin} from '@/mixins';
  import * as $config from '~/config/config.c';
  import $storage from '~/utils/storage'
  import * as $var from '~/utils/variables.c'
let baseURL = $config.ServeBaseHost;
  export default {
    data() {
      return {
        name: `song_list`,
        showHandleDialog: false,
        showSendDialog: false,
        showGenerateDialog: false,
        handleType: this.$var.Handle.add,
        handleData: {},
        isReloadTable: false,
        tableParams: {},
        generateCodeCouponId:'',
        sendCouponData:{}
      }
    },

    components: {
      ManageTable,
      FilterTable,
      CouponAdd: () => import('./components/CouponAdd.vue'),
      CouponGenerate: () => import('./components/GenerateCoponCode.vue'),
      CouponSend: () => import('./components/SendCopon.vue'),
    },

    mixins: [
      mainContentMixin
    ],

    methods: {
      onDownload() {
        let token = encodeURIComponent($storage.get($var.STORAGE.TOKEN));
        let url = `${baseURL}/bms/coupon/downCoupon?token=${token}`
        window.open(url)
     
      },
      onFilterChange(values) {
        let params = Object.assign({}, this.tableParams, values);

        let sale_status = params.sale_status;

        params.sale_status = sale_status ? [sale_status] : [];

        this.tableParams = params;
      },

      onSearch(keyword) {
        typeof keyword === 'string' && (keyword = keyword.trim());

        let search = {keyword, page: 1};

        this.$root.$goSearch(search);

        this.tableParams = search;
      },

      handleAddSuccess(isLoad) {
        console.log('isLoad: ', isLoad);

        this.showHandleDialog = false;
        this.$refs.ngTable.loadTableData();
      },

      onTableHandleSuccess(scope) {
        let handle = scope.handleName;
        if(handle == 'generate'){
          this.showGenerateDialog = true
          this.generateCodeCouponId = scope.coupon_id
        }
         if(handle == 'send'){
          this.showSendDialog = true
          this.sendCouponData = scope
        }

      },

      handleFunc(key, scope) {
        this.handleData = scope || {};
        this.handleType = key;
        this.showHandleDialog = true;
      },
      handleGenerate(key,scope){
        console.log('2233332,scope: ', key,scope);
        this.showGenerateDialog = true

      },
      handleSend(){
        
      },
      handleSendSuccess(){

      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
