<template>
  <a-modal
    class="add-tags-type"
    :title="title"
    width="640px"
    v-model="show"
    :maskClosable="false"
    :bodyStyle="{
      maxHeight: `${dialogBodyMaxHeight ? dialogBodyMaxHeight : '500'}px`,
      overflow: 'auto',
    }"
  >
    <a-form
      :form="form"
      layout="horizontal"
      :label-col="{ xs: { span: 5 } }"
      :wrapper-col="{ xs: { span: 17 } }"
      class="ng-form m-top-base"
    >
      <a-form-item label="类别名称">
        <ng-input
          :maxLength="20"
          show-counter
          placeholder="请输入标签类别名称，上限20个字"
          v-decorator="[
            'name',
            {
              rules: [$validate.required],
              initialValue: '',
              validateTrigger: 'blur',
            },
          ]"
        ></ng-input>
      </a-form-item>

      <a-form-item label="类别名称">
        <ng-input
          :maxLength="20"
          show-counter
          placeholder="请输入标签类别英文名称，上限20个字"
          v-decorator="[
            'name_en',
            {
              rules: [$validate.required],
              initialValue: '',
              validateTrigger: 'blur',
            },
          ]"
        ></ng-input>
      </a-form-item>

      <a-form-item label="类别描述">
        <ng-textarea
          placeholder="请输入类别描述"
          :maxLength="200"
          show-counter
          v-decorator="[
            'desc',
            { rules: [$validate.required], initialValue: '' },
          ]"
          style="height: 80px"
        ></ng-textarea>
      </a-form-item>

      <a-form-item label="标注对象">
        <a-checkbox-group
          :options="markTarget"
          v-decorator="[
            'first_tag_target',
            { rules: [$validate.required], initialValue: [] },
          ]"
        />
      </a-form-item>

      <!-- 音乐类型 -->
      <a-form-item
        v-if="
          form.getFieldValue('first_tag_target') &&
          form.getFieldValue('first_tag_target').includes('1')
        "
        :wrapper-col="{ xs: { offset: 5 } }"
      >
        <a-checkbox-group
          :options="markTargetData"
          v-decorator="[
            'second_tag_target',
            { rules: [$validate.required], initialValue: [] },
          ]"
        />
      </a-form-item>

      <a-form-item label="标注方式">
        <a-radio-group v-decorator="['tag_type', { rules: [$validate.required] }]">
          <a-radio value="1">单选</a-radio>
          <a-radio value="2">多选</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="标注上限">
        <a-input-number
          :min="0"
          placeholder="标注上限"
          v-decorator="[
            'tag_limit',
            {
              rules: [$validate.required],
              initialValue: '',
            },
          ]"
        />
        <a-tooltip placement="right">
          <template slot="title">
            <span>标注上限用于控制该类别下可标注的标签数量</span>
          </template>
          <a-button
            type="primary"
            shape="circle"
            icon="exclamation"
            size="small"
            class="ant-form-text"
          ></a-button>
        </a-tooltip>
      </a-form-item>

      <a-form-item label="标签配色">
        <color-picker
          class="color-picker ant-input-number"
          v-decorator="[
            'color',
            {
              rules: [$validate.required],
              initialValue: '',
            },
          ]"
        ></color-picker>

        <a-tooltip placement="right">
          <template slot="title">
            <span>标签颜色将从视觉上帮助标注人员区分标签类别</span>
          </template>
          <a-button
            type="primary"
            shape="circle"
            icon="exclamation"
            size="small"
            class="ant-form-text"
          ></a-button>
        </a-tooltip>
      </a-form-item>
    </a-form>

    <template slot="footer">
      <a-button key="back" @click="handleCancel">取消</a-button>

      <a-button
        v-if="handle !== $var.Handle.edit"
        key="save"
        type="primary"
        @click="handleOk(false)"
        :loading="confirmLoading && saveBtnType == statusDir.saveAndContinus"
      >保存并继续新增</a-button>

      <a-button
        key="submit"
        type="primary"
        @click="handleOk(true)"
        :loading="confirmLoading && saveBtnType == statusDir.save"
      >保存</a-button>
    </template>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import * as allOptions from '@/config/options';

  import {ColorPicker} from '@/components/index';

  export default {
    data() {
      return {
        show: false,

        color: '',

        confirmLoading: false,

        markTarget: allOptions.MarkTarget,

        markTargetData: allOptions.MusicTypeOptions,

        defaultData: {},

        form: this.$form.createForm(this, {
          name: 'linkman'
        }),

        statusDir: {
          save: '2',
          saveAndContinus: '1'
        },

        saveBtnType: '', // 1（保存并继续） 2（保存）
      }
    },

    props: {
      handle: String,
      options: Object,
      id: String,
      defaultValue: {
        type: Object,
        default() {
          return {}
        }
      },
    },

    watch: {
      visible(newVal) {
        if (newVal) {
          if (this.handle === this.$var.Handle.edit) {
            this.$nextTick(() => {
              setTimeout(() => {
                this.setDefaultValue(this.defaultValue);
              }, 200);
            })
          }
        }
      }
    },

    computed: {
      isEdit() {
        return this.handle === this.$var.Handle.edit;
      },

      title() {
        return this.isEdit ? '编辑标签类别' : '新增标签类别'
      }
    },

    mixins: [dialogMixins],

    components: {
      ColorPicker
    },

    methods: {

      handleOk(isClose) {
        this.saveBtnType = isClose ? this.statusDir.save : this.statusDir.saveAndContinus;

        this.form.validateFieldsAndScroll((errors, values) => {

          if (!errors) {
            let {first_tag_target: ftage, second_tag_target: stag} = values;

            values.first_tag_target = ftage ? ftage.join(',') : '';
            stag && (values.second_tag_target = stag ? stag.join(',') : '');

            this.addTagType(values, isClose);
          }
        })
      },

      handleCancel() {
        this.$emit('update:visible', false);
      },

      setDefaultValue(values) {
        let {first_tag_target: ftt, second_tag_target: stt} = values

        typeof ftt === 'string' && (values.first_tag_target = ftt.split(','))
        typeof stt === 'string' && (values.second_tag_target = stt.split(','))

        this.form.setFieldsValue(values);

        setTimeout(() => {
          this.form.setFieldsValue({second_tag_target: values.second_tag_target})
        }, 200);
      },

      addTagType(params, isClose) {
        let urlFunc = 'Tags_cgy_add';
        let message = '添加成功'

        this.confirmLoading = true;

        if (this.handle === this.$var.Handle.edit) {
          params.cate_id = this.id;
          urlFunc = 'Tags_cgy_edit';
          message = '编辑成功';
        }

        this.$axios[urlFunc](params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success(message);
            this.$emit('success', data.data);

            if (isClose) {
              this.$emit('update:visible', false);
            } else {
              this.form.resetFields();
            }
          }

          this.confirmLoading = false;
        }).catch(() => {
          this.confirmLoading = false;
        })
      },
      // onChangeColor(value) {

      // }
    }
  }
</script>

<style lang="scss" scoped>
  .add-tags-type {
    /deep/ {
      .ant-checkbox-group-item {
        margin-bottom: 10px;
      }
    }

    .color-picker {
      vertical-align: middle;

      /deep/ .colorBtn {
        width: 100%;
        height: 100%;
      }
    }
  }
</style>
