<template>
  <ng-table
    class="ng-table"
    :columns="columns"
    :params="params"
    :rowKey="row => row.playlist_id"
    :request="$axios.Song_list"
    :load.sync="loadTable"
    :reload.sync="reloadTable"
    :scroll="{y: scrollyHeight - 20}"
    @data-change="requestSuccess"
  >
    <!-- 头像 -->
    <template slot="profile_photo" slot-scope="text, scope">
      <ng-image
        class="table-header"
        :src="scope.logo_url ? `${scope.logo_url}?x-oss-process=style/thumb_10` : $var.defaultHeader"
        @error="({target}) => target.src = $var.defaultHeader"
      />
    </template>

    <template slot="name" slot-scope="text, scope">
      <router-link :to="`/songlistdetail/${scope.playlist_id}`">{{text}}</router-link>
    </template>

    <template slot="sale_status" slot-scope="text, scope">
      <table-switch
        v-if="$root.$checkAuth($auth[authName] && $auth[authName].status)"
        :options="text >= 0 ? SellOperateOptions.filter(item => item.value >= 0) : SellOperateOptions"
        :default-value="parseInt(text) || 0"
        :format="{id: 'value', name: 'label'}"
        :change="function() { onChangeSwitch(...arguments, scope) }"
      ></table-switch>
      <span v-else :class="text == 0 ? 'font-error' : 'font-success'">{{text | toSellStatus}}</span>
    </template>

    <template slot="operation" slot-scope="text, scope">
      <router-link
        v-if="$root.$checkAuth($auth[authName] && $auth[authName].discount)"
        class="ant-btn-link m-right-ms"
        :to="`/songlistDiscount/${scope.playlist_id}`"
      >配置折扣</router-link>

      <a-button
        v-if="$root.$checkAuth($auth[authName] && $auth[authName].edit)"
        class="m-right-ms"
        type="link"
        @click="handleFunc($var.Handle.edit, scope)"
      >编辑</a-button>

      <a-button
        v-if="$root.$checkAuth($auth[authName] && $auth[authName].del)"
        class="ant-btn-error font-error"
        type="link"
        @click="onDelete(scope)"
      >删除</a-button>
    </template>
  </ng-table>
</template>

<script>
  import {tableMixins} from '@/mixins';

  import {AuthAreaOptions, AuthTypeOptions, BusinessSceneOptions, SellStatusOptions, SellOperateOptions} from '@/config/options';
  import {toOptionName} from '@/utils/function';

  import * as TabCol from '@/config/columns';

  const columns = [
    TabCol.colId,
    TabCol.portrait('封面', 'profile_photo'),
    TabCol.commonKey('名称', 'name', {ellipsis: true, isSlot: true}),
    TabCol.commonKey('音乐数量', 'music_num'),
    TabCol.commonKey('创建者', 'creator'),
    TabCol.commonKey('创建时间', 'create_at'),
    TabCol.commonKey('售卖状态', 'sale_status', '140px', true),
    TabCol.operation('170px'),
  ];

  export default {
    data() {
      return {
        columns,
        tableList: [],
        loading: false,
        SellOperateOptions,
        showAddMembersDialog: false,
        handleData: {},
        params: {},
      }
    },

    props: {
      authName: String,
      scrollyHeight: {
        type: Number,
        default: 400
      },
      reload: Boolean,
      user_id: String,
      supplierType: String,
      tableParams: Object,
    },

    mounted() {
      let {page, name, sale_status} = this.$route.query;
      let params = {page: page || 1, name};

      params.sale_status = sale_status ? [sale_status] : [];

      this.params = params;
    },

    filters: {
      toArea(value) {
        return toOptionName(AuthAreaOptions, value);
      },

      toAuthType(value) {
        return toOptionName(AuthTypeOptions, value);
      },

      toAuthScene(value) {
        return toOptionName(BusinessSceneOptions, value);
      },

      toSellStatus(value) {
        return toOptionName(SellStatusOptions, value);
      }
    },

    computed: {
      fromSuppllier() {
        return !!this.user_id;
      },

      request() {
        return this.fromSuppllier ? this.$axios.Song_list : this.$axios.LibraryManage_list;
      }
    },

    watch: {
      tableParams(newVal) {
        this.$nextTick(() => {
          this.params = Object.assign({}, this.params, newVal || {});
        })
      },
    },

    mixins: [
      tableMixins
    ],

    methods: {
      handleFunc(key, scope) {
        this.$emit('handle', Object.assign(scope, {
          handleName: key
        }))
      },

      onChangeSwitch(key, options, next, loading, scope) {
        loading(true);

        let param = {
          playlist_id: scope.playlist_id,
          sale_status: key
        }

        this.$axios.Song_status(param).then(res => {
          let data = res.data;

          loading(false);

          if (data && data.code == 0) {
            next();

            key == 1 ? this.$message.success('已上架') : this.$message.error('已下架');

            this.loadTableData();
          }
        })
      },

      requestSuccess(data) {
        this.tableList = data.data.list ? data.data.list : [];
      },

      onDelete(scope) {
        let vm = this;

        this.$confirm({
          width: '480px',
          title: '确认要删除该歌单吗？',
          // content: '删除该部门，部门关联的子部门及角色数据，会同步删除。',
          onOk() {
            return new Promise((resolve, reject) => {
              vm.deleteMember(scope, resolve, reject);
            });
          }
        });
      },

      deleteMember(scope, next, reject) {
        this.$axios.Song_del({playlist_id: scope.playlist_id}).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('已成功删除该歌单');
            next();

            setTimeout(() => {
              this.loadTableData();
            }, 500);
          } else {
            reject();
          }
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .ant-table td {
    white-space: nowrap;
  }
</style>
