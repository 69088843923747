export const initParams = (query, type) => {
  let seperateSign = ',';

  Object.entries(query).forEach(([key, item]) => {
    if (Array.isArray(item)) {
      if (key === 'lib_id') {
        query.lib_id = item[item.length - 1] || '';
      }
    }
    // 逗号分隔
    else if (typeof item === 'string' && ~item.indexOf(seperateSign)) {
      if (key === 'label_ids') {
        item.split(seperateSign).forEach((item, index) => {
          item && (query[`${key}[${index}]`] = item);
        })

        delete query.label_ids;
      }
      // 其他
      else {
        let [min, max] = item.split(seperateSign);

        query[`${key}[min]`] = min || '';
        query[`${key}[max]`] = max || '';

        delete query[key];
      }
    }
  })

  type && (query.type = type);

  return query;
}

export default {
  data() {
    return {
      switchKey: 0
    }
  },

  methods: {
    onSuccess() {
      this.loadTableData();
    },

    onClickMusic(scope) {
      let {
        origin_url,
        audition_url,
        name
      } = scope;

      let url = origin_url ? origin_url : audition_url;

      this.$store.dispatch('onPlayGlobalVideo', Object.assign(scope, {
        url,
        name
      }));
    },

    onChangeSwitch(scope, key, options, next, loading) {
      loading(true);

      let param = {
        music_id: scope.music_id,
        status: key
      }

      this.$axios.Music_changeStatus(param).then(res => {
        let data = res.data;

        loading(false);

        if (data && data.code == 0) {
          next();

          key == 1 ? this.$message.success('已上架') : this.$message.error('已下架');

          this.onSuccess();
        }
      })
    },

    onBlurWeight(scope, sort) {
      let {
        music_id,
        sort: oldSort
      } = scope;

      let params = {
        music_id,
        sort
      }

      if (oldSort === sort) return;

      this.$axios.MusicManage_ChangeSort(params).then(res => {
        const data = res.data;

        if (data && data.code == 0) {
          this.$message.success('设置成功');
        }
      })
    },

    clearQuery() {

    }
  }
}