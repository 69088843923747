var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticClass:"add-tags-type",attrs:{"title":_vm.title,"width":"640px","maskClosable":false,"bodyStyle":{
    maxHeight: ((_vm.dialogBodyMaxHeight ? _vm.dialogBodyMaxHeight : '500') + "px"),
    overflow: 'auto',
  }},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('a-form',{staticClass:"ng-form m-top-base",attrs:{"form":_vm.form,"layout":"horizontal","label-col":{ xs: { span: 5 } },"wrapper-col":{ xs: { span: 17 } }}},[_c('a-form-item',{attrs:{"label":"类别名称"}},[_c('ng-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            rules: [_vm.$validate.required],
            initialValue: '',
            validateTrigger: 'blur',
          } ]),expression:"[\n          'name',\n          {\n            rules: [$validate.required],\n            initialValue: '',\n            validateTrigger: 'blur',\n          },\n        ]"}],attrs:{"maxLength":20,"show-counter":"","placeholder":"请输入标签类别名称，上限20个字"}})],1),_c('a-form-item',{attrs:{"label":"类别名称"}},[_c('ng-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name_en',
          {
            rules: [_vm.$validate.required],
            initialValue: '',
            validateTrigger: 'blur',
          } ]),expression:"[\n          'name_en',\n          {\n            rules: [$validate.required],\n            initialValue: '',\n            validateTrigger: 'blur',\n          },\n        ]"}],attrs:{"maxLength":20,"show-counter":"","placeholder":"请输入标签类别英文名称，上限20个字"}})],1),_c('a-form-item',{attrs:{"label":"类别描述"}},[_c('ng-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'desc',
          { rules: [_vm.$validate.required], initialValue: '' } ]),expression:"[\n          'desc',\n          { rules: [$validate.required], initialValue: '' },\n        ]"}],staticStyle:{"height":"80px"},attrs:{"placeholder":"请输入类别描述","maxLength":200,"show-counter":""}})],1),_c('a-form-item',{attrs:{"label":"标注对象"}},[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'first_tag_target',
          { rules: [_vm.$validate.required], initialValue: [] } ]),expression:"[\n          'first_tag_target',\n          { rules: [$validate.required], initialValue: [] },\n        ]"}],attrs:{"options":_vm.markTarget}})],1),(
        _vm.form.getFieldValue('first_tag_target') &&
        _vm.form.getFieldValue('first_tag_target').includes('1')
      )?_c('a-form-item',{attrs:{"wrapper-col":{ xs: { offset: 5 } }}},[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'second_tag_target',
          { rules: [_vm.$validate.required], initialValue: [] } ]),expression:"[\n          'second_tag_target',\n          { rules: [$validate.required], initialValue: [] },\n        ]"}],attrs:{"options":_vm.markTargetData}})],1):_vm._e(),_c('a-form-item',{attrs:{"label":"标注方式"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['tag_type', { rules: [_vm.$validate.required] }]),expression:"['tag_type', { rules: [$validate.required] }]"}]},[_c('a-radio',{attrs:{"value":"1"}},[_vm._v("单选")]),_c('a-radio',{attrs:{"value":"2"}},[_vm._v("多选")])],1)],1),_c('a-form-item',{attrs:{"label":"标注上限"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'tag_limit',
          {
            rules: [_vm.$validate.required],
            initialValue: '',
          } ]),expression:"[\n          'tag_limit',\n          {\n            rules: [$validate.required],\n            initialValue: '',\n          },\n        ]"}],attrs:{"min":0,"placeholder":"标注上限"}}),_c('a-tooltip',{attrs:{"placement":"right"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("标注上限用于控制该类别下可标注的标签数量")])]),_c('a-button',{staticClass:"ant-form-text",attrs:{"type":"primary","shape":"circle","icon":"exclamation","size":"small"}})],2)],1),_c('a-form-item',{attrs:{"label":"标签配色"}},[_c('color-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'color',
          {
            rules: [_vm.$validate.required],
            initialValue: '',
          } ]),expression:"[\n          'color',\n          {\n            rules: [$validate.required],\n            initialValue: '',\n          },\n        ]"}],staticClass:"color-picker ant-input-number"}),_c('a-tooltip',{attrs:{"placement":"right"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("标签颜色将从视觉上帮助标注人员区分标签类别")])]),_c('a-button',{staticClass:"ant-form-text",attrs:{"type":"primary","shape":"circle","icon":"exclamation","size":"small"}})],2)],1)],1),_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":_vm.handleCancel}},[_vm._v("取消")]),(_vm.handle !== _vm.$var.Handle.edit)?_c('a-button',{key:"save",attrs:{"type":"primary","loading":_vm.confirmLoading && _vm.saveBtnType == _vm.statusDir.saveAndContinus},on:{"click":function($event){return _vm.handleOk(false)}}},[_vm._v("保存并继续新增")]):_vm._e(),_c('a-button',{key:"submit",attrs:{"type":"primary","loading":_vm.confirmLoading && _vm.saveBtnType == _vm.statusDir.save},on:{"click":function($event){return _vm.handleOk(true)}}},[_vm._v("保存")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }