<template>
  <a-modal
    title="修改音乐授权"
    width="600px"
    v-model="show"
    :confirm-loading="confirmLoading"
    centered
    :maskClosable="false"
    :afterClose="closeModal"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form :form="form" :label-col="{ span: 5}" :wrapper-col="{ span: 17 }">
      <p>为保证您的授权真实有效，请如实填写项目信息！</p>

      <a-form-item label="授权类型">
        <a-radio-group
          :options="ActiviateMusicAuthTypeOptions"
          :disabled="!canEdit"
          v-decorator="['type', { rules: [$root.$validate.checkRequired], initialValue: $val.ActiviateMusicAuthType.company}]"
        ></a-radio-group>
      </a-form-item>

      <a-form-item label="授权主体">
        <a-input
          size="large"
          placeholder="请输入被授权「公司全称」或「个人身份证真实姓名」"
          :disabled="!canEdit"
          v-decorator="['main', { rules: [$root.$validate.required], validateTrigger: 'blur'}]"
        ></a-input>
      </a-form-item>

      <a-form-item label="授权项目">
        <a-input
          size="large"
          placeholder="请输入项目全称"
          :disabled="!canEdit"
          v-decorator="['project', { rules: [$root.$validate.required], validateTrigger: 'blur'}]"
        ></a-input>
      </a-form-item>

      <a-form-item label="投放渠道">
        <a-input
          size="large"
          placeholder="请输入投放渠道"
          :disabled="!canEdit"
          v-decorator="['put_path', { rules: [$root.$validate.required], validateTrigger: 'blur'}]"
        ></a-input>
      </a-form-item>

      <a-form-item label="联系人">
        <a-input
          size="large"
          placeholder="请输入联系人姓名"
          :disabled="!canEdit"
          v-decorator="['contacter', { rules: [$root.$validate.required], validateTrigger: 'blur'}]"
        ></a-input>
      </a-form-item>

      <a-form-item label="联系方式">
        <a-input
          size="large"
          placeholder="请输入授权对象的联系方式"
          :disabled="!canEdit"
          v-decorator="['contact', { rules: [$root.$validate.required, $root.$validate.phone], validateTrigger: 'blur'}]"
        ></a-input>
      </a-form-item>
    </a-form>

    <template slot="footer">
      <a-button @click="handleCancel">取消</a-button>
      <template v-if="canEdit">
        <a-button type="primary" @click="handleOk()" :loading="confirmLoading">保存</a-button>
        <!-- <a-button type="primary" @click="preview()">预览</a-button> -->
      </template>
      <template v-else>
        <a-button type="primary" @click="downloadAuth()" :loading="confirmLoading">下载音乐授权</a-button>
        <a-button type="primary" @click="preview()">预览音乐授权</a-button>
      </template>
    </template>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import {ActiviateMusicAuthTypeOptions} from '@/config/options';
  import {formatTime, DownloadFile} from '@/utils/function';

  export default {
    data() {
      return {
        show: false,
        confirmLoading: false,
        loading: false,
        allPrice: '',
        submitingCart: false,
        allFieldFinised: false,

        ActiviateMusicAuthTypeOptions
      }
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {});
    },

    computed: {
      canEdit() {
        return this.handle !== this.$var.Handle.look;
      },

      canRequest() {
        return this.handle !== this.$var.Handle.add;
      }
    },

    watch: {
      show(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.canRequest && this.getAuthInfo(this.data);
          })
        }
      }
    },

    props: {
      handle: String,
      data: Object,
    },

    mixins: [dialogMixins],

    methods: {

      onChangeFinished(bool) {
        this.allFieldFinised = bool;
      },

      onChangePrice(price) {
        this.allPrice = price;
      },

      getAuthInfo(data) {
        let params = {
          license_id: data.license_id
        };

        this.$axios.User_order_info(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            let {type, main, project, contacter, contact, put_path} = data.data;

            this.form.setFieldsValue({
              type, main, project, contacter, contact, put_path
            })
          }
        })
      },

      handleOk() {
        this.form.validateFieldsAndScroll((errors, values) => {
          if (!errors) {
            values.license_ids = [this.data.license_id]

            this.ActiviateOrder(values);
          }
        });
      },

      ActiviateOrder(params) {
        this.confirmLoading = true;

        this.$axios.User_order_save(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('修改成功');
            this.showCartDialog(false);
            this.$emit('success', data)
          }

          this.confirmLoading = false;
        })
      },

      preview() {
        this.form.validateFieldsAndScroll((errors, values) => {
          if (!errors) {
            let scope = Object.assign({}, this.data, values);

            scope.create_at = formatTime(new Date());

            let routeUrl = this.$router.resolve({
              path: '/authbook/-1',
              query: scope,
            });

            window.open(routeUrl.href, '_blank');
          }
        });
      },

      downloadAuth() {
        let params = {
          license_id: this.data.license_id
        };

        this.confirmLoading = true;

        this.$axios.User_orderAuth_download(params).then(res => {
          const data = res.data;

          if (data) {
            try {
              let disposition = res.headers['content-disposition'];
              let filename = '';

              if (disposition) {
                filename = disposition.replace(/.*filename=["']([^"']+)["'].*/, '$1');
              }

              DownloadFile(data, filename);
              this.$message.success('下载成功');
              this.showCartDialog(false);
            } catch (error) {
              this.$error(error);
            }
          }

          this.confirmLoading = false;
        }).catch(() => {
          this.confirmLoading = false;
        })
      },

      showCartDialog(bool) {
        this.$emit('update:visible', bool);
      },

      handleCancel() {
        this.showCartDialog(false);
      },

      closeModal() {
        this.showCartDialog(false);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .dialog-shop-cart {
    /deep/ {
      .ant-modal-body {
        padding-left: 50px;
        padding-right: 50px;
        min-height: 390px;
      }

      .ant-radio-group {
        margin-top: -10px;
      }

      .ant-radio-button-wrapper {
        margin-right: 15px;
        margin-top: 10px;
      }

      .ant-form-item-label {
        font-weight: bold;
      }

      .ant-form-item-control-wrapper {
        padding-left: 10px;
      }
    }
  }
</style>
