<template>
  <a-modal
    :title="title"
    :width="$config.DialogWidth"
    v-model="show"
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form
      :form="form"
      layout="horizontal"
      :wrapper-col="{ xs: {span: 18, offset: 3}}"
      class="ng-form m-top-base"
    >
      <a-form-item v-for="(k, index) in listKeys" :key="k">
        <a-input
          v-decorator="[`self_link[${index}].url`, { rules: [$validate.required], initialValue: '', trigger: 'blur' }]"
          placeholder="请输入你的主页链接"
        >
          <form-links
            slot="addonBefore"
            v-decorator="[`self_link[${index}].type`, { initialValue: $val.MusicianWebs.Person}]"
            style="width:120px;"
          ></form-links>
        </a-input>

        <svg class="iconfont remove-icon" @click="() => remove(k)">
          <use xlink:href="#icontrash" />
        </svg>
      </a-form-item>

      <a-form-item>
        <a-button type="dashed" class="w-100" @click="add">
          <a-icon type="plus" />添加新链接
        </a-button>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import {FormLinks} from '@/components/index'

  let id = 0;

  export default {
    data() {
      return {
        show: false,

        title: '添加个人链接',

        confirmLoading: false,

        listKeys: [],
      }
    },

    props: {
      user_id: String,
      data: {
        type: Array,
        default() {
          return []
        }
      },
    },

    watch: {
      visible: {
        handler(newVal) {
          if (newVal && this.form && this.data && this.data.length > 0) {
            let arr = [];

            this.data.forEach(() => {arr = arr.concat(id++)});

            this.listKeys = arr;

            this.$nextTick(() => {
              setTimeout(() => {
                this.setDefaultData(this.data);
              }, 300);
            })
          }
        },
        immediate: true
      }
    },

    mixins: [dialogMixins],

    beforeCreate() {
      this.form = this.$form.createForm(this, {name: 'linkman'});
    },

    components: {
      FormLinks
    },

    methods: {

      setDefaultData(data) {
        let param = {};

        data.forEach((item, index) => {
          param[`self_link[${index}].type`] = item.type;
          param[`self_link[${index}].url`] = item.url;
        })

        this.form.setFieldsValue(param);
      },

      remove(k) {
        this.listKeys = this.listKeys.filter(key => key !== k);
      },

      add() {
        this.listKeys = this.listKeys.concat(id++);
      },

      handleOk() {
        this.form.validateFieldsAndScroll((errors, values) => {
          if (!errors) {
            let param = this.$transferParamFromObjToStr(values);
            // let param = Object.keys(handleParam).length > 0 ? handleParam : {
            //   self_link: ''
            // };

            this.onSubmit(param).then(() => {
              this.$emit('success', values);
              this.$emit('update:visible', false);
            })
          }
        })
      },

      onSubmit(param) {
        this.confirmLoading = true;

        param.user_id = this.user_id;

        return new Promise(resolve => {
          this.$axios.Sup_Account_editLink(param).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              resolve(data);
              this.$message.success('添加成功');
            }

            this.confirmLoading = false;
          }).catch(() => {
            this.confirmLoading = false;
          })
        })
      },

      handleCancel() {

      },
    }
  }
</script>

<style lang="scss" scoped>
  .remove-icon {
    position: absolute;
    top: 50%;
    width: 20px;
    right: -26px;
    transform: translateY(-50%);
    cursor: pointer;
  }
</style>
