/**
 * 根据唯一的label值，配置menu的相关属性
 * label是由后台生成的
 * 
 * 在写key值需要注意:
 * 二级菜单的key值最后一个 '_' 前面部分需和对应一级菜单的key相同
 */
export default {
  C_ACCOUNT_ACCESS: {
    name: "账号权限管理",
    icon: "iconiconfontmingpian",
    key: "account",
  },
  M_USER_MANAGE: {
    name: "成员管理",
    path: "/account/members",
    key: "account_members"
  },
  M_DEPT_ROLE: {
    name: "部门角色管理",
    path: "/account/department",
    key: "account_department"
  },
  // 账号 end
  C_REVIEW_MANAGE: {
    name: "审核管理",
    icon: "iconshenhe",
    key: "verify",
  },
  M_REVIEW_MUSICIANS: {
    name: "音乐人审核",
    path: "/verify/musician",
    key: "verify_musician"
  },
  M_REVIEW_TEAM: {
    name: "音乐团队审核",
    path: "/verify/team",
    key: "verify_team"
  },
  M_REVIEW_COMPANY: {
    name: "版权公司审核",
    path: "/verify/company",
    key: "verify_company"
  },
  M_REVIEW_SIGN: {
    name: "签约审核",
    path: "/verify/library",
    key: "verify_library"
  },
  // 审核管理 end
  // 供应商管理 start
  C_SUPPLIER_MANAGE: {
    name: "供应商管理",
    icon: "iconiconfontjiafangjiashi",
    key: "supplier",
  },
  M_MUSICIAN_MANAGE: {
    name: "音乐人管理",
    path: "/supplier/musician",
    key: "supplier_musician"
  },
  M_TEAM_MANAGE: {
    name: "发行音乐人管理",
    path: "/supplier/team",
    key: "supplier_team"
  },
  M_COMPANY_MANAGE: {
    name: "音乐公司管理",
    path: "/supplier/company",
    key: "supplier_company"
  },
  // 供应商管理 end
  // 入库管理 start
  C_STORE_MANAGE: {
    name: "入库管理",
    icon: "iconrukuguanli",
    key: "library_enter_manage",
  },
  M_STORE_MANAGE_UNSERVE: {
    name: "待服务",
    path: "/libraryenter/manage/noserve",
    key: "library_enter_manage_noserve"
  },
  M_STORE_MANAGE_SERVING: {
    name: "服务中",
    path: "/libraryenter/manage/serving",
    key: "library_enter_manage_serving"
  },
  M_STORE_MANAGE_DONE: {
    name: "已完成",
    path: "/libraryenter/manage/servedone",
    key: "library_enter_manage_served"
  },
  // 入库管理 end
  // 入库任务 start
  C_STORE_TASK: {
    name: "入库任务",
    icon: "iconrenwu",
    key: "library_enter_task",
  },
  M_STORE_TASK_UNSERVE: {
    name: "待服务",
    path: "/libraryenter/task/noserve",
    key: "library_enter_task_noserve"
  },
  M_STORE_TASK_SERVING: {
    name: "服务中",
    path: "/libraryenter/task/serving",
    key: "library_enter_task_serving"
  },
  M_STORE_TASK_DONE: {
    name: "已完成",
    path: "/libraryenter/task/servedone",
    key: "library_enter_task_served"
  },
  // 曲库任务 end
  // 标注管理 start
  C_TAG_MANAGE: {
    name: "标注管理",
    icon: "iconbiaozhuguanli",
    key: "library_mark_manage",
  },
  M_TAG_MANAGE_UNSERVE: {
    name: "待标注",
    path: "/librarymark/manage/noserve",
    key: "library_mark_manage_noserve"
  },
  M_TAG_MANAGE_SERVING: {
    name: "标注中",
    path: "/librarymark/manage/serving",
    key: "library_mark_manage_serving"
  },
  M_TAG_MANAGE_DONE: {
    name: "已完成",
    path: "/librarymark/manage/servedone",
    key: "library_mark_manage_served"
  },
  // 标注管理 end
  // 标注任务 start
  C_TAG_TASK: {
    name: "标注任务",
    icon: "iconbiaozhu",
    key: "library_mark_task",
  },
  M_TAG_TASK_UNSERVE: {
    name: "待标注",
    path: "/librarymark/task/noserve",
    key: "library_mark_task_noserve"
  },
  M_TAG_TASK_SERVING: {
    name: "标注中",
    path: "/librarymark/task/serving",
    key: "library_mark_task_serving"
  },
  M_TAG_TASK_DONE: {
    name: "已完成",
    path: "/librarymark/task/servedone",
    key: "library_mark_task_served"
  },
  // 标注任务 end
  // 标签管理
  C_LABEL_MANAGE: {
    name: "标签管理",
    icon: "iconbiaoqian1",
    key: "tags",
  },
  M_BASE_LABEL_MANAGE: {
    name: "基础标签管理",
    path: "/tag/manage",
    key: "tags_baseTags"
  },
  // 标签管理 end

  // 曲库音乐管理
  C_MUSICLIB_MUSIC_MANAGE: {
    name: "曲库音乐管理",
    icon: "iconkucun",
    key: "libraryManage",
  },
  M_MUSICLIB_MANAGE: {
    name: "曲库管理",
    path: "/librarymanage",
    key: "libraryManage_library"
  },
  M_MUSIC_MANAGE: {
    name: "音乐管理",
    is_sub: true,
    key: "libraryManage_music"
  },
  M_MUSIC_MANAGE_BGM: {
    name: "BGM",
    path: "/musicmanage/bgm",
    key: "libraryManage_music_bgm"
  },
  M_MUSIC_MANAGE_YS: {
    name: "人声",
    path: "/musicmanage/rs",
    key: "libraryManage_music_rs"
  },
  M_MUSIC_MANAGE_YX: {
    name: "音效",
    path: "/musicmanage/yx",
    key: "libraryManage_music_yx"
  },
  // 曲库音乐管理 end

  // 商品管理
  C_GOODS_MANAGE: {
    name: "商品管理",
    icon: "iconpingjunshoujia",
    key: "goods",
  },
  M_GOODS_MANAGE: {
    name: "商品管理",
    path: "/goods/manage",
    key: "goods_manage"
  },
  // 售价管理 end
  C_VIP_MANAGE: {
    name: "用户管理",
    icon: "iconren",
    key: "user",
  },
  M_VIP_MANAGE: {
    name: "用户管理",
    path: "/user/manage",
    key: "user_manage"
  },
  M_AUTH_RECORD: {
    name: "授权记录",
    path: "/user/auth",
    key: "user_auth"
  },
  // 订单管理 start
  C_ORDER_MANAGE: {
    name: "订单管理",
    icon: "iconlistpress",
    key: "order",
  },
  M_CHANNEL_ORDER: {
    name: "渠道订单",
    path: "/order/channel",
    key: "order_channel"
  },
  M_GOODS_ORDER: {
    name: "套餐订单",
    path: "/order/package",
    key: "order_package"
  },
  // 订单管理 end
  // 歌单管理 start
  C_PLAYLIST_MANAGE: {
    name: "歌单管理",
    icon: "iconmugedan",
    key: "songlist",
  },
  M_PLAYLIST_MANAGE: {
    name: "歌单管理",
    path: "/songlist",
    key: "songlist_manage"
  },
  // 订单管理 end
  // 歌单管理 start
  C_COMBO_MANAGE: {
    name: "套餐管理",
    icon: "icontaocan",
    key: "package",
  },
  M_OPEN_RECORD: {
    name: "开通记录",
    path: "/package/list",
    key: "package_manage"
  },
  // 订单管理 end
  // 售后管理 start
  C_AFTER_SALES_MANAGE: {
    name: "售后管理",
    icon: "iconshouhou",
    key: "buy",
  },
  M_INVOICE_MANAGE: {
    name: "发票管理",
    path: "/buy/receipt",
    key: "buy_receipt"
  },
  M_CONTRACT_MANAGE: {
    name: "合同管理",
    path: "/buy/contract",
    key: "buy_contract"
  },
  // 售后管理 end
  // 收益管理 start
  C_EARNINGS_MANAGE: {
    name: "收益管理",
    icon: "iconshouyi",
    key: "income",
  },
  M_WITHDRAW_APPLY_MANAGE: {
    name: "提现记录",
    path: "/income/drawing",
    key: "income_cash"
  },
  C_ACTIVITY_MANAGE: {
    name: "活动管理",
    icon: "iconhuodong",
    key: "eventManage",
  },
  M_COUPON_MANAGE: {
    name: "优惠券",
    path: "/eventManage/coupon",
    key: "eventManage_coupon"
  },
  C_WEB_DATA: {
    name: "网站数据",
    icon: "iconlistpress",
    key: "webdata",
  },
  M_WEB_DATA: {
    name: "搜索记录",
    path: "/webdata/Search",
    key: "webdata_search"
  },
  // 售后管理 end
  M_EARNINGS_DATA: {
    name: "收益记录",
    path: "/earnings/earnings",
    key: "earnings"
  },
  M_VIP_ORDER: {
    name: "订阅订单",
    path: "/order/vip",
    key: "order_vip"
  },
  C_HOME_MANAGE: {
    name: "首页管理",
    icon: "iconhome",
    key: "home",
  },
  M_BANNER: {
    name: "轮播图",
    path: "/home/banner",
    key: "home_banner"
  },
  // 售后管理 end
  M_TOPIC: {
    name: "热搜词",
    path: "/topic/list",
    key: "home_topic"
  },
  M_PLAYLIST: {
    name: "主题歌单",
    path: "/playlist",
    key: "home_playlist"
  },
}