<template>
  <div class="tags-show">
    <template v-for="item in list">
      <div class="tags-row" :key="item.cate_id">
        <span class="title">{{item.name}}</span>

        <template v-for="cItem in item.child">
          <a-tag
            :color="cItem.color"
            :key="cItem.label"
            :style="{
              color: darken(item.color),
              borderColor: darken(item.color),
            }"
          >{{ cItem.name }}</a-tag>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
  import {Tag as ATag} from 'ant-design-vue'
  import {darken} from '@/utils/function'

  function loop(child) {
    return child.reduce((a, b) => {
      if (b.child && b.child.length > 0) {
        a = a.concat(loop(b.child))
      }

      a = a.concat([b])

      return a;
    }, [])
  }
  export default {
    data() {
      return {
        list: []
      }
    },
    props: {
      data: Array
    },
    watch: {
      data: {
        handler(newVal) {
          this.list = newVal.map(item => {
            item.child = loop(item.child);

            return item;
          })
        },
        immediate: true
      }
    },
    components: {
      ATag
    },
    methods: {
      darken
    }
  }
</script>

<style lang="scss" scoped>
  .tags-show {
    .title {
      margin-right: 20px;
    }
    .tags-row {
      margin-bottom: 10px;
    }
  }
</style>
