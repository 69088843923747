<template>
  <ng-video-tag
    class="video-tag-s"
    :playing="videoPlaying(item)"
    @play="onPlayVideo(item, optionsFormat)"
  >
    <slot name="left"></slot>

    <svg class="iconfont" slot="playicon">
      <use xlink:href="#iconiconfontyinleeps" />
    </svg>

    <transition name="fade">
      <div class="main-box flex-1">
        <a-input
          v-if="isEdit"
          v-model="value"
          @blur="onBlur"
          @keydown.enter="(e) => e.target.blur()"
          ref="input"
        />
        <div v-else class="w-100 ellipsis" :title="value">{{value}}</div>
      </div>
    </transition>

    <!-- <div class="right-handle edit-box">编辑</div> -->
    <div class="iconfont-box operation-tool">
      <svg
        v-if="$root.$checkAuth($auth[authName] && $auth[authName].editMusicName)"
        class="iconfont"
        @click="onEdit"
      >
        <use xlink:href="#iconbianji" />
      </svg>

      <div class="close-box">
        <svg
          v-if="$root.$checkAuth($auth[authName] && $auth[authName].delMusic)"
          class="iconfont"
          @click="onDel"
        >
          <use xlink:href="#iconshanchu" />
        </svg>
      </div>
    </div>
  </ng-video-tag>
</template>

<script>
  import {NgVideoTag} from '@/components/index'
  import videoTag from '@/mixins/videoTag'

  export default {
    inject: ['authName'],

    data() {
      return {
        isEdit: false,
        value: '',
        optionsFormat: {url: 'music_url'}
      }
    },

    props: {
      item: Object,
      index: Number,
      current: Number,
      music_id: String
    },

    watch: {
      item: {
        handler(newVal) {
          this.value = newVal && newVal.name || '';
          this.videoUrl = newVal.url;
        },
        immediate: true
      }
    },

    components: {
      NgVideoTag
    },

    mixins: [videoTag],

    methods: {
      onEdit() {
        this.isEdit = true;

        this.$nextTick(() => {
          this.$refs.input.$el.focus();
        })
      },

      onDel() {
        let vm = this;
        let params = {
          music_id: this.music_id,
        };

        this.$confirm({
          width: '480px',
          title: '确认要删除该音乐吗？',
          // content: '删除该部门，部门关联的子部门及角色数据，会同步删除。',
          onOk() {
            return new Promise((resolve, reject) => {
              vm.deleteMusic(params, resolve, reject);
            });
          }
        });
      },

      deleteMusic(params, next, reject) {
        this.$axios.Music_del(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('删除成功');
            this.$emit('delsuccess', this.music_id)
            next();
          } else {
            reject();
          }
        })
      },

      onBlur(event) {
        let target = event.target;
        let value = target.value;

        if (value === this.item.name) {
          this.isEdit = false;
          return;
        }

        let params = {
          music_id: this.music_id,
          name: value
        };

        this.$axios.Music_editName(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('修改成功');
            this.$emit('editsuccess', {music_id: this.music_id, value})
          }

          this.isEdit = false;
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .video-tag-s {
    padding-left: 25px;
    height: 50px;

    .main-box {
      width: calc(100% - 60px);
      padding: 0 10px;
    }

    .edit-box {
      width: 40px;
    }

    .iconfont-box {
      display: none;
    }

    &:hover {
      .iconfont-box {
        display: block;
      }
    }

    .operation-tool {
      .iconfont {
        width: 18px;
        fill: #666;
        cursor: pointer;
      }
    }

    .close-box {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
    }

    /deep/ {
      .icon {
        border-radius: 30px;

        &,
        .iconfont {
          width: 25px;
          height: 25px;
        }
      }

      .main-tip {
        &,
        &::before,
        .tip-text {
          position: absolute;
          top: 0;
          left: 0;
        }

        &::before {
          content: " ";
          border: 13px solid transparent;
          border-top-color: #0061f3;
          border-left-color: #0061f3;
        }

        .tip-text {
          color: #fff;
          z-index: 5;
          font-size: 12px;
        }
      }
    }
  }
</style>
