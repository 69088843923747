<template>
  <main-structure class="ng-account-members" dir="ver">
    <template slot="header">
      <span class="title">合约管理</span>

      <div class="header-content">
        <a-button
          v-if="$root.$checkAuth($auth[name] && $auth[name].add)"
          type="sub"
          @click="handleFunc($var.Handle.add)"
        >新增合同</a-button>
      </div>
    </template>

    <template>
      <div class="m-top-ms flex-0">
        <ng-breadcrumb :data="breadData"></ng-breadcrumb>
      </div>

      <div class="m-top-base flex-1" ref="ngTableBox">
        <manage-table
          v-if="$root.$checkAuth($auth[name] && $auth[name].list)"
          :scrolly-height="mainContentScrollMaxHeight"
          :auth-name="name"
          @handle="onTableHandleSuccess"
          ref="ngTable"
        ></manage-table>
      </div>
    </template>

    <contract-add
      :visible.sync="showCoopHandleDialog"
      :handle="handleType"
      :default-value="handleData"
      :contract_id="handleData.contract_id"
      :user_id="$route.params.user_id"
      @success="handleSuccess(false)"
    ></contract-add>
  </main-structure>
</template>

<script>
  import ManageTable from './contract/ManageTable.vue';

  import {mainContentMixin} from '@/mixins';
  import {SupplierTypeDir} from '@/utils/variables'

  const breadData = function (type) {
    return [
      {breadcrumbName: `${SupplierTypeDir[type]}管理`, path: `/supplier/${type}`},
      {breadcrumbName: '合约管理'}
    ]
  }

  export default {
    data() {
      return {
        name: `library_${this.$route.params.type}`,

        showCoopHandleDialog: false,

        handleType: this.$var.Handle.add,

        handleData: {},
        breadData: breadData(this.$route.params.type),

        isReloadTable: false,
      }
    },
    components: {
      ManageTable,
      ContractAdd: () => import('./contract/ContractAdd.vue'),
    },

    mixins: [
      mainContentMixin
    ],

    methods: {
      handleFunc(key, scope) {
        this.handleData = scope || {};
        this.handleType = key;
        this.showCoopHandleDialog = true;
      },

      onTableHandleSuccess(scope) {
        let handle = scope.handleName;
        let varHandle = this.$var.Handle;

        if (handle === varHandle.look) {
          this.handleFunc(handle, scope);
        }
      },

      handleSuccess(isLoad) {
        this.showCoopHandleDialog = false;
        isLoad !== false ? this.$refs.ngTable.reloadTableData() : this.$refs.ngTable.loadTableData();
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
