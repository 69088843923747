<template>
  <a-modal
    width="640px"
    class="add-tags-type"
    title="编辑发票信息"
    v-model="show"
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
    :bodyStyle="bodyStyle"
  >
    <!-- <ng-detail title="基本信息">
      <ul class="info-list">
        <li>申请日期：{{defaultValue.create_at}}</li>
        <li>处理日期：{{defaultValue.operate_time}}</li>
        <li>开票金额：￥{{defaultValue.total_amount}}</li>
        <li>申请用户：{{defaultValue.user_name}}</li>
        <li>关联订单：{{defaultValue.order ? defaultValue.order.order_no : ''}}</li>
        <li>发票类型：{{defaultValue.invoice_type | toReceiptType}}</li>
        <li>发票内容：{{defaultValue.title_type | toReceiptTitleType}}</li>

        <li>申请编号：{{defaultValue.number}}</li>
        <li>处理人员：{{defaultValue.operator_name}}</li>
      </ul>
    </ng-detail>-->

    <a-form
      :form="form"
      layout="horizontal"
      :label-col="{ xs: { span: 5 } }"
      :wrapper-col="{ xs: { span: 17 } }"
      class="ng-form m-top-base"
    >
      <ng-detail title="开票信息">
        <template v-if="defaultValue.title_type === $val.ReceiptTitleType.company">
          <a-form-item label="公司名称">
            <a-input
              placeholder="请输入公司名称"
              v-decorator="[ 'title[name]', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'}]"
            ></a-input>
          </a-form-item>

          <a-form-item label="纳税人识别号">
            <a-input
              placeholder="请输入纳税人识别号"
              v-decorator="[ 'title[taxer_number]', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'}]"
            ></a-input>
          </a-form-item>

          <a-form-item label="注册电话" v-if="defaultValue.invoice_type === $val.ReceiptType.specialEelec">
            <a-input
              placeholder="请输入注册电话"
              v-decorator="[ 'title[phone]', { rules: [$validate.required, $validate.phone], initialValue: '', validateTrigger: 'blur'}]"
            ></a-input>
          </a-form-item>

          <a-form-item label="注册地址" v-if="defaultValue.invoice_type === $val.ReceiptType.specialEelec">
            <a-input
              placeholder="请输入注册地址"
              v-decorator="[ 'title[address]', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'}]"
            ></a-input>
          </a-form-item>

          <a-form-item label="开户银行" v-if="defaultValue.invoice_type === $val.ReceiptType.specialEelec">
            <a-input
              placeholder="请输入开户银行"
              v-decorator="[ 'title[bank]', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'}]"
            ></a-input>
          </a-form-item>

          <a-form-item label="银行账号" v-if="defaultValue.invoice_type === $val.ReceiptType.specialEelec">
            <a-input
              placeholder="请输入银行账号"
              v-decorator="[ 'title[account]', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'}]"
            ></a-input>
          </a-form-item>
        </template>
        <template v-else>
          <a-form-item label="抬头名称">
            <a-input
              placeholder="请输入抬头名称"
              v-decorator="[ 'title[name]', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'}]"
            ></a-input>
          </a-form-item>
        </template>
      </ng-detail>

      <ng-detail title="收件信息">
        <template v-if="defaultValue.invoice_type >= $val.ReceiptType.commonEelec">
          <a-form-item label="邮箱地址">
            <a-input
              placeholder="请输入邮箱地址"
              v-decorator="[ 'express[email]', { rules: [$validate.required, $validate.email], initialValue: '', validateTrigger: 'blur'}]"
            ></a-input>
          </a-form-item>
        </template>
        <template v-else>
          <a-form-item label="邮寄地址">
            <a-input
              placeholder="请输入邮寄地址"
              v-decorator="[ 'express[address]', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'}]"
            ></a-input>
          </a-form-item>
          <a-form-item label="收件人姓名">
            <a-input
              placeholder="请输入收件人姓名"
              v-decorator="[ 'express[name]', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'}]"
            ></a-input>
          </a-form-item>
          <a-form-item label="收件人手机号">
            <a-input
              placeholder="请输入收件人手机号"
              v-decorator="[ 'express[phone]', { rules: [$validate.required, $validate.phone], initialValue: '', validateTrigger: 'blur'}]"
            ></a-input>
          </a-form-item>
        </template>
      </ng-detail>
    </a-form>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import {
    ReceiptHeaderTypeOptions,
    ReceiptTypeOptions,
    BusinessSceneOptions
  } from '@/config/options';
  import {toOptionName} from '@/utils/function';

  let newSceneOptions = BusinessSceneOptions.filter(item => item.value != '0');

  export default {
    data() {
      return {
        show: false,

        BusinessSceneOptions: newSceneOptions,

        confirmLoading: false,

        info: {}
      }
    },

    props: {
      handle: String,
      options: Object,
      id: String,
      defaultValue: {
        type: Object,
        default() {
          return {}
        }
      },
    },

    filters: {
      toReceiptType(value) {
        return toOptionName(ReceiptTypeOptions, value);
      },

      toReceiptTitleType(value) {
        return toOptionName(ReceiptHeaderTypeOptions, value);
      },
    },

    beforeCreate() {
      this.form = this.$form.createForm(this);
    },

    watch: {
      visible(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.setDefaultValue(this.defaultValue);
          })
        }
      }
    },

    mixins: [dialogMixins],

    methods: {

      handleOk() {
        this.form.validateFieldsAndScroll((errors, values) => {
          if (!errors) {
            this.editComtract(values);
          }
        })
      },

      handleCancel() {
        this.$emit('update:visible', false);
      },

      setDefaultValue(values) {
        this.form.resetFields();

        if (values) {
          values.title && this.form.setFieldsValue({
            'title[name]': values.title.name,
            'title[taxer_number]': values.title.taxer_number,
            'title[phone]': values.title.phone,
            'title[address]': values.title.address,
            'title[bank]': values.title.bank,
            'title[account]': values.title.account,
          });

          values.express && this.form.setFieldsValue({
            'express[email]': values.express.email,
            'express[address]': values.express.address,
            'express[name]': values.express.name,
            'express[phone]': values.express.phone,
          });
        }
      },

      editComtract(params) {
        this.confirmLoading = true;

        params.invoice_id = this.id;

        this.$axios.BuyManage_receiptEdit(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('编辑成功');
            this.$emit('success');

            this.$emit('update:visible', false);
          }

          this.confirmLoading = false;
        }).catch(() => {
          this.confirmLoading = false;
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .info-list {
    line-height: 2.2;
  }
</style>
