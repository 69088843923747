import {
  ServeType
} from '@/utils/variables'

const LibraryEnterServeManage = () => import('@/pages/enterManage/ServeManage.vue');
const LibraryEnterServeTask = () => import('@/pages/enterTask/EnterTask.vue');

export default [

  // 入库管理 start
  {
    path: '/libraryenter/manage/noserve',
    name: 'libraryEnterManageUndo',
    meta: {
      type: ServeType.UNDO
    },
    component: LibraryEnterServeManage,
  },
  {
    path: '/libraryenter/manage/serving',
    name: 'libraryEnterManageDoing',
    meta: {
      type: ServeType.DOING
    },
    component: LibraryEnterServeManage,
  },
  {
    path: '/libraryenter/manage/servedone',
    name: 'libraryEnterManageDone',
    meta: {
      type: ServeType.DONE
    },
    component: LibraryEnterServeManage,
  },
  // 入库管理 end

  // 入库任务 start
  {
    path: '/libraryenter/task/noserve',
    name: 'libraryEnterTaskUndo',
    meta: {
      type: ServeType.UNDO
    },
    component: LibraryEnterServeTask,
  },
  {
    path: '/libraryenter/task/serving',
    name: 'libraryEnterTaskDoing',
    meta: {
      type: ServeType.DOING
    },
    component: LibraryEnterServeTask,
  },
  {
    path: '/libraryenter/task/servedone',
    name: 'libraryEnterTaskDone',
    meta: {
      type: ServeType.DONE
    },
    component: LibraryEnterServeTask,
  },
  // 入库任务 end
]