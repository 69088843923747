<template>
  <ng-table
    class="ng-table"
    :columns="columns"
    :params="params"
    :rowKey="row => row.lib_id"
    :request="request"
    :load.sync="loadTable"
    :reload.sync="reloadTable"
    :scroll="{y: scrollyHeight - 20}"
    @data-change="requestSuccess"
  >
    <!-- 头像 -->
    <template slot="profile_photo" slot-scope="text, scope">
      <ng-image
        class="table-header"
        :src="scope.logo_url ? `${scope.logo_url}?x-oss-process=style/thumb_10` : $var.defaultHeader"
        @error="({target}) => target.src = $var.defaultHeader"
      />
    </template>

    <template slot="name" slot-scope="text, scope">
      <!-- ?user_id=${scope.user_id}&supplier_type=${user_id ? supplierType : 'manage'}` /${supplierType}/${$route.params.user_id} -->
      <router-link
        :to="`/librarydetail/${scope.lib_id}?user_id=${scope.user_id}&supplier_type=${supplierType || ''}`"
      >{{text}}</router-link>
    </template>

    <template slot="contract_data.auth_type" slot-scope="text">
      <span>{{text | toAuthType}}</span>
    </template>

    <template slot="contract_data.auth_area" slot-scope="text">
      <span>{{text | toArea}}</span>
    </template>

    <template slot="contract_data.deadline" slot-scope="text, scope">
      <span>
        {{scope.contract_data.coop_start_date || $config.emptyText}} -
        <br />
        {{scope.contract_data.coop_end_date || $config.emptyText}}
      </span>
    </template>

    <template slot="contract_data.auth_scene" slot-scope="text, scope">
      <div v-for="(item, index) in scope.contract_data.auth_scene" :key="index">
        <span>{{item.type | toAuthScene}}：</span>
        <span>{{item.ratio ? `${item.ratio}%` : $config.emptyText}}</span>
      </div>
    </template>

    <template slot="status" slot-scope="text, scope">
      <table-switch
        v-if="$root.$checkAuth($auth[authName] && $auth[authName].status)"
        :options="text >= 0 ? SellOperateOptions.filter(item => item.value >= 0) : SellOperateOptions"
        :default-value="parseInt(text) || 0"
        :format="{id: 'value', name: 'label'}"
        :change="function() { onChangeSwitch(...arguments, scope) }"
      ></table-switch>
      <span v-else :class="text == 0 ? 'font-error' : 'font-success'">{{text | toSellStatus}}</span>
    </template>

    <template slot="operation" slot-scope="text, scope">
      <a-button
        v-if="$root.$checkAuth($auth[authName] && $auth[authName].edit)"
        class="m-right-ms"
        type="link"
        @click="handleFunc($var.Handle.edit, scope)"
        :disabled="scope.op_update == 0"
      >编辑</a-button>

      <router-link
        v-if="$root.$checkAuth($auth[authName] && $auth[authName].look)"
        class="m-right-ms"
        :to="`/librarybatch/${scope.lib_id}?user_id=${scope.user_id}&supplier_type=${supplierType || ''}`"
        :disabled="scope.op_manage_batch == 0"
      >批次管理</router-link>

      <a-button
        v-if="$root.$checkAuth($auth[authName] && $auth[authName].del)"
        class="ant-btn-error"
        type="link"
        @click="$root.dontFinished"
        :disabled="scope.op_update == 0"
      >删除</a-button>
    </template>
  </ng-table>
</template>

<script>
  import {tableMixins} from '@/mixins';

  import {AuthAreaOptions, AuthTypeOptions, BusinessSceneOptions, SellStatusOptions, SellOperateOptions} from '@/config/options';
  import {toOptionName} from '@/utils/function';

  import * as TabCol from '@/config/columns';

  const columns = [
    TabCol.colId,
    TabCol.portrait('封面', 'profile_photo'),
    TabCol.commonKey('曲库名称', 'name', {ellipsis: true, isSlot: true}),
    TabCol.commonKey('授权地域', 'contract_data.auth_area', null, true),
    TabCol.commonKey('授权类型', 'contract_data.auth_type', null, true),
    TabCol.commonKey('合作期限', 'contract_data.deadline', null, true),
    TabCol.commonKey('授权场景&分成比例', 'contract_data.auth_scene', null, true),
    TabCol.commonKey('售卖状态', 'status', '120px', true),
    TabCol.operation('160px'),
  ];

  export default {
    data() {
      return {
        columns,
        tableList: [],
        loading: false,
        SellOperateOptions,
        showAddMembersDialog: false,
        handleData: {},
        params: {},
      }
    },

    props: {
      authName: String,
      scrollyHeight: {
        type: Number,
        default: 400
      },
      reload: Boolean,
      user_id: String,
      supplierType: String,
      tableParams: Object,
    },

    mounted() {
      let user_id = this.user_id;
      let {page, name} = this.$route.query;
      let params = {page: page || 1, name};

      user_id && (params.user_id = user_id || 0)

      this.params = params;
    },

    filters: {
      toArea(value) {
        return toOptionName(AuthAreaOptions, value);
      },

      toAuthType(value) {
        return toOptionName(AuthTypeOptions, value);
      },

      toAuthScene(value) {
        return toOptionName(BusinessSceneOptions, value);
      },

      toSellStatus(value) {
        return toOptionName(SellStatusOptions, value);
      }
    },

    computed: {
      fromSuppllier() {
        return !!this.user_id;
      },

      request() {
        return this.fromSuppllier ? this.$axios.Library_list : this.$axios.LibraryManage_list;
      }
    },

    watch: {
      tableParams(newVal) {
        this.$nextTick(() => {
          this.params = Object.assign({}, this.params, newVal || {});
        })
      },
    },

    mixins: [
      tableMixins
    ],

    methods: {
      handleFunc(key, scope) {
        this.$emit('handle', Object.assign(scope, {
          handleName: key
        }))
      },

      onChangeSwitch(key, options, next, loading, scope) {
        loading(true);

        let param = {
          lib_id: scope.lib_id,
          status: key
        }

        this.$axios.Library_startSale(param).then(res => {
          let data = res.data;

          loading(false);

          if (data && data.code == 0) {
            next();

            key == 1 ? this.$message.success('已上架') : this.$message.error('已下架');

            this.loadTableData();
          }
        })
      },

      requestSuccess(data) {
        this.tableList = data.data.list ? data.data.list : [];
      },
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .ant-table td {
    white-space: nowrap;
  }
</style>
