<template>
  <main-structure class="ng-account-members" dir="ver">
    <template slot="header">
      <span class="title">批次管理</span>
      <div class="header-content">
        <a-button
          v-if="$root.$checkAuth($auth[name] && $auth[name].add)"
          type="sub"
          @click="handleFunc($var.Handle.add)"
        >新增批次</a-button>
      </div>
    </template>

    <template>
      <div class="m-top-ms flex-0">
        <ng-breadcrumb :data="breadData"></ng-breadcrumb>
      </div>

      <div class="m-top-base flex-1" ref="ngTableBox">
        <batch-table
          v-if="$root.$checkAuth($auth[name] && $auth[name].list)"
          :scrolly-height="mainContentScrollMaxHeight"
          :auth-name="name"
          @handle="onTableHandleSuccess"
          ref="ngTable"
        ></batch-table>
      </div>
    </template>

    <dialog-manage-batch
      :visible.sync="showManageBatch"
      @success="handleSuccess"
      :lib_id="$route.params.lib_id"
    ></dialog-manage-batch>
  </main-structure>
</template>

<script>
  import BatchTable from './batch/BatchTable.vue';
  import DialogManageBatch from './batch/DialogManageBatch.vue';

  import {mainContentMixin} from '@/mixins';
  import {SupplierTypeDir} from '@/utils/variables'

  const breadData = function (query) {
    let {supplier_type, user_id} = query;

    if (!supplier_type) {
      return [
        {breadcrumbName: `曲库管理`, path: `/librarymanage`},
        {breadcrumbName: '批次管理'}
      ]
    } else {
      return [
        {breadcrumbName: `${SupplierTypeDir[supplier_type]}管理`, path: `/supplier/${supplier_type}`},
        {breadcrumbName: `曲库管理`, path: `/library/${supplier_type}/${user_id}`},
        {breadcrumbName: '批次管理'}
      ]
    }
  }

  export default {
    data() {
      return {
        name: `batch_${this.$route.query.supplier_type}`,

        showManageBatch: false,

        breadData: breadData(this.$route.query),

        isReloadTable: false,
      }
    },
    components: {
      BatchTable,
      DialogManageBatch,
    },

    mixins: [
      mainContentMixin
    ],

    methods: {

      handleFunc() {
        this.showManageBatch = true;
      },

      onTableHandleSuccess(key) {
        console.log(key);
      },

      handleSuccess(isLoad) {
        this.showManageBatch = false;
        isLoad !== false ? this.$refs.ngTable.reloadTableData() : this.$refs.ngTable.loadTableData();
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
