<template>
  <div class="login-box">
    <div class="login-logo">
      <img src="@/assets/images/login-logo.png" alt="火花音悦" />
    </div>
    <div class="login-content">
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item label="账号 / Account" :required="false">
          <a-input
            size="large"
            placeholder="请输入登录邮箱"
            v-decorator="['email', { rules: [$validate.required, $validate.email] }]"
          />
        </a-form-item>

        <a-form-item label="密码 / Password" :required="false">
          <a-input-password
            size="large"
            placeholder="请输入密码"
            v-decorator="['password', { rules: [$validate.required, $validate.letter_number, $validate.pwd_len], validateFirst: true }]"
          />
        </a-form-item>

        <a-button
          class="submit-btn"
          type="primary"
          size="large"
          html-type="submit"
          :loading="submiting"
        >登录</a-button>
      </a-form>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        form: this.$form.createForm(this, {}),
        submiting: false,
      }
    },
    methods: {
      handleSubmit(e) {
        e.preventDefault();

        this.form.validateFields((err, fieldsValue) => {
          if (err) {
            return;
          }

          let param = fieldsValue;

          this.submiting = true;

          this.$axios.Login(param).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              this.$message.success('登录成功');
              this.$router.replace('/');
              this.$store.dispatch('inLogin', data.data || {});
            }

            this.submiting = false;
          }).catch(error => {
            console.error(error);
            this.submiting = false;
          })
        })

      }
    }
  }
</script>

<style lang="scss" scoped>
  .login-box {
    position: relative;
    height: 100%;
    background: linear-gradient(to bottom, #1CB5E0, #000046);
  }

  .login-logo {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
    height: 300px;

    > img {
      width: 100%;
      height: 100%;
    }
  }

  .login-content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 380px;
    transform: translate(-50%, -50%);

    /deep/ .ant-form-item-label {
      margin-bottom: 10px;
      line-height: 1;

      label {
        font-size: 16px;
        color: #ffffff;
      }
    }
  }

  .submit-btn {
    width: 100%;
    // background-color: #0061f3;
    // border-color: #0061f3;
  }

  @media screen and (max-height: 800px) {
    .login-logo {
      width: 250px;
      height: 250px;
    }

    .login-content {
      width: 320px;
    }
  }

  @media screen and (max-height: 700px) {
    .login-logo {
      width: 200px;
      height: 200px;
    }

    .login-content {
      width: 280px;
    }
  }
</style>
