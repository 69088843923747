<template>
  <main-structure class="ng-sell-way" dir="ver" :loading="$store.state.contentLoading">
    <template slot="header">
      <div class="m-top-ms flex-0">
        <ng-breadcrumb :data="breadData"></ng-breadcrumb>
      </div>

      <transition>
        <div
          v-if="curTabIndex === TabKey.auth && $root.$checkAuth($auth[name] && $auth[name].editAuth)
            || curTabIndex === TabKey.scene && $root.$checkAuth($auth[name] && $auth[name].editScene)"
          class="header-content header-search-content"
        >
          <!-- 授权配置没有编辑 -->
          <template v-if="isEditDir[curTabIndex] || curTabIndex === TabKey.auth">
            <a-button class="w-px-100 m-right-base" @click="goCancel">取消</a-button>
            <a-button class="w-px-100" type="sub" @click="goSave">保存</a-button>
          </template>
          <template v-else>
            <a-button class="w-px-100" type="sub" @click="goEdit">编辑</a-button>
          </template>
        </div>
      </transition>
    </template>

    <div>
      <a-tabs v-model="curTabIndex" :animated="false">
        <template v-for="item in TabArr">
          <a-tab-pane :tab="item.name" :key="item.key">
            <component
              :is="item.component"
              :goods_id="goods_id"
              :custom-data="fromCustomOriginData[item.label] || []"
              :data="priceInfo[item.label] || []"
              :is-edit="isEditDir[item.key]"
              :ref="`tabPane${item.key}`"
            ></component>
          </a-tab-pane>
        </template>
      </a-tabs>
    </div>
    <!-- <a-spin :spinning="spinning1" class="pos-all"></a-spin> -->
  </main-structure>
</template>

<script>
  import AuthConfig from './sellway/ConfigAuth.vue'
  import SceneConfig from './sellway/ConfigScene.vue'

  import Observe from '~/utils/observe.c.js'

  import * as  $func from '@/utils/function';

  import {transferParamFromObjToStr} from '@/utils/function'

  const breadData = function (query) {
    let {goods_id} = query;
    let CommonArr = [{breadcrumbName: `商品管理`, path: `/goods/manage`}]

    if (goods_id) {
      return [
        ...CommonArr,
        {breadcrumbName: '自定义售价方案'}
      ]
    } else {
      return [
        ...CommonArr,
        {breadcrumbName: '通用定价配置'}
      ]
    }
  }

  // 下标
  const TabKey = {
    scene: '1',
    auth: '2'
  }

  const TabArr = [
    {
      name: '场景配置',
      key: TabKey.scene,
      component: 'SceneConfig',
      label: 'scene',
    },
    // {
    //   name: '授权配置',
    //   key: TabKey.auth,
    //   component: 'AuthConfig',
    //   label: 'auth',
    // }
  ]

  export default {
    data() {
      return {
        name: 'sell_config',
        breadData: breadData(this.$route.query),
        curTabIndex: TabArr[0].key,
        TabArr,
        TabKey,

        isEditDir: {
          [TabArr[0].key]: false,
          // [TabArr[1].key]: false,
        },

        priceInfo: {},

        fromCustomOriginData: {},
      }
    },

    computed: {
      goods_id() {
        return this.$route.query && this.$route.query.goods_id;
      },

      goods_type() {
        return this.$route.query && this.$route.query.goods_type;
      },

      isCustom() {
        return this.goods_id;
      },

      infoRequest() {
        return this.isCustom ? this.$axios.Price_PriceCustomInfo : this.$axios.Price_PriceCommonInfo;
      },

      saveRequest() {
        if (this.curTabIndex === TabKey.scene) {
          return this.isCustom ? this.$axios.Price_ChangeCustomScene : this.$axios.Price_ChangeCommonScene;
        } else {
          return this.isCustom ? this.$axios.Price_ChangeCustomAuth : this.$axios.Price_ChangeCommonAuth;
        }
      },

      // 已定价
      donePrice() {
        return this.$route.query && this.$route.query.done_price == '1';
      }
    },

    watch: {
      infoRequest: {
        handler(newVal) {
          if (newVal && typeof newVal === 'function') {
            this.getPriceInfo()
          }
        },
        immediate: true
      },
    },

    mounted() {
      Observe.subscribe('reloadSellWayInfo', this.getPriceInfo);
    },

    destroyed() {
      Observe.unsubscribe('reloadSellWayInfo');
    },

    components: {
      AuthConfig, SceneConfig
    },
    methods: {
      goEdit() {
        let curIndex = this.curTabIndex;

        this.isEditDir[curIndex] = true;
      },

      goSave() {
        let curTabIndex = this.curTabIndex;
        let {scene, auth} = TabKey;

        if (curTabIndex === scene) {
          this.saveScene();
        }
        // 
        else if (curTabIndex === auth) {
          this.saveAuth();
        }
      },

      saveAuth() {
        let ele = this.$refs[`tabPane${TabKey.auth}`];

        if (!ele || !ele[0]) console.warn('ele dont be found');

        ele[0] && ele[0].saveData && ele[0].saveData().then(values => {
          let params = {auth: values};

          if (this.isCustom) {
            params.goods_id = this.goods_id;
            params.type = this.goods_type;
          }

          this.saveData(params);
        })
      },

      saveScene() {
        let ele = this.$refs[`tabPane${TabKey.scene}`];

        if (!ele || !ele[0]) console.warn('ele dont be found');

        ele[0] && ele[0].saveData && ele[0].saveData().then(value => {
          let params = {scene: value};

          if (this.isCustom) {
            params.goods_id = this.goods_id;
            params.type = this.goods_type;
          }

          if (!params.scene || params.scene.length === 0) {
            this.$message.error('场景不可为空');
            return false;
          }

          this.saveData(params);
        })
        // .catch(err => {
        // console.warn(err)
        // });
      },

      saveData(params) {
        params = transferParamFromObjToStr(params);

        // 将比率小数转为整数
        Object.keys(params).forEach(item => {
          if (/ratio/.test(item)) params[item] = params[item] ? $func.mul(params[item], 100) : params[item]
          else if (/is_enabled/.test(item)) params[item] = params[item] === true ? '1' : params[item] ? params[item] : '0'
        })

        this.$store.commit('changeContentLoading', true);

        return new Promise(resolve => {
          this.saveRequest && typeof this.saveRequest === 'function' && this.saveRequest(params).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              this.$message.success('保存成功');
              this.getPriceInfo();
              this.isEditDir[this.curTabIndex] = false;
            }

            resolve(data);

            this.$store.commit('changeContentLoading', false);
          }).catch(() => {
            this.$store.commit('changeContentLoading', false);
          })
        })
      },

      goCancel() {
        let curIndex = this.curTabIndex;

        this.isEditDir[curIndex] = false;
      },

      getPriceInfo() {
        this.$store.commit('changeContentLoading', true);

        let params = {};

        if (this.isCustom) {
          params.goods_id = this.goods_id
          params.type = this.goods_type;
        }

        return new Promise(resolve => {
          this.infoRequest(params).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              let {auth, scene} = data.data;
              this.checkSceneCanEdit(data.data);

              this.fromCustomOriginData = {...data.data};

              let donePrice = this.donePrice;
              let doneAuth = auth && Object.keys(auth).length > 0

              if (scene && scene.length > 0) donePrice = true;

              // 已定价 并且权限已设置
              if (donePrice && doneAuth || !this.isCustom) {
                this.priceInfo = data.data;
                this.$store.commit('changeContentLoading', false);
              } else if (!donePrice && doneAuth) {
                this.getDefaultData(data.data, 'scene');
              } else if (!doneAuth && donePrice) {
                this.getDefaultData(data.data, 'auth');
              } else {
                this.getDefaultData(data.data, 'all');
              }

              resolve(data.data);
            }
          }).catch(() => {
            this.$store.commit('changeContentLoading', false);
          })
        })
      },

      checkSceneCanEdit(newVal) {
        if (newVal && Object.keys(newVal).length > 0) {
          let {scene} = newVal;
          let isLook = scene && scene.length > 0;

          let isEditDir = {...this.isEditDir};

          Object.keys(isEditDir).forEach(item => {
            isEditDir[item] = !isLook;
          })

          this.isEditDir = isEditDir;
        }
      },

      getDefaultData(curData, key) {
        let params = {
          goods_id: this.goods_id,
          type: this.goods_type
        }

        this.$store.commit('changeContentLoading', true);

        this.$axios.Price_DefaultCustomeInfo(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            let {scene, auth} = data.data;

            if (key === 'auth') {
              this.priceInfo = Object.assign(curData, {auth});
            } else if (key === 'scene') {
              this.priceInfo = Object.assign(curData, {scene});
            } else {
              this.priceInfo = Object.assign(curData, data.data);
            }
          }

          this.$store.commit('changeContentLoading', false);
        }).catch(() => {
          this.$store.commit('changeContentLoading', false);
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .ng-sell-way {
    /deep/ {
      .ant-tabs-content {
        height: 100%;
        overflow: auto;
        padding-bottom: 40px;
      }

      .auth-config {
        padding-bottom: 100px;
        padding-left: 40px;
      }
    }
  }
</style>
