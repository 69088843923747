<template>
  <ng-card>
    <template>
      <div class="detail-item">
        <h3>修改密码</h3>
        <div>建议定期更改密码以保证账户安全</div>
      </div>

      <div v-if="editable" slot="right">
        <template v-if="data.phone">
          <a-button type="link" @click="handleItem($var.Handle.edit)">变更</a-button>
        </template>
        <template v-else>
          <a-button type="link" @click="handleItem($var.Handle.add)">关联</a-button>
        </template>
      </div>
    </template>

    <dialog-add
      :type="type"
      :handle="handleType"
      @success="$emit('success')"
      :visible.sync="showAddDialog"
    ></dialog-add>

    <dialog-verify
      :type="type"
      :number="data.phone"
      :handle="handleType"
      @change="onVerifyChange"
      :visible.sync="showVerifyDialog"
    ></dialog-verify>
  </ng-card>
</template>

<script>
  import {NgCard} from '@/components/index';
  import DialogAdd from './DialogAdd.vue';
  import DialogVerify from './DialogVerify.vue';

  export default {
    data() {
      return {
        type: 'phone',
        showAddDialog: false,
        showVerifyDialog: false,
        handleType: this.$var.Handle.add,
      }
    },

    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      },
      editable: {
        type: Boolean,
        default: false,
      }
    },

    components: {
      NgCard, DialogAdd, DialogVerify
    },

    methods: {
      onVerifyChange() {
        this.showAddDialog = true;
      },

      handleItem(type) {
        this.handleType = type;

        if (type === this.$var.Handle.add) {
          this.showAddDialog = true;
        }
        else if (type === this.$var.Handle.edit) {
          this.showVerifyDialog = true;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .detail-item {
    padding: 15px;
  }
</style>
