<template>
  <main-structure class="ng-library" dir="ver">
    <template slot="header">
      <span class="title">发票管理</span>

      <div class="header-content header-search-content">
        <a-input-search
          class="ng-input-search"
          placeholder="请输入订单号或申请编号"
          :default-value="$route.query.keyword || ''"
          @search="onSearch"
          allowClear
        >
          <a-button slot="enterButton" type="sub">
            <a-icon type="search" />
          </a-button>
        </a-input-search>
      </div>
    </template>

    <template>
      <filter-table @change="onFilterChange" :defaultData="$route.query" class="m-top-base"></filter-table>

      <div class="m-top-base flex-1" ref="ngTableBox">
        <manage-table
          v-if="$root.$checkAuth($auth[name] && $auth[name].list)"
          :scrolly-height="mainContentScrollMaxHeight"
          :auth-name="name"
          :table-params="tableParams"
          @handle="onHandleFunc"
          ref="ngTable"
        ></manage-table>
      </div>
    </template>

    <dialog-contract-info
      :auth-name="name"
      :visible.sync="showDialogInfo"
      :id="currentData.invoice_id"
    ></dialog-contract-info>

    <dialog-contract-edit-info
      :auth-name="name"
      :visible.sync="showDialogEditInfo"
      :id="currentData.invoice_id"
      :defaultValue="currentData"
      @success="onSuccess"
    ></dialog-contract-edit-info>
  </main-structure>
</template>

<script>
  import ManageTable from './receipt/ManageTable.vue';
  import FilterTable from './receipt/FilterTable.vue'
  import DialogContractInfo from './receipt/DialogReceiptInfo.vue'
  import DialogContractEditInfo from './receipt/DialogReceiptEditInfo.vue'

  import {mainContentMixin, filterReq} from '@/mixins';

  export default {
    data() {
      return {
        name: `receipt_manage`,

        isReloadTable: false,

        tableParams: {},

        showDialogInfo: false,
        showDialogEditInfo: false,

        currentData: {}
      }
    },

    mounted() {
      let {time = [], page = 1, is_operated, keyword} = this.$route.query;

      let params = {time, page, is_operated, keyword};

      params = this.parseArrParams({...params});

      this.tableParams = params;
    },

    components: {
      ManageTable, FilterTable,
      DialogContractInfo,
      DialogContractEditInfo
    },

    mixins: [
      mainContentMixin, filterReq
    ],

    methods: {
      onFilterChange(values) {
        let params = Object.assign({}, this.tableParams, values);

        params = this.parseArrParams(params);

        this.tableParams = this.filterCommon(params);
      },

      onSearch(keyword) {
        typeof keyword === 'string' && (keyword = keyword.trim());

        let search = {keyword, page: 1};

        this.$root.$goSearch(search);

        this.tableParams = search;
      },

      onHandleFunc(option) {
        let handleName = option.handleName;

        this.currentData = option;

        if (handleName === this.$var.Handle.look) {
          this.showDialogInfo = true;
        } else if (handleName === this.$var.Handle.edit) {
          this.showDialogEditInfo = true;
        }
      },

      onSuccess() {
        let ngTable = this.$refs.ngTable;

        ngTable && typeof ngTable.loadTableData === 'function' && ngTable.loadTableData();
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
