<template>
  <main-structure class="ng-verify-detail ng-detail" dir="ver" :loading="loading">
    <template slot="header">
      <ng-breadcrumb :data="breadData"></ng-breadcrumb>
    </template>

    <template>
      <template v-for="(item, index) in contentKeys">
        <div class="ng-detail-item" :key="index">
          <h4 class="ng-detail-title">{{item.title}}</h4>
          <table class="ng-detail-content table-layout">
            <tr v-for="cItem in item.children" :key="cItem.key">
              <td>{{cItem.name}}</td>

              <!-- 证件类型 -->
              <td v-if="cItem.key === 'id_type'">
                <div>
                  <span>{{$root.$getItemWithKey(CertificateType, data.id_type).label}}</span>&nbsp;&nbsp;
                  <span>{{data.id_number}}</span>
                </div>
                <div>
                  <div class="img-box">
                    <a :href="data.id_photo" class="w-100 h-100" target="_blank">
                      <ng-image class="img" :src="data.id_photo" alt />
                    </a>
                  </div>
                </div>
              </td>
              <!-- 营业执照 -->
              <td v-else-if="cItem.key === 'business_license'">
                <a
                  :href="data.business_license ? `${data.business_license}` : ''"
                  class="img-box w-100 h-100"
                  target="_blank"
                >
                  <ng-image
                    class="img"
                    :src="data.business_license ? `${data.business_license}` : ''"
                    alt
                  />
                </a>
              </td>
              <!-- 手机号 -->
              <td v-else-if="cItem.key === 'phone'">
                <div>+{{data.phone_code ?data.phone_code.prefix : ''}} {{data.phone}}</div>
              </td>

              <!-- 作品 -->
              <td v-else-if="cItem.key === 'showreel'">
                <template v-for="(gItem, gIndex) in data.showreel">
                  <ng-video-tag
                    class="table-video-tag"
                    :url="gItem.url"
                    :text="gItem.name || ''"
                    :playing="$store.state.globalVideoPlaying && $store.state.globalVideoInfo.url === gItem.url"
                    @play="onPlayVideo(gItem)"
                    :key="gIndex"
                  ></ng-video-tag>
                </template>
              </td>
              <td v-else>{{data[cItem.key] || '-'}}</td>
            </tr>
          </table>
        </div>
      </template>
    </template>

    <div v-if="data.check_status === '0'" class="detail-bottom-btns-box">
      <a-button class="op-btn" type="primary" size="large" @click="handleFunc($var.Handle.agree)">通过</a-button>
      <a-button class="op-btn" type="danger" size="large" @click="handleFunc($var.Handle.refuse)">拒绝</a-button>
    </div>
    <div v-if="data.check_status === '1'" class="detail-bottom-btns-box">
      <a-tooltip
        v-if="data.check_result === '1'"
        placement="top"
        :title="`通过理由：${data.check_reason || '--'}`"
        arrow-point-at-center
      >
        <a-button class="op-btn color-disabled" size="large" type="primary">已通过</a-button>
      </a-tooltip>
      <a-tooltip
        v-else
        placement="top"
        :title="`拒绝理由：${data.check_reason || '--'}`"
        arrow-point-at-center
      >
        <a-button class="op-btn color-disabled" size="large" type="danger">已拒绝</a-button>
      </a-tooltip>
    </div>

    <verify-confirm
      :visible.sync="showVerifyConfirm"
      :options="confirmOptions"
      :type="$route.params.type"
      :handle="confirmHandle"
      @success="verifySuccess"
    ></verify-confirm>
  </main-structure>
</template>

<script>
  import {NgBreadcrumb} from '@/components/index';
  import {NgVideoTag} from '@/components/index';
  import VerifyConfirm from './dialog/VerifyConfirm.vue';
  import {CertificateType} from '@/config/options'
  import {SupplierType, SupplierTypeDir} from '@/utils/variables'

  const breadData = function (name = SupplierType.MUSICIAN, SupplierTypeDir) {
    return [
      {breadcrumbName: `${SupplierTypeDir[name]}审核`, path: `/verify/${name}`},
      {breadcrumbName: `${SupplierTypeDir[name]}详情`}
    ]
  }

  // 音乐人信息
  const musicianBase = [
    {key: 'name', name: '姓名'},
    {key: 'stage_name', name: '艺名'},
    {key: 'id_type', name: '证件类型'},
    {key: 'address', name: '国籍'},
    {key: 'desc', name: '个人描述'},
  ]

  // 音乐团体信息
  const teamBase = [
    {key: 'name', name: '团体名称'},
    {key: 'address', name: '所在地'},
    {key: 'desc', name: '描述'},
  ]

  // 音乐公司
  const companyBase = [
    {key: 'name', name: '公司名称'},
    {key: 'brand_name', name: '厂牌名称'},
    {key: 'address', name: '所在地'},
    {key: 'business_license', name: '营业证明'},
    {key: 'desc', name: '简介'},
  ]

  // 音乐人信息
  const musicianLink = [
    {key: 'email', name: '邮箱'}
  ]

  // 音乐团体信息
  const teamLink = [
    {key: 'linkman', name: '联系人姓名'},
    {key: 'id_type', name: '证件类型'},
    {key: 'email', name: '邮箱'}
  ]

  // 音乐公司
  const companyLink = [
    {key: 'linkman', name: '联系人姓名'},
    {key: 'id_type', name: '证件类型'},
    {key: 'linkman_position', name: '职位'},
    {key: 'phone', name: '手机号'},
    {key: 'email', name: '邮箱'}
  ]

  // 对应基本数据
  const baseDir = {
    [SupplierType.MUSICIAN]: musicianBase,
    [SupplierType.TEAM]: teamBase,
    [SupplierType.COMPANY]: companyBase
  }

  // 对应联系数据
  const linkDir = {
    [SupplierType.MUSICIAN]: musicianLink,
    [SupplierType.TEAM]: teamLink,
    [SupplierType.COMPANY]: companyLink
  }

  const contentKeys = function (name = SupplierType.MUSICIAN, baseDir) {
    return [
      {
        title: '个人信息',
        children: [
          ...(baseDir[name] || [])
        ]
      },
      {
        title: '联系方式',
        children: [
          ...(linkDir[name] || [])
        ]
      },
      {
        title: '作品信息',
        children: [
          {key: 'showreel', name: '上传作品'}
        ]
      }
    ]
  }

  // 请求对应方法
  const requestMethods = {
    [SupplierType.MUSICIAN]: 'Ver_musicianInfo',
    [SupplierType.TEAM]: 'Ver_teamInfo',
    [SupplierType.COMPANY]: 'Ver_companyInfo'
  }

  export default {
    data() {
      return {
        data: [],
        loading: false,
        showAddMembersDialog: false,

        breadData: breadData(this.$route.params.type, SupplierTypeDir),
        contentKeys: contentKeys(this.$route.params.type, baseDir),

        CertificateType,

        confirmHandle: '',
        confirmOptions: {},

        showVerifyConfirm: false,
      }
    },
    components: {
      NgBreadcrumb, NgVideoTag, VerifyConfirm
    },

    mounted() {
      this.param = {apply_id: this.$route.params.apply_id || 0};

      this.getData(this.param);
    },

    methods: {

      handleFunc(handle) {
        this.confirmHandle = handle;

        this.confirmOptions = {
          apply_id: this.data.apply_id
        }

        this.showVerifyConfirm = true;
      },

      onPlayVideo(scope) {
        let playing = this.$store.state.globalVideoPlaying;
        let stUrl = this.$store.state.globalVideoInfo.url;
        let scopeUrl = scope.url;

        if (stUrl !== scopeUrl) {
          this.$store.commit('changeVideoPlaying', true);
          this.$store.commit('changeVideoInfo', scope);
        } else {
          this.$store.commit('changeVideoPlaying', !playing);
        }
      },

      getData(param) {
        this.loading = true;

        let method = requestMethods[this.$route.params.type];

        this.$axios[method](param).then(res => {
          let data = res.data;

          if (data && data.code == 0) {
            this.data = data.data ? Object.assign(data.data, {...data.data.other_msg}) : [];

            this.handleData = this.data;
          }

          this.loading = false;
        })
      },

      verifySuccess() {
        this.getData(this.params);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .ng-verify-detail {
    .img-box {
      display: flex;
      align-items: center;
      justify-content: center;
      float: left;
      width: 200px;
      height: 118px;
      margin-right: 10px;
      margin-top: 10px;
      background-color: rgba(216, 216, 216, 1);

      .img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .dept-members {
      display: flex;
      flex-direction: row;
      align-items: center;

      > span {
        margin-left: 10px;
      }
    }

    .table-video-tag {
      width: 400px;
      margin-bottom: 10px;
    }

    .detail-bottom-btns-box {
      display: flex;
      justify-content: flex-end;

      .op-btn {
        width: 100px;
        margin-left: 20px;
      }
    }
  }
</style>
