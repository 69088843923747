// 全局变量
import * as Axios from '@/api';
import * as validate from '@/config/validate';
import {
  Storage,
  $storage
} from "@/utils/from-common";

import * as $var from '@/utils/variables';
import * as $val from '@/config/options.value';
import * as $config from '@/config/config';
import $toFormType from './toFormType'
import Auth from '@/config/auth';

export default {
  install(Vue) {
    Vue.prototype.$axios = Axios;

    // 表单验证模式
    Vue.prototype.$validate = validate;

    // storage
    Vue.prototype.$storage = $storage;

    // session
    Vue.prototype.$session = new Storage({
      source: window.sessionStorage
    });

    // 全局变量
    Vue.prototype.$var = $var;

    // 全局变量
    Vue.prototype.$val = $val;

    // 全局变量
    Vue.prototype.$auth = Auth;

    // 输入字段对应的录入类型
    Vue.prototype.$toFormType = $toFormType;

    // 全局配置
    Vue.prototype.$config = $config;
  }
}