import {
  SupplierType
} from '@/utils/variables'

const Verify = () => import('@/pages/verify/verify.vue');
const VerifyDetail = () => import('@/pages/verify/verifyDetail.vue');
const VerifyLibrarySign = () => import('@/pages/verify/VerifyLibrarySign.vue');

export default [{
    path: '/verify/musician',
    name: 'verifyMusician',
    meta: {
      type: SupplierType.MUSICIAN
    },
    component: Verify,
  },
  {
    path: '/verify/team',
    name: 'verifyTeam',
    meta: {
      type: SupplierType.TEAM
    },
    component: Verify,
  },
  {
    path: '/verify/company',
    name: 'verifyCompany',
    meta: {
      type: SupplierType.COMPANY
    },
    component: Verify,
  },
  {
    path: '/verify/:type/detail/:apply_id',
    name: 'verifyDetail',
    component: VerifyDetail
  },
  {
    path: '/verify/library',
    name: 'VerifyLibrarySign',
    component: VerifyLibrarySign
  },
]