  import {MusicType} from '@/config/options.value'

  const LibraryMarkMusicManage = () => import('@/pages/musicManage/MarkMusic.vue');
  const LibraryEnterMusicManage = () => import('@/pages/musicManage/EnterMusic.vue');
  const MusicManage = () => import('@/pages/musicManage/MusicManage.vue');

  export default [
    // 入库音乐管理 ?from
    {
      path: '/libraryenter/musicmanage/:task_id/:type/:from', // type 音乐类型  bgm 人声 音效
      name: 'LibraryEnterMusicManage',
      component: LibraryEnterMusicManage,
    },
    // 入库音乐管理 end
    // 标注音乐管理 ?from
    {
      path: '/librarymark/musicmanage/:id', // id (task_id 或者 batch_id（标注管理>已完成）)
      name: 'LibraryMarkMusicManage',
      component: LibraryMarkMusicManage,
    },
    // 标注任务 end
    // 标注音乐管理 ?from
    {
      path: '/musicmanage/bgm',
      name: 'MusicManageBGM',
      meta: {
        type: MusicType.BGM
      },
      component: MusicManage,
    },
    {
      path: '/musicmanage/rs',
      name: 'MusicManageRS',
      meta: {
        type: MusicType.RS
      },
      component: MusicManage,
    },
    {
      path: '/musicmanage/yx',
      name: 'MusicManageYX',
      meta: {
        type: MusicType.YX
      },
      component: MusicManage,
    },
    // 标注任务 end
  ]