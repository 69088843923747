<template>
  <ng-table
    class="ng-table"
    :columns="columns"
    :params="params"
    :rowKey="row => row.contract_id"
    :request="$axios.Contract_list"
    :load.sync="loadTable"
    :reload.sync="reloadTable"
    :scroll="{y: scrollyHeight - 20}"
  >
    <template slot="auth_type" slot-scope="text">
      <span>{{text | toAuthType}}</span>
    </template>

    <template slot="contract_no" slot-scope="text, scope">
      <a-button class="m-right-ms" type="link" @click="handleFunc($var.Handle.look, scope)">{{text}}</a-button>
    </template>

    <template slot="auth_area" slot-scope="text">
      <span>{{text | toArea}}</span>
    </template>

    <template slot="is_auto_renew" slot-scope="text, scope">
      <!-- <span>{{text | toIsOrNot}}</span> -->
      <a-switch
        :default-checked="text == '1'"
        @change="(value) =>{onChangeContinueContract(scope, value)}"
      />
    </template>

    <template slot="is_maintain_rights" slot-scope="text">
      <span>{{text | toIsOrNot}}</span>
    </template>

    <template slot="deadline" slot-scope="text, scope">
      <span>
        {{scope.coop_start_date || $config.emptyText}} -
        <br />
        {{scope.coop_end_date || $config.emptyText}}
      </span>
    </template>

    <template slot="auth_scene" slot-scope="text, scope">
      <div v-for="(item, index) in scope.auth_scene" :key="index">
        <span>{{item.type | toAuthScene}}：</span>
        <span>{{item.ratio ? `${item.ratio}%` : $config.emptyText}}</span>
      </div>
    </template>

    <template slot="operation" slot-scope="text, scope">
      <a :href="scope.contract_file_url ? scope.contract_file_url : 'javascript:;'" download>下载</a>
      <!-- <a-button class="m-right-ms" type="link" @click="downloadContract(scope)">下载</a-button> -->
    </template>
  </ng-table>
</template>

<script>
  import {tableMixins} from '@/mixins';

  import {AuthAreaOptions, AuthTypeOptions, BusinessSceneOptions} from '@/config/options';
  import {toOptionName} from '@/utils/function';

  import * as TabCol from '@/config/columns';

  const columns = [
    TabCol.colId,
    TabCol.commonKey('合同编号', 'contract_no', '', true),
    TabCol.commonKey('合作期限', 'deadline', '', true),
    TabCol.commonKey('授权场景&分润系数', 'auth_scene', '180px', true),
    TabCol.commonKey('授权地域', 'auth_area', '', true),
    TabCol.commonKey('授权类型', 'auth_type', '', true),
    TabCol.commonKey('维权授权', 'is_maintain_rights', '', true),
    TabCol.commonKey('自动续约', 'is_auto_renew', '', true),
    TabCol.operation('80px'),
  ];

  export default {
    data() {
      return {
        columns,
        tableList: [],
        loading: false,
        showAddMembersDialog: false,
        handleData: {},
        params: {},
      }
    },

    props: {
      authName: String,
      scrollyHeight: {
        type: Number,
        default: 400
      },
      reload: Boolean,
    },

    mounted() {
      this.params = {user_id: this.$route.params.user_id || 0, page: this.$route.query.page || 1};
    },

    filters: {
      toArea(value) {
        return toOptionName(AuthAreaOptions, value);
      },

      toAuthType(value) {
        return toOptionName(AuthTypeOptions, value);
      },

      toAuthScene(value) {
        return toOptionName(BusinessSceneOptions, value);
      }
    },

    mixins: [
      tableMixins
    ],

    methods: {

      onChangeContinueContract(scope, is_renew) {
        let params = {
          contract_id: scope.contract_id,
          is_renew: +is_renew
        };

        this.$axios.Library_AuthSignContract(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('修改成功');
            this.loadTable = true;
          }
        })
      },

      handleFunc(key, scope) {
        this.$emit('handle', Object.assign(scope, {
          handleName: key
        }))
      },

      downloadContract() {
        this.$message.warning('暂未开发');
        // this.tableList = data.data.list ? data.data.list : [];
      },
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .ant-table td {
    white-space: nowrap;
  }
</style>
