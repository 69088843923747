<template>
  <main-structure class="ng-library" dir="ver">
    <template slot="header">
      <span class="title">曲库管理</span>

      <div class="header-content header-search-content">
        <a-input-search
          class="ng-input-search"
          placeholder="请输入曲库名称、供应商名称以检索"
          :default-value="$route.query.name || ''"
          @search="onSearch"
          allowClear
        >
          <a-button slot="enterButton" type="sub">
            <a-icon type="search" />
          </a-button>
        </a-input-search>

        <a-button
          v-if="$root.$checkAuth($auth[name] && $auth[name].add)"
          type="sub"
          @click="handleFunc($var.Handle.add)"
        >新增曲库</a-button>
      </div>
    </template>

    <template>
      <div v-if="fromSupplier" class="m-top-ms flex-0">
        <ng-breadcrumb :data="breadData"></ng-breadcrumb>
      </div>

      <div class="m-top-base flex-1" ref="ngTableBox">
        <manage-table
          v-if="$root.$checkAuth($auth[name] && $auth[name].list)"
          :scrolly-height="mainContentScrollMaxHeight"
          :auth-name="name"
          :user_id="$route.params.user_id"
          :supplier-type="$route.params.supplier_type"
          :table-params="tableParams"
          @handle="onTableHandleSuccess"
          ref="ngTable"
        ></manage-table>
      </div>
    </template>

    <library-add
      :visible.sync="showHandleDialog"
      :handle="handleType"
      :default-value="handleData"
      :user_id="$route.params.user_id"
      @success="handleSuccess"
    ></library-add>
  </main-structure>
</template>

<script>
  import ManageTable from './components/ManageTable.vue';

  import {mainContentMixin} from '@/mixins';
  import {SupplierTypeDir} from '@/utils/variables'

  const breadData = function (type) {
    return [
      {breadcrumbName: `${SupplierTypeDir[type]}管理`, path: `/supplier/${type}`},
      {breadcrumbName: '曲库管理'}
    ]
  }

  export default {
    data() {
      return {
        // name: `library_manage`,

        showHandleDialog: false,
        showCoopHandleDialog: false,

        handleType: this.$var.Handle.add,

        handleData: {},
        // tableParams: {},
        breadData: breadData(this.$route.params.supplier_type),

        isReloadTable: false,

        tableParams: {},
      }
    },

    computed: {
      fromSupplier() {
        return this.$route.meta.from === 'supplier';
      },
      name() {
        let supType = this.$route.params.supplier_type;
        let fromSup = this.fromSupplier;

        return fromSup ? `library_${supType}` : 'library_manage'
      }
    },

    components: {
      ManageTable,
      LibraryAdd: () => import('./components/LibraryAdd.vue'),
    },

    mixins: [
      mainContentMixin
    ],

    methods: {
      onSearch(name) {
        typeof name === 'string' && (name = name.trim());

        let search = {name, page: 1};

        this.$root.$goSearch(search);

        this.tableParams = search;
      },

      handleSuccess(isLoad) {
        this.showHandleDialog = false;
        this.showCoopHandleDialog = false;
        isLoad !== false ? this.$refs.ngTable.reloadTableData() : this.$refs.ngTable.loadTableData();
      },

      onTableHandleSuccess(scope) {
        let handle = scope.handleName;
        let varHandle = this.$var.Handle;

        if (handle === varHandle.edit) {
          this.handleFunc(handle, scope);
        }
      },

      handleFunc(key, scope) {
        this.handleData = scope || {};
        this.handleType = key;
        this.showHandleDialog = true;
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
