<template>
  <main-structure class="ng-verify-detail ng-detail" dir="ver" :loading="loading">
    <template slot="header">
      <ng-breadcrumb :data="breadData"></ng-breadcrumb>
    </template>
    <div class="info-content">
      <ng-detail title="用户信息">
        <div class="flex order-info">
          <ul class="flex-1">
            <li>用户名：{{orderInfo.user ? orderInfo.user.name : ''}}</li>
            <li>用户ID：{{orderInfo.user ? orderInfo.user.user_id : ''}}</li>

            <li>注册时间：{{orderInfo.user ? orderInfo.user.create_at : ''}}</li>
            <li>最近登录时间：{{orderInfo.user ? orderInfo.user.login_time : ''}}</li>
          </ul>

          <ul class="flex-1">
            <li>用户手机号：{{orderInfo.user ? orderInfo.user.phone : ''}}</li>
            <li>用户邮箱：{{orderInfo.user ? orderInfo.user.email : '--'}}</li>
          </ul>
        </div>
      </ng-detail>

      <ng-detail class="m-top-base" title="商品信息">
        <div class="flex order-info">
          <ul class="flex-1">
            <li>套餐名称：{{orderInfo.goods_name}}</li>
            <li>授权场景：{{orderInfo.auth_scene | toAuthScene}}</li>
            <li>使用渠道：{{orderInfo.use_mode}}</li>
            <li>授权地域：{{orderInfo.auth_area | toArea}}</li>
            <li>授权期限：{{orderInfo.auth_time | toAuthPeriod}}</li>

            <li>
              <span class="m-right-md">有效期：{{orderInfo.valid_time || 0}}天</span>
              <span
                class="m-right-md"
              >开通价格：￥{{orderInfo.order ? orderInfo.order.total_amount : '--'}}</span>
              <span
                class="m-right-md"
              >活动折扣：{{orderInfo.order ? (orderInfo.order.discount_ratio ? (orderInfo.order.discount_ratio / 10) : '--') : '--'}}折</span>
              <span class="m-right-md">授权数：{{orderInfo.auth_num}}</span>
              <span class="m-right-md">下载数：{{orderInfo.download_num}}</span>
            </li>

            <li>实际支付：￥{{orderInfo.order ? orderInfo.order.pay_amount : '--'}}</li>
            <li>开通时间：{{orderInfo.create_at}}</li>
            <li>到期时间：{{orderInfo.expire_date}}</li>

            <li>
              <span class="m-right-md">总授权数：{{orderInfo.auth_num}}</span>
              <span class="m-right-md">剩余授权数：{{orderInfo.unauth_num}}</span>
            </li>
            <li>
              <span class="m-right-md">总下载数：{{orderInfo.download_num}}</span>
              <span class="m-right-md">剩余下载数：{{orderInfo.undownload_num}}</span>
            </li>
          </ul>
        </div>
      </ng-detail>
    </div>
  </main-structure>
</template>

<script>
  import {NgBreadcrumb} from '@/components/index';
  import {OrderStatusOptionsAdmin, OrderChannelOptions} from '@/config/options'
  import {toOptionName} from '@/utils/function';

  const breadData = [
    {breadcrumbName: `开通记录`, path: `/package/list`},
    {breadcrumbName: `套餐详情`}
  ]

  export default {
    data() {
      return {
        name: `packageorder_info`,
        loading: false,
        breadData,
        orderInfo: {},
        showDialog: false,
      }
    },
    components: {
      NgBreadcrumb
    },

    created() {
      this.getInfo(this.$route.params.goods_id)
    },

    filters: {
      toOrderStatus(value) {
        return toOptionName(OrderStatusOptionsAdmin, value);
      },
      toChannel(value) {
        return toOptionName(OrderChannelOptions, value);
      }
    },

    methods: {
      getInfo(id) {
        let params = {id};

        this.loading = true;

        this.$axios.Package_info(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.orderInfo = data.data;
          }

          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })
      },

      onSettelOrder() {
        this.showDialog = true;
      },

      onSuccess() {
        this.getInfo(this.$route.params.order_id)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .info-content {
    padding: 20px;
  }

  .order-info {
    line-height: 3;
  }
</style>
