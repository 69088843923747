<template>
  <div class="auth-config m-top-base">
    <a-button v-if="isEdit" type="dashed" class="w-100 add-box" size="large" @click="addScene">
      <a-icon type="plus" />添加折扣方案
    </a-button>

    <template v-if="list && list.length > 0">
      <template v-for="(item, index) in list">
        <!-- v-if="item.isEdit" -->
        <discount-edit
          v-if="item.isEdit"
          class="m-top-base"
          :index="index"
          :title="getTitle(item)"
          :default-data="item"
          :dis_id="item.discount_id"
          :sid="sid"
          @success="onSaveSuccess"
          @endEdit="onCancelEdit"
          @close="onClickClose"
          :key="item.discount_id ? item.discount_id : item.discount_id_ran"
          :ref="`sceneEdit_${index}`"
        ></discount-edit>

        <discount-info
          v-else
          class="m-top-base"
          :title="getTitle(item)"
          :index="index"
          :data="item"
          :dis_id="item.discount_id"
          :key="item.discount_id"
          @edit="onEditDiscount"
          @del="onDelDiscount"
        ></discount-info>
      </template>
    </template>
    <template v-else>
      <a-empty style="margin-top: 100px;" />
    </template>
  </div>
</template>

<script>
  import DiscountEdit from './DiscountEdit.vue';
  import DiscountInfo from './DiscountInfo.vue';

  import {Empty as AEmpty} from 'ant-design-vue';

  import {BusinessSceneOptions, AuthAreaOptions, AuthTimeOptions} from '@/config/options';
  import {toOptionName} from '@/utils/function';

  export default {
    data() {
      return {
        list: [],
        sceneInfoList: []
      }
    },

    props: {
      data: {
        type: Array,
        default() {
          return []
        }
      },
      sid: String
    },

    computed: {
      isEdit() {
        return true;
      }
    },

    filters: {
      toArea(value) {
        return toOptionName(BusinessSceneOptions, value, undefined, undefined, '');
      },
    },

    watch: {
      data: {
        handler(newVal) {
          this.list = [...newVal];
          this.curIndex = newVal && newVal.length ? newVal.length : 1;
        },
        immediate: true
      }
    },

    components: {
      DiscountInfo, DiscountEdit, AEmpty
    },

    methods: {
      addScene() {
        this.list.unshift({discount_id_ran: this.curIndex, isEdit: true});

        this.curIndex += 1;
      },

      onEditDiscount(index) {
        this.editDiscount(index, true);
      },

      onDelDiscount(index) {
        let vm = this;

        this.$confirm({
          width: '480px',
          title: '确认要删除该折扣方案吗？',
          // content: '删除该角色，关联该角色的成员账号，会失去对应的权限；',
          onOk() {
            return new Promise((resolve, reject) => {
              vm.deleteFunc(index, resolve, reject);
            });
          }
        });
      },

      deleteFunc(index, next, reject) {
        let curItem = this.list[index];
        let dis_id = curItem.discount_id;
        let params = {discount_id: dis_id};

        this.$axios.SongList_discountDel(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('删除成功');

            this.list.splice(index, 1);

            next();
          } else {
            reject();
          }
        })
      },

      onCancelEdit(index) {
        this.editDiscount(index, false);
      },

      onSaveSuccess(item) {
        let discount_id = item.discount_id;

        if (discount_id) {
          let index = item.index;

          this.editDiscount(index, false, item);
        }
      },

      onClickClose(index) {
        this.list.splice(index, 1);
      },

      editDiscount(index, bool, item) {
        let curItem = item ? item : this.list[index];

        this.list.splice(index, 1, Object.assign({}, curItem, {isEdit: bool}));
      },

      getTitle(scope) {
        let auth_scene = toOptionName(BusinessSceneOptions, scope.auth_scene, undefined, undefined, '');
        let usemode = scope.use_mode;
        let auth_area = toOptionName(AuthAreaOptions, scope.auth_area, undefined, undefined, '');
        let auth_time = toOptionName(AuthTimeOptions, scope.auth_time, undefined, undefined, '');

        let final = [auth_scene, usemode, auth_area, auth_time].filter(item => item);

        return [...final].join(' - ');
      }
    }
  }
</script>

<style lang="scss" scoped>
  .btn-group {
    position: absolute;
    top: 100px;
    right: 100px;
  }

  // .auth-config {
  //   padding-top: 60px;

  //   .add-box {
  //     position: absolute;
  //     top: 10px;
  //     left: 0;
  //     right: 0;
  //   }
  // }
</style>
