<template>
  <div>
    <Detail v-if="!isModifly" :data="detailData"></Detail>

    <!-- <modify-form v-else :data="detailData" ref="formBox"></modify-form> -->

    <!-- <div class="modifly-btn">
        <a-button class="modifly" type="sub" @click="handleBtn" :loading="btnConfirm">{{btnText}}</a-button>
    </div>-->
  </div>
</template>

<script>
  // import modifyForm from "./components/modifyForm.vue";
  import Detail from "./components/Detail.vue";

  export default {
    data() {
      return {
        title: "基本信息",
        isModifly: false,
        detailData: {},
        btnConfirm: false,
      };
    },

    props: {
      user_id: String,
    },

    components: {
      Detail,
      // modifyForm,
    },
    computed: {
      // btnText() {
      //   return this.isModifly ? "保存" : "修改";
      // },
    },

    created() {
      this.getData({user_id: this.user_id});
    },

    methods: {
      onSuccess(data) {
        this.detailData = data;
      },

      // handleBtn() {
      //   if (this.isModifly) {
      //     this.$refs.formBox.form.validateFieldsAndScroll((err, values) => {

      //       if (!err) {
      //         this.submit(values);
      //       }
      //     });
      //     return;
      //   } else {
      //     this.isModifly = !this.isModifly;
      //   }
      // },

      // submit(param) {
      //   this.btnConfirm = true;

      //   this.$axios.Base_editInfo(param).then(res => {
      //     const data = res.data;

      //     if (data && data.code == 0) {
      //       this.$message.success('修改成功');
      //       this.isModifly = false;
      //       this.getData();
      //     }

      //     this.btnConfirm = false;
      //   })
      // },

      getData(params) {
        this.$axios.User_Base_info(params).then(res => {
          const data = res.data;
          if (data && data.code == 0) {
            this.detailData = data.data;
          }
        })
      }
    }
  };
</script>

<style lang="scss" scoped>
  .modifly-btn {
    margin-top: $space-md;
    .modifly {
      width: 120px;
      height: 32px;
      border-radius: 2px;
      // background: $ng-bg;
      font-size: 14px;
      font-weight: 300;
      color: rgba(255, 255, 255, 1);
      border: none;
    }
  }
</style>
