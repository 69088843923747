const Coupon = () => import('@/pages/eventManage/Coupon.vue');
const CouponDetail = () => import('@/pages/eventManage/couponDetail.vue');

export default [
  {
    path: '/eventManage/coupon',
    name: 'coupon',
    component: Coupon,
  },
  {
    path: '/CouponDetail/:sid', // 歌单id
    name: 'CouponDetail',
    component: CouponDetail,
  },
]