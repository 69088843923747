/**
 * 账号权限管理
 */
import {
  Axios
} from '@/utils/from-common';

// 歌单列表
export const Song_list = (param) => Axios('POST', '/bms/playlist/list', param);

// 歌单详情
export const Song_info = (param) => Axios('POST', '/bms/playlist/info', param);

// 新增歌单
export const Song_add = (param) => Axios('POST', '/bms/playlist/add', param);

// 编辑歌单
export const Song_edit = (param) => Axios('POST', '/bms/playlist/save', param);

// 删除歌单
export const Song_del = (param) => Axios('POST', '/bms/playlist/del', param);

// 歌单状态
export const Song_status = (param) => Axios('POST', '/bms/playlist/upSale', param);

// 歌单 音乐列表
export const Song_musicList = (param) => Axios('POST', '/bms/playlist/musicList', param);

// 歌单 音乐列表 删除音乐
export const Song_musicList_delMusic = (param) => Axios('POST', '/bms/playlist/delMusic', param);

// 歌单 音乐列表 添加音乐
export const Song_musicList_addMusic = (param) => Axios('POST', '/bms/playlist/addMusic', param);


// 歌单折扣 
export const SongList_discountList = (param) => Axios('POST', '/bms/playlistdct/list', param);

// 歌单折扣 信息
export const SongList_discountInfo = (param) => Axios('POST', '/bms/playlistdct/info', param);

// 歌单折扣 添加
export const SongList_discountAdd = (param) => Axios('POST', '/bms/playlistdct/add', param);

// 歌单折扣 修改
export const SongList_discountEdit = (param) => Axios('POST', '/bms/playlistdct/save', param);

// 歌单折扣 修改状态
export const SongList_discountStatus = (param) => Axios('POST', '/bms/playlistdct/upStat', param);

// 歌单折扣 删除
export const SongList_discountDel = (param) => Axios('POST', '/bms/playlistdct/del', param);