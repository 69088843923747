<template>
    
  <div class="ng-aside">
    <a-menu
      mode="inline"
      theme="dark"
      v-model="selectedKeys"
      :open-keys="openKeys"
      :defaultSelectedKeys="defaultSelectedKeys"
      @openChange="onOpenChange"
    >
      <template v-for="item in menu">
        <a-menu-item v-if="!item.modules" :key="item.key">
          <router-link :to="item.path || ''">
            <a-icon v-if="item.icon" class="menu-icon">
              <use :xlink:href="`#${item.icon}`" />
            </a-icon>

            <span>{{item.name}}</span>
          </router-link>
        </a-menu-item>

        <sub-menu v-else :key="item.key" :menu-info="item"></sub-menu>
      </template>
    </a-menu>
  </div>
</template>

<script>
  import MENUCOFING from '../config/menu';

  const SubMenu = {
    template: `
      <a-sub-menu :key="menuInfo.key" v-bind="$props" v-on="$listeners">
        <span slot="title">
          <a-icon v-if="menuInfo.icon" class="menu-icon">
            <use :xlink:href="'#' + menuInfo.icon" />
          </a-icon>

          <span>{{menuInfo.name}}</span>
        </span>

        <template v-for="item in menuInfo.modules">
          <a-menu-item v-if="!item.modules" :key="item.key">
            <router-link :to="item.path || ''">
              <a-icon v-if="item.icon" class="menu-icon">
                <use :xlink:href="'#' + item.icon" />
              </a-icon>

              <span>{{item.name}}</span>
            </router-link>
          </a-menu-item>
          
          <sub-menu v-else :key="item.key" :menu-info="item" />
        </template>
      </a-sub-menu>
    `,
    name: 'SubMenu',
    props: {
      menuInfo: {
        type: Object,
        default: () => ({}),
      },
    },
  };

  export default {
    data() {
      return {
        menu: [],
        // rootSubmenuKeys: [],
        openKeys: [],
        defaultSelectedKeys: [],
        selectedKeys: []
      }
    },

    components: {
      'sub-menu': SubMenu,
    },

    watch: {
      '$route'() {
        this.initMenuOpenKeys();
      }
    },

    created() {
      this.getMenuList().then((menu) => {
        if (!menu || menu.length === 0) {
          this.$router.push('/403');
          return false;
        }

        // 一级菜单所有的key值
        this.rootSubmenuKeys = menu.map(item => item.key || item.label);

        this.initMenuOpenKeys();
      })
    },

    methods: {

      getMenuList() {
        return new Promise(resolve => {
          this.$axios.MenuList({}).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              let menu_list = data.data ? data.data.menu_list : [];

              this.menu = this.handleMenuList(menu_list, MENUCOFING);
              resolve(this.menu);
            }
          })
        })
      },

      // 添加三级菜单
      addExtraMenu(item) {
        if (item && item.label) {
          switch (item.label) {
            // 音乐管理 添加BGM 人声 音效
            case 'M_MUSIC_MANAGE':
              return Object.assign(item, {
                modules: [
                  {label: 'M_MUSIC_MANAGE_BGM'},
                  {label: 'M_MUSIC_MANAGE_YS'},
                  {label: 'M_MUSIC_MANAGE_YX'}
                ]
              })
          }
        }

        return item;
      },

      handleMenuList(list, config) {
        let that = this;
        let childName = 'modules';

        return one(list, config);

        function one(list, config) {
          return list.map(item => {
            item = that.addExtraMenu(item);

            let label = item.label;
            let citem = config[label];
            let modules = item[childName];

            if (modules && modules.length > 0) {
              item[childName] = one(modules, config);
            }

            return typeof citem === 'object' ? Object.assign(item, citem) : item;
          })
        }
      },

      initMenuOpenKeys() {
        const SPACE = '_';
        const path = this.$route.path;

        let menuItem = this.$getItemWithValue(this.menu, 'path', path, 'modules');
        if (menuItem && menuItem.key && ~menuItem.key.indexOf(SPACE)) {
          let arr = menuItem.key.split(SPACE).slice(0, -1);
          let str = '';
          let finalArr = arr.reduce((a, b) => {
            let newStr = str ? `${str}${SPACE}${b}` : b;
            a.push(newStr)
            str = newStr;
            return a;
          }, [])
          this.openKeys = finalArr;
          this.selectedKeys = [menuItem.key];
        }
        // 如果是 / 路由时跳转到第一个菜单页
        else if (!path || path === '/') {
          this.goFirstMenu();
          
        }
        
      },

      goFirstMenu() {
        let firstSubMenu = this.$getItemWithValue(this.menu, 'path', null, 'modules');

        this.openKeys = this.rootSubmenuKeys.slice(0, 1);
        if (typeof firstSubMenu === 'object') {
          this.selectedKeys = [firstSubMenu.key];
          this.$router.push(firstSubMenu.path);
        }
      },

      onOpenChange(openKeys) {
        
        const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);

        if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
          this.openKeys = openKeys;
        } else {
          this.openKeys = latestOpenKey ? [latestOpenKey] : [];
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .ng-aside {
    /deep/ {
      .menu-icon {
        font-size: 20px;
        vertical-align: -0.225em;
      }
    }
  }
</style>
