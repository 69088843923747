import {
  Axios
} from '@/utils/from-common';

export * from '~/api/api.c';
export * from './account';
export * from './verify'
export * from './contract'
export * from './supplier'
export * from './supplierInfo';
export * from './library';
export * from './libraryEnter';
export * from './mark';
export * from './tags';
export * from './music';
export * from './goods';
export * from './user';
export * from './orderManage';
export * from './package';
export * from './songLIst';
export * from './buyManage';
export * from './income';
export * from './coupon';
export * from './webdata';
export * from './earnings';
export * from './home';
// 登录
export const Login = (param) => Axios('POST', '/bms/auth/login', param);

// 获取用户登录信息
export const UserInfo = (param) => Axios('POST', '/bms/user/loginMsg', param);

// 修改登录密码
export const ChangePassword = (param) => Axios('POST', '/bms/user/upPasswd', param);

// 修改用户信息
export const ChangeUserinfo = (param) => Axios('POST', '/bms/user/upSelfMsg', param);

// 当前用户所有的权限
export const AllAuthList = (param) => Axios('POST', '/bms/auth/authorizedList', param);

// 获取菜单列表
export const MenuList = (param) => Axios('POST', '/bms/auth/menus', param);

// 获取部门权限
export const DeptAuthList = (param) => Axios('POST', '/bms/common/deptActions', param);

// 部门列表 - 下拉筛选 - 不同板块通用（启用的部门）
export const DepartmentList = (param) => Axios('POST', '/bms/common/deptStruct', param);

// 获取所有已启用的角色列表
export const AllOpenRoles = (param) => Axios('POST', '/bms/common/roleSelector', param);

// 获取所有已启用的成员列表
export const AllOpenMembers = (param) => Axios('POST', '/bms/common/userSelector', param);

// 获取指定的部门名称
export const GetDeptNames = (param) => Axios('POST', '/bms/common/deptNames', param);

// 获取指定部门可以用曲库成员
export const GetLibraryCommonMember = (param) => Axios('POST', '/bms/common/msclibUsers', param);

// 获取可利用的曲库
export const GetLibraryList = (param) => Axios('POST', '/bms/common/musicLibs', param);

// 获取可用的供应商
export const GetValuableSuppliers = (param) => Axios('POST', '/bms/common/suplrSelector', param);

// 获取可用音乐标签
export const GetValuableMusicLabels = (param) => Axios('POST', '/bms/common/musicLabels', param);

// 获取可用曲库对应场景
export const GetSceneByLibrary = (param) => Axios('POST', '/bms/common/msclibScenes', param);

// 获取可用曲库对应场景 使用方式
export const GetSceneWay = (param) => Axios('POST', '/bms/common/comnScenes', param);

// 获取歌单可以标签
export const GetSongsListLabels = (param) => Axios('POST', '/bms/common/playlistLabels', param);

// 获取通用标签
export const GetCommonLabels = (param) => Axios('POST', '/bms/common/labels', param);