import {
  Axios
} from '@/utils/from-common';

/* ***********  标注管理  *********** */
// 待标注列表
export const Marklibrary_waitServeList = (param) => Axios('POST', '/bms/batch/unTagList', param);

// 标注中列表
export const Marklibrary_servingList = (param) => Axios('POST', '/bms/batch/tagingList', param);

// 已完成列表
export const Marklibrary_doneServeList = (param) => Axios('POST', '/bms/batch/tagDoneList', param);

// 指派
export const Marklibrary_taskToAnyone = (param) => Axios('POST', '/bms/task/addTag', param);

// 交付
export const Marklibrary_TaskSubmit = (param) => Axios('POST', '/bms/batch/tagDeliver', param);

// 上线
export const Marklibrary_TaskRelease = (param) => Axios('POST', '/bms/batch/online', param);

// 查看进度
export const Marklibrary_storeProgress = (param) => Axios('POST', '/bms/batch/tagProgress', param);


/***  标注任务 */

// 标注任务 待标注 列表
export const MarklibraryTask_waitingList = (param) => Axios('POST', '/bms/task/unTagList', param);

// 标注任务 标注中 列表
export const MarklibraryTask_servingList = (param) => Axios('POST', '/bms/task/tagingList', param);

// 标注任务 已完成 列表
export const MarklibraryTask_servedList = (param) => Axios('POST', '/bms/task/tagDoneList', param);

// 标注任务 领取任务
export const MarklibraryTask_getTask = (param) => Axios('POST', '/bms/task/tagClaim', param);

// 标注任务 交付任务
export const MarklibraryTask_pushTask = (param) => Axios('POST', '/bms/task/tagDeliver', param);

// 标注任务 查看音乐 根据任务
export const MarkTask_musicListByTask = (param) => Axios('POST', '/bms/task/tagedMusic', param);

// 标注任务 查看音乐 根据批次 (标注已完成里边得)
export const MarkTask_musicListByBatch = (param) => Axios('POST', '/bms/batch/tagedMusic', param);


/***** 标注音乐 */
// 标注音乐 标注详情
export const MarkMusic_tagInfo = (param) => Axios('POST', '/bms/task/tagInfo', param);

// 标注音乐 标注详情 编辑时使用 有已经打好的标签数据
export const MarkMusic_tagInfoAndTags = (param) => Axios('POST', '/bms/tag/info', param);

// 标注音乐 驳回已标注音乐
export const MarkMusic_rejectMarked = (param) => Axios('POST', '/bms/tag/undo', param);

// 标注音乐 标注
export const MarkMusic_addMark = (param) => Axios('POST', '/bms/tag/add', param);

// 标注音乐 编辑标注
export const MarkMusic_editMark = (param) => Axios('POST', '/bms/tag/save', param);

// 标注音乐 驳回任务 标注
export const MarkMusic_addMark_reject = (param) => Axios('POST', '/bms/tag/addUndoed', param);

// 标注音乐 驳回任务 标注信息详情
export const MarkMusic_tagInfo_reject = (param) => Axios('POST', '/bms/tag/undoed', param);

// 标注音乐 即时任务 标注信息详情
export const MarkMusic_tagInfo_imme = (param) => Axios('POST', '/bms/task/instTag', param);

// 标注音乐 即时任务 标注
export const MarkMusic_addMark_imme = (param) => Axios('POST', '/bms/tag/addInst', param);