<template>
  <div class="app-outer">
    <!-- header -->
    <header class="app-header">
      <Header></Header>
    </header>

    <div class="app-content">
      <!-- aside -->
      <aside class="aside-box innerbox">
        <Aside></Aside>
      </aside>

      <main class="app-main">
        <router-view :key="key"></router-view>
      </main>
    </div>

    <!-- footer -->
    <footer class="app-footer">
      <ng-video v-if="$store.state.globalVideoInfo.url"></ng-video>
    </footer>
  </div>
</template>

<script>
  import {Aside} from '@/components/index';
  import {Header} from '@/components/index';
  import {NgVideo} from '@/components/index';

  export default {
    data() {
      return {

      }
    },
    components: {
      Aside, Header, NgVideo
    },

    computed: {
      key() {
        return this.$route.name !== undefined ? (this.$route.name + this.$route.meta.type) : (this.$route + Date.now())
      }
    },

    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  .app-outer {
    position: relative;
    height: 100%;
    overflow: hidden;

    .app-header {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 99;
    }

    .app-content {
      display: flex;
      height: 100%;
      padding-top: $header-height;

      .aside-box {
        width: 210px;
        height: 100%;
        flex: 0 0 auto;
        background-color: $menu-dark-bg;
        overflow: auto;
      }

      .app-main {
        flex: 1;
        position: relative;
        height: 100%;
        width: calc(100% - 200px);
      }
    }

    .app-footer {
      position: fixed;
      bottom: 0;
      right: 100px;
      height: 0;
    }
  }
</style>
