<template>
  <a-modal
    :title="title"
    :width="$config.DialogWidth"
    v-model="show"
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form
      :form="form"
      layout="horizontal"
      :wrapper-col="{ xs: {span: 18, offset: 3}}"
      class="ng-form m-top-md"
    >
      <!-- 电话 -->
      <a-form-item v-if="type === 'phone'" class="m-bottom-0">
        <a-form-item style="display: inline-block; width: calc(100% - 300px); margin-right: 20px">
          <form-phone-area
            show-type="both"
            v-decorator="['phone_code', { rules: [$validate.required], initialValue: $config.PhoneCodeDefault}]"
          ></form-phone-area>
        </a-form-item>

        <a-form-item :style="{display: 'inline-block', width: '280px'}">
          <a-input
            type="number"
            v-decorator="['phone', { rules: [$validate.required, $validate.phone], initialValue: '', trigger: 'blur' }]"
            :placeholder="type === $var.Handle.add ? '请输入手机号码' : '请输入新手机号码'"
          />
        </a-form-item>
      </a-form-item>

      <!-- 邮箱 -->
      <a-form-item v-else>
        <a-input
          v-decorator="['email', { rules: [$validate.required, $validate.email], initialValue: '', trigger: 'blur' }]"
          :placeholder="type === $var.Handle.add ? '请输入邮箱' : '请输入新邮箱'"
        />
      </a-form-item>

      <a-form-item class="m-bottom-0">
        <a-form-item style="display: inline-block; width: calc(100% - 180px); margin-right: 20px">
          <a-input
            v-decorator="['vfcode', { rules: [$validate.required], initialValue: '', trigger: 'blur' }]"
            placeholder="请输入验证码"
          />
          <span class="success-tip font-grey" v-if="showSuccessTip">{{sendSuccessTips}}</span>
        </a-form-item>

        <a-form-item :style="{display: 'inline-block', width: '160px'}">
          <form-send-code-button
            type="sub"
            :url-func="codeRequestFunc"
            :param="identifyCodeParam"
            @click="onClickSend"
            @sendSuccess="onSendSuccess"
            @countDownFinished="onCountDownFinished"
          ></form-send-code-button>
        </a-form-item>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import {FormPhoneArea} from '@/components/index';
  import {FormSendCodeButton} from '@/components/index';

  const phoneKey = 'phone';
  const emailKey = 'email';

  export default {
    data() {
      return {
        show: false,

        confirmLoading: false,

        defaultData: {},

        identifyCodeParam: {},

        showSuccessTip: false,
      }
    },

    props: {
      // phone email
      type: {
        type: String,
        default: phoneKey
      },
      handle: String,
      // options: Object,

      defaultValue: {
        type: Object,
        default() {
          return {}
        }
      },
    },

    computed: {
      title() {
        let dir = {
          [phoneKey]: {
            [this.$var.Handle.add]: '绑定手机号',
            [this.$var.Handle.edit]: '变更手机号'
          },
          [emailKey]: {
            [this.$var.Handle.add]: '绑定邮箱',
            [this.$var.Handle.edit]: '变更邮箱'
          }
        }

        return dir[this.type || phoneKey][this.handle];
      },

      sendSuccessTips() {
        let dir = {
          [phoneKey]: '已发送短信验证码到绑定手机',
          [emailKey]: '已发送验证码到绑定邮箱',
        }

        return dir[this.type || phoneKey];
      },

      // 验证码发送方法
      codeRequestFunc() {
        return this.type === phoneKey ? this.$axios.SendCommonPhoneCode : this.$axios.SendCommonEmailCode;
      },

      // 绑定手机/邮箱
      bindCodeFunc() {
        return this.type === phoneKey ? this.$axios.Account_bindPhone : this.$axios.Account_bindEmail;
      }
    },

    mixins: [dialogMixins],

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        name: 'linkman'
      })
    },

    components: {
      FormPhoneArea, FormSendCodeButton
    },

    methods: {

      onClickSend(next) {
        this.form.validateFields(['phone', 'phone_code', 'email'], (errors, values) => {

          if (this.type === phoneKey) {
            values.sms_tpl = this.$val.SendCodeDefaultValue.phone;
          } else if (this.type === emailKey) {
            values.email_tpl = this.$val.SendCodeDefaultValue.email;
          }

          if (this.type === phoneKey && this.$root.$PhoneCodeArea && this.$root.$PhoneCodeArea.length > 0) {
            this.$root.$PhoneCodeArea.forEach(item => {
              if (item.id == values.phone_code) {
                values.phone_area = item.prefix;
              }
            })
          }

          if (!errors) {
            next(values);
          }
        })
      },

      onSendSuccess(data) {
        if (data && data.code == 0) {
          this.showSuccessTip = true;
          this.transaction_id = data.data.transaction_id;
        }
      },

      onCountDownFinished() {
        this.showSuccessTip = false;
      },

      handleOk() {
        this.form.validateFieldsAndScroll((errors, values) => {

          if (!errors) {
            values.transaction_id = this.transaction_id;

            this.confirmLoading = true;

            this.bindCodeFunc(values).then(res => {
              const data = res.data;

              if (data && data.code == 0) {
                this.$message.success(this.handle === this.$var.Handle.add ? '绑定成功' : '修改成功');
                this.$emit('success', values);
                this.$emit('update:visible', false);
              }

              this.confirmLoading = false;
            }).catch(() => {
              this.confirmLoading = false;
            })
          }
        })
      },

      handleCancel() {

      },
    }
  }
</script>

<style lang="scss" scoped>
  .success-tip {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -56px;
  }
</style>
