<template>
  <a-modal
    width="700px"
    v-model="show"
    :title="modalTitle"
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    destroyOnClose
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <library-base-info
      :user_id="user_id"
      ref="libraryBaseInfo"
      :is-edit="isEdit"
      @onChangeContracts="onChangeContracts"
    ></library-base-info>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import LibraryBaseInfo from './LibraryBaseInfo.vue';

  export default {
    data() {
      return {
        show: false,

        confirmLoading: false,

        defaultData: {},
      }
    },

    props: {
      handle: String,
      defaultValue: {
        type: Object,
        default() {
          return {}
        }
      },
      user_id: String,
    },

    computed: {
      isEdit() {
        return this.handle === this.$var.Handle.edit;
      },

      modalTitle() {
        return this.isEdit ? '修改曲库' : '新增曲库'
      },

      request() {
        return this.isEdit ? this.$axios.Library_edit : this.$axios.Library_add
      },

      successMessage() {
        return this.isEdit ? '修改成功' : '新增成功';
      }
    },

    watch: {
      visible(newVal) {

        if (newVal) {

          this.$nextTick(() => {
            let ref = this.$refs.libraryBaseInfo ? this.$refs.libraryBaseInfo : null;

            if (ref) {
              let form = ref ? ref.form : null;

              ref.profile_photo_url = this.defaultValue.logo_url;

              let {logo, name, short_name, desc, contract_id, music_type, user_id} = this.defaultValue;

              if (form) {
                form.resetFields(['logo', 'name', 'short_name', 'desc', 'user_id']);

                if (this.isEdit) {
                  form.setFieldsValue({
                    logo, name, short_name, desc, music_type, user_id
                  });

                  setTimeout(() => {
                    form.setFieldsValue({contract_id})
                  }, 400);
                }
              }
            }
          })
        }
      }
    },

    mixins: [dialogMixins],

    components: {
      LibraryBaseInfo
    },

    methods: {

      handleOk() {
        this.$refs.libraryBaseInfo.form.validateFieldsAndScroll((errors, values) => {

          if (!errors) {
            this.user_id && (values.user_id = this.user_id);

            this.submitLibrary(values);
          }
        })
      },

      submitLibrary(params) {
        let method = this.request;

        if (typeof method !== 'function') return;

        this.confirmLoading = true;

        this.isEdit && (params.lib_id = this.defaultValue.lib_id)

        method(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success(this.successMessage);
            this.$emit('success', data);
          }

          this.confirmLoading = false;
        }).catch(() => {
          this.confirmLoading = false;
        })
      },

      handleCancel() {
        this.show = false;
      },

      onChangeContracts(data) {
        if (data && data.length > 0) {
          this.$refs.libraryBaseInfo.form.setFieldsValue({contract_id: data[0].contract_id})
        }
      },

      // onChangeMusicType(data) {
      //   if (data && data.length > 0) {
      //     this.$refs.libraryBaseInfo.form.setFieldsValue({music_type: data[0].value})
      //   }
      // },
    }
  }
</script>

<style lang="scss" scoped>
</style>
