<template>
  <div class="ng-tags h-100">
    <main-structure dir="ver">
      <template slot="header">
        <span class="title">基础标签管理</span>

        <div class="header-content">
          <a-input-search
            class="ng-input-search"
            placeholder="请输入标签名称以检索"
            :default-value="$route.query.keyword || ''"
            @search="onSearch"
            allowClear
          >
            <template v-slot:enterButton>
              <a-button type="sub">
                <a-icon type="search" />
              </a-button>
            </template>
          </a-input-search>
        </div>
      </template>

      <template>
        <filter-tags @change="onFilterChange" :defaultData="$route.query"></filter-tags>

        <a-row class="h-100" style="margin-top: 20px;height: calc(100% - 60px);">
          <a-col class="h-100" :span="6">
            <a-spin :spinning="loading" class="h-100">
              <tags-type
                :selected-key.sync="currentType"
                :data="treeData"
                :search-value="searchValue"
                @clickExtra="onClickExtra"
                @change="onChangeType"
              ></tags-type>
            </a-spin>
          </a-col>
          <a-col class="h-100" :span="9">
            <tags-sub
              :data="treeData"
              :curr-type="currentType"
              :search-value="searchValue"
              :reload-content="reloadSub"
              :curr-id.sync="currentTagKey"
              @clickExtra="onClickExtra"
              ref="tagsSub"
            ></tags-sub>
          </a-col>
          <a-col class="h-100" :span="9">
            <tags-info
              v-if="isType && $root.$checkAuth($auth[name] && $auth[name].cateInfo) 
                || !isType && $root.$checkAuth($auth[name] && $auth[name].tagInfo)"
              :active-key="currentType"
              :active-tag-key="currentTagKey"
              @edit="onEdit"
              @del="onDel"
              ref="tagsInfo"
            ></tags-info>
          </a-col>
        </a-row>
      </template>
    </main-structure>

    <dialog-add-tags-type
      :visible.sync="showAddTagType"
      :default-value="handleData.data"
      :handle="handleData.handle"
      :id="handleData.id"
      @success="onSuccess"
    ></dialog-add-tags-type>

    <dialog-add-tags
      :visible.sync="showAddTag"
      :default-value="handleData.data"
      :handle="handleData.handle"
      :id="handleData.id"
      :options="handleData"
      @success="onSuccess"
    ></dialog-add-tags>
  </div>
</template>

<script>
  import TagsType from './components/TagsType.vue';
  import TagsSub from './components/TagsSub.vue';
  import TagsInfo from './components/TagsInfo.vue';
  import FilterTags from './components/FilterTags.vue'
  import DialogAddTagsType from './components/DialogAddTagsType.vue'
  import DialogAddTags from './components/DialogAddTags.vue'

  export default {
    provide() {
      return {
        authName: this.name
      }
    },
    
    data() {
      return {
        name: `tagManage`,
        treeData: [],
        currentTagKey: '', // 当前选中的标签
        currentType: '', // 当前选中的类型
        searchValue: '', // 搜索字段
        params: {}, // 

        showAddTagType: false,
        showAddTag: false,
        handleData: {},
        reloadSub: true,
        loading: false,
      }
    },

    computed: {
      // 类别
      isType() {
        return this.currentType && !this.currentTagKey
      },
    },

    components: {
      TagsType, TagsSub, TagsInfo, FilterTags, DialogAddTagsType, DialogAddTags
    },

    created() {
      this.getTagsData();
    },

    methods: {
      onSearch(value) {
        typeof value === 'string' && (value = value.trim());

        this.searchValue = value;
      },

      reloadData() {
        this.getTagsData(this.params).then(() => {
          this.$refs.tagsInfo.reloadData();
        });
      },

      onSuccess() {
        this.reloadData();
      },

      onChangeType(item) {
        this.curTagTypeItem = item;
      },

      onClickExtra(options) {
        let key = options.key;

        // 标签类型
        if (key === 'type') {
          this.showAddTagType = true;
          this.handleData = {};
        }
        // 标签
        else if (key === 'tag') {
          this.showAddTag = true;
          this.handleData = options;
        }
      },

      onFilterChange(value) {
        this.params = Object.assign({}, this.params, value);

        this.getTagsData(this.params)

        this.currentType = '';
        this.currentTagKey = '';
      },

      onEdit(options) {
        this.handleData = options;
        this.handleData.handle = this.$var.Handle.edit;

        if (options.from === 'type') {
          this.showAddTagType = true;
        } else if (options.from === 'tag') {
          this.showAddTag = true;
        }
      },

      onDel(options) {
        let fromPage = options.from;

        this.reloadData();

        this.currentTagKey = '';

        if (fromPage === 'type') {
          this.currentType = '';
        }
      },

      getTagsData(params = {}) {
        return new Promise((resolve) => {
          this.loading = true;
          this.$axios.Tags_list(params).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              const {cate_list, label_list} = data.data;

              this.treeData = cate_list.map(item => {
                item.child = label_list.filter(cItem => cItem.cate_id === item.cate_id)
                return item;
              });

              resolve(data.data);
            }

            this.loading = false;
          })
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .ng-tags {
    /deep/ {
      .ant-card-body {
        height: calc(100% - 37px);
        overflow: auto;
      }
    }
  }
</style>
