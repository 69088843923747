<template>
  <div class>
    <ng-table
      class="ng-table"
      :columns="columns"
      :params="requestParams"
      :rowKey="row => row.user_id"
      :request="$axios.User_list"
      :load.sync="loadTable"
      :reload.sync="reloadTable"
      :scroll="{y: scrollyHeight - 20}"
    >
      <!-- 头像 -->
      <template slot="profile_photo_url" slot-scope="text, scope">
        <ng-image
          class="table-header"
          :src="`${scope.profile_photo_url}?x-oss-process=style/thumb_10`"
          :default-value="$var.defaultHeader"
        />
      </template>

      <template slot="sex" slot-scope="text">
        <span>{{text | toSexName}}</span>
      </template>

      <template slot="name" slot-scope="text, scope">
        <a-tooltip placement="top" title="单击用户名复制 ID 至剪贴板">
          <span class="cursor-pointer" @click="() => {onCopyText(scope.user_id)}">{{text}}</span>
        </a-tooltip>
      </template>

      <template slot="operation" slot-scope="text, scope">
        <router-link
          v-if="$root.$checkAuth($auth[authName] && $auth[authName].look)"
          type="link"
          class="m-right-ms"
          :to="`/user/detail/${scope.user_id}`"
        >查看</router-link>

        <a-dropdown>
          <a-button type="link" @click="e => e.preventDefault()">
            <a-icon type="more" :rotate="90" :style="{fontSize: '28px', verticalAlign: 'middle'}" />
          </a-button>
          <a-menu slot="overlay">
            <a-menu-item>
              <div>
                <router-link
                  v-if="$root.$checkAuth($auth[authName] && $auth[authName].deeds)"
                  class="ant-btn ant-btn-link"
                  type="link"
                  :to="`/user/deeds/${scope.user_id}`"
                >用户行为</router-link>
              </div>
            </a-menu-item>
            <a-menu-item>
              <div>
                <router-link
                  v-if="$root.$checkAuth($auth[authName] && $auth[authName].order)"
                  class="ant-btn ant-btn-link"
                  type="link"
                  :to="`/user/order/${scope.user_id}`"
                >用户订单</router-link>
              </div>
            </a-menu-item>
            <a-menu-item>
              <div>
                <router-link
                  v-if="$root.$checkAuth($auth[authName] && $auth[authName].order)"
                  class="ant-btn ant-btn-link"
                  type="link"
                  :to="`/user/package/${scope.user_id}`"
                >用户套餐</router-link>
              </div>
            </a-menu-item>
            <a-menu-item>
              <a-button class="m-right-ms" type="link" @click="onOpenPackage(scope)">开通套餐</a-button>
            </a-menu-item>
            <a-menu-item>
              <a-button class="m-right-ms" type="link" @click="onOpenVip(scope)">开通会员</a-button>
            </a-menu-item>
            <a-menu-item>
              <a-button class="ant-btn-error" type="link" @click="$root.dontFinished">删除</a-button>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </template>
    </ng-table>

    <dialog-open-package
      :visible.sync="showDialog"
      :user_id="handleData.user_id"
      @success="onSuccess"
    ></dialog-open-package>
    <dialog-open-vip
      :visible.sync="showVipDialog"
      :user_id="handleData.user_id"
      @success="onSuccess"
    ></dialog-open-vip>
  </div>
</template>

<script>
  import DialogOpenPackage from './DialogOpenPackage.vue'
  import DialogOpenVip from './DialogOpenVip.vue'

  import {tableMixins} from '@/mixins';
  import {copyStr} from '@/utils/function'

  import * as TabCol from '@/config/columns';

  const columns = [
    TabCol.colId,
    TabCol.commonKey('头像', 'profile_photo_url', null, true),
    TabCol.commonKey('用户名', 'name', null, true),
    TabCol.commonKey('性别', 'sex', '60px', true),
    TabCol.commonKey('所在地', 'address'),
    TabCol.commonKey('手机号', 'phone'),
    TabCol.commonKey('邮箱', 'email', {ellipsis: true}),
    TabCol.commonKey('注册时间', 'create_at', {ellipsis: true}),
    TabCol.commonKey('最近登录时间', 'login_time', {ellipsis: true}),
    TabCol.operation('100px'),
  ];

  export default {
    data() {
      return {
        columns,
        requestParams: {},
        showDialog: false,
        currentData: {},
        handleData: {},
        showVipDialog: false,
      }
    },

    props: {
      authName: String,
      scrollyHeight: {
        type: Number,
        default: 400
      },
      from: String,
      params: Object,
    },

    watch: {
      params: {
        handler(newVal) {
          this.requestParams = Object.assign({}, this.requestParams, newVal, {page: 1})
        },
        immediate: true
      },
    },

    components: {
      DialogOpenPackage,
      DialogOpenVip
    },

    mounted() {
    },

    mixins: [
      tableMixins
    ],

    methods: {
      onSuccess() {
        this.loadTableData();
      },

      onCopyText(id) {
        if (!id) {
          this.$message.warning('没有可以复制的');
          return false;
        }

        try {
          copyStr(id);
          this.$message.success('复制成功');
        } catch (error) {
          console.error(error);
        }
      },

      onOpenPackage(scope) {
        this.handleData = scope;

        this.showDialog = true;
      },
      onOpenVip(scope){
        this.handleData = scope;
        this.showVipDialog = true;
      },
    }
  }
</script>

<style lang="scss" scoped>
  .mark-music-table {
    /deep/ {
      .ant-table td {
        white-space: nowrap;
      }

      .ant-table-tbody {
        .ant-table-row {
          &:hover {
            > td {
              background: transparent;
            }
          }
        }
      }

      .odd-row {
        > td {
          border-bottom: 0;
        }
      }

      .even-row {
        > td {
          padding-top: 0;
          padding-bottom: 5px;
        }
      }
    }
  }
</style>
