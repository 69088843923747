<template>
  <div class="ng-main">
    <div class="ng-main-header">
      <slot name="header"></slot>
    </div>

    <div v-if="$slots.middle" class="middle-content">
      <slot name="middle"></slot>
    </div>

    <div
      class="ng-main-content"
      :class="[loading ? 'ant-spin-blur' : '',dir ==='ver' ? 'ver' : 'hor']"
      ref="mainContent"
    >
      <slot></slot>

      <a-spin :spinning="loading" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'MainStructure',

    props: {
      dir: {
        type: String,
        default: 'ver', // ver 竖向 hor 横向
      },
      loading: Boolean
    },
    mounted() {
      // this.calcMainContent();
    },
    methods: {
      // calcMainContent() {
      //   let mainContent = this.$refs.mainContent;

      //   var pos = mainContent.getBoundingClientRect();

      // }
    }
  }
</script>

<style lang="scss" scoped>
  .ng-main {
    display: flex;
    flex-direction: column;
    height: 100%;

    .ng-main-header {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      padding: 0 $space-base 0 $space-base;
      background-color: #fff;

      /deep/ .title {
        font-size: 18px;
        font-weight: $font-weight-bold;
      }
    }

    .middle-content {
      padding: $space-base;
      background-color: #fff;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      margin: $space-base $space-base 0;
    }

    .ng-main-content {
      position: relative;
      display: flex;
      height: 100%;
      padding: $space-base;
      margin: $space-base $space-base 0;
      background-color: #fff;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      overflow: auto;

      &.ver {
        flex-direction: column;
      }

      &.hor {
        flex-direction: row;
      }
    }

    /deep/ .ant-spin {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 100;

      .ant-spin-dot {
        position: absolute;
        left: 50%;
        top: 50%;
      }
    }
  }
</style>
