<template>
  <div class="cart-detail-item clearfix pos-relative">
    <div class="f-l item-left">
      <ng-image :src="data.logo_url" alt />

      <div v-if="data.is_putoff && data.is_putoff.toString() == '1'" class="status-tag status">
        <span class="tag-item drop grey">已下架</span>
      </div>
    </div>

    <ul class="f-l item-right text-left">
      <li class="ellipsis title">
        <b>{{data.music_name}}</b>
        &nbsp;&nbsp;
        <span>-</span>&nbsp;&nbsp;
        <span :title="data.suplr_name">{{data.suplr_name}}</span>
        <slot name="top" :item="data"></slot>
      </li>
      <li>使用场景：{{data.auth_scene | toAuthScene}}</li>
      <li>使用方式：{{data.use_mode}}</li>
      <li>授权地域：{{data.auth_area | toArea}}</li>
      <li>授权期限：{{data.auth_time | toAuthPeriod}}</li>
    </ul>
  </div>
</template>

<script>
  import {AuthAreaOptions, BusinessSceneOptions, AuthTimeOptions} from '@/config/options';
  import {toOptionName} from '@/utils/function';

  export default {
    data() {
      return {

      }
    },

    props: {
      data: {
        type: Object,
        default() {
          return {}
        }
      }
    },
    filters: {
      toArea(value) {
        return toOptionName(AuthAreaOptions, value);
      },

      toAuthScene(value) {
        return toOptionName(BusinessSceneOptions, value);
      },

      toAuthPeriod(value) {
        return toOptionName(AuthTimeOptions, value);
      }
    },

    components: {

    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  .item-left {
    position: absolute;
    left: 0px;
    top: 50%;
    width: 100px;
    height: 100px;
    overflow: hidden;
    transform: translateY(-50%);

    > img {
      width: 100%;
      height: 100%;
    }

    .status-tag {
      position: absolute;
      top: 0;
      left: 0;
      width: 100px;
      height: 50px;
      z-index: 5;
    }
  }

  .item-right {
    padding-left: 120px;

    .title {
      display: inline-block;
      width: 350px;
    }
  }
</style>
