<template>
  <div>
    <ng-table
      class="ng-table"
      :columns="columns"
      :params="params"
      :rowKey="row => row.apply_id"
      :request="$axios.Ver_LibrarySignList"
      :load.sync="loadTable"
      :reload.sync="reloadTable"
      :scroll="{y: scrollyHeight - 20}"
    >
      <!-- 头像 -->
      <template slot="logo_url" slot-scope="text, scope">
        <ng-image
          class="table-header"
          :src="scope.logo_url ? `${scope.logo_url}?x-oss-process=style/thumb_10` : $var.defaultHeader"
          @error="({target}) => target.src = $var.defaultHeader"
        />
      </template>

      <template slot="sign_check_status" slot-scope="text">{{text | toServerVerifyStatus}}</template>

      <template slot="name" slot-scope="text, scope">
        <router-link :to="`/verify/company/detail/${scope.apply_id}`" :title="text">{{text}}</router-link>
      </template>

      <template slot="operation" slot-scope="text, scope">
        <a-button
          v-if="$root.$checkAuth($auth[authName] && $auth[authName].lookView)"
          class="m-right-ms"
          type="link"
          @click="goSign(scope)"
          :disabled="!scope.op_sign_check"
        >签约</a-button>
        <a-button
          v-if="$root.$checkAuth($auth[authName] && $auth[authName].lookView)"
          class="ant-btn-error"
          type="link"
          @click="rejectSign(scope)"
          :disabled="!scope.op_sign_check"
        >拒绝</a-button>
      </template>
    </ng-table>

    <dialog-sign
      :visible.sync="showDialog"
      :default-value="handleData"
      :apply_id="handleData.apply_id"
      @success="handleSuccess()"
    ></dialog-sign>

    <confirm-and-info
      :visible.sync="showRejectDialog"
      successTip="审核成功"
      text="请提交拒绝签约的理由"
      placeholder="被拒绝后该公司将无法成为供应商，请认真填写拒绝理由"
      inputKey="sign_check_reason"
      :data-func="$axios.Ver_LibraryRejectSign"
      :params="{apply_id: handleData.apply_id}"
      @success="handleSuccess()"
    ></confirm-and-info>
  </div>
</template>

<script>
  import {
    toOptionName
  } from '@/utils/function';
  import * as AllOptions from '@/config/options';
  import {tableMixins} from '@/mixins';

  import * as TabCol from '@/config/columns';

  const columns = [
    TabCol.colId,
    TabCol.commonKey('公司名称', 'name', {ellipsis: true, isSlot: true}),

    TabCol.commonKey('联系人姓名', 'linkman'),
    TabCol.commonKey('联系人职位', 'linkman_position'),
    TabCol.commonKey('联系电话', 'phone'),
    TabCol.commonKey('联系邮箱', 'email'),

    TabCol.commonKey('状态', 'sign_check_status', '', true),
    TabCol.operation('100px')
  ]

  export default {
    data() {
      return {
        columns,
        tableList: [],
        loading: false,
        showDialog: false,
        showRejectDialog: false,
        handleData: {},
        params: {},
      }
    },

    props: {
      authName: String,
      scrollyHeight: {
        type: Number,
        default: 400
      },
      reload: Boolean,
    },

    mounted() {
      this.params = {user_id: this.$route.params.user_id || 0, page: this.$route.query.page || 1};
    },

    components: {
      DialogSign: () => import('./DialogSign.vue'),
      ConfirmAndInfo: () => import('@/components/dialog/ConfirmAndInfo.vue'),
    },

    filters: {
      toServerVerifyStatus(value) {
        return toOptionName(AllOptions.VerifyStatus, value);
      }
    },

    mixins: [
      tableMixins
    ],

    methods: {

      goSign(scope) {
        this.showDialog = true;
        this.handleData = scope;
      },

      rejectSign(scope) {
        this.showRejectDialog = true;
        this.handleData = scope;
      },

      handleSuccess() {
        this.showDialog = false;
        this.showRejectDialog = false;
        this.loadTableData();
      }
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .ant-table td {
    white-space: nowrap;
  }
</style>
