<template>
  <div class="h-100">
    <ng-table
      class="ng-table ant-table-small-space"
      :columns="columns"
      :params="params"
      childrenColumnName="child"
      :rowKey="row => row.id"
      :request="$axios.CouponCodeList"
      :load.sync="loadTable"
      :reload.sync="reloadTable"
    >
      <template slot="name" slot-scope="text, scope">
        <a href="javascript:;" @click="onClickMusic(scope)" :title="text">{{text}}</a>
      </template>
      <template slot="status" slot-scope="text">
        <span>{{text | toMusicStatus}}</span>
      </template>
      <template slot="type" slot-scope="text">
        <span>{{text | toMusicType}}</span>
      </template>
      <template slot="auth_area" slot-scope="text">
        <span>{{text | toArea}}</span>
      </template>
      <template slot="auth_type" slot-scope="text">
        <span>{{text | toAuthType}}</span>
      </template>
    </ng-table>
  </div>
</template>

<script>
  import * as TabCol from '@/config/columns';
  import {SellStatusOptions, AuthAreaOptions, AuthTypeOptions} from '@/config/options'
  import {toOptionName} from '@/utils/function'
  import {tableMixins} from '@/mixins';

  const columns = [
    TabCol.colIdFunc((text, index) => !text.parent_id ? index + 1 : ' '),
    TabCol.commonKey('优惠券名称', 'coupon_name', {ellipsis: true, isSlot: true}),
    TabCol.commonKey('领取用户', 'user_name', {ellipsis: true}),
    TabCol.commonKey('领取状态', 'is_bind_str', {isSlot: true}),
    TabCol.commonKey('领取时间', 'get_time', {ellipsis: true}),
    TabCol.commonKey('使用状态', 'is_used_str', {ellipsis: true}),
    TabCol.commonKey('使用时间', 'use_time', {isSlot: true, class: 'word-white-normal'}),
    TabCol.commonKey('订单编号', 'order_no', {isSlot: true, class: 'word-white-normal'}),
    TabCol.commonKey('订单金额', 'order_amount', {isSlot: true, class: 'word-white-normal'}),
    TabCol.commonKey('实付金额', 'pay_amount', {isSlot: true, class: 'word-white-normal'}),


    // TabCol.operation('100px'),
  ]

  export default {
    data() {
      return {
        columns,
      }
    },

    props: {
      sid: String,
      authName: String,
      params:Object
    },

    filters: {
      toMusicStatus(value) {
        return toOptionName(SellStatusOptions, value);
      },
      toArea(value) {
        return toOptionName(AuthAreaOptions, value);
      },

      toAuthType(value) {
        return toOptionName(AuthTypeOptions, value);
      },
    },

    mounted() {
    
    },

    mixins: [
      tableMixins
    ],

    components: {
    },

    methods: {
      onClickMusic(scope) {
        let {music_url: url} = scope;

        this.$store.dispatch('onPlayGlobalVideo', Object.assign(scope, {url}));
      },

      onDelete(scope) {
        let vm = this;

        this.$confirm({
          width: '480px',
          title: '确认要删除该歌曲吗？',
          onOk() {
            return new Promise((resolve, reject) => {
              vm.deleteMember(scope, resolve, reject);
            });
          }
        });
      },

      deleteMember(scope, next, reject) {
        let param = {
          coupon_id: this.sid,
          music_id: scope.music_id
        };

        this.$axios.Song_musicList_delMusic(param).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('已成功删除该歌曲');
            next();

            setTimeout(() => {
              this.loadTableData();
            }, 500);
          } else {
            reject();
          }
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .ant-table td {
    white-space: nowrap;
  }
</style>
