<template>
  <div class="channel-order-detail-list">
    <template v-for="item in list">
      <div class="good-item pos-relative operation-item" :key="item.detail_id">
        <div class="good-item-content flex cen-start">
          <music-detail-item class="inline-block" :data="item">
            <template v-slot:top>
              <!-- 未支付 -->
              <template v-if="status === $val.UndoPayOrderType.payed">
                <template v-if="item.is_licensed == '0'">
                  <a-tag class="m-left-base" color="cyan">未授权</a-tag>
                </template>
                <template v-else>
                  <a-tag class="m-left-base" color="orange">已授权</a-tag>
                </template>
              </template>
              <template v-else>
                <template v-if="status === $val.UndoPayOrderType.wait">
                  <a-tag class="m-left-base" color="pink">待支付</a-tag>
                </template>
                <template v-else-if="status === $val.UndoPayOrderType.error">
                  <a-tag class="m-left-base" color="orange">支付失败</a-tag>
                </template>
                <template v-else-if="status === $val.UndoPayOrderType.overtime">
                  <a-tag class="m-left-base" color="red">已超时</a-tag>
                </template>
                <template v-else-if="status === $val.UndoPayOrderType.cancel">
                  <a-tag class="m-left-base" color="grey">已取消</a-tag>
                </template>
              </template>
            </template>
          </music-detail-item>

          <span class="inline-block p-left-base">价格：￥{{item.price}}</span>
        </div>

        <template v-if="status === $val.UndoPayOrderType.payed">
          <div class="operation pos-center-right operation-name">
            <a
              v-if="item.is_license != '0'"
              class="m-right-ms no-p-lr"
              :href="$root.getAuthUrl(item.license_id)"
              target="_blank"
            >查看授权信息</a>

            <a-button
              v-if="item.is_license != '0'"
              class="m-right-ms no-p-lr"
              type="link"
              @click="editAuth(item)"
            >修改授权信息</a-button>
          </div>
        </template>
      </div>
    </template>

    <dialog-active-auth :visible.sync="showDialogActive" :data="handleData"></dialog-active-auth>
  </div>
</template>

<script>
  import MusicDetailItem from '@/components/music/MusicDetailItem.vue';
  import DialogActiveAuth from "@/components/music/DialogActiveAuth.vue";

  export default {
    data() {
      return {
        showDialogActive: false,
        handleData: {},
        handle: '',
      }
    },

    props: {
      status: String,
      list: {
        type: Array,
        default() {
          return []
        }
      }
    },

    components: {
      MusicDetailItem,
      DialogActiveAuth
    },

    methods: {
      lookAuth(scope) {
        let api = this.$axios.User_lookOrderAuth;
        let host = this.$config.ServeBaseHost;

        return `${host}${api}?license_id=${scope.license_id}`;
      },

      // lookAuth(scope) {
      //   let routeUrl = this.$router.resolve({
      //     path: '/authbook/-1',
      //     query: scope,
      //   });

      //   window.open(routeUrl.href, '_blank');
      // },

      editAuth(scope) {
        this.handleData = scope;
        this.handle = this.$var.Handle.add;
        this.showDialogActive = true;
      },

      onSuccess() {

      },

      onCountDownEnd() {
        this.loadData = true;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .channel-order-detail-list {
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 10px;

    .good-item {
      padding: 7px 0;
    }
  }
</style>
