import Vue from 'vue'
import App from './App.vue'
import store from './store/store'
import router from './router/router'

import GlobalFilter from '~/filter/global'
import Filter from '@/filter/index'

Vue.use(GlobalFilter);
Vue.use(Filter);

Vue.config.productionTip = false;

import '~/utils/comp.c';
import '@/utils/comp';
import "@/scss/main.scss";
import '@/scss/sp-theme.scss';

// ============= plugins
import GlobalFunction from '@/plugins/globalFunction';
import GlobalVariables from '@/plugins/globalVariables';

Vue.use(GlobalFunction);
Vue.use(GlobalVariables);
// ============= plugins end

import * as RootFunc from '@/utils/rootFunc'

new Vue({
  render: h => h(App),
  data() {
    return {
      $transferData: {}, // 全局传送数据
      $commonSceneAndMode: [], // 全局通用授权场景和使用方式
    }
  },
  store,
  router,
  methods: {
    ...RootFunc,
    dontFinished() {
      this.$message.warn('暂未开通');
    },

    // 获取数组对象第一项的某个属性值
    getFirstAsDefault(options, valueName = 'value') {
      if (options && options.length > 0) {
        return options[0][valueName] || '';
      }

      return '';
    },

    getCommonSceneAndMode() {
      let commonSceneAndMode = this.$commonSceneAndMode;

      return new Promise((resolve, reject) => {
        if (commonSceneAndMode) {
          resolve(this.$commonSceneAndMode);
        } else {
          this.$axios.GetSceneWay().then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              this.$commonSceneAndMode = data.data ? data.data.list : [];

              resolve(this.$commonSceneAndMode);
            } else {
              reject();
            }
          })
        }
      })
    },

    getAuthUrl(license_id) {
      let api = this.$axios.User_lookOrderAuth;
      let host = this.$config.ServeBaseHost;

      return `${host}${api}?license_id=${license_id}`;
    }
  }
}).$mount('#app')