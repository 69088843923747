<template>
  <div class="auth-content">
    <div v-if="list && list.length > 0">
      <template v-for="(item, index) in list">
        <form-checkbox-all-three
          :data="item.modules"
          :allLabel="item.name"
          :value="value || []"
          :key="item.id"
          :fieldNames="fieldNames"
          @change="onChange(index, $event)"
          :disabled="disabled"
        ></form-checkbox-all-three>
      </template>
    </div>

    <a-empty v-else description="请配置相关权限" />
  </div>
</template>

<script>
  import {FormCheckboxAllThree} from '@/components/index';

  export default {
    data() {
      return {
        list: [],
        fieldNames: {label: 'name', value: 'id', child: 'actions'},
      }
    },

    props: {
      dept_id: [String, Number],
      value: Array,
      disabled: Boolean
    },

    created() {
      this.allData = [];
    },

    watch: {
      dept_id: {
        handler(newVal) {
          let dept_id = newVal;
          this.getAuthList({dept_id});
        },
        immediate: true
      }
    },

    components: {
      FormCheckboxAllThree
    },

    methods: {
      onChange(index, list) {
        this.allData[index] = list;

        let finalArr = this.allData.reduce((a, b) => a.concat(b));

        this.$emit('change', finalArr);
      },

      getAuthList(param) {
        this.$store.commit('changeContentLoading', true);

        this.$axios.DeptAuthList(param).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.list = data.data ? data.data.list : [];
            this.$root.deptAuthList = this.list;
          }

          this.$store.commit('changeContentLoading', false);
        }).catch(() => {
          this.$store.commit('changeContentLoading', false);
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .auth-content {
    min-height: 200px;
  }
</style>
