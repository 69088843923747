<template>
  <a-modal
    width="500px"
    v-model="show"
    title="线下结单"
    destroyOnClose
    :maskClosable="false"
    @ok="handleOk"
  >
    <a-row class="flex cen-center">
      <a-col :sm="4">实付金额</a-col>
      <a-col :sm="18">
        <a-input-number :min="0" v-model="price" placeholder="请输入该订单实际支付金额" style="width: 300px;" />
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';

  export default {
    data() {
      return {
        show: false,
        price: '',
        confirmLoading: false,
      }
    },

    props: {
      order_id: String,
    },

    mixins: [dialogMixins],

    methods: {
      handleOk() {
        if (!this.price || this.price <= 0) {
          this.$message.warning('请输入支付金额');
          return false;
        }

        let params = {
          pay_amount: this.price,
          order_id: this.order_id
        };

        this.confirmLoading = true;
        this.$axios.OrderVip_settle(params).then(res => {
          const data = res.data;
          if (data && data.code == 0) {
            this.$message.success('结算成功');
            this.$emit('success', data);
            this.show = false;
          }
          this.confirmLoading = false;
        }).catch(() => {
          this.confirmLoading = false;
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
