<template>
  <a-modal
    class="add-tags-type"
    :title="title"
    width="640px"
    v-model="show"
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
    :bodyStyle="{
      maxHeight: `${dialogBodyMaxHeight ? dialogBodyMaxHeight : '500'}px`,
      overflow: 'auto',
    }"
  >
    <a-form
      :form="form"
      layout="horizontal"
      :label-col="{ xs: { span: 5 } }"
      :wrapper-col="{ xs: { span: 17 } }"
      class="ng-form m-top-base"
    >
      <a-form-item label="标签名称">
        <ng-input
          :maxLength="20"
          show-counter
          placeholder="请输入标签名称，上限20个字"
          v-decorator="[
            'name',
            {
              rules: [$validate.required],
              initialValue: '',
              validateTrigger: 'blur',
            },
          ]"
        ></ng-input>
      </a-form-item>

      <a-form-item label="标签英文名称">
        <ng-input
          :maxLength="20"
          show-counter
          placeholder="请输入标签英文名称，上限20个字"
          v-decorator="[
            'name_en',
            {
              rules: [$validate.required],
              initialValue: '',
              validateTrigger: 'blur',
            },
          ]"
        ></ng-input>
      </a-form-item>

      <a-form-item label="标签描述">
        <ng-textarea
          placeholder="请输入类别描述，200字以内"
          :maxLength="200"
          show-counter
          v-decorator="['desc', { initialValue: '' }]"
          style="height: 80px"
        ></ng-textarea>
      </a-form-item>
    </a-form>

    <template slot="footer">
      <a-button key="back" @click="handleCancel">取消</a-button>

      <a-button
        v-if="handle !== $var.Handle.edit"
        key="save"
        type="primary"
        @click="handleOk(false)"
        :loading="confirmLoading && saveBtnType == '1'"
      >保存并继续新增</a-button>

      <a-button
        key="submit"
        type="primary"
        @click="handleOk(true)"
        :loading="confirmLoading && saveBtnType == '2'"
      >保存</a-button>
    </template>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';

  export default {
    data() {
      return {
        show: false,

        confirmLoading: false,

        form: this.$form.createForm(this, {
          name: 'linkman'
        }),

        statusDir: {
          save: '2',
          saveAndContinus: '1'
        },

        saveBtnType: '', // 1（保存并继续） 2（保存）
      }
    },

    props: {
      handle: String,
      options: Object,
      id: String,
      defaultValue: {
        type: Object,
        default() {
          return {}
        }
      },
    },

    computed: {
      isEdit() {
        return this.handle === this.$var.Handle.edit;
      },

      title() {
        return this.isEdit ? '编辑标签' : '新增标签'
      }
    },

    watch: {
      visible(newVal) {
        if (newVal) {
          if (this.handle === this.$var.Handle.edit) {
            this.$nextTick(() => {
              setTimeout(() => {
                this.setDefaultValue(this.defaultValue);
              }, 200);
            })
          }
        }
      }
    },

    mixins: [dialogMixins],

    components: {
    },

    methods: {

      handleOk(isClose) {
        this.saveBtnType = isClose ? this.statusDir.save : this.statusDir.saveAndContinus;

        this.form.validateFieldsAndScroll((errors, values) => {

          if (!errors) {
            this.addTagType(values, isClose);
          }
        })
      },

      handleCancel() {
        this.$emit('update:visible', false);
      },

      setDefaultValue(values) {
        this.form.setFieldsValue(values);
      },

      addTagType(params, isClose) {
        let urlFunc = 'Tags_add';
        let message = '添加成功'

        this.confirmLoading = true;

        if (this.handle === this.$var.Handle.edit) {
          urlFunc = 'Tags_edit';
          message = '编辑成功';
          params.label_id = this.id;
        } else {
          params.cate_id = this.options.cateId;
          params.label_id = this.options.tagId;
        }

        this.$axios[urlFunc](params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success(message);
            this.$emit('success', data.data);

            if (isClose) {
              this.$emit('update:visible', false);
            } else {
              this.form.resetFields();
            }
          }

          this.confirmLoading = false;
        }).catch(() => {
          this.confirmLoading = false;
        })
      },
      // onChangeColor(value) {

      // }
    }
  }
</script>

<style lang="scss" scoped>
  .add-tags-type {
    /deep/ {
      .ant-checkbox-group-item {
        margin-bottom: 10px;
      }
    }

    .color-picker {
      vertical-align: middle;

      /deep/ .colorBtn {
        width: 100%;
        height: 100%;
      }
    }
  }
</style>
