<template>
  <div class="ng-verify-musician h-100">
    <main-structure dir="ver">
      <template slot="header">
        <span class="title">{{title}}</span>
      </template>

      <template>
        <div class="m-top-ms flex-0">
          <verify-filter
            @change="onFilterChange"
            :defaultData="$route.query"
            :statusDisabledResult="statusDisabledResult"
          ></verify-filter>
        </div>

        <div class="m-top-base flex-1" ref="ngTableBox">
          <ng-table
            v-if="$root.$checkAuth($auth[name] && $auth[name].list)"
            class="ng-table ver-top"
            :columns="columns"
            :rowKey="row => row[idName]"
            :scroll="{y: mainContentScrollMaxHeight}"
            :params="params"
            :request="tableRequestFunc"
            :load.sync="loadTable"
            :reload.sync="reloadTable"
          >
            <!-- 查看详情 -->
            <template slot="supplier_name" slot-scope="text, scope">
              <a-tooltip placement="top" title="点击进入详情页了解更多">
                <router-link :to="`/verify/${$route.meta.type}/detail/${scope.apply_id}`">{{text}}</router-link>
              </a-tooltip>
            </template>

            <!-- 证件信息 -->
            <template slot="license_info" slot-scope="text, scope">
              <a href="javascript:;" @click="lookLicenseInfo(scope, $event)">查看</a>
            </template>

            <!-- 营业执照 -->
            <template slot="business_license" slot-scope="text, scope">
              <div class="table-image-box pointer" @click="previewPicture(scope, $event)">
                <ng-image
                  class="table-image"
                  :src="text ? `${text}?x-oss-process=style/thumb_10` : ''"
                  @error="({target}) => target.src = ''"
                />
              </div>
            </template>

            <!-- 上传作品 -->
            <template slot="showreel" slot-scope="text, scope">
              <template v-for="(item, index) in scope.showreel">
                <ng-video-tag
                  class="table-video-tag"
                  :url="item.url"
                  :text="item.name || ''"
                  :playing="$store.state.globalVideoPlaying && $store.state.globalVideoInfo.url === item.url"
                  @play="onPlayVideo(item)"
                  :key="`${scope[idName]}-${index}`"
                ></ng-video-tag>
              </template>
            </template>

            <!-- 操作 -->
            <template
              v-if="$root.$checkAuth($auth[name] && $auth[name].operation)"
              slot="operation"
              slot-scope="text, scope"
            >
              <template v-if="scope.check_status !== '1'">
                <a-button
                  class="op-btn"
                  type="primary"
                  @click="handleFunc($var.Handle.agree, scope)"
                >同意</a-button>

                <a-button
                  class="op-btn"
                  type="danger"
                  @click="handleFunc($var.Handle.refuse, scope)"
                >拒绝</a-button>
              </template>
              <template v-else>
                <template v-if="scope.check_result === '1'">
                  <a-tooltip
                    placement="top"
                    :title="`通过理由：${scope.check_reason || '--'}`"
                    arrow-point-at-center
                  >
                    <a-button class="op-btn" type="primary">已通过</a-button>
                  </a-tooltip>
                </template>
                <template v-else>
                  <a-tooltip
                    placement="top"
                    :title="`拒绝理由：${scope.check_reason || '--'}`"
                    arrow-point-at-center
                  >
                    <a-button class="op-btn" type="danger">已拒绝</a-button>
                  </a-tooltip>
                </template>
              </template>
            </template>
          </ng-table>
        </div>
      </template>

      <verify-confirm
        :visible.sync="showVerifyConfirm"
        :options="confirmOptions"
        :type="$route.meta.type"
        :handle="confirmHandle"
        @success="verifySuccess"
      ></verify-confirm>

      <license-info :visible.sync="showLicenseInfo" :data="license_info"></license-info>

      <preview-image :visible.sync="showPreviewImage" :url="license_info.business_license"></preview-image>
    </main-structure>
  </div>
</template>

<script>
  import {NgVideoTag} from '@/components/index';
  import VerifyFilter from './components/VerifyFilter.vue';
  import VerifyConfirm from './dialog/VerifyConfirm.vue';
  import LicenseInfo from './dialog/LicenseInfo.vue';
  import {PreviewImage} from '@/components/index';

  import {mainContentMixin, tableMixins, filterReq} from '@/mixins';

  import {SupplierType, SupplierTypeDir} from '@/utils/variables'
  import * as TabCol from '@/config/columns';

  const {MUSICIAN, TEAM, COMPANY} = SupplierType;

  const columnsFunc = function (type) {
    let column = [TabCol.colId], diffCol = [];

    switch (type) {
      case MUSICIAN:
        diffCol = [
          TabCol.commonKey('艺名', 'stage_name', {isSlot: true, slotName: 'supplier_name', ellipsis: true}),
          TabCol.commonKey('创建时间', 'create_at'),
          TabCol.commonKey('证件信息', 'license_info', null, true),
        ];
        break;

      case TEAM:
        diffCol = [
          TabCol.commonKey('团体名称', 'name', {isSlot: true, slotName: 'supplier_name', ellipsis: true}),
          TabCol.commonKey('创建时间', 'create_at'),
          TabCol.commonKey('证件信息', 'license_info', null, true),
        ];
        break;

      case COMPANY:
        diffCol = [
          TabCol.commonKey('公司名称', 'name', {isSlot: true, slotName: 'supplier_name', ellipsis: true}),
          TabCol.commonKey('创建时间', 'create_at'),
          TabCol.commonKey('营业执照', 'business_license', null, true),
        ];
        break;
    }

    column = column.concat(diffCol, [
      TabCol.commonKey('作品', 'showreel', null, true),
      TabCol.operation('180px')
    ]);

    return column;
  };

  const titleFunc = function (type) {
    return `${SupplierTypeDir[type]}审核`
  }

  const RequestListDir = function (axios, type) {
    const dir = {
      [MUSICIAN]: axios['Ver_musicianList'],
      [TEAM]: axios['Ver_teamList'],
      [COMPANY]: axios['Ver_companyList']
    }

    return dir[type] || dir[MUSICIAN];
  }

  export default {
    data() {
      return {
        name: `verify_${this.$route.meta.type}`,

        title: '',
        columns: [],

        data: [],

        params: {},

        tableRequestFunc: () => { },

        idName: 'apply_id',

        videoPlayId: 0,

        statusDisabledResult: '0', // 需要禁用result的status 的id值

        showVerifyConfirm: false,

        showLicenseInfo: false,
        showPreviewImage: false,

        license_info: {},

        confirmOptions: {},

        confirmHandle: '',

      }
    },

    created() {
      this.init(this.$route.meta.type)
    },

    mounted() {
      let {check_status = '', check_result = '', page = 1} = this.$route.query;
      this.params = {check_status, check_result, page};
    },

    components: {
      NgVideoTag, VerifyFilter, VerifyConfirm, LicenseInfo, PreviewImage
    },

    mixins: [
      mainContentMixin, tableMixins, filterReq
    ],

    methods: {

      init(type) {
        this.tableRequestFunc = RequestListDir(this.$axios, type)
        this.name = `verify_${type}`;
        this.title = titleFunc(type);
        this.columns = columnsFunc(type);
      },

      onPlayVideo(scope) {
        let playing = this.$store.state.globalVideoPlaying;
        let stUrl = this.$store.state.globalVideoInfo.url;
        let scopeUrl = scope.url;

        if (stUrl !== scopeUrl) {
          this.$store.commit('changeVideoPlaying', false);

          setTimeout(() => {
            this.$store.commit('changeVideoPlaying', true);
            this.$store.commit('changeVideoInfo', scope);
          }, 100);
        } else {
          this.$store.commit('changeVideoPlaying', !playing);
        }
      },

      onFilterChange(values = {}) {
        let params = {...values};

        // 待审核
        if (values.check_status === this.statusDisabledResult) {
          params.check_result = '';
        }

        this.params = this.filterCommon(params);
      },

      lookLicenseInfo(scope) {
        this.license_info = scope;
        this.showLicenseInfo = true;
      },

      previewPicture(scope) {
        this.license_info = scope;
        this.showPreviewImage = true;
      },

      handleFunc(handle, scope) {
        this.confirmHandle = handle;

        // this.confirmOptions = {
        //   apply_id: scope.apply_id
        // }

        this.showVerifyConfirm = true;

        let check_result = handle === 'agree' ? '1' : '0';

        let options = {};

        options.apply_id = scope.apply_id;
        options.check_result = check_result;

        if (handle === 'agree') {
          options.title = '确认通过该对象的合作申请吗？';
          options.placeholder = '请输入通过理由，该理由仅用于内部记录&查看';
        } else {
          options.title = '确认拒绝该对象的合作申请吗？';
          options.placeholder = '请输入拒绝理由';
        }

        this.confirmOptions = options;
      },

      verifySuccess() {
        this.loadTableData();
      }
    }
  }
</script>

<style lang="scss" scoped>
  .ng-verify-musician {
    .op-btn {
      width: 88px;
      margin-bottom: 10px;
    }

    .table-video-tag {
      max-width: 230px;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .table-image-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 151px;
      height: 100px;

      > img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
</style>
