<template>
  <a-modal
    :title="title"
    :width="$config.DialogWidth"
    v-model="show"
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form
      :form="form"
      layout="horizontal"
      :label-col="{ xs: {span: 6}}"
      :wrapper-col="{ xs: {span: 16}}"
      class="ng-form m-top-base"
    >
      <a-form-item label="联系人姓名">
        <a-input
          size="large"
          placeholder="请输入联系人真实姓名"
          v-decorator="['name', { rules: [$validate.required, $validate.letter_zh_number_char], initialValue: defaultData.linkman || '', validateTrigger: 'blur'  }]"
        ></a-input>
      </a-form-item>

      <a-form-item label="联系人职位">
        <a-input
          size="large"
          placeholder="请输入联系人职位"
          v-decorator="['position', { rules: [$validate.required, $validate.letter_zh_number_char], initialValue: defaultData.linkman_position || '', validateTrigger: 'blur'  }]"
        ></a-input>
      </a-form-item>

      <!-- 电话 -->
      <a-form-item label="联系电话" required style="margin-bottom:0;">
        <a-form-item style="display: inline-block; width: calc(100% - 250px); margin-right: 20px">
          <form-phone-area
            size="large"
            show-type="both"
            v-decorator="['phone_code', { rules: [$validate.required], initialValue: defaultData.phone_code || $config.PhoneCodeDefault}]"
          ></form-phone-area>
        </a-form-item>

        <a-form-item :style="{display: 'inline-block', width: '230px'}">
          <a-input
            size="large"
            type="number"
            v-decorator="['phone', { rules: [$validate.required, $validate.phone], initialValue: defaultData.phone || '', trigger: 'blur' }]"
            placeholder="请输入你的新手机号"
          />
        </a-form-item>
      </a-form-item>

      <a-form-item label="联系邮箱">
        <a-input
          size="large"
          placeholder="请输入联系人邮箱"
          v-decorator="['email', { rules: [$validate.required, $validate.email], initialValue: defaultData.email || '', validateTrigger: 'blur'  }]"
        ></a-input>
      </a-form-item>

      <a-form-item label="备注">
        <a-input
          size="large"
          v-decorator="['remark', { initialValue: defaultData.remark || '', validateTrigger: 'blur'  }]"
        ></a-input>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import {FormPhoneArea} from '@/components/index';

  export default {
    data() {
      return {
        show: false,

        confirmLoading: false,

        defaultData: {},
      }
    },

    props: {
      user_id: String,
      handle: String,
      options: Object,
      defaultValue: {
        type: Object,
        default() {
          return {}
        }
      },
    },

    computed: {
      isEdit() {
        return this.handle === this.$var.Handle.edit;
      },
      title() {
        return this.isEdit ? '修改联系人' : '添加联系人'
      },
    },

    watch: {
      visible(newVal) {
        if (newVal && this.form) {

          this.$nextTick(() => {
            this.form.resetFields();

            this.isEdit && this.form.setFieldsValue(this.defaultValue);
          })
        }
      },
    },

    mixins: [dialogMixins],

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        name: 'linkman'
      })
    },

    components: {
      FormPhoneArea
    },

    methods: {

      handleOk() {
        this.form.validateFieldsAndScroll((errors, values) => {

          if (!errors) {
            this.onSubmit(values).then((data) => {
              this.$emit('update:visible', false);
              this.$emit('success', data);
            })
          }
        })
      },

      // Address_add  Address_edit
      onSubmit(param) {
        let url = this.isEdit ? 'Sup_Contact_edit' : 'Sup_Contact_add';

        this.confirmLoading = true;

        if (this.isEdit) {
          param = Object.assign(param, {contact_id: this.defaultValue.contact_id})
        }

        param.user_id = this.user_id;

        return new Promise(resolve => {
          this.$axios[url] && this.$axios[url](param).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              resolve(data);
              this.$message.success(this.handle === this.$var.Handle.edit ? '编辑成功' : '新增成功');
            }

            this.confirmLoading = false;
          }).catch(() => {
            this.confirmLoading = false;
          })
        })
      },

      handleCancel() {

      },
    }
  }
</script>

<style lang="scss" scoped>
  .ng-supplier-add {
    /deep/ .ant-form-item-label {
      width: 130px;
    }
  }

  .dynamic-delete-button {
    position: absolute;
    top: 50%;
    right: -0;
    transform: translate(120%, -50%);
    font-size: 24px;
    color: #999;
    transition: all 0.3s;
    cursor: pointer;
  }

  .upload-box {
    /deep/ {
      .ant-upload {
        width: 148px;
        height: 104px;
        margin-bottom: 0;
      }
    }
  }
</style>
