<template>
  <a-select v-bind="$props" :options="listOptions" @change="triggerChange"></a-select>
</template>

<script>
  import {Select} from 'ant-design-vue';

  let props = Object.assign({}, Select.props);

  delete props.open;

  export default {
    data() {
      return {
        list: []
      }
    },

    props: {
      ...props,
      deptId: {
        type: [Number, String],
        default: ''
      },
      all: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      listOptions() {
        return this.list.map(({uid, name}) => ({label: name, value: uid}))
      }
    },

    created() {
      this.getData();
    },

    methods: {
      triggerChange(changedValue) {
        this.$emit('change', changedValue);
      },

      getData() {
        this.$axios.GetLibraryCommonMember({}).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            let options = data.data ? data.data.list : [];

            if (this.all) {
              options.unshift({
                name: '全部',
                uid: ''
              })
            }

            this.list = options;
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
