<template>
  <a-modal
    width="640px"
    title="发票详情"
    v-model="show"
    :maskClosable="true"
    @ok="show = false;"
    :bodyStyle="bodyStyle"
  >
    <ng-detail title="基本信息">
      <ul class="info-list">
        <li>申请日期：{{info.create_at}}</li>
        <li>处理日期：{{info.operate_time}}</li>
        <li>开票金额：￥{{info.total_amount}}</li>
        <li>申请用户：{{info.user_name}}</li>
        <!-- <li>关联订单：{{info.order ? info.order.order_no : ''}}</li> -->
        <li>抬头类型：{{info.title_type | toReceiptTitleType}}</li>
        <li>发票类型：{{info.invoice_type | toReceiptType}}</li>

        <li>申请编号：{{info.number}}</li>
        <li>处理人员：{{info.operator_name}}</li>
      </ul>
    </ng-detail>
    <ng-detail title="开票信息">
      <ul class="info-list">
        <template v-if="info.title_type === $val.ReceiptTitleType.company">
          <li>公司名称：{{info.title ? info.title.name: ''}}</li>
          <li>纳税人识别号：{{info.title ? info.title.taxer_number: ''}}</li>
          <div v-if="info.invoice_type === $val.ReceiptType.specialEelec">
            <li>注册电话：{{info.title ? info.title.phone: ''}}</li>
            <li>注册地址：{{info.title ? info.title.address: ''}}</li>
            <li>开户银行：{{info.title ? info.title.bank: ''}}</li>
            <li>银行账号：{{info.title ? info.title.account: ''}}</li>
          </div>
          
        </template>
        <template>
          <!-- <li>抬头名称：{{info.title ? info.title.name: ''}}</li> -->
        </template>
      </ul>
    </ng-detail>

    <ng-detail title="收件信息">
      <ul class="info-list">
        <template v-if="info.invoice_type >= $val.ReceiptType.commonEelec">
          <li>邮箱地址：{{info.express ? info.express.email : ''}}</li>
        </template>
        <template v-else>
          <li>邮寄地址：{{info.express ? info.express.address : ''}}</li>
          <li>收件人姓名：{{info.express ? info.express.name : ''}}</li>
          <li>收件人手机号：{{info.express ? info.express.phone : ''}}</li>
        </template>
      </ul>
    </ng-detail>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';

  import {
    ReceiptHeaderTypeOptions,
    ReceiptTypeOptions
  } from '@/config/options';
  import {toOptionName} from '@/utils/function';

  export default {
    data() {
      return {
        show: false,
        info: {}
      }
    },

    props: {
      id: String,
    },

    watch: {
      visible(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.getData(this.id);
          })
        }
      }
    },

    filters: {
      toReceiptType(value) {
        return toOptionName(ReceiptTypeOptions, value);
      },

      toReceiptTitleType(value) {
        return toOptionName(ReceiptHeaderTypeOptions, value);
      },
    },

    mixins: [dialogMixins],

    components: {
    },

    methods: {
      getData(invoice_id) {
        let params = {invoice_id};

        this.$axios.BuyManage_receiptInfo(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.info = data.data ? data.data : {}
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .info-list {
    line-height: 2.2;
  }
</style>
