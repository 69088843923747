export default {
  data() {
    return {
      reloadTable: false,
      loadTable: false,
    }
  },

  methods: {
    reloadTableData() {
      this.reloadTable = true;
    },

    loadTableData() {
      console.log('loadTableData')
      this.loadTable = true;
    },
  }
}