<template>
  <div>
    <!-- <div class="text-center">
      <a-button class="w-100" @click="onHandle($var.Handle.add)">添加收件信息</a-button>
    </div>-->

    <a-spin :spinning="spinning" class="w-100">
      <detail
        v-if="$root.$checkAuth($auth[authName] && $auth[authName].addressList)"
        :list="list"
        :user_id="user_id"
        :is-look="handle === $var.Handle.look"
        :auth-name="authName"
        @onEdit="onEditHandle"
        @success="onSuccess"
      ></detail>
    </a-spin>

    <div
      v-if="handle !== $var.Handle.look && $root.$checkAuth($auth[authName] && $auth[authName].addAddress)"
      class="text-center"
    >
      <a-button type="dashed" size="large" class="w-100" @click="onHandle($var.Handle.add)">
        <a-icon type="plus" />添加收件信息
      </a-button>
    </div>

    <dialog-add
      :user_id="user_id"
      :default-value="dialogFormDefault"
      :visible.sync="showHandleDialog"
      :handle="handleType"
      @success="onSuccess"
    ></dialog-add>
  </div>
</template>

<script>
  import detail from "./components/detail";
  import DialogAdd from './components/DialogAdd.vue'

  export default {
    data() {
      return {
        title: "收件信息",

        showHandleDialog: false,
        dialogFormDefault: {},
        handleType: this.$var.Handle.add,

        list: [],
        spinning: false,
      };
    },

    props: {
      user_id: String,
      handle: String,
      authName: String
    },

    created() {
      this.getData();
    },

    components: {
      detail,
      DialogAdd,
    },

    methods: {
      onSuccess() {
        this.getData();
      },

      onEditHandle(item) {
        item = Object.assign({}, item, {
          is_default: !!+item.is_default
        })

        this.dialogFormDefault = item;

        this.onHandle(this.$var.Handle.edit);
      },

      getData() {
        let param = {user_id: this.user_id};

        this.spinning = true;

        this.$axios.Sup_Address_list(param).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            // something
            this.list = data.data.list.map(item => Object.assign(item, {
              phone_code: item.phone_code.id,
              phone_code_item: item.phone_code,
            }));
          }

          this.spinning = false;
        })
      },

      onHandle(type) {
        this.handleType = type;
        this.showHandleDialog = true;
      },
    }
  };
</script>

<style lang="scss" scoped>
  .add-btn {
    cursor: pointer;

    .iconfont {
      width: 20px;
      height: 20px;
      margin-right: 2px;
    }
  }
</style>
