<template>
  <main-structure class="ng-account-members" dir="ver">
    <template slot="header">
      <span class="title">标注音乐管理</span>
    </template>

    <template>
      <div class="m-top-ms flex-0">
        <ng-breadcrumb :data="breadData"></ng-breadcrumb>
      </div>

      <filter-music @change="onFilterChange" :defaultData="$route.query" class="m-top-base"></filter-music>

      <div class="m-top-base flex-1" ref="ngTableBox">
        <table-music
          :scrolly-height="mainContentScrollMaxHeight"
          :auth-name="name"
          :from="$route.query.from"
          :params="tableParams"
          ref="manageTable"
        ></table-music>
      </div>
    </template>
  </main-structure>
</template>

<script>
  import TableMusic from './markMusicManage/TableMusic.vue';
  import FilterMusic from './markMusicManage/FilterMusic.vue'

  import {mainContentMixin, filterReq} from '@/mixins';
  import {Common} from '@/utils/variables'

  // 根据跳转的原页面，判断面包屑路由路径
  const pathDir = {
    [Common.first]: {
      name: '标注任务',
      path: '/librarymark/task/serving', // 标注任务 服务中 查看
    },
    [Common.second]: {
      name: '标注任务',
      path: '/librarymark/task/servedone', // 标注任务 已完成 查看
    },
    [Common.third]: {
      name: '标注管理 - 查看进度',
      path: '/librarymark/manage/serving', // 标注管理 服务中 进度 查看
    },
    [Common.fourth]: {
      name: '标注管理',
      path: '/librarymark/manage/servedone', // 标注管理 已完成 查看
    },
  }

  const breadData = function (from) {
    let curPath = pathDir[from], dPath = pathDir[Common.first];

    return [
      {breadcrumbName: curPath.name || dPath.name, path: curPath.path || dPath.path},
      {breadcrumbName: '音乐管理'}
    ]
  }

  export default {
    data() {
      return {
        name: `musicManage_mark_${this.$route.query.from}`,
        breadData: breadData(this.$route.query.from),
        isSuccess: true,
        tableParams: {}
      }
    },
    components: {
      TableMusic, FilterMusic
    },

    mounted() {
      let query = this.$route.query;
      let id = this.$route.params.id || '';
      let isByBatch = this.$route.query.from === this.$var.Common.fourth;
      let idName = isByBatch ? 'batch_id' : 'task_id';

      this.tableParams = this.parseArrParams({...query, [idName]: id});
    },

    mixins: [
      mainContentMixin, filterReq
    ],

    methods: {
      onFilterChange(values) {
        let params = Object.assign({}, this.tableParams, values);

        params = this.parseArrParams(params);

        this.tableParams = this.filterCommon(params);
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
