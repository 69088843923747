<template>
  <a-modal
    width="420px"
    v-model="show"
    title="修改音乐"
    destroyOnClose
    :maskClosable="false"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    class="submit-music"
  >
    <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 15 }">
      <a-form-item label="音乐名称">
        <a-input
          placeholder="请输入音乐名称"
          v-decorator="['name', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'  }]"
        ></a-input>
      </a-form-item>
      <a-form-item label="音乐类型">
        <a-radio-group
          :options="allOption.MusicTypeOptions"
          v-decorator="['type', { rules: [$validate.required], initialValue:  '' }]"
        ></a-radio-group>
      </a-form-item>
      <a-form-item v-if="form.getFieldValue('type') === $val.MusicType.YX" label="音效描述">
        <ng-textarea
          v-decorator="['sound_desc', { initialValue: info.sound_desc || '', validateTrigger: 'blur'  }]"
          placeholder="请输入音效描述"
          style="height: 80px"
          :max-length="100"
          show-counter
        ></ng-textarea>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import * as allOption from '@/config/options'

  export default {
    data() {
      return {
        show: false,
        confirmLoading: false,
        form: this.$form.createForm(this, {name: ''}),
        allOption
      }
    },

    props: {
      info: {
        type: Object,
        default() {
          return {}
        },
      },
      music_id: String,
      type: String
    },

    components: {
    },

    watch: {
      show(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            let {name, type} = this.info;

            this.form.setFieldsValue({
              name,
              type,
            })
          })
        }
      }
    },

    mixins: [dialogMixins],


    methods: {

      handleOk() {
        this.form.validateFieldsAndScroll((errors, values) => {

          if (!errors) {
            values.music_id = this.music_id;

            this.confirmLoading = true;

            this.$axios.Enterlibrary_musicEdit(values).then(res => {
              const data = res.data;

              if (data && data.code == 0) {
                this.$message.success('修改成功');

                this.$emit('update:visible', false);

                this.$emit('success', data.data);
              }

              this.confirmLoading = false;
            }).catch(() => {
              this.confirmLoading = false;
            })

          }
        });
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
