<template>
  <div class>
    <ng-table
      class="ng-table"
      :columns="columns"
      :params="requestParams"
      :rowKey="(row) => row.license_id"
      :request="$axios.authAllRecord"
      :load.sync="loadTable"
      :reload.sync="reloadTable"
      :scroll="{ y: scrollyHeight - 20 }"
    >
      <template slot="operation" slot-scope="text, scope">
        <a-button
          class="m-right-ms"
          type="link"
          @click="goInfo(scope)"
          >查看详情
        </a-button>
      </template>
    </ng-table>

    <dialog-open-package
      :visible.sync="showDialog"
      :user_id="handleData.user_id"
      @success="onSuccess"
    ></dialog-open-package>
    <dialog-open-vip
      :visible.sync="showVipDialog"
      :user_id="handleData.user_id"
      @success="onSuccess"
    ></dialog-open-vip>
  </div>
</template>

<script>
import DialogOpenPackage from "./DialogOpenPackage.vue";
import DialogOpenVip from "./DialogOpenVip.vue";

import { tableMixins } from "@/mixins";

import * as TabCol from "@/config/columns";

const columns = [
  TabCol.colId,
  TabCol.commonKey("音乐名称", "music_name"),
  TabCol.commonKey("音乐人名称", "suplr_name"),
  TabCol.commonKey("套餐名称", "goods_name"),
  TabCol.commonKey("联系人", "contacter"),
  TabCol.commonKey("手机号码", "contact"),
  TabCol.commonKey("项目名称", "project"),
  TabCol.operation("100px"),
];

export default {
  data() {
    return {
      columns,
      requestParams: {},
      showDialog: false,
      currentData: {},
      handleData: {},
      showVipDialog: false,
    };
  },

  props: {
    authName: String,
    scrollyHeight: {
      type: Number,
      default: 400,
    },
    from: String,
    params: Object,
  },

  watch: {
    params: {
      handler(newVal) {
        this.requestParams = Object.assign({}, this.requestParams, newVal, {
          page: 1,
        });
      },
      immediate: true,
    },
  },

  components: {
    DialogOpenPackage,
    DialogOpenVip,
  },

  mounted() {},

  mixins: [tableMixins],

  methods: {
    onSuccess() {
      this.loadTableData();
    },
    goInfo(scope){
      let {license_id} = scope;
      if(license_id){
        window.open(`${this.$config.ServeBaseHost}/bms/musiclic/view?license_id=${license_id}`, '_blank');
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.mark-music-table {
  /deep/ {
    .ant-table td {
      white-space: nowrap;
    }

    .ant-table-tbody {
      .ant-table-row {
        &:hover {
          > td {
            background: transparent;
          }
        }
      }
    }

    .odd-row {
      > td {
        border-bottom: 0;
      }
    }

    .even-row {
      > td {
        padding-top: 0;
        padding-bottom: 5px;
      }
    }
  }
}
</style>
