/**
 * 账号权限管理
 */
import {
  Axios
} from '@/utils/from-common';
import {
  DownloadTimeout
} from '@/config/config'

// 用户列表
export const User_list = (param) => Axios('POST', '/bms/vip/userList', param);

//授权记录
export const authAllRecord = (param) => Axios('POST', '/bms/vip/authAllRecord', param);

// 基础信息
export const User_Base_info = (param) => Axios('POST', '/bms/vip/userInfo', param);

// 收件信息
export const User_Address_list = (param) => Axios('POST', '/bms/vip/exprsList', param);

// 账户信息
export const User_Account_info = (param) => Axios('POST', '/bms/vip/account', param);

// 发票抬头列表
export const User_Recipt_list = (param) => Axios('POST', '/bms/vip/invList', param);

// 用户订单列表
export const User_order_list = (param) => Axios('POST', '/bms/order/selfList', param);

// 用户订单信息
export const User_order_info = (param) => Axios('POST', '/bms/musiclic/info', param);

// 修改用户订单
export const User_order_save = (param) => Axios('POST', '/bms/musiclic/save', param);

// 下载用户授权
export const User_orderAuth_download = (param) => Axios('POST', '/bms/musiclic/download', param, {
  timeout: DownloadTimeout,
  responseType: 'blob'
});

// 用户行为列表
export const User_deed_list = (param) => Axios('POST', '/bms/deed/list', param);

// 用户订单授权信息
export const User_orderAuthInfo = (param) => Axios('POST', '/bms/musiclic/info', param);

// 查看用户授权书
export const User_lookOrderAuth = '/bms/musiclic/view';