export default {
    data() {
        return {
            labels: {
                all: [
                    { name: '音乐名称', modify: 'music_name' },
                    { name: '音乐ID', modify: 'music_id' },
                    { name: '音乐人名称', modify: 'suplr_name' },
                    { name: '音乐人ID', modify: 'suplr_id' },
                    { name: '下载时间', modify: 'download_time' },
                ],
                auth: [
                    { name: '音乐名称', modify: 'music_name' },
                    { name: '音乐ID', modify: 'music_id' },
                    { name: '音乐人名称', modify: 'suplr_name' },
                    { name: '音乐人ID', modify: 'suplr_id' },
                    { name: '授权时间', modify: 'auth_time' },
                    { name: '项目名称', modify: 'project' },
                    { name: '被授权方', modify: 'main' },

                    { name: '授权用途', modify: 'auth_scene' },
                    { name: '授权范围', modify: 'use_mode' },
                    { name: '授权地域', modify: 'auth_area' },
                    { name: '授权期限', modify: 'auth_time' },
                    { name: '投放渠道', modify: 'put_path' },
                    { name: '联系人', modify: 'contacter' },
                    { name: '手机号码', modify: 'contact' },
                ]
            }
        }
    },
    methods: {
        downloadRecorder(data, type,fileName) {
            
            let labels = this.labels[type];
            let CSV = labels.map(el => el.name).join(',');
            CSV += '\r\n';
            for (let i = 0; i < data.length; i++) {
                let d = data[i];
                let temp = [];
                labels.forEach(element => {
                    temp.push("\""+d[element.modify]+"\"");
                });
                CSV += temp.join(',');
                CSV += '\r\n';
            }
            //生成一个文件名称
            //这将从标题中删除空格，并用下划线替换
            fileName = fileName.replace(/ /g, "_");

            //初始化文件格式，您需要csv或xls。
            var uri = 'data:text/csv;charset=utf-8,\uFEFF' + encodeURI(CSV).replace(/&/g, '%26').replace(/\+/g, '%2B').replace(/\s/g, '%20').replace(/#/g, '%23');
            //这个技巧将生成一个temp <a />标记
            var link = document.createElement("a");
            link.href = uri;

            //这个技巧将生成一个temp <a />标记//设置隐藏的可见性，因此不会影响web布局
            link.style = "visibility:hidden";
            link.download = fileName + ".csv";

            //此部分将在自动点击后添加锚标签并删除
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}