<template>
  <div>
    <a-spin v-if="!isModifly" :spinning="spinning" class="w-100">
      <detail :data="detailData" :type="$route.params.type"></detail>
    </a-spin>

    <modify-form
      v-else
      :data="detailData"
      :user_id="user_id"
      :type="$route.params.type"
      :handle="handle"
      ref="formBox"
    ></modify-form>

    <div v-if="handle !== $var.Handle.look" class="modifly-btn">
      <a-button type="sub" class="w-px-120" @click="handleBtn" :loading="submiting">{{btnText}}</a-button>
    </div>
  </div>
</template>

<script>
  import modifyForm from "./components/modifyForm.vue";
  import detail from "./components/detail.vue";

  export default {
    data() {
      return {
        title: "基本信息",
        isModifly: false,
        detailData: {},
        spinning: false,
        submiting: false,
      };
    },

    props: {
      user_id: String,
      handle: String,
      authName: String
    },

    components: {
      detail,
      modifyForm,
    },

    computed: {
      btnText() {
        return this.isModifly ? "保存" : "修改";
      },
      name() {
        return this.authName
      }
    },

    created() {
      // if()
    },

    mounted() {
      if (this.handle !== this.$var.Handle.add) {
        this.getData(this.user_id);
      }
      else {
        this.isModifly = true;
      }
    },

    beforeRouteLeave(to, from, next) {
      if (this.isModifly) {
        this.$confirm({
          width: "480px",
          title: "确认放弃编辑？",
          onOk() {
            next();
          }
        });
      } else {
        next();
      }
    },

    methods: {
      handleBtn() {
        if (this.isModifly) {
          this.$refs.formBox.form.validateFieldsAndScroll((err, values) => {

            if (!err) {
              if (this.handle === this.$var.Handle.edit) {
                this.submitEdit(values);
              }
              else {
                this.submitAdd(values);
              }
            }
          });
          return;
        } else {
          this.isModifly = !this.isModifly;
        }
      },

      submitEdit(param) {
        let dir = {
          [this.$var.SupplierType.MUSICIAN]: this.$axios.Sup_Base_editMusicianInfo,
          [this.$var.SupplierType.TEAM]: this.$axios.Sup_Base_editTeamInfo,
          [this.$var.SupplierType.COMPANY]: this.$axios.Sup_Base_editCompanyInfo,
        }

        param.user_id = this.user_id;

        this.submiting = true;

        dir[this.$route.params.type](param).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('修改成功');
            this.getData(this.user_id);
            this.isModifly = false;
          }

          this.submiting = false;
        })
      },

      submitAdd(param) {
        let dir = {
          [this.$var.SupplierType.MUSICIAN]: this.$axios.Supplier_musicianAdd,
          [this.$var.SupplierType.TEAM]: this.$axios.Supplier_teamAdd,
          [this.$var.SupplierType.COMPANY]: this.$axios.Supplier_companyAdd,
        }

        this.submiting = true;

        dir[this.$route.params.type](param).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('新增成功');

            setTimeout(() => {
              this.$router.push(`/supplier/${this.$route.params.type}/detail/${data.data.user_id || 0}/${this.$var.Handle.edit}`);

              // location.reload();
            }, 500);
          }

          this.submiting = false;
        })
      },

      getData(user_id) {
        let param = {user_id};

        this.spinning = true;

        this.$axios.Sup_Base_info(param).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.detailData = data.data;

            this.$emit('success', this.data);
          }

          this.spinning = false;
        })
      }
    }
  };
</script>

<style lang="scss" scoped>
  .modifly-btn {
    margin-top: $space-md;
    // .modifly {
    //   width: 120px;
    //   height: 32px;
    //   border-radius: 2px;
    //   background: $ng-bg;
    //   font-size: 14px;
    //   font-weight: 300;
    //   color: rgba(255, 255, 255, 1);
    //   border: none;
    // }
  }
</style>
