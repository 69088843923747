<template>
  <main-structure class="ng-library" dir="ver">
    <template slot="header">
      <span class="title">用户管理</span>

      <div class="header-content header-search-content">
        <a-input-search
          class="ng-input-search"
          placeholder="请输入用户ID、用户名称以查询"
          :default-value="$route.query.keyword || ''"
          @search="onSearch"
          allowClear
        >
          <a-button slot="enterButton" type="sub">
            <a-icon type="search" />
          </a-button>
        </a-input-search>

        <!-- <a-button
          v-if="$root.$checkAuth($auth[name] && $auth[name].add)"
          type="sub"
          @click="handleFunc($var.Handle.add)"
        >新增曲库</a-button>-->
      </div>
    </template>

    <template>
      <div class="m-top-base flex-1" ref="ngTableBox">
        <AuthTable
          v-if="$root.$checkAuth($auth[name] && $auth[name].list)"
          :scrolly-height="mainContentScrollMaxHeight"
          :auth-name="name"
          :params="tableParams"
          :user_id="$route.params.user_id"
          @handle="onTableHandleSuccess"
          ref="ngTable"
        ></AuthTable>
      </div>
    </template>
  </main-structure>
</template>

<script>
import FilterUser from "./components/FilterUser.vue";
// import AuthTable from "./components/AuthTable.vue";
import AuthTable from "./components/MauthTable.vue";
import { mainContentMixin, filterReq } from "@/mixins";

import * as $config from "~/config/config.c";
import $storage from "~/utils/storage";
import * as $var from "~/utils/variables.c";
let baseURL = $config.ServeBaseHost;

export default {
  data() {
    return {
      name: `user_manage`,

      showHandleDialog: false,
      showCoopHandleDialog: false,

      handleType: this.$var.Handle.add,

      handleData: {},

      isReloadTable: false,

      tableParams: {},
    };
  },

  components: {
    AuthTable,
    FilterUser,
  },

  mixins: [mainContentMixin, filterReq],

  mounted() {
    let query = this.$route.query;

    this.tableParams = this.parseArrParams({ ...query });
  },

  methods: {
    onFilterChange() {
      let query = this.$route.query;

      query = this.parseArrParams({ ...query });

      this.tableParams = this.filterCommon(query);
    },

    onSearch(keyword) {
      typeof keyword === "string" && (keyword = keyword.trim());

      this.$root.$goSearch({ user_id:keyword });

      this.tableParams = Object.assign({}, this.tableParams, { user_id:keyword });
    },

    handleSuccess(isLoad) {
      this.showHandleDialog = false;
      this.showCoopHandleDialog = false;
      isLoad !== false
        ? this.$refs.ngTable.reloadTableData()
        : this.$refs.ngTable.loadTableData();
    },

    onTableHandleSuccess(scope) {
      let handle = scope.handleName;
      let varHandle = this.$var.Handle;

      if (handle === varHandle.edit) {
        this.handleFunc(handle, scope);
      }
    },

    handleFunc(key, scope) {
      this.handleData = scope || {};
      this.handleType = key;
      this.showHandleDialog = true;
    },

    onDownload() {
      let token = encodeURIComponent($storage.get($var.STORAGE.TOKEN));
      let url = `${baseURL}/bms/vip/downUser?token=${token}`;
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
