<template>
  <div class="h-100">
    <ng-table
      class="ng-table ant-table-small-space"
      :columns="columns"
      :params="params"
      childrenColumnName="child"
      :rowKey="row => row.music_id"
      :request="$axios.Song_musicList"
      :load.sync="loadTable"
      :reload.sync="reloadTable"
    >
      <template slot="name" slot-scope="text, scope">
        <a href="javascript:;" @click="onClickMusic(scope)" :title="text">{{text}}</a>
      </template>
      <template slot="status" slot-scope="text">
        <span>{{text | toMusicStatus}}</span>
      </template>
      <template slot="type" slot-scope="text">
        <span>{{text | toMusicType}}</span>
      </template>
      <template slot="auth_area" slot-scope="text">
        <span>{{text | toArea}}</span>
      </template>
      <template slot="auth_type" slot-scope="text">
        <span>{{text | toAuthType}}</span>
      </template>

      <template slot="operation" slot-scope="text, scope">
        <a
          v-if="$root.$checkAuth($auth[authName] && $auth[authName].download)"
          :href="scope.music_url"
          class="m-right-ms ant-btn ant-btn-link"
          :download="scope.original_name"
          target="_blank"
        >下载</a>

        <a-button
          v-if="$root.$checkAuth($auth[authName] && $auth[authName].del)"
          class="ant-btn-error"
          type="link"
          @click="onDelete(scope)"
        >删除</a-button>
      </template>
    </ng-table>
  </div>
</template>

<script>
  import * as TabCol from '@/config/columns';
  import {SellStatusOptions, AuthAreaOptions, AuthTypeOptions} from '@/config/options'
  import {toOptionName} from '@/utils/function'
  import {tableMixins} from '@/mixins';

  const columns = [
    TabCol.colIdFunc((text, index) => !text.parent_id ? index + 1 : ' '),
    TabCol.commonKey('音乐名称', 'name', {ellipsis: true, isSlot: true}),
    TabCol.commonKey('音乐原名', 'original_name', {ellipsis: true}),
    TabCol.commonKey('音乐类型', 'type', {isSlot: true}),

    TabCol.commonKey('所属曲库', 'lib_name', {ellipsis: true}),
    TabCol.commonKey('所属供应商', 'suplr_name', {ellipsis: true}),
    TabCol.commonKey('授权地域', 'auth_area', {isSlot: true, class: 'word-white-normal'}),
    TabCol.commonKey('授权类型', 'auth_type', {isSlot: true, class: 'word-white-normal'}),

    TabCol.commonKey('ISRC', 'isrc'),
    TabCol.commonKey('ISWC', 'iswc'),
    TabCol.commonKey('BPM', 'bpm'),
    TabCol.commonKey('比特率', 'bitrate'),
    TabCol.commonKey('采样率', 'samples'),
    TabCol.commonKey('时长(s)', 'duration'),
    TabCol.commonKey('文件大小(M)', 'size'),

    TabCol.operation('100px'),
  ]

  export default {
    data() {
      return {
        columns,
        params: {}
      }
    },

    props: {
      sid: String,
      authName: String,
    },

    filters: {
      toMusicStatus(value) {
        return toOptionName(SellStatusOptions, value);
      },
      toArea(value) {
        return toOptionName(AuthAreaOptions, value);
      },

      toAuthType(value) {
        return toOptionName(AuthTypeOptions, value);
      },
    },

    mounted() {
      this.params = {
        playlist_id: this.sid
      }
    },

    mixins: [
      tableMixins
    ],

    components: {
    },

    methods: {
      onClickMusic(scope) {
        let {music_url: url} = scope;

        this.$store.dispatch('onPlayGlobalVideo', Object.assign(scope, {url}));
      },

      onDelete(scope) {
        let vm = this;

        this.$confirm({
          width: '480px',
          title: '确认要删除该歌曲吗？',
          onOk() {
            return new Promise((resolve, reject) => {
              vm.deleteMember(scope, resolve, reject);
            });
          }
        });
      },

      deleteMember(scope, next, reject) {
        let param = {
          playlist_id: this.sid,
          music_id: scope.music_id
        };

        this.$axios.Song_musicList_delMusic(param).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('已成功删除该歌曲');
            next();

            setTimeout(() => {
              this.loadTableData();
            }, 500);
          } else {
            reject();
          }
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .ant-table td {
    white-space: nowrap;
  }
</style>
