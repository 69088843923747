<template>
  <ng-card>
    <!-- 已经关联 -->
    <template v-if="data.self_link && data.self_link.length > 0">
      <div class="detail-item">
        <h3>个人网站</h3>
        <div class="flex flex-wrap">
          <template v-for="(item, index) in data.self_link">
            <div class="web-icon flex cen-center" :key="index">
              <ng-image
                class="img"
                :src="MusicIconDir[item.type] ? MusicIconDir[item.type].url : ''"
                alt
              />
              <span>{{MusicIconDir[item.type] ? MusicIconDir[item.type].name : "--"}}</span>
            </div>
          </template>
        </div>
      </div>

      <div slot="right">
        <a-button
          v-if="!isLook && $root.$checkAuth($auth[authName] && $auth[authName].accountSelfLink)"
          type="link"
          @click="handleItem"
        >变更</a-button>
      </div>
    </template>
    <!-- 没有关联 -->
    <template v-else>
      <div class="detail-item">
        <h3>个人网站</h3>
        <div>未填写，分享你在其他音乐创作者社区的主页链接，帮助我们更好地了解你</div>
      </div>

      <div slot="right">
        <a-button
          v-if="!isLook && $root.$checkAuth($auth[authName] && $auth[authName].accountSelfLink)"
          type="link"
          @click="handleItem"
        >关联</a-button>
      </div>
    </template>

    <dialog-add-link
      :user_id="user_id"
      :data="data.self_link"
      @success="$emit('success')"
      :visible.sync="showAddDialog"
    ></dialog-add-link>
  </ng-card>
</template>

<script>
  import {NgCard} from '@/components/index';
  import DialogAddLink from './DialogAddLink.vue';

  import {MusicianWebs} from '@/config/options.value';

  import {LinksOptions} from '@/config/options';

  let MusicIconDir = {
    [MusicianWebs.AudioJungle]: {url: require('~/assets/images/musicicon/audiojungle.png')},
    [MusicianWebs.Douban]: {url: require('~/assets/images/musicicon/douban.png')},
    [MusicianWebs.Bandcamp]: {url: require('~/assets/images/musicicon/bandcamp.png')},
    [MusicianWebs.Spotify]: {url: require('~/assets/images/musicicon/spotify.png')},
    [MusicianWebs.SoundCloud]: {url: require('~/assets/images/musicicon/soundcloud.png')},
    [MusicianWebs.AppleMusic]: {url: require('~/assets/images/musicicon/applemusic.png')},
    [MusicianWebs.Tencent]: {url: require('~/assets/images/musicicon/tencent.png')},
    [MusicianWebs.NetEase]: {url: require('~/assets/images/musicicon/net.png')}, // 网易云音乐
    [MusicianWebs.Xiami]: {url: require('~/assets/images/musicicon/xiami.png')}, // 虾米音乐
    [MusicianWebs.Person]: {url: require('~/assets/images/musicicon/person.png')}, // 个人网站
  }

  LinksOptions.forEach(({label, value}) => {
    MusicIconDir[value] = {name: label, ...MusicIconDir[value]};
  })

  export default {
    data() {
      return {
        showAddDialog: false,
        handleType: this.$var.Handle.add,
        MusicIconDir
      }
    },

    props: {
      user_id: String,
      data: {
        type: Object,
        default() {
          return {};
        }
      },
      isLook: Boolean,
      authName: String
    },

    components: {
      NgCard, DialogAddLink
    },

    methods: {
      onAddChange() {

      },

      onVerifyChange() {

      },

      handleItem(type) {
        this.handleType = type;
        this.showAddDialog = true;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .detail-item {
    padding: 15px;
  }

  .web-icon {
    margin-right: 20px;
    margin-bottom: 10px;
    .img {
      height: 30px;
      margin-right: 5px;
    }
  }
</style>
