<template>
  <ng-table
    class="ng-table"
    :columns="columns"
    :params="params"
    :rowKey="row => row.order_id"
    :request="$axios.OrderPackage_list"
    :load.sync="loadTable"
    :reload.sync="reloadTable"
    :scroll="{y: scrollyHeight - 20}"
  >
    <template slot="order_no" slot-scope="text">
      <span class="word-break order-no">{{text}}</span>
    </template>

    <template slot="total_amount" slot-scope="text">
      <span>￥{{text}}</span>
    </template>

    <template slot="pay_amount" slot-scope="text">
      <span>￥{{text}}</span>
    </template>

    <template slot="status" slot-scope="text">
      <span>{{text | toOrderStatus}}</span>
    </template>

    <template slot="operation" slot-scope="text, scope">
      <router-link
        v-if="$root.$checkAuth($auth[authName] && $auth[authName].look)"
        class="m-right-ms"
        :to="`/order/package/${scope.order_id}`"
      >查看详情</router-link>
    </template>
  </ng-table>
</template>

<script>
  import {tableMixins} from '@/mixins';

  import {OrderStatusOptionsAdmin} from '@/config/options';
  import {toOptionName} from '@/utils/function';

  import * as TabCol from '@/config/columns';

  const columns = [
    TabCol.colId,
    TabCol.commonKey('订单号', 'order_no', {width: '200px', isSlot: true}),
    TabCol.commonKey('下单时间', 'create_at', null, true),
    TabCol.commonKey('应付金额', 'total_amount', null, true),
    TabCol.commonKey('实付金额', 'pay_amount', null, true),
    TabCol.commonKey('订单状态', 'status', null, true),
    TabCol.commonKey('用户名', 'user_name', null, true),
    TabCol.commonKey('套餐名称', 'goods_name', null, true),
    TabCol.operation('100px'),
  ];

  export default {
    data() {

      return {
        columns,
        params: {},
      }
    },

    props: {
      authName: String,
      scrollyHeight: {
        type: Number,
        default: 400
      },
      reload: Boolean,
      tableParams: Object,
      order_id: String,
    },

    watch: {
      tableParams(newVal) {
        this.$nextTick(() => {
          this.params = Object.assign({}, this.params, newVal || {});
        })
      }
    },

    mounted() {
      // let {range = [], page = 1, channel, status} = this.$route.query;
      // let order_id = this.order_id;

      // let params = {channel, page, status, }

      // params['time[start]'] = range[0] || '';
      // params['time[end]'] = range[1] || '';

      // order_id && (params.order_id = order_id || 0)

      // this.params = params;
    },

    filters: {
      toOrderStatus(value) {
        return toOptionName(OrderStatusOptionsAdmin, value);
      },
    },

    mixins: [
      tableMixins
    ],

    methods: {
      handleFunc(key, scope) {
        this.$emit('handle', Object.assign(scope, {
          handleName: key
        }))
      },
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .ant-table .operation {
    white-space: nowrap;
  }
</style>
