<template>
  <main-structure
    class="ng-verify-detail ng-detail"
    dir="ver"
    :loading="loading"
  >
    <template slot="header">
      <ng-breadcrumb :data="breadData"></ng-breadcrumb>
    </template>

    <div class="m-top-base flex-1" ref="ngTableBox">
      <div class="m-bottom-base">
        <span class="m-right-base">开通套餐总数 {{ total }}</span>
        <span>已到期套餐数 {{ expired_total }}</span>
        <div class="downloadBtn" @click="download">下载记录</div>
      </div>

      <manage-table
        v-if="$root.$checkAuth($auth[name] && $auth[name].list)"
        :scrolly-height="mainContentScrollMaxHeight"
        :auth-name="name"
        :table-params="tableParams"
        :user_id="$route.params.user_id"
        @dataChange="onDataChange"
        ref="ngTable"
      ></manage-table>
    </div>
  </main-structure>
</template>

<script>
import { NgBreadcrumb } from "@/components/index";
import ManageTable from "./package/ManageTable.vue";
import { recorderExport } from "@/mixins";

import { mainContentMixin, filterReq } from "@/mixins";

const breadData = [
  { breadcrumbName: `用户管理`, path: `/user/manage` },
  { breadcrumbName: `用户套餐` },
];

export default {
  data() {
    return {
      name: `user_package`,
      breadData,
      user_id: "0",
      loading: false,
      tableParams: {},
      isReloadTable: false,

      expired_total: 0,
      total: 0,
    };
  },
  components: {
    NgBreadcrumb,
    ManageTable,
  },

  mixins: [mainContentMixin, filterReq, recorderExport],

  created() {
    let user_id = this.$route.params.user_id || "0";

    this.user_id = user_id;

    this.tableParams.user_id = user_id;
  },

  methods: {
    download() {
      let type = "all";
      let user_id = this.$route.params.user_id || "0";
      let params = { user_id };
      let filename = `${user_id}-所有套餐记录`;
      this.$axios.Package_all_downloadRecord(params)
        .then((res) => {
          
          if(Object.keys(res.data.data).length >0){
            this.downloadRecorder(res.data.data,type,filename);
          }else{
            this.$message.error('记录为空');
          }
        })
        .catch(() => {});
    },
    handleFunc() {},

    onDataChange(data) {
      data = data.data ? data.data : {};

      this.expired_total = data.expired_total;
      this.total = data.total;
    },
  },
};
</script>

<style lang="scss" scoped>
.downloadBtn{
  float: right;
  margin-right: 35px;
  color: #00A1D6;
  cursor: pointer;
}
.downloadBtn:hover{
  color: #24bae3;
}
</style>
