<template>
  <a-form class="ng-form-style ng-filter" :form="form" layout="inline">
    <a-form-item label="标注对象">
      <a-cascader
        :options="MarkTarget"
        changeOnSelect
        expandTrigger="hover"
        placeholder="请选择标注对象"
        v-decorator="['tag_target', {initialValue: ['']}]"
      ></a-cascader>
    </a-form-item>

    <a-form-item label="状态">
      <a-select
        :options="CloseStatus"
        placeholder="请选择状态"
        v-decorator="[
          'is_enabled',
          { initialValue: defaultData.is_enabled || '' },
        ]"
      ></a-select>
    </a-form-item>
  </a-form>
</template>

<script>
  import {Cascader as ACascader} from 'ant-design-vue';

  import {MarkTarget, MusicTypeOptions, OpenAndCloseStatus, AddDefaultToOptions} from '@/config/options'

  let CloseStatus = AddDefaultToOptions(OpenAndCloseStatus, '全部', '', 'name', 'id').map(({name, id}) => ({label: name, value: id}));
  let Target = AddDefaultToOptions(MarkTarget, '全部', '');

  Target[1].children = MusicTypeOptions;

  export default {
    data() {
      return {
        MarkTarget: Target,
        CloseStatus,
      }
    },

    props: {
      type: String,
      defaultData: Object,
    },

    components: {
      ACascader
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        onValuesChange: (props, values) => {
          if (values.tag_target) {
            values = {
              first_tag_target: values.tag_target[0] || '',
              second_tag_target: values.tag_target[1] || '',
            }
          }

          this.$root.$goSearch(values);

          this.$emit('change', values);
        }
      });
    },

    mounted() {
      this.$nextTick(() => {
        let {first_tag_target, second_tag_target} = this.$route.query

        let tag_target = first_tag_target ? [first_tag_target, second_tag_target] : ['']

        this.form.setFieldsValue({tag_target: tag_target});
      })
    },

    methods: {
    }
  }
</script>

<style lang="scss" scoped>
  .ng-filter {
  }
</style>
