<template>
  <a-modal
    :title="modalTitle"
    width="500px"
    v-model="show"
    :confirm-loading="confirmLoading"
    centered
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }">
      <!-- 姓名 -->
      <a-form-item label="姓名">
        <a-input
          size="large"
          v-decorator="[
            'name',
            { rules: [$validate.required], trigger: 'blur' },
          ]"
          placeholder="请输入成员姓名"
          :disabled="allDisabled"
        />
      </a-form-item>

      <!-- 邮箱 -->
      <a-form-item label="邮箱">
        <a-input
          size="large"
          type="email"
          v-decorator="[
            'email',
            { rules: [$validate.required, $validate.email], trigger: 'blur' },
          ]"
          placeholder="请输入成员邮箱"
          :disabled="allDisabled"
        />
      </a-form-item>

      <!-- 电话 -->
      <a-form-item label="电话" required style="margin-bottom: 0">
        <a-form-item
          style="
            display: inline-block;
            width: calc(100% - 210px);
            margin-right: 20px;
          "
        >
          <form-phone-area
            size="large"
            v-decorator="[
              'phone_code',
              {
                rules: [$validate.required],
                initialValue: $config.PhoneCodeDefault,
              },
            ]"
            :disabled="allDisabled"
          ></form-phone-area>
        </a-form-item>

        <a-form-item :style="{ display: 'inline-block', width: '190px' }">
          <a-input
            size="large"
            type="number"
            v-decorator="[
              'phone',
              { rules: [$validate.required, $validate.phone], trigger: 'blur' },
            ]"
            placeholder="请输入成员手机号"
            :disabled="allDisabled"
          />
        </a-form-item>
      </a-form-item>

      <!-- 部门 -->
      <a-form-item label="部门">
        <form-department
          size="large"
          changeOnSelect
          expandTrigger="hover"
          v-decorator="['dept_id', { rules: [$validate.required] }]"
          placeholder="请选择部门"
          @change="changeDepartment"
          :disabled="allDisabled"
        ></form-department>
      </a-form-item>

      <!-- 职位 -->
      <a-form-item label="职位">
        <a-input
          size="large"
          v-decorator="[
            'job_title',
            { rules: [$validate.required], trigger: 'blur' },
          ]"
          placeholder="请输入成员职位"
          :disabled="allDisabled"
        />
      </a-form-item>

      <!-- 角色 -->
      <a-form-item label="角色">
        <form-select
          size="large"
          v-decorator="['role_id', { rules: [$validate.required] }]"
          placeholder="请选择成员角色"
          valueName="role_id"
          :dataFunc="$axios.AllOpenRoles"
          :disabled="allDisabled || disabledRoles"
          :params="rolesParams"
          :all="false"
          :immediate="false"
        ></form-select>
      </a-form-item>

      <!-- 驻地 -->
      <a-form-item label="驻地">
        <a-input
          size="large"
          v-decorator="[
            'station',
            {
              rules: [$validate.required],
              initialValue: '北京',
              trigger: 'blur',
            },
          ]"
          placeholder="请输入成员工作驻地"
          :disabled="allDisabled"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import {FormDepartment} from '@/components/index';
  import {FormPhoneArea} from '@/components/index';

  const TitleOptions = {
    add: '新增成员',
    edit: '编辑成员',
    look: '查看成员'
  }

  export default {
    data() {
      return {
        show: false,
        confirmLoading: false,
        form: this.$form.createForm(this, {name: 'members'}),
        rolesParams: {dept_id: '', has_children: 1},
      }
    },

    props: {
      handle: String,
      data: Object
    },

    computed: {
      canEdit() {
        return this.handle === this.$var.Handle.add || this.handle === this.$var.Handle.edit;
      },

      // 全部禁用
      allDisabled() {
        return this.handle === this.$var.Handle.look;
      },

      modalTitle() {
        return this.$root.$getContentAccHandle(this.handle, TitleOptions) || TitleOptions.add
      },

      disabledRoles() {
        return !(this.rolesParams.dept_id !== '' && this.rolesParams.dept_id !== undefined && this.rolesParams.dept_id !== null);
      }
    },

    mixins: [dialogMixins],

    watch: {
      visible(newVal) {
        if (newVal && this.handle !== this.$var.Handle.add) {
          setTimeout(() => {
            this.initFields();
          }, 500);
        }
      },
    },

    components: {
      FormDepartment, FormPhoneArea
    },

    methods: {
      initFields() {
        let data = {...this.data};

        data.phone_code = data.phone_code ? data.phone_code.id : 1;

        try {
          this.form.setFieldsValue(data);

          this.rolesParams = {dept_id: data.dept_id, has_children: 0}
        } catch (error) {
          // console.error(error);
        }
      },

      changeDepartment(text) {
        let params = Object.assign({}, this.rolesParams, {
          dept_id: text,
          has_children: 0
        })

        this.rolesParams = params;
        this.form.resetFields(['role_id']);
      },

      handleOk() {
        if (this.handle === this.$var.Handle.look) {
          this.$emit('update:visible', false);
          return;
        }

        this.form.validateFieldsAndScroll((errors, values) => {
          if (!errors) {
            let method = 'Account_addMembers';

            if (this.handle === this.$var.Handle.edit) {
              method = 'Account_editMembers';

              values = Object.assign(values, {
                uid: this.data.uid
              });
            }

            this.confirmLoading = true;

            this.$axios[method](values).then(res => {
              const data = res.data;

              if (data && data.code == 0) {
                this.$message.success(this.handle === this.$var.Handle.add ? '新增成功' : '编辑成功');

                this.$emit('update:visible', false);

                this.$emit('success', {
                  handle: this.handle,
                  data: data
                })
              }

              this.confirmLoading = false;
            }).catch(() => {
              this.confirmLoading = false;
            })

          }
        });
      },

      handleCancel() {

      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
