<template>
  <main-structure class="ng-account-members" dir="ver">
    <template slot="header">
      <span class="title">角色管理</span>

      <div class="header-content">
        <a-button
          v-if="$root.$checkAuth($auth[name] && $auth[name].add)"
          type="sub"
          @click="handleFunc($var.Handle.add)"
        >新增角色</a-button>
      </div>
    </template>

    <template>
      <div class="m-top-ms flex-0">
        <ng-breadcrumb :data="breadData"></ng-breadcrumb>
      </div>

      <div class="m-top-base flex-1" ref="ngTableBox">
        <ng-table
          v-if="$root.$checkAuth($auth[name] && $auth[name].list)"
          class="ng-table"
          :columns="columns"
          :rowKey="row => row.role_id"
          :scroll="{y: mainContentScrollMaxHeight}"
          :params="param"
          :request="$axios.Account_roles_list"
          :load.sync="loadTable"
          :reload.sync="reloadTable"
        >
          <template slot="action_name" slot-scope="text">
            <span>{{text.join('、')}}</span>
          </template>

          <template slot="status" slot-scope="text, scope">
            <table-switch
              v-if="$root.$checkAuth($auth[name] && $auth[name].status)"
              :options="memberStatusOptions"
              :default-value="parseInt(text)"
              :change="function() { onChangeSwitch(...arguments, scope) }"
            ></table-switch>
            <span
              v-else
              :class="text == 0 ? 'font-error' : 'font-success'"
            >{{$root.$getItemWithKey(memberStatusOptions, 'id', text).name || ''}}</span>
          </template>
          <template slot="operation" slot-scope="text, scope">
            <a-button
              v-if="$root.$checkAuth($auth[name] && $auth[name].look)"
              class="m-right-ms"
              type="link"
              @click="handleFunc($var.Handle.look, scope)"
            >查看</a-button>
            <a-button
              v-if="$root.$checkAuth($auth[name] && $auth[name].edit)"
              class="m-right-ms"
              type="link"
              @click="handleFunc($var.Handle.edit, scope)"
            >编辑</a-button>
            <a-button
              v-if="$root.$checkAuth($auth[name] && $auth[name].del)"
              class="ant-btn-error"
              type="link"
              @click="onDelete(scope)"
              :disabled="scope.is_enabled == 1"
            >删除</a-button>
          </template>
        </ng-table>
      </div>
    </template>
  </main-structure>
</template>

<script>
  import {OpenAndCloseStatus} from '@/config/options'

  import {mainContentMixin, tableMixins} from '@/mixins';

  const columns = [
    {
      title: 'ID',
      customRender(text, record, index) {
        return index + 1;
      },
      width: '100px'
    },
    {
      title: '角色名称',
      dataIndex: 'name',
      width: '150px'
    },
    {
      title: '关联权限',
      dataIndex: 'action_name',
      scopedSlots: {customRender: 'action_name'},
    },
    {
      title: '状态',
      dataIndex: 'is_enabled',
      scopedSlots: {customRender: 'status'},
      width: '200px'
    },
    {
      title: '操作',
      scopedSlots: {customRender: 'operation'},
      width: '180px'
    },
  ];

  const breadData = function () {
    return [
      {breadcrumbName: '部门角色管理', path: '/account/department'},
      {breadcrumbName: '角色管理'}
    ]
  }

  export default {
    data() {
      return {
        name: 'account_role',
        columns,
        data: [],
        loading: false,
        pagination: {},
        memberStatusOptions: OpenAndCloseStatus,
        switchLoading: false,
        showAddMembersDialog: false,

        curDeptId: this.$route.params.dept_id || 0,

        handleType: this.$var.Handle.add,
        handleData: {},

        breadData: breadData(),

        key_id: 'role_id', // 列表标识id名称

        param: {},

        loadTable: false,
      }
    },

    mounted() {
      let {page = 1} = this.$route.query;

      this.param = {dept_id: this.curDeptId, page};
    },

    mixins: [
      mainContentMixin, tableMixins
    ],

    methods: {

      handleFunc(key, scope) {
        let path = this.$route.path;
        let role_id = scope ? scope.role_id : 0;

        this.$router.push({path: `${path}/add/${role_id}/${key}`});

        // this.$session.set(this.$var.SESSION_STORAGE.TRANSFER_DATA, scope);
      },

      onDelete(scope) {
        let vm = this;

        this.$confirm({
          width: '480px',
          title: '确认要删除该角色吗？',
          content: '删除该角色，关联该角色的成员账号，会失去对应的权限；',
          onOk() {
            return new Promise((resolve, reject) => {
              vm.deleteFunc(scope, resolve, reject);
            });
          }
        });
      },

      deleteFunc(scope, next, reject) {
        let key_id = this.key_id;

        this.$axios.Account_roles_del({[key_id]: scope[key_id]}).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('已成功删除该角色');
            next();

            this.loadTableData();
          } else {
            reject()
          }
        })
      },

      onChangeSwitch(key, options, next, loading, scope) {
        loading(true);

        let key_id = this.key_id;

        let param = {
          [key_id]: scope[key_id],
          is_enabled: key
        }

        this.$axios.Account_roles_status(param).then(res => {
          let data = res.data;

          loading(false);

          if (data && data.code == 0) {
            next();

            key == 1 ? this.$message.success('已启用') : this.$message.error('已停用');

            this.loadTableData();
          }
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .ng-account-members {
    /deep/ .ant-input-search {
      width: 360px;
      margin-right: $space-base;
    }
  }
</style>
