<template>
  <div class="detail-main flex flex-column cen-start">
    <div class="user-img">
      <ng-image :src="data.profile_photo_url" :default-value="$var.defaultHeader" />
    </div>
    <div class="detail">
      <div class="flex cen-start m-top-base">
        <span class="left flex cen-end">用户名</span>
        <span class="right">{{data.name}}</span>
      </div>
      <div class="flex cen-start m-top-base">
        <span class="left flex cen-end">性别</span>
        <span class="right">{{data.sex | toSexName}}</span>
      </div>
      <div class="flex cen-start m-top-base">
        <span class="left flex cen-end">所在地</span>
        <span class="right">{{data.address}}</span>
      </div>
    </div>
  </div>
</template>
<script>

  export default {
    data() {
      return {
      }
    },

    props: {
      type: String,
      data: Object,
    },

    created() {
      // this.getData();
    },

    methods: {
      // getData() {
      //   this.$axios.Base_info().then(res => {
      //     const data = res.data;

      //     if (data && data.code == 0) {
      //       this.data = data.data;

      //       this.$emit('success', this.data);
      //     }
      //   })
      // }
    }
  }
</script>
<style lang="scss" scoped>
  .detail-main {
    width: 100%;
    .user-img {
      position: relative;
      border-radius: 50%;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);

      img {
        display: inline-block;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.04);
      }

      span {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 12px;
        font-weight: 300;
        color: rgba(0, 0, 0, 0.65);
        text-align: center;
      }
    }
    .detail {
      width: 100%;
      padding: 0 80px 0 0;
      .left {
        flex: 0 0 136px;
        font-size: 13px;
        font-weight: 300;
        color: rgba(84, 91, 102, 1);
      }
      .right {
        flex: 1;
        margin-left: 20px;
        font-size: 14px;
        font-weight: 300;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
</style>