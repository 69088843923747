<template>
  <a-modal
    class="ant-modal-confirm ng-modal-confirm ant-modal-confirm-confirm no-footer-margin"
    type="confirm"
    width="480px"
    v-model="show"
    :confirm-loading="loading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div class="ant-modal-confirm-body">
      <a-icon type="question-circle" />
      <span class="ant-modal-confirm-title">{{text}}</span>

      <div class="ant-modal-confirm-content">
        <a-form :form="form">
          <a-form-item>
            <ng-textarea
              size="large"
              v-decorator="[inputKey, { rules: [$validate.required], initialValue: '' }]"
              :placeholder="placeholder"
              style="height: 130px"
              :maxLength="maxLength"
              show-counter
            ></ng-textarea>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';

  export default {
    data() {
      return {
        show: false,
        loading: false,
        form: this.$form.createForm(this, {name: 'confirmAndInfo'}),
      }
    },

    props: {
      text: String, // 提示语
      placeholder: String, // 输入框提示语
      inputKey: String, // 输入框提交字段
      params: Object, // 提交参数
      dataFunc: Function, // 提交地址方法
      successTip: String, // 提交成功的提示语
      maxLength: {
        type: Number,
        default: 50
      }
    },

    mixins: [dialogMixins],

    methods: {

      handleOk() {
        this.form.validateFieldsAndScroll((errors, values) => {
          if (!errors) {
            this.loading = true;

            let params = Object.assign(this.params, values);

            typeof this.dataFunc === 'function' && this.dataFunc(params).then(res => {
              const data = res.data;

              if (data && data.code == 0) {
                this.successTip && this.$message.success(this.successTip);
                this.$emit('success', params);
                this.$emit('update:visible', false);
              }

              this.loading = false;
            })
          }
        })
      },
      handleCancel() {
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
