<template>
  <a-form class="ng-form-style ng-filter" :form="form" layout="inline">
    <!-- <a-form-item label="渠道名称">
      <a-select
        :options="OrderChannelOptions"
        v-decorator="['channel', {initialValue: defaultData.channel || ''}]"
        :style="{width: '120px'}"
      ></a-select>
    </a-form-item> -->

    <a-form-item label="下单时间">
      <a-range-picker
        class="w-100"
        valueFormat="YYYY-MM-DD"
        v-decorator="['time', { initialValue: defaultData.time}]"
        :style="{width: '240px'}"
      >
        <a-icon slot="suffixIcon" type="calendar" />
      </a-range-picker>
    </a-form-item>

    <a-form-item label="订单状态">
      <a-select
        :options="OrderStatusOptionsAdmin"
        v-decorator="['status', {initialValue: defaultData.status || ''}]"
        :style="{width: '120px'}"
      ></a-select>
    </a-form-item>
  </a-form>
</template>

<script>
  import {DatePicker} from 'ant-design-vue';

  import {
    OrderChannelOptions,
    AddDefaultToOptions,
    OrderStatusOptionsAdmin
  } from '@/config/options'

  export default {
    data() {
      return {
        OrderChannelOptions: AddDefaultToOptions(OrderChannelOptions, '全部'),
        OrderStatusOptionsAdmin: AddDefaultToOptions(OrderStatusOptionsAdmin, '全部'),
        labelList: [],
      }
    },

    props: {
      type: String,
      defaultData: {
        type: Object,
        default() {
          return {}
        }
      },
    },

    components: {
      ARangePicker: DatePicker.RangePicker,
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        onValuesChange: (props, values) => {
          values = Object.assign(values, {page: 1});

          this.$root.$goSearch(values);

          this.$emit('change', values);
        }
      });
    },

    methods: {
    }
  }
</script>

<style lang="scss" scoped>
  .ng-filter {
  }
</style>
