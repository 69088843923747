<template>
  <div class="detail-main flex flex-column cen-start">
    <template v-if="list && list.length > 0">
      <template v-for="(item, index) in list">
        <ng-card :key="index">
          <div class="flex cen-center detail-content">
            <ng-image :src="PayLogoDir[item.type]" alt class="logo" />

            <div class="flex flex-column">
              <!-- 第三方支付 -->
              <template v-if="item.type != $val.SettleWayValues.CToC">
                <span class="title font-xs">{{item.account || '-'}}</span>
                <span class="sub-title font-xs">{{item.number || '-' | omitAccount}}</span>
              </template>
              <!-- 公对公付款 -->
              <template v-else>
                <span class="title font-xs">{{item.company || '-'}}</span>
                <span class="title font-xs">{{item.bank || '-'}}</span>
                <span class="sub-title font-xs">{{item.number || '-' | omitBank}}</span>
              </template>
            </div>

            <a-tag v-if="item.is_default === '1'" color="cyan">默认</a-tag>
          </div>

          <div v-if="!isLook" class="handle-btns flex" slot="right">
            <a-button
              v-if="item.is_default !== '1' && $root.$checkAuth($auth[authName] && $auth[authName].setDefaultReceipt)"
              type="link"
              @click="onDefault(item)"
            >设为默认收款方式</a-button>
            <a-button
              v-if="$root.$checkAuth($auth[authName] && $auth[authName].editReceipt)"
              type="link"
              @click="handleItem($var.Handle.edit, item)"
            >修改</a-button>
            <a-button
              v-if="$root.$checkAuth($auth[authName] && $auth[authName].delReceipt)"
              type="link"
              class="ant-btn-error"
              @click="handleItem($var.Handle.del, item)"
            >删除</a-button>
          </div>
        </ng-card>
      </template>
    </template>
    <template v-else>
      <div class="empty flex cen-center">暂无收款信息</div>
    </template>
  </div>
</template>
<script>
  import {NgCard} from '@/components/index';
  import {SettleWayValues} from "@/config/options.value";

  const PayLogoDir = {
    [SettleWayValues.Alipay]: require('~/assets/images/Alipay.png'),
    [SettleWayValues.PayPal]: require('~/assets/images/PayPal.png'),
    [SettleWayValues.Payoneer]: require('~/assets/images/Payoneer.png'),
    [SettleWayValues.CToC]: require('~/assets/images/CToC.png'),
  }

  export default {
    data() {
      return {
        PayLogoDir,
      }
    },

    props: {
      list: Array,
      user_id: String,
      isLook: Boolean,
      authName: String
    },

    components: {
      NgCard
    },
    methods: {
      handleItem(type, item) {
        let vm = this;

        if (type === this.$var.Handle.del) {
          this.$confirm({
            width: '480px',
            title: '确认要地址信息吗？',
            content: '删除该地址信息',
            onOk() {
              return new Promise((resolve, reject) => {
                vm.onDel(item, resolve, reject);
              });
            }
          });
        } else if (type === this.$var.Handle.edit) {
          this.$emit('onEdit', item);
        }
      },

      onDefault(item) {
        let vm = this;

        this.$confirm({
          width: '480px',
          title: '确认设置默认地址信息吗？',
          // content: '删除该地址信息',
          onOk() {
            return new Promise((resolve, reject) => {
              vm.setDefault(item, resolve, reject);
            });
          }
        });
      },

      setDefault(item, next, reject) {
        let params = {
          receipt_id: item.receipt_id,
          user_id: this.user_id
        };

        this.$axios.Sup_Cash_setDefaultAccount(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('设置成功');

            next();

            this.$emit('success', data.data);
          } else {
            reject()
          }
        })
      },

      onDel(item, next, reject) {
        let params = {
          receipt_id: item.receipt_id
        };

        this.$axios.Sup_Cash_delAccount(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('删除成功');

            next();

            this.$emit('success', data.data);
          } else {
            reject()
          }
        })
      },
    }
  }
</script>
<style lang="scss" scoped>
  .logo {
    height: 30px;
    margin: 20px 20px 20px 0;
  }

  .detail-main {
    .title,
    .sub-title {
      margin-right: 20px;
    }
  }
</style>