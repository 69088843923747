<template>
  <main-structure class="ng-mark-music" dir="ver">
    <template slot="header">
      <span class="title">标注任务管理</span>
    </template>

    <template v-if="!isSuccess">
      <div class="m-top-ms flex-0">
        <ng-breadcrumb :data="breadData"></ng-breadcrumb>
      </div>

      <mark-music-gather
        :task_id="$route.params.task_id"
        :data="musicData"
        :init-selected="initTags"
        @success="onSuccess"
        :is-edit="isEdit"
      ></mark-music-gather>

      <a-spin :spinning="spinning"></a-spin>
    </template>
    <template v-else>
      <marked-success :task_id="$route.params.task_id" :type="music_type" :data="markSuccessInfo"></marked-success>
    </template>
  </main-structure>
</template>

<script>
  import MarkMusicGather from './components/MarkMusicGather.vue';
  import MarkedSuccess from './components/MarkedSuccess.vue'
  import {Common} from '@/utils/variables'

  // 根据跳转的原页面判断面包屑路由路径
  const pathDir = (query) => {
    let dir = {
      [Common.first]: {
        name: '标注任务',
        path: '/librarymark/task/serving', // 标注任务 服务中
      },
      [Common.second]: {
        name: '标注音乐管理',
        path: `/librarymark/musicmanage/${query.previd}?from=${query.prevfrom}`, // 标注音乐管理
      },
      // [Common.third]: {
      //   name: '标注音乐管理 - 查看进度',
      //   path: `/librarymark/manage/serving`, // 标注任务管理 查看进度
      // },
      [Common.fourth]: {
        name: '音乐管理',
        path: `/musicmanage/${query.mtype || 'bgm'}`, // 标注任务管理 查看进度
      },
    }

    return dir[query.from] || dir[Common.first];
  }

  const breadData = function (query) {
    let pdir = pathDir(query);

    return [
      {breadcrumbName: pdir.name, path: pdir.path},
      {breadcrumbName: '任务详情'}
    ]
  }

  export default {
    provide() {
      return {
        authName: this.name
      }
    },

    data() {
      return {
        name: `markMusic`,
        breadData: breadData(this.$route.query),
        isSuccess: false,
        music_type: '',
        musicData: {},
        spinning: true,
        initTags: [],
        markSuccessInfo: {},
        isEmpty: false,
      }
    },

    computed: {
      // 驳回任务
      isReject() {
        return this.$route.params.task_id === this.$var.Handle.reject
      },

      // 即时任务
      isImme() {
        return this.$route.params.task_id === this.$var.Handle.imme
      },

      // 音乐管理
      isMusicManage() {
        return this.$route.params.task_id === 'musicmanage';
      },

      isEdit() {
        // 音乐管理传入 music_id
        // 其他传入 log_id
        return !!this.$route.query.log_id || (this.isMusicManage && !!this.$route.query.music_id)
      },

      request() {
        if (this.isReject) return this.$axios.MarkMusic_tagInfo_reject;
        else if (this.isImme) return this.$axios.MarkMusic_tagInfo_imme;
        else if (this.isMusicManage) return this.$axios.MusicManage_musicInfo;
        else if (this.isEdit) return this.$axios.MarkMusic_tagInfoAndTags;
        else return this.$axios.MarkMusic_tagInfo;
      },

      requestParams() {
        if (this.isReject || this.isImme) return {};
        else if (this.isMusicManage) return {music_id: this.$route.query.music_id};
        else if (this.isEdit) return {log_id: this.$route.query.log_id};
        else return {task_id: this.$route.params.task_id};
      }
    },

    components: {
      MarkMusicGather, MarkedSuccess
    },

    mounted() {
      this.onSuccess();
    },

    methods: {
      onSuccess(data) {
        // 本批次标注完成
        if (data && data.data && data.data.task_info && data.data.task_info.task_id) {
          this.markSuccessInfo = data.data.task_info;
          this.isSuccess = true;
          return false;
        }

        let params = this.requestParams;

        this.getData(params);
      },

      getData(params) {
        this.spinning = true;

        return new Promise(() => {

          if (typeof this.request !== 'function') return;

          this.request(params).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              let {music_info, checked_labels = []} = data.data;
              this.musicData = data.data;
              this.music_type = music_info.type;

              if (!checked_labels || checked_labels.length === 0) {
                // this.$message.warning('没有音乐信息');
                this.isEmpty = true;
              }

              if (this.isEdit || this.isImme) {
                this.initTags = checked_labels;
              }
            }

            this.spinning = false;
          })
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
