<template>
  <div>
    <a-spin class="w-100" :spinning="spinning">
      <div class="detail-main flex flex-column cen-start">
        <user-phone :data="info" @success="onSuccess"></user-phone>
        <user-email :data="info" @success="onSuccess"></user-email>
        <user-pwd :data="info" @success="onSuccess"></user-pwd>
      </div>
    </a-spin>

    <!-- <dialog-add
      :visible.sync="showHandleDialog"
      @success="onSuccess"
      :default-value="dialogFormDefault"
      :handle="handleType"
    ></dialog-add>-->
  </div>
</template>

<script>
  // import detail from "./components/detail";
  // import DialogAdd from './components/DialogAdd.vue'
  import UserPhone from './components/UserPhone.vue';
  import UserEmail from './components/UserEmail.vue';
  import UserPwd from './components/UserPwd.vue';

  export default {
    data() {
      return {
        title: "账户安全",

        showHandleDialog: false,
        dialogFormDefault: {},
        handleType: 'add',

        info: {},

        spinning: false,
      };
    },

    props: {
      user_id: String,
    },

    components: {
      UserPhone, UserEmail, UserPwd
    },

    created() {
      this.getData({user_id: this.user_id});
    },

    methods: {
      onSuccess() {
        this.getData({user_id: this.user_id});
      },

      getData(params) {
        this.spinning = true;

        this.$axios.User_Account_info(params).then(res => {
          const data = res.data;
          if (data && data.code == 0) {
            this.info = data.data;
          }

          this.spinning = false;
        })
      },
    }
  };
</script>

<style lang="scss" scoped>
  .add-btn {
    cursor: pointer;

    .iconfont {
      width: 20px;
      height: 20px;
      margin-right: 2px;
    }
  }
</style>
