<template>
  <ng-table
    class="ng-table"
    :columns="columns"
    :params="params"
    :rowKey="row => row.invoice_id"
    :request="$axios.BuyManage_receiptList"
    :load.sync="loadTable"
    :reload.sync="reloadTable"
    :scroll="{y: scrollyHeight - 20}"
  >
    <template slot="order_no" slot-scope="text, scope">
      <router-link
        class="word-break order-no"
        :to="`/order/${scope.order_type === $val.orderType.music ? 'channel' : 'vip'}/${scope.order_id || '-1'}?from=${$route.name}`"
      >{{text}}</router-link>
    </template>

    <template slot="total_amount" slot-scope="text">
      <span>￥{{text}}</span>
    </template>

    <template slot="is_operated" slot-scope="text">
      <span>{{text | toApplyStatus}}</span>
    </template>

    <template slot="invoice_type" slot-scope="text">
      <span>{{text | toReceiptType}}</span>
    </template>

    <template slot="title_type" slot-scope="text">
      <span>{{text | toReceiptTitleType}}</span>
    </template>

    <template slot="operation" slot-scope="text, scope">
      <a-button
        v-if="$root.$checkAuth($auth[authName] && $auth[authName].look)"
        class="m-right-ms"
        type="link"
        @click="handleFunc($var.Handle.look, scope)"
      >查看</a-button>

      <a-button
        v-if="$root.$checkAuth($auth[authName] && $auth[authName].edit)"
        class="m-right-ms"
        type="link"
        @click="handleFunc($var.Handle.edit, scope)"
      >编辑</a-button>

      <a-button
        v-if="$root.$checkAuth($auth[authName] && $auth[authName].operation)"
        type="link"
        :disabled="scope.is_operated == '1'"
        @click="onOperate(scope)"
      >开票</a-button>
    </template>
  </ng-table>
</template>

<script>
  import {tableMixins} from '@/mixins';

  import {
    ApplyOptions,
    ReceiptHeaderTypeOptions,
    ReceiptTypeOptions
  } from '@/config/options';
  import {toOptionName} from '@/utils/function';

  import * as TabCol from '@/config/columns';

  const columns = [
    TabCol.colId,
    TabCol.commonKey('订单号', 'order_no', {width: '200px', isSlot: true}),
    TabCol.commonKey('申请时间', 'create_at'),
    TabCol.commonKey('申请状态', 'is_operated', null, true),
    TabCol.commonKey('发票类型', 'invoice_type', null, true),
    TabCol.commonKey('抬头类型', 'title_type', null, true),
    TabCol.commonKey('发票金额', 'total_amount', null, true),
    TabCol.operation('160px'),
  ];

  export default {
    data() {
      return {
        columns,
        params: {},
      }
    },

    props: {
      authName: String,
      scrollyHeight: {
        type: Number,
        default: 400
      },
      reload: Boolean,
      tableParams: Object,
    },

    watch: {
      tableParams(newVal) {
        this.$nextTick(() => {
          this.params = Object.assign({}, this.params, newVal || {});
        })
      }
    },

    mounted() {
      // let {range = [], page = 1, channel, status} = this.$route.query;
      // let order_id = this.order_id;

      // let params = {channel, page, status, }

      // params['time[start]'] = range[0] || '';
      // params['time[end]'] = range[1] || '';

      // order_id && (params.order_id = order_id || 0)

      // this.params = params;
    },

    filters: {
      toApplyStatus(value) {
        return toOptionName(ApplyOptions, value);
      },

      toReceiptType(value) {
        return toOptionName(ReceiptTypeOptions, value);
      },

      toReceiptTitleType(value) {
        return toOptionName(ReceiptHeaderTypeOptions, value);
      },
    },

    mixins: [
      tableMixins
    ],

    methods: {
      handleFunc(key, scope) {
        this.$emit('handle', Object.assign(scope, {
          handleName: key
        }))
      },

      onDownload() {

      },

      onOperate(scope) {
        let vm = this;

        this.$confirm({
          width: '480px',
          title: '确认该发票已成功开出？',
          onOk() {
            return new Promise((resolve, reject) => {
              vm.handleFinish(scope, resolve, reject);
            });
          }
        });
      },

      handleFinish(scope, next, reject) {
        this.$axios.BuyManage_receiptOperate({invoice_id: scope.invoice_id}).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('已处理完成');
            next();

            setTimeout(() => {
              this.loadTableData();
            }, 500);
          } else {
            reject();
          }
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .ant-table .operation {
    white-space: nowrap;
  }
</style>
