import { render, staticRenderFns } from "./SongListDetailBaseInfo.vue?vue&type=template&id=53a941a8&scoped=true&"
import script from "./SongListDetailBaseInfo.vue?vue&type=script&lang=js&"
export * from "./SongListDetailBaseInfo.vue?vue&type=script&lang=js&"
import style0 from "./SongListDetailBaseInfo.vue?vue&type=style&index=0&id=53a941a8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53a941a8",
  null
  
)

export default component.exports