<template>
  <main-structure class="ng-userinfo ng-form-label" dir="ver" :loading="loading">
    <template slot="header">
      <span class="title">修改个人资料</span>
    </template>

    <template>
      <div class="info-content">
        <a-form :form="form" :label-col="{ span: 2 }" :wrapper-col="{ span: 15 }">
          <h4 class="content-title">基础信息</h4>

          <!-- 姓名 -->
          <a-form-item label="姓名">
            <a-input
              size="large"
              v-decorator="['name', { rules: [$validate.required], trigger: 'blur' }]"
              placeholder="请输入姓名"
              :disabled="allDisabled"
            />
          </a-form-item>
          <!-- 邮箱 -->
          <a-form-item label="邮箱">
            <a-input
              size="large"
              type="email"
              v-decorator="['email', { rules: [$validate.required, $validate.email], trigger: 'blur'}]"
              placeholder="请输入成员邮箱"
              :disabled="allDisabled"
            />
          </a-form-item>
          <a-form-item label="电话" required style="margin-bottom:0;">
            <a-form-item style="display: inline-block; width: 30%; margin-right: 20px">
              <form-phone-area
                size="large"
                v-decorator="['phone_code', {initialValue: '1'}]"
                :disabled="allDisabled"
              ></form-phone-area>
            </a-form-item>

            <a-form-item :style="{display: 'inline-block', width: 'calc(70% - 20px)'}">
              <a-input
                size="large"
                type="number"
                v-decorator="['phone', { rules: [$validate.required, $validate.phone], trigger: 'blur' }]"
                placeholder="请输入手机号"
                :disabled="allDisabled"
              />
            </a-form-item>
          </a-form-item>

          <h4 class="content-title">职位信息</h4>

          <!-- 部门 -->
          <a-form-item label="部门">
            <form-department
              size="large"
              changeOnSelect
              expandTrigger="hover"
              v-decorator="['dept_id', { rules: [$validate.required] }]"
              placeholder="请选择部门"
              :disabled="true"
            ></form-department>
          </a-form-item>

          <!-- 职位 -->
          <a-form-item label="职位">
            <a-input
              size="large"
              v-decorator="['job_title', { rules: [$validate.required], trigger: 'blur' }]"
              placeholder="请输入成员职位"
              :disabled="allDisabled"
            />
          </a-form-item>

          <!-- 驻地 -->
          <a-form-item label="驻地">
            <a-input
              size="large"
              v-decorator="['station', { rules: [$validate.required], initialValue: '北京', trigger: 'blur' }]"
              placeholder="请输入成员工作驻地"
              :disabled="allDisabled"
            />
          </a-form-item>

          <a-form-item label="角色">
            <form-select
              size="large"
              v-decorator="['role_id', { rules: [$validate.required] }]"
              placeholder="请选择成员角色"
              valueName="role_id"
              :dataFunc="$axios.AllOpenRoles"
              :disabled="true"
              :all="false"
            ></form-select>
          </a-form-item>
        </a-form>

        <div v-if="!isEdit" class="float-r m-top-base">
          <a-button type="sub" size="large" class="w-px-100" @click="goEdit">编辑</a-button>
        </div>
        <div v-else class="float-r m-top-base">
          <a-button size="large" class="w-px-100 m-right-ms" @click="handleCancel">取消</a-button>
          <a-button type="sub" size="large" class="w-px-100" @click="saveEditInfo">保存</a-button>
        </div>
      </div>
    </template>
  </main-structure>
</template>

<script>
  import {FormPhoneArea} from '@/components/index';
  import {FormDepartment} from '@/components/index';

  export default {
    data() {
      return {
        loading: false,
        form: this.$form.createForm(this, {name: 'members'}),
        allDisabled: true,

        isEdit: false,
      }
    },
    components: {
      FormPhoneArea, FormDepartment
    },

    mounted() {

      this.initFields();
    },

    mixins: [

    ],

    methods: {
      initFields() {

        this.$axios.UserInfo().then(res => {
          const data = res.data;

          if (data && data.code == 0) {

            let userinfo = data.data;

            userinfo.phone_code = userinfo.phone_code ? userinfo.phone_code.id : 1;

            this.form.setFieldsValue(userinfo);
          }
        })
      },

      goEdit() {
        this.isEdit = true;
        this.allDisabled = false;
      },

      handleCancel() {
        this.isEdit = false;
        this.allDisabled = true;
      },

      saveEditInfo() {
        this.form.validateFieldsAndScroll((errors, values) => {
          if (!errors) {
            this.loading = true;

            this.$axios.ChangeUserinfo(values).then(res => {
              const data = res.data;

              if (data && data.code == 0) {
                this.$message.success('修改成功');
                this.handleCancel();
                this.$store.dispatch('getUserInfo', true);
              }

              this.loading = false;
            })
          }
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .ng-userinfo {
    .content-title {
      font-weight: $font-weight-bold;
      margin: 20px 0 30px;
    }

    .info-content {
      padding: 0 50px;
    }

    /deep/ .ant-form-item-label {
      width: 100px;
    }

    /deep/ .ant-form-item-control-wrapper {
      max-width: 500px;
    }
  }
</style>
