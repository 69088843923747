<template>
  <div>
    <a-form class="ng-form-style ng-filter" :form="form" layout="inline">
      <a-form-item label="认领时间：" style="margin-left: 20px">
        <a-select
          :options="SortOptions"
          :style="{ width: '120px' }"
          v-decorator="['sort']"
          @change="onChangeSort"
        ></a-select>
      </a-form-item>
      <span style="float: right">
        <a-badge :count="tipInfo.reject_num" class="m-right-base">
          <a-button
            v-if="
              $root.$checkAuth($auth[authName] && $auth[authName].markReject)
            "
            class="ant-btn-self"
            size="large"
            type="warning"
            @click="goTask($var.Handle.reject)"
            :class="{ 'ant-btn-disabled': !tipInfo.reject_num }"
            >驳回待处理</a-button
          >
        </a-badge>
        <a-badge :count="tipInfo.imme_num">
          <a-button
            v-if="
              $root.$checkAuth($auth[authName] && $auth[authName].markInstance)
            "
            class="ant-btn-self"
            size="large"
            type="primary"
            @click="goTask($var.Handle.imme)"
            :class="{ 'ant-btn-disabled': !tipInfo.imme_num }"
            >即时任务</a-button
          >
        </a-badge>
      </span>
    </a-form>
    <div class="m-bottom-base text-right"></div>

    <ng-table
      class="ng-table"
      :columns="columns"
      :params="params"
      :rowKey="(row) => row.task_id"
      :request="$axios.MarklibraryTask_servingList"
      :load.sync="loadTable"
      :reload.sync="reloadTable"
      :scroll="{ y: scrollyHeight - 20 }"
      @dataChange="onDataChange"
    >
      <!-- 头像 -->
      <template slot="lib_logo_url" slot-scope="text, scope">
        <ng-image
          class="table-header"
          :src="
            scope.lib_logo_url
              ? `${scope.lib_logo_url}?x-oss-process=style/thumb_10`
              : $var.defaultHeader
          "
          @error="({ target }) => (target.src = $var.defaultHeader)"
        />
      </template>

      <template slot="priority" slot-scope="text">
        <span v-html="$options.filters.toLevelName(text)"></span>
      </template>

      <template slot="current_progress" slot-scope="text, scope">
        <span>{{ scope.done_music_num }} / {{ scope.music_num }}</span>
      </template>

      <template slot="operation" slot-scope="text, scope">
        <router-link
          v-if="$root.$checkAuth($auth[authName] && $auth[authName].info)"
          class="m-right-ms"
          :to="`/markmusic/${scope.task_id}?from=${$var.Common.first}`"
          :disabled="!scope.op_tag"
          >进入任务</router-link
        >

        <a-button
          v-if="$root.$checkAuth($auth[authName] && $auth[authName].deliver)"
          class="m-right-ms"
          type="link"
          @click="goDeliever(scope)"
          :disabled="!scope.op_deliver"
          >交付</a-button
        >
      </template>
    </ng-table>
  </div>
</template>

<script>
import { Badge as ABadge } from "ant-design-vue";
import { tableMixins } from "@/mixins";

import * as TabCol from "@/config/columns";
import { AddDefaultToOptions, SortOptions } from "@/config/options";
const columns = [
  TabCol.colId,
  TabCol.portrait("封面", "lib_logo_url"),
  TabCol.commonKey("曲库名称", "lib_name", { ellipsis: true }),

  TabCol.commonKey("认领时间", "claim_time"),
  TabCol.commonKey("优先级", "priority", "", true),
  TabCol.commonKey("当前进度", "current_progress", "", true),

  TabCol.operation("140px"),
];

export default {
  data() {
    return {
      form: this.$form.createForm(this, "nodify"),
      columns,
      tableList: [],
      loading: false,
      // params: {},
      tipCount: 1,

      tipInfo: {},

      currentData: {},

      showProgressDialog: false,
      SortOptions: AddDefaultToOptions(SortOptions),
      sort: "",
    };
  },

  props: {
    authName: String,
    scrollyHeight: {
      type: Number,
      default: 400,
    },
    params: Object,
  },

  mounted() {
    // this.params = {page: this.$route.query.page || 1};
    this.$nextTick(() => {
      this.form.setFieldsValue({ sort: this.sort });
    });
  },

  mixins: [tableMixins],

  components: {
    ABadge,
  },

  methods: {
    goDeliever(scope) {
      let vm = this;

      this.$confirm({
        width: "480px",
        title: "确认要交付这个任务吗？",
        content: "任务交付后不可撤回，请谨慎操作！",
        onOk() {
          return new Promise((resolve, reject) => {
            vm.deliever(scope, resolve, reject);
          });
        },
      });
    },

    goTask(key) {
      this.$router.push(`/markmusic/${key}`);
    },

    deliever(scope, next, reject) {
      this.$axios
        .MarklibraryTask_pushTask({ task_id: scope.task_id })
        .then((res) => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success("交付成功");
            next();

            setTimeout(() => {
              this.loadTableData();
            }, 500);
          } else {
            reject();
          }
        });
    },

    onDataChange({ data }) {
      if (data) {
        let { inst_num, undoed_num } = data;
        this.tipInfo = { imme_num: inst_num, reject_num: undoed_num };
      }
    },
    onChangeSort(value) {
      this.sort = value;
      // this.params = Object.assign({}, this.params, {tsort:this.sort});
      // this.loadTableData();
      this.$emit("changesort", value);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .ant-table td {
  white-space: nowrap;
}
</style>
