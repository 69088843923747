import {
  ServeType
} from '@/utils/variables'

const LibraryMarkServeManage = () => import('@/pages/markManage/MarkManage.vue');
const LibraryMarkServeTask = () => import('@/pages/markTask/MarkTaskManage.vue');
const MarkMusic = () => import('@/pages/markMusic/MarkMusic.vue');

export default [

  // 标注管理 start
  {
    path: '/librarymark/manage/noserve',
    name: 'libraryMarkManageUndo',
    meta: {
      type: ServeType.UNDO
    },
    component: LibraryMarkServeManage,
  },
  {
    path: '/librarymark/manage/serving',
    name: 'libraryMarkManageDoing',
    meta: {
      type: ServeType.DOING
    },
    component: LibraryMarkServeManage,
  },
  {
    path: '/librarymark/manage/servedone',
    name: 'libraryMarkManageDone',
    meta: {
      type: ServeType.DONE
    },
    component: LibraryMarkServeManage,
  },
  // 标注管理 end

  // 标注任务 start
  {
    path: '/librarymark/task/noserve',
    name: 'libraryMarkTaskUndo',
    meta: {
      type: ServeType.UNDO
    },
    component: LibraryMarkServeTask,
  },
  {
    path: '/librarymark/task/serving',
    name: 'libraryMarkTaskDoing',
    meta: {
      type: ServeType.DOING
    },
    component: LibraryMarkServeTask,
  },
  {
    path: '/librarymark/task/servedone',
    name: 'libraryMarkTaskDone',
    meta: {
      type: ServeType.DONE
    },
    component: LibraryMarkServeTask,
  },
  // 标注音乐 ?log_id?from
  {
    path: '/markmusic/:task_id', // log_id 标注id 
    name: 'markmusic',
    component: MarkMusic,
  },
]