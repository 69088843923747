<template>
  <a-form class="ng-form-style ng-filter" :form="form" layout="inline">
    <a-form-item label="注册时间">
      <a-range-picker
        v-decorator="['reg_time', {initialValue: defaultData.reg_time}]"
        valueFormat="YYYY-MM-DD"
        style="width: 250px;"
      ></a-range-picker>
    </a-form-item>

    <a-form-item label="最近登录">
      <a-range-picker
        v-decorator="['login_time', {initialValue: defaultData.login_time}]"
        valueFormat="YYYY-MM-DD"
        style="width: 250px;"
      ></a-range-picker>
    </a-form-item>

    <a-form-item label="所在地">
      <form-country
        all
        placeholder="请选择所在地"
        v-decorator="['address_id', {initialValue: defaultData.address_id}]"
      ></form-country>
    </a-form-item>
    <slot name="extra"></slot>
  </a-form>
</template>

<script>
  import {FormCountry} from '@/components/index';
  import {DatePicker} from 'ant-design-vue';

  export default {
    data() {
      return {
        labelList: [],
      }
    },

    props: {
      type: String,
      defaultData: {
        type: Object,
        default() {
          return {}
        }
      },
    },

    components: {
      FormCountry, ARangePicker: DatePicker.RangePicker
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        onValuesChange: (props, values) => {
          values = Object.assign(values, {page: 1});

          this.$root.$goSearch(values);

          this.$emit('change', values);
        }
      });
    },

    methods: {
      // setDefaultValue(list, ids, index) {
      //   let value = ids;

      //   if (typeof ids === 'string' && ~ids.indexOf(SEPERATE_SIGN)) {
      //     value = value.split(SEPERATE_SIGN)[index] || '';
      //   }

      //   let final = getAllValueAccrodingFinal(list, value, 'label_id');

      //   return final.length > 0 ? final : [undefined];
      // },
    }
  }
</script>

<style lang="scss" scoped>
</style>
