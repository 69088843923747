<template>
  <a-modal
    width="700px"
    v-model="show"
    :title="modalTitle"
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    destroyOnClose
    @ok="handleOk"
    @cancel="handleCancel"
    :bodyStyle="{
      maxHeight: `${dialogBodyMaxHeight ? dialogBodyMaxHeight : '500'}px`,
      overflow: 'auto',
    }"
  >
    <a-form class="w-100 ng-form" :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }">
      <a-form-item  label="优惠券名称">
         <ng-input
            show-counter
            size="large"
            :disabled='true'
            v-decorator="['name', { rules: [$validate.required], initialValue: sendCouponData.name, validateTrigger: 'blur' }]"
          ></ng-input>
      </a-form-item>
        <a-form-item  label="发送对象">
        <a-radio-group 
          v-decorator="['send_type', { rules: [$validate.required], initialValue: '1', validateTrigger: 'blur' }]"
         :options="typeOptions"  @change="typeChange">
        </a-radio-group>
      </a-form-item>
      <a-form-item  v-if="form.getFieldValue('send_type') != '2'" label="用户ID">
           <ng-input
            placeholder=""
            show-counter
            size="large"
            v-decorator="['user_id', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur' }]"
          ></ng-input>
      </a-form-item>
      <a-form-item  v-if="form.getFieldValue('send_type') == '2'"   label="上传用户">
            <a-upload
              :multiple="false"
              :file-list="fileList"
              :remove="handleRemove"
              :before-upload="beforeUpload"
              v-decorator="['file', { rules: [$validate.required] }]"
            >
              <a-button> <a-icon type="upload" /> 上传文件 </a-button>
            </a-upload>
           <div class="description" >

            <span>请</span>&nbsp;
            <a :href="$config.SubmitUserTemplateUrl" download>
              下载用户信息模板
            </a>
            <span>，规范填写用户信息</span>
          </div>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import {Upload as AUpload} from 'ant-design-vue';

  const typeOptions = [
  { label: '单个用户', value: '1' },
  { label: '部分用户', value: '2' },
];
  export default {
    data() {
      return {
        typeOptions,
        form: this.$form.createForm(this, {
        name:'',
        send_type:'1',
        user_id:'',
        file:''
        }),
        show: false,
        confirmLoading: false,
        loading: false,
        fileList:[],
        successMessage:'发送成功'
      }
    },

    props: ['sendCouponData']
    ,

    computed: {
      modalTitle() {
        return  '发送优惠券'
      },
    },

    watch: {
       show(newVal) {
        if (newVal) {
          this.fileList = []
        }
      }
    },

    created() {
    },

    mixins: [dialogMixins],

    components: {
     AUpload
    },

    methods: {
      beforeUpload(file) {
        this.fileList = [file];
        return false;
      },

      handleRemove() {
        this.fileList = [];
        this.form.setFieldsValue({file: {}});
      },
      typeChange(){
        this.fileList = [];
        
      },

      handleOk() {
        const _this = this;
        this.form.validateFieldsAndScroll((errors, values) => {
          if (!errors) {
            values.coupon_id =this.sendCouponData.coupon_id
            if(values.send_type == '2'){
              let formData = new FormData();
              formData.append('file', values.file.fileList[0].originFileObj);
              formData.append('coupon_id', values.coupon_id);
              formData.append('send_type', values.send_type);
              this.submitLibrary(formData);
            }else{
              this.submitLibrary(values);
            }
            

            }

    
          
        })
      },


      submitLibrary(params) {
        let method = this.$axios.SendCoupon;
        this.confirmLoading = true;
          console.log('params: ', params);
        method(params).then(res => {
          const data = res.data;
          if (data && data.code == 0) {
            this.$message.success(this.successMessage);
            this.$emit('success', data);
          }
          this.show = false;
          this.confirmLoading = false;
        }).catch(() => {
          this.confirmLoading = false;
        })
      },

      handleCancel() {
        this.show = false;
      },

   
    }
  }
</script>

<style lang="scss" scoped>
  .upload-box {
    display: inline;
    width: auto;

    /deep/ {
      .ant-upload {
        width: 80px;
        height: 80px;
        margin-bottom: 0;
        padding: 0;
        background-color: #f7f4f8;
      }
    }
  }
</style>
