<template>
  <main-structure class="ng-library" dir="ver">
    <template slot="header">
      <span class="title">歌单管理</span>

      <div class="header-content header-search-content">
        <a-input-search
          class="ng-input-search"
          placeholder="请输入歌单名称以检索"
          :default-value="$route.query.name || ''"
          @search="onSearch"
          allowClear
        >
          <a-button slot="enterButton" type="sub">
            <a-icon type="search" />
          </a-button>
        </a-input-search>

        <a-button
          v-if="$root.$checkAuth($auth[name] && $auth[name].add)"
          type="sub"
          @click="handleFunc($var.Handle.add)"
        >新增歌单</a-button>
      </div>
    </template>

    <template>
      <filter-songs @change="onFilterChange" :defaultData="$route.query" class="m-top-base"></filter-songs>

      <div class="m-top-base flex-1" ref="ngTableBox">
        <manage-table
          v-if="$root.$checkAuth($auth[name] && $auth[name].list)"
          :scrolly-height="mainContentScrollMaxHeight"
          :auth-name="name"
          :table-params="tableParams"
          @handle="onTableHandleSuccess"
          ref="ngTable"
        ></manage-table>
      </div>
    </template>

    <song-list-add
      :visible.sync="showHandleDialog"
      :handle="handleType"
      :default-value="handleData"
      :user_id="$route.params.user_id"
      @success="handleSuccess"
    ></song-list-add>
  </main-structure>
</template>

<script>
  import ManageTable from './components/ManageTable.vue';
  import FilterSongs from './components/FilterSongs.vue'

  import {mainContentMixin} from '@/mixins';

  export default {
    data() {
      return {
        name: `song_list`,

        showHandleDialog: false,
        showCoopHandleDialog: false,

        handleType: this.$var.Handle.add,

        handleData: {},

        isReloadTable: false,

        tableParams: {},
      }
    },

    components: {
      ManageTable,
      FilterSongs,
      SongListAdd: () => import('./components/SongListAdd.vue'),
    },

    mixins: [
      mainContentMixin
    ],

    methods: {
      onFilterChange(values) {
        let params = Object.assign({}, this.tableParams, values);

        let sale_status = params.sale_status;

        params.sale_status = sale_status ? [sale_status] : [];

        this.tableParams = params;
      },

      onSearch(name) {
        typeof name === 'string' && (name = name.trim());

        let search = {name, page: 1};

        this.$root.$goSearch(search);

        this.tableParams = search;
      },

      handleSuccess(isLoad) {
        this.showHandleDialog = false;
        this.showCoopHandleDialog = false;
        isLoad !== false ? this.$refs.ngTable.reloadTableData() : this.$refs.ngTable.loadTableData();
      },

      onTableHandleSuccess(scope) {
        let handle = scope.handleName;
        let varHandle = this.$var.Handle;

        if (handle === varHandle.edit) {
          this.handleFunc(handle, scope);
        }
      },

      handleFunc(key, scope) {
        this.handleData = scope || {};
        this.handleType = key;
        this.showHandleDialog = true;
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
