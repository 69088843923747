/**
 * 账号权限管理
 */
import {
  Axios
} from '@/utils/from-common';

// 曲库列表
export const Library_list = (param) => Axios('POST', '/bms/musiclib/list', param);

// 曲库管理 曲库列表
export const LibraryManage_list = (param) => Axios('POST', '/bms/musiclib/allList', param);

// 新增曲库
export const Library_add = (param) => Axios('POST', '/bms/musiclib/add', param);

// 曲库上下架
export const Library_startSale = (param) => Axios('POST', '/bms/musiclib/upSale', param);

// 曲库基本信息
export const Library_info = (param) => Axios('POST', '/bms/musiclib/baseInfo', param);

// 曲库音乐列表
export const Library_musicList = (param) => Axios('POST', '/bms/musiclib/musicList', param);

// 修改曲库基本信息
export const Library_edit = (param) => Axios('POST', '/bms/musiclib/saveInfo', param);

// // 曲库签约
// export const Library_sign = (param) => Axios('POST', '/bms/musiclib/sign', param);

// // 曲库签约详情
// export const Library_signInfo = (param) => Axios('POST', '/bms/musiclib/signInfo', param);

// 供应商可用合同列表
export const Library_ContractList = (param) => Axios('POST', '/bms/contract/selList', param);

// 供应商合约自动续约
export const Library_AuthSignContract = (param) => Axios('POST', '/bms/contract/autoRenew', param);


/* ***************  批次管理  ************** */
// 批次列表
export const Batch_list = (param) => Axios('POST', '/bms/batch/list', param);

// 批次列表
export const Batch_add = (param) => Axios('POST', '/bms/batch/add', param);

// 发起入库
export const Batch_store = (param) => Axios('POST', '/bms/batch/launchStore', param);