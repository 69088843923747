<template>
  <main-structure
    class="ng-account-role-add ng-form-label"
    dir="ver"
    :loading="$store.state.contentLoading"
  >
    <template slot="header">
      <div class="m-top-ms flex-0">
        <ng-breadcrumb :data="breadData"></ng-breadcrumb>
      </div>
    </template>

    <template>
      <a-form
        class="roles-form flex flex-column"
        :form="form"
        :label-col="{ xs: {span: 6}, sm: {span: 5}, md: {span: 4}, lg: {span: 3}, xl: {span: 2} }"
        :wrapper-col="{ xs: {span: 18}, sm: {span: 17}, md: {span: 16}, lg: {span: 15}, xl: {span: 14} }"
      >
        <a-form-item label="角色名称">
          <ng-input
            size="large"
            v-decorator="['name', { rules: [$validate.required, $validate.letter_zh_number,], initialValue: defaultValue.name}]"
            placeholder="请输入角色名称"
            :disabled="allDisabled"
            :maxLength="10"
            show-counter
          ></ng-input>
        </a-form-item>

        <a-form-item label="所属部门">
          <a-input size="large" :value="params.dept_name" :disabled="true"></a-input>
          <a-input
            v-if="$route.params.hid === $var.Handle.add"
            type="hidden"
            v-decorator="['dept_id', {initialValue: $route.params.dept_id }]"
          ></a-input>
          <a-input
            v-else-if="$route.params.hid === $var.Handle.edit"
            type="hidden"
            v-decorator="['role_id', {initialValue: $route.params.role_id }]"
          ></a-input>
        </a-form-item>

        <a-form-item label="角色描述">
          <ng-textarea
            size="large"
            v-decorator="['desc', { initialValue: defaultValue.desc || ''}]"
            placeholder="负责曲库、歌单、音乐的标注工作"
            :disabled="allDisabled"
            :maxLength="50"
            show-counter
            style="height: 110px;"
          ></ng-textarea>
        </a-form-item>

        <a-form-item class="flex-1" label="角色权限" required>
          <auth-manage
            v-if="showCheckbox"
            :dept_id="$route.params.dept_id"
            v-decorator="['action_ids', { rules: [$validate.checkRequired], initialValue: defaultValue.action_ids}]"
            :disabled="allDisabled"
          ></auth-manage>
        </a-form-item>

        <a-form-item class="btn-group" label=" ">
          <a-affix :offset-bottom="60">
            <div class="footer-btn-group">
              <a-button size="large" @click="goBack" :disabled="allDisabled">取消</a-button>
              <a-button size="large" type="sub" @click="onSubmit" :disabled="allDisabled">保存并继续新增</a-button>
              <a-button size="large" type="sub" @click="onSubmit('back')" :disabled="allDisabled">保存</a-button>
            </div>
          </a-affix>
        </a-form-item>
      </a-form>
    </template>
  </main-structure>
</template>

<script>
  import AuthManage from "./components/AuthManage";
  import {Handle} from '@/utils/variables'

  import {Affix as AAffix} from 'ant-design-vue'

  const dir = {
    [Handle.add]: '添加角色',
    [Handle.edit]: '编辑角色',
    [Handle.look]: '查看角色',
  }

  const breadData = function (handle) {
    return [
      {breadcrumbName: '部门角色管理', path: '/account/department'},
      {breadcrumbName: '角色管理', path: `/account/roles/${this.$route.params.dept_id}`},
      {breadcrumbName: dir[handle] || dir[Handle.add]}
    ]
  }

  export default {
    data() {
      return {
        loading: false,

        breadData: breadData.call(this, this.$route.params.hid),
        form: this.$form.createForm(this, {}),

        allDisabled: false,

        showCheckbox: false,

        params: {},

        tips: true, // 是否提示

        defaultValue: {},
      }
    },

    created() {
      this.params = this.$route.params;

      if (this.params.hid === this.$var.Handle.look) {
        this.allDisabled = true;
      }

      this.init();
    },

    beforeRouteLeave(to, from, next) {
      if (this.tips && this.params.hid !== this.$var.Handle.look) {
        this.$confirm({
          width: '480px',
          title: '确认放弃编辑？',
          onOk() {
            next();
          }
        });
      } else {
        next();
      }
    },

    components: {
      AuthManage, AAffix
    },

    methods: {

      init() {
        let dept_id = this.params.dept_id;

        this.getDeptList({dept_id}).then(data => {

          this.params = Object.assign({}, this.params, {
            dept_name: data && data.length > 0 ? data.join('-') : '-'
          })
        });

        if (this.$route.params.hid !== this.$var.Handle.add) {
          this.initDefaultValue().then(() => {
            setTimeout(() => {
              this.showCheckbox = true;
            }, 100);
          });
        } else {
          this.showCheckbox = true;
        }
      },

      initDefaultValue() {
        let role_id = this.$route.params.role_id;

        return new Promise((resolve, reject) => {
          this.$axios.Account_roles_info({role_id}).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              let newData = data.data || {};

              newData.action_ids = [...newData.action_ids.split(/[,、]/g)];

              this.defaultValue = newData;
            }

            resolve(data);
          }).catch(reject);
        })
      },

      onSubmit(key) {
        this.form.validateFieldsAndScroll((errors, values) => {
          if (!errors) {
            let method = 'Account_roles_add';

            if (this.$route.params.hid === this.$var.Handle.edit) {
              method = 'Account_roles_edit'
            }

            this.$axios[method](values).then(res => {
              const data = res.data;

              if (data && data.code == 0) {
                this.$message.success('创建成功');

                if (key === 'back') {
                  this.tips = false;
                  this.goBack();
                }
              }
            })
          }
        })
      },

      goBack() {
        this.$router.go(-1);
      },

      getDeptList(params) {
        return new Promise(resolve => {
          this.$axios.GetDeptNames(params).then(res => {
            let data = res.data;

            if (data && data.code == 0) {
              resolve(data.data.dept_names);
            }
          })

        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .ng-account-role-add {
    .roles-form {
      min-height: 100%;
      padding-top: 30px;
      margin-right: $space-base;

      /deep/ .ant-form-item-label {
        min-width: 120px;
      }

      /deep/ .ant-input-affix-wrapper,
      /deep/ .ant-input {
        max-width: 500px;
      }
    }

    .footer-btn-group {
      float: right;

      .ant-btn {
        margin-left: 14px;
        min-width: 120px;
      }
    }

    /deep/ .btn-group {
      label {
        visibility: hidden;
      }
    }
  }
</style>
