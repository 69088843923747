import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';

Vue.use(Vuex);

// state
let state = {
  login: false, // 是否登录

  userInfo: {}, // 用户信息

  authList: [], // 权限列表

  /**
   * 加载中的触发变量，需要设置到需要加载中的位置
   * 
   * 为了处理跨多级组件不方便调用的情况
   */
  contentLoading: false,

  globalVideoPlaying: false, // 全局播放音频
  globalVideoInfo: {}, // 播放音频信息（url，name）
  globalVideoList: [], // 播放音频列表
}

// mutations
let mutations = {
  changeLogin(state, value) {
    state.login = value;
  },

  changeUserInfo(state, value) {
    value && (state.userInfo = value);
  },

  changeAuthList(state, value) {
    value && (state.authList = value);
  },

  changeContentLoading(state, value) {
    state.contentLoading = !!value;
  },

  changeVideoPlaying(state, value) {
    state.globalVideoPlaying = value;
  },

  changeVideoInfo(state, value) {
    state.globalVideoInfo = value;
  },

  changeVideoList(state, value) {
    state.globalVideoList = value;
  }
}

export default new Vuex.Store({
  state,
  actions,
  mutations,
  strict: process.env.NODE_ENV !== 'production',
})