<template>
  <a-modal
    width="700px"
    v-model="show"
    :title="modalTitle"
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    destroyOnClose
    @ok="handleOk"
    @cancel="handleCancel"
    :bodyStyle="{
      maxHeight: `${dialogBodyMaxHeight ? dialogBodyMaxHeight : '500'}px`,
      overflow: 'auto',
    }"
  >
    <a-form class="w-100 ng-form" :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }">
      <!-- 优惠券名称 -->
      <a-form-item label="优惠券名称">
        <ng-input
          placeholder="请输入优惠券名称"
          :maxLength="50"
          show-counter
          size="large"
          v-decorator="['name', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur' }]"
        ></ng-input>
      </a-form-item>
      <a-form-item label="优惠类型">
        <a-radio-group 
          v-decorator="['coupon_type', { rules: [$validate.required], initialValue: '1', validateTrigger: 'blur' }]"
         :options="typeOptions"  @change="typeChange">
        </a-radio-group>
      </a-form-item>
      <a-form-item v-if="form.getFieldValue('coupon_type') == '1'" label="折扣">
           <ng-input
            style="width: 120px"
            placeholder=""
            :maxLength="50"
            show-counter
            size="large"
            suffix="折"
            v-decorator="['discount', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur' }]"
          ></ng-input>
      </a-form-item>
      <a-form-item v-if="form.getFieldValue('coupon_type') == '2'" label="金额">
           <ng-input
            style="width: 120px"
            placeholder=""
            :maxLength="50"
            show-counter
            size="large"
            suffix="元"
            v-decorator="['money', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur' }]"
          ></ng-input>
      </a-form-item>

      <!-- 使用门槛 -->
      <a-form-item label="使用门槛">
        满
        <ng-input
         style="width: 100px"
          show-counter
          size="large"
          v-decorator="['threshold', {rules: [$validate.required],  initialValue: '' }]"
        ></ng-input>
        元可用
        * 填"0"不限制(无门槛)
      </a-form-item>
      <a-form-item label="时间形式">
        <a-radio-group 
          v-decorator="['time_type', { rules: [$validate.required], initialValue: '1', validateTrigger: 'blur' }]"
         :options="TimeTypeOptions"  @change="timeTypeChange">
       
        </a-radio-group>
      </a-form-item>
      
      <a-form-item v-if="form.getFieldValue('time_type') == '1'" label=" " :colon='false'>
         <a-range-picker
            class="w-100"
            valueFormat="YYYY-MM-DD"
            v-decorator="['time', {rules: [$validate.required],  initialValue: []}]"
            :style="{width: '240px'}"
          >
            <a-icon slot="suffixIcon" type="calendar" />
          </a-range-picker>
      </a-form-item>
      
       <a-form-item v-if="form.getFieldValue('time_type') == '2'" label=" " :colon='false'>
          <ng-input
          style="width: 100px"
          show-counter
          size="large"
          v-decorator="['valid_day', {rules: [$validate.required],  initialValue: '' }]"
        ></ng-input>
        天后失效
      </a-form-item>

      <a-form-item label="领取方式">
        <a-radio-group 
          v-decorator="['get_way', { rules: [$validate.required], initialValue: '2', validateTrigger: 'blur' }]"
         :options="getTypeOptions"  @change="getTypeChange">
       
        </a-radio-group>
      </a-form-item>

      <a-form-item label="使用说明">
        <ng-textarea
          placeholder="请输入优惠券使用说明"
          style="height: 106px"
          :maxLength="300"
          show-counter
          size="large"
          v-decorator="['description', {rules: [$validate.required],  initialValue: '' }]"
        ></ng-textarea>
      </a-form-item>
     
    </a-form>
  </a-modal>
</template>

<script>
  // import TagGather from '@/pages/markMusic/markTags/TagGather.vue'
  import {NgTextarea} from '@/components/index';
  import {dialogMixins} from '@/mixins/index';
  import {DatePicker} from 'ant-design-vue';

const typeOptions = [
  { label: '折扣', value: '1' },
  { label: '满减', value: '2' },
];
const TimeTypeOptions = [
  { label: '固定日期', value: '1' },
  { label: '自领取当天起', value: '2' },
];
const getTypeOptions = [
  { label: '后台发送', value: '2' },
  { label: '官网兑换', value: '1' },
  { label: '口令码兑换', value: '3' },
];
  export default {
    data() {
      return {
        
        TimeTypeOptions,
        typeOptions,
        getTypeOptions,
        form: this.$form.createForm(this, {
          name:'',
          coupon_type:'',
          threshold:'',
          time_type:'',
          time:[],
          valid_day:'',
          description:'',
          get_way:''
        }),
        show: false,
        confirmLoading: false,

        defaultData: {},

        uploadParams: {},
        uploadName: 'file',
        uploading: false,

        loading: false,

        tagList: [],
        // selectedList: [],

        profile_photo_url: '',

        labelIndex: 0,
      }
    },

    props: {
      handle: String,
      defaultValue: {
        type: Object,
        default() {
          return {}
        }
      },
      user_id: String,
    },

    computed: {
      isEdit() {
        return this.handle === this.$var.Handle.edit;
      },

      modalTitle() {
        return  '新建优惠券'
      },

      request() {
        return this.isEdit ? this.$axios.Song_edit : this.$axios.Song_add
      },

      successMessage() {
        return this.isEdit ? '修改成功' : '新增成功';
      }
    },

    watch: {
      visible(newVal) {
        if (newVal) {
          this.profile_photo_url = '';
          // this.selectedList = [];
          // this.getSongLabel();
        }
      }
    },

    created() {
      console.log('this.form: ', this.form);
      this.uploadParams = {
        upload_type: 1,
        field_name: this.uploadName
      }
      // console.log('this.form: ', this.form);
      // this.getSongLabel();
    },

    mixins: [dialogMixins],

    components: {
      NgTextarea,  
      ARangePicker: DatePicker.RangePicker,
    },

    methods: {
      timeTypeChange(e){
        console.log('v: ', e.target.value);

      },
      getTypeChange(e){

      },
      typeChange(e){
        console.log('v: ', e.target.value);

      },
      onUploading(uploading) {
        this.uploading = uploading;
      },

      getSongLabel() {
        let params = {};

        this.loading = true;

        params.type = this.$val.MarkTargetVal.songlist;

        this.$axios.GetCommonLabels(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.tagList = data.data ? data.data.list : [];

            this.tagListCopy = [...this.tagList];

            // this.selectedList = this.tagList.map(() => []);

            this.isEdit && this.initSongListInfo(this.tagList);
          }

          this.loading = false;
        })
      },

      initSongListInfo(tagList = []) {
        this.profile_photo_url = this.defaultValue.logo_url;

        let {logo, name, desc, labels} = this.defaultValue;

        if (this.form) {
          this.form.resetFields(['logo', 'name', 'desc']);

          if (this.isEdit) {
            this.form.setFieldsValue({logo, name, desc});

            labels = tagList.map(({cate_id}) => labels.filter(cItem => cate_id && cItem.cate_id === cate_id) || []);

            this.$nextTick(() => {
              // this.$refs.tagGather && this.$refs.tagGather.onInitTags(labels);
            })
          }
        }
      },

      handleOk() {
        this.form.validateFieldsAndScroll((errors, values) => {
          console.log('values: ', values);

          if (!errors) {

            // values.label_ids = [...selectedList].flat().map(item => item.label_id);
            if(values.time_type == '1'){
                values.begin_time = values.time[0]
                values.end_time = values.time[1]
            }
        
            this.submitLibrary(values);
          }
        })
      },

      checkRequest(list, tagList) {
        let typeList = tagList.map(item => item.name)

        if (!list || !list.flat(2).length) {
          this.$message.error('请选择标签');
          return false;
        }

        let res = list.some((item, index) => {
          if (!item || !item.length) {
            this.$message.error(`请标注${typeList[index] || ''}类别`);
            return true;
          }
        })

        if (res) return false;

        return true;
      },

      submitLibrary(params) {
        let method = this.$axios.NewCoupon;
        this.confirmLoading = true;
        method(params).then(res => {
          const data = res.data;
          if (data && data.code == 0) {
            this.$message.success(this.successMessage);
            this.$emit('success', data);
          }

          this.confirmLoading = false;
        }).catch(() => {
          this.confirmLoading = false;
        })
      },

      handleCancel() {
        this.show = false;
      },

    
    }
  }
</script>

<style lang="scss" scoped>
  .upload-box {
    display: inline;
    width: auto;

    /deep/ {
      .ant-upload {
        width: 80px;
        height: 80px;
        margin-bottom: 0;
        padding: 0;
        background-color: #f7f4f8;
      }
    }
  }
</style>
