<template>
  <div class="tag-group">
    <template v-for="(item, index) in data">
      <a-tag
        :class="['tag-ele', currKey === item[keyId] ? 'active' : '']"
        :color="item.selected ? item.color : ''"
        :key="item[`${keyId}_${dataKeyIndexArr[index]}`]"
        @click="onClickTag(item, index)"
        :style="{
          color: item.selected ? darken(item.color) : 'inherit',
          borderColor: item.selected ? darken(item.color) : '',
        }"
      >{{ item.name }}</a-tag>
    </template>
  </div>
</template>

<script>
  import {Tag as ATag} from 'ant-design-vue'

  import {darken} from '@/utils/function'

  const KEYID = 'label_id'; // 数据唯一标识

  export default {
    data() {
      return {
        keyIndex: 1,
        currKey: '',
        keyId: KEYID,
        selectedArr: [], // 选中集合
        dataKeyIndexArr: []
      }
    },

    props: {
      data: {
        type: Array,
        default() {
          return [];
        }
      },
      deep: Number, // 当前标签组的层级
      /**
       * item 当前列表项
       * currGroupIndex 当前点击标签所在的组，对应标签级别（下标）
       * handle 操作方式
       */
      beforeTag: {
        type: Function,
        // eslint-disable-next-line
        default: (item, currGroupIndex, handle) => true
      },
      handleDir: Object,
      parentKey: String,
    },

    watch: {
      data: {
        handler(newVal = []) {
          this.selectedArr = newVal.filter(item => item.selected)
          this.currKey = '';
          newVal && newVal.length > 0 && (this.dataKeyIndexArr = new Array(newVal.length).fill(0))
        },
        immediate: true
      }
    },

    components: {
      ATag
    },

    methods: {
      darken,
      onClickTag(item, index) {
        const lastKey = this.currKey;
        const currKey = item[this.keyId];
        const isSelected = item.selected; // 选中
        const hasChildren = this.hasChildren(this.data, currKey);
        const isActiveAndSelected = currKey && lastKey === currKey && isSelected; // 选中且处于激活状态
        const {CANCEL, SELECTED, ACTIVE} = this.handleDir;

        let handleName = '';

        if (isActiveAndSelected || (isSelected && !hasChildren)) {
          handleName = CANCEL;
        } else if (isSelected && hasChildren) {
          handleName = ACTIVE;
        } else {
          handleName = SELECTED;
        }

        if (!this.beforeTag(item, this.deep, handleName)) return;

        if (handleName === CANCEL) {
          this.currKey = '';
          this.dataKeyIndexArr.splice(index, 1, this.dataKeyIndexArr[index] + 1);
          this.selectedArr = this.selectedArr.splice(currKey.indexOf(this.selectedArr), 1);
        } else {
          this.currKey = currKey;
          handleName === SELECTED && this.selectedArr.push({...item});
        }

        this.$emit('tag', {
          deep: this.deep,
          parentKey: this.parentKey,
          handle: handleName,
          index,
          [this.keyId]: item[this.keyId],
          item,
          isLast: !hasChildren
        })
        // this.$emit('change', this.selectedArr)
      },

      hasChildren(arr, key, childName = 'child') {
        let final = arr.filter(item => item[this.keyId] === key);

        return !!(final && final.length > 0 && final[0][childName] && final[0][childName].length > 0);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .tag-group {
    .tag-ele {
      cursor: pointer;
      margin-top: 10px;
      margin-bottom: 10px;

      &.active {
        box-shadow: 2px 2px 2px #43c408;
        // color: #fff;
      }
    }
  }
</style>
