<template>
  <a-form class="w-100 ng-form" :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }">
    <!-- 合作方式 -->
    <!-- <a-form-item label="合作方式">
      
      <form-coop-way
        size="large"
        placeholder="请选择合作方式"
        v-decorator="['coop_way', { rules: [$validate.required] }]"
      ></form-coop-way>
    </a-form-item>-->

    <!-- 合同编号 -->
    <a-form-item label="合同编号">
      <a-input
        class="w-100"
        placeholder="请输入合同编号"
        v-decorator="['contract_no', { rules: [$validate.required], initialValue: ''}]"
        :disabled="isLook"
      ></a-input>
    </a-form-item>

    <!-- 合同名称 -->
    <a-form-item label="合同名称">
      <a-input
        class="w-100"
        placeholder="请输入合同名称"
        v-decorator="['name', { rules: [$validate.required], initialValue: ''}]"
        :disabled="isLook"
      ></a-input>
    </a-form-item>

    <!-- 合作期限 -->
    <a-form-item label="合作期限">
      <!-- :disabled-date="(current) => current && current < moment().startOf('day')" -->
      <a-range-picker
        class="w-100"
        valueFormat="YYYY-MM-DD"
        v-decorator="['coop_time', { rules: [$validate.required], initialValue: []}]"
        :ranges="getDateRange()"
        :disabled="isLook"
      >
        <a-icon slot="suffixIcon" type="calendar" />
      </a-range-picker>
    </a-form-item>

    <!-- 自动续约 -->
    <a-form-item required label="自动续约">
      <div class="flex cen-start">
        <a-switch
          v-decorator="['is_auto_renew', { valuePropName: 'checked', initialValue: true }]"
          checked-children="是"
          un-checked-children="否"
          :disabled="isLook"
        />

        <a-tooltip placement="right" title="自动续约表示曲库合作到期后，合作期限将自动顺延一年。关闭此开关，指该曲库合作到期后，曲库内音乐将自动下架。">
          <span class="ng-form-text font-black">
            <a-icon slot="suffixIcon" type="question-circle" />
          </span>
        </a-tooltip>
      </div>
    </a-form-item>
  </a-form>
</template>
<script>
  import {DatePicker} from 'ant-design-vue';
  export default {
    data() {
      return {
        form: this.$form.createForm(this, "nodify"),

        uploadParams: {},
        uploadName: 'file',
        uploading: false,

        moment: this.$moment,

        profile_photo_url: '',

        defaultRangeValue: Date.now(),
      };
    },

    props: {
      defaultValue: Object,
      isLook: Boolean
    },

    created() {
      this.uploadParams = {
        upload_type: 1,
        field_name: this.uploadName
      }
    },

    components: {
      ARangePicker: DatePicker.RangePicker
    },

    methods: {
      getDateRange() {
        let moment = this.$moment;
        let current = moment();
        let currYear = moment().year();
        let oneYearLater = moment().set('year', currYear + 1).subtract(1, 'days');
        let threeYearLater = moment().set('year', currYear + 3).subtract(1, 'days');
        let fiveYearLater = moment().set('year', currYear + 5).subtract(1, 'days');

        return {'一年': [current, oneYearLater], '三年': [current, threeYearLater], '五年': [current, fiveYearLater]};
      },

      disabledDate(current) {
        return current && current < this.$moment().endOf('day');
      },

      onUploading(uploading) {
        this.uploading = uploading;
      },
    }
  };
</script>
<style lang="scss" scoped>
  .ng-form-text {
    margin-left: 10px;
    font-size: 16px;
  }
</style>