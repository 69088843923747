<template>
  <div class="ng-supplier-manage h-100">
    <main-structure dir="ver">
      <template slot="header">
        <span class="title">{{title}}</span>

        <div class="header-content header-search-content">
          <a-input-search
            class="ng-input-search"
            :placeholder="searchPlaceholder"
            :default-value="$route.query.keyword || ''"
            @search="onSearch"
            allowClear
          >
            <a-button slot="enterButton" type="sub">
              <a-icon type="search" />
            </a-button>
          </a-input-search>
          <a-button
            v-if="$root.$checkAuth($auth[name] && $auth[name].add)"
            type="sub"
            @click="handleFunc($var.Handle.add)"
          >新增{{SupplierTypeDir[$route.meta.type]}}</a-button>
        </div>
      </template>

      <template>
        <div class="m-top-ms flex-0">
          <supplier-filter
            :type="$route.meta.type"
            @change="onFilterChange"
            :defaultData="$route.query"
          ></supplier-filter>
        </div>

        <div class="m-top-base flex-1" ref="ngTableBox">
          <ng-table
            v-if="$root.$checkAuth($auth[name] && $auth[name].list)"
            class="ng-table"
            :columns="columns"
            :rowKey="row => row[idName]"
            :scroll="{y: mainContentScrollMaxHeight}"
            :params="params"
            :request="tableRequestFunc"
            :load.sync="loadTable"
            :reload.sync="reloadTable"
          >
            <template slot="phone" slot-scope="text">{{text || '--'}}</template>

            <template slot="data_source" slot-scope="text">{{text | toAccountOriginCnName}}</template>

            <!-- 头像 -->
            <template slot="profile_photo" slot-scope="text">
              <ng-image
                class="table-header"
                :src="`${text}?x-oss-process=style/thumb_10`"
                :default-value="$var.defaultHeader"
              />
            </template>

            <!-- 查看详情 -->
            <template slot="supplier_name" slot-scope="text, scope">
              <a-tooltip
                v-if="$root.$checkAuth($auth[name] && $auth[name].look)"
                placement="top"
                title="点击进入详情页了解更多"
              >
                <router-link :to="getUrl($var.Handle.look, scope)">{{text}}</router-link>
              </a-tooltip>
              <span v-else type="link">{{text}}</span>
            </template>

            <!-- 查看详情 -->
            <template slot="sign_status" slot-scope="text, scope">
              <span>{{text | toSignStatus}}</span>
            </template>

            <template slot="status" slot-scope="text, scope">
              <table-switch
                v-if="$root.$checkAuth($auth[name] && $auth[name].status)"
                :options="memberStatusOptions"
                :default-value="parseInt(scope.is_enabled)"
                :change="function() { onChangeSwitch(...arguments, scope) }"
                :disabled="scope.op_enable == 0"
              ></table-switch>
              <span
                v-else
                :class="text == 0 ? 'font-error' : 'font-success'"
              >{{$root.$getItemWithKey(memberStatusOptions, 'id', text).name || ''}}</span>
            </template>

            <!-- 操作 -->
            <template
              v-if="$root.$checkAuth($auth[name] && $auth[name].operation)"
              slot="operation"
              slot-scope="text, scope"
            >
              <router-link
                v-if="$root.$checkAuth($auth[name] && $auth[name].edit)"
                class="m-right-ms"
                :to="getUrl($var.Handle.edit, scope)"
                :disabled="scope.op_update == 0"
              >编辑</router-link>


              <a-button :disabled="scope.op_update == 0" v-if="$route.meta.type=='musician'" class="m-right-ms" type="link" @click="sendMail(scope)" >发送邀请邮件</a-button>

              <a-dropdown>
                <a-button type="link" @click="e => e.preventDefault()">
                  <a-icon
                    type="more"
                    :rotate="90"
                    :style="{ fontSize: '28px', verticalAlign: 'middle' }"
                  />
                </a-button>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a-button
                      v-if="$root.$checkAuth($auth[name] && $auth[name].manageLibrary)"
                      class="m-right-ms"
                      type="link"
                      @click="goAddLibaray(scope)"
                    >曲库管理</a-button>
                  </a-menu-item>
                  <a-menu-item>
                    <a-button
                      v-if="$root.$checkAuth($auth[name] && $auth[name].manageContract)"
                      class="m-right-ms"
                      type="link"
                      @click="goAddContract(scope)"
                    >合约管理</a-button>
                  </a-menu-item>
                  <a-menu-item>
                    <a-button
                      v-if="$root.$checkAuth($auth[name] && $auth[name].auth)"
                      class="m-right-ms"
                      type="link"
                      @click="configureAccount(scope)"
                      :disabled="scope.op_set == 0"
                    >配置账号</a-button>
                  </a-menu-item>
                  <a-menu-item>
                    <a-button
                      v-if="$root.$checkAuth($auth[name] && $auth[name].del)"
                      class="ant-btn-error"
                      type="link"
                      @click="onDelete(scope)"
                      :disabled="scope.op_delete == 0"
                    >删除</a-button>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </template>
          </ng-table>
        </div>
      </template>
    </main-structure>
    <account-setting :visible.sync="showDialog" :user_id="user_id" @success="handleSuccess"></account-setting>
  </div>
</template>

<script>
  import SupplierFilter from './components/SupplierFilter.vue';
  import AccountSetting from '@/pages/supplier/dialog/AccountSetting';

  import {mainContentMixin, tableMixins, filterReq} from '@/mixins';

  import {SupplierType, SupplierTypeDir} from '@/utils/variables'
  import * as TabCol from '@/config/columns';

  import {SignStatusOptions} from '@/config/options';
  import {toOptionName} from '@/utils/function';

  import {OpenAndCloseStatus} from '@/config/options'

  const {MUSICIAN, TEAM, COMPANY} = SupplierType;

  // 获取表格的columns
  const columnsFunc = function (type) {
    let column = [
      TabCol.colId,
      TabCol.commonKey('头像', 'profile_photo', '', true)
    ], diffCol = [];

    let nameOptions = {ellipsis: true, isSlot: true, slotName: 'supplier_name', width: '130px'};

    switch (type) {
      case MUSICIAN:
        diffCol = [
          TabCol.commonKey('艺名', 'stage_name', nameOptions),
          TabCol.commonKey('国籍', 'address'),
        ];
        break;

      case TEAM:
        diffCol = [
          TabCol.commonKey('真实姓名', 'name', nameOptions),
          TabCol.commonKey('艺名', 'brand_name'),
          TabCol.commonKey('国籍', 'address'),
        ];
        break;

      case COMPANY:
        diffCol = [
          TabCol.commonKey('公司名称', 'name', nameOptions),
          TabCol.commonKey('关联厂牌', 'brand_name'),
          TabCol.commonKey('所在地', 'address'),
        ];
        break;
    }

    column = column.concat(diffCol, [
      TabCol.commonKey('入驻时间', 'create_at'),
      TabCol.commonKey('来源', 'data_source', '', true),
      TabCol.commonKey('签约状态', 'sign_status', '', true),
      TabCol.commonKey('服务状态', 'status', '130px', true),
      TabCol.operation('140px')
    ]);

    return column;
  };

  const searchPlaceholderDir = function (type) {
    const dir = {
      [MUSICIAN]: '请输入音乐人ID、姓名、艺名以查询',
      [TEAM]: '请输入发行音乐人ID、名称、艺名以查询',
      [COMPANY]: '请输入公司名称、关联厂牌以查询'
    }

    return dir[type] || dir[MUSICIAN];
  }

  const titleFunc = function (type) {
    return `${SupplierTypeDir[type]}管理`
  }

  // way ===> List\Add....
  const RequestDir = function (axios, type, way = 'List') {
    const dir = {
      [MUSICIAN]: axios[`Supplier_musician${way}`],
      [TEAM]: axios[`Supplier_team${way}`],
      [COMPANY]: axios[`Supplier_company${way}`]
    }

    return dir[type] || dir[MUSICIAN];
  }

  export default {
    data() {
      return {
        name: `supplier_${this.$route.meta.type}`,

        SupplierTypeDir,

        memberStatusOptions: OpenAndCloseStatus,

        title: '',
        columns: [],
        data: [],
        params: {},

        tableRequestFunc: () => { },

        idName: 'user_id',

        showDialog: false,
        user_id: '',

        searchPlaceholder: searchPlaceholderDir(this.$route.meta.type),
      }
    },

    created() {
      this.init(this.$route.meta.type)
    },

    mounted() {
      let {time = [], is_enabled = '', page = 1, address_id = '', keyword = ''} = this.$route.query;
      let params = {is_enabled, page, address_id, keyword, time};

      params = this.parseArrParams(params);

      this.params = params;
    },

    filters: {
      toSignStatus(value) {
        return toOptionName(SignStatusOptions, value);
      }
    },

    components: {
      SupplierFilter, AccountSetting
    },

    mixins: [
      mainContentMixin, tableMixins, AccountSetting, filterReq
    ],

    methods: {

      init(type) {
        this.tableRequestFunc = RequestDir(this.$axios, type)
        this.name = `supplier_${type}`;
        this.title = titleFunc(type);
        this.columns = columnsFunc(type);
      },

      onSearch(keyword) {
        typeof keyword === 'string' && (keyword = keyword.trim());

        let search = {keyword, page: 1};

        this.$root.$goSearch(search);

        this.params = Object.assign({}, this.params, search || {});
      },

      onFilterChange(values) {
        let params = Object.assign({}, this.params, values);

        params = this.parseArrParams(params);

        this.params = this.filterCommon(params);
      },

      onChangeSwitch(key, options, next, loading, scope) {
        loading(true);

        let param = {
          user_id: scope.user_id,
          is_enabled: key
        }

        RequestDir(this.$axios, this.$route.meta.type, 'Status')(param).then(res => {
          let data = res.data;

          loading(false);

          if (data && data.code == 0) {
            next();
            key == 1 ? this.$message.success('已启用') : this.$message.error('已停用');

            this.loadTableData();
          }
        })
      },

      getUrl(handle, scope) {
        let user_id = -1;
        let Handle = this.$var.Handle;
        let url = `/supplier/${this.$route.meta.type}/detail`;

        if (handle === Handle.edit || handle === Handle.look) {
          user_id = scope.user_id;
        }

        return `${url}/${user_id}/${handle}`;
      },

      handleFunc(handle, scope) {
        let user_id = -1;
        let Handle = this.$var.Handle;
        let url = `/supplier/${this.$route.meta.type}/detail`;

        if (handle === Handle.edit || handle === Handle.look) {
          user_id = scope.user_id;
        }

        this.$router.push(`${url}/${user_id}/${handle}`);
      },

      configureAccount() {
        this.$root.dontFinished();
        // this.showDialog = true;
        // this.user_id = scope.user_id;
      },

      goAddLibaray(scope) {
        this.$router.push({
          path: `/library/${this.$route.meta.type}/${scope.user_id}`
        })
      },

      goAddContract(scope) {
        this.$router.push({
          path: `/supplier/contract/${this.$route.meta.type}/${scope.user_id}`
        })
      },

      handleSuccess() {
        this.reloadTableData();
      },

      onDelete(scope) {
        let vm = this;

        this.$confirm({
          width: '480px',
          title: '确定删除该供应商？',
          onOk() {
            return new Promise((resolve, reject) => {
              vm.deleteSupplier(scope, resolve, reject);
            });
          }
        });
      },

      deleteSupplier(scope, next, reject) {
        RequestDir(this.$axios, this.$route.meta.type, 'Del')({user_id: scope.user_id}).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('已成功删除该供应商');
            next();

            setTimeout(() => {
              this.loadTableData();
            }, 500);
          } else {
            reject()
          }
        })
      },

      sendMail(scope) {
        let vm = this;
        this.$confirm({
          width: '480px',
          title: '确定发送邮件吗？',
          onOk() {
            return new Promise((resolve, reject) => {
              vm.sendMailToSupplier(scope, resolve, reject);
            });
          }
        });
      },
      sendMailToSupplier(scope, next, reject) {
          let param = {user_id: scope.user_id};
          this.$axios.send_invate_email(param).then(res => {
            const data = res.data;
            if (data && data.code == 0) {
              let msg = '邮箱:'+scope.email+' 艺名'+ scope.stage_name+'发送成功';
              this.$message.success(msg);
              next();
            } else {
              reject()
            }
          })
      },

    }
  }
</script>

<style lang="scss" scoped>
  .ng-supplier-manage {
    .op-btn {
      width: 88px;
      margin-bottom: 10px;
    }

    .table-video-tag {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .table-image-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 151px;
      height: 100px;
      background-color: rgba(216, 216, 216, 1);
    }
  }
</style>
