<template>
  <main-structure class="ng-account-members" dir="ver">
    <template slot="header">
      <span class="title">曲库详情</span>
    </template>

    <template>
      <div class="m-top-ms flex-0">
        <ng-breadcrumb :data="breadData"></ng-breadcrumb>
      </div>

      <div class="m-top-md p-20">
        <ng-detail title="基础信息">
          <library-detail-base-info :requestData="requestData"></library-detail-base-info>
        </ng-detail>
        <ng-detail class="m-top-md" title="音乐信息">
          <music-list :lib_id="$route.params.lib_id"></music-list>
        </ng-detail>
      </div>
    </template>
  </main-structure>
</template>

<script>
  import LibraryDetailBaseInfo from './detail/LibraryDetailBaseInfo.vue'
  import MusicList from './detail/MusicList.vue'

  import {SupplierTypeDir} from '@/utils/variables'

  const breadData = function (query) {
    let {supplier_type, user_id, price} = query;
    let commonArr = [{breadcrumbName: '曲库详情'}];

    // 供应商
    if (supplier_type) {
      return [
        {breadcrumbName: `${SupplierTypeDir[supplier_type]}管理`, path: `/supplier/${supplier_type}`},
        {breadcrumbName: `曲库管理`, path: `/library/${supplier_type}/${user_id}`},
        ...commonArr
      ]
    }
    // 价格配置
    else if (price) {
      return [
        {breadcrumbName: `售价管理`, path: `/goods/manage`},
        ...commonArr
      ]
    }
    // 曲库管理
    else {
      return [
        {breadcrumbName: `曲库管理`, path: `/librarymanage`},
        ...commonArr
      ]
    }
  }

  export default {
    data() {
      return {
        data: {},
        breadData: breadData(this.$route.query),
        requestData: {}
      }
    },
    components: {
      LibraryDetailBaseInfo,
      MusicList
    },
    created() {
      this.getData();
    },
    methods: {
      getData() {
        let param = {lib_id: this.$route.params.lib_id};

        this.$axios.Library_info(param).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.requestData = data.data;
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
