<template>
  <main-structure class="ng-verify-detail ng-detail" dir="ver" :loading="loading">
     <template slot="header">
      <ng-breadcrumb :data="breadData"></ng-breadcrumb>
    </template>
    <div class="info-content">
      <ng-detail title="基础信息">
        <div class="flex order-info">
          <ul class="flex-1">
            <li>订单编号：{{orderInfo.order_no}}</li>
            <li>下单时间：{{orderInfo.create_at}}</li>
            <li>付款方式：{{orderInfo.pay_way | toPayWay}}</li>

            <li>订单状态：{{orderInfo.status | toOrderStatus}}</li>
            <li>应付金额：￥{{orderInfo.total_amount}}</li>
            <li>实付金额：￥{{orderInfo.pay_amount}}</li>
          </ul>
          <ul class="flex-1">
            <li>用户名：{{orderInfo.user_name}}</li>
            <li>用户ID：{{orderInfo.user_id}}</li>
            <a-button
              v-if="orderInfo.status === $val.UndoPayOrderType.paying"
              type="primary"
              @click="onSettelOrder"
            >线下结单</a-button>
          </ul>
        </div>
      </ng-detail>
      <ng-detail class="m-top-base" title="认证信息" v-if="orderInfo.auth_info">
        <div class="flex order-info">
          <ul class="flex-1" v-if="orderInfo.auth_info[0].auth_type==1">
            <li>公司名称： {{orderInfo.auth_info[0].name}}</li>
            <li>税号： {{orderInfo.auth_info[0].due}}</li>
            <li>联系方式：{{orderInfo.auth_info[0].phone}}</li>
          </ul>
          <ul class="flex-1" v-if="orderInfo.auth_info[0].auth_type==2">
            <li>姓名： {{orderInfo.auth_info[0].name}}</li>
            <li>联系方式：{{orderInfo.auth_info[0].phone}}</li>
          </ul>
        </div>
      </ng-detail>

      <ng-detail class="m-top-base" title="商品信息" v-if="orderInfo.vip_info">
        <div class="flex order-info">
          <ul class="flex-1">
            <li>会员名称：{{orderInfo.vip_info[0].name}}</li>
            <li v-if="orderInfo.vip_info[0].auth_type==2">会员类型：个人vip</li>
            <li v-if="orderInfo.vip_info[0].auth_type==1">会员类型：企业vip</li>
            <li>开通时间：{{orderInfo.vip_info[0].coop_start_date}}</li>
            <li v-if="orderInfo.vip_info[0].coop_end_date">到期时间：{{orderInfo.vip_info[0].coop_end_date}}</li>
            <li v-if="orderInfo.vip_info[0].download_num=='-1'">下载限制：无限制</li>
            <li v-else>下载限制：{{orderInfo.vip_info[0].download_num}}</li>
          </ul>
        </div>
      </ng-detail>
    </div>
    <dialog-vip-settle-order
      :visible.sync="showDialog"
      :order_id="orderInfo.order_id"
      @success="onSuccess"
    ></dialog-vip-settle-order>
  </main-structure>
</template>

<script>

  import {NgBreadcrumb} from '@/components/index';
  import DialogVipSettleOrder from './vip/DialogSettleOrder.vue'
  import {toOptionName} from '@/utils/function';
  import {OrderStatusOptionsAdmin} from '@/config/options'

  const breadData = [
    {breadcrumbName: `订阅订单`, path: `/order/vip`},
    {breadcrumbName: `订单详情`}
  ]

  export default {
    data() {
      return {
        name: `packageorder_info`,
        loading: false,
        breadData,
        orderInfo: {},
        showDialog: false,
      }
    },
    components: {
      NgBreadcrumb,
      DialogVipSettleOrder
    },
    created() {
      this.getInfo(this.$route.params.order_id)
    },
    filters: {
      toOrderStatus(value) {
        return toOptionName(OrderStatusOptionsAdmin, value);
      },
    },
    methods: {
      getInfo(order_id) {
        let params = {order_id};
        this.loading = true;
        this.$axios.VipManage_info(params).then(res => {
          const data = res.data;
          if (data && data.code == 0) {
            this.orderInfo = data.data;
          }
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })
      },
      onSuccess() {
        this.getInfo(this.$route.params.order_id)
      },
      onSettelOrder() {
        this.showDialog = true;
      },
    }
  }
</script>

<style lang="scss" scoped>
  .info-content {
    padding: 20px;
  }

  .order-info {
    line-height: 3;
  }
</style>
