export * from '~/config/options.value.c'

// 商品类型
export const Good_type = {
  lib: '1', // 曲库
  good: '2', // 商品
}

// 商品定义类型
export const Good_define_type = {
  common: '1', // 通用
  custome: '2', // 自定义
}