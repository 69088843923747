<template>
  <main-structure class="ng-library" dir="ver" :loading="$store.state.contentLoading">
    <template slot="header">
      <span class="title">收益账单</span>

      <div class="header-content header-search-content">
        <a-input-search
          class="ng-input-search"
          placeholder="请输入供应商ID或名称或音乐ID以搜索"
          :default-value="$route.query.keyword || ''"
          @search="onSearch"
          allowClear
        >
        <a-button slot="enterButton" type="sub">
            <a-icon type="search" />
          </a-button>
        </a-input-search>

        <a-button
          v-if="$root.$checkAuth($auth[name] && $auth[name].add)"
          type="sub"
          @click="AddhandleFunc($var.Handle.add)"
        >上传收益</a-button>
      </div>
    </template>
    
    <template>
       <filter-table @change="onFilterChange" :defaultData="$route.query">
        <template v-slot:extra>
          <a-button type="sub" style="margin-top: 4px;float:right;" v-if="$root.$checkAuth($auth[name] && $auth[name].download)"
              @click="onDownload()" icon="download"> 下载账单
          </a-button>
        </template>
      </filter-table>

      <div class="m-top-base flex-1" ref="ngTableBox">
        <manage-table
          v-if="$root.$checkAuth($auth[name] && $auth[name].list)"
          :scrolly-height="mainContentScrollMaxHeight"
          :auth-name="name"
          :table-params="tableParams"
          @handle="onHandleFunc"
          ref="ngTable"
        ></manage-table>
      </div>
    </template>
     <earnings-add
      :visible.sync="showHandleDialog"
      :handle="handleType"
      :default-value="handleData"
      :user_id="$route.params.user_id"
      @success="AddhandleSuccess"
    ></earnings-add>
     <dialog-earnings-info
      :auth-name="name"
      :visible.sync="showDialogInfo"
      :earnings_id="currentData.earnings_id"
    ></dialog-earnings-info>
  </main-structure>
</template>

<script>
  import ManageTable from './components/ManageTable.vue';
  import FilterTable from './components/FilterTable.vue'
  import EarningsAdd from './components/EarningsAdd.vue'
  import DialogEarningsInfo from './components/DialogEarningsInfo.vue'

  import {mainContentMixin, filterReq} from '@/mixins';
  import * as $config from '~/config/config.c';
  import $storage from '~/utils/storage'
  import * as $var from '~/utils/variables.c'
  let baseURL = $config.ServeBaseHost;

  export default {
    data() {
      return {
        name: `earnings_manage`,
        isReloadTable: false,
        tableParams: {},
        handleType: this.$var.Handle.add,
        showHandleDialog: false,
        showCoopHandleDialog: false,
        handleData: {},
        showDialogInfo: false,
        currentData: {}
      }
    },

    mounted() {
      let {time = [], page = 1, keyword} = this.$route.query;
      let params = {time, page, keyword};
      params = this.parseArrParams({...params});
      this.tableParams = params;
    },

    components: {
      ManageTable, 
      FilterTable,
      EarningsAdd,
      DialogEarningsInfo,
    },

    mixins: [
      mainContentMixin, filterReq
    ],

    methods: {
      onDownload() {
        let token = encodeURIComponent($storage.get($var.STORAGE.TOKEN));
        let url = `${baseURL}/bms/earnings/downEarnings?token=${token}`
        window.open(url)
      },
      onFilterChange(values) {
        let params = Object.assign({}, this.tableParams, values);
        params = this.parseArrParams(params);
        this.tableParams = this.filterCommon(params);
      },
      onSearch(keyword) {
        typeof keyword === 'string' && (keyword = keyword.trim());
        let search = {keyword, page: 1};
        this.$root.$goSearch(search);
        this.tableParams = search;
      },
      //上传收益成功
      AddhandleSuccess(isLoad) {
        this.showHandleDialog = false;
        this.showCoopHandleDialog = false;
        isLoad !== false ? this.$refs.ngTable.reloadTableData() : this.$refs.ngTable.loadTableData();
      },
      //上传收益弹窗
      AddhandleFunc(key, scope) {
        this.handleData = scope || {};
        this.handleType = key;
        this.showHandleDialog = true;
      },
      //收益详情
      onHandleFunc(option) {
        let handleName = option.handleName;
        this.currentData = option;
        if (handleName === this.$var.Handle.look) {
          this.showDialogInfo = true;
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
