<template>
  <div class="coupon-detail-info  w-100">
    <div class="flex cen-center">
      <a-card class="info-card">
         <div>发送数量</div>
         <div>{{requestData.total_num}}</div>
      </a-card>
      <a-card class="info-card">
         <div>领取数量</div>
         <div>{{requestData.bind_num}}</div>
      </a-card>
      <a-card class="info-card">
         <div>使用数量</div>
         <div>{{requestData.used_num}}</div>
      </a-card>
      <a-card class="info-card">
        <div>累计收益</div>
         <div>{{requestData.total_earnings}}</div>
      </a-card>
    </div>
  
    <div class="coupon-info m-top-md">
      <a-card>
        <div class="info-wrap flex row cen-center">
          <div class="info-item">
              <p>优惠券名称：{{couponInfo.name}}</p>
              <p>优惠券ID：{{couponInfo.coupon_id}}</p>
              <p>领取方式：{{couponInfo.get_way_str}}</p>
          </div>
          <div class="info-item">
              <p>优惠类型：{{couponInfo.coupon_type_str}}</p> 
              <p>优惠内容：{{couponInfo.coupon_type==1?`${couponInfo.discount}折`:`减${couponInfo.money}`}}</p>
              <p>使用门槛：{{couponInfo.threshold>0?`满${couponInfo.threshold}`:`无门槛`}}</p>
          </div>
          <div class="info-item">
              <p>创建时间：{{couponInfo.create_at}}</p>
              <p>有效时间：{{couponInfo.time_type=='2'?`领取后${couponInfo.valid_day}天有效`:couponInfo.begin_time+'-'+couponInfo.end_time}}</p>
              <p>使用说明：{{couponInfo.description}}</p>
          </div>
        </div>
      </a-card>
    </div>

  </div>
</template>

<script>
  import {BusinessSceneOptions} from '@/config/options';
  import {toOptionName} from '@/utils/function';

  import {darken} from '@/utils/function'

  export default {
    data() {
      return {
       
      }
    },

    props: {
      requestData: Object,
      couponInfo:Object
    },

    filters: {
      toAuthScene(value) {
        return toOptionName(BusinessSceneOptions, value);
      }
    },

    components: {

    },
    methods: {
      darken
    }
  }
</script>

<style lang="scss" scoped>
/deep/{
 .ant-card-body{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
}
  .coupon-detail-info {
    padding: $space-ms;
    justify-content: space-around;
    .info-card{
      flex: 1;
      margin-right: 3%;
      font-size: 24px;
      font-weight: bold;
      &:last-child{
        margin-right: 0;
      }
     
    }
    .img-box {
      width: 230px;
      height: 230px;
      margin: 0 10px;
    }
    .coupon-info{
      .info-wrap{
        width: 100%;
        .info-item{
          flex: 1;
        }
      }
    }
  }
</style>
