/**
 * 账号权限管理
 */
import {
  Axios
} from '@/utils/from-common';


// 账户信息
export const Sup_Account_info = (param) => Axios('POST', '/bms/supplier/accountInfo', param);

// 认证信息
export const Sup_Account_authInfo = (param) => Axios('POST', '/bms/supplier/authInfo', param);

// 编辑个人链接
export const Sup_Account_editLink = (param) => Axios('POST', '/bms/supplier/opSelfLink', param);

// 编辑手机号
export const Sup_Account_editPhone = (param) => Axios('POST', '/bms/supplier/upPhone', param);

// 编辑邮箱
export const Sup_Account_editEmail = (param) => Axios('POST', '/bms/supplier/upEmail', param);



// 基础信息
export const Sup_Base_info = (param) => Axios('POST', '/bms/supplier/baseInfo', param);

// 修改音乐人信息
export const Sup_Base_editMusicianInfo = (param) => Axios('POST', '/bms/supplier/upMusBaseInfo', param);

// 修改音乐团体信息
export const Sup_Base_editTeamInfo = (param) => Axios('POST', '/bms/supplier/upTeamBaseInfo', param);

// 修改音乐公司信息
export const Sup_Base_editCompanyInfo = (param) => Axios('POST', '/bms/supplier/upCompBaseInfo', param);



// 联系人信息
export const Sup_Contact_list = (param) => Axios('POST', '/bms/supplier/contactList', param);

// 联系人新增
export const Sup_Contact_add = (param) => Axios('POST', '/bms/supplier/addContact', param);

// 联系人编辑
export const Sup_Contact_edit = (param) => Axios('POST', '/bms/supplier/upContact', param);

// 联系人删除
export const Sup_Contact_del = (param) => Axios('POST', '/bms/supplier/delContact', param);



// 收件信息列表
export const Sup_Address_list = (param) => Axios('POST', '/bms/supplier/expressList', param);

// 收件详细信息
export const Sup_Address_info = (param) => Axios('POST', '/bms/supplier/expressInfo', param);

// 收件新增
export const Sup_Address_add = (param) => Axios('POST', '/bms/supplier/addExpress', param);

// 收件编辑
export const Sup_Address_edit = (param) => Axios('POST', '/bms/supplier/upExpress', param);

// 收件删除
export const Sup_Address_del = (param) => Axios('POST', '/bms/supplier/delExpress', param);

// 设置默认收货地址
export const Sup_Address_setDefault = (param) => Axios('POST', '/bms/supplier/setDefaultExprs', param);



// 收款账户列表
export const Sup_Cash_list = (param) => Axios('POST', '/bms/supplier/receiptList', param);

// 收款账户详细信息
export const Sup_Cash_info = (param) => Axios('POST', '/bms/supplier/receiptInfo', param);

// 添加收款账户
export const Sup_Cash_addAccount = (param) => Axios('POST', '/bms/supplier/addReceipt', param);

// 编辑收款账户
export const Sup_Cash_editAccount = (param) => Axios('POST', '/bms/supplier/upReceipt', param);

// 添加第三方收款账户
// export const Sup_Cash_addThirdAccount = (param) => Axios('POST', '/bms/supplier/addSelfRcpt', param);

// // 编辑第三方收款账户
// export const Sup_Cash_editThirdAccount = (param) => Axios('POST', '/bms/supplier/upSelfRcpt', param);

// // 添加公对公收款账户
// export const Sup_Cash_addBTBAccount = (param) => Axios('POST', '/bms/supplier/addCompRcpt', param);

// // 添加公对公收款账户
// export const Sup_Cash_editBTBAccount = (param) => Axios('POST', '/bms/supplier/upCompRcpt', param);

// 删除收款账户
export const Sup_Cash_delAccount = (param) => Axios('POST', '/bms/supplier/delReceipt', param);

// 设置默认收款账户
export const Sup_Cash_setDefaultAccount = (param) => Axios('POST', '/bms/supplier/setDefaultRcpt', param);