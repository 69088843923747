/**
 * 账号权限管理
 */
import {
  Axios
} from '@/utils/from-common';

// 音乐人列表
export const Ver_musicianList = (param) => Axios('POST', '/bms/check/musicianList', param);

// 音乐人详情
export const Ver_musicianInfo = (param) => Axios('POST', '/bms/check/musicianInfo', param);

// 音乐人审核
export const Ver_musicianCheck = (param) => Axios('POST', '/bms/check/musicianCheck', param);

// 音乐团体列表
export const Ver_teamList = (param) => Axios('POST', '/bms/check/teamList', param);

// 音乐团体详情
export const Ver_teamInfo = (param) => Axios('POST', '/bms/check/teamInfo', param);

// 音乐团体审核
export const Ver_teamCheck = (param) => Axios('POST', '/bms/check/teamCheck', param);

// 音乐公司列表
export const Ver_companyList = (param) => Axios('POST', '/bms/check/companyList', param);

// 音乐公司详情
export const Ver_companyInfo = (param) => Axios('POST', '/bms/check/companyInfo', param);

// 音乐公司审核
export const Ver_companyCheck = (param) => Axios('POST', '/bms/check/companyCheck', param);


// 曲库审核列表
// export const Ver_LibraryList = (param) => Axios('POST', '/bms/musiclib/reviewList', param);

// // 审核通过并签约
// export const Ver_LibrarySign = (param) => Axios('POST', '/bms/musiclib/reviewSign', param);

// 签约审核列表
export const Ver_LibrarySignList = (param) => Axios('POST', '/bms/check/signList', param);

// 拒绝签约
export const Ver_LibraryRejectSign = (param) => Axios('POST', '/bms/check/rejectSign', param);

// 签约
export const Ver_LibrarySign = (param) => Axios('POST', '/bms/check/sign', param);