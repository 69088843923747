/**
 * 账号权限管理
 */
import {
    Axios
} from '@/utils/from-common';
import * as $config from '~/config/config.c';

// 新建优惠券
export const NewCoupon = (param) => Axios('POST', '/bms/coupon/couponSave', param);

// 优惠券详情
export const CouponInfo = (param) => Axios('POST', '/bms/coupon/couponInfo', param);

// 优惠券列表
export const CouponList = (param) => Axios('POST', '/bms/coupon/couponList', param);

// 优惠券券码列表
export const CouponCodeList = (param) => Axios('POST', '/bms/coupon/couponCodeList', param);

// 获取优惠券统计数据
export const CouponStatistics = (param) => Axios('POST', '/bms/coupon/couponStatistics', param);

// 生成券码
export const GenerateCode = (param) => Axios('POST', '/bms/coupon/generateCode', param);

// 发送优惠券
export const SendCoupon = (param) => Axios('POST', '/bms/coupon/send', param);

// 优惠券券码下载
export const DownCouponCode = (param) => Axios('POST', '/bms/coupon/downCouponCode', param);

// 优惠券下载
export const DownloadCoupon = (param) => Axios('POST', '/bms/coupon/downCoupon', param);

// 优惠券启用/停用接口
export const ChangeCouponStatus = (param) => Axios('POST', '/bms/coupon/changeStatus', param);


// 优惠券数据 下载

