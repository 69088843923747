<template>
  <a-modal
    width="640px"
    title="合同详情"
    v-model="show"
    :maskClosable="true"
    @ok="show = false;"
    :bodyStyle="bodyStyle"
  >
    <ng-detail title="基本信息">
      <ul class="info-list">
        <li>申请日期：{{info.create_at}}</li>
        <li>处理日期：{{info.operate_time}}</li>
        <li>申请用户：{{info.user_name}}</li>
        <!-- <li>关联订单：{{info.order ? info.order.order_no : ''}}</li> -->
        <li>订单金额：￥{{info.order ? info.order.pay_amount : ''}}</li>
        <li>申请编号：{{info.number}}</li>
        <li>处理人员：{{info.operator_name}}</li>
      </ul>
    </ng-detail>
    <ng-detail title="公司信息">
      <ul class="info-list">
        <li>公司名称：{{info.company ? info.company.name: ''}}</li>
        <li>公司地址：{{info.company ? info.company.address: ''}}</li>
        <li>联系人姓名：{{info.company ? info.company.contacter: ''}}</li>
        <li>联系电话：{{info.company ? info.company.phone: ''}}</li>
        <li>联系邮箱：{{info.company ? info.company.email: ''}}</li>
      </ul>
    </ng-detail>
    <ng-detail title="收件信息">
      <ul class="info-list">
        <li>邮寄地址：{{info.express ? info.express.address : ''}}</li>
        <li>收件人姓名：{{info.express ? info.express.name : ''}}</li>
        <li>收件人手机号：{{info.express ? info.express.phone : ''}}</li>
      </ul>
    </ng-detail>
    <ng-detail title="授权信息">
      <ul class="info-list">
        <li>项目名称：{{info.project}}</li>
        <li>投放渠道：{{info.project_path}}</li>
      </ul>
    </ng-detail>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';

  export default {
    data() {
      return {
        show: false,
        info: {}
      }
    },

    props: {
      id: String,
    },

    watch: {
      visible(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.getData(this.id);
          })
        }
      }
    },

    mixins: [dialogMixins],

    components: {
    },

    methods: {
      getData(contract_id) {
        let params = {contract_id};

        this.$axios.BuyManage_contractInfo(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.info = data.data ? data.data : {}
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .info-list {
    line-height: 2.2;
  }
</style>
