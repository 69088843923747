<template>
  <a-form class="ng-form-style ng-filter" :form="form" layout="inline">
    <a-form-item label="售卖状态">
      <a-select
        :options="SellOperateOptions"
        v-decorator="['sale_status', {initialValue: defaultData.sale_status || ''}]"
        :style="{width: '120px'}"
      ></a-select>
    </a-form-item>
  </a-form>
</template>

<script>
  import {
    AddDefaultToOptions,
    SellOperateOptions
  } from '@/config/options'

  export default {
    data() {
      return {
        SellOperateOptions: AddDefaultToOptions(SellOperateOptions, '全部'),
      }
    },

    props: {
      type: String,
      defaultData: {
        type: Object,
        default() {
          return {}
        }
      },
    },

    components: {
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        onValuesChange: (props, values) => {
          values = Object.assign(values, {page: 1});

          this.$root.$goSearch(values);

          this.$emit('change', values);
        }
      });
    },

    methods: {
    }
  }
</script>

<style lang="scss" scoped>
</style>
