import {
  SupplierType
} from '@/utils/variables';

// 音乐人
const MusicianForms = [{
    key: 'profile_photo',
    name: '头像'
  },
  {
    key: 'name',
    name: '姓名',
    placeholder: '请输入姓名'
  },
  {
    key: 'stage_name',
    name: '艺名',
    placeholder: '请输入艺名'
  },
  {
    key: 'sex',
    name: '性别',
    placeholder: '请选择性别'
  },
  {
    key: 'address_id',
    name: '国籍',
    placeholder: '请选择国籍'
  },
  {
    key: 'desc',
    name: '个人描述',
    placeholder: '请输入个人描述'
  },
]

// 音乐团体 -》 发行音乐人 2021/6/21 up 
const TeamForms = [{
    key: 'profile_photo',
    name: '头像'
  },
  {
    key: 'name',
    name: '真实姓名/公司名称',
    placeholder: '请输入真实姓名/公司名称'
  },
  {
    key: 'brand_name',
    name: '艺名/厂牌名称',
    placeholder: '请输入艺名/厂牌名'
  },
  {
    key: 'address_id',
    name: '国籍',
    placeholder: '请选择国籍'
  },
  {
    key: 'desc',
    name: '介绍',
    placeholder: '请输入介绍'
  },
]

// 音乐公司
const CompanyForms = [{
    key: 'profile_photo',
    name: '头像'
  },
  {
    key: 'name',
    name: '公司名称',
    placeholder: '请输入公司名称'
  },
  {
    key: 'foreign_name',
    name: '对外显示名称',
    placeholder: '请输入对外显示名称'
  },
  {
    key: 'brand_name',
    name: '厂牌名称',
    placeholder: '请输入厂牌名称'
  },
  {
    key: 'address_id',
    name: '经营地址',
    placeholder: '请选择经营地址'
  },
  {
    key: 'desc',
    name: '简介',
    placeholder: '请输入简介'
  },
]

export default {
  [SupplierType.MUSICIAN]: MusicianForms,
  [SupplierType.TEAM]: TeamForms,
  [SupplierType.COMPANY]: CompanyForms,
}