<template>
  <div>
    <a-spin class="w-100" :spinning="spinning">
      <div
        v-if="$root.$checkAuth($auth[authName] && $auth[authName].accountInfo)"
        class="detail-main flex flex-column cen-start"
      >
        <user-phone
          :user_id="user_id"
          :is-look="handle === $var.Handle.look"
          :data="info"
          :auth-name="authName"
          @success="onSuccess"
        ></user-phone>
        <user-email
          :user_id="user_id"
          :is-look="handle === $var.Handle.look"
          :data="info"
          :auth-name="authName"
          @success="onSuccess"
        ></user-email>
        <user-link
          :user_id="user_id"
          :is-look="handle === $var.Handle.look"
          :data="info"
          :auth-name="authName"
          @success="onSuccess"
        ></user-link>
      </div>
    </a-spin>
  </div>
</template>

<script>
  // import detail from "./components/detail";
  // import DialogAdd from './components/DialogAdd.vue'
  import UserPhone from './components/UserPhone.vue';
  import UserEmail from './components/UserEmail.vue';
  import UserLink from './components/UserLink.vue';

  export default {
    data() {
      return {
        title: "账户管理",

        showHandleDialog: false,
        dialogFormDefault: {},
        handleType: 'add',

        info: {},

        spinning: false,
      };
    },

    props: {
      user_id: String,
      handle: String,
      authName: String
    },

    components: {
      UserPhone, UserEmail, UserLink
    },

    created() {
      this.$root.$checkAuth(this.$auth[this.authName].accountInfo) && this.getData();
    },

    methods: {
      onSuccess() {
        this.getData();
      },

      getData() {
        let param = {user_id: this.user_id};

        this.spinning = true;

        this.$axios.Sup_Account_info(param).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.info = data.data;
          }

          this.spinning = false;
        })
      },
    }
  };
</script>

<style lang="scss" scoped>
  .add-btn {
    cursor: pointer;

    .iconfont {
      width: 20px;
      height: 20px;
      margin-right: 2px;
    }
  }
</style>
