<template>
        <ng-table
          class="ng-table"
          :columns="columns"
          :params="params"
          :rowKey="row => row.playlist_id"
          :request="$axios.PlayListByLabel"
          :load.sync="loadTable"
          :reload.sync="reloadTable"
          :scroll="{y: scrollyHeight - 20}"
          @dataChange="requestSuccess"
        >
        <template slot="logo_url" slot-scope="text, scope">
            <ng-image
                class="table-header"
                :src="scope.logo_url ? `${scope.logo_url}?x-oss-process=style/thumb_10` : $var.defaultHeader"
                @error="({target}) => target.src = $var.defaultHeader"
            />
        </template>
      
          <template
            v-if="$root.$checkAuth($auth[authName] && $auth[authName].customePriceInfo)"
            slot="operation"
            slot-scope="text, scope"
          >
            <a-button  type="link" @click="onOperate(scope,1)" style="margin-right:10px">编辑</a-button>
            <!-- <a-button  type="link" @click="onOperate(scope,2)" style="color:gray">删除</a-button> -->
            <!-- <router-link
              v-else
              :to="`/goods/sellway?goods_id=${scope.lib_id}&goods_type=${$val.Good_type.lib}&done_price=${scope.is_price}`"
            >编辑</router-link> -->
          </template>
        </ng-table>
      </template>
            
            <script>
      import { tableMixins } from "@/mixins";
      
      import {
        AuthAreaOptions,
        AuthTypeOptions,
        BusinessSceneOptions,
        SellStatusOptions,
        PriceStatue
      } from "@/config/options";
      import { toOptionName } from "@/utils/function";
      import * as AllOptions from "@/config/options";
      import * as TabCol from "@/config/columns";
      
      export default {
          data() {
              const filterEle = (text, scope, index, item) => {
                  let label = item.key;
                  let arr = [];
                  let options = [{ label: "上架", value: 1 }, { label: "下架", value: 0 }, { label: "待上架", value: -1 }];
                  switch (label) {
                      
                      case "sale_status":
                          if (text > 1) options.shift();
                          return (
                              <table-switch
                              options={
                                  text >= 0 ? options.filter(item => item.value >= 0) : options
                              }
                              default-value={parseInt(text) || 0}
                              format={{ id: "value", name: "label" }}
                              change={(...arr) => {
                                  this.onChangeSwitch(scope, ...arr);
                              }}
                              ></table-switch>
                          );
                      case "sort":
                          return (
                              <a-input-number
                              default-value={scope.sort}
                              on={{
                                  blur: event => {
                                  this.onBlurWeight(scope, event.target.value);
                                  }
                              }}
                              style="width: 100px;"
                              />
                          );
                      case "create_at":
                          arr = text.split(" ");
                          return <div>{arr[0]}</div>;
                      default:
                          return text;
                  }
              };
              const columns = [
                  TabCol.colId,
                  TabCol.portrait("歌单封面", "logo_url"),
                  TabCol.commonKey("歌单名称", "name", { ellipsis: true, isSlot: true, width: "280px", }),
                  TabCol.commonKey("数量", "music_num",),
                  TabCol.commonKey("创建者", "creator",),
                  TabCol.commonKey("创建时间", "create_at", {
                      isSlot: true,width: 180,
                      customRender: filterEle.bind(this)
                  }),
                  TabCol.commonKey("售卖状态", "sale_status", {
                      isSlot: true,
                      width: 180,
                      customRender: filterEle.bind(this)
                  }),
                  TabCol.commonKey("排序", "sort", {
                      isSlot: true,width: 180,
                      customRender: filterEle.bind(this)
                  }),
                  
                  TabCol.operation("100px")
              ];
              return {
                  columns,
                  tableDict: {},
                  handleData: {},
                  
              };
          },
      
          props: {
                  authName: String,
                  scrollyHeight: {
                  type: Number,
                  default: 400
                  },
                  reload: Boolean,
                  params: Object,
          },
      
          mounted() {
      
          },
          filters: {
          },
      
          computed: {
      
          },
          mixins: [tableMixins],
          methods: {
                onOperate(scope,type) {
                    var d = this.tableDict[scope['playlist_id']];
                    d.type = type;
                    this.$emit("operate", d);
                },
                requestSuccess(data) {
                    for (var i=0;i<data.data.list.length;i++){
                        var key = data.data.list[i]['playlist_id'];
                        this.tableDict[key] = data.data.list[i];
                    }
                },
                onChangeSwitch(scope,arr) {
                    scope.sale_status = arr;
                    this.onOperate(scope,3);
                },
                onBlurWeight(scope,arr){
                    scope.sort = arr;
                    this.onOperate(scope,4);
                }
          }
      };
      </script>
            
      <style lang="scss" scoped>
      /deep/ .ant-table td {
        white-space: nowrap;
      }
      .word-break {
        width: 100%;
        word-break: break-all;
      }
      </style>
            