const Library = () => import('@/pages/library/Library.vue');
const LibraryBatch = () => import('@/pages/library/LibraryBatch.vue');
const LibraryDetail = () => import('@/pages/library/LibraryDetail.vue');

export default [
  // 供应商下的曲库
  {
    path: '/library/:supplier_type/:user_id', // user_id 供应商id  type 供应商类型
    name: 'library',
    meta: {
      from: 'supplier'
    },
    component: Library,
  },
  // 曲库管理
  {
    path: '/librarymanage',
    name: 'librarymanage',
    meta: {
      from: ''
    },
    component: Library,
  },
  // 批次
  {
    path: '/librarybatch/:lib_id', // lib_id 曲库id  user_id supplier_type 供应商类型
    name: 'librarybatch',
    component: LibraryBatch,
  },
  // 曲库详情
  {
    path: '/librarydetail/:lib_id', // lib_id 曲库id  type 供应商类型  /:type/:user_id
    name: 'librarydetail',
    component: LibraryDetail,
  },
]