<template>
  <main-structure class="ng-account-members" dir="ver">
    <template slot="header">
      <span class="title">标注任务 - {{title}}</span>
      <div class="header-content header-search-content">
        <a-input-search
          class="ng-input-search"
          placeholder="请输入曲库名称、供应商名称以检索"
          :default-value="$route.query.name || ''"
          @search="onSearch"
          allowClear
          v-model="keyword"
        >
          <a-button slot="enterButton" type="sub">
            <a-icon type="search" />
          </a-button>
        </a-input-search>
      </div>
    </template>
    <a-form class="ng-form-style ng-filter" :form="form"  layout="inline" v-if="title=='已完成' ">
          <a-form-item label="认领时间：" style="margin-left: 20px">
            <a-select
              :options="SortOptions"
              :style="{width: '120px'}"
              v-decorator="['sort']"
              @change="onChangeSort"
            ></a-select>
          </a-form-item>
          <a-form-item label="交付时间：" style="margin-left: 20px">
            <a-select
              :options="SortOptions"
              :style="{width: '120px'}"
              v-decorator="['ssort']"
              @change="onChangeSSort"
            ></a-select>
          </a-form-item>
        </a-form>
       
    <template>
      <div class="m-top-base flex-1" ref="ngTableBox">
        <component
          v-if="$root.$checkAuth($auth[name] && $auth[name].list)"
          :is="getServeTableComp"
          :scrolly-height="mainContentScrollMaxHeight"
          :auth-name="name"
          :params="tableParams"
          @changesort="onChangeSort"
          ref="manageTable"
        ></component>
      </div>
    </template>
  </main-structure>
</template>

<script>
  import TableUndo from './components/TableUndo.vue';
  import TableDoing from './components/TableDoing.vue';
  import TableDone from './components/TableDone.vue';

  import {mainContentMixin} from '@/mixins';
  import {ServeType} from '@/utils/variables';
  import {AddDefaultToOptions,SortOptions} from '@/config/options'
  const getServeTableComp = (type) => {
    const dir = {
      [ServeType.UNDO]: TableUndo,
      [ServeType.DOING]: TableDoing,
      [ServeType.DONE]: TableDone,
    }

    return dir[type] ? dir[type] : dir[ServeType.UNDO]
  }

  const getTitle = (type) => {
    const dir = {
      [ServeType.UNDO]: '待标注',
      [ServeType.DOING]: '标注中',
      [ServeType.DONE]: '已完成',
    }

    return dir[type] ? dir[type] : dir[ServeType.UNDO]
  }

  export default {
    data() {
      return {
        form: this.$form.createForm(this, "nodify"),
        name: `markTask_${this.$route.meta.type}`,
        getServeTableComp: getServeTableComp(this.$route.meta.type),
        title: getTitle(this.$route.meta.type),

        tableParams: {},
        keyword: "",
        SortOptions: AddDefaultToOptions(SortOptions),
        sort:"",
        ssort:"",
      }
    },
    components: {
      TableUndo, TableDoing, TableDone
    },

    mixins: [
      mainContentMixin
    ],

    mounted() {
      let { query } = this.$route;
      this.tableParams = Object.assign({}, this.tableParams, {
        page: query.page || 1,
      });
      if(this.title=="已完成")
      {
        this.$nextTick(() => {
            this.form.setFieldsValue({ sort : this.sort })
            this.form.setFieldsValue({ ssort : this.ssort })
        })
      }
    },
    methods: {
      onSearch() {
        this.tableParams = Object.assign({}, this.tableParams, {
          keyword: this.keyword,
        });
        this.$refs.manageTable.loadTableData();
      },
      onChangeSort(value){
          // console.log(value)
          this.sort = value;
          // console.log(this.form.getFieldsValue());
          this.tableParams = Object.assign({}, this.tableParams, {tsort:this.sort});
          this.$refs.manageTable.loadTableData();
      },
      onChangeSSort(value){
        this.ssort = value;
        this.tableParams = Object.assign({}, this.tableParams, {ssort:this.ssort});
        this.$refs.manageTable.loadTableData();
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
