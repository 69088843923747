<template>
  <a-form class="ng-form-style ng-filter" :form="form" layout="inline">
    <a-form-item label="授权地域">
      <a-select
        :options="AuthAreaOptions"
        v-decorator="['auth_area', {initialValue: defaultData.auth_area || ''}]"
        :style="{width: '120px'}"
      ></a-select>
    </a-form-item>

    <a-form-item label="授权场景">
      <a-select
        :options="BusinessSceneOptions"
        v-decorator="['auth_scene_ids', {initialValue: defaultData.auth_scene_ids || ''}]"
        :style="{width: '120px'}"
      ></a-select>
    </a-form-item>

    <a-form-item label="售卖状态">
      <a-select
        :options="SellOperateOptions"
        v-decorator="['status', {initialValue: defaultData.status || ''}]"
        :style="{width: '120px'}"
      ></a-select>
    </a-form-item>

    <a-form-item label="曲库名称">
      <form-library
        all
        placeholder="请输入曲库名称"
        v-decorator="['lib_id', {initialValue: defaultData.lib_id && Array.isArray(defaultData.lib_id) ? defaultData.lib_id : ['']}]"
        :style="{width: '200px'}"
      ></form-library>
    </a-form-item>

    <a-form-item label="速度范围">
      <form-min-max
        v-decorator="['bpm', {initialValue: defaultData.bpm || '', trigger: 'blur'}]"
        :style="{width: '170px'}"
      ></form-min-max>
    </a-form-item>

    <a-form-item label="时长范围">
      <form-min-max
        v-decorator="['duration', {initialValue: defaultData.duration || '', trigger: 'blur'}]"
        :style="{width: '170px'}"
      ></form-min-max>
    </a-form-item>

    <a-form-item label="比特率">
      <form-min-max
        v-decorator="['bitrate', {initialValue: defaultData.bitrate || '', trigger: 'blur'}]"
        :style="{width: '170px'}"
      ></form-min-max>
    </a-form-item>

    <a-form-item label="采样率">
      <form-min-max
        v-decorator="['samples', {initialValue: defaultData.samples || '', trigger: 'blur'}]"
        :style="{width: '170px'}"
      ></form-min-max>
    </a-form-item>

    <a-form-item label="热度">
      <a-select
        :options="FilterMusicOptions"
        v-decorator="['sort_type', {initialValue: defaultData.sort_type || ''}]"
        :style="{width: '120px'}"
      ></a-select>
    </a-form-item>

    <a-form-item label="排序">
      <a-select
        :options="SortOptions"
        v-decorator="['sort', {initialValue: defaultData.sort || ''}]"
        :style="{width: '120px'}"
      ></a-select>
    </a-form-item>

    <div style="min-height: 40px;">
      <template v-for="(item, index) in labelList">
        <a-form-item :label="item.name" :key="item.cate_id">
          <a-cascader
            :field-names="{ label: 'name', value: 'label_id', children: 'child' }"
            :options="setAllOptions(item.child)"
            placeholder="请选择"
            expandTrigger="hover"
            changeOnSelect
            v-decorator="[`label_ids[${index}]`, {initialValue: setDefaultValue(item.child, defaultData.label_ids, index)}]"
            :style="{width: '120px'}"
          />
        </a-form-item>
      </template>
    </div>
  </a-form>
</template>

<script>
  import {FormLibrary} from '@/components/index';
  import {Cascader} from 'ant-design-vue';
  // import FormLabelsCascade from '../components/FormLabelsCascade.vue'

  import {getAllValueAccrodingFinal} from '@/utils/function';

  let SEPERATE_SIGN = ',';

  import {
    AuthAreaOptions,
    AddDefaultToOptions,
    BusinessSceneOptions,
    SellOperateOptions,
    FilterMusicOptions,
    SortOptions
  } from '@/config/options'

  export default {
    data() {
      return {
        AuthAreaOptions: AddDefaultToOptions(AuthAreaOptions, '全部'),
        SellOperateOptions: AddDefaultToOptions(SellOperateOptions, '全部'),
        BusinessSceneOptions: AddDefaultToOptions(BusinessSceneOptions, '全部'),
        FilterMusicOptions: AddDefaultToOptions(FilterMusicOptions, '全部'),
        SortOptions: AddDefaultToOptions(SortOptions, '全部'),
        labelList: [],
      }
    },

    props: {
      type: String,
      defaultData: {
        type: Object,
        default() {
          return {}
        }
      },
    },

    components: {
      FormLibrary,
      ACascader: Cascader
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        onValuesChange: (props, values) => {
          // 设置label_ids 选中数据
          if (values.label_ids) {
            let {label_ids: qlabel = []} = this.$route.query;

            let slabel = values.label_ids;
            let len = slabel.length;
            let siglabel = slabel[len - 1];
            let cLabel = Array.isArray(siglabel) ? siglabel[siglabel.length - 1] : '';

            // 如果搜索框中有，需要组合拼接
            if (typeof qlabel === 'string') {
              qlabel = qlabel.split(SEPERATE_SIGN);
            }

            qlabel[len - 1] = cLabel || '';

            qlabel.length <= 1 && (qlabel = qlabel.concat(''));

            values.label_ids = qlabel.join(SEPERATE_SIGN);
          }

          values = Object.assign(values, {page: 1});

          this.$root.$goSearch(values);

          this.$emit('change', values);
        }
      });
    },

    created() {
      this.getData({type: this.$val.MarkTargetVal.music});
      
    },

    methods: {
      onChange(value) {
        console.log(value);
      },

      getData(params) {
        this.$axios.GetCommonLabels(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            let list = data.data.list;

            this.labelList = list.map(item => item);
          }
        })
      },

      setDefaultValue(list, ids, index) {
        let value = ids;

        if (typeof ids === 'string' && ~ids.indexOf(SEPERATE_SIGN)) {
          value = value.split(SEPERATE_SIGN)[index] || '';
        }

        let final = getAllValueAccrodingFinal(list, value, 'label_id');

        return final.length > 0 ? final : [undefined];
      },

      setAllOptions(list = [], keyname = 'name', idName = 'lib_id') {
        return [
          {[keyname]: '全部', [idName]: ''},
          ...list
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  .ng-filter {
  }
</style>
