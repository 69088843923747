<template>
  <main-structure class="ng-verify-detail ng-detail" dir="ver" :loading="loading">
    <template slot="header">
      <ng-breadcrumb :data="breadData"></ng-breadcrumb>
    </template>
    <div class :key="curTabId">
      <a-tabs v-model="currentTabIndex" :default-active-key="$val.DeedType.collect">
        <a-tab-pane :key="$val.DeedType.collect" tab="用户收藏">
          <deeds-list :type="$val.DeedType.collect"></deeds-list>
        </a-tab-pane>

        <a-tab-pane :key="$val.DeedType.tryListen" tab="试听&下载">
          <deeds-list :type="$val.DeedType.tryListen"></deeds-list>
        </a-tab-pane>

        <a-tab-pane :key="$val.DeedType.share" tab="用户分享">
          <deeds-list :type="$val.DeedType.share"></deeds-list>
        </a-tab-pane>
      </a-tabs>
    </div>
  </main-structure>
</template>

<script>
  import {NgBreadcrumb} from '@/components/index';

  const breadData = [
    {breadcrumbName: `用户管理`, path: `/user/manage`},
    {breadcrumbName: `用户行为`}
  ]

  export default {
    data() {
      return {
        name: `user_deeds`,
        breadData,
        user_id: '0',
        loading: false,
        currentTabIndex: this.$val.DeedType.collect,

        reload: true,

        curTabId: 0,
      }
    },
    components: {
      NgBreadcrumb,
      DeedsList: () => import('./deeds/DeedsList.vue'),
    },

    created() {
      let user_id = this.$route.params.user_id || '0';

      this.user_id = user_id;
    },

    methods: {

      handleFunc() {

      },

      reloadTab() {
        this.curTabId++;
      },
    }
  }
</script>

<style lang="scss" scoped>
  .ng-verify-detail {
    .tab-box {
      padding: 0 20px;

      /deep/ .ant-tabs-content {
        // padding: 40px 0;
      }
    }
  }
</style>
