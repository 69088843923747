/**
 * 音乐管理
 */
import {
  Axios
} from '@/utils/from-common';

// 编辑音乐名称
export const Music_editName = (param) => Axios('POST', '/bms/music/saveName', param);

// 删除音乐
export const Music_del = (param) => Axios('POST', '/bms/music/del', param);

// 修改音效类型
export const Music_editSoundType = (param) => Axios('POST', '/bms/music/saveSoundType', param);

// 修改音乐封面
export const Music_editMusicLogo = (param) => Axios('POST', '/bms/music/saveLogo', param);

// 修改音乐状态 上/下架
export const Music_changeStatus = (param) => Axios('POST', '/bms/music/upSale', param);

/** *************  入库 音乐管理 start  ************ */
//  音乐列表
export const Enterlibrary_musicList = (param) => Axios('POST', '/bms/task/storedMusic', param);

//  修改音乐
export const Enterlibrary_musicEdit = (param) => Axios('POST', '/bms/task/saveStoredMusic', param);

//  删除音乐
// export const Enterlibrary_musicDel = (param) => Axios('POST', '/bms/music/del', param);
/** *************  入库 音乐管理 end  ************ */

/** *************  音乐管理 start  ************ */
// 音乐管理 列表
export const MusicManage_list = (param) => Axios('POST', '/bms/music/list', param);

// 音乐管理 音乐详情 标注详情
export const MusicManage_musicInfo = (param) => Axios('POST', '/bms/music/info', param);

// 音乐管理 修改标注详情
export const MusicManage_changeTags = (param) => Axios('POST', '/bms/music/saveLabels', param);

// 音乐管理 修改音乐排序
export const MusicManage_ChangeSort = (param) => Axios('POST', '/bms/music/saveSort', param);

/** *************  音乐管理 end  ************ */