<template>
  <a-modal
    width="640px"
    v-model="show"
    title="曲库合作签约"
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    okText="签约并通知用户"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div style="height: 500px;overflow-y: auto;">
      <coop-group ref="coopGroup"></coop-group>
    </div>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import CoopGroup from '@/pages/library/coop/CoopGroup.vue';

  export default {
    data() {
      return {
        show: false,

        confirmLoading: false,

        defaultData: {},
      }
    },

    props: {
      handle: String,
      defaultValue: {
        type: Object,
        default() {
          return {}
        }
      },
      apply_id: String,
    },

    components: {
      CoopGroup
    },

    mixins: [dialogMixins],

    methods: {

      handleOk() {
        if (!this.isLook) {
          this.$refs.coopGroup.handleOk().then(value => {
            value.apply_id = this.apply_id

            this.submit(value);
          });
        } else {
          this.show = false;
        }
      },

      submit(params) {
        this.confirmLoading = true;

        this.$axios.Ver_LibrarySign(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('签约成功');
            this.$emit('success', data);
          }

          this.confirmLoading = false;
        }).catch(() => {
          this.confirmLoading = false;
        })
      },

      handleCancel() {
        this.show = false;
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
