<template>
  <a-form class="ng-form-style ng-filter" :form="form" layout="inline">
    <a-form-item label="标注时间">
      <a-range-picker
        v-decorator="['time', {initialValue: defaultData.time}]"
        valueFormat="YYYY-MM-DD"
        style="width: 250px;"
      ></a-range-picker>
    </a-form-item>

    <a-form-item label="标注结果">
      <a-select
        :options="MarkResultOptions"
        v-decorator="['status', {initialValue: defaultData.status || ''}]"
        style="width: 80px;"
      ></a-select>
    </a-form-item>

    <a-form-item label="标注人">
      <form-member-enter-library
        all
        class="w-px-100"
        placeholder="请选择标注人"
        v-decorator="[`charger_id`, {initialValue: defaultData.charger_id || ''}]"
        style="width: 120px;"
      ></form-member-enter-library>
    </a-form-item>

    <a-form-item label>
      <a-checkbox
        v-decorator="[`is_hardcase`, {initialValue: defaultData.is_hardcase == '1', valuePropName: 'checked'}]"
      >是否难例</a-checkbox>
    </a-form-item>
  </a-form>
</template>

<script>
  import {DatePicker} from 'ant-design-vue';
  import FormMemberEnterLibrary from '@/components/form/FormMemberEnterLibrary.vue';
  import {MarkResultOptions, AddDefaultToOptions} from '@/config/options';

  export default {
    data() {
      return {
        MarkResultOptions: AddDefaultToOptions(MarkResultOptions, '全部'),
      }
    },

    props: {
      type: String,
      defaultData: Object,
    },


    components: {
      FormMemberEnterLibrary,
      ARangePicker: DatePicker.RangePicker
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        onValuesChange: (props, values) => {
          if (values.is_hardcase !== undefined) {
            values.is_hardcase = Number(!!values.is_hardcase)
          }

          this.$root.$goSearch(values);

          this.$emit('change', values);
        }
      });
    },

    methods: {
    }
  }
</script>

<style lang="scss" scoped>
  .ng-filter {
  }
</style>
