<template>
  <div>
    <ng-table
      class="ng-table"
      :columns="columns"
      :params="params"
      :rowKey="(row) => row.task_id"
      :request="$axios.EnterlibraryTask_servingList"
      :load.sync="loadTable"
      :reload.sync="reloadTable"
      :scroll="{ y: scrollyHeight - 20 }"
    >
      <!-- 头像 -->
      <template slot="lib_logo_url" slot-scope="text, scope">
        <ng-image
          class="table-header"
          :src="
            scope.lib_logo_url
              ? `${scope.lib_logo_url}?x-oss-process=style/thumb_10`
              : $var.defaultHeader
          "
          @error="({ target }) => (target.src = $var.defaultHeader)"
        />
      </template>

      <template slot="current_progress" slot-scope="text, scope">
        <span>{{ scope.done_music_num }} / {{ scope.music_num }}</span>
      </template>

      <template slot="priority" slot-scope="text">
        <span v-html="$options.filters.toLevelName(text)"></span>
      </template>

      <template slot="operation" slot-scope="text, scope">
        <a-button
          v-if="$root.$checkAuth($auth[authName] && $auth[authName].uploadMusic)"
          class="m-right-ms"
          type="link"
          @click="goSubmitMusic(scope)"
          :disabled="!scope.op_upload_music"
        >上传音乐</a-button>

        <router-link
          v-if="$root.$checkAuth($auth[authName] && $auth[authName].lookMusic)"
          class="ant-btn-link m-right-ms"
          :to="`/libraryenter/musicmanage/${scope.task_id}/${scope.music_type}/${$var.Common.first}`"
        >查看</router-link>

        <a-button
          v-if="$root.$checkAuth($auth[authName] && $auth[authName].deliver)"
          class="m-right-ms"
          type="link"
          @click="goDeliever(scope)"
          :disabled="!scope.op_deliver"
        >交付</a-button>
      </template>
    </ng-table>

    <dialog-submit-music
      :visible.sync="showDialog"
      :task_id="currentData.task_id"
      @success="onSuccess"
    ></dialog-submit-music>
  </div>
</template>

<script>
  import DialogSubmitMusic from './DialogSubmitMusic.vue'
  import {tableMixins} from '@/mixins';

  import * as TabCol from '@/config/columns';

  const columns = [
    TabCol.colId,
    TabCol.portrait('封面', 'lib_logo_url'),
    TabCol.commonKey('曲库名称', 'lib_name', {ellipsis: true}),
    TabCol.commonKey('曲库简称', 'lib_short_name'),
    TabCol.commonKey('所属供应商', 'suplr_name', {ellipsis: true}),

    TabCol.commonKey('任务进度', 'current_progress', '', true),
    TabCol.commonKey('入库状态', 'priority', false, true),

    TabCol.operation('180px')
  ]

  export default {
    data() {
      return {
        columns,
        tableList: [],
        loading: false,
        // params: {},

        currentData: {},

        showDialog: false,
        // reloadTable: false,
      }
    },

    props: {
      authName: String,
      scrollyHeight: {
        type: Number,
        default: 400
      },
      params:Object,
    },

    mounted() {
      // this.params = {page: this.$route.query.page || 1};
    },

    mixins: [
      tableMixins
    ],

    components: {
      DialogSubmitMusic
    },

    methods: {

      onSuccess() {
        this.loadTableData();
      },

      goSubmitMusic(scope) {
        this.currentData = scope;
        this.showDialog = true;
      },


      goDeliever(scope) {
        let vm = this;

        this.$confirm({
          width: '480px',
          title: '确认交付？',
          onOk() {
            return new Promise((resolve, reject) => {
              vm.deliever(scope, resolve, reject);
            });
          }
        });
      },

      deliever(scope, next, reject) {
        this.$axios.EnterlibraryTask_pushTask({task_id: scope.task_id}).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('交付成功');
            next();

            setTimeout(() => {
              this.loadTableData();
            }, 500);
          } else {
            reject();
          }
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .ant-table td {
    white-space: nowrap;
  }
</style>
