var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ng-table',{staticClass:"ng-table",attrs:{"columns":_vm.columns,"params":_vm.params,"rowKey":function (row) { return row.playlist_id; },"request":_vm.$axios.Song_list,"load":_vm.loadTable,"reload":_vm.reloadTable,"scroll":{y: _vm.scrollyHeight - 20}},on:{"update:load":function($event){_vm.loadTable=$event},"update:reload":function($event){_vm.reloadTable=$event},"data-change":_vm.requestSuccess},scopedSlots:_vm._u([{key:"profile_photo",fn:function(text, scope){return [_c('ng-image',{staticClass:"table-header",attrs:{"src":scope.logo_url ? ((scope.logo_url) + "?x-oss-process=style/thumb_10") : _vm.$var.defaultHeader},on:{"error":function (ref) {
	var target = ref.target;

	return target.src = _vm.$var.defaultHeader;
}}})]}},{key:"name",fn:function(text, scope){return [_c('router-link',{attrs:{"to":("/songlistdetail/" + (scope.playlist_id))}},[_vm._v(_vm._s(text))])]}},{key:"sale_status",fn:function(text, scope){return [(_vm.$root.$checkAuth(_vm.$auth[_vm.authName] && _vm.$auth[_vm.authName].status))?_c('table-switch',{attrs:{"options":text >= 0 ? _vm.SellOperateOptions.filter(function (item) { return item.value >= 0; }) : _vm.SellOperateOptions,"default-value":parseInt(text) || 0,"format":{id: 'value', name: 'label'},"change":function() {
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
 _vm.onChangeSwitch.apply(void 0, argsArray.concat( [scope] )) }}}):_c('span',{class:text == 0 ? 'font-error' : 'font-success'},[_vm._v(_vm._s(_vm._f("toSellStatus")(text)))])]}},{key:"operation",fn:function(text, scope){return [(_vm.$root.$checkAuth(_vm.$auth[_vm.authName] && _vm.$auth[_vm.authName].discount))?_c('router-link',{staticClass:"ant-btn-link m-right-ms",attrs:{"to":("/songlistDiscount/" + (scope.playlist_id))}},[_vm._v("配置折扣")]):_vm._e(),(_vm.$root.$checkAuth(_vm.$auth[_vm.authName] && _vm.$auth[_vm.authName].edit))?_c('a-button',{staticClass:"m-right-ms",attrs:{"type":"link"},on:{"click":function($event){return _vm.handleFunc(_vm.$var.Handle.edit, scope)}}},[_vm._v("编辑")]):_vm._e(),(_vm.$root.$checkAuth(_vm.$auth[_vm.authName] && _vm.$auth[_vm.authName].del))?_c('a-button',{staticClass:"ant-btn-error font-error",attrs:{"type":"link"},on:{"click":function($event){return _vm.onDelete(scope)}}},[_vm._v("删除")]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }