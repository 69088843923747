<template>
  <ng-table
    class="ng-table"
    :columns="columns"
    :params="params"
    :rowKey="row => row.goods_id"
    :request="$axios.Good_list"
    :load.sync="loadTable"
    :reload.sync="reloadTable"
    :scroll="{y: scrollyHeight - 20}"
    @data-change="requestSuccess"
  >
    <template slot="auth_area" slot-scope="text">
      <span>{{text | toArea}}</span>
    </template>

    <template slot="auth_time" slot-scope="text">
      <span>{{text | toAuthPeriod}}</span>
    </template>

    <template slot="auth_scene" slot-scope="text, scope">
      <span>{{scope.auth_scene | toAuthScene}}：</span>
      <span>{{scope.use_mode || ''}}</span>
    </template>

    <template slot="status" slot-scope="text, scope">
      <table-switch
        :options="text >= 0 ? SellOperateOptions.filter(item => item.value >= 0) : SellOperateOptions"
        :default-value="parseInt(text) || 0"
        :format="{id: 'value', name: 'label'}"
        :change="(...arr) => {onChangeSwitch(scope, ...arr)}"
      ></table-switch>
    </template>

    <template slot="is_price" slot-scope="text">
      <span>{{text | toPriceStatus}}</span>
    </template>

    <template slot="operation" slot-scope="text, scope">
      <a-button
        v-if="$root.$checkAuth($auth[authName] && $auth[authName].editGood)"
        class="m-right-ms"
        type="link"
        @click="handleFunc($var.Handle.edit, scope)"
      >编辑</a-button>

      <template v-if="$root.$checkAuth($auth[authName] && $auth[authName].customePriceInfo)">
        <a-button
          v-if="scope.is_price == $val.CommonCheck.fail"
          type="link"
          @click="setPay(scope)"
        >配置售价</a-button>
        <router-link
          v-else
          :to="`/goods/sellway?goods_id=${scope.goods_id}&goods_type=${$val.Good_type.good}&done_price=${scope.is_price}`"
        >配置售价</router-link>
      </template>
    </template>
  </ng-table>
</template>

<script>
  import {tableMixins} from '@/mixins';

  import {AuthAreaOptions, BusinessSceneOptions, SellOperateOptions, SellStatusOptions, PriceStatue, AuthTimeOptions} from '@/config/options';
  import {toOptionName} from '@/utils/function';

  import * as TabCol from '@/config/columns';

  const columns = [
    TabCol.colId,
    TabCol.commonKey('商品名称', 'name'),
    TabCol.commonKey('授权场景&使用方式', 'auth_scene', 220, true),
    TabCol.commonKey('授权期限', 'auth_time', null, true),
    TabCol.commonKey('授权地域', 'auth_area', null, true),
    TabCol.commonKey('有效期限/天', 'valid_time', {align: 'center'}),
    TabCol.commonKey('商品权益/首', 'auth_num', {align: 'center'}),
    TabCol.commonKey('定价状态', 'is_price', '', true),
    TabCol.commonKey('售卖状态', 'status', '120px', true),
    TabCol.operation('120px'),
  ];

  export default {
    data() {
      return {
        columns,
        tableList: [],
        SellOperateOptions,
        handleData: {},
        params: {},
      }
    },

    props: {
      authName: String,
      scrollyHeight: {
        type: Number,
        default: 400
      },
      reload: Boolean,
      cate_id: String,
    },

    mounted() {
      let cate_id = this.cate_id;
      let params = {page: this.$route.query.page || 1};

      cate_id && (params.cate_id = cate_id || '')

      this.params = params;
    },

    filters: {
      toArea(value) {
        return toOptionName(AuthAreaOptions, value);
      },

      toAuthScene(value) {
        return toOptionName(BusinessSceneOptions, value);
      },

      toSellStatus(value) {
        return toOptionName(SellStatusOptions, value);
      },

      toPriceStatus(value) {
        return toOptionName(PriceStatue, value);
      },

      toAuthPeriod(value) {
        return toOptionName(AuthTimeOptions, value);
      }
    },

    mixins: [
      tableMixins
    ],

    methods: {

      requestSuccess(data) {
        this.tableList = data.data.list ? data.data.list : [];
      },

      handleFunc(key, scope) {
        this.$emit('handle', Object.assign(scope, {
          handleName: key
        }))
      },

      setPay(scope) {
        let obj = Object.assign({}, scope, {
          goods_type: this.$val.Good_type.good,
        })

        this.$emit('goPay', obj);
      },

      onChangeSwitch(scope, key, options, next, loading) {
        loading(true);

        let param = {
          goods_id: scope.goods_id,
          status: key
        }

        this.$axios.Good_status(param).then(res => {
          let data = res.data;

          loading(false);

          if (data && data.code == 0) {
            next();

            key == 1 ? this.$message.success('已上架') : this.$message.error('已下架');

            this.loadTableData();
          }
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .ant-table td {
    white-space: nowrap;
  }
</style>
