<template>
  <div class="nodify-form-wrap">
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" class="ng-form">
      <template v-for="(item, index) in formList">
        <!-- 头像 -->
        <a-form-item
          v-if="item.key === 'profile_photo'"
          :key="index"
          :wrapper-col="{ span: 16, offset: 5 }"
        >
          <div class="flex flex-column cen-center">
            <form-upload
              class="upload-box"
              accept="image/jpg, image/jpeg, image/png"
              list-type="picture-card"
              :action="$axios.UploadFile"
              :data="uploadParams"
              :name="uploadName"
              circle
              @uploading="onUploading"
              :max-size="$config.UploadLicenseMaxSize"
              v-decorator="[item.key, { initialValue: '' }]"
              :key="index"
              :remove-icon="true"
              :init-header-url="defaultHeaderUrl"
            >
              <svg class="iconfont" slot="icon">
                <use xlink:href="#icontouxiang" />
              </svg>
            </form-upload>

            <div>大小 5M 以内，支持格式 .jpg .jpeg .png</div>
          </div>
        </a-form-item>

        <!-- 性别 -->
        <a-form-item v-else-if="item.key === 'sex'" :key="index" :label="item.name">
          <a-select
            :options="SexOptions"
            size="large"
            :placeholder="item.placeholder"
            v-decorator="[item.key, { rules: [$validate.required] }]"
          ></a-select>
        </a-form-item>

        <!-- 地区 -->
        <a-form-item v-else-if="item.key === 'address_id'" :key="index" :label="item.name">
          <form-country
            size="large"
            changeOnSelect
            expandTrigger="hover"
            :rank="type === $var.SupplierType.COMPANY ? 'third' : 'first'"
            :placeholder="item.placeholder"
            v-decorator="[item.key, { rules: [$validate.required] }]"
          ></form-country>
        </a-form-item>

        <!-- 描述 -->
        <a-form-item v-else-if="item.key === 'desc'" :key="index" :label="item.name">
          <ng-textarea
            size="large"
            showCounter
            :max-length="500"
            :placeholder="item.placeholder"
            v-decorator="[item.key, { initialValue: '' }]"
            style="height: 160px;"
          ></ng-textarea>
        </a-form-item>

        <!-- 输入框 -->
        <a-form-item v-else :key="index" :label="item.name">
          <a-input
            size="large"
            :placeholder="item.placeholder"
            v-decorator="[item.key, { rules: [$validate.required], initialValue: '', trigger: 'blur' }]"
          />
        </a-form-item>
      </template>
    </a-form>
  </div>
</template>
<script>
  import {FormUpload} from '@/components/index';
  import FormDir from './keyDir';
  import {Handle} from '@/utils/variables';
  import {SexOptions} from '@/config/options'

  export default {
    data() {
      return {
        FormDir: [],
        allDisabled: false,
        form: this.$form.createForm(this, "nodify"),

        uploading: false,
        uploadParams: {},
        uploadName: 'file',

        defaultHeaderUrl: '',

        SexOptions,
      };
    },

    props: {
      user_id: String,
      type: String,
      data: Object,
      handle: {
        type: String,
        default: Handle.add
      }
    },

    components: {
      FormUpload
    },

    computed: {
      formList() {
        return FormDir[this.type] || [];
      }
    },

    created() {
      this.uploadParams = {
        upload_type: 1,
        field_name: this.uploadName
      }
    },

    mounted() {
      if (this.handle === this.$var.Handle.edit) {
        this.$nextTick(() => {
          this.initFields();
          this.defaultHeaderUrl = this.data.profile_photo_url;
        })
      }
    },

    methods: {
      onUploading(uploading) {
        this.uploading = uploading;
      },
      initFields() {
        // let data = this.$toFormType(this.data);
        this.form.setFieldsValue(this.data);
      },
    }
  };
</script>
<style lang="scss" scoped>
  .nodify-form-wrap {
    width: 100%;
    .ant-form-item-label {
      margin-right: 20px !important;
    }
    .ant-form-item-control-wrapper {
      margin-left: 20px;
    }
  }

  .upload-box {
    // display: block;
    // width: 80px;
    // height: 80px;
    // border-radius: 100%;
    // overflow: hidden;

    /deep/ .ant-upload {
      border: 0;
      width: 80px;
      height: 80px;
      padding: 0;
      background-color: #ddd;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);

      img[alt="avatar"] {
        width: 100%;
        height: 100%;
      }

      .close-icon {
        transform: translate(10px, 0px);
      }
    }

    .iconfont {
      width: 60%;
      height: 60%;
    }
  }

  .ant-upload-picture-card-wrapper {
    width: auto;
  }
</style>