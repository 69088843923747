/**
 * 账号权限管理
 */
import {
  Axios
} from '@/utils/from-common';


/* ***************  批次管理  ************** */
// 合约列表
export const Contract_list = (param) => Axios('POST', '/bms/contract/list', param);

// 新增合约
export const Contract_add = (param) => Axios('POST', '/bms/contract/sign', param);

// 合约详情
export const Contract_info = (param) => Axios('POST', '/bms/contract/signInfo', param);

