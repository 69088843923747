<template>
  <div class="music-list-item">
    <div class="cursor-pointer flex" @click="() => {goPlayMusic(item)}">
      <div class="thumb-nail">
        <ng-image v-if="item.logo_url" class="w-100 h-100" :src="item.logo_url" alt="thumbnail" />
      </div>

      <div class="music-info flex-1">
        <div class="ellipsis">
          <b class="m-right-base">{{item.name || ''}}</b>
          <b class="font-grey">by {{item.suplr_name || ''}}</b>
        </div>
        <div class="amplitude">
          <span class="time">{{item.duration}}</span>
          <music-wave :music_id="item.music_id"></music-wave>
          <span class="date">{{item.create_at}}</span>
        </div>
        <div class="tag-group w-100" @click.stop="onClickTags">
          <template v-for="(item, index) in item.label_names">
            <a-tag :key="index">{{item}}</a-tag>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {MusicWave} from '@/components/index';
  import {gMusicMixins} from '@/mixins/index'

  export default {
    data() {
      return {
      }
    },

    props: {
      item: {
        type: Object,
        default() {
          return {}
        }
      },
    },

    mixins: [gMusicMixins],

    components: {
      MusicWave,
    },

    methods: {
      onClickTags() {
      },

      goPlayMusic(scope) {
        this.$store.commit('changeVideoPlaying', false);
        this.$store.commit('changeVideoInfo', this.toggleListKey(scope));

        this.$nextTick(() => {
          setTimeout(() => {
            this.$store.commit('changeVideoPlaying', true);
          }, 500);
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  $item-height: 113px;

  .music-list-item {
    border: 1px solid #efefef;
    padding: 10px 20px;
    border-radius: 5px;

    > div {
      height: $item-height;
    }

    .music-info {
      margin-right: 25px;

      .tag-group {
        .ant-tag {
          margin-bottom: 10px;
        }
      }
    }

    .amplitude {
      position: relative;
      width: 100%;
      height: 40px;
      padding-left: 55px;
      padding-right: 140px;
      margin: 15px 0;

      .time {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      .date {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .thumb-nail {
      width: 110px;
      height: 110px;
      background: #f2f2f5;
      box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
      border-radius: 4px;
      margin-right: 20px;
      overflow: hidden;
    }

    .music-operation {
      position: relative;
      width: 140px;
    }

    .operation-box {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    .shop-cart {
      width: 40px;
      height: 40px;
      background: #171718;
      border-radius: 100%;
      cursor: pointer;
      color: #fff;
      line-height: 45px;
      text-align: center;

      .iconfont {
        width: 20px;
        height: 40px;
      }
    }

    .operation {
      .iconfont {
        width: 22px;
        font-size: 18px;
        margin-right: 35px;
        color: #000;
        font-weight: bold;
        cursor: pointer;

        &:hover {
          opacity: 0.9;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
</style>
