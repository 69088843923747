<template>
  <a-form class="w-100 ng-form" :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }">
    <a-form-item label="商品名称">
      <ng-input
        :maxLength="10"
        show-counter
        placeholder="请输入商品名称"
        v-decorator="[ 'name', {rules: [$validate.required], initialValue: '', validateTrigger: 'blur'}]"
      ></ng-input>
    </a-form-item>

    <a-form-item label="商品简介">
      <ng-textarea
        placeholder="请输入商品简介"
        :maxLength="100"
        show-counter
        v-decorator="['desc', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur' }]"
        style="height: 120px"
      ></ng-textarea>
    </a-form-item>

    <a-form-item class="m-bottom-0 line-height-inherit" required layout="inline" label="授权场景">
      <a-row>
        <a-col :span="14">
          <a-form-item>
            <a-select
              :options="AuthSceneOtions"
              placeholder="请选择商品支持的授权场景"
              v-decorator="[`auth_scene`,  { rules: [$validate.required] }]"
            ></a-select>
          </a-form-item>
        </a-col>
        <a-col :span="1" class="text-center" style="line-height: 30px;">-</a-col>
        <a-col :span="9">
          <a-form-item>
            <!-- <form-scene-way
              placeholder="请选择使用方式"
              :scene_type="form.getFieldValue('auth_scene')"
              @changeData="onChangeSceneWay"
              v-decorator="[`use_mode`,  { rules: [$validate.required] }]"
            ></form-scene-way>-->
            <a-select
              placeholder="请选择商品支持的授权场景"
              :options="useModeOptions"
              v-decorator="[`use_mode`,  { rules: [$validate.required] }]"
            ></a-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form-item>

    <!-- 授权类型 -->
    <a-form-item required class="form-item-lh-20" label="授权期限">
      <a-radio-group :options="AuthTimeOptions" v-decorator="['auth_time']"></a-radio-group>
    </a-form-item>

    <!-- 授权地域 -->
    <a-form-item required class="form-item-lh-20" label="授权地域">
      <a-radio-group :options="AuthAreaOptions" v-decorator="['auth_area']"></a-radio-group>
    </a-form-item>
  </a-form>
</template>
<script>
  import {FormAdd, FormSceneWay} from '@/components/index';
  import {AuthAreaOfMusicOptions, AuthTimeOptions, BusinessSceneOptions} from '@/config/options'
  import * as FunctionCommon from '@/utils/function'

  export default {
    data() {
      return {
        AuthSceneOtions: [],
        AuthAreaOptions: [],
        AuthTimeOptions: [],
        useModeOptions: [],

        authSceneDefaultValue: [],
        uploadFilesDefaultValue: [],

        PayWayList: []
      };
    },

    props: {
      defaultValue: Object,
      isLook: Boolean,
      goodInfo: {
        type: Object,
        default() {
          return {}
        }
      }
      // authScene: String
    },

    // created() {
    //   this.getCurAuthOptions();
    // },

    watch: {
      goodInfo: {
        handler(newVal) {
          console.log(newVal)
          if (newVal && newVal.auth_scene) {
            let authScene = newVal.auth_scene;
            let cate_id = newVal.cate_id;

            this.AuthSceneOtions = BusinessSceneOptions.filter(item => item.value === authScene);
            this.getCurAuthOptions({cate_id});
          }
        },
        immediate: true
      }
    },

    // computed: {
    //   authAreaAndTime() {
    //     let auth_scene = this.authScene;
    //     let PayWayList = this.PayWayList;

    //     let filterList = PayWayList.filter(item => item.type === auth_scene);

    //     let filterItem = filterList && filterList.length > 0 ? filterList[0] : null;

    //     if (!filterItem) return {area: AuthAreaOfMusicOptions, time: AuthTimeOptions}

    //     let {area, time} = filterItem.auth;

    //     area = area.filter(item => item.is_enabled == '1').map(item => item.type);
    //     time = time.filter(item => item.is_enabled == '1').map(item => item.type);

    //     return {
    //       area: AuthAreaOfMusicOptions.filter(item => ~area.indexOf(item.value)),
    //       time: AuthTimeOptions.filter(item => ~time.indexOf(item.value))
    //     }
    //   }
    // },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        onValuesChange: (props, values) => {
          if (values.auth_scene) {
            // this.user_mode = values.auth_scene;

            this.form.resetFields(`use_mode`)
          }
        }
      });
    },

    components: {
      // FormAdd, 
      FormSceneWay
    },

    methods: {
      // onAuthSceneUpdate(data) {
      //   if (data && data.length > 0) {
      //     let value = FunctionCommon.transferParamFromObjToStr({auth_scene: data});
      //     this.form.setFieldsValue(value);
      //   }
      // },

      onChangeSceneWay(list) {
        this.PayWayList = list;
      },

      getCurAuthOptions(params) {
        this.$axios.Good_cate_scene(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            let dir = data.data ? data.data : {}

            let {use_mode = [], auth_time = [], auth_area = []} = dir;

            auth_time = auth_time.filter(item => item.is_enabled == '1').map(item => item.type);
            auth_area = auth_area.filter(item => item.is_enabled == '1').map(item => item.type);

console.log(auth_time, auth_area)

            this.AuthAreaOptions = AuthAreaOfMusicOptions.filter(item => ~auth_area.indexOf(item.value));
            this.AuthTimeOptions = AuthTimeOptions.filter(item => ~auth_time.indexOf(item.value))
            this.useModeOptions = use_mode.map(({name}) => ({label: name, value: name}))
          }
        })
      },
    }
  };
</script>
<style lang="scss" scoped>
  .upload-box {
    width: auto;

    /deep/ {
      .ant-upload {
        width: 80px;
        height: 80px;
        margin-bottom: 0;
        padding: 0;
        background-color: #f7f4f8;
      }
    }
  }
</style>