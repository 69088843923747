<template>
  <main-structure class="ng-verify-detail ng-detail" dir="ver" :loading="loading">
    <template slot="header">
      <ng-breadcrumb :data="breadData"></ng-breadcrumb>
      <!-- <div class="header-content">
        <a-button type="sub" @click="handleFunc()">编辑资料</a-button>
      </div>-->
    </template>
    <div class="tab-box" v-if="reload">
      <a-tabs v-model="currentTabIndex" default-active-key="1" @change="onChangeTabs">
        <a-tab-pane v-if="$root.$checkAuth($auth[name] && $auth[name].base)" key="1" tab="基础信息">
          <supplier-base-information
            :user_id="user_id"
            :handle="$route.params.handle"
            :auth-name="name"
          ></supplier-base-information>
        </a-tab-pane>

        <a-tab-pane
          v-if="$route.params.type === $var.SupplierType.COMPANY && $root.$checkAuth($auth[name] && $auth[name].linkList)"
          key="2"
          tab="联系人信息"
        >
          <supplier-contact-information
            :user_id="user_id"
            :handle="$route.params.handle"
            :auth-name="name"
          ></supplier-contact-information>
        </a-tab-pane>

        <a-tab-pane v-if="$root.$checkAuth($auth[name] && $auth[name].addressList)" key="3" tab="收件信息">
          <supplier-address-information
            :user_id="user_id"
            :handle="$route.params.handle"
            :auth-name="name"
          ></supplier-address-information>
        </a-tab-pane>

        <a-tab-pane v-if="$root.$checkAuth($auth[name] && $auth[name].receiptList)" key="4" tab="收款信息">
          <supplier-bill-information
            :user_id="user_id"
            :handle="$route.params.handle"
            :auth-name="name"
          ></supplier-bill-information>
        </a-tab-pane>

        <a-tab-pane v-if="$root.$checkAuth($auth[name] && $auth[name].accountInfo)" key="8" tab="账户信息">
          <supplier-user-information
            :user_id="user_id"
            :handle="$route.params.handle"
            :auth-name="name"
          ></supplier-user-information>
        </a-tab-pane>
      </a-tabs>
    </div>
  </main-structure>
</template>

<script>
  import {NgBreadcrumb} from '@/components/index';
  import {SupplierType, SupplierTypeDir} from '@/utils/variables'

  const breadData = function (name = SupplierType.MUSICIAN, SupplierTypeDir, isAdd) {
    return [
      {breadcrumbName: `${SupplierTypeDir[name]}管理`, path: `/supplier/${name}`},
      {breadcrumbName: !isAdd ? `${SupplierTypeDir[name]}详情` : `新增${SupplierTypeDir[name]}`}
    ]
  }

  export default {
    data() {
      return {
        name: `supplier_${this.$route.params.type}_info`,
        // data: {},
        user_id: '0',
        loading: false,
        currentTabIndex: '1',

        reload: true,
      }
    },
    components: {
      NgBreadcrumb,
      SupplierBaseInformation: () => import('./information/basicInformation/index.vue'),
      SupplierAddressInformation: () => import('./information/addressInformation/index.vue'),
      SupplierBillInformation: () => import('./information/billingInformation/index.vue'),
      SupplierContactInformation: () => import('./information/contactInformation/index.vue'),
      SupplierUserInformation: () => import('./information/userInformation/index.vue'),
    },

    computed: {
      breadData() {
        return breadData(this.$route.params.type, SupplierTypeDir, this.$route.params.handle === this.$var.Handle.add)
      }
    },

    watch: {
      $route(to) {
        this.user_id = to.params.user_id;

        this.reloadTab();
      }
    },

    created() {
      let user_id = this.$route.params.user_id || '0';

      this.user_id = user_id;
    },

    methods: {

      handleFunc() {

      },

      reloadTab() {
        this.reload = false;

        setTimeout(() => {
          this.reload = true;
        }, 500);
      },

      onChangeTabs() {
        if (this.$route.params.handle === this.$var.Handle.add) {
          this.currentTabIndex = '1';
          this.$message.warning('请填写基础信息');
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .ng-verify-detail {
    .tab-box {
      padding: 0 20px;

      /deep/ .ant-tabs-content {
        // padding: 40px 0;
      }
    }
  }

  @media screen and (min-width: 1180px) {
    .tab-box {
      width: 800px;
    }
  }

  @media screen and (max-width: 1180px) {
    .tab-box {
      width: 100%;
    }
  }
</style>
