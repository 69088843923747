<template>
  <main-structure class="ng-account-members" dir="ver">
    <template slot="header">
      <span class="title">音乐管理</span>
    </template>

    <template>
      <div class="m-top-ms flex-0">
        <ng-breadcrumb :data="breadData"></ng-breadcrumb>
      </div>

      <div class="m-top-base flex-1" ref="ngTableBox">
        <component
          :is="getComponents($route.params.type)"
          :scrolly-height="mainContentScrollMaxHeight"
          :auth-name="name"
          ref="manageTable"
        ></component>
      </div>
    </template>
  </main-structure>
</template>

<script>
  import TableMusicBgm from './enterMusicManage/TableMusicBgm.vue';
  import TableMusicRs from './enterMusicManage/TableMusicRs.vue';
  import TableMusicYx from './enterMusicManage/TableMusicYx.vue';

  import {mainContentMixin} from '@/mixins';
  import {Common} from '@/utils/variables';
  import {MusicType} from '@/config/options.value';

  // 根据跳转的原页面判断面包屑路由路径
  const pathDir = {
    [Common.first]: {
      name: '入库任务',
      path: '/libraryenter/task/serving', // 入库任务 服务中 查看
    },
    [Common.second]: {
      name: '入库任务',
      path: '/libraryenter/task/servedone', // 入库任务 已完成 查看
    },
    [Common.third]: {
      name: '入库管理',
      path: '/libraryenter/manage/serving', // 入库管理 服务中 进度 查看
    },
  }

  const componentDir = {
    [MusicType.BGM]: 'TableMusicBgm',
    [MusicType.RS]: 'TableMusicRs',
    [MusicType.YX]: 'TableMusicYx',
  }

  const breadData = function (from) {
    let curPath = pathDir[from], dPath = pathDir[Common.first];

    return [
      {breadcrumbName: curPath.name || dPath.name, path: curPath.path || dPath.path},
      {breadcrumbName: '音乐管理'}
    ]
  }

  export default {
    data() {
      return {
        name: `musicManage_${this.$route.params.from}`,
        breadData: breadData(this.$route.params.from),
        isSuccess: true,
      }
    },

    components: {
      TableMusicBgm, TableMusicRs, TableMusicYx
    },

    mixins: [
      mainContentMixin
    ],

    methods: {
      getComponents(type) {
        return componentDir[type];
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
