<template>
  <div class="detail-main flex flex-column cen-start">
    <template v-if="list && list.length > 0">
      <template v-for="(item, index) in list">
        <ng-card :key="index">
          <div class="detail-content m-bottom-ms">
            <h3>
              <span class="title">{{item.name || '-'}}</span>&nbsp;&nbsp;
              <span class="sub-title font-xs">{{item.position || '-'}}</span>
            </h3>
            <div
              class="m-bottom-xs font-xs"
            >{{`${item.phone_code_item.prefix} - ${item.phone}` || '-'}}</div>
            <div class="m-bottom-ms font-xs">{{item.email || '-'}}</div>
            <div class="font-grey font-xs">{{item.remark || '-'}}</div>
          </div>

          <div v-if="!isLook" class="handle-btns flex" slot="right">
            <a-button
              v-if="$root.$checkAuth($auth[authName] && $auth[authName].editLink)"
              type="link"
              @click="handleItem($var.Handle.edit, item)"
            >修改</a-button>
            <a-button
              v-if="$root.$checkAuth($auth[authName] && $auth[authName].delLink)"
              type="link"
              class="ant-btn-error"
              @click="handleItem($var.Handle.del, item)"
            >删除</a-button>
          </div>
        </ng-card>
      </template>
    </template>
    <template v-else>
      <div class="empty flex cen-center">暂无联系人信息</div>
    </template>
  </div>
</template>
<script>
  import {NgCard} from '@/components/index';

  export default {
    data() {
      return {
      }
    },

    props: {
      list: Array,
      isLook: Boolean,
      authName: String
    },

    components: {
      NgCard
    },
    methods: {

      handleItem(type, item) {
        let vm = this;

        if (type === this.$var.Handle.del) {
          this.$confirm({
            width: '480px',
            title: '确认要删除该地址信息吗？',
            // content: '删除该地址信息',
            onOk() {
              return new Promise((resolve, reject) => {
                vm.onDel(item, resolve, reject);
              });
            }
          });
        } else if (type === this.$var.Handle.edit) {
          this.$emit('onEdit', item);
        }
      },

      onDel(item, next, reject) {
        let params = {
          contact_id: item.contact_id
        };

        this.$axios.Sup_Contact_del(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('删除成功');

            next();

            this.$emit('success', data.data);
          } else {
            reject()
          }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
</style>