<template>
  <ng-table
    class="ng-table"
    :columns="columns"
    :params="params"
    :rowKey="row => row.contract_id"
    :request="$axios.BuyManage_contractList"
    :load.sync="loadTable"
    :reload.sync="reloadTable"
    :scroll="{y: scrollyHeight - 20}"
  >
    <template slot="order.order_no" slot-scope="text, scope">
      <router-link
        class="word-break order-no"
        :to="`/order/${scope.order_type === $val.orderType.music ? 'channel' : 'package'}/${scope.order_id || '-1'}?from=${$route.name}`"
      >{{text}}</router-link>
    </template>

    <template slot="order.pay_amount" slot-scope="text">
      <span>￥{{text}}</span>
    </template>

    <template slot="is_operated" slot-scope="text">
      <span>{{text | toApplyStatus}}</span>
    </template>

    <template slot="operation" slot-scope="text, scope">
      <a-button
        v-if="$root.$checkAuth($auth[authName] && $auth[authName].look)"
        class="m-right-ms"
        type="link"
        @click="handleFunc($var.Handle.look, scope)"
      >查看</a-button>

      <a-dropdown>
        <a-button type="link" @click="e => e.preventDefault()">
          <a-icon type="more" :rotate="90" :style="{fontSize: '28px', verticalAlign: 'middle'}" />
        </a-button>

        <a-menu slot="overlay">
          <a-menu-item>
            <a-button
              v-if="$root.$checkAuth($auth[authName] && $auth[authName].edit)"
              class="m-right-ms"
              type="link"
              @click="handleFunc($var.Handle.edit, scope)"
            >编辑</a-button>
          </a-menu-item>
          <a-menu-item>
            <a-button
              v-if="$root.$checkAuth($auth[authName] && $auth[authName].operation)"
              type="link"
              :disabled="scope.is_operated == '1'"
              @click="onOperate(scope)"
            >处理</a-button>
          </a-menu-item>
          <a-menu-item>
            <a-button
              v-if="$root.$checkAuth($auth[authName] && $auth[authName].download)"
              type="link"
              :disabled="scope.is_operated == '0'"
              @click="onDownload(scope.contract_id)"
            >下载</a-button>
            <!-- <a
              class="ant-btn-link"
              :disabled="scope.is_operated == '0'"
              :href="`${$axios.BuyManage_contractDownload}?contract_id=${scope.contract_id}`"
              download
            >下载</a>-->
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </template>
  </ng-table>
</template>

<script>
  import {tableMixins} from '@/mixins';

  import {ApplyOptions} from '@/config/options';
  import {toOptionName, DownloadFile, DownloadFileByIframe} from '@/utils/function';

  import * as TabCol from '@/config/columns';

  const columns = [
    TabCol.colId,
    TabCol.commonKey('订单号', 'order.order_no', {width: '200px', isSlot: true}),
    TabCol.commonKey('申请时间', 'create_at'),

    TabCol.commonKey('申请状态', 'is_operated', null, true),
    TabCol.commonKey('公司名称', 'company.name'),
    TabCol.commonKey('订单金额', 'order.pay_amount', null, true),
    TabCol.operation('120px'),
  ];

  export default {
    data() {
      return {
        columns,
        params: {},
      }
    },

    props: {
      authName: String,
      scrollyHeight: {
        type: Number,
        default: 400
      },
      reload: Boolean,
      tableParams: Object,
    },

    watch: {
      tableParams(newVal) {
        this.$nextTick(() => {
          this.params = Object.assign({}, this.params, newVal || {});
        })
      }
    },

    mounted() {
      // let {range = [], page = 1, channel, status} = this.$route.query;
      // let order_id = this.order_id;

      // let params = {channel, page, status, }

      // params['time[start]'] = range[0] || '';
      // params['time[end]'] = range[1] || '';

      // order_id && (params.order_id = order_id || 0)

      // this.params = params;
    },

    filters: {
      toApplyStatus(value) {
        return toOptionName(ApplyOptions, value);
      },
    },

    mixins: [
      tableMixins
    ],

    methods: {
      handleFunc(key, scope) {
        this.$emit('handle', Object.assign(scope, {
          handleName: key
        }))
      },

      onDownload(contract_id) {
        DownloadFileByIframe(`${this.$axios.BuyManage_contractDownload}?contract_id=${contract_id}`, '');

        // let params = {contract_id};

        // this.$store.commit('changeContentLoading', true);

        // this.$axios.BuyManage_contractDownload(params).then(res => {
        //   const data = res.data;

        //   if (data) {
        //     try {
        //       let disposition = res.headers['content-disposition'];
        //       let filename = '';

        //       if (disposition) {
        //         filename = disposition.replace(/.*filename=["']([^"']+)["'].*/, '$1');
        //       }

        //       DownloadFile(data, filename);
        //       this.$message.success('下载成功');
        //     } catch (error) {
        //       this.$error(error);
        //     }
        //   }

        //   this.$store.commit('changeContentLoading', false);
        // }).catch(() => {
        //   this.$store.commit('changeContentLoading', false);
        // })
      },

      onOperate(scope) {
        let vm = this;

        this.$confirm({
          width: '480px',
          title: '确认该合同已处理完成？',
          onOk() {
            return new Promise((resolve, reject) => {
              vm.handleFinish(scope, resolve, reject);
            });
          }
        });
      },

      handleFinish(scope, next, reject) {
        this.$axios.BuyManage_contractOperate({contract_id: scope.contract_id}).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('已处理完成');
            next();

            setTimeout(() => {
              this.loadTableData();
            }, 500);
          } else {
            reject();
          }
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .ant-table .operation {
    white-space: nowrap;
  }
</style>
