<template>
  <a-layout class="m-top-base ng-mark-music h-100" :key="keyIndex">
    <a-layout-sider class="content-aside" width="350px">
      <mark-music-detail class="m-bottom-base" :data="music_info"></mark-music-detail>
      <marked-tag-list
        class="m-bottom-base"
        :list="selectedList"
        @close="(options) => delTagInfo = options"
      ></marked-tag-list>
      <mark-music-list class="m-bottom-base" :list="musicList" @empty="$emit('success', 'empty')"></mark-music-list>
    </a-layout-sider>

    <a-layout-content class="content-main h-100" style="padding-bottom: 45px;overflow: auto;">
      <tag-gather
        :selected-list.sync="selectedList"
        :list="tagList"
        :del-info="delTagInfo"
        @change="onChangeTags($event, index)"
        ref="tagGather"
      ></tag-gather>

      <a-empty v-if="tagList.length === 0" description="没有音乐信息" style="margin-top: 100px;"></a-empty>

      <div class="mark-tool-box">
        <marked-tool
          :list="selectedIds"
          :typeList="tagList.map(item => item.name)"
          :handle-info="toolHandleInfo"
          :is-edit="isEdit"
          :music_id="music_info.music_id"
          :type="music_info.type"
          :task_id="task_id"
          @success="(data) => {$emit('success', data)}"
        ></marked-tool>
      </div>
    </a-layout-content>
  </a-layout>
</template>

<script>

  import MarkMusicDetail from '../components/MarkMusicDetail.vue'
  import MarkedTagList from '../components/MarkedTagList.vue'
  import MarkMusicList from '../components/MarkMusicList.vue'
  import MarkedTool from '../components/MarkedTool.vue'
  import TagGather from '../markTags/TagGather.vue'

  const KEYID = 'label_id';

  export default {
    data() {
      return {
        name: `markMusicManage`,

        keyId: KEYID,

        keyIndex: 0,

        spinning: false,

        selectedList: [],

        delTagInfo: {},

        tagList: [],
        musicList: [],
        music_info: {},
        toolHandleInfo: {},
      }
    },

    props: {
      task_id: String,
      data: {
        type: Object,
        default() {
          return {}
        }
      },
      // 初始化选中标签
      initSelected: Array,
      isEdit: Boolean
    },

    computed: {
      initSelectedList() {
        if (this.tagList && this.tagList.length > 0) {
          return this.tagList.map(item => {
            return this.initSelected.filter(cItem => item.cate_id === cItem.cate_id);
          })
        }

        return []
      },

      selectedIds() {
        return this.selectedList.map(item => item ? item.map(cItem => cItem[this.keyId]) : [])
      }
    },

    watch: {
      data: {
        handler(newVal) {
          let {label_list = [], music_list = [], music_info = {}, is_hardcase, status, log_id} = newVal;

          this.tagList = label_list;
          this.musicList = music_list;
          this.music_info = music_info || {};
          this.toolHandleInfo = {is_hardcase, status, log_id};
          this.selectedList = label_list.map(() => []);
        },
        immediate: true
      },
      initSelectedList: {
        handler(newVal) {
          let arr = newVal || [];
          let tagGather = this.$refs.tagGather;

          if (tagGather && tagGather.onInitTags) {
            this.$nextTick(() => {
              setTimeout(() => {
                tagGather.onInitTags(arr);
              }, 500);
            })
          }
        },
        immediate: true
      }
    },

    components: {
      MarkMusicDetail, MarkedTagList, MarkMusicList, MarkedTool, TagGather
    },

    mounted() {

    },

    methods: {
    }
  }
</script>

<style lang="scss" scoped>
  .ng-mark-music {
    .content-aside {
      padding: 20px;
      max-width: 300px;
      background-color: #f5f5f5;
    }

    .content-main {
      padding: 0 20px;
      background-color: #ffffff;
    }

    .mark-tool-box {
      position: fixed;
      bottom: 40px;
    }
  }
</style>
