<template>
  <a-modal
    width="420px"
    v-model="show"
    title="添加到歌单"
    destroyOnClose
    :maskClosable="false"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    class="submit-music"
    :bodyStyle="bodyStyle"
  >
    <a-input-search
      class="ng-input-search m-bottom-base"
      placeholder="请输入歌单名称"
      @search="onSearch"
      allowClear
      style="width: 350px;"
    >
      <template v-slot:enterButton>
        <a-button type="sub">
          <a-icon type="search" />
        </a-button>
      </template>
    </a-input-search>

    <a-spin :spinning="loading">
      <ul style="min-height: 200px;">
        <li
          v-for="item in songList"
          class="flex cen-start song-list-item"
          :class="{active: curSongListId === item.playlist_id}"
          :key="item.playlist_id"
          @click="curSongListId = item.playlist_id"
        >
          <ng-image class="logo m-right-base" :src="item.logo_url"></ng-image>

          <div class>
            <div>{{item.name}}</div>
            <span>{{item.music_num || 0}}</span>
          </div>
        </li>
      </ul>
    </a-spin>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import * as allOption from '@/config/options'

  export default {
    data() {
      return {
        show: false,
        confirmLoading: false,
        form: this.$form.createForm(this, {name: ''}),
        allOption,
        params: {},
        loading: false,
        songList: [],
        curSongListId: ''
      }
    },

    props: {
      info: {
        type: Object,
        default() {
          return {}
        },
      },
      music_id: String,
      type: String
    },

    watch: {
      show(newVal) {
        if (newVal) {
          this.curSongListId = '';
          this.getSongList();
        }
      }
    },

    mixins: [dialogMixins],

    created() {
      // this.getSongList(this.params)
    },

    methods: {
      onSearch(keyword) {
        typeof keyword === 'string' && (keyword = keyword.trim());

        this.params.name = keyword;

        this.curSongListId = '';

        this.getSongList(this.params)
      },

      getSongList(params = {}) {
        let {wait, offline} = this.$val.SaleStatus;

        this.loading = true;

        params.sale_status = [wait, offline];

        this.$axios.Song_list(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            // something
            this.songList = data.data ? data.data.list : [];
          }

          this.loading = false;
        })
      },

      handleOk() {
        let params = {};

        params.music_ids = [this.music_id];
        params.playlist_id = this.curSongListId;

        if (!this.curSongListId) {
          this.$message.error('请选择歌单');
          return false;
        }

        this.confirmLoading = true;

        this.$axios.Song_musicList_addMusic(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('添加成功');

            this.$emit('update:visible', false);

            this.$emit('success', data.data);
          }

          this.confirmLoading = false;
        }).catch(() => {
          this.confirmLoading = false;
        })

      },

      // handleOk() {
      //   this.form.validateFieldsAndScroll((errors, values) => {

      //     if (!errors) {
      //       values.music_id = this.music_id;

      //       this.confirmLoading = true;

      //       this.$axios.Song_musicList_addMusic(values).then(res => {
      //         const data = res.data;

      //         if (data && data.code == 0) {
      //           this.$message.success('修改成功');

      //           this.$emit('update:visible', false);

      //           this.$emit('success', data.data);
      //         }

      //         this.confirmLoading = false;
      //       }).catch(() => {
      //         this.confirmLoading = false;
      //       })

      //     }
      //   });
      // },
    }
  }
</script>

<style lang="scss" scoped>
  .song-list-item {
    padding: 10px;
    border: 1px solid #efefef;
    margin-bottom: 10px;
    cursor: pointer;

    &.active {
      border-color: $primary-color;
    }

    .logo {
      width: 40px;
      height: 40px;
    }
  }
</style>
