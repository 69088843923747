<template>
  <a-card class="scene-item" :title="title || '添加折扣方案'" :scopedSlots="$scopedSlots">
    <!-- <span class="close-icon" slot="extra" @click="$emit('close', index)">&#9587;</span> -->

    <a-form :form="form">
      <a-row class>
        <a-col class="left" span="16">
          <a-row>
            <a-col span="12">
              <a-form-item label="授权场景">
                <a-select
                  :options="BusinessSceneOptions"
                  placeholder="请选择授权场景"
                  v-decorator="[`auth_scene`, {rules: [$validate.required] }]"
                ></a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="授权地域">
                <a-select
                  :options="AuthAreaOptions"
                  placeholder="请选择授权地域"
                  v-decorator="[`auth_area`, {rules: [$validate.required] }]"
                ></a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="12">
              <a-form-item label="授权渠道">
                <!-- <a-input
                  placeholder="请输入授权渠道"
                  v-decorator="[`use_mode`, {rules: [$validate.required] }]"
                />-->
                <a-select
                  :options="UserModeOptions"
                  placeholder="请输入授权渠道"
                  v-decorator="[`use_mode`, {rules: [$validate.required] }]"
                ></a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="授权期限">
                <a-select
                  :options="AuthTimeOptions"
                  placeholder="请选择授权期限"
                  v-decorator="[`auth_time`, {rules: [$validate.required] }]"
                ></a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-col>
        <a-col class="right" span="8">
          <a-form-item label="场景权重">
            <a-input-number
              class="w-100"
              :min="0"
              :step="0.01"
              v-decorator="[`ratio`, {rules: [$validate.required], initialValue: 0 }]"
            ></a-input-number>
          </a-form-item>

          <a-form-item label="折扣期限">
            <a-range-picker
              valueFormat="YYYY-MM-DD"
              v-decorator="[`range`, {rules: [$validate.required] }]"
            ></a-range-picker>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <div class="footer">
      <a-button class="m-right-base" @click="onCancel">取消</a-button>
      <a-button type="primary" @click="onSave">保存</a-button>
    </div>

    <a-spin :spinning="loading" class="pos-all"></a-spin>
  </a-card>
</template>

<script>
  import {Card, DatePicker} from 'ant-design-vue';
  import {BusinessSceneOptions, AuthAreaOptions, AuthTimeOptions} from '@/config/options'

  export default {
    data() {
      return {
        BusinessSceneOptions,
        AuthAreaOptions,
        AuthTimeOptions,
        UserModeOptions: [],
        formAddDefault: [],
        curAuthScene: '',
        loading: false,
      }
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        onValuesChange: (props, values) => {
          if (values.auth_scene) {
            this.form.resetFields(['use_mode', 'auth_time', 'auth_area']);

            this.curAuthScene = values.auth_scene;
          }
        }
      });
    },

    created() {
      this.commonList = [];

      this.getList().then((list) => {
        this.getUseModeOptions(list);

        this.$nextTick(() => {
          this.setDefaultValue(this.defaultData);
        })
      })
    },

    computed: {
      isEdit() {
        return this.dis_id;
      },

      request() {
        return this.isEdit ? this.$axios.SongList_discountEdit : this.$axios.SongList_discountAdd;
      }
    },

    props: {
      title: String,
      defaultData: Object,
      dis_id: String,
      sid: String,
      index: [String, Number]
    },

    watch: {
      defaultData: {
        handler(newVal) {
          if (this.commonList && this.commonList.length > 0) {
            this.$nextTick(() => {
              this.setDefaultValue(newVal);
            })
          }
        },
        immediate: true
      },

      curAuthScene: {
        handler(newVal) {
          this.getList().then((list) => {
            this.getUseModeOptions(list, newVal)
          });
        },
        // immediate: true
      }
    },

    components: {
      ACard: Card,
      ARangePicker: DatePicker.RangePicker
    },
    methods: {
      setDefaultValue(value) {
        if (value && Object.keys(value).length > 0 && this.isEdit) {
          let {auth_scene, auth_time, auth_area, start_date, end_date, use_mode, ratio} = value;
          let copyVal = {auth_scene};

          copyVal.range = [start_date, end_date];
          copyVal.ratio = ratio / 100;

          this.form.setFieldsValue(copyVal);

          this.$nextTick(() => {
            this.form.setFieldsValue({use_mode, auth_time, auth_area});
          })
        }
      },

      getList() {
        let commonList = this.commonList;

        return new Promise((resolve) => {
          if (commonList && commonList.length > 0) {
            resolve(this.commonList);
          } else {
            this.$root.getCommonSceneAndMode().then((list) => {
              resolve(list)
            });
          }
        })
      },

      onCancel() {
        let isEdit = this.isEdit;

        if (isEdit) {
          this.$emit('endEdit', this.index);
        } else {
          this.$emit('close', this.index);
        }
      },

      onSave() {
        this.form.validateFields((err, values) => {
          if (!err) {
            if (values.range) {
              const [start, end] = values.range;

              values.start_date = start;
              values.end_date = end;
            }

            values.ratio = values.ratio * 100;

            if (this.isEdit) {
              values.discount_id = this.dis_id;
            } else {
              values.playlist_id = this.sid;
            }

            delete values.range;

            this.loading = true;

            this.request(values).then(res => {
              const data = res.data;

              if (data && data.code == 0) {
                this.$message.success(this.isEdit ? '编辑成功' : '新增成功');
                this.$emit('success', Object.assign({}, data.data, {index: this.index}));
              }

              this.loading = false;
            })
          }
        })
      },

      getUseModeOptions(list = [], auth_scene) {
        let {correct} = this.$val.CommonCheck;

        let stayScene = list.filter(item => item.is_enabled === correct).map(item => item.type);

        this.BusinessSceneOptions = BusinessSceneOptions.filter(item => ~stayScene.indexOf(item.value));

        if (auth_scene) {
          list.some(item => {
            if (item.type === auth_scene) {
              let {use_mode = [], auth: {time = [], area = []}} = item;
              let stayTime = time.filter(item => item.is_enabled === correct).map(item => item.type);
              let stayArea = area.filter(item => item.is_enabled === correct).map(item => item.type);

              this.UserModeOptions = use_mode.map(item => ({value: item.name, label: item.name}));
              this.AuthAreaOptions = AuthAreaOptions.filter(item => ~stayArea.indexOf(item.value));
              this.AuthTimeOptions = AuthTimeOptions.filter(item => ~stayTime.indexOf(item.value));

              return true;
            }
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .scene-item {
    /deep/ {
      .iconfont-trash {
        top: 20px !important;
      }

      .ant-form-item {
        display: flex;
        flex-direction: row;
        padding-right: 20px;

        .ant-form-item-label {
          width: 90px;
        }

        .ant-form-item-control-wrapper {
          flex: 1;
        }
      }
    }

    .left {
      &::before {
        content: " ";
        position: absolute;
        top: 0;
        bottom: 20px;
        right: 0;
        width: 1px;
        background-color: #efefef;
      }
    }

    .footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      border-top: 1px solid #efefef;
      padding-top: 20px;
    }
  }

  .close-icon {
    font-size: 20px;
    cursor: pointer;
  }
</style>
