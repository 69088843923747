/**
 * 账号权限管理
 */
import {
  Axios
} from '@/utils/from-common';

// 成员列表
export const Account_membersList = (param) => Axios('POST', '/bms/user/list', param);

// 新增成员
export const Account_addMembers = (param) => Axios('POST', '/bms/user/add', param);

// 编辑成员
export const Account_editMembers = (param) => Axios('POST', '/bms/user/save', param);

// 删除成员
export const Account_delMembers = (param) => Axios('POST', '/bms/user/del', param);

// 启用/禁用成员
export const Account_membersStatus = (param) => Axios('POST', '/bms/user/upStatus', param);

// 部门列表
export const Account_departmentList = (param) => Axios('POST', '/bms/department/list', param);

// 新增部门
export const Account_addDepartment = (param) => Axios('POST', '/bms/department/add', param);

// 编辑部门
export const Account_editDepartment = (param) => Axios('POST', '/bms/department/save', param);

// 删除部门
export const Account_delDepartment = (param) => Axios('POST', '/bms/department/del', param);

// 启用/禁用部门
export const Account_department_status = (param) => Axios('POST', '/bms/department/upStatus', param);

// 部门详情
export const Account_department_info = (param) => Axios('POST', '/bms/department/info', param);

// 角色新增
export const Account_roles_add = (param) => Axios('POST', '/bms/role/add', param);

// 角色编辑
export const Account_roles_edit = (param) => Axios('POST', '/bms/role/save', param);

// 角色删除
export const Account_roles_del = (param) => Axios('POST', '/bms/role/del', param);

// 角色启用/禁用
export const Account_roles_status = (param) => Axios('POST', '/bms/role/upStatus', param);

// 角色列表
export const Account_roles_list = (param) => Axios('POST', '/bms/role/list', param);

// 角色详情
export const Account_roles_info = (param) => Axios('POST', '/bms/role/info', param);