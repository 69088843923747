<template>
  <a-modal
    width="700px"
    v-model="show"
    title="查看任务进度"
    destroyOnClose
    :maskClosable="false"
    :footer="null"
  >
    <div style="min-height: 240px;">
      <ng-table
        class="ng-table"
        :columns="columns"
        :params="params"
        :rowKey="row => row.task_id"
        :request="$axios.Enterlibrary_storeProgress"
        :load.sync="loadTable"
        :reload.sync="reloadTable"
        :scroll="{y: 400}"
        :has-pagination="false"
      >
        <template slot="status" slot-scope="text">{{text | toServerProgressName}}</template>
        <template slot="operation" slot-scope="text, scope">
          <router-link
            v-if="$root.$checkAuth($auth[authName] && $auth[authName].lookDetail)"
            :to="`/libraryenter/musicmanage/${scope.task_id}/${scope.music_type}/${$var.Common.third}`"
            :disabled="!scope.op_view"
          >查看</router-link>
        </template>
      </ng-table>
    </div>
  </a-modal>
</template>

<script>
  import {dialogMixins, tableMixins} from '@/mixins/index';

  import * as TabCol from '@/config/columns';

  const columns = [
    TabCol.commonKey('认领人', 'charger_name'),
    TabCol.commonKey('认领时间', 'claim_time'),
    TabCol.commonKey('任务进度', 'status', false, true),
    TabCol.operation('100px')
  ]

  export default {
    data() {
      return {
        show: false,
        columns,
        params: {}
      }
    },

    props: {
      batch_id: String,
      authName: String,
    },

    // mounted() {
    //   this.params = Object.assign({}, this.params, {page: 1})
    // },

    watch: {
      // batch_id: {
      //   handler(newVal) {
      //     if (newVal) {
      //       this.$nextTick(() => {
      //         this.params = Object.assign({}, this.params, {batch_id: newVal})
      //       })
      //     }
      //   },
      //   immediate: true
      // },

      show: {
        handler(newVal) {
          if (newVal) {
            this.$nextTick(() => {
              this.params = Object.assign({}, this.params, {batch_id: this.batch_id})
            })
          }
        },
        immediate: true
      }
    },

    mixins: [dialogMixins, tableMixins],

    methods: {
      // getData() {
      //   let params = {batch_id: this.batch_id};

      //   this.$axios.Enterlibrary_storeProgress(params).then(res => {
      //     const data = res.data;

      //     if (data && data.code == 0) {
      //       // something
      //     }
      //   })
      // },
    }
  }
</script>

<style lang="scss" scoped>
</style>
