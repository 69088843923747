<template>
  <div>
    <ng-table
      class="ng-table"
      :columns="columns"
      :params="params"
      :rowKey="row => row.batch_id"
      :request="$axios.Marklibrary_servingList"
      :load.sync="loadTable"
      :reload.sync="reloadTable"
      :scroll="{y: scrollyHeight - 20}"
    >
      <!-- 头像 -->
      <template slot="lib_logo_url" slot-scope="text, scope">
        <ng-image
          class="table-header"
          :src="scope.lib_logo_url ? `${scope.lib_logo_url}?x-oss-process=style/thumb_10` : $var.defaultHeader"
          @error="({target}) => target.src = $var.defaultHeader"
        />
      </template>

      <template slot="priority" slot-scope="text">
        <span v-html="$options.filters.toLevelName(text)"></span>
      </template>

      <template slot="current_progress" slot-scope="text, scope">
        <span>{{scope.done_music_num}} / {{scope.music_num}}</span>
      </template>

      <template slot="operation" slot-scope="text, scope">
        <a-button
          v-if="$root.$checkAuth($auth[authName] && $auth[authName].progress)"
          class="m-right-ms"
          type="link"
          @click="lookProgress(scope)"
        >成员进度</a-button>

        <a-button
          v-if="$root.$checkAuth($auth[authName] && $auth[authName].deliver)"
          class="m-right-ms"
          type="link"
          @click="goDeliever(scope)"
          :disabled="!scope.op_deliver"
        >交付</a-button>
      </template>
    </ng-table>

    <dialog-look-progress
      :auth-name="authName"
      :visible.sync="showProgressDialog"
      :batch_id="currentData.batch_id"
    ></dialog-look-progress>
  </div>
</template>

<script>
  import DialogLookProgress from './DialogLookProgress.vue'
  import {tableMixins} from '@/mixins';

  import * as TabCol from '@/config/columns';

  const columns = [
    TabCol.colId,
    TabCol.portrait('封面', 'lib_logo_url'),
    TabCol.commonKey('曲库名称', 'lib_name', {ellipsis: true}),

    TabCol.commonKey('优先级', 'priority', '', true),
    TabCol.commonKey('当前进度', 'current_progress', '', true),
    TabCol.operation('140px')
  ]

  export default {
    data() {
      return {
        columns,
        tableList: [],
        loading: false,
        // params: {},

        currentData: {},

        showProgressDialog: false,
        // reloadTable: false,
      }
    },

    props: {
      authName: String,
      scrollyHeight: {
        type: Number,
        default: 400
      },
      params:Object
    },

    mounted() {
      // this.params = {page: this.$route.query.page || 1};
    },

    mixins: [
      tableMixins
    ],

    components: {
      DialogLookProgress
    },

    methods: {

      lookProgress(scope) {
        this.showProgressDialog = true;
        this.currentData = scope
      },

      goDeliever(scope) {
        let vm = this;

        this.$confirm({
          width: '480px',
          title: '确认交付？',
          onOk() {
            return new Promise((resolve, reject) => {
              vm.deliever(scope, resolve, reject);
            });
          }
        });
      },

      deliever(scope, next, reject) {
        this.$axios.Marklibrary_TaskSubmit({batch_id: scope.batch_id}).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('已交付');
            next();

            setTimeout(() => {
              this.loadTableData();
            }, 500);
          } else {
            reject()
          }
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .ant-table td {
    white-space: nowrap;
  }
</style>
