<template>
  <div class>
    <template v-for="(item, index) in TagList">
      <template v-if="index > 0 && index < TagList.length">
        <a-divider :key="`divider${index}`">{{ tagNameArr[index] }}级标签</a-divider>
      </template>
      <tag-group
        :data="item.child"
        :deep="index"
        :parent-key="item.parentKey"
        :parent-index="item.index"
        :before-tag="onBeforeTag"
        :key="`${index}_${keyIndex}`"
        :handle-dir="handleDir"
        @tag="onTag"
      ></tag-group>
    </template>
  </div>
</template>

<script>
  /**
   * 3. 删除一个选中标签做出对应变化
   */
  import TagGroup from './TagGroup.vue';

  const KEYID = 'label_id'; // 数据唯一标识

  const handleDir = {
    CANCEL: 'CANCEL',
    SELECTED: 'SELECTED',
    ACTIVE: 'ACTIVE'
  }

  export default {
    data() {
      return {
        dataList: [],
        /**
         * 列表分层级
         * 
         * [0] 一级标签列表
         * [1] 二级标签列表
         * ...
         */
        TagList: [],

        TagIndexList: [], // 保存当前展示标签在对应数组中的下标

        selectedList: [], // 选中标签集合，只保存最底层标签

        keyId: KEYID,

        handleDir,

        keyIndex: 1,

        // 大写数字对应
        tagNameArr: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'],
      }
    },

    props: {
      data: {
        type: Array,
        default() {
          return []
        }
      },
      max: [Number, String],
      /**
       * 提示信息
       * 
       * max 超出max提示信息
       */
      message: {
        type: Object,
        default() {
          return {
            max: '',
          }
        }
      },

      // delItem: {
      //   type: Function,
      // }
    },

    watch: {
      data: {
        handler(newVal) {
          this.dataList = newVal;
          this.TagList = [this.getCollectItem(this.dataList, 0, '')];
          this.childListCollect = this.getChildrenCollect(this.dataList, this.keyId, this.getCollectItem, 'child');
        },
        immediate: true
      }
    },

    components: {
      TagGroup
    },

    methods: {

      onBeforeTag(item, deepIndex, handle) {
        let selectedList = this.selectedList || [];
        let curItemParentKey = this.getParentKey(this.data, item[KEYID], KEYID);
        let selectedListIncludeParentKey = curItemParentKey && selectedList.some(item => item[KEYID] === curItemParentKey);

        // if (!this.TagIndexList || this.TagIndexList.length > 0) {
        //   this.checkAndClearPrevSelected([this.TagList[deepIndex]]);
        // }

        if (handle === handleDir.SELECTED) {
          if (this.max && selectedList.length >= this.max && !selectedListIncludeParentKey) {
            this.$message.warning(this.message.max || `最多选择${this.max}个`)
            return false;
          }
        }

        return true;
      },

      onTag(options) {
        const keyId = options[this.keyId];
        const deep = options.deep;
        const handle = options.handle;
        const index = options.index;
        // const isLast = options.isLast;
        const item = options.item;
        const parentKey = options.parentKey;

        this.setTagIndex(index, deep);
        this.toggleTagGroupVisible(keyId, deep, handle);
        this.toggleTagSelected(keyId, deep, handle);
        this.saveSelected(handle, keyId, parentKey, item);

        this.$emit('change', this.selectedList);
      },

      // 展示或隐藏子标签组
      toggleTagGroupVisible(keyId, deep, handle) {
        const children = this.childListCollect[keyId];
        const len = this.TagList.length;
        const isCancel = handle === handleDir.CANCEL;

        // 有子级标签，添加子级标签组
        // 只添加次一级的子标签，如果有孙级别或更低级的，删除
        if (children && children.child && children.child.length > 0 && !isCancel) {
          this.TagList.splice(deep + 1, len, children);
        }
        // 没有子级标签，删除存在的更低级的标签组
        else {
          this.TagList.splice(deep + 1);
        }
      },

      setTagIndex(index, deep) {
        let len = this.TagIndexList.length;
        this.TagIndexList.splice(deep, len, index);
      },

      // 修改数据选中态
      toggleTagSelected(keyId, deep, handle) {
        const isCancel = handle === handleDir.CANCEL;
        const isSelected = handle === handleDir.SELECTED;

        if (isCancel) {
          this.removeSelected(deep)
        } else if (isSelected) {
          this.addSelected(deep);
        }
      },

      getParentKey(data, childKey, keyName, childName = 'child') {
        let parentKey = '';

        one(data);

        return parentKey;

        function one(list) {
          for (let i = 0, len = list.length; i < len; i++) {
            const item = list[i];
            const child = item[childName];

            if (item[keyName] === childKey) {
              return true;
            }

            if (child && child.length > 0) {
              parentKey = item[keyName];

              if (!one(child)) {
                parentKey = '';
              } else {
                return true;
              }
            }
          }

          return false;
        }
      },

      addSelected(deep, changeKey = 'selected') {
        let path = this.getCurrItemPath(this.TagIndexList, deep);

        this.setItem(this.dataList, path, changeKey, true);
      },

      removeSelected(deep, changeKey = 'selected') {
        let path = this.getCurrItemPath(this.TagIndexList, deep);

        this.setItem(this.dataList, path, (final, parent) => {
          final[changeKey] = false;

          if (final.child && final.child.length > 0) {
            this.removeChildSelectedStatus(final.child, (item) => {
              this.selectedList = this.selectedList.filter(sitem => sitem[this.keyId] !== item[this.keyId])
            })
          }

          // 如果父元素的所有子元素都没有选中，需要添加父元素到选中
          if (parent) {
            let child = parent.child;

            let isSelected = child.some(item => item[changeKey]);

            !isSelected && this.selectedList.push(parent);
          }
        });
      },

      // 移除选中该态
      removeChildSelectedStatus(data, func, keyName = this.keyId, changeKey = 'selected') {
        return one(data, keyName, changeKey)

        function one(newData, keyName, changeKey) {
          return newData.map(item => {
            let isLast = true;

            item[changeKey] = false;

            if (item.child && item.child.length > 0) {
              item.child = one(item.child, keyName, changeKey)
              isLast = false;
            }

            typeof func === 'function' && func(item, isLast);

            return item;
          })
        }
      },

      removeAllSelected(data, id, keyName = this.keyId, changeKey = 'selected') {
        loop(data, id, keyName, changeKey);

        function loop(newData, id, keyName, changeKey) {
          return data.map(item => {
            if (item[keyName]) {
              item[changeKey] = false;
            }

            if (item.child && item.child.length > 0) {
              this.removeSelected(item.child, ...arguments.slice(1));
            }
          })
        }
      },

      initSelected(selectedList, keyName = this.keyId, changeKey = 'selected') {
        let selectedListId = selectedList.map(item => item[keyName]);
        let data = this.dataList;

        loop(data, selectedListId, keyName, changeKey);

        this.selectedList = selectedList;

        this.keyIndex++;

        this.$emit('change', this.selectedList);

        function loop(data, sList, keyName, changeKey) {
          return data.filter(item => {
            let ac = [];

            if (item.child && item.child.length > 0) {
              ac = loop(item.child, sList, keyName, changeKey);
            }

            if (~sList.indexOf(item[keyName]) || (ac && ac.length > 0)) {
              item[changeKey] = true;
              return item;
            }
          })
        }
      },

      delSelected(delIds, keyName = this.keyId, changeKey = 'selected') {
        let data = this.dataList;

        if (typeof delIds === 'string') delIds = [delIds];

        loop(data, delIds, keyName, changeKey);

        this.selectedList = this.selectedList.filter(item => !~delIds.indexOf(item[keyName]));

        this.keyIndex++;

        this.checkCurrSelected();

        this.$emit('change', this.selectedList);

        function loop(data, delIds, keyName, changeKey) {
          return data.filter(item => {
            let ac = [];
            let isCur = !!~delIds.indexOf(item[keyName]);
            let isRoot = false;

            item.isTarget = false;

            if (isCur) item[changeKey] = false;

            if (item.child && item.child.length > 0 && !isCur) {
              ac = loop(item.child, delIds, keyName, changeKey);
              isRoot = item.child.some(item => item.isTarget);
            }

            if (isRoot || isCur) item.isTarget = true;

            if (isRoot && ac.length === 0) {
              item[changeKey] = false;
            }

            return item[changeKey];
          }) || []
        }
      },

      // 检查当前展示选中状态，如果没有选中项，关闭相关子元素
      checkCurrSelected(changeKey = 'selected') {
        let TagList = this.TagList;
        let len = TagList.length;
        let min = len;

        TagList.slice(1).some((item, index) => {
          let selectedList = item.child.filter(cItem => cItem[changeKey]);

          if (selectedList.length === 0) {
            min = Math.min(min, index + 1);
            return true
          }
        })

        this.TagList.splice(min, len);
        this.TagIndexList.splice(min, len);
      },

      // 获取当前点击item的路径，如：[0][child][1]...
      getCurrItemPath(list, deep) {
        return list.slice(0, deep + 1).reduce((a, b, i, {length: l}) => (a + `[${b}]` + (i < l - 1 ? '[child]' : '')), '');
      },

      setItem(data, path = '', keyNameOrFunc, value) {
        let final = data;
        let parent = null;

        if (!~path.indexOf('[')) return false;

        let pathArr = path.slice(1, path.length - 1).split('][');

        pathArr.forEach(item => {
          final = final[item];
        })

        pathArr.length > 2 && pathArr.slice(0, pathArr.length - 2).forEach(item => {
          parent = parent ? parent[item] : data[item];
        })

        if (typeof keyNameOrFunc === 'string') {
          final[keyNameOrFunc] = value;
        } else if (typeof keyNameOrFunc === 'function') {
          keyNameOrFunc(final, parent);
        }
      },

      saveSelected(handle, keyId, parentKey, item) {
        let idName = this.keyId;

        if (handle === handleDir.CANCEL) {
          this.selectedList = this.selectedList.filter(cItem => cItem[idName] !== keyId);
        }
        else if (handle === handleDir.SELECTED) {
          if (parentKey) {
            this.selectedList = this.selectedList.filter(cItem => cItem[idName] !== parentKey);
          }

          this.selectedList.push(item);
        }
      },

      isSavable(list) {
        return list.some(item => item.selected);
      },

      checkAndClearPrevSelected(data, keyName = this.keyId, changeKey = 'selected') {
        loop(data, keyName, changeKey);

        function loop(data, keyName, changeKey) {
          return data.filter(item => {
            let ac = [], isLast = true;

            if (item.child && item.child.length > 0) {
              ac = loop(item.child, keyName, changeKey);
              isLast = false;
            }

            if (item[changeKey] && isLast) {
              return item
            } else if (!isLast) {
              item[changeKey] = false;

              if (ac && ac.length > 0) {
                item[changeKey] = true;
                return item;
              }
            }

            return false;
          }) || []
        }
      },

      /**
       * 获取子标签数据集合
       * 父标签id作为key值，对应子标签组作为value值
       * 
       * @return {Array} 
       * {
       *    [父级id]: [{deep: [层级], value: []}]
       * }
       */
      getChildrenCollect(data, keyId, func, childName = 'children') {
        let collect = {}, deep = 0;

        one(data);

        return collect;

        function one(data) {
          deep++;

          data.forEach(item => {
            if (item[childName] && item[childName].length > 0) {
              const key = item[keyId];

              collect[key] = func(item[childName], deep, item[keyId]);

              one(item[childName])

              deep--;
            }
          })
        }
      },

      getCollectItem(child, deep, pKey) {
        return {
          child,
          deep,
          parentKey: pKey
        };
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
