<template>
  <main-structure class="ng-account-members" dir="ver">
    <template slot="header">
      <span class="title">部门角色管理</span>

      <div class="header-content">
        <a-button
          v-if="$root.$checkAuth($auth[name] && $auth[name].add)"
          type="sub"
          @click="handleFunc($var.Handle.add)"
        >新增部门</a-button>
      </div>
    </template>

    <template>
      <div class="m-top-ms flex-0">
        <filter-department @change="onFilterChange" :defaultData="$route.query"></filter-department>
      </div>

      <div class="m-top-base flex-1" ref="ngTableBox">
        <ng-table
          v-if="$root.$checkAuth($auth[name] && $auth[name].list)"
          class="ng-table"
          childrenColumnName="child"
          :columns="columns"
          :rowKey="row => row.dept_id"
          :scroll="{y: mainContentScrollMaxHeight}"
          :params="params"
          :request="$axios.Account_departmentList"
          :load.sync="loadTable"
          :reload.sync="reloadTable"
        >
          <template slot="status" slot-scope="text, scope">
            <table-switch
              v-if="$root.$checkAuth($auth[name] && $auth[name].status)"
              :options="memberStatusOptions"
              :default-value="parseInt(text)"
              :change="function() { onChangeSwitch(...arguments, scope) }"
              :disabled="scope.op_enable == 0"
            ></table-switch>
            <span
              v-else
              :class="text == 0 ? 'font-error' : 'font-success'"
            >{{$root.$getItemWithKey(memberStatusOptions, 'id', text).name || ''}}</span>
          </template>

          <template slot="operation" slot-scope="text, scope">
            <a-button
              v-if="$root.$checkAuth($auth[name] && $auth[name].look)"
              class="m-right-ms"
              type="link"
              @click="handleFunc($var.Handle.look, scope)"
            >查看</a-button>
            <a-button
              v-if="$root.$checkAuth($auth[name] && $auth[name].edit)"
              class="m-right-ms"
              type="link"
              @click="handleFunc($var.Handle.edit, scope)"
            >编辑</a-button>
            <a-button
              v-if="$root.$checkAuth($auth[name] && $auth[name].add)"
              class="m-right-ms"
              type="link"
              @click="handleFunc($var.Handle.add, scope, true)"
            >添加子部门</a-button>
            <a-button class="m-right-ms" type="link" @click="manageRole(scope)">角色管理</a-button>
            <a-button
              v-if="$root.$checkAuth($auth[name] && $auth[name].del)"
              class="ant-btn-error"
              type="link"
              @click="onDelete(scope)"
              :disabled="scope.op_delete == 0"
            >删除</a-button>
          </template>
        </ng-table>
      </div>
    </template>

    <add-department
      :visible.sync="showHandleDialog"
      :handle="handleType"
      :data="handleData"
      @success="handleSuccess"
      :isChild="isChild"
    ></add-department>
  </main-structure>
</template>

<script>
  import FilterDepartment from '@/pages/account/components/FilterDepartment';

  import {OpenAndCloseStatus} from '@/config/options'

  import {mainContentMixin, tableMixins, filterReq} from '@/mixins';

  const columns = [
    {
      title: '部门名称',
      dataIndex: 'name',
    },
    {
      title: '状态',
      dataIndex: 'is_enabled',
      scopedSlots: {customRender: 'status'},
    },
    {
      title: '操作',
      scopedSlots: {customRender: 'operation'},
      width: '300px'
    },
  ];

  export default {
    data() {
      return {
        name: 'account_dept',
        columns,
        data: [],

        memberStatusOptions: OpenAndCloseStatus,
        switchLoading: false,
        showHandleDialog: false,

        handleType: this.$var.Handle.add,
        handleData: {},
        isChild: false,

        params: {},
        loadTable: false,
      }
    },
    components: {
      FilterDepartment,
      AddDepartment: () => import('./dialog/AddDepartment')
    },

    mounted() {
      let {is_enabled = '', page = 1} = this.$route.query;

      this.params = {is_enabled, page};
    },

    mixins: [
      mainContentMixin, tableMixins, filterReq
    ],

    methods: {
      onFilterChange(values) {
        this.params = this.filterCommon(values);
      },

      handleFunc(key, scope, isChild) {
        if (key === this.$var.Handle.look) {
          this.$router.push(`/account/departmentDetail/${scope.dept_id}`);
          return;
        }

        this.handleData = scope;
        this.isChild = isChild;
        this.handleType = key;
        this.showHandleDialog = true;
      },

      manageRole(scope) {
        this.$router.push(`/account/roles/${scope.dept_id}`);
      },

      onDelete(scope) {
        let vm = this;

        this.$confirm({
          width: '480px',
          title: '确认要删除该部门吗？',
          content: '删除该部门，部门关联的子部门及角色数据，会同步删除。',
          onOk() {
            return new Promise((resolve, reject) => {
              vm.deleteMember(scope, resolve, reject);
            });
          }
        });
      },

      deleteMember(scope, next, reject) {
        this.$axios.Account_delDepartment({dept_id: scope.dept_id}).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('已成功删除该部门');
            next();

            setTimeout(() => {
              this.loadTableData();
            }, 500);
          } else {
            reject();
          }
        })
      },

      handleSuccess({handle, data}) {
        data = data.data;

        if (typeof data !== 'object') return;

        if (handle === this.$var.Handle.add) {
          this.loadTableData();
        }
        // edit
        else if (handle === this.$var.Handle.edit) {
          this.data = this.$changeDataItem(this.data, data.dept_id, () => Object.assign({}, data), 'dept_id');
        }
      },

      onChangeSwitch(key, options, next, loading, scope) {
        loading(true);

        let param = {
          dept_id: scope.dept_id,
          is_enabled: key
        }

        this.$axios.Account_department_status(param).then(res => {
          let data = res.data;

          loading(false);

          if (data && data.code == 0) {
            next();
            key == 1 ? this.$message.success('已启用') : this.$message.error('已停用');

            this.loadTableData();
          }
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .ng-account-members {
    /deep/ .ant-input-search {
      width: 360px;
      margin-right: $space-base;
    }
  }
</style>
