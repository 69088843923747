<template>
  <div class="p-20">
    <ng-detail title="基础信息">
      <coop-base-info ref="coopBaseInfo" :is-look="isLook"></coop-base-info>
    </ng-detail>
    <ng-detail title="授权信息">
      <coop-auth-info ref="coopAuthInfo" :is-look="isLook"></coop-auth-info>
    </ng-detail>
  </div>
</template>

<script>
  import CoopAuthInfo from './CoopAuthInfo.vue';
  import CoopBaseInfo from './CoopBaseInfo.vue';

  export default {
    data() {
      return {

      }
    },

    props: {
      isLook: Boolean,
    },

    components: {
      CoopAuthInfo, CoopBaseInfo
    },

    methods: {

      setDefaultValue(data) {
        let coopBaseInfo = this.$refs.coopBaseInfo ? this.$refs.coopBaseInfo : null;
        let coopAuthInfo = this.$refs.coopAuthInfo ? this.$refs.coopAuthInfo : null;

        data = {...data};

        this.setSingleDefaultValue(coopBaseInfo, {
          contract_no: data.contract_no,
          name: data.name,
          coop_time: [data.coop_start_date, data.coop_end_date],
          is_auto_renew: !!+data.is_auto_renew
        });

        data.is_maintain_rights = coopAuthInfo.isLegalRight = !!+data.is_maintain_rights;

        if (data.contract_file) {
          let fileName = data.contract_file_url.replace(/.*\//g, '');

          data.contract_file = [
            {uid: '1', name: fileName, path: data.contract_file, url: data.contract_url, status: 'done', }
          ]
        }

        this.setSingleDefaultValue(coopAuthInfo, data);

        if (data.auth_scene && data.auth_scene.length > 0) {
          coopAuthInfo.authSceneDefaultValue = data.auth_scene
        }

        if (data.contract_file) {
          let fileName = data.contract_file_url.replace(/.*\//g, '');

          coopAuthInfo.uploadFilesDefaultValue = [
            {uid: '1', name: fileName, path: data.contract_file_url, url: data.contract_file_url, status: 'done', }
          ]
        }
      },

      setSingleDefaultValue(ref, value) {
        if (ref) {
          let form = ref ? ref.form : null;

          if (form) {
            form.resetFields();

            this.isLook && form.setFieldsValue(value);
          }
        }
      },

      checkCoopBase() {
        return new Promise(resolve => {
          this.$refs.coopBaseInfo.form.validateFieldsAndScroll((errors, values) => {

            console.log(values);

            if (!errors) {
              // this.$emit('change', values);
              resolve(values);
            }
          })
        })
      },

      checkCoopAuth() {
        return new Promise(resolve => {
          this.$refs.coopAuthInfo.form.validateFieldsAndScroll((errors, values) => {

            values.auth_scene = Object.values(values.auth_scene);

            console.log(values);

            if (!errors) {
              // this.$emit('change', values);
              resolve(values);
            }
          })
        })
      },

      handleOk() {
        return new Promise(resolve => {
          Promise.all([this.checkCoopBase(), this.checkCoopAuth()]).then(res => {
            let value = res.reduce((a, b) => {
              return Object.assign(a, b);
            }, {})

            value.is_auto_renew = +!!value.is_auto_renew;
            value.is_maintain_rights = +!!value.is_maintain_rights;

            if (value.contract_file && value.contract_file.length > 0) {
              value.contract_file = value.contract_file[0].path;
            }

            if (value.coop_time && value.coop_time.length > 1) {
              value.coop_start_date = value.coop_time[0];
              value.coop_end_date = value.coop_time[1];
            }

            delete value.coop_time;

            resolve(value);
          })
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
