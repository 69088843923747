<template>
  <div class="load-page">
    <a-spin class="load-content" :spinning="loading" ref="loadBox" :style="scrollStyle">
      <template v-if="!isList">
        <template v-for="item in list">
          <slot name="item" :item="item"></slot>
        </template>
      </template>
      <template v-else>
        <slot name="list" :list="list"></slot>
      </template>
    </a-spin>

    <div class="pagination-box">
      <a-pagination :current="current" :total="total" :pageSize="pageSize" @change="onChange" />
    </div>
  </div>
</template>

<script>
  import {Pagination, Spin} from 'ant-design-vue';

  export default {
    data() {
      return {
        list: [],
        current: 1,
        loadParams: {},
        total: 1,
        pageSize: 20,
        loading: false,
        defaultPageSize: 10,
        mainContentScrollMaxHeight: 0,
        scrollStyle: {}
      }
    },

    created() {
      this.current = parseInt(this.$route.query.page || '1');
    },

    props: {
      request: Function,
      params: {
        type: Object,
        default() {
          return {}
        }
      },
      reload: Boolean,
      load: Boolean,
      isList: Boolean,
      isScroll: {
        type: Boolean,
        default: true
      }
    },

    mounted() {
      if (this.isScroll) {
        this.calcScrollHeight();

        this.scrollStyle = {
          height: `${this.mainContentScrollMaxHeight}px`,
          overflowY: 'auto'
        }
      }
    },

    watch: {
      params: {
        handler(newVal) {
          let {page} = newVal;

          this.current = page ? page : 1;

          this.goSearch({page: this.current})

          newVal = this.getParams(newVal);

          this.getData(newVal);
        },
        immediate: true
      },

      reload(newVal) {
        if (newVal) {
          this.current = 1;

          this.goSearch({page: this.current})

          let params = this.getParams();

          this.getData(params);

          this.$emit('update:reload', false);
        }
      },

      load(newVal) {
        if (newVal) {
          let params = this.getParams();

          this.getData(params);

          this.$emit('update:load', false);
        }
      }
    },

    components: {
      APagination: Pagination,
      ASpin: Spin
    },

    methods: {
      getData(params) {
        let request = this.request;

        if (typeof request !== 'function') return;

        this.loading = true;

        request(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            let {page_size, total} = data.data || {};

            this.list = data.data ? data.data.list : [];
            this.pageSize = page_size || this.defaultPageSize;
            this.total = total || 0;

            this.$emit('dataChange', this.list);
          }

          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })
      },

      onChange(page) {
        this.current = page;

        this.goSearch({page})

        let params = this.getParams();

        this.getData(params);
      },

      getParams(params) {
        return Object.assign({}, params ? params : this.params, {page: this.current});
      },

      goSearch(params) {
        let query = this.$route.query;

        params = Object.assign({}, query, params);

        this.$router.push({
          query: params
        });
      },

      calcScrollHeight(mainContent, needMinusBottomHeight = 100) {
        let pos;
        let clientHeight = document.body.clientHeight;

        mainContent = mainContent || this.$refs.loadBox.$el;

        pos = mainContent.getBoundingClientRect();

        this.mainContentScrollMaxHeight = clientHeight - pos.top - (needMinusBottomHeight || 0);
      },
    }
  }
</script>

<style lang="scss" scoped>
  .load-content {
    min-height: 300px;
    margin-bottom: 20px;
    padding: 0 20px;
    margin-left: -20px;
    margin-right: -20px;
  }

  .pagination-box {
    text-align: right;
  }
</style>
