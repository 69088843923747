<template>
  <a-card class="scene-item" :title="title">
    <!-- <span class="close-icon" slot="extra" @click="$emit('close', index)">&#9587;</span> -->

    <span slot="extra">
      <span>启用</span>&nbsp;&nbsp;
      <a-switch v-model="isStart" :loading="statusLoading" @change="onChangeStatus"></a-switch>
    </span>

    <div class="flex cen-space">
      <div class="left">
        <span class="m-right-base">折扣系数：{{data.ratio | toDecimals}}</span>
        <span>折扣期限：{{data.start_date}} - {{data.end_date}}</span>
      </div>
      <div class="right">
        <a-button type="link" class="m-right-base no-p-lr" @click="$emit('edit', index)">编辑</a-button>
        <a-button type="link" class="no-p-lr ant-btn-error" @click="$emit('del', index)">删除</a-button>
      </div>
    </div>
  </a-card>
</template>

<script>
  import {Card} from 'ant-design-vue';
  import {BusinessSceneOptions} from '@/config/options'

  export default {
    data() {
      return {
        form: this.$form.createForm(this, 'scene'),
        BusinessSceneOptions,
        isStart: false,

        statusLoading: false,
      }
    },
    props: {
      index: [String, Number],
      title: String,
      data: {
        type: Object,
        default() {
          return {}
        }
      },
      dis_id: String
    },

    // computed: {
    //   isCustom() {
    //     return !!this.goods_id;
    //   },

    //   statusRequest() {
    //     return this.isCustom ? this.$axios.Price_ChangeCustomSceneStatus : this.$axios.Price_ChangeCommonSceneStatus;
    //   }
    // },

    watch: {
      data: {
        handler(newVal) {
          if (newVal && newVal.is_enabled) {
            this.isStart = newVal.is_enabled == '1' ? true : false;
          }
        },
        immediate: true
      },
    },

    components: {
      ACard: Card,
      // ACollapse: Collapse,
      // ACollapsePanel: Collapse.Panel,
    },

    methods: {
      onChangeStatus(newVal) {
        let is_enabled = newVal ? '1' : '0';

        let params = {is_enabled, discount_id: this.dis_id};

        this.statusLoading = true;

        this.$axios.SongList_discountStatus(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success(newVal ? '启用成功' : '禁用成功');
          } else {
            // 回退
            this.isStart = !newVal;
          }

          this.statusLoading = false;
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .scene-item {
    /deep/ {
      .iconfont-trash {
        top: 20px !important;
      }
    }
  }

  .close-icon {
    font-size: 20px;
    cursor: pointer;
  }
</style>
