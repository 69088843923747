<template>
  <div class>
    <a-radio-group class="f-l" v-model="value" button-style="solid" @change="onChangeGoodCate">
      <a-radio-button :value="lib_tabs_id">曲库</a-radio-button>
      <a-radio-button
        v-for="item in goodCategray"
        :value="item.cate_id"
        :key="item.cate_id"
      >{{item.name}}</a-radio-button>
    </a-radio-group>

    <div class="f-l">
      <a-button
        v-if="$root.$checkAuth($auth[authName] && $auth[authName].addCate)"
        icon="plus"
        @click="addCate"
      ></a-button>
      <a-button
        v-if="$root.$checkAuth($auth[authName] && $auth[authName].editCate)"
        :disabled="value === lib_tabs_id"
        icon="edit"
        @click="editCate"
      ></a-button>
    </div>

    <slot :slot-scope="value"></slot>

    <dialog-add-good-type
      :visible.sync="showDialog"
      :default-value="handleData.data"
      :handle="handleData.handle"
      :id="value"
      @success="onSuccess"
    ></dialog-add-good-type>
  </div>
</template>

<script>
  import DialogAddGoodType from './DialogAddGoodType.vue';
  import {formatTime} from '@/utils/function';

  export default {
    data() {
      return {
        value: '',
        goodCategray: [],
        showDialog: false,

        handleData: {}
      }
    },
    components: {
      DialogAddGoodType
    },

    props: {
      authName: String,
      lib_tabs_id: String,
      scope: Object
    },

    created() {
      let good_cate = this.$route.query.good_cate;

      this.value = good_cate || this.lib_tabs_id;

      this.getGoodType();
    },

    watch: {
      value: {
        handler(newVal) {
          this.$emit('change', newVal);

          let scope = this.getCurGoodInfo(newVal);

          this.$emit('changeInfo', scope);
        },
        immediate: true
      }
    },

    methods: {
      getGoodType() {
        let params = {};

        return new Promise(resolve => {
          this.$axios.Good_categ_list(params).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              // something
              this.goodCategray = data.data.list;

              let scope = this.getCurGoodInfo(this.value);

              this.$emit('changeInfo', scope);
            }

            resolve(data)
          })
        })
      },

      getCurGoodInfo(value) {
        let list = this.goodCategray;
        let filterList = list.filter(item => item.cate_id === value);
        let scope = filterList && filterList.length > 0 ? filterList[0] : {};

        return scope;
      },


      addCate() {
        this.handleData.handle = this.$var.Handle.add;
        this.showDialog = true;
      },

      editCate() {
        let dValue = this.goodCategray.filter(item => item.cate_id === this.value);

        dValue = dValue && dValue.length > 0 ? dValue[0] : {};

        let ms = dValue.issue_time * 1000;

        dValue.issue_time > 0 && (dValue.issue_time = formatTime(ms, 'YYYY-MM-DD hh:mm:ss'))

        this.handleData.data = dValue;
        this.handleData.handle = this.$var.Handle.edit;
        this.showDialog = true;
      },

      onSuccess(isEdit) {
        this.getGoodType().then(data => {
          if (data && data.code == '0' && !isEdit) {
            let list = data.data.list;
            this.value = list && list.length > 0 ? list[list.length - 1].cate_id : this.lib_tabs_id;
          }
        })
      },

      onChangeGoodCate(event) {
        let value = event.target.value;

        this.$root.$goSearch({
          good_cate: value
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
