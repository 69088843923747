import {
  SupplierType,
  ServeType,
  Common
} from '@/utils/variables'

export default {
  // 成员管理
  account_member: {
    list: 'A_USER_LIST',
    add: 'A_USER_ADD',
    edit: 'A_USER_SAVE',
    del: 'A_USER_DEL',
    status: 'A_USER_UPSTATUS',
    look: 'A_USER_INFO'
  },
  // 部门
  account_dept: {
    list: 'A_DEPT_LIST',
    add: 'A_DEPT_ADD',
    edit: 'A_DEPT_SAVE',
    del: 'A_DEPT_DEL',
    status: 'A_DEPT_UPSTATUS',
    look: 'A_DEPT_INFO'
  },
  // 角色
  account_role: {
    list: 'A_DEPT_ROLE_LIST',
    add: 'A_DEPT_ADD_ROLE',
    edit: 'A_DEPT_ROLE_UPDATE',
    del: 'A_DEPT_ROLE_DELETE',
    status: 'A_DEPT_ROLE_UPSTATUS',
    look: 'A_ROLE_INFO'
  },
  // 角色
  verify_library: {
    list: 'A_MUSICLIB_SIGN_REVIEW',
  },
  // 音乐人审核
  [`verify_${SupplierType.MUSICIAN}`]: {
    list: 'A_REVIEW_MUSICIANS_LIST',
    handle: 'A_OP_REVIEW_MUSICIANS',
    look: 'A_REVIEW_MUSICIANS_INFO',
  },
  // 音乐团队审核
  [`verify_${SupplierType.TEAM}`]: {
    list: 'A_REVIEW_MUSICIANS_INFO',
    handle: 'A_OP_REVIEW_TEAM',
    look: 'A_REVIEW_TEAM_INFO',
  },
  // 版权公司审核
  [`verify_${SupplierType.COMPANY}`]: {
    list: 'A_REVIEW_COMPANY_LIST',
    handle: 'A_OP_REVIEW_COMPANY',
    look: 'A_REVIEW_COMPANY_INFO',
  },
  // 音乐人管理
  [`supplier_${SupplierType.MUSICIAN}`]: {
    list: 'A_SUPPLIER_MUSICIAN_LIST',
    add: 'A_SUPPLIER_ADD_MUS_BASEINFO',
    edit: 'A_SUPPLIER_UP_MUS_BASEINFO',
    status: 'A_SUPPLIER_UP_MUSICIAN_STATUS',
    del: 'A_SUPPLIER_DELETE_MUSICIAN',
    look: 'A_SUPPLIER_MUSICIAN_BASEINFO',
    auth: 'A_SUPPLIER_SET_MUSICIAN_ACCESS', // 配置权限
    manageLibrary: 'A_MUSICLIB_LIST_6',
    manageContract: 'A_ADD_CONTRACT_6'

  },
  // 音乐团队管理
  [`supplier_${SupplierType.TEAM}`]: {
    list: 'A_SUPPLIER_TEAM_LIST',
    add: 'A_SUPPLIER_ADD_TEAM_BASEINFO',
    edit: 'A_SUPPLIER_UP_TEAM_BASEINFO',
    status: 'A_SUPPLIER_UP_TEAM_STATUS',
    del: 'A_SUPPLIER_DELETE_TEAM',
    look: 'A_SUPPLIER_TEAM_BASEINFO',
    auth: 'A_SUPPLIER_SET_TEAM_ACCESS',
    manageContract: 'A_ADD_CONTRACT_7',
  },
  // 版权公司管理
  [`supplier_${SupplierType.COMPANY}`]: {
    list: 'A_SUPPLIER_COMPANY_LIST',
    add: 'A_SUPPLIER_ADD_COMP_BASEINFO',
    edit: 'A_SUPPLIER_UP_COMP_BASEINFO',
    status: 'A_SUPPLIER_UP_COMPANY_STATUS',
    del: 'A_SUPPLIER_DELETE_COMPANY',
    look: 'A_SUPPLIER_COMPANY_BASEINFO',
    auth: 'A_SUPPLIER_SET_COMPANY_ACCESS',
    manageLibrary: 'A_MUSICLIB_LIST_8',
    manageContract: 'A_ADD_CONTRACT_8'
  },
  // 音乐人信息
  [`supplier_${SupplierType.MUSICIAN}_info`]: {
    base: 'A_SUPPLIER_MUSICIAN_BASEINFO',
    addBase: 'A_SUPPLIER_ADD_MUS_BASEINFO',
    editBase: 'A_SUPPLIER_UP_MUS_BASEINFO',

    addressList: 'A_SUPPLIER_EXPRESS_LIST_6',
    addressInfo: 'A_SUPPLIER_EXPRESS_INFO_6',
    addAddress: 'A_SUPPLIER_ADD_EXPRESS_6',
    editAddress: 'A_SUPPLIER_UP_EXPRESS_6',
    delAddress: 'A_SUPPLIER_DEL_EXPRESS_6',
    setDefaultAddress: 'A_SUPPLIER_SET_DEFAULT_EXPRS_6',

    receiptList: 'A_SUPPLIER_RECEIPT_LIST_6',
    receiptInfo: 'A_SUPPLIER_RECEIPT_INFO_6',
    addReceipt: 'A_SUPPLIER_ADD_RECEIPT_6',
    editReceipt: 'A_SUPPLIER_UP_RECEIPT_6',
    delReceipt: 'A_SUPPLIER_DEL_RECEIPT_6',
    setDefaultReceipt: 'A_SUPPLIER_SET_DEFAULT_RECEIPT_6',
    verifyInfo: 'A_SUPPLIER_AUTH_INFO_6',
    accountInfo: 'A_SUPPLIER_ACCOUNT_INFO_6',
    accountChangePhone: 'A_SUPPLIER_UP_PHONE_6',
    accountChangeEmail: 'A_SUPPLIER_UP_EMAIL_6',
    accountSelfLink: 'A_SUPPLIER_OP_SELF_LINK_6'
  },
  // 音乐团队信息
  [`supplier_${SupplierType.TEAM}_info`]: {
    base: 'A_SUPPLIER_TEAM_BASEINFO',
    addBase: 'A_SUPPLIER_ADD_TEAM_BASEINFO',
    editBase: 'A_SUPPLIER_UP_TEAM_BASEINFO',
    linkList: 'A_SUPPLIER_CONTACT_LIST_7',
    linkInfo: 'A_SUPPLIER_CONTACT_INFO_7',
    addLink: 'A_SUPPLIER_ADD_CONTACT_7',
    editLink: 'A_SUPPLIER_UP_CONTACT_7',
    delLink: 'A_SUPPLIER_DEL_CONTACT_7',
    addressList: 'A_SUPPLIER_EXPRESS_LIST_7',
    addressInfo: 'A_SUPPLIER_EXPRESS_INFO_7',
    addAddress: 'A_SUPPLIER_ADD_EXPRESS_7',
    editAddress: 'A_SUPPLIER_UP_EXPRESS_7',
    delAddress: 'A_SUPPLIER_DEL_EXPRESS_7',
    setDefaultAddress: 'A_SUPPLIER_SET_DEFAULT_EXPRS_7',
    receiptList: 'A_SUPPLIER_RECEIPT_LIST_7',
    receiptInfo: 'A_SUPPLIER_RECEIPT_INFO_7',
    addReceipt: 'A_SUPPLIER_ADD_RECEIPT_7',
    editReceipt: 'A_SUPPLIER_UP_RECEIPT_7',
    delReceipt: 'A_SUPPLIER_DEL_RECEIPT_7',
    setDefaultReceipt: 'A_SUPPLIER_SET_DEFAULT_RECEIPT_7',
    verifyInfo: 'A_SUPPLIER_AUTH_INFO_7',
    accountInfo: 'A_SUPPLIER_ACCOUNT_INFO_7',
    accountChangePhone: 'A_SUPPLIER_UP_PHONE_7',
    accountChangeEmail: 'A_SUPPLIER_UP_EMAIL_7',
    accountSelfLink: 'A_SUPPLIER_OP_SELF_LINK_7'
  },
  // 版权公司信息
  [`supplier_${SupplierType.COMPANY}_info`]: {
    base: 'A_SUPPLIER_COMPANY_BASEINFO',
    addBase: 'A_SUPPLIER_ADD_COMP_BASEINFO',
    editBase: 'A_SUPPLIER_UP_COMP_BASEINFO',

    linkList: 'A_SUPPLIER_CONTACT_LIST_8',
    linkInfo: 'A_SUPPLIER_CONTACT_INFO_8',
    addLink: 'A_SUPPLIER_ADD_CONTACT_8',
    editLink: 'A_SUPPLIER_UP_CONTACT_8',
    delLink: 'A_SUPPLIER_DEL_CONTACT_8',

    addressList: 'A_SUPPLIER_EXPRESS_LIST_8',
    addressInfo: 'A_SUPPLIER_EXPRESS_INFO_8',
    addAddress: 'A_SUPPLIER_ADD_EXPRESS_8',
    editAddress: 'A_SUPPLIER_UP_EXPRESS_8',
    delAddress: 'A_SUPPLIER_DEL_EXPRESS_8',
    setDefaultAddress: 'A_SUPPLIER_SET_DEFAULT_EXPRS_8',

    receiptList: 'A_SUPPLIER_RECEIPT_LIST_8',
    receiptInfo: 'A_SUPPLIER_RECEIPT_INFO_8',
    addReceipt: 'A_SUPPLIER_ADD_RECEIPT_8',
    editReceipt: 'A_SUPPLIER_UP_RECEIPT_8',
    delReceipt: 'A_SUPPLIER_DEL_RECEIPT_8',
    setDefaultReceipt: 'A_SUPPLIER_SET_DEFAULT_RECEIPT_8',

    verifyInfo: 'A_SUPPLIER_AUTH_INFO_8',

    accountInfo: 'A_SUPPLIER_ACCOUNT_INFO_8',
    accountChangePhone: 'A_SUPPLIER_UP_PHONE_8',
    accountChangeEmail: 'A_SUPPLIER_UP_EMAIL_8',
    accountSelfLink: 'A_SUPPLIER_OP_SELF_LINK_8'
  },

  // 音乐人曲库
  [`library_${SupplierType.MUSICIAN}`]: {
    list: 'A_MUSICLIB_LIST_6',
    look: 'A_MUSICLIB_BASE_INFO_6',
    edit: 'A_MUSICLIB_SAVE_INFO_6',
    add: 'A_MUSICLIB_DO_ADD_6',
    lookView: 'A_MUSICLIB_VIEW_SIGN_6', // 查看合约
    sign: 'A_MUSICLIB_DO_SIGN_6', // 曲库签约
    batchList: 'A_BATCH_LIST_6', // 批次列表
  },

  // 音乐团队曲库
  [`library_${SupplierType.TEAM}`]: {
    list: 'A_MUSICLIB_LIST_7',
    look: 'A_MUSICLIB_BASE_INFO_7',
    edit: 'A_MUSICLIB_SAVE_INFO_7',
    add: 'A_MUSICLIB_DO_ADD_7',
    batchList: 'A_BATCH_LIST_7', // 批次列表
    // lookView: 'A_MUSICLIB_VIEW_SIGN_7', // 查看合约
    // sign: 'A_MUSICLIB_DO_SIGN_7', // 曲库签约
  },

  // 版权公司曲库
  [`library_${SupplierType.COMPANY}`]: {
    list: 'A_MUSICLIB_LIST_8',
    look: 'A_MUSICLIB_BASE_INFO_8',
    edit: 'A_MUSICLIB_SAVE_INFO_8',
    add: 'A_MUSICLIB_DO_ADD_8',
    lookView: 'A_MUSICLIB_VIEW_SIGN_8', // 查看合约
    sign: 'A_MUSICLIB_DO_SIGN_8', // 曲库签约
    batchList: 'A_BATCH_LIST_8', // 批次列表
  },

  // 批次管理 - 音乐人
  [`batch_${SupplierType.MUSICIAN}`]: {
    list: 'A_MUSICLIB_LIST_6',
    add: 'A_BATCH_ADD_6',
    store: 'A_BATCH_LAUNCH_STORE_6', // 发起入库
  },

  // 批次管理 - 音乐团队
  [`batch_${SupplierType.TEAM}`]: {
    list: 'A_MUSICLIB_LIST_7',
    add: 'A_BATCH_ADD_7',
    store: 'A_BATCH_LAUNCH_STORE_7', // 发起入库
  },

  // 批次管理 - 版权公司
  [`batch_${SupplierType.COMPANY}`]: {
    list: 'A_MUSICLIB_LIST_8',
    add: 'A_BATCH_ADD_8',
    store: 'A_BATCH_LAUNCH_STORE_8', // 发起入库
  },

  // 批次管理 - 版权公司
  // [`batch_manage`]: {
  //   list: 'A_MUSICLIB_LIST_8',
  //   add: 'A_BATCH_ADD_8',
  //   store: 'A_BATCH_LAUNCH_STORE_8', // 发起入库
  // },

  // 入库管理 待服务
  [`enterlibrary_${ServeType.UNDO}`]: {
    list: 'A_BATCH_UNSTORE_LIST',
    direction: 'A_TASK_ADD_STORE', // 指派
  },

  // 入库管理 服务中
  [`enterlibrary_${ServeType.DOING}`]: {
    list: 'A_BATCH_STORING_LIST',
    deliver: 'A_BATCH_STORE_DELIVER', // 交付
    progress: 'A_BATCH_STORE_PROGRESS',
    lookDetail: 'A_BATCH_STORE_PROGRESS_INFO', // 查看音乐
  },

  // 入库管理 已完成
  [`enterlibrary_${ServeType.DONE}`]: {
    list: 'A_BATCH_STORE_DONE_LIST',
    goTag: 'A_BATCH_LAUNCH_TAG', // 发起标注
  },

  // 入库任务 待服务
  [`enterlibraryTask_${ServeType.UNDO}`]: {
    list: 'A_TASK_UNSTORE_LIST',
    getTask: 'A_TASK_STORE_CLAIM', // 认领
  },

  // 入库任务 服务中
  [`enterlibraryTask_${ServeType.DOING}`]: {
    list: 'A_TASK_STORING_LIST',
    deliver: 'A_TASK_STORE_DELIVER', // 交付
    lookMusic: 'A_TASK_STORED_MUSIC_14',
    uploadMusicMusic: 'A_MUSIC_IMPORT', // 上传音乐
  },

  // 入库任务 已完成
  [`enterlibraryTask_${ServeType.DONE}`]: {
    list: 'A_TASK_STORE_DONE_LIST',
  },

  // 音乐列表 入库任务 入库中 查看
  [`musicManage_${Common.first}`]: {
    del: 'A_MUSIC_DEL_14',
    edit: 'A_TASK_SAVE_STORED_MUSIC_14'
  },

  // 音乐列表 入库任务 入库完成 查看
  [`musicManage_${Common.second}`]: {
    del: 'A_MUSIC_DEL_15',
    edit: 'A_TASK_SAVE_STORED_MUSIC_15'
  },

  // 音乐列表 入库管理 入库中 查看
  [`musicManage_${Common.third}`]: {
    del: 'A_MUSIC_DEL_10',
    edit: 'A_TASK_SAVE_STORED_MUSIC_10'
  },

  // 标注管理 待服务
  [`mark_${ServeType.UNDO}`]: {
    list: 'A_BATCH_UNTAG_LIST',
    direction: 'A_TASK_ADD_TAG', // 指派
  },

  // 标注管理 服务中
  [`mark_${ServeType.DOING}`]: {
    list: 'A_BATCH_TAGING_LIST',
    deliver: 'A_BATCH_TAG_DELIVER', // 交付
    progress: 'A_BATCH_TAG_PROGRESS', // 查看进度
    lookDetail: 'A_BATCH_TAG_PROGRESS_INFO', // 查看音乐
  },

  // 标注管理 已完成
  [`mark_${ServeType.DONE}`]: {
    list: 'A_BATCH_TAG_DONE_LIST',
    info: 'A_TAG_INFO_19', // 
  },

  // 标注任务 待服务
  [`markTask_${ServeType.UNDO}`]: {
    list: 'A_TASK_UNTAG_LIST',
    getTask: 'A_TASK_TAG_CLAIM', // 认领
  },

  // 标注任务 服务中
  [`markTask_${ServeType.DOING}`]: {
    list: 'A_TASK_TAGING_LIST',
    info: 'A_TASK_TAG_INFO', // 任务详情
    deliver: 'A_TASK_TAG_DELIVER', // 交付
    markReject: 'A_TAG_ADD_UNDOED', // 标注驳回任务
    markInstance: 'A_TAG_ADD_INST', // 标注即时任务
  },

  // 标注任务 已完成
  [`markTask_${ServeType.DONE}`]: {
    list: 'A_TASK_TAG_DONE_LIST',
    lookMusic: 'A_TASK_TAGED_MUSIC', // 查看音乐
  },

  // 音乐管理 标注任务 服务中 查看
  [`musicManage_mark_${Common.first}`]: {
    info: 'A_TAG_INFO_21',
    editMusic: 'A_TAG_SAVE_21', // 修改标注音乐
    reject: 'A_TAG_UNDO_21', // 修改标注音乐
  },

  // 音乐管理 标注任务 已完成 查看
  [`musicManage_mark_${Common.second}`]: {
    info: 'A_TAG_INFO_22',
    editMusic: 'A_TAG_SAVE_22', // 修改标注音乐
    reject: 'A_TAG_UNDO_22', // 修改标注音乐
  },

  // 音乐管理 标注管理 服务中 进度 查看
  [`musicManage_mark_${Common.third}`]: {
    info: 'A_TAG_INFO_18',
    editMusic: 'A_TAG_SAVE_18', // 修改标注音乐
    reject: 'A_TAG_UNDO_18', // 修改标注音乐
  },

  // 音乐管理 标注管理 已完成 查看
  [`musicManage_mark_${Common.fourth}`]: {
    info: 'A_TAG_INFO_19',
    editMusic: 'A_TAG_SAVE_19', // 修改标注音乐
    reject: 'A_TAG_UNDO_19', // 修改标注音乐
  },

  // 标注音乐 - 标注任务 服务中
  markMusic: {
    tag: 'A_TAG_ADD', // 标注
    delMusic: 'A_MUSIC_DEL_21', // 删除音乐
    editMusicName: 'A_MUSIC_SAVE_NAME_21', // 修改音乐
    editMusicLogo: 'A_MUSIC_SAVE_LOGO_21', // 修改音乐封面
    editMusicType: 'A_MUSIC_SAVE_SOUNDTYPE_21', // 修改音乐类型
  },
  // [`markMusic_${Common.first}`]: {
  //   tag: 'A_TAG_ADD', // 标注
  //   tagInst: 'A_TAG_ADD_INST', // 标注即时任务
  //   tagReject: 'A_TAG_ADD_UNDOED', // 标注驳回任务
  //   delMusic: 'A_MUSIC_DEL_21', // 删除音乐
  //   editMusicName: 'A_MUSIC_SAVE_NAME_21', // 修改音乐
  //   editMusicLogo: 'A_MUSIC_SAVE_LOGO_21', // 修改音乐封面
  //   editMusicType: 'A_MUSIC_SAVE_SOUNDTYPE_21', // 修改音乐类型
  // },

  // 标注音乐 - 标注任务 已完成 查看
  // [`markMusic_${Common.second}`]: {
  //   info: 'A_TAG_INFO_18',
  // },

  // // 标注音乐 - 标注管理 服务中 进度 查看
  // [`markMusic_${Common.third}`]: {
  //   info: 'A_TAG_INFO_18',
  // },

  // // 标注音乐 - 标注管理 已完成 查看
  // [`markMusic_${Common.fourth}`]: {
  //   info: 'A_TAG_INFO_18',
  // },

  // 标签管理
  tagManage: {
    list: 'A_LABEL_LIST',

    addTag: 'A_LABEL_ADD',
    editTag: 'A_LABEL_SAVE', // 修改标签
    delTag: 'A_LABEL_DEL',
    tagInfo: 'A_LABEL_INFO',

    addCate: 'A_LABEL_ADD_CATE',
    editCate: 'A_LABEL_SAVE_CATE',
    delCate: 'A_LABEL_DEL_CATE',
    cateInfo: 'A_LABEL_CATE_INFO',

    editCateSatus: 'A_LABEL_UP_CATESTATE',
  },

  // 曲库音乐管理
  library_manage: {
    list: 'A_MUSICLIB_ALL_LIST_24',
    add: 'A_MUSICLIB_ADD_24',
    look: 'A_MUSICLIB_MUSIC_LIST_24',
    edit: 'A_MUSICLIB_EDIT_24',
    batchList: 'A_BATCH_LIST_24', // 批次列表
  },

  goods_manage: {
    customePriceInfo: 'A_PRICE_CUSTOM_INFO',
    commonPriceInfo: 'A_PRICE_COMMON_INFO',

    getCost: 'A_PRICE_GET_COST', // 配置成本价
    addCate: 'A_GOODS_ADD_CATE',
    editCate: 'A_GOODS_SAVE_CATE',

    goodInfo: 'A_GOODS_INFO',
    addGood: 'A_GOODS_ADD',
    editGood: 'A_GOODS_SAVE',
    editGoodStatus: 'A_GOODS_UP_GOODS_STAT',
  },

  sell_config: {
    editScene: 'A_PRICE_SAVE_CUSTOM_SCENE', // 编辑场景
    editAuth: 'A_PRICE_SAVE_CUSTOM_AUTH', // 编辑授权
  },

  user_manage: {
    list: 'A_VIP_USER_LIST',
    look: 'A_VIP_USER_INFO',
    order: 'A_ORDER_LIST',
    deeds: 'A_DEED_LIST'
  },

  channel_order: {
    list: 'A_ORDER_LIST',
    look: 'A_ORDER_INFO'
  },

  package_order: {
    list: 'A_GOODS_ORDER_LIST',
    look: 'A_GOODS_ORDER_INFO'
  },

  // 歌单管理
  song_list: {
    list: 'A_PLAYLIST_LIST',
    look: 'A_PLAYLIST_INFO',
    add: 'A_PLAYLIST_ADD',
    edit: 'A_PLAYLIST_SAVE',
    status: 'A_PLAYLIST_UP_SALE',
    del: 'A_PLAYLIST_DEL',
    discount: 'A_PLAYLISTDCT_LIST'
  },

  package_buyRecord: {
    list: 'A_VIP_GOODS_LIST',
    look: 'A_VIP_GOODS_INFO',
  },

  receipt_manage: {
    list: 'A_ORDER_INVOICE_LIST',
    look: 'A_ORDER_INVOICE_INFO',
    edit: 'A_ORDER_INVOICE_SAVE',
    operation: 'A_ORDER_INVOICE_OPERATE',
  },

  contract_manage: {
    list: 'A_ORDER_CONTRACT_LIST',
    look: 'A_ORDER_CONTRACT_INFO',
    edit: 'A_ORDER_CONTRACT_SAVE',
    operation: 'A_ORDER_CONTRACT_OPERATE',
    download: 'A_ORDER_CONTRACT_DOWNLOAD',
  }
}