<template>
  <main-structure class="ng-account-members" dir="ver" :key="$route.meta.type">
    <template slot="header">
      <span class="title">{{$route.meta.type | toMusicType}}管理</span>

      <div class="header-content">
        <a-input-search
          class="ng-input-search"
          placeholder="请输入音乐ID、音乐名称、音乐原名查询"
          :default-value="$route.query.keyword || ''"
          @search="onSearch"
          allowClear
          style="width: 350px;"
        >
          <template v-slot:enterButton>
            <a-button type="sub">
              <a-icon type="search" />
            </a-button>
          </template>
        </a-input-search>
      </div>
    </template>

    <template>
      <div class="filter-box">
        <component
          class="m-top-base"
          :is="getFilterComponents($route.meta.type)"
          :default-data="$route.query || {}"
          :type="$route.meta.type"
          @change="onFilterChange"
        ></component>
      </div>

      <div class="m-top-base flex-1" ref="ngTableBox">
        <component
          :is="getComponents($route.meta.type)"
          :scrolly-height="mainContentScrollMaxHeight"
          :auth-name="name"
          :params="tableParams"
          :type="MusicTypeText[$route.meta.type]"
          ref="manageTable"
        ></component>
      </div>
    </template>

    <dialog-add-to-music-list :visible.sync="showDialog" :music_id="handleData.music_id"></dialog-add-to-music-list>
  </main-structure>
</template>

<script>
  import TableMusicBgm from './musicManage/TableMusicBgm.vue';
  import TableMusicRs from './musicManage/TableMusicRs.vue';
  import TableMusicYx from './musicManage/TableMusicYx.vue';
  import FilterMusicBgmAndRs from './musicManage/FilterMusicBgmAndRs.vue';
  import FilterMusicYx from './musicManage/FilterMusicYx.vue';

  import DialogAddToMusicList from './musicManage/DialogAddToMusicList.vue';

  import Observe from '~/utils/observe.c.js'

  import {mainContentMixin, filterReq} from '@/mixins';
  import {MusicTypeText} from '@/utils/variables'
  import {MusicType} from '@/config/options.value'

  import {initParams} from './musicManage/_mixins';

  const componentFilterDir = {
    [MusicType.BGM]: 'FilterMusicBgmAndRs',
    [MusicType.RS]: 'FilterMusicBgmAndRs',
    [MusicType.YX]: 'FilterMusicYx',
  }

  const componentDir = {
    [MusicType.BGM]: 'TableMusicBgm',
    [MusicType.RS]: 'TableMusicRs',
    [MusicType.YX]: 'TableMusicYx',
    // [MusicType.YX]: 'TableMusicBgm',
  }


  export default {
    data() {
      return {
        name: `music_manage`,
        isSuccess: true,

        MusicTypeText,

        tableParams: {},

        showDialog: false,
        handleData: {}
      }
    },
    components: {
      TableMusicBgm, TableMusicRs, TableMusicYx, FilterMusicBgmAndRs, FilterMusicYx, DialogAddToMusicList
    },

    mixins: [
      mainContentMixin, filterReq
    ],

    mounted() {
      let {query} = this.$route;

      query = initParams({...query}, this.$route.meta.type);

      this.tableParams = Object.assign({}, query, {
        page: query.page || 1
      });

      Observe.subscribe('addMusicToSongList', this.addMusicToSongList);
    },

    destroyed() {
      Observe.unsubscribe('addMusicToSongList');
    },

    methods: {
      addMusicToSongList(scope) {
        this.showDialog = true;

        this.handleData = scope;
      },

      onSearch(keyword) {
        typeof keyword === 'string' && (keyword = keyword.trim());

        this.$root.$goSearch({keyword});

        this.tableParams = Object.assign({}, this.tableParams, {keyword});
      },

      getComponents(type) {
        return componentDir[type];
      },

      getFilterComponents(type) {
        return componentFilterDir[type];
      },

      onFilterChange() {
        let {query} = this.$route;

        query = initParams({...query}, this.$route.meta.type);

        this.tableParams = this.filterCommon(query);
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
