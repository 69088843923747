<template>
  <a-select v-bind="$props" :options="listOptions" v-on="$listeners" :filter-option="filterOption" placeholder="placeholder"></a-select>
</template>

<script>
  import {Select} from 'ant-design-vue';

  let props = Object.assign({}, Select.props);

  delete props.open;
  delete props.filterOption;
  delete props.placeholder

  export default {
    data() {
      return {
        list: []
      }
    },

    props: {
      ...props,
    },

    computed: {
      listOptions() {
        return this.list.map(({user_id, name}) => ({label: name, value: user_id}))
      }
    },

    created() {
      this.getData();
    },

    methods: {
      filterOption(input, option) {
        return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      },

      getData() {
        this.$axios.GetValuableSuppliers({}).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            let options = data.data ? data.data.list : [];

            this.list = options;
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
