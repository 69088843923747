import * as $func from '@/utils/function'

export default {
  data() {
    return {}
  },
  methods: {
    onTableDataChange(data, format, removeRepeat = true, child = false, childName = 'child') {
      let list = data.data.list || [];

      format = Object.assign({url: 'music_url', name: 'name'}, format || {});

      if (child) list = $func.flatArray(list, childName);

      list = list.map(item => ({url: item[format.url], name: item[format.name]}))

      removeRepeat && (list = list.filter((item, index) => index % 2))

      this.$store.dispatch('changeVideoList', list)
    },

    onHandleDataFunc(data, keyname) {
      if (data.data.list && data.data.list.length > 0) {
        data.data.list = data.data.list.reduce((a, b) => {
          return a.concat(b, Object.assign({}, b, {
            [keyname]: `${b[keyname]}rep`
          }))
        }, [])

        data.data.page_size *= 2;
        data.data.total *= 2;
      }

      return data;
    },

  }
}