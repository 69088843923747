import Vue from 'vue';
import VueRouter from 'vue-router';

// 解决跳转路由报不能跳转同一路由的错误
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

Vue.use(VueRouter);

const Login = () => import('@/pages/login.vue');
const Index = () => import('@/pages/index.vue');
const Userinfo = () => import('@/pages/userinfo.vue');

const Common403 = () => import('@/pages/common/403.vue');
const AuthBook = () => import('@/pages/common/AuthBook.vue');

import AccountRoute from './account';
import SupplierRoute from './verify';
import VerifyRoute from './supplier';
import LibraryRoute from './library';
import LibraryEnterRoute from './libraryEnter'
import libraryMarkRoute from './mark'
import musicManage from './musicManage'
import Tags from './tags'
import Goods from './goods'
import User from './user'
import orderManage from './orderManage'
import songList from './songList'
import packageManage from './package'
import buyManage from './buyManage'
import income from './income'
import eventManage from './eventManage'
import webdata from './webdata'
import earnings from './earnings'
import home from './home'
const Other = [{
  path: '/account/userinfo',
  name: 'accountUserinfo',
  component: Userinfo
}]

let routes = [{
    path: '/login',
    component: Login
  },
  {
    path: '/',
    component: Index,
    children: [
      ...AccountRoute,
      ...VerifyRoute,
      ...SupplierRoute,
      ...LibraryRoute,
      ...LibraryEnterRoute,
      ...libraryMarkRoute,
      ...musicManage,
      ...Other,
      ...Tags,
      ...Goods,
      ...User,
      ...orderManage,
      ...songList,
      ...packageManage,
      ...buyManage,
      ...income,
      ...eventManage,
      ...webdata,
      ...earnings,
      ...home,
    ]
  },
  {
    path: '/403',
    component: Common403
  },
  {
    path: '/authbook/:auth_id',
    component: AuthBook
  },
]

export default new VueRouter({
  routes
})