<template>
  <a-modal
    class="modal-no-lr-padding"
    width="640px"
    v-model="show"
    :title="title"
    :maskClosable="false"
  >
    <div style="height: 500px;overflow-y: auto;">
      <good-group ref="coopGroup" :key="groupKey" :goodInfo="goodInfo"></good-group>
    </div>

    <template slot="footer">
      <a-button key="back" @click="handleCancel">取消</a-button>

      <a-button
        v-if="handle !== $var.Handle.edit"
        key="save"
        type="primary"
        @click="handleOk(false)"
        :loading="confirmLoading && saveBtnType == statusDir.saveAndContinus"
      >保存并继续</a-button>

      <a-button
        key="submit"
        type="primary"
        @click="handleOk(true)"
        :loading="confirmLoading && saveBtnType == statusDir.save"
      >保存</a-button>
    </template>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import GoodGroup from './goodInfo/GoodGroup.vue';

  export default {
    data() {
      return {
        show: false,

        confirmLoading: false,

        defaultData: {},

        statusDir: {
          save: '2',
          saveAndContinus: '1'
        },

        saveBtnType: '', // 1（保存并继续） 2（保存）

        groupKey: 1,
      }
    },

    props: {
      handle: String,
      defaultValue: {
        type: Object,
        default() {
          return {}
        }
      },
      id: String,
      cate_id: String,
      goodInfo: Object
    },

    components: {
      GoodGroup
    },

    computed: {
      isEdit() {
        return this.handle === this.$var.Handle.edit;
      },

      title() {
        return this.isEdit ? '编辑商品' : '新增商品';
      },

      request() {
        return this.isEdit ? this.$axios.Good_edit : this.$axios.Good_add;
      }
    },

    watch: {
      visible(newVal) {
        if (newVal) {
          this.groupKey++;

          this.$nextTick(() => {
            if (this.isEdit) {
              this.$refs.coopGroup.setDefaultValue(this.defaultValue);
            }
          })
        }
      }
    },


    mixins: [dialogMixins],

    methods: {

      handleOk(isClose) {
        this.saveBtnType = isClose ? this.statusDir.save : this.statusDir.saveAndContinus;

        this.$refs.coopGroup.handleOk().then(value => {
          this.isEdit && (value.goods_id = this.id)

          value.cate_id = this.cate_id;
          value.discount_ratio = value.discount_ratio * 100;

          this.submit(value);
        });
      },

      submit(params) {
        let message = this.isEdit ? '编辑成功' : '新增成功';

        this.confirmLoading = true;

        typeof this.request === 'function' && this.request(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success(message);
            this.$emit('success', data);

            this.groupKey++;

            if (this.saveBtnType === this.statusDir.save) {
              this.show = false;
            }
          }

          this.confirmLoading = false;
        }).catch(() => {
          this.confirmLoading = false;
        })
      },

      handleCancel() {
        this.show = false;
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
