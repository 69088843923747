<template>
  <a-form class="ng-form-style ng-filter" :form="form" layout="inline">
    <a-form-item :label="addressName">
      <form-country
        all
        changeOnSelect
        expandTrigger="hover"
        :rank="type === SupplierType.MUSICIAN ? 'first' : 'third'"
        :method="adressRequestDir[type]"
        placeholder="请选择"
        v-decorator="['address_id', {initialValue: defaultData.address_id || '0'}]"
      ></form-country>
    </a-form-item>

    <a-form-item label="入驻时间">
      <a-range-picker
        v-decorator="['time', {initialValue: defaultData.time}]"
        valueFormat="YYYY-MM-DD"
        style="width: 250px;"
      ></a-range-picker>
    </a-form-item>

    <a-form-item label="状态">
      <form-member-status
        v-decorator="['is_enabled', {initialValue: defaultData.is_enabled || ''}]"
        placeholder="请选择"
      ></form-member-status>
    </a-form-item>
  </a-form>
</template>

<script>
  import {DatePicker} from 'ant-design-vue';
  import {VerifyStatusOptions, VerifyResultOptions} from '@/config/options'
  import {FormCountry} from '@/components/index';
  import {FormMemberStatus} from '@/components/index'
  import {SupplierType} from '@/utils/variables'

  const allOptions = {label: '全部', value: ''};

  const AdressDir = {
    musician: '国籍',
    team: '所在地',
    company: '经营地'
  }

  const adressRequestDir = {
    [SupplierType.MUSICIAN]: 'Supplier_musicianAddress',
    [SupplierType.TEAM]: 'Supplier_teamAddress',
    [SupplierType.COMPANY]: 'Supplier_companyAddress'
  }

  export default {
    data() {
      return {
        VerifyStatusOptions: [{...allOptions}, ...VerifyStatusOptions],
        VerifyResultOptions: [{...allOptions}, ...VerifyResultOptions],

        disabledResult: false,

        SupplierType,

        adressRequestDir,
      }
    },

    computed: {
      addressName() {
        return AdressDir[this.type];
      }
    },

    props: {
      type: String,
      defaultData: Object,
    },

    components: {
      FormCountry, FormMemberStatus,
      ARangePicker: DatePicker.RangePicker
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        onValuesChange: (props, values) => {
          this.$root.$goSearch(values);

          this.$emit('change', values);
        }
      });
    },

    methods: {
    }
  }
</script>

<style lang="scss" scoped>
  .ng-filter {
  }
</style>
