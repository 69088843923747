<template>
  <main-structure class="ng-account-members" dir="ver">
    <template slot="header">
      <span class="title">歌单详情</span>

      <div class="header-content">
        <!-- <a-button class="m-right-base" type="sub">配置折扣</a-button> -->
        <a-button type="sub" @click="$router.push('/musicmanage/bgm')">添加音乐</a-button>
      </div>
    </template>

    <template>
      <div class="m-top-ms flex-0">
        <ng-breadcrumb :data="breadData"></ng-breadcrumb>
      </div>

      <div class="m-top-md p-20">
        <ng-detail title="基础信息">
          <base-info :requestData="requestData"></base-info>
        </ng-detail>
        <ng-detail class="m-top-md" title="音乐信息">
          <music-list :sid="$route.params.sid"></music-list>
        </ng-detail>
      </div>
    </template>
  </main-structure>
</template>

<script>
  import BaseInfo from './detail/SongListDetailBaseInfo.vue'
  import MusicList from './detail/MusicList.vue'

  const breadData = [
    {breadcrumbName: `歌单管理`, path: `/songlist`},
    {breadcrumbName: '歌单详情'}
  ]

  export default {
    data() {
      return {
        data: {},
        breadData: breadData,
        requestData: {}
      }
    },
    components: {
      BaseInfo,
      MusicList
    },
    created() {
      this.getData();
    },
    methods: {
      getData() {
        let param = {playlist_id: this.$route.params.sid};

        this.$axios.Song_info(param).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.requestData = data.data;
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
