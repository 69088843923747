<template>
  <a-modal
    width="500px"
    v-model="show"
    title="指派标注任务"
    destroyOnClose
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div>
      <p class="font-grey">
        <span>该任务包含待标注音乐 {{totalSongs}} 首，可指派 {{canAssignSongs}} 首，</span>
        <span>请合理分配任务</span>
      </p>

      <a-form class="w-100 ng-form" :form="form" :wrapper-col="{ span: 24}">
        <div style="width: 95%;">
          <form-add add-text="添加新批次" add-is-icon icon-top="22px" @change="onChangeBatch">
            <template v-slot:default="scopes">
              <a-row>
                <a-col :span="12">
                  <a-form-item>
                    <form-member-enter-library
                      class="w-100"
                      v-decorator="[`task[${scopes.index}][charger_id]`, {rules: [$validate.required]}]"
                      placeholder="选择人员"
                    ></form-member-enter-library>
                  </a-form-item>
                </a-col>

                <a-col :span="11" :offset="1">
                  <a-form-item>
                    <a-input-number
                      :min="0"
                      :max="canAssignSongs"
                      class="w-100"
                      v-decorator="[`task[${scopes.index}][music_num]`, {rules: [$validate.required, $validate.greater_zero], initialValue: 0 }]"
                      placeholder="音乐数量"
                      @change="onChangeNumber($event, scopes.index)"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
            </template>
          </form-add>
        </div>
      </a-form>

      <p class="font-grey">
        <span :class="{'font-error': remainNubmer > canAssignSongs}">已指派 {{remainNubmer}} 首</span>
      </p>
    </div>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import {FormAdd} from '@/components/index';
  import FormMemberEnterLibrary from '@/components/form/FormMemberEnterLibrary.vue';

  export default {
    data() {
      return {
        form: this.$form.createForm(this, "nodify"),

        show: false,

        confirmLoading: false,

        canAssignSongs: 0,
        totalSongs: 0,

        // 存放每个数字输入框的最大值
        songNumbers: [],

        remainNubmer: 0,
      }
    },

    props: {
      handle: String,
      defaultValue: {
        type: Object,
        default() {
          return {}
        }
      },
      batch_id: String
    },

    watch: {
      visible(newVal) {
        if (newVal) {
          this.totalSongs = parseFloat(this.defaultValue.music_num);
          this.canAssignSongs = parseFloat(this.defaultValue.unassign_music_num);
        }
      }
    },

    components: {
      FormAdd, FormMemberEnterLibrary
    },

    mixins: [dialogMixins],

    methods: {

      onChangeNumber(value, index) {
        this.songNumbers[index] = value;

        this.remainNubmer = this.calcExtraNubmer();
      },

      onChangeBatch(data) {
        let index = data.index;
        let handle = data.handle;

        if (handle === this.$var.Handle.del) {
          this.songNumbers.splice(index, 1, 0);

          this.remainNubmer = this.calcExtraNubmer();
        }
      },

      calcExtraNubmer() {
        let numbers = this.songNumbers;

        return numbers.reduce((a = 0, b = 0) => a + b, 0)
      },

      getCurrentRemainNumber(values) {
        let total = this.canAssignSongs;
        let extra = total;

        if (values && values.task && values.task.length > 0) {
          extra = total - values.task.reduce((a, b) => a + (b.music_num || 0), 0);
        }

        return extra
      },

      handleOk() {
        this.form.validateFieldsAndScroll((errors, values) => {

          let extra = this.getCurrentRemainNumber(values);

          values.task = Object.values(values.task);
          values.batch_id = this.batch_id;

          if (extra < 0) {
            this.$message.error('指派数量超出最大可指派数量');
            return false;
          }

          if (!errors) {
            this.submit(values);
          }
        })
      },

      submit(params) {
        this.confirmLoading = true;

        this.$axios.Marklibrary_taskToAnyone(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('指派成功');
            this.$emit('success', data);
          }

          this.confirmLoading = false;
        }).catch(() => {
          this.confirmLoading = false;
        })
      },

      handleCancel() {
        this.show = false;
      },
    }
  }
</script>

<style lang="scss" scoped>
  .ng-supplier-add {
    /deep/ .ant-form-item-label {
      width: 130px;
    }
  }

  .dynamic-delete-button {
    position: absolute;
    top: 50%;
    right: -0;
    transform: translate(120%, -50%);
    font-size: 24px;
    color: #999;
    transition: all 0.3s;
    cursor: pointer;
  }

  .upload-box {
    /deep/ {
      .ant-upload {
        width: 148px;
        height: 104px;
        margin-bottom: 0;
      }
    }
  }
</style>
