<template>
  <a-form class="ng-form-style ng-filter" :form="form" layout="inline">
    <a-form-item label="领取状态">
      <a-select
        :options="CouponBindPromotion"
        v-decorator="['bind_status', {initialValue: defaultData.bind_status || ''}]"
        :style="{width: '120px'}"
      ></a-select>
    </a-form-item>
 <a-form-item label="使用状态">
      <a-select
        :options="CouponUsePromotion"
        v-decorator="['use_status', {initialValue: defaultData.use_status || ''}]"
        :style="{width: '120px'}"
      ></a-select>
    </a-form-item>

    <slot name="extra"></slot>
  </a-form>
</template>

<script>

  import {
    AddDefaultToOptions,
    CouponBindPromotion,
    CouponUsePromotion,
    DrawingStatusOptions
  } from '@/config/options'


  export default {
    data() {
      return {
        labelList: [],
        CouponBindPromotion:AddDefaultToOptions(CouponBindPromotion, '全部'),
        CouponUsePromotion:AddDefaultToOptions(CouponUsePromotion, '全部'),
        DrawingStatusOptions: AddDefaultToOptions(DrawingStatusOptions, '全部'),
      }
    },

    props: {
      type: String,
      defaultData: {
        type: Object,
        default() {
          return {}
        }
      },
    },

    components: {
    },
    created(){
      console.log('defaultData: ', this.defaultData);
    },
    beforeCreate() {
      this.form = this.$form.createForm(this, {
        onValuesChange: (props, values) => {
          values = Object.assign(values, {page: 1});

          this.$root.$goSearch(values);

          this.$emit('change', values);
        }
      });
    },

    methods: {
    }
  }
</script>

<style lang="scss" scoped>
  .ng-filter {
    margin-top: 0;
  }
</style>
