<template>
  <a-modal
    :title="modalTitle"
    width="500px"
    v-model="show"
    :confirm-loading="confirmLoading"
    centered
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
      <!-- <a-input type="hidden" v-decorator="['dept_id']"></a-input> -->

      <!-- 姓名 -->
      <a-form-item label="部门名称">
        <ng-input
          size="large"
          v-decorator="['name', { rules: [$validate.required], initialValue: '' }]"
          :placeholder="namePlaceholder"
          :disabled="allDisabled"
          :maxLength="10"
          show-counter
        ></ng-input>
      </a-form-item>

      <!-- 部门负责人 -->
      <a-form-item label="部门负责人">
        <form-select
          size="large"
          :dataFunc="$axios.AllOpenMembers"
          v-decorator="['uid']"
          placeholder="请选择"
          :disabled="allDisabled"
          :all="false"
        ></form-select>
      </a-form-item>

      <!-- 部门描述 -->
      <a-form-item label="部门描述">
        <ng-textarea
          size="large"
          v-decorator="['desc', { rules: [$validate.required], initialValue: '' }]"
          placeholder="请输入部门描述"
          style="height: 106px"
          :disabled="allDisabled"
          :maxLength="30"
          show-counter
        ></ng-textarea>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import {NgInput} from '@/components/index'
  import {NgTextarea} from '@/components/index'

  const TitleOptions = {
    add: '新增部门',
    edit: '编辑部门',
    look: '查看部门'
  }

  export default {
    data() {
      return {
        // modalTitle: '',
        show: false,
        confirmLoading: false,
        form: this.$form.createForm(this, {name: 'department'}),
      }
    },

    props: {
      handle: String,
      data: Object,
      isChild: Boolean,
    },

    computed: {
      canEdit() {
        return this.handle === this.$var.Handle.add || this.handle === this.$var.Handle.edit;
      },

      // 全部禁用
      allDisabled() {
        return this.handle === this.$var.Handle.look;
      },

      modalTitle() {
        return this.$root.$getContentAccHandle(this.handle, Object.assign(TitleOptions, {
          add: () => !this.isChild ? '新增部门' : '新增子部门',
        })) || TitleOptions.add;
      },

      namePlaceholder() {
        return !this.isChild ? '请输入部门名称' : '请输入子部门名称';
      }
    },

    mixins: [dialogMixins],

    watch: {
      visible(newVal) {
        if (newVal && this.handle !== this.$var.Handle.add) {
          setTimeout(() => {
            this.initFields();
          }, 500);
        }
      },
    },

    components: {
      NgInput, NgTextarea
    },

    methods: {
      initFields() {
        let data = {...this.data};

        this.form.setFieldsValue(data);
      },

      handleOk() {
        if (this.handle === this.$var.Handle.look) {
          this.$emit('update:visible', false);
          return;
        }

        this.form.validateFieldsAndScroll((errors, values) => {
          if (!errors) {
            let method = 'Account_addDepartment';

            if (this.handle === this.$var.Handle.edit || (this.handle === this.$var.Handle.add && this.isChild)) {
              // 编辑部门和添加子部门需要传入dept_id
              values = Object.assign(values, {
                dept_id: this.data ? this.data.dept_id : 0
              });

              this.handle === this.$var.Handle.edit && (method = 'Account_editDepartment');
            }

            this.confirmLoading = true;

            this.$axios[method](values).then(res => {
              const data = res.data;

              if (data && data.code == 0) {
                this.$message.success(this.handle === this.$var.Handle.add ? '新增成功' : '编辑成功');

                this.$emit('update:visible', false);

                this.$emit('success', {
                  handle: this.handle,
                  data: data
                })
              }

              this.confirmLoading = false;
            }).catch(() => {
              this.confirmLoading = false;
            })

          }
        });
      },

      handleCancel() {

      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
