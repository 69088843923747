<template>
  <div class="library-info flex w-100">
    <ng-image class="img-box" :src="requestData.logo_url" alt />
    <div class="library-detail flex-just-1">
      <h2>
        {{requestData.name}}
        <span
          v-if="requestData.short_name"
          class="sub font-xs"
        >（{{requestData.short_name}}）</span>
      </h2>

      <!-- 未签约 -->
      <div v-if="requestData.is_signed == '0'" class="font-error m-bottom-ms">未签约</div>
      <!-- 已签约 -->
      <div v-else class="m-bottom-ms">
        <template
          v-if="requestData.contract_data && requestData.contract_data.auth_scene.length > 0"
        >
          <div v-for="(item, index) in requestData.contract_data.auth_scene" :key="index">
            <span>{{item.type | toAuthScene}}：</span>
            <span>{{item.ratio ? `${item.ratio}%` : $config.emptyText}}</span>
          </div>
        </template>
      </div>

      <div class="m-bottom-ms">曲库类型：{{requestData.music_type | toMusicType}}</div>

      <div class="m-bottom-ms">创建时间：{{requestData.create_at}}</div>

      <div class="text-justify m-bottom-ms">{{requestData.desc}}</div>

      <slot></slot>
    </div>
  </div>
</template>

<script>
  import {BusinessSceneOptions} from '@/config/options';
  import {toOptionName} from '@/utils/function';

  export default {
    data() {
      return {

      }
    },

    props: {
      requestData: Object
    },

    filters: {
      toAuthScene(value) {
        return toOptionName(BusinessSceneOptions, value);
      }
    },

    components: {

    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  .library-info {
    padding: $space-ms;

    .img-box {
      width: 230px;
      height: 230px;
      margin: 0 10px;
    }
  }
</style>
