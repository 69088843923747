<template>
  <div class="mark-tags">
    <template v-for="(item, index) in list">
      <template v-if="item && item.length > 0">
        <template v-for="childItem in item">
          <a-tag
            class="m-bottom-ms"
            :color="childItem.color"
            closable
            :key="childItem[keyId]"
            @close="onClose(childItem, index)"
            :style="{
              color: darken(childItem.color),
              borderColor: darken(childItem.color),
            }"
          >{{ childItem.name }}</a-tag>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
  import {darken} from '@/utils/function'

  const keyId = 'label_id';

  export default {
    data() {
      return {
        keyId
      }
    },

    props: {
      list: Array,
    },

    components: {

    },
    methods: {
      darken,
      onClose(item, index) {
        this.$emit('close', Object.assign({}, item, {
          collapseIndex: index
        }))
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mark-tags {
    /deep/ {
      .anticon-close {
        color: inherit;
      }
    }
  }
</style>
