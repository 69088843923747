import { render, staticRenderFns } from "./TableUndo.vue?vue&type=template&id=70f8b906&scoped=true&"
import script from "./TableUndo.vue?vue&type=script&lang=js&"
export * from "./TableUndo.vue?vue&type=script&lang=js&"
import style0 from "./TableUndo.vue?vue&type=style&index=0&id=70f8b906&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70f8b906",
  null
  
)

export default component.exports