<template>
  <ng-table
    class="ng-table"
    :columns="columns"
    :params="params"
    :rowKey="row => row.id"
    :request="$axios.Package_list"
    :load.sync="loadTable"
    :reload.sync="reloadTable"
    :scroll="{y: scrollyHeight - 20}"
  >
    <template slot="user.name" slot-scope="text, scope">
      <a-tooltip placement="top" title="单击用户名复制 ID 至剪贴板">
        <span class="cursor-pointer" @click="() => {onCopyText(scope.user ? scope.user.user_id : '')}">{{text}}</span>
      </a-tooltip>
    </template>

    <template slot="operation" slot-scope="text, scope">
      <router-link
        v-if="$root.$checkAuth($auth[authName] && $auth[authName].look)"
        class="m-right-ms"
        :to="`/package/info/${scope.id}`"
      >查看详情</router-link>
    </template>
  </ng-table>
</template>

<script>
  import {tableMixins} from '@/mixins';

  import {OrderStatusOptionsAdmin} from '@/config/options';
  import {toOptionName, copyStr} from '@/utils/function';

  import * as TabCol from '@/config/columns';

  const columns = [
    TabCol.colId,
    TabCol.commonKey('用户名称', 'user.name', null, true),
    TabCol.commonKey('套餐名称', 'goods_name'),
    TabCol.commonKey('开通时间', 'create_at'),
    TabCol.commonKey('剩余天数', 'rest_time'),
    TabCol.commonKey('剩余授权数', 'unauth_num'),
    TabCol.commonKey('剩余下载数', 'undownload_num'),
    TabCol.operation('100px'),
  ];

  export default {
    data() {

      return {
        columns,
        params: {},
      }
    },

    props: {
      authName: String,
      scrollyHeight: {
        type: Number,
        default: 400
      },
      reload: Boolean,
      tableParams: Object,
      order_id: String,
    },

    watch: {
      tableParams(newVal) {
        this.$nextTick(() => {
          this.params = Object.assign({}, this.params, newVal || {});
        })
      }
    },

    mounted() {
      // let {range = [], page = 1, channel, status} = this.$route.query;
      // let order_id = this.order_id;

      // let params = {channel, page, status, }

      // params['time[start]'] = range[0] || '';
      // params['time[end]'] = range[1] || '';

      // order_id && (params.order_id = order_id || 0)

      // this.params = params;
    },

    filters: {
      toOrderStatus(value) {
        return toOptionName(OrderStatusOptionsAdmin, value);
      },
    },

    mixins: [
      tableMixins
    ],

    methods: {
      onCopyText(id) {
        if (!id) {
          this.$message.warning('没有可以复制的');
          return false;
        }

        try {
          copyStr(id);
          this.$message.success('复制成功');
        } catch (error) {
          console.error(error);
        }
      },

      handleFunc(key, scope) {
        this.$emit('handle', Object.assign(scope, {
          handleName: key
        }))
      },
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .ant-table .operation {
    white-space: nowrap;
  }
</style>
