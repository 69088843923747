<template>
  <a-modal
    width="640px"
    title="提现详情"
    v-model="show"
    :maskClosable="true"
    @ok="onPassApply(id)"
    :bodyStyle="bodyStyle"
  >
    <a-descriptions :column="2">
      <a-descriptions-item label="音乐人名">{{info.apply_name}}</a-descriptions-item>
      <a-descriptions-item label="提现状态">{{info.apply_status_str}}</a-descriptions-item>

      <a-descriptions-item label="真实姓名">{{info.real_name}}</a-descriptions-item>
      <a-descriptions-item label="支付类型">{{info.apply_type_str}}</a-descriptions-item>

      <a-descriptions-item label="证件号码">{{info.id_number}}</a-descriptions-item>
      <a-descriptions-item label="支付账户">{{info.account}}</a-descriptions-item>

      <a-descriptions-item label="提现金额">{{info.apply_amount}}</a-descriptions-item>
      <a-descriptions-item label="支付账号">{{info.number}}</a-descriptions-item>

      <a-descriptions-item label="个人所得税">{{info.tax_amount}}</a-descriptions-item>
      <a-descriptions-item label="申请时间">{{info.apply_time}}</a-descriptions-item>
      
      <a-descriptions-item label="实际转账金额">{{info.real_amount}}</a-descriptions-item>
      <a-descriptions-item label="审核时间">{{info.audit_time}}</a-descriptions-item>
      
      <a-descriptions-item label="转账时间">{{info.transfer_time}}</a-descriptions-item>
      

    </a-descriptions>

    <template v-slot:footer>
      <a-button
        v-if="info.apply_status === DrawingStatus.waitAudit"
        type="primary"
        @click="onPassApply(id)"
      >通过审核</a-button>
      <a-button
        v-else-if="info.apply_status === DrawingStatus.waitTransfer"
        type="primary"
        @click="onPassTransfer(id)"
      >确认转账</a-button>
      <a-button v-else type="primary" @click="$emit('update:visible', false)">确认</a-button>
    </template>
  </a-modal>
</template>

<script>
  import {DrawingStatus} from '@/config/options.value'
  import {dialogMixins} from '@/mixins/index';

  export default {
    data() {
      return {
        show: false,
        info: {},
        confirmLoading: false,
        DrawingStatus
        // okTitle: '通过审核'
      }
    },

    props: {
      id: String,
    },

    watch: {
      visible(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.getData(this.id);
          })
        }
      }
    },

    mixins: [dialogMixins],

    components: {
    },

    methods: {
      getData(apply_id) {
        let params = {apply_id};

        this.$axios.Drawing_Info(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.info = data.data ? data.data : {}
          }
        })
      },

      onPassApply(apply_id) {
        let params = {apply_id};

        this.confirmLoading = true;

        this.$axios.Drawing_Audit(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('审核通过');
            this.$emit('update:visible', false);
            this.$emit('success');
          }

          this.confirmLoading = false;
        }).catch(() => {
          this.confirmLoading = false;
        })
      },

      onPassTransfer(apply_id) {
        let params = {apply_id};

        this.confirmLoading = true;

        this.$axios.Drawing_Transfer(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('已确认');
            this.$emit('update:visible', false);
            this.$emit('success');
          }

          this.confirmLoading = false;
        }).catch(() => {
          this.confirmLoading = false;
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .info-list {
    line-height: 2.2;
  }
</style>
