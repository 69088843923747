<template>
  <div class="done-pay-order h-100" ref="ngTableBox">
    <div class="header m-top-ms m-left-xs">
      <span class="m-right-base">订单状态</span>
      <a-radio-group v-model="radioActiveValue" :options="filterOptions"></a-radio-group>
    </div>

    <ng-list
      class="m-top-base"
      id-key="music_id"
      :url-func="$axios.User_order_list"
      :params="listParams"
      :load.sync="loadData"
      empty-text="暂无购买记录"
      :scroll="{y: mainContentScrollMaxHeight}"
    >
      <template v-slot="{item}">
        <ng-list-item class="m-bottom-base">
          <template v-slot:header>
            <a-row>
              <a-col :span="12">
                <span>订单编号：</span>
                <b>{{item.order_no}}</b>
              </a-col>
              <a-col :span="8">
                <span>下单时间:</span>
                <b>{{item.create_at}}</b>
              </a-col>
              <a-col :span="4">
                <span>实付金额:</span>
                <b>￥{{(item.pay_amount || 0).toFixed(2)}}</b>
              </a-col>
            </a-row>
          </template>
          <template v-for="(cItem, index) in item.detail">
            <div :key="cItem.detail_id">
              <div class="good-item pos-relative operation-item">
                <div class="good-item-content flex cen-start">
                  <music-detail-item class="inline-block" :data="cItem">
                    <template v-slot:top>
                      <!-- 未支付 -->
                      <template v-if="type === $val.OrderStatus.undoPay">
                        <template v-if="item.status === $val.UndoPayOrderType.wait">
                          <a-tag class="m-left-base" color="pink">待支付</a-tag>
                        </template>
                        <template v-else-if="item.status === $val.UndoPayOrderType.error">
                          <a-tag class="m-left-base" color="orange">支付失败</a-tag>
                        </template>
                        <template v-else-if="item.status === $val.UndoPayOrderType.overtime">
                          <a-tag class="m-left-base" color="red">已超时</a-tag>
                        </template>
                        <template v-else-if="item.status === $val.UndoPayOrderType.cancel">
                          <a-tag class="m-left-base" color="grey">已取消</a-tag>
                        </template>
                      </template>
                      <template v-else>
                        <a-tag v-if="cItem.is_licensed == '0'" class="m-left-base" color="cyan">未授权</a-tag>
                        <a-tag v-else class="m-left-base" color="orange">已授权</a-tag>
                      </template>
                    </template>
                  </music-detail-item>

                  <span class="inline-block p-left-base">价格：￥{{cItem.price}}</span>
                </div>

                <!-- 未支付 -->
                <template v-if="type === $val.OrderStatus.undoPay">
                  <div v-if="item.status === $val.UndoPayOrderType.wait" class="pos-center-right">
                    <span>倒计时：</span>
                    <count-down
                      place="time"
                      :start="new Date(item.create_at)"
                      :count-time="$config.OrderExpirs"
                      @end="onCountDownEnd"
                    ></count-down>&nbsp;
                    <span>后订单超时</span>
                  </div>
                </template>
                <template v-else-if="cItem.is_licensed != '0'">
                  <div class="operation pos-center-right operation-name">
                    <a
                      class="m-right-ms no-p-lr"
                      :href="$root.getAuthUrl(cItem.license_id)"
                      target="_blank"
                    >查看授权信息</a>

                    <a-button class="no-p-lr" type="link" @click="editAuth(cItem)">修改授权信息</a-button>
                  </div>
                </template>
              </div>
              <a-divider v-if="index < item.detail.length - 1" class="m-top-ms m-bottom-ms"></a-divider>
            </div>
          </template>
        </ng-list-item>
      </template>
    </ng-list>

    <dialog-active-auth
      :visible.sync="showDialogActive"
      :handle="handle"
      :data="handleData"
      @success="onSuccess"
    ></dialog-active-auth>
  </div>
</template>

<script>
  import {UndoPayOrderOptionsAdmin, DidPayOrderOptions, AddDefaultToOptions} from '@/config/options';
  import DialogActiveAuth from "@/components/music/DialogActiveAuth.vue";
  import {NgList, NgListItem} from '@/components/index';
  import MusicDetailItem from '@/components/music/MusicDetailItem.vue';
  import {mainContentMixin} from '@/mixins';
  import {CountDown} from '@/components/index'

  export default {
    data() {
      return {
        radioActiveValue: '',
        loadData: false,
        showDialogActive: false,
        handleData: {},
        handle: '',
      }
    },

    watch: {
      radioActiveValue() {
        this.page = 1;
        this.$root.$goSearch({page: 1})
      }
    },

    computed: {
      filterOptions() {
        let options = this.type === this.$val.OrderStatus.undoPay ? UndoPayOrderOptionsAdmin : DidPayOrderOptions;

        return AddDefaultToOptions(options, '全部')
      },

      listParams() {
        let type = this.type;
        let user_id = this.$route.params.user_id;
        let status = this.radioActiveValue;
        let page = this.page;

        return Object.assign({}, this.params, {type, user_id, status, page})
      }
    },

    mixins: [
      mainContentMixin
    ],

    props: {
      type: String,
      params: {
        type: Object,
        default() {
          return {}
        }
      },
    },

    components: {
      NgList,
      NgListItem,
      MusicDetailItem,
      DialogActiveAuth,
      CountDown
    },

    methods: {
      lookAuth(scope) {
        this.handle = this.$var.Handle.look;
        this.handleData = scope;
        this.showDialogActive = true;
      },

      editAuth(scope) {
        this.handle = this.$var.Handle.edit;
        this.handleData = scope;
        this.showDialogActive = true;
      },

      onSuccess() {

      },

      onCountDownEnd() {
        this.loadData = true;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .done-pay-order {
    padding: 0 10px;

    .good-item {
      // border-bottom: 1px solid #ddd;

      .good-item-content {
        padding-right: 190px;
      }
    }
  }
</style>
