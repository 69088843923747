const ChannelOrder = () => import('@/pages/orderManage/ChannelOrder.vue');
const ChannelOrderInfo = () => import('@/pages/orderManage/ChannelOrderInfo.vue');
const PackageOrder = () => import('@/pages/orderManage/PackageOrder.vue');
const PackageOrderInfo = () => import('@/pages/orderManage/PackageOrderInfo.vue');
const VipOrder = () => import('@/pages/orderManage/VipOrder.vue');
const VipOrderInfo = () => import('@/pages/orderManage/VipOrderInfo.vue');

export default [{
    path: '/order/channel',
    name: 'ChannelOrder',
    component: ChannelOrder,
  },
  {
    path: '/order/channel/:order_id',
    name: 'ChannelOrderInfo',
    component: ChannelOrderInfo,
  },
  {
    path: '/order/package',
    name: 'PackageOrder',
    component: PackageOrder,
  },
  {
    path: '/order/package/:order_id',
    name: 'PackageOrderInfo',
    component: PackageOrderInfo,
  },
  {
    path: '/order/vip',
    name: 'VipOrder',
    component: VipOrder,
  },
  {
    path: '/order/vip/:order_id',
    name: 'VipOrderInfo',
    component: VipOrderInfo,
  },
]