<template>
  <ng-table
    class="ng-table"
    :columns="columns"
    :params="params"
    :rowKey="row => row.batch_id"
    :request="$axios.Batch_list"
    :load.sync="loadTable"
    :reload.sync="reloadTable"
    :scroll="{y: scrollyHeight - 20}"
    @data-change="requestSuccess"
  >
    <template slot="number" slot-scope="text">
      <span>{{`批次${text}`}}</span>
    </template>

    <template slot="priority" slot-scope="text">
      <span v-html="$options.filters.toLevelName(text)"></span>
    </template>

    <template slot="charger_names" slot-scope="text">
      <span>{{text || $config.emptyText}}</span>
    </template>

    <template slot="status" slot-scope="text">
      <span>{{text | enterLibraryStatus}}</span>
    </template>

    <template slot="operation" slot-scope="text, scope">
      <template v-if="scope.op_store">
        <a-button
          v-if="$root.$checkAuth($auth[authName] && $auth[authName].store)"
          class="m-right-ms"
          type="link"
          @click="goLibrary(scope)"
        >发起入库</a-button>
      </template>
      <template v-else>
        <span class="font-grey">已入库</span>
      </template>
    </template>

    <!-- <template slot-scope="text">
      <span>{{text || $configs.emptyText}}</span>
    </template>-->
  </ng-table>
</template>

<script>
  import {tableMixins} from '@/mixins';

  import * as TabCol from '@/config/columns';
  // import * as TabCol from '../columns';
  import * as Filters from '../filter';

  const columns = [
    TabCol.colId,
    TabCol.commonKey('批次编号', 'number', undefined, true),
    TabCol.commonKey('计划入库音乐数', 'music_num'),
    TabCol.commonKey('任务优先级', 'priority', undefined, true),
    TabCol.commonKey('入库负责人', 'charger_names', undefined, true),
    TabCol.commonKey('入库状态', 'status', undefined, true),
    TabCol.operation('操作', undefined, '80px'),
  ];

  export default {
    data() {
      return {
        columns,
        tableList: [],
        loading: false,
        params: {},
        // reloadTable: false,
      }
    },

    props: {
      authName: String,
      scrollyHeight: {
        type: Number,
        default: 400
      },
      reload: Boolean,
    },

    watch: {
      // reload(newVal) {
      //   if (newVal) {
      //     this.reloadTableData();
      //   }
      // },
    },
    filters: {
      enterLibraryStatus: Filters.enterLibraryStatus
    },

    mounted() {
      this.params = {lib_id: this.$route.params.lib_id || 0, page: this.$route.query.page || 1};

      // this.getData(this.params0);
    },

    mixins: [
      tableMixins
    ],

    methods: {

      goLibrary(scope) {
        let vm = this;

        this.$confirm({
          width: '480px',
          title: '确认要发起入库吗？',
          // content: '删除该部门，部门关联的子部门及角色数据，会同步删除。',
          onOk() {
            return new Promise((resolve, reject) => {
              vm.deleteMember(scope, resolve, reject);
            });
          }
        });
      },

      deleteMember(scope, next, reject) {
        this.$axios.Batch_store({batch_id: scope.batch_id}).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('入库成功');
            next();

            setTimeout(() => {
              this.loadTableData();
            }, 500);
          } else {
            reject()
          }
        })
      },

      requestSuccess(data) {
        this.tableList = data.data.list ? data.data.list : [];
      },
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .ant-table td {
    white-space: nowrap;
  }
</style>
