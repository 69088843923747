<template>
        <a-modal
          width="700px"
          v-model="show"
          :title="modalTitle"
          :confirm-loading="confirmLoading"
          :maskClosable="false"
          destroyOnClose
          @ok="handleOk"
          @cancel="handleCancel"
          :bodyStyle="{
            maxHeight: `${dialogBodyMaxHeight ? dialogBodyMaxHeight : '500'}px`,
            overflow: 'auto',
          }"
        >
          <a-form class="w-100 ng-form" :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }">
            <a-form-item label="活动标题">
                <ng-input
                    placeholder="请输入活动标题"
                    :maxLength="50"
                    show-counter
                    size="large"
                    v-decorator="['title', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur' }]"
                ></ng-input>
            </a-form-item>
            <a-form-item label="上传图片">
              <form-upload
                class="upload-box"
                accept="image/jpg, image/jpeg, image/png"
                list-type="picture-card"
                :action="$axios.UploadFile"
                :data="uploadParams"
                :name="uploadName"
                :remove-icon="true"
                @uploading="onUploading"
                @change="onChangeLogo"
                :max-size="$config.UploadLicenseMaxSize"
                :init-header-url="profile_photo_url"
                v-decorator="['logo_url', { rules: [$validate.required] }]"
              ></form-upload>
              <template v-slot:extra>
                <span class="font-grey font-xs">仅支持 .jpg .png 格式，文件大小不超过 10M</span>
              </template>
            </a-form-item>

            <a-form-item label="跳转链接">
                <ng-input
                    placeholder="请输入跳转链接"
                    :maxLength="200"
                    show-counter
                    size="large"
                    v-decorator="['link', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur' }]"
                ></ng-input>
            </a-form-item>
            <a-form-item label="自动发布">
                <a-switch
                @change="onChangStatus($event)"
                v-decorator="['status', {valuePropName: 'checked', initialValue: true}]"
                ></a-switch>
            </a-form-item>
            <a-form-item  label="上线时间" >
                <a-date-picker
                    placeholder="请选择日期"
                    valueFormat="YYYY-MM-DD"
                    v-decorator="['online_at', { rules: [$validate.required] }]">
                    <a-icon slot="suffixIcon" type="calendar" />
                </a-date-picker>
          
            </a-form-item>
          </a-form>
        </a-modal>
      </template>
      
      <script>
        import {NgTextarea} from '@/components/index';
        import {FormUpload} from '@/components/index';
        import {dialogMixins} from '@/mixins/index';
        import {DatePicker} from 'ant-design-vue';
        export default {
            data() {
                return {
                    form: this.$form.createForm(this, "nodify"),
                    show: false,
            
                    confirmLoading: false,
                        
                    uploadParams: {},
                    uploadName: 'file',
                    uploading: false,
            
                    loading: false,

                    profile_photo_url: '',
                    
                }
            },
            components: {
                NgTextarea, FormUpload,
                ARangePicker: DatePicker.RangePicker,
                ADatePicker: DatePicker,
            },
            props: {
                defaultValue: {
                    type: Object,
                    default() {
                        return {}
                    }
                },
                isEdit : Boolean,
            },
      
            computed: {
                modalTitle() {
                    return this.isEdit ? '修改轮播图' : '添加轮播图'
                },
                request() {
                    return this.isEdit ? this.$axios.BannerSave : this.$axios.BannerAdd
                },
                successMessage() {
                    return this.isEdit ? '修改成功' : '添加成功';
                }
            },
        
            watch: {
                defaultValue:function () {
                    this.$nextTick(() => {
                        if (this.defaultValue['status'] == '1'){
                            this.defaultValue['status'] = true;
                        }else if (this.defaultValue['status'] == '0'){
                            this.defaultValue['status'] = false;
                        }
                        this.form.setFieldsValue(this.defaultValue);
                        this.profile_photo_url = this.defaultValue.logo_url;
                    })
                },
                deep:true

            },
            created() {
                this.uploadParams = {
                upload_type: 1,
                field_name: this.uploadName
                }
            },
            mixins: [dialogMixins],
            methods: {
                onUploading(uploading) {
                this.uploading = uploading;
                },
                onChangeLogo(logo) {
                    this.defaultValue.url = logo;
                },
        
                handleOk() {
                    this.form.validateFieldsAndScroll((errors, values) => {
                        if (!errors) {
                            values.url = this.defaultValue.url;
                            if (values.status){
                                values.status = 1;
                            }else{
                                values.status = 0;
                            }
                            this.submitLibrary(values);
                        }
                    })
                },
        
                submitLibrary(params) {
                    let method = this.request;
                    if (typeof method !== 'function') return;
                    this.confirmLoading = true;
                    this.isEdit && (params.banner_id = this.defaultValue.banner_id)
                    
                    method(params).then(res => {
                        const data = res.data;
            
                        if (data && data.code == 0) {
                            this.$message.success(this.successMessage);
                            this.$emit('success', data);
                        }
                        this.confirmLoading = false;
                    }).catch(() => {
                        this.confirmLoading = false;
                    })
                },
        
                handleCancel() {
                    this.$emit('success', false);
                    this.show = false;
                },
        
                onChangeContracts(data) {
                    if (data && data.length > 0) {
                        this.form.setFieldsValue({contract_id: data[0].contract_id})
                    }
                },
                onChangStatus(){

                },
            }
        }
      </script>
      
      <style lang="scss" scoped>
        .upload-box {
          display: inline;
          width: auto;
      
          /deep/ {
            .ant-upload {
              width: 80px;
              height: 80px;
              margin-bottom: 0;
              padding: 0;
              background-color: #f7f4f8;
            }
          }
        }
      </style>
      