<template>
  <main-structure class="ng-verify-detail ng-detail" dir="ver" :loading="loading">
    <template slot="header">
      <ng-breadcrumb :data="breadData"></ng-breadcrumb>
    </template>
    <div class="tab-box" v-if="reload">
      <a-tabs v-model="currentTabIndex" default-active-key="1">
        <a-tab-pane key="1" tab="基础信息">
          <Basic :user_id="$route.params.user_id"></Basic>
        </a-tab-pane>

        <a-tab-pane key="2" tab="收件信息">
          <Address :user_id="$route.params.user_id"></Address>
        </a-tab-pane>

        <a-tab-pane key="3" tab="发票抬头">
          <Receipt :user_id="$route.params.user_id"></Receipt>
        </a-tab-pane>

        <a-tab-pane key="4" tab="公司信息">
          <Company :user_id="$route.params.user_id"></Company>
        </a-tab-pane>

        <a-tab-pane key="5" tab="账户信息">
          <Account :user_id="$route.params.user_id"></Account>
        </a-tab-pane>
      </a-tabs>
    </div>
  </main-structure>
</template>

<script>
  import {NgBreadcrumb} from '@/components/index';

  const breadData = [
    {breadcrumbName: `用户管理`, path: `/user/manage`},
    {breadcrumbName: `用户详情`}
  ]

  export default {
    data() {
      return {
        name: `user_detail`,
        breadData,
        // data: {},
        user_id: '0',
        loading: false,
        currentTabIndex: '1',

        reload: true,
      }
    },
    components: {
      NgBreadcrumb,
      Basic: () => import('./info/basic/Index.vue'),
      Address: () => import('./info/address/Index.vue'),
      Account: () => import('./info/account/Index.vue'),
      Company: () => import('./info/company/Index.vue'),
      Receipt: () => import('./info/receipt/Index.vue'),
    },

    created() {
      let user_id = this.$route.params.user_id || '0';

      this.user_id = user_id;
    },

    methods: {

      handleFunc() {

      },

      reloadTab() {
        this.reload = false;

        setTimeout(() => {
          this.reload = true;
        }, 500);
      },

      // onChangeTabs() {
      //   if (this.$route.params.handle === this.$var.Handle.add) {
      //     this.currentTabIndex = '1';
      //     this.$message.warning('请填写基础信息');
      //   }
      // },
    }
  }
</script>

<style lang="scss" scoped>
  .ng-verify-detail {
    .tab-box {
      padding: 0 20px;

      /deep/ .ant-tabs-content {
        // padding: 40px 0;
      }
    }
  }

  @media screen and (min-width: 1180px) {
    .tab-box {
      width: 800px;
    }
  }

  @media screen and (max-width: 1180px) {
    .tab-box {
      width: 100%;
    }
  }
</style>
