/**
 * 输入字段对应的录入类型
 * 主要用来编辑或查看时给后台数据转化为数组（select....）
 */
const config = {
  name: 'input',
  email: 'input',
  phone: 'input',
  station: 'input',
  job_title: 'input',
  // dept_id: 'cascader',
  role_id: 'select',
  phone_code: 'select', // 电话区域
}

/**
 * 当录入位select.., 将数据转化为数组
 * 
 * @param {*} obj 
 */
export default function (obj) {
  let newObj = Object.assign({}, obj);

  Object.keys(obj).forEach(item => {
    const cItem = config[item];
    if (cItem === 'cascader' && !Array.isArray(newObj[item])) {
      newObj[item] = [newObj[item]]
    }
  })

  return newObj;
}