/**
 * 售后管理
 */
import {
  Axios
} from '@/utils/from-common';
// import {
//   DownloadTimeout
// } from '@/config/config'
import * as $config from '~/config/config.c';

let baseURL = $config.ServeBaseHost;

// 发票列表
export const BuyManage_receiptList = (param) => Axios('POST', '/bms/orderinv/list', param);

// 发票详情
export const BuyManage_receiptInfo = (param) => Axios('POST', '/bms/orderinv/info', param);

// 发票修改
export const BuyManage_receiptEdit = (param) => Axios('POST', '/bms/orderinv/save', param);

// 发票 开出
export const BuyManage_receiptOperate = (param) => Axios('POST', '/bms/orderinv/operate', param);


// 合同列表
export const BuyManage_contractList = (param) => Axios('POST', '/bms/ordercontr/list', param);

// 合同详情
export const BuyManage_contractInfo = (param) => Axios('POST', '/bms/ordercontr/info', param);

// 合同修改
export const BuyManage_contractEdit = (param) => Axios('POST', '/bms/ordercontr/save', param);

// 合同 开出
export const BuyManage_contractOperate = (param) => Axios('POST', '/bms/ordercontr/operate', param);

// 合同 下载
export const BuyManage_contractDownload = `${baseURL}/bms/ordercontr/download`;
// export const BuyManage_contractDownload = (param) => Axios('GET', '/bms/ordercontr/download', param, {
//   timeout: DownloadTimeout,
//   responseType: 'blob'
// });