<template>
  <a-modal
    width="640px"
    v-model="show"
    :title="modalTitle"
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    :okText="!isLook ? '确定' : '关闭'"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <coop-group
      v-if="show"
      :is-look="isLook"
      ref="coopGroup"
      style="height: 500px;overflow-y: auto;"
    ></coop-group>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import CoopGroup from '@/pages/library/coop/CoopGroup.vue';

  export default {
    data() {
      return {
        show: false,

        confirmLoading: false,

        defaultData: {},
      }
    },

    props: {
      handle: String,
      defaultValue: {
        type: Object,
        default() {
          return {}
        }
      },
      contract_id: String,
      user_id: String,
    },

    computed: {
      isLook() {
        return this.handle === this.$var.Handle.look;
      },

      modalTitle() {
        return this.isLook ? '查看合作信息' : '确认合作信息'
      },
    },

    components: {
      CoopGroup
    },

    watch: {
      visible(newVal) {

        if (newVal) {
          this.$nextTick(() => {
            if (this.contract_id) {
              this.getDefaultValue().then(data => {
                this.$refs.coopGroup.setDefaultValue(data);
              });
            }
          })
        }
      }
    },

    mixins: [dialogMixins],

    methods: {

      // handleOk() {
      //   this.$refs.coopGroup.handleOk().then(value => {
      //     value.contract_id = this.contract_id;
      //     value.user_id = this.user_id;

      //     this.submit(value);
      //   });
      // },

      handleOk() {
        if (!this.isLook) {
          this.$refs.coopGroup.handleOk().then(value => {
            value.contract_id = this.contract_id;
            value.user_id = this.user_id;

            this.submit(value);
          });
        } else {
          this.show = false;
        }
      },

      getDefaultValue() {
        let params = {contract_id: this.contract_id};

        return new Promise(resolve => {
          this.$axios.Contract_info(params).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              resolve(data.data);
            }
          })
        })
      },

      submit(params) {
        this.confirmLoading = true;

        this.$axios.Contract_add(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('创建成功');
            this.$emit('success', data);
          }

          this.confirmLoading = false;
        }).catch(() => {
          this.confirmLoading = false;
        })
      },

      handleCancel() {
        this.show = false;
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
