<template>
  <a-form
    :form="form"
    layout="horizontal"
    :label-col="{ xs: {span: 6}}"
    :wrapper-col="{ xs: {span: 16}}"
    class="ng-form m-top-base"
  >
    <a-form-item label="收款单位">
      <a-input
        size="large"
        placeholder="请输入收款单位名称"
        v-decorator="['company', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'  }]"
      ></a-input>
    </a-form-item>

    <a-form-item label="收款银行">
      <a-input
        size="large"
        placeholder="请输入开户银行名称"
        v-decorator="['bank', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'  }]"
      ></a-input>
    </a-form-item>

    <a-form-item label="收款账号">
      <a-input
        size="large"
        placeholder="请输入收款账号"
        v-decorator="['number', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'  }]"
      ></a-input>
    </a-form-item>
  </a-form>
</template>

<script>

  export default {
    data() {
      return {
      }
    },

    props: {
      defaultData: {
        type: Object,
        default() {
          return {}
        }
      },
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        name: 'linkman'
      })
    },

    methods: {
    }
  }
</script>

<style lang="scss" scoped>
</style>
