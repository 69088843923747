<template>
  <div class="ng-header">
    <div class="logo">
      <img src="../assets/images/logo.png" alt="logo" />
      <span class="logo-text">运营管理系统</span>
    </div>

    <ul class="header-menu">
      <li class="header-menu-item h-100">
        <a-dropdown :trigger="['click']">
          <a class="ant-dropdown-link header-link h-100" @click="e => e.preventDefault()">
            <svg class="iconfont">
              <use xlink:href="#iconicon011" />
            </svg>
            <span
              class="username font-bold"
            >{{$store.state.userInfo ? $store.state.userInfo.name : '--'}}</span>
            <a-icon type="down" />
          </a>

          <a-menu slot="overlay" class="menu-select">
            <a-menu-item key="0">
              <router-link to="/account/userinfo">
                <a-icon type="user" :style="{ fontSize: '20px'}"></a-icon>
                <span>修改资料</span>
              </router-link>
            </a-menu-item>
            <a-divider></a-divider>
            <a-menu-item key="1">
              <a href="javascript:;" @click="changePassword">
                <a-icon type="setting" :style="{ fontSize: '20px'}"></a-icon>
                <span>修改密码</span>
              </a>
            </a-menu-item>
            <a-divider></a-divider>
            <a-menu-item key="2">
              <a href="javascript:;" @click="outLogin">退出登录</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </li>
    </ul>

    <change-password :visible.sync="showDialog" @success="handleSuccess"></change-password>
  </div>
</template>

<script>
  import ChangePassword from './dialog/ChangePassword';

  export default {
    data() {
      return {
        // username: this.$store.userInfo.name,
        showDialog: false,
      }
    },

    components: {
      ChangePassword
    },

    methods: {
      outLogin() {
        this.$store.dispatch('outLogin', {
          router: this.$router
        });
      },

      changePassword(event) {
        event.preventDefault();

        this.showDialog = true;
      },

      handleSuccess() {

      }
    }
  }
</script>

<style lang="scss" scoped>
  $header-font-color: #000;

  .ng-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: $header-height;
    color: $header-font-color;
    background-color: $header-bg;

    .logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 32px;

      > img {
        height: calc(#{$header-height} - 24px);
      }

      .logo-text {
        font-size: 18px;
        margin-left: 8px;
      }
    }

    .header-menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0;

      .header-link {
        color: $header-font-color;
        display: flex;
        padding-right: $space-base;
        flex-direction: row;
        align-items: center;

        .iconfont {
          width: 20px;
          height: 20px;
        }

        .username {
          margin: 0 20px;
          font-size: 16px;
        }
      }
    }
  }

  .menu-select {
    width: 336px;

    .ant-dropdown-menu-item {
      height: 55px;
      line-height: 55px;

      > a {
        padding-top: 0;
        padding-bottom: 0;
      }

      .anticon {
        margin-right: 10px;
      }
    }

    .ant-divider {
      margin: 0;
    }

    .ant-dropdown-menu-item:last-child {
      text-align: center;
    }
  }
</style>
