var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ng-table',{staticClass:"ng-table",attrs:{"columns":_vm.columns,"params":_vm.params,"rowKey":function (row) { return row.lib_id; },"request":_vm.request,"load":_vm.loadTable,"reload":_vm.reloadTable,"scroll":{y: _vm.scrollyHeight - 20}},on:{"update:load":function($event){_vm.loadTable=$event},"update:reload":function($event){_vm.reloadTable=$event},"data-change":_vm.requestSuccess},scopedSlots:_vm._u([{key:"profile_photo",fn:function(text, scope){return [_c('ng-image',{staticClass:"table-header",attrs:{"src":scope.logo_url ? ((scope.logo_url) + "?x-oss-process=style/thumb_10") : _vm.$var.defaultHeader},on:{"error":function (ref) {
	var target = ref.target;

	return target.src = _vm.$var.defaultHeader;
}}})]}},{key:"name",fn:function(text, scope){return [_c('router-link',{attrs:{"to":("/librarydetail/" + (scope.lib_id) + "?user_id=" + (scope.user_id) + "&supplier_type=" + (_vm.supplierType || ''))}},[_vm._v(_vm._s(text))])]}},{key:"contract_data.auth_type",fn:function(text){return [_c('span',[_vm._v(_vm._s(_vm._f("toAuthType")(text)))])]}},{key:"contract_data.auth_area",fn:function(text){return [_c('span',[_vm._v(_vm._s(_vm._f("toArea")(text)))])]}},{key:"contract_data.deadline",fn:function(text, scope){return [_c('span',[_vm._v(" "+_vm._s(scope.contract_data.coop_start_date || _vm.$config.emptyText)+" - "),_c('br'),_vm._v(" "+_vm._s(scope.contract_data.coop_end_date || _vm.$config.emptyText)+" ")])]}},{key:"contract_data.auth_scene",fn:function(text, scope){return _vm._l((scope.contract_data.auth_scene),function(item,index){return _c('div',{key:index},[_c('span',[_vm._v(_vm._s(_vm._f("toAuthScene")(item.type))+"：")]),_c('span',[_vm._v(_vm._s(item.ratio ? ((item.ratio) + "%") : _vm.$config.emptyText))])])})}},{key:"status",fn:function(text, scope){return [(_vm.$root.$checkAuth(_vm.$auth[_vm.authName] && _vm.$auth[_vm.authName].status))?_c('table-switch',{attrs:{"options":text >= 0 ? _vm.SellOperateOptions.filter(function (item) { return item.value >= 0; }) : _vm.SellOperateOptions,"default-value":parseInt(text) || 0,"format":{id: 'value', name: 'label'},"change":function() {
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
 _vm.onChangeSwitch.apply(void 0, argsArray.concat( [scope] )) }}}):_c('span',{class:text == 0 ? 'font-error' : 'font-success'},[_vm._v(_vm._s(_vm._f("toSellStatus")(text)))])]}},{key:"operation",fn:function(text, scope){return [(_vm.$root.$checkAuth(_vm.$auth[_vm.authName] && _vm.$auth[_vm.authName].edit))?_c('a-button',{staticClass:"m-right-ms",attrs:{"type":"link","disabled":scope.op_update == 0},on:{"click":function($event){return _vm.handleFunc(_vm.$var.Handle.edit, scope)}}},[_vm._v("编辑")]):_vm._e(),(_vm.$root.$checkAuth(_vm.$auth[_vm.authName] && _vm.$auth[_vm.authName].look))?_c('router-link',{staticClass:"m-right-ms",attrs:{"to":("/librarybatch/" + (scope.lib_id) + "?user_id=" + (scope.user_id) + "&supplier_type=" + (_vm.supplierType || '')),"disabled":scope.op_manage_batch == 0}},[_vm._v("批次管理")]):_vm._e(),(_vm.$root.$checkAuth(_vm.$auth[_vm.authName] && _vm.$auth[_vm.authName].del))?_c('a-button',{staticClass:"ant-btn-error",attrs:{"type":"link","disabled":scope.op_update == 0},on:{"click":_vm.$root.dontFinished}},[_vm._v("删除")]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }