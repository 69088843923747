<template>
  <div class="h-100">
    <ng-table
      class="ng-table"
      :columns="columns"
      :params="params"
      childrenColumnName="child"
      :rowKey="row => row.music_id"
      :request="$axios.Library_musicList"
      :load.sync="loadTable"
      :reload.sync="reloadTable"
    >
      <template slot="name" slot-scope="text, scope">
        <a href="javascript:;" @click="onClickMusic(scope)" :title="text">{{text}}</a>
      </template>
      <template slot="status" slot-scope="text">
        <span>{{text | toMusicStatus}}</span>
      </template>
    </ng-table>
    <!-- <a-table
      class="ng-table"
      childrenColumnName="child"
      :columns="columns"
      :pagination="false"
      :dataSource="list"
      :rowKey="row => row.music_id"
    >
      <template slot="name" slot-scope="text, scope">
        <a href="javascript:;" @click="onClickMusic(scope)" :title="text">{{text}}</a>
      </template>
      <template slot="status" slot-scope="text">
        <span>{{text | toMusicStatus}}</span>
      </template>
    </a-table>-->
  </div>
</template>

<script>
  import * as TabCol from '@/config/columns';
  import {SellStatusOptions} from '@/config/options'
  import {toOptionName} from '@/utils/function'
  import {tableMixins} from '@/mixins';

  const columns = [
    TabCol.colIdFunc((text, index) => !text.parent_id ? index + 1 : ' '),
    TabCol.commonKey('音乐名称', 'name', {ellipsis: true, isSlot: true}),
    TabCol.commonKey('上传时间', 'create_at',),
    TabCol.commonKey('时长(s)', 'duration'),
    TabCol.commonKey('状态', 'status', '', true),
  ]

  export default {
    data() {
      return {
        columns,
        params: {}
      }
    },

    props: {
      lib_id: String,
      authName: String,
    },

    filters: {
      toMusicStatus(value) {
        return toOptionName(SellStatusOptions, value);
      }
    },

    mounted() {
      this.params = {
        lib_id: this.lib_id
      }
    },

    mixins: [
      tableMixins
    ],

    components: {
    },

    methods: {
      onClickMusic(scope) {
        let {music_url: url} = scope;

        this.$store.dispatch('onPlayGlobalVideo', Object.assign(scope, {url}));
      },
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .ant-table td {
    white-space: nowrap;
  }
</style>
