<template>
  <div class="done-pay-order h-100" ref="ngTableBox">
    <div class="header m-top-ms m-left-xs">
      <span class="m-right-base">分享对象</span>
      <a-radio-group v-model="deed_target" :options="TargetOfDeedsOptions"></a-radio-group>
    </div>

    <ng-list
      class="m-top-base"
      id-key="music_id"
      :url-func="$axios.User_deed_list"
      :params="params"
      :load.sync="loadData"
      empty-text="暂无购买记录"
      :scroll="{y: mainContentScrollMaxHeight}"
    >
      <template v-slot="{item}">
        <music-list-item class="m-bottom-base" :item="item"></music-list-item>
      </template>
    </ng-list>
  </div>
</template>

<script>
  import {TargetOfDeedsOptions} from '@/config/options';
  import {NgList} from '@/components/index';
  import {mainContentMixin} from '@/mixins';
  import MusicListItem from '@/components/music/MusicListItem.vue'

  export default {
    data() {
      return {
        deed_target: this.$val.ShareAndCollectType.music,
        loadData: false,
        showDialogActive: false,
        handleData: {},
        handle: '',
        TargetOfDeedsOptions
      }
    },

    watch: {
      deed_target() {
        this.page = 1;
        this.$root.$goSearch({page: 1})
      }
    },

    computed: {

      params() {
        let deed_type = this.type;
        let user_id = this.$route.params.user_id;
        let obj_type = this.deed_target;
        let page = this.page;

        return {deed_type, user_id, obj_type, page}
      }
    },

    mixins: [
      mainContentMixin
    ],

    props: {
      type: String
    },

    components: {
      NgList,
      MusicListItem
    },

    methods: {
      lookAuth(scope) {
        this.handle = this.$var.Handle.look;
        this.handleData = scope;
        this.showDialogActive = true;
      },

      editAuth(scope) {
        this.handle = this.$var.Handle.edit;
        this.handleData = scope;
        this.showDialogActive = true;
      },

      onSuccess() {

      },

      onCountDownEnd() {
        this.loadData = true;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .done-pay-order {
    padding: 0 10px;

    .good-item {
      // border-bottom: 1px solid #ddd;

      .good-item-content {
        padding-right: 190px;
      }
    }
  }
</style>
