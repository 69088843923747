<template>
  <main-structure class="ng-library" dir="ver">
    <template slot="header">
      <span class="title">套餐订单</span>

      <div class="header-content header-search-content">
        <a-input-search
          class="ng-input-search"
          placeholder="请输入订单号、用户ID以查询"
          :default-value="$route.query.keyword || ''"
          @search="onSearch"
          allowClear
        >
          <a-button slot="enterButton" type="sub">
            <a-icon type="search" />
          </a-button>
        </a-input-search>

        <!-- <a-button
          v-if="$root.$checkAuth($auth[name] && $auth[name].add)"
          type="sub"
          @click="handleFunc($var.Handle.add)"
        >开通套餐</a-button> -->
      </div>
    </template>

    <template>
      <filter-order @change="onFilterChange" :defaultData="$route.query" class="m-top-base"></filter-order>

      <div class="m-top-base flex-1" ref="ngTableBox">
        <manage-table
          v-if="$root.$checkAuth($auth[name] && $auth[name].list)"
          :scrolly-height="mainContentScrollMaxHeight"
          :auth-name="name"
          :table-params="tableParams"
          :order_id="$route.params.order_id"
          ref="ngTable"
        ></manage-table>
      </div>
    </template>
  </main-structure>
</template>

<script>
  import ManageTable from './package/ManageTable.vue';
  import FilterOrder from './package/FilterOrder.vue'

  import {mainContentMixin, filterReq} from '@/mixins';

  export default {
    data() {
      return {
        name: `package_order`,

        isReloadTable: false,

        tableParams: {}
      }
    },

    mounted() {
      let {time = [], page = 1, channel, status, keyword} = this.$route.query;

      let params = {time, page, channel, status, keyword};

      params = this.parseArrParams({...params});

      this.tableParams = params;
    },

    components: {
      ManageTable, FilterOrder
    },

    mixins: [
      mainContentMixin, filterReq
    ],

    methods: {
      onFilterChange(values) {
        let params = Object.assign({}, this.tableParams, values);

        params = this.parseArrParams(params);

        this.tableParams = this.filterCommon(params);
      },

      onSearch(keyword) {
        typeof keyword === 'string' && (keyword = keyword.trim());

        let search = {keyword, page: 1};

        this.$root.$goSearch(search);

        this.tableParams = search;
      },

      handleFunc() {

      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
