  import {
    toOptionName
  } from '@/utils/function';

  const enterStatusDir = [{
      label: '未开始',
      value: '0',
      color: '#000000',
    },
    {
      label: '进行中',
      value: '1',
      color: '#FF4D4F'
    },
    {
      label: '已完成',
      value: '2',
      color: ''
    }
  ]

  export const enterLibraryStatus = (value) => {
    return toOptionName(enterStatusDir, value);
  }