<template>
  <ng-table
    class="ng-table"
    :columns="columns"
    :params="params"
    :rowKey="row => row.id"
    :request="$axios.Package_listByUser"
    :load.sync="loadTable"
    :reload.sync="reloadTable"
    :scroll="{y: scrollyHeight - 20}"
    @dataChange="onDataChange"
  >
    <template slot="auth_area" slot-scope="text">
      <span class="word-break order-no">{{text | toArea}}</span>
    </template>

    <template slot="auth_time" slot-scope="text">
      <span class="word-break order-no">{{text | toAuthPeriod}}</span>
    </template>
    <template slot="operation" slot-scope="text,scope">
       <a-button class="m-right-ms" type="link" @click="downloadRecoder(scope,'all')">下载记录</a-button>
       <a-button class="m-right-ms" type="link" @click="downloadRecoder(scope)">授权记录</a-button>
    </template>
    <!-- <template slot="operation" slot-scope="text, scope">
      <router-link
        v-if="$root.$checkAuth($auth[authName] && $auth[authName].look)"
        class="m-right-ms"
        :to="`/order/channel/${scope.order_id}`"
      >查看详情</router-link>
    </template>-->
  </ng-table>
</template>

<script>
  import {tableMixins,recorderExport} from '@/mixins';
  // import {recorderExport} from '@/mixins';

  import {OrderStatusOptionsAdmin} from '@/config/options';
  import {toOptionName} from '@/utils/function';

  import * as TabCol from '@/config/columns';

  const columns = [
    TabCol.colId,
    TabCol.commonKey('套餐名称', 'goods_name'),
    TabCol.commonKey('套餐类型', 'goods_cate'),
    TabCol.commonKey('使用渠道', 'use_mode'),
    TabCol.commonKey('授权地域', 'auth_area', null, true),
    TabCol.commonKey('授权期限', 'auth_time', null, true),
    TabCol.commonKey('开通时间', 'create_at'),
    TabCol.commonKey('到期时间', 'expire_date'),
    TabCol.commonKey('授权次数', 'auth_num'),
    TabCol.commonKey('下载次数', 'download_num'),
    TabCol.operation('100px'),
  ];

  export default {
    data() {

      return {
        columns,
        params: {},
      }
    },

    props: {
      authName: String,
      scrollyHeight: {
        type: Number,
        default: 400
      },
      reload: Boolean,
      tableParams: Object,
      order_id: String,
    },

    watch: {
      tableParams: {
        handler(newVal) {
          this.$nextTick(() => {
            this.params = Object.assign({}, this.params, newVal || {});
          })
        },
        immediate: true
      }
    },

    mounted() {
    },

    filters: {
      toOrderStatus(value) {
        return toOptionName(OrderStatusOptionsAdmin, value);
      },
    },

    mixins: [
      tableMixins,
      recorderExport
    ],

    methods: {
      handleFunc(key, scope) {
        this.$emit('handle', Object.assign(scope, {
          handleName: key
        }))
      },

      onDataChange(list) {
        this.$emit('dataChange', list);
      },
      downloadRecoder(scope,type='auth'){
        let user_id = scope.user.user_id;
        let goods_id = scope.id;
        let params = {user_id,goods_id};
        let func = type == 'auth'?this.$axios.Package_authRecord:this.$axios.Package_downloadRecord;
        let filename = `${scope.goods_name}-${type=='auth'?'授权记录':'下载记录'}`
        func(params).then(res => {
          if(Object.keys(res.data.data).length >0){
            this.downloadRecorder(res.data.data,type,filename);
          }else{
            this.$message.error('记录为空');
          }
        }).catch(() => {
        })

      }

    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .ant-table .operation {
    white-space: nowrap;
  }
</style>
