export * from '~/config/config.c'

// 弹出框的宽度
export const DialogWidth = '640px';

// 上传音乐模板
export const SubmitMusicTemplateUrl = 'https://vf2-test.oss-cn-hangzhou.aliyuncs.com/tpls/音乐入库字段模板201013.xlsx';
// 上传用户模板
export const SubmitUserTemplateUrl = 'https://spark-pro-1304044220.cos.ap-beijing.myqcloud.com/assets/common/Download_template/%E7%94%A8%E6%88%B7%E4%BF%A1%E6%81%AF%E6%A8%A1%E6%9D%BF202105.xlsx';

//上传音乐人收益账单字段表
export const SubmitEarningsTemplateUrl = 'https://spark-pro-1304044220.cos.ap-beijing.myqcloud.com/assets/common/tpls/%E9%9F%B3%E4%B9%90%E4%BA%BA%E6%94%B6%E7%9B%8A%E8%B4%A6%E5%8D%95%E5%AD%97%E6%AE%B5%E8%A1%A8.xlsx';

//授权场景对照表
export const SubmitAuthTemplateUrl = 'https://spark-pro-1304044220.cos.ap-beijing.myqcloud.com/assets/common/tpls/%E9%9F%B3%E4%B9%90%E4%BA%BA%E6%94%B6%E7%9B%8A%E8%B4%A6%E5%8D%95%E5%AD%97%E6%AE%B5%E8%A1%A8.xlsx';