<template>
  <div class="library-info flex w-100">
    <ng-image class="img-box" :src="requestData.logo_url" alt />

    <div class="library-detail flex-just-1">
      <h2>
        {{requestData.name}}
        <span
          v-if="requestData.short_name"
          class="sub font-xs"
        >（{{requestData.short_name}}）</span>
      </h2>

      <div class="m-bottom-ms">歌单介绍：{{requestData.desc}}</div>

      <div class="m-bottom-ms">
        <span>歌单标签：</span>
        <template v-if="requestData.labels && requestData.labels.length > 0">
          <a-tag
            v-for="item in requestData.labels"
            :color="item.color"
            :style="{ color: darken(item.color), borderColor: darken(item.color)}"
            :key="item.label_id"
          >{{ item.name }}</a-tag>
        </template>
      </div>

      <div class="m-bottom-ms">创建时间：{{requestData.create_at}}</div>
    </div>
  </div>
</template>

<script>
  import {BusinessSceneOptions} from '@/config/options';
  import {toOptionName} from '@/utils/function';

  import {darken} from '@/utils/function'

  export default {
    data() {
      return {
      }
    },

    props: {
      requestData: Object
    },

    filters: {
      toAuthScene(value) {
        return toOptionName(BusinessSceneOptions, value);
      }
    },

    components: {

    },
    methods: {
      darken
    }
  }
</script>

<style lang="scss" scoped>
  .library-info {
    padding: $space-ms;

    .img-box {
      width: 230px;
      height: 230px;
      margin: 0 10px;
    }
  }
</style>
