<template>
  <a-modal
    width="600px"
    v-model="show"
    title="上传账单"
    destroyOnClose
    :maskClosable="false"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    class="submit-music"
  >
    <a-form :form="form">
      <div class="font-xs m-bottom-base">
        上传音乐人收益账单，请务必按照收益账单字段表规范填写：
      </div>
      <a-steps direction="vertical" size="small">
        <a-step status="process">
          <b slot="title">填写音乐人收益账单字段表</b>
          <div class="description" slot="description">
            <span>请下载</span>&nbsp;
            <a :href="$config.SubmitEarningsTemplateUrl" download>
              音乐人收益账单信息模板  
            </a>
            <span>，规范填写音乐信息</span>
          </div>
        </a-step>
        <a-step status="process">
          <b slot="title">上传音乐人收益账单字段表</b>
          <a-form-item slot="description">
            <a-upload
              :multiple="false"
              :file-list="fileList"
              :remove="handleRemove"
              :before-upload="beforeUpload"
              v-decorator="['file', { rules: [$validate.required] }]"
            >
              <a-button> <a-icon type="upload" /> 上传文件 </a-button>
            </a-upload>
          </a-form-item>
        </a-step>
      </a-steps>
    </a-form>
  </a-modal>
</template>

<script>
  import {Steps as ASteps, Upload as AUpload} from 'ant-design-vue';
  import {dialogMixins, tableMixins} from '@/mixins/index';

  export default {
    data() {
      return {
        show: false,
        confirmLoading: false,
        form: this.$form.createForm(this, {name: ''}),
        fileList: []
      }
    },

    props: {
    },

    components: {
      ASteps, AStep: ASteps.Step, AUpload
    },

    watch: {
      show(newVal) {
        if (newVal) {
          this.fileList = []
        }
      }
    },

    mixins: [dialogMixins, tableMixins],

    methods: {

      beforeUpload(file) {
        this.fileList = [file];
        return false;
      },

      handleRemove() {
        this.fileList = [];
        this.form.setFieldsValue({file: {}});
      },

      handleOk() {
        this.form.validateFieldsAndScroll((errors, values) => {
          
          if (!errors) {
            const {fileList} = this;
            const formData = new FormData();

            formData.append('file', fileList[0]);

            this.confirmLoading = true;

            this.$axios.Earnings_Import(formData).then(res => {
              const data = res.data;

              if (data && data.code == 0) {
                let {errors} = data.data;

                if (errors && errors.length > 0) {
                  this.errorsTips(errors);
                } else {
                  this.$message.success('上传成功');
                  this.$emit('update:visible', false);
                  this.$emit('success');
                }
              }

              this.confirmLoading = false;
            }).catch(() => {
              this.confirmLoading = false;
            })

          }
        });
      },

      errorsTips(errors) {
        const h = this.$createElement;
        this.$warning({
          title: '上传失败',
          content: h('div', {}, errors.map(item => h('div', item)))
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .submit-music {
    /deep/ {
      .ant-steps-item-description {
        padding-bottom: 0;
      }

      .description {
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }
  }
</style>
