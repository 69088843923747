<template>
  <a-modal
    class="ant-modal-confirm ng-modal-confirm"
    width="480px"
    v-model="show"
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >

  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import {SupplierType} from '@/utils/variables'

  const checkRequest = {
    [SupplierType.MUSICIAN]: 'Ver_musicianCheck',
    [SupplierType.TEAM]: 'Ver_teamCheck',
    [SupplierType.COMPANY]: 'Ver_companyCheck'
  }

  export default {
    data() {
      return {
        show: false,
        confirmLoading: false,
        form: this.$form.createForm(this, {name: 'verifyconfirm'}),

        contentOptions: {}
      }
    },

    props: {
      options: Object,
      type: String,
      handle: String
    },

    watch: {
      handle: {
        handler(newVal) {
          if (newVal) {
            this.initOptions(newVal);
          }
        }
      }
    },

    mixins: [dialogMixins],

    methods: {
      initOptions(handle) {
        let agree = this.$var.Handle.agree;
        let scope = this.options;

        let check_result = handle === agree ? '1' : '0';

        let contentOptions = {};

        if (handle === agree) {
          contentOptions.title = '确认通过该对象的合作申请吗？';
          contentOptions.placeholder = '请输入通过理由，该理由仅用于内部记录&查看';
        } else {
          contentOptions.title = '确认拒绝该对象的合作申请吗？';
          contentOptions.placeholder = '请输入拒绝理由';
        }

        this.params = {
          apply_id: scope.apply_id,
          check_result: check_result
        }

        this.contentOptions = contentOptions;
      },

      handleOk() {
        let {apply_id, check_result} = this.options;

        this.form.validateFieldsAndScroll((errors, values) => {

          if (!errors) {

            this.confirmLoading = true;

            let params = Object.assign({apply_id, check_result}, values);

            let method = checkRequest[this.type] || checkRequest[SupplierType.MUSICIAN];

            this.$axios[method](params).then(res => {
              const data = res.data;

              if (data && data.code == 0) {
                this.$message.success('审核成功');
                this.$emit('success', params);
                this.$emit('update:visible', false);
              }

              this.confirmLoading = false;
            })
          }
        })
      },
      handleCancel() {
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
