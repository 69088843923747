import { render, staticRenderFns } from "./GoodAuthInfo.vue?vue&type=template&id=0ec89330&scoped=true&"
import script from "./GoodAuthInfo.vue?vue&type=script&lang=js&"
export * from "./GoodAuthInfo.vue?vue&type=script&lang=js&"
import style0 from "./GoodAuthInfo.vue?vue&type=style&index=0&id=0ec89330&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ec89330",
  null
  
)

export default component.exports