<template>
  <a-card class="scene-item" :title="title">
    <!-- <span class="close-icon" slot="extra" @click="$emit('close', index)">&#9587;</span> -->

    <span slot="extra">
      启用
      <a-switch v-model="isStart" :loading="statusLoading" @change="onChangeStatus"></a-switch>
    </span>

    <template>
      <!-- <div class="m-bottom-base">场景权重：{{data.ratio | toDecimals}}</div> -->
      <a-descriptions title="授权地域权重">
        <template v-for="item in data.auth.area">
          <a-descriptions-item
            v-if="item.is_enabled == '1'"
            :label="item.type | toArea"
            :key="item.type"
          >{{item.ratio | toDecimals}}</a-descriptions-item>
        </template>
      </a-descriptions>
      <a-descriptions title="授权时间权重">
        <template v-for="item in data.auth.time">
          <a-descriptions-item
            v-if="item.is_enabled == '1'"
            :label="item.type | toAuthTime"
            :key="item.type"
          >{{item.ratio | toDecimals}}</a-descriptions-item>
        </template>
      </a-descriptions>

      <a-collapse accordion>
        <template v-for="(item, index) in data.use_mode">
          <a-collapse-panel :header="item.name" :key="index">
            <p>渠道价格：￥{{item.price}}</p>
            <p>渠道描述：{{item.desc}}</p>
          </a-collapse-panel>
        </template>
      </a-collapse>
    </template>
  </a-card>
</template>

<script>
  import {Card, Collapse} from 'ant-design-vue';
  import {AuthAreaOfMusicOptions, AuthTimeOptions, BusinessSceneOptions} from '@/config/options'
  import {toOptionName} from '@/utils/function';

  import Observe from '~/utils/observe.c.js'

  export default {
    data() {
      return {
        form: this.$form.createForm(this, 'scene'),
        BusinessSceneOptions,
        isStart: false,

        statusLoading: false,
      }
    },
    props: {
      title: String,
      data: {
        type: Object,
        default() {
          return {}
        }
      },
      scene_id: [String, Number],
      goods_id: String,
    },

    computed: {
      isCustom() {
        return !!this.goods_id;
      },

      statusRequest() {
        return this.isCustom ? this.$axios.Price_ChangeCustomSceneStatus : this.$axios.Price_ChangeCommonSceneStatus;
      }
    },

    watch: {
      data: {
        handler(newVal) {
          if (newVal && newVal.is_enabled) {
            this.isStart = newVal.is_enabled == '1' ? true : false;
          }
        },
        immediate: true
      },
    },

    filters: {
      toArea(value) {
        return toOptionName(AuthAreaOfMusicOptions, value);
      },

      toAuthTime(value) {
        return toOptionName(AuthTimeOptions, value);
      }
    },

    components: {
      ACard: Card,
      ACollapse: Collapse,
      ACollapsePanel: Collapse.Panel,
    },

    methods: {
      onChangeStatus(newVal) {
        let is_enabled = newVal ? '1' : '0';

        let params = {is_enabled, scene_id: this.scene_id};

        this.isCustom && (params.goods_id = this.goods_id || '');

        this.statusLoading = true;

        this.statusRequest && this.statusRequest(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success(newVal ? '启用成功' : '禁用成功');

            Observe.publish('reloadSellWayInfo');
          } else {
            // 回退
            this.isStart = !newVal;
          }

          this.statusLoading = false;
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .scene-item {
    /deep/ {
      .iconfont-trash {
        top: 20px !important;
      }
    }
  }

  .close-icon {
    font-size: 20px;
    cursor: pointer;
  }
</style>
