import { render, staticRenderFns } from "./MarkMusic.vue?vue&type=template&id=efa420f2&scoped=true&"
import script from "./MarkMusic.vue?vue&type=script&lang=js&"
export * from "./MarkMusic.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efa420f2",
  null
  
)

export default component.exports