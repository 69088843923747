<template>
  <ng-table
    class="ng-table"
    :columns="columns"
    :params="params"
    :rowKey="row => row.id"
    :request="$axios.Search_List"
    :load.sync="loadTable"
    :reload.sync="reloadTable"
    :scroll="{y: scrollyHeight - 20}"
  >
    <template slot="name" slot-scope="text, scope">
      <router-link
        class="word-break order-no"
        :to="`/order/${scope.order_type === $val.orderType.music ? 'channel' : 'package'}/${scope.order_id || '-1'}?from=${$route.name}`"
      >{{text}}</router-link>
    </template>

    <template slot="operation" slot-scope="text, scope">
      <a-button
        v-if="$root.$checkAuth($auth[authName] && $auth[authName].look)"
        class="m-right-ms"
        type="link"
        @click="$emit('detail', scope)"
      >详情</a-button>
    </template>
  </ng-table>
</template>

<script>
  import {tableMixins} from '@/mixins';
  import * as TabCol from '@/config/columns';

  // "时间","搜索内容","IP地址","客户端名称","用户ID","用户名称","来源地址"
  const columns = [
    TabCol.colId,
    TabCol.commonKey('时间', 'created_at'),
    TabCol.commonKey('搜索内容', 'content'),
    TabCol.commonKey('IP地址', 'ip'),
    TabCol.commonKey('用户ID', 'user_id'),
    TabCol.commonKey('用户名称', 'user_name'),
  ];

  export default {
    data() {
      return {
        columns,
        params: {},
      }
    },

    props: {
      authName: String,
      scrollyHeight: {
        type: Number,
        default: 400
      },
      reload: Boolean,
      tableParams: Object,
    },

    watch: {
      tableParams(newVal) {
        this.$nextTick(() => {
          this.params = Object.assign({}, this.params, newVal || {});
        })
      }
    },

    mounted() {
    },

    mixins: [
      tableMixins
    ],

    methods: {
    }
  }
</script>

<style lang="scss" scoped>
</style>
