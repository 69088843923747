<template>
  <ng-table
    class="ng-table"
    :columns="columns"
    :params="params"
    :rowKey="row => row.coupon_id"
    :request="$axios.CouponList"
    :load.sync="loadTable"
    :reload.sync="reloadTable"
    :scroll="{y: scrollyHeight - 20}"
    @data-change="requestSuccess"
  >

 
    <template slot='couponArea' slot-scope="text, scope">
      <span>
        {{scope.coupon_type==1?`${scope.discount}折`:`减${scope.money}`}}
      </span>
    </template>
    <template slot='thresholdArea' slot-scope="text, scope">
      <span>
        {{scope.threshold>0?`满${scope.threshold}`:`无门槛`}}
      </span>
    </template>
 
    <template slot="coupon_status" slot-scope="text, scope">
       <a-switch
          @change="onChangStatus($event,scope)"
          :defaultChecked='text == 2'
          unCheckedChildren='未启用'
          checkedChildren='已启用'
          v-decorator="['sale_status', {valuePropName: 'checked', initialValue: '1'}]"
        ></a-switch>
    </template>

    <template slot="timeArea" slot-scope="text, scope">
      <span>{{scope.time_type=='2'?`领取后${scope.valid_day}天有效`:scope.begin_time+'-'+scope.end_time}}</span>
    </template>

    <template slot="operation" slot-scope="text, scope">
      <router-link
        v-if="$root.$checkAuth($auth[authName] && $auth[authName].discount)"
        class="ant-btn-link m-right-ms"
        :to="`/CouponDetail/${scope.coupon_id}`"
      >详情</router-link>
      <a-button
        v-if="scope.get_way == 2"
        class="m-right-ms"
        type="link"
        @click="handleFunc('send', scope)"
      >发送</a-button>
         <a-button
        v-if="scope.get_way == 1"
        class="m-right-ms"
        type="link"
        @click="handleFunc('generate', scope)"
      >生成</a-button>
      <a-button
        v-if="scope.get_way == 3"
        class="m-right-ms"
        type="link"
        @click="handleFunc('generate', scope)"
      >生成</a-button>
      
      

    </template>
  </ng-table>
</template>

<script>
  import {tableMixins, filterReq} from '@/mixins';

  import {AuthAreaOptions, AuthTypeOptions, BusinessSceneOptions, SellStatusOptions, SellOperateOptions} from '@/config/options';
  import {toOptionName} from '@/utils/function';
  // import {} from 'ant-design-vue';

  import * as TabCol from '@/config/columns';

  const columns = [
    TabCol.colId,
   TabCol.commonKey('优惠券名称', 'name',{ellipsis: true,}),
    TabCol.commonKey('优惠类型', 'coupon_type_str'),
    TabCol.couponArea(''),
    TabCol.thresholdArea(''),
    TabCol.timeArea('',{ellipsis: true,}),
    TabCol.commonKey('领取方式', 'get_way_str'),
    TabCol.commonKey('状态', 'coupon_status','140px',true),
  
    TabCol.operation('170px'),
  ];

  export default {
    data() {
      return {
        columns,
        tableList: [],
        loading: false,
        SellOperateOptions,
        showAddMembersDialog: false,
        handleData: {},
        params: {},
        successMessage:{
          "1":'启用成功',
          "2":'停用成功'
        }
      }
    },

    props: {
      authName: String,
      scrollyHeight: {
        type: Number,
        default: 400
      },
      reload: Boolean,
      user_id: String,
      supplierType: String,
      tableParams: Object,
    },

    mounted() {
      let {page, keyword, time, coupon_type} = this.$route.query;
      console.log('this.$route.query: ', this.$route.query);
      let params = {page: page || 1 ,keyword};

      params.coupon_type = coupon_type || '';
      params.time =time?time:[]
      params = this.parseArrParams(params);
      this.params = params;
    },

    filters: {
      toArea(value) {
        return toOptionName(AuthAreaOptions, value);
      },

      toAuthType(value) {
        return toOptionName(AuthTypeOptions, value);
      },

      toAuthScene(value) {
        return toOptionName(BusinessSceneOptions, value);
      },

      toSellStatus(value) {
        return toOptionName(SellStatusOptions, value);
      }
    },

    computed: {
      
    
    },

    watch: {
      tableParams(newVal) {
        this.$nextTick(() => {
          this.params = Object.assign({}, this.params, newVal || {});
        })
      },
    },

    mixins: [
      tableMixins,filterReq
    ],

    methods: {
      onChangStatus(v,scope){
        console.log('scope: ',v, scope);
        let method = this.$axios.ChangeCouponStatus;
        method({
          coupon_id:scope.coupon_id,
          coupon_status:v?2:1
        }).then(res => {
          const data = res.data;
          if (data && data.code == 0) {
            this.$message.success(this.successMessage[v?1:2]);
            // this.$emit('success', data);
            // this.loadTableData();

          }

          // this.confirmLoading = false;
        }).catch(() => {
          // this.confirmLoading = false;
        })
      },
      handleFunc(key, scope) {
        this.$emit('handle', Object.assign(scope, {
          handleName: key
        }))
      },

      onChangeSwitch(key, options, next, loading, scope) {
        loading(true);

        let param = {
          playlist_id: scope.playlist_id,
          sale_status: key
        }

        this.$axios.Song_status(param).then(res => {
          let data = res.data;

          loading(false);

          if (data && data.code == 0) {
            next();

            key == 1 ? this.$message.success('已上架') : this.$message.error('已下架');

            this.loadTableData();
          }
        })
      },

      requestSuccess(data) {
        this.tableList = data.data.list ? data.data.list : [];
      },

    

    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .ant-table td {
    white-space: nowrap;
  }
</style>
