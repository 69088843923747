const OPTIONSDEFAULT = {
  url: 'url',
  name: 'name'
}

const changeItem = (item, options) => {
  item = Object.assign({}, item);

  Object.keys(options).forEach(cItem => {
    item[cItem] = item[options[cItem]];
  })

  return item;
}

export default {
  data() {
    return {
      videoUrl: '',
    }
  },
  computed: {},
  methods: {
    videoPlaying(item) {
      return this.$store.state.globalVideoPlaying && this.$store.state.globalVideoInfo.url === item[this.optionsFormat.url]
    },

    onPlayVideo(scope, options) {
      options = options || OPTIONSDEFAULT;

      let playing = this.$store.state.globalVideoPlaying;
      let stUrl = this.$store.state.globalVideoInfo.url;
      let scopeUrl = scope[options.url];

      if (stUrl !== scopeUrl) {
        this.$store.commit('changeVideoPlaying', false);

        setTimeout(() => {
          this.$store.commit('changeVideoPlaying', true);
          this.$store.commit('changeVideoInfo', changeItem(scope, options));
        }, 100);
      } else {
        this.$store.commit('changeVideoPlaying', !playing);
      }
    },

    changeListKeys(list, options) {
      return list.map(item => changeItem(item, options));
    }
  }
}