<template>
  <a-modal
    width="500px"
    v-model="show"
    title="入库批次管理"
    destroyOnClose
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div>
      <p class="font-grey">根据供应商的入库计划，将曲库音乐合理拆分批次</p>

      <a-form class="w-100 ng-form" :form="form" :wrapper-col="{ span: 24}">
        <a-form-item>
          <a-row>
            <a-col :span="12">
              <a-form-item>
                <a-input-number
                  :min="0"
                  class="w-100"
                  v-decorator="['music_num', {rules: [$validate.required]}]"
                  placeholder="计划入库音乐数"
                />
              </a-form-item>
            </a-col>

            <a-col :span="11" :offset="1">
              <a-form-item>
                <a-select
                  :options="LevelOptions"
                  placeholder="任务优先级"
                  v-decorator="['priority', {rules: [$validate.checkRequired]}]"
                ></a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import {LevelOptions} from '@/config/options';

  export default {
    data() {
      return {
        form: this.$form.createForm(this, "nodify"),

        show: false,

        confirmLoading: false,
        LevelOptions,
      }
    },

    mixins: [dialogMixins],

    props: {
      lib_id: String,
    },

    methods: {

      handleOk() {
        this.form.validateFieldsAndScroll((errors, values) => {

          console.log(values);

          if (!errors) {
            values.lib_id = this.lib_id;

            this.submit(values);
            // this.$emit('change', values);
          }
        })
      },

      submit(params) {
        this.confirmLoading = true;

        this.$axios.Batch_add(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('新增成功');
            this.$emit('success', data);
          }

          this.confirmLoading = false;
        }).catch(() => {
          this.confirmLoading = false;
        })
      },

      handleCancel() {
        this.show = false;
      },
    }
  }
</script>

<style lang="scss" scoped>
  .ng-supplier-add {
    /deep/ .ant-form-item-label {
      width: 130px;
    }
  }

  .dynamic-delete-button {
    position: absolute;
    top: 50%;
    right: -0;
    transform: translate(120%, -50%);
    font-size: 24px;
    color: #999;
    transition: all 0.3s;
    cursor: pointer;
  }

  .upload-box {
    /deep/ {
      .ant-upload {
        width: 148px;
        height: 104px;
        margin-bottom: 0;
      }
    }
  }
</style>
