<template>
  <main-structure class="ng-account-members" dir="ver">
    <template slot="header">
      <span class="title">入库任务 - {{title}}</span>
      <div class="header-content header-search-content">
        <a-input-search
          class="ng-input-search"
          placeholder="请输入曲库名称、供应商名称以检索"
          :default-value="$route.query.name || ''"
          @search="onSearch"
          allowClear
          v-model="keyword"
        >
          <a-button slot="enterButton" type="sub">
            <a-icon type="search" />
          </a-button>
        </a-input-search>
      </div>
    </template>

    <template>
      <div class="m-top-base flex-1" ref="ngTableBox">
        <component
          v-if="$root.$checkAuth($auth[name] && $auth[name].list)"
          :is="getServeTableComp"
          :scrolly-height="mainContentScrollMaxHeight"
          :auth-name="name"
          :params="tableParams"
          ref="manageTable"
        ></component>
      </div>
    </template>
  </main-structure>
</template>

<script>
  import TableUndo from './components/TableUndo.vue';
  import TableDoing from './components/TableDoing.vue';
  import TableDone from './components/TableDone.vue';

  import {mainContentMixin} from '@/mixins';
  import {ServeType} from '@/utils/variables';

  const getServeTableComp = (type) => {
    const dir = {
      [ServeType.UNDO]: TableUndo,
      [ServeType.DOING]: TableDoing,
      [ServeType.DONE]: TableDone,
    }

    return dir[type] ? dir[type] : dir[ServeType.UNDO]
  }

  const getTitle = (type) => {
    const dir = {
      [ServeType.UNDO]: '待服务',
      [ServeType.DOING]: '服务中',
      [ServeType.DONE]: '已完成',
    }

    return dir[type] ? dir[type] : dir[ServeType.UNDO]
  }

  export default {
    data() {
      return {
        name: `enterlibraryTask_${this.$route.meta.type}`,
        getServeTableComp: getServeTableComp(this.$route.meta.type),
        title: getTitle(this.$route.meta.type),
        tableParams: {},
        keyword: "",
      }
    },
    components: {
      TableUndo, TableDoing, TableDone
    },

    mixins: [
      mainContentMixin
    ],
    mounted() {
      let { query } = this.$route;
      this.tableParams = Object.assign({}, this.tableParams, {
        page: query.page || 1,
      });
    },
    methods: {
      onSearch() {
        this.tableParams = Object.assign({}, this.tableParams, {
          keyword: this.keyword,
        });
        this.$refs.manageTable.loadTableData();
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
