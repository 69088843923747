<template>
  <main-structure class="ng-library" dir="ver">
    <template slot="header">
      <span class="title">商品管理</span>

      <div class="header-content header-search-content">
        <a-button
          v-if="$root.$checkAuth($auth[name] && $auth[name].getCost)"
          class="m-right-base"
          type="sub"
          @click="showPluginDialog = true;"
        >成本价配置</a-button>

        <a-button
          v-if="$root.$checkAuth($auth[name] && $auth[name].commonPriceInfo)"
          type="sub"
          @click="$router.push(`/goods/sellway`)"
        >通用售价配置</a-button>
      </div>
    </template>

    <template>
      <div class="m-top-base flex-1 flex flex-column">
        <good-type-switch
          :auth-name="name"
          :lib_tabs_id="lib_tabs_id"
          @change="onChangeType"
          @changeInfo="onChangeSwitchData"
          style="min-height: 50px;"
        ></good-type-switch>

        <div class="flex-1 table-box" ref="ngTableBox">
          <keep-alive>
            <template v-if="goodType === lib_tabs_id">
              <manage-table-lib
                v-if="$root.$checkAuth($auth[name] && $auth[name].list)"
                :scrolly-height="mainContentScrollMaxHeight"
                :auth-name="name"
                :user_id="$route.params.user_id"
                @handle="onTableHandleSuccess"
                @goPay="onGoPay"
                ref="ngTable"
              ></manage-table-lib>
            </template>
            <div v-else>
              <div class="text-right m-bottom-base btn-group">
                <a-button
                  v-if="$root.$checkAuth($auth[name] && $auth[name].list)"
                  type="sub"
                  @click="handleFunc($var.Handle.add)"
                >新增商品</a-button>
              </div>

              <manage-table-good
                v-if="$root.$checkAuth($auth[name] && $auth[name].addGood)"
                :scrolly-height="mainContentScrollMaxHeight"
                :auth-name="name"
                :cate_id="goodType"
                :key="goodType"
                @handle="onTableHandleSuccess"
                @goPay="onGoPay"
                ref="ngTable"
              ></manage-table-good>
            </div>
          </keep-alive>
        </div>
      </div>
    </template>

    <dialog-add-good
      :visible.sync="showAddGoodDialog"
      :handle="handleType"
      :id="handleData.goods_id"
      :defaultValue="handleData"
      :goodInfo="curGoodTypeInfo"
      :cate_id="goodType"
      @success="onSuccess"
    ></dialog-add-good>

    <cost-config :visible.sync="showPluginDialog"></cost-config>

    <dialog-select-sale-way
      :visible.sync="showSalewayDialog"
      :data="curGoodsInfo"
      :lib_tabs_id="lib_tabs_id"
      @success="onSuccess"
    ></dialog-select-sale-way>
  </main-structure>
</template>

<script>
  import ManageTableLib from './components/ManageTableLib.vue';
  import ManageTableGood from './components/ManageTableGood.vue';
  import GoodTypeSwitch from './components/GoodTypeSwitch.vue'
  import DialogAddGood from './components/DialogAddGood.vue'
  import DialogSelectSaleWay from './components/DialogSelectPriceWay.vue'

  import {mainContentMixin} from '@/mixins';

  const lib_tabs_id = 'lib';

  export default {
    // provide: {
    //   currentGoodSceneAuth: this.curGoodSceneType
    // },

    data() {
      return {
        name: `goods_manage`,

        showAddGoodDialog: false,
        showPluginDialog: false,
        showSalewayDialog: false,

        handleType: this.$var.Handle.add,

        handleData: {},
        curGoodsInfo: {},

        lib_tabs_id,

        goodType: '',

        curGoodTypeInfo: {},

        isReloadTable: false,
      }
    },

    computed: {
      fromSupplier() {
        return this.$route.meta.from === 'supplier';
      },
    },

    components: {
      ManageTableLib,
      ManageTableGood,
      GoodTypeSwitch,
      DialogAddGood,
      DialogSelectSaleWay,
      // ComfirmSellWay: () => import('./components/ComfirmSellWay.vue'),
      CostConfig: () => import('./components/ConfigCost.vue'),
    },

    mixins: [
      mainContentMixin
    ],

    methods: {
      handleSuccess(isLoad) {
        this.showHandleDialog = false;
        this.showPluginDialog = false;
        isLoad !== false ? this.$refs.ngTable.reloadTableData() : this.$refs.ngTable.loadTableData();
      },

      onTableHandleSuccess(scope) {
        let handle = scope.handleName;
        let varHandle = this.$var.Handle;

        if (handle === varHandle.edit) {
          this.handleFunc(handle, scope);
        }
      },

      onGoPay(scope) {
        this.showSalewayDialog = true;
        this.curGoodsInfo = scope;
      },

      handleFunc(key, scope) {
        this.handleData = scope || {};
        this.handleType = key;
        this.showAddGoodDialog = true;
      },

      onChangeType(value) {
        // let 
        this.goodType = value;
      },

      onChangeSwitchData(scope) {
        // this.curGoodSceneType = '3';
        this.curGoodTypeInfo = scope;
      },

      // addGood() {
      //   this.handle = this.$var.Handle.add;
      //   this.showAddGoodDialog = true;
      // },

      onSuccess() {
        this.$refs.ngTable.reloadTableData();
      },

      // onEditGood(scope) {
      //   this.handleData = scope;
      //   this.handle = this.$var.Handle.edit;
      //   this.showAddGoodDialog = true;
      // }
    }
  }
</script>

<style lang="scss" scoped>
  .table-box {
    position: relative;
    margin-top: 80px;

    .btn-group {
      position: absolute;
      top: -43px;
      right: 4px;
    }
  }
</style>
