<template>
  <div>
    <!-- <div slot="right" class="flex cen-start add-btn font-primary" @click="onHandle('add')">
      <span class="font-sm">添加发票抬头</span>
    </div> -->

    <a-spin :spinning="spinning" class="w-100">
      <!-- <Detail :list="list" @onEdit="onEditHandle" @success="onSuccess"></Detail> -->
      <Detail :list="list"></Detail>
    </a-spin>

    <!-- <dialog-add
      :default-value="dialogFormDefault"
      :visible.sync="showHandleDialog"
      :handle="handleType"
      @success="onSuccess"
    ></dialog-add>-->
  </div>
</template>

<script>
  import Detail from "./components/Detail";

  export default {
    data() {
      return {
        title: "发票抬头",

        showHandleDialog: false,
        dialogFormDefault: {},
        handleType: 'add',

        list: [],

        spinning: false,
      };
    },

    props: {
      user_id: String,
    },

    components: {
      Detail,
    },

    created() {
      this.getData({user_id: this.user_id});
    },

    methods: {
      // onSuccess() {
      //   this.getData();
      // },

      // onEditHandle(item) {
      //   item = Object.assign({}, item, {
      //     is_default: !!+item.is_default,
      //     is_special: !!+item.is_special
      //   })

      //   this.dialogFormDefault = item;

      //   this.onHandle(this.$var.Handle.edit);
      // },

      getData(params) {
        this.spinning = true;

        this.$axios.User_Recipt_list(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            // something
            this.list = data.data.list;
          }

          this.spinning = false;
        })
      },

      // onHandle(type) {
      //   this.handleType = type;
      //   this.showHandleDialog = true;
      // },
    }
  };
</script>

<style lang="scss" scoped>
  .add-btn {
    cursor: pointer;

    .iconfont {
      width: 20px;
      height: 20px;
      margin-right: 2px;
    }
  }
</style>
