<template>
  <a-modal
    width="700px"
    v-model="show"
    :title="modalTitle"
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    destroyOnClose
    @ok="handleOk"
    @cancel="handleCancel"
    :bodyStyle="{
      maxHeight: `${dialogBodyMaxHeight ? dialogBodyMaxHeight : '500'}px`,
      overflow: 'auto',
    }"
  >
    <a-form class="w-100 ng-form" :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }">
      <a-form-item  label="生成数量">
           <ng-input
            placeholder=""
            show-counter
            size="large"
            v-decorator="['num', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur' }]"
          ></ng-input>
      </a-form-item>
      <a-form-item label="口令码">
      <ng-input
            placeholder="口令码兑换必填"
            show-counter
            size="large"
            v-decorator="['code_name', {initialValue: '', validateTrigger: 'blur' }]"
          ></ng-input>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  export default {
    data() {
      return {
        form: this.$form.createForm(this, {
        num:''
        }),
        show: false,
        confirmLoading: false,
        loading: false
      }
    },

    props: {
        coupon_id: String
    },

    computed: {
      modalTitle() {
        return  '生成兑换码'
      },
    },

    watch: {
     
    },

    created() {
    },

    mixins: [dialogMixins],

    components: {
     
    },

    methods: {
   

     
      handleOk() {
        this.form.validateFieldsAndScroll((errors, values) => {
          if (!errors) {
            values.coupon_id =this.coupon_id
            this.submitLibrary(values);
          }
        })
      },


      submitLibrary(params) {
        let method = this.$axios.GenerateCode;
        this.confirmLoading = true;
        method(params).then(res => {
          const data = res.data;
          if (data && data.code == 0) {
            this.$message.success('生成成功');
            this.$emit('success', data);
          }
          this.show = false;
          this.confirmLoading = false;

        }).catch(() => {
          this.confirmLoading = false;
        })
      },

      handleCancel() {
        this.show = false;
      },

   
    }
  }
</script>

<style lang="scss" scoped>
  .upload-box {
    display: inline;
    width: auto;

    /deep/ {
      .ant-upload {
        width: 80px;
        height: 80px;
        margin-bottom: 0;
        padding: 0;
        background-color: #f7f4f8;
      }
    }
  }
</style>
