<template>
  <main-structure class="ng-account-members" dir="ver">
    <template slot="header">
      <span class="title">成员管理</span>

      <div class="header-content header-search-content">
        <a-input-search
          class="ng-input-search"
          placeholder="请输入成员姓名搜索"
          :default-value="$route.query.name || ''"
          @search="onSearch"
          allowClear
        >
          <a-button slot="enterButton" type="sub">
            <a-icon type="search" />
          </a-button>
        </a-input-search>

        <a-button
          v-if="$root.$checkAuth($auth[name] && $auth[name].add)"
          type="sub"
          @click="handleFunc($var.Handle.add)"
        >新增成员</a-button>
      </div>
    </template>

    <template>
      <div class="m-top-ms flex-0">
        <filter-member @change="onFilterChange" :defaultData="$route.query"></filter-member>
      </div>

      <div class="m-top-base flex-1" ref="ngTableBox">
        <ng-table
          v-if="$root.$checkAuth($auth[name] && $auth[name].list)"
          class="ng-table"
          :columns="columns"
          :rowKey="(row) => row.uid"
          :scroll="{ y: mainContentScrollMaxHeight }"
          :params="params"
          :request="$axios.Account_membersList"
          :load.sync="loadTable"
          :reload.sync="reloadTable"
        >
          <template slot="phone" slot-scope="text, scope">
            <span v-if="text">
              {{
              `${
              scope.phone_code.prefix ? `+${scope.phone_code.prefix}` : ""
              } ${text}`
              }}
            </span>
          </template>

          <template slot="status" slot-scope="text, scope">
            <table-switch
              v-if="$root.$checkAuth($auth[name] && $auth[name].status)"
              :options="memberStatusOptions"
              :default-value="parseInt(text)"
              :change="
                function () {
                  onChangeSwitch(...arguments, scope);
                }
              "
            ></table-switch>
            <span v-else :class="text == 0 ? 'font-error' : 'font-success'">
              {{
              $root.$getItemWithKey(memberStatusOptions, "id", text).name || ""
              }}
            </span>
          </template>
          <template slot="operation" slot-scope="text, scope">
            <a-button
              v-if="$root.$checkAuth($auth[name] && $auth[name].look)"
              class="m-right-ms"
              type="link"
              @click="handleFunc($var.Handle.look, scope)"
            >查看</a-button>
            <a-button
              v-if="$root.$checkAuth($auth[name] && $auth[name].edit)"
              class="m-right-ms"
              type="link"
              @click="handleFunc($var.Handle.edit, scope)"
            >编辑</a-button>
            <a-button
              v-if="$root.$checkAuth($auth[name] && $auth[name].del)"
              class="ant-btn-error"
              type="link"
              @click="onDelete(scope)"
              :disabled="scope.is_enabled == 1"
            >删除</a-button>
          </template>
        </ng-table>
      </div>
    </template>

    <add-members
      :visible.sync="showAddMembersDialog"
      :handle="handleType"
      :data="handleData"
      @success="handleSuccess"
    ></add-members>
  </main-structure>
</template>

<script>
  import FilterMember from '@/pages/account/components/FilterMember';

  import {OpenAndCloseStatus} from '@/config/options'

  import {mainContentMixin, tableMixins, filterReq} from '@/mixins';

  const columns = [
    {
      title: 'ID',
      customRender(text, record, index) {
        return index + 1;
      },
      width: '100px'
    },
    {
      title: '姓名',
      dataIndex: 'name',
    },
    {
      title: '电话',
      dataIndex: 'phone',
      scopedSlots: {customRender: 'phone'},
    },
    {
      title: '部门',
      dataIndex: 'dept_name',
    },
    {
      title: '角色',
      dataIndex: 'role_name',
    },
    {
      title: '状态',
      dataIndex: 'is_enabled',
      scopedSlots: {customRender: 'status'},
    },
    {
      title: '操作',
      scopedSlots: {customRender: 'operation'},
      width: '180px'
    },
  ];

  export default {
    data() {
      return {
        name: 'account_member',
        columns,
        data: [],
        loading: false,
        pagination: {},
        memberStatusOptions: OpenAndCloseStatus,
        switchLoading: false,
        showAddMembersDialog: false,

        handleType: this.$var.Handle.add,
        handleData: {},
        params: {},

        loadTable: false,
      }
    },
    components: {
      // NgFilter, 
      FilterMember,
      AddMembers: () => import('./dialog/AddMember')
    },

    mounted() {
      let {dept_id = '', role_id = '', is_enabled = '', name = '', page = 1} = this.$route.query;

      this.params = {dept_id, role_id, is_enabled, name, page};
    },

    mixins: [
      mainContentMixin, tableMixins, filterReq
    ],

    methods: {
      onFilterChange(values) {
        let params = Object.assign({}, this.params, values);

        this.params = this.filterCommon(params);
      },

      onSearch(name) {
        typeof name === 'string' && (name = name.trim());

        let search = {name, page: 1};

        this.$root.$goSearch(search);

        this.params = Object.assign({}, this.params, search || {});
      },

      handleFunc(key, scope) {
        if (key !== this.$var.Handle.add) {
          this.handleData = scope;
        }

        this.handleType = key;
        this.showAddMembersDialog = true;
      },

      onDelete(scope) {
        let vm = this;

        this.$confirm({
          width: '480px',
          title: '确认要删除该成员吗？',
          content: '此操作仅将该成员账号信息删除，对应的项目信息、操作记录不会被删除。',
          onOk() {
            return new Promise((resolve, reject) => {
              vm.deleteMember(scope, resolve, reject);
            });
          }
        });
      },

      deleteMember(scope, next, reject) {
        this.$axios.Account_delMembers({uid: scope.uid}).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('已成功删除该成员');
            next();

            this.loadTableData();
          } else {
            reject()
          }
        })
      },

      handleSuccess() {
        this.loadTableData();
      },

      onChangeSwitch(key, options, next, loading, scope) {
        loading(true);

        let param = {
          uid: scope.uid,
          is_enabled: key
        }

        this.$axios.Account_membersStatus(param).then(res => {
          let data = res.data;

          if (data && data.code == 0) {
            next();
            key == 1 ? this.$message.success('已启用') : this.$message.error('已停用');

            // this.data = this.$changeDataItem(this.data, scope.uid, item => Object.assign({}, item, {
            //   is_enabled: key
            // }));

            this.loadTableData();
          }

          loading(false);
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  // .ng-account-members {
  //   /deep/ .ant-input-search {
  //     width: 360px;
  //     margin-right: $space-base;
  //   }
  // }
</style>
