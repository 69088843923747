var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticClass:"add-tags-type",attrs:{"title":_vm.title,"width":"640px","confirm-loading":_vm.confirmLoading,"maskClosable":false,"bodyStyle":{
    maxHeight: ((_vm.dialogBodyMaxHeight ? _vm.dialogBodyMaxHeight : '500') + "px"),
    overflow: 'auto',
  }},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('a-form',{staticClass:"ng-form m-top-base",attrs:{"form":_vm.form,"layout":"horizontal","label-col":{ xs: { span: 5 } },"wrapper-col":{ xs: { span: 17 } }}},[_c('a-form-item',{attrs:{"label":"标签名称"}},[_c('ng-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            rules: [_vm.$validate.required],
            initialValue: '',
            validateTrigger: 'blur',
          } ]),expression:"[\n          'name',\n          {\n            rules: [$validate.required],\n            initialValue: '',\n            validateTrigger: 'blur',\n          },\n        ]"}],attrs:{"maxLength":20,"show-counter":"","placeholder":"请输入标签名称，上限20个字"}})],1),_c('a-form-item',{attrs:{"label":"标签英文名称"}},[_c('ng-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name_en',
          {
            rules: [_vm.$validate.required],
            initialValue: '',
            validateTrigger: 'blur',
          } ]),expression:"[\n          'name_en',\n          {\n            rules: [$validate.required],\n            initialValue: '',\n            validateTrigger: 'blur',\n          },\n        ]"}],attrs:{"maxLength":20,"show-counter":"","placeholder":"请输入标签英文名称，上限20个字"}})],1),_c('a-form-item',{attrs:{"label":"标签描述"}},[_c('ng-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['desc', { initialValue: '' }]),expression:"['desc', { initialValue: '' }]"}],staticStyle:{"height":"80px"},attrs:{"placeholder":"请输入类别描述，200字以内","maxLength":200,"show-counter":""}})],1)],1),_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":_vm.handleCancel}},[_vm._v("取消")]),(_vm.handle !== _vm.$var.Handle.edit)?_c('a-button',{key:"save",attrs:{"type":"primary","loading":_vm.confirmLoading && _vm.saveBtnType == '1'},on:{"click":function($event){return _vm.handleOk(false)}}},[_vm._v("保存并继续新增")]):_vm._e(),_c('a-button',{key:"submit",attrs:{"type":"primary","loading":_vm.confirmLoading && _vm.saveBtnType == '2'},on:{"click":function($event){return _vm.handleOk(true)}}},[_vm._v("保存")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }