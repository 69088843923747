<template>
  <a-modal
    width="700px"
    v-model="show"
    :title="modalTitle"
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    destroyOnClose
    @ok="handleOk"
    @cancel="handleCancel"
    :bodyStyle="{
      maxHeight: `${dialogBodyMaxHeight ? dialogBodyMaxHeight : '500'}px`,
      overflow: 'auto',
    }"
  >
    <a-form class="w-100 ng-form" :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }">
      <a-form-item label="歌单封面">
        <form-upload
          class="upload-box"
          accept="image/jpg, image/jpeg, image/png"
          list-type="picture-card"
          :action="$axios.UploadFile"
          :data="uploadParams"
          :name="uploadName"
          :remove-icon="true"
          @uploading="onUploading"
          :max-size="$config.UploadLicenseMaxSize"
          :init-header-url="profile_photo_url"
          v-decorator="['logo', { rules: [$validate.required] }]"
        ></form-upload>
        <template v-slot:extra>
          <span class="font-grey font-xs">仅支持 .jpg .png .jpeg .gif 格式，文件大小不超过 5M</span>
        </template>
      </a-form-item>

      <!-- 曲库名称 -->
      <a-form-item label="歌单名称">
        <ng-input
          placeholder="请输入歌单名称"
          :maxLength="50"
          show-counter
          size="large"
          v-decorator="['name', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur' }]"
        ></ng-input>
      </a-form-item>

      <!-- 曲库简介 -->
      <a-form-item label="歌单简介">
        <ng-textarea
          placeholder="请输入歌单简介，有助于用户更好地了解音乐"
          style="height: 106px"
          :maxLength="300"
          show-counter
          size="large"
          v-decorator="['desc', { initialValue: '' }]"
        ></ng-textarea>
      </a-form-item>

      <a-spin :spinning="loading">
        <!-- 曲库简介 -->
        <a-form-item label="歌单标签" required>
          <tag-gather
            :selected-list.sync="selectedList"
            :list="tagList"
            :key="labelIndex"
            ref="tagGather"
          ></tag-gather>
        </a-form-item>
      </a-spin>
    </a-form>
  </a-modal>
</template>

<script>
  import TagGather from '@/pages/markMusic/markTags/TagGather.vue'
  import {NgTextarea} from '@/components/index';
  import {FormUpload} from '@/components/index';
  import {dialogMixins} from '@/mixins/index';

  export default {
    data() {
      return {
        form: this.$form.createForm(this, "nodify"),
        show: false,

        confirmLoading: false,

        defaultData: {},

        uploadParams: {},
        uploadName: 'file',
        uploading: false,

        loading: false,

        tagList: [],
        selectedList: [],

        profile_photo_url: '',

        labelIndex: 0,
      }
    },

    props: {
      handle: String,
      defaultValue: {
        type: Object,
        default() {
          return {}
        }
      },
      user_id: String,
    },

    computed: {
      isEdit() {
        return this.handle === this.$var.Handle.edit;
      },

      modalTitle() {
        return this.isEdit ? '修改歌单' : '新增歌单'
      },

      request() {
        return this.isEdit ? this.$axios.Song_edit : this.$axios.Song_add
      },

      successMessage() {
        return this.isEdit ? '修改成功' : '新增成功';
      }
    },

    watch: {
      visible(newVal) {
        if (newVal) {
          this.profile_photo_url = '';
          this.selectedList = [];

          this.getSongLabel();
        }
      }
    },

    created() {
      this.uploadParams = {
        upload_type: 1,
        field_name: this.uploadName
      }

      // this.getSongLabel();
    },

    mixins: [dialogMixins],

    components: {
      NgTextarea, FormUpload, TagGather
    },

    methods: {
      onUploading(uploading) {
        this.uploading = uploading;
      },

      getSongLabel() {
        let params = {};

        this.loading = true;

        params.type = this.$val.MarkTargetVal.songlist;

        this.$axios.GetCommonLabels(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.tagList = data.data ? data.data.list : [];

            this.tagListCopy = [...this.tagList];

            this.selectedList = this.tagList.map(() => []);

            this.isEdit && this.initSongListInfo(this.tagList);
          }

          this.loading = false;
        })
      },

      initSongListInfo(tagList = []) {
        this.profile_photo_url = this.defaultValue.logo_url;

        let {logo, name, desc, labels} = this.defaultValue;

        if (this.form) {
          this.form.resetFields(['logo', 'name', 'desc']);

          if (this.isEdit) {
            this.form.setFieldsValue({logo, name, desc});

            labels = tagList.map(({cate_id}) => labels.filter(cItem => cate_id && cItem.cate_id === cate_id) || []);

            this.$nextTick(() => {
              this.$refs.tagGather && this.$refs.tagGather.onInitTags(labels);
            })
          }
        }
      },

      handleOk() {
        this.form.validateFieldsAndScroll((errors, values) => {

          if (!errors) {
            let selectedList = this.selectedList;

            if (!this.checkRequest(selectedList, this.tagList)) return false;

            values.label_ids = [...selectedList].flat().map(item => item.label_id);

            this.submitLibrary(values);
          }
        })
      },

      checkRequest(list, tagList) {
        let typeList = tagList.map(item => item.name)

        if (!list || !list.flat(2).length) {
          this.$message.error('请选择标签');
          return false;
        }

        let res = list.some((item, index) => {
          if (!item || !item.length) {
            this.$message.error(`请标注${typeList[index] || ''}类别`);
            return true;
          }
        })

        if (res) return false;

        return true;
      },

      submitLibrary(params) {
        let method = this.request;

        if (typeof method !== 'function') return;

        this.confirmLoading = true;

        this.isEdit && (params.playlist_id = this.defaultValue.playlist_id)

        method(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success(this.successMessage);
            this.$emit('success', data);
          }

          this.confirmLoading = false;
        }).catch(() => {
          this.confirmLoading = false;
        })
      },

      handleCancel() {
        this.show = false;
      },

      onChangeContracts(data) {
        if (data && data.length > 0) {
          this.form.setFieldsValue({contract_id: data[0].contract_id})
        }
      },

      // onChangeMusicType(data) {
      //   if (data && data.length > 0) {
      //     this.$refs.libraryBaseInfo.form.setFieldsValue({music_type: data[0].value})
      //   }
      // },
    }
  }
</script>

<style lang="scss" scoped>
  .upload-box {
    display: inline;
    width: auto;

    /deep/ {
      .ant-upload {
        width: 80px;
        height: 80px;
        margin-bottom: 0;
        padding: 0;
        background-color: #f7f4f8;
      }
    }
  }
</style>
