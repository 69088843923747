<template>
  <ng-card>
    <!-- 已经关联 -->
    <template v-if="data.email">
      <div class="detail-item">
        <h3>邮箱号码</h3>
        <div>{{data.hide_email || '--'}}</div>
      </div>

      <div slot="right">
        <a-button
          v-if="!isLook && $root.$checkAuth($auth[authName] && $auth[authName].accountChangeEmail)"
          type="link"
          @click="handleItem($var.Handle.edit)"
        >变更</a-button>
      </div>
    </template>
    <!-- 没有关联 -->
    <template v-else>
      <div class="detail-item">
        <h3>邮箱号码</h3>
        <div>未关联，关联后可通过手机号登录系统</div>
      </div>

      <div slot="right">
        <a-button
          v-if="!isLook && $root.$checkAuth($auth[authName] && $auth[authName].accountChangeEmail)"
          type="link"
          @click="handleItem($var.Handle.add)"
        >关联</a-button>
      </div>
    </template>

    <dialog-add
      :user_id="user_id"
      :type="type"
      :default-value="defaultValue"
      :handle="handleType"
      @success="$emit('success')"
      :visible.sync="showAddDialog"
    ></dialog-add>
  </ng-card>
</template>

<script>
  import {NgCard} from '@/components/index';
  import DialogAdd from './DialogAdd.vue';

  export default {
    data() {
      return {
        type: 'email',
        showAddDialog: false,
        showVerifyDialog: false,
        handleType: this.$var.Handle.add,
        defaultValue: {}
      }
    },

    props: {
      user_id: String,
      data: {
        type: Object,
        default() {
          return {};
        }
      },
      isLook: Boolean,
      authName: String
    },

    components: {
      // eslint-disable-next-line
      NgCard, DialogAdd
    },

    methods: {


      onAddChange() {

      },

      onVerifyChange() {

      },

      handleItem(type) {
        this.defaultValue = this.data;
        this.handleType = type;
        this.showAddDialog = true;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .detail-item {
    padding: 15px;
  }
</style>
