
import {
    Axios
  } from '@/utils/from-common';
  
// 轮播图列表
export const BannerList = (param) => Axios('POST', '/bms/banner/list', param);

export const BannerAdd = (param) => Axios('POST', '/bms/banner/add', param);

export const BannerSave = (param) => Axios('POST', '/bms/banner/save', param);

export const BannerDel = (param) => Axios('POST', '/bms/banner/del', param);

export const BannerOnline = (param) => Axios('POST', '/bms/banner/online', param);

export const BannerSaveSort = (param) => Axios('POST', '/bms/banner/saveSort', param);

export const BannerSearch = (param) => Axios('POST', '/bms/banner/search', param);

export const TopicList = (param) => Axios('POST', '/bms/topic/list', param);

export const TopicAdd = (param) => Axios('POST', '/bms/topic/add', param);

export const TopicSave = (param) => Axios('POST', '/bms/topic/save', param);

export const TopicDel = (param) => Axios('POST', '/bms/topic/del', param);

export const TopicOnline = (param) => Axios('POST', '/bms/topic/online', param);

export const TopicSaveSort = (param) => Axios('POST', '/bms/topic/saveSort', param);

export const PlayListByLabel = (param) => Axios('POST', '/bms/playlist/bmsListByLabel', param);

export const PlayListSaveSort = (param) => Axios('POST', '/bms/playlist/saveSort', param);
