<template>
  <main-structure class="ng-sell-way" dir="ver" :loading="$store.state.contentLoading">
    <template slot="header">
      <div class="m-top-ms flex-0">
        <ng-breadcrumb :data="breadData"></ng-breadcrumb>
      </div>

      <!-- <transition>
        <div
          v-if="$root.$checkAuth($auth[name] && $auth[name].editAuth)"
          class="header-content header-search-content"
        >
          <template v-if="isEdit">
            <a-button class="w-px-100 m-right-base" @click="goCancel">取消</a-button>
            <a-button class="w-px-100" type="sub" @click="goSave">保存</a-button>
          </template>
          <template v-else>
            <a-button class="w-px-100" type="sub" @click="goEdit">编辑</a-button>
          </template>
        </div>
      </transition>-->
    </template>

    <ng-load :request="$axios.SongList_discountList" :params="loadPageParams" is-list>
      <template v-slot:list="{list}">
        <discount-config :data="list" :sid="sid"></discount-config>
      </template>
    </ng-load>
  </main-structure>
</template>

<script>
  import DiscountConfig from './discount/DiscountConfig.vue'
  import NgLoad from '@/components/NgLoad.vue'
  // import Observe from '~/utils/observe.c.js'

  const breadData = [
    {breadcrumbName: `歌单管理`, path: `/songlist`},
    {breadcrumbName: '配置折扣'}
  ]

  export default {
    data() {
      return {
        name: 'sell_config',
        breadData,
        isEdit: false,
        discountList: [],

        fromCustomOriginData: {},

        loadPageParams: {}
      }
    },

    computed: {
      sid() {
        return this.$route.params && this.$route.params.sid;
      }
    },

    created() {
      let sid = this.$route.params && this.$route.params.sid;
      let page = this.$route.query.page;

      this.loadPageParams = {playlist_id: sid}

      page && (this.loadPageParams.page = page)
    },

    mounted() {
      // this.getDiscountList();
    },

    components: {
      DiscountConfig,
      NgLoad
    },
    methods: {
      // goEdit() {
      //   this.isEdit = true;
      // },

      // goSave() {
      // let curTabIndex = this.curTabIndex;
      // let {scene, auth} = TabKey;

      // if (curTabIndex === scene) {
      //   this.saveScene();
      // }
      // // 
      // else if (curTabIndex === auth) {
      //   this.saveAuth();
      // }
      // },

      // saveDiscount() {
      //   let ele = this.$refs[`tabPane${TabKey.scene}`];

      //   if (!ele || !ele[0]) console.warn('ele dont be found');

      //   ele[0] && ele[0].saveData && ele[0].saveData().then(value => {
      //     let params = {scene: value};

      //     if (this.isCustom) {
      //       params.goods_id = this.goods_id;
      //       params.type = this.goods_type;
      //     }

      //     if (!params.scene || params.scene.length === 0) {
      //       this.$message.error('场景不可为空');
      //       return false;
      //     }

      //     this.saveData(params);
      //   })
      //   // .catch(err => {
      //   // console.warn(err)
      //   // });
      // },

      // saveData(params) {
      //   params = transferParamFromObjToStr(params);

      //   // 将比率小数转为整数
      //   Object.keys(params).forEach(item => {
      //     if (/ratio/.test(item)) params[item] = params[item] ? $func.mul(params[item], 100) : params[item]
      //     else if (/is_enabled/.test(item)) params[item] = params[item] === true ? '1' : params[item] ? params[item] : '0'
      //   })

      //   this.$store.commit('changeContentLoading', true);

      //   return new Promise(resolve => {
      //     this.saveRequest && typeof this.saveRequest === 'function' && this.saveRequest(params).then(res => {
      //       const data = res.data;

      //       if (data && data.code == 0) {
      //         this.$message.success('保存成功');
      //         this.getPriceInfo();
      //         this.isEditDir[this.curTabIndex] = false;
      //       }

      //       resolve(data);

      //       this.$store.commit('changeContentLoading', false);
      //     }).catch(() => {
      //       this.$store.commit('changeContentLoading', false);
      //     })
      //   })
      // },

      goCancel() {
        this.isEdit = false;
      },

      // getDiscountList() {
      //   this.$store.commit('changeContentLoading', true);

      //   let params = {
      //     playlist_id: this.sid
      //   };

      //   return new Promise(resolve => {
      //     this.$axios.SongList_discountList(params).then(res => {
      //       const data = res.data;

      //       if (data && data.code == 0) {
      //         this.discountList = data.data ? data.data.list : [];

      //         resolve(data.data);
      //       }

      //       this.$store.commit('changeContentLoading', false);
      //     }).catch(() => {
      //       this.$store.commit('changeContentLoading', false);
      //     })
      //   })
      // },

      // getDefaultData() {
      //   let params = {
      //     playlist_id: this.sid,
      //   }

      //   this.$store.commit('changeContentLoading', true);

      //   this.$axios.SongList_discountInfo(params).then(res => {
      //     const data = res.data;

      //     if (data && data.code == 0) {
      //       this.list = data.data ? data.data.list : [];
      //     }

      //     this.$store.commit('changeContentLoading', false);
      //   }).catch(() => {
      //     this.$store.commit('changeContentLoading', false);
      //   })
      // }
    }
  }
</script>

<style lang="scss" scoped>
  .ng-sell-way {
    /deep/ {
      .ant-tabs-content {
        height: 100%;
        overflow: auto;
        padding-bottom: 40px;
      }

      .auth-config {
        // padding-bottom: 100px;
        // padding-left: 40px;
      }
    }
  }
</style>
