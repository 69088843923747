export default {
  data() {
    return {
      // tableParams: {}
    }
  },
  methods: {
    // 获取筛选参数
    filterCommon(values, initFilter) {
      let query = values ? values : this.$route.query || {};

      query = Object.assign({}, query, {
        page: 1,
      });

      typeof initFilter === 'function' && (query = initFilter(query));

      query._reload = true;

      return query;
    },

    /**
     * 解析数据参数，如时间范围，数字区间
     * 
     * @param {*} query 
     * @param {*} keyDir {time: 'st', range: 'mm'}
     */
    parseArrParams(query, keyDir = {}) {
      let dir = {
        mm: ['min', 'max'],
        st: ['start', 'end']
      }

      Object.entries(query).forEach(([key, item]) => {
        let rangname = keyDir[key] ? keyDir[key] : 'st';

        if (Array.isArray(item)) {
          let [min, max] = item;
          let [skey, ekey] = dir[rangname] || [];

          query[`${key}[${skey}]`] = min || '';
          query[`${key}[${ekey}]`] = max || '';

          delete query[key];
        }
      })

      return query;
    },
  }
}