<template>
  <div class="auth-config m-top-base">
    <a-button v-if="!isCustom && isEdit" type="dashed" class="w-100" size="large" @click="addScene">
      <a-icon type="plus" />添加新场景
    </a-button>

    <p v-if="isCustom">提示：该曲库合约仅支持以下场景：</p>

    <template v-if="list && list.length > 0">
      <template v-for="(item, index) in list">
        <template v-if="isEdit">
          <scene-edit-item-custom
            v-if="isCustom"
            class="m-top-base"
            :index="index"
            :title="item.type | toArea"
            :default-data="item"
            :key="item.scene_id"
            @close="(cItem) => {onClickClose(cItem, index)}"
            :ref="`sceneEdit_${index}`"
          ></scene-edit-item-custom>
          <scene-edit-item
            v-else
            class="m-top-base"
            :index="index"
            :title="item.type | toArea"
            :default-data="item"
            :key="item.scene_id"
            @close="(cItem) => {onClickClose(cItem, index)}"
            :ref="`sceneEdit_${index}`"
          ></scene-edit-item>
        </template>

        <template v-else>
          <scene-info-item
            class="m-top-base"
            :title="item.type | toArea"
            :data="item"
            :scene_id="item.scene_id"
            :goods_id="goods_id"
            :key="item.scene_id"
          ></scene-info-item>
        </template>
      </template>
    </template>
    <template v-else>
      <a-empty style="margin-top: 100px;" />
    </template>
  </div>
</template>

<script>
  import SceneEditItem from './SceneCommonEdit.vue';
  import SceneEditItemCustom from './SceneCustomEdit.vue';
  import SceneInfoItem from './SceneInfo.vue';

  import {Empty as AEmpty} from 'ant-design-vue';

  import {BusinessSceneOptions} from '@/config/options';
  import {toOptionName} from '@/utils/function';

  export default {
    data() {
      return {
        list: [],
        sceneInfoList: []
      }
    },

    computed: {
      isCustom() {
        return !!this.goods_id;
      }
    },

    props: {
      goods_id: String,
      data: Array,
      isEdit: Boolean,
    },

    filters: {
      toArea(value) {
        return toOptionName(BusinessSceneOptions, value, undefined, undefined, '');
      },
    },

    watch: {
      data: {
        handler(newVal) {
          this.list = [...newVal];
          this.curIndex = newVal && newVal.length ? newVal.length : 1;
        },
        immediate: true
      }
    },

    components: {
      SceneEditItem, SceneInfoItem, SceneEditItemCustom, AEmpty
    },

    methods: {
      addScene() {
        // this.list.push({scene_id: this.curIndex});
        this.list.unshift({scene_id: this.curIndex});

        this.curIndex += 1;
      },

      onClickClose(item, index) {
        this.list.splice(index, 1);
      },

      saveData() {
        let promiseList = [];

        if (this.list && this.list.length > 0) {
          promiseList = this.list.map((item, index) => {
            return new Promise((resolve, reject) => {
              let ele = this.$refs[`sceneEdit_${index}`];

              try {
                ele && ele[0] && ele[0].form.validateFields((err, values) => {
                  console.log(values)
                  if (err) return reject(err);
                  resolve(values);
                })
              } catch (error) {
                reject();
              }
            })
          })
        }

        return Promise.all(promiseList)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .btn-group {
    position: absolute;
    top: 100px;
    right: 100px;
  }
</style>
