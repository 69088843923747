<template>
  <div class="tag-gather">
    <template v-for="(item, index) in list">
      <a-collapse
        class="collapse-content"
        expandIconPosition="right"
        :default-active-key="`${index}`"
        :key="`${index}`"
      >
        <a-collapse-panel :key="`${index}`">
          <div slot="header">
            <span>{{item.name}}</span>
            <span class="sub-text">{{`最多支持标注${item.tag_limit}个`}}</span>
          </div>
          <tag-cascade
            :data="item.child"
            @change="(arr) => {onChangeTags(arr, index)}"
            :max="item.tag_limit"
            :message="{max: `该类别可选中标签已达上限！`}"
            :ref="`tagCascade_${index}`"
          ></tag-cascade>
        </a-collapse-panel>
      </a-collapse>
    </template>
  </div>
</template>

<script>
  import {Collapse as ACollapse} from 'ant-design-vue'
  import TagCascade from './TagCascade.vue'

  const ACollapsePanel = ACollapse.Panel;

  export default {
    data() {
      return {
        // selectedList: [],
        keyId: 'label_id',
      }
    },

    props: {
      list: {
        type: Array,
        default() {
          return []
        }
      },
      selectedList: Array,
      delInfo: Object,
    },

    watch: {
      delInfo: {
        handler(newVal) {
          this.onDelTags(newVal);
        },
      }
    },

    components: {
      TagCascade, ACollapse, ACollapsePanel,
    },
    methods: {
      onChangeTags(selectedArr, index) {
        let list = this.selectedList || [];

        list[index] = [];
        list.splice(index, 1, selectedArr);

        this.$emit('update:selectedList', list);
      },

      onDelTags(options) {
        let index = options.collapseIndex;

        if (options[this.keyId]) {
          let ref = this.$refs[`tagCascade_${index}`];

          ref && ref[0] && typeof ref[0].delSelected === 'function' && ref[0].delSelected(options[this.keyId])
        }
      },

      onInitTags(list) {
        this.list.forEach((item, index) => {
          let ref = this.$refs[`tagCascade_${index}`];

          ref && ref[0] && typeof ref[0].initSelected === 'function' && ref[0].initSelected(list[index]);
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .tag-gather {
    .collapse-content {
      margin-bottom: 40px;
    }

    /deep/ {
      .sub-text {
        font-size: 12px;
        color: #999;
        margin-left: 10px;
      }
    }
  }
</style>
