import { render, staticRenderFns } from "./ConfirmAndInfo.vue?vue&type=template&id=3ffd1992&scoped=true&"
import script from "./ConfirmAndInfo.vue?vue&type=script&lang=js&"
export * from "./ConfirmAndInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ffd1992",
  null
  
)

export default component.exports