<template>
  <main-structure class="ng-verify-detail ng-detail" dir="ver" :loading="loading">
    <template slot="header">
      <ng-breadcrumb :data="breadData"></ng-breadcrumb>
    </template>
    <div class="info-content">
      <ng-detail title="基础信息">
        <div class="flex order-info">
          <ul class="flex-1">
            <li>订单编号：{{orderInfo.order_no}}</li>
            <!-- <li>订单渠道：{{orderInfo.channel | toChannel}}</li> -->
            <li>下单时间：{{orderInfo.create_at}}</li>
            <li>付款方式：{{orderInfo.pay_way | toPayWay}}</li>

            <li>订单状态：{{orderInfo.status | toOrderStatus}}</li>
            <li>应付金额：￥{{orderInfo.total_amount}}</li>
            <li>实付金额：￥{{orderInfo.pay_amount}}</li>
          </ul>
          <ul class="flex-1">
            <li>用户名：{{orderInfo.user_name}}</li>
            <li>用户ID：{{orderInfo.user_id}}</li>

            <a-button
              v-if="orderInfo.status === $val.UndoPayOrderType.paying"
              type="primary"
              @click="onSettelOrder"
            >线下结单</a-button>
          </ul>
        </div>
      </ng-detail>

      <ng-detail class="m-top-base" title="商品信息">
        <div class="flex order-info">
          <ul class="flex-1">
            <li>套餐名称：{{orderInfo.goods_name}}</li>
            <li>套餐类型：{{orderInfo.goods_cate}}</li>
            <li>使用渠道：{{orderInfo.use_mode}}</li>
            <li>授权地域：{{orderInfo.auth_area | toArea}}</li>
            <li>授权期限：{{orderInfo.auth_time | toAuthPeriod}}</li>
          </ul>
          <ul class="flex-1">
            <li>有效期：{{orderInfo.valid_time}}天</li>
            <li>授权次数：{{orderInfo.auth_num}}</li>
            <li>下载次数：{{orderInfo.download_num}}</li>
          </ul>
        </div>
      </ng-detail>
    </div>

    <dialog-settle-order
      :visible.sync="showDialog"
      :order_id="orderInfo.order_id"
      :order_type="$val.orderType.package"
      @success="onSuccess"
    ></dialog-settle-order>
  </main-structure>
</template>

<script>
  import ChannelOrderDetailList from './detail/ChannelOrderDetailList.vue'
  import DialogSettleOrder from './package/DialogSettleOrder.vue'

  import {NgBreadcrumb} from '@/components/index';
  import {OrderStatusOptionsAdmin, OrderChannelOptions} from '@/config/options'
  import {toOptionName} from '@/utils/function';

  const breadData = [
    {breadcrumbName: `套餐订单`, path: `/order/package`},
    {breadcrumbName: `订单详情`}
  ]

  export default {
    data() {
      return {
        name: `packageorder_info`,
        loading: false,
        breadData,
        orderInfo: {},
        showDialog: false,
      }
    },
    components: {
      NgBreadcrumb, ChannelOrderDetailList, DialogSettleOrder
    },

    created() {
      this.getInfo(this.$route.params.order_id)
    },

    filters: {
      toOrderStatus(value) {
        return toOptionName(OrderStatusOptionsAdmin, value);
      },
      toChannel(value) {
        return toOptionName(OrderChannelOptions, value);
      }
    },

    methods: {
      getInfo(order_id) {
        let params = {order_id};

        this.loading = true;

        this.$axios.OrderPackage_info(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.orderInfo = data.data;
          }

          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })
      },

      onSettelOrder() {
        this.showDialog = true;
      },

      onSuccess() {
        this.getInfo(this.$route.params.order_id)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .info-content {
    padding: 20px;
  }

  .order-info {
    line-height: 3;
  }
</style>
