<template>
  <a-modal width="500px" title="确认曲库售价方案" v-model="show" :maskClosable="false">
    <div>
      <p>请确认本曲库是否应用「通用售价方案」，如需应用，点击「应用通用定价方案」按钮，该曲库售价方案即刻生效。</p>
      <p>如通用售价方案无法满足曲库售卖需求，点击「自定义定价方案」按钮，开始为曲库定制售价方案。</p>
    </div>

    <template slot="footer">
      <a-button key="back" @click="handleCancel">取消</a-button>

      <a-button key="save" type="primary" @click="useCommonWay()" :loading="commonLoading">应用通用售价方案</a-button>
      <a-button key="submit" type="primary">
        <router-link
          :to="`/goods/sellway?goods_id=${data[isLib ? 'lib_id' : 'goods_id']}&goods_type=${data.goods_type}&done_price=${data.is_price}`"
        >自定义售价方案</router-link>
      </a-button>
    </template>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';

  export default {
    data() {
      return {
        show: false,
        commonLoading: false,
      }
    },

    props: {
      id: String,
      data: {
        type: Object,
        default() {
          return {}
        }
      },
      lib_tabs_id: String
    },

    computed: {
      isLib() {
        return this.$route.query.good_cate === this.lib_tabs_id
      }
    },

    mixins: [dialogMixins],

    methods: {
      useCommonWay() {
        let params = {type: this.data.goods_type, goods_id: this.data.goods_id};

        this.commonLoading = true;

        this.$axios.Price_UseCommonPrice(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('通用方案应用成功');
            this.show = false;
            this.$emit('success');
          }

          this.commonLoading = false;
        }).catch(() => {
          this.commonLoading = false;
        })
      },

      handleCancel() {
        this.show = false;
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
