<template>
  <a-modal
    class="add-tags-type"
    :title="title"
    width="640px"
    v-model="show"
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
    :bodyStyle="bodyStyle"
  >
    <a-form
      :form="form"
      layout="horizontal"
      :label-col="{ xs: { span: 5 } }"
      :wrapper-col="{ xs: { span: 17 } }"
      class="ng-form m-top-base"
    >
      <a-form-item label="类别名称">
        <ng-input
          :maxLength="10"
          show-counter
          placeholder="请输入商品类别名称"
          v-decorator="[ 'name', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'}]"
        ></ng-input>
      </a-form-item>

      <a-form-item label="授权场景">
        <a-select
          :options="BusinessSceneOptions"
          placeholder="请选择授权场景"
          v-decorator="[`auth_scene`,  { rules: [$validate.required] }]"
        ></a-select>
      </a-form-item>

      <a-form-item label="类别简介">
        <ng-textarea
          placeholder="请输入商品类别描述"
          :maxLength="100"
          show-counter
          v-decorator="['desc', { rules: [$validate.required], initialValue: '' }]"
          style="height: 120px"
        ></ng-textarea>
      </a-form-item>

      <a-form-item label="自动发布">
        <a-switch v-decorator="['is_autoissue', { valuePropName: 'checked', initialValue: false }]"></a-switch>
      </a-form-item>

      <a-form-item v-if="form.getFieldValue('is_autoissue')" required label="上架时间">
        <a-form-item :style="{display: 'inline-block', width: '140px', marginRight: '20px'}">
          <date-picker
            class="m-right-base"
            valueFormat="YYYY-MM-DD"
            v-decorator="['date', { rules: [$validate.required], initialValue: '' }]"
          ></date-picker>
        </a-form-item>
        <a-form-item :style="{display: 'inline-block', width: '80px'}">
          <time-picker
            valueFormat="HH:mm:ss"
            v-decorator="['time', { rules: [$validate.required], initialValue: '' }]"
          ></time-picker>
        </a-form-item>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import {DatePicker, TimePicker} from 'ant-design-vue'
  import {BusinessSceneOptions} from '@/config/options'

  let newSceneOptions = BusinessSceneOptions.filter(item => item.value != '0');

  export default {
    data() {
      return {
        show: false,

        BusinessSceneOptions: newSceneOptions,

        confirmLoading: false,
      }
    },

    props: {
      handle: String,
      options: Object,
      id: String,
      defaultValue: {
        type: Object,
        default() {
          return {}
        }
      },
    },

    computed: {
      isEdit() {
        return this.handle === this.$var.Handle.edit;
      },

      title() {
        return this.isEdit ? '编辑商品类别' : '新增商品类别'
      },

      request() {
        return this.isEdit ? this.$axios.Good_categ_edit : this.$axios.Good_categ_add
      }
    },

    beforeCreate() {
      this.form = this.$form.createForm(this);
    },

    watch: {
      visible(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            if (this.isEdit) {
              this.setDefaultValue(this.defaultValue);
            }
          })
        }
      }
    },

    mixins: [dialogMixins],

    components: {
      DatePicker, TimePicker
    },

    methods: {

      handleOk(isClose) {
        this.form.validateFieldsAndScroll((errors, values) => {
          if (!errors) {
            let CommonCheck = this.$val.CommonCheck;

            values.issue_time = `${values.date} ${values.time}`
            values.is_autoissue = values.is_autoissue ? CommonCheck.correct : CommonCheck.fail;

            this.addCate(values, isClose);
          }
        })
      },

      handleCancel() {
        this.$emit('update:visible', false);
      },

      setDefaultValue(values) {
        let CommonCheck = this.$val.CommonCheck;
        let {name, desc, is_autoissue, issue_time, auth_scene} = values;

        this.form.resetFields();

        if (is_autoissue == CommonCheck.correct) {
          this.form.setFieldsValue({is_autoissue: true});

          let timeArr = issue_time ? issue_time.split(' ') : [];

          let date = timeArr[0] || '';
          let time = timeArr[1] || '';

          this.$nextTick(() => {
            this.form.setFieldsValue({name, desc, date, time, auth_scene});
          })
        } else {
          this.form.setFieldsValue(Object.assign(values, {is_autoissue: false}));
        }
      },

      addCate(params) {
        let message;

        this.confirmLoading = true;

        if (this.isEdit) {
          message = '编辑成功';
          params.cate_id = this.id;
        } else {
          message = '添加成功';
        }

        typeof this.request === 'function' && this.request(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success(message);
            this.$emit('success', this.isEdit);

            this.$emit('update:visible', false);
          }

          this.confirmLoading = false;
        }).catch(() => {
          this.confirmLoading = false;
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .add-tags-type {
    /deep/ {
      .ant-checkbox-group-item {
        margin-bottom: 10px;
      }
    }

    .color-picker {
      vertical-align: middle;

      /deep/ .colorBtn {
        width: 100%;
        height: 100%;
      }
    }
  }
</style>
