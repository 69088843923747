import {
  $storage
} from "@/utils/from-common";
import * as $var from '@/utils/variables';
import * as $func from '@/utils/function.js'
import {
  UserInfo,
  AllAuthList
} from '@/api/index'
import {
  Modal
} from 'ant-design-vue';

export default {
  // 退出登录
  outLogin(context, data = {}) {
    $storage.remove($var.STORAGE.TOKEN);
    $storage.remove($var.STORAGE.USERINFO);
    $storage.remove($var.STORAGE.AUTHLIST);

    context.commit('changeLogin', false);

    let $router = data.router;

    $router && $router.replace && $router.replace('/login');
  },

  // 先提示，后点击确定退出登录
  checkOutLogin(context, data) {
    Modal.confirm({
      title: data.title || '是否退出登录？',
      content: data.content || '',
      onOk() {
        context.dispatch('outLogin', data)
      }
    })
  },

  // 登录成功后需要保存的一些数据
  inLogin(context, data) {
    context.commit('changeLogin', true);
    context.commit('changeUserInfo', data);
    $storage.set($var.STORAGE.TOKEN, data.token);
    $storage.set($var.STORAGE.USERINFO, data);
  },

  // 判断登录
  checkLogin(context, router) {
    let token = $storage.get($var.STORAGE.TOKEN);

    if (token) {
      context.commit('changeLogin', true);
    } else {
      context.dispatch('outLogin', router);
    }
  },

  // 获取用户信息
  getUserInfo(context, request) {
    if (context.state.login) {
      let userinfo = $storage.get($var.STORAGE.USERINFO);

      if (userinfo && !request) {
        context.commit('changeUserInfo', userinfo);
      } else {
        UserInfo().then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            // something
            $storage.set($var.STORAGE.USERINFO, data.data);
            context.commit('changeUserInfo', data.data);
          }
        })
      }
    }
  },

  // 获取权限列表
  getUserAuthList(context, request) {
    if (context.state.login) {
      let authlist = $storage.get($var.STORAGE.AUTHLIST);

      if (authlist && !request) {
        context.commit('changeUserInfo', authlist);
      } else {
        AllAuthList().then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            let list = data.data ? data.data.actions : [];

            if (list && list.length > 0) {
              list = list.map(item => item.label);

              $storage.set($var.STORAGE.AUTHLIST, list);

              context.commit('changeAuthList', list);
            }
          }
        })
      }
    }
  },

  // 播放音频
  onPlayGlobalVideo(context, scope) {
    let playing = context.state.globalVideoPlaying;
    let stUrl = context.state.globalVideoInfo.url;
    let scopeUrl = scope.url;

    if (!scopeUrl) return;

    if (stUrl !== scopeUrl) {
      context.commit('changeVideoPlaying', false);
      context.commit('changeVideoInfo', scope);

      setTimeout(() => {
        context.commit('changeVideoPlaying', true);
      }, 200);
    } else {
      context.commit('changeVideoPlaying', !playing);
    }
  },

  changeVideoList(context, listOrFormat) {
    let list = [];

    if (Array.isArray(listOrFormat)) {
      list = listOrFormat;
    }
    // 
    else if ($func.isObject(listOrFormat)) {
      let format = listOrFormat.format;

      list = [...listOrFormat.list];

      list = list.map(item => $func.changeDataItemKey(item, format));
    }

    context.commit('changeVideoList', list);
    context.commit('changeVideoInfo', {});
  }
}