<template>
  <a-row type="flex" class="mark-music-detail" align="top" :gutter="10">
    <a-col :span="10" style>
      <form-upload
        class="upload-box"
        accept="image/jpg, image/jpeg, image/png"
        list-type="picture-card"
        :action="$axios.UploadFile"
        :data="uploadParams"
        :name="uploadName"
        :init-header-url="defaultHeaderUrl"
        v-decorator="['upload_logo', { initialValue: '' }]"
        @change="onChangeLogo"
        :reloadUploading="uploadLoading"
        :disabled="!($root.$checkAuth($auth[authName] && $auth[authName].editMusicLogo))"
      >
        <div class="upload-mask" slot="mask">
          <svg class="iconfont">
            <use xlink:href="#iconzhaoxiangji" />
          </svg>
        </div>
      </form-upload>
    </a-col>

    <a-col :span="14" class="font-xs detail-content">
      <a-row>
        <a-col>
          <b class="font-sm">{{ infoData.music_name || "--" }}</b>
        </a-col>
        <a-col>{{ infoData.suplr_name || "--" }}</a-col>
        <a-col>上传于 {{ infoData.create_at || "--" }}</a-col>
        <a-col>{{infoData.type | toMarkedType}}</a-col>
        <a-col v-if="infoData.type === $val.MusicType.YX">
          音效类型：
          <a-dropdown
            v-if="$root.$checkAuth($auth[authName] && $auth[authName].editMusicType)"
            :trigger="['click']"
          >
            <a
              href="javascript:;"
              :disabled="loading"
              class="ant-dropdown-link cursor-pointer font-grey"
              @click="e => e.preventDefault()"
            >
              {{infoData.sound_type | toMusicEffect}}
              <a-icon :type="loading ? 'loading': 'down'" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item
                v-for="item in MusicEffectOptions"
                :key="item.value"
                @click="onClickDropItem(item.value)"
              >
                <a href="javascript:;">{{item.label}}</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          <span v-else>{{infoData.sound_type | toMusicEffect}}</span>
        </a-col>
      </a-row>
    </a-col>
    <a-col
      v-if="infoData.type === $val.MusicType.YX"
      :span="24"
      class="m-top-ms"
    >{{infoData.sound_desc}}</a-col>
  </a-row>
</template>

<script>
  import {toOptionName} from '@/utils/function';
  import {MusicTypeOptions, MusicEffectOptions} from '@/config/options';

  export default {
    inject: ['authName'],

    data() {
      return {
        infoData: {},
        MusicEffectOptions,
        loading: false,
        uploadLoading: false,

        uploadParams: {},
        uploadName: 'file',
        defaultHeaderUrl: '',
      }
    },

    props: {
      data: {
        type: Object,
        default() {
          return {}
        }
      }
    },

    watch: {
      data: {
        handler(newVal) {
          this.infoData = newVal;
          this.defaultHeaderUrl = newVal.logo_url || '';
        },
        immediate: true
      }
    },

    filters: {
      toMarkedType: function (value) {
        return toOptionName(MusicTypeOptions, value);
      },
      toMusicEffect: function (value) {
        return toOptionName(MusicEffectOptions, value);
      }
    },

    created() {
      this.uploadParams = {
        upload_type: 1,
        field_name: this.uploadName
      }
    },

    components: {

    },
    methods: {
      onChangeLogo(logo) {
        let params = {
          music_id: this.data.music_id,
          logo
        };

        this.uploadLoading = true;

        this.$axios.Music_editMusicLogo(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('修改封面成功');
          }

          this.uploadLoading = false;
        })
      },

      onClickDropItem(sound_type) {
        let params = {
          music_id: this.infoData.music_id,
          sound_type
        };

        this.loading = true;

        this.$axios.Music_editSoundType(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('音效类型修改成功');
            this.infoData.sound_type = sound_type;
          }

          this.loading = false;
        })
      },

      changeMusicEffect() {
        let params = {};

        this.$axios.Music_editMusicLogo(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            // something
          }
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .mark-music-detail {
    .detail-content {
      line-height: 24px;
    }

    /deep/ {
      .ant-upload {
        width: 100%;
        height: auto;
        border: none;
        .ant-upload {
          padding: 0;
        }
      }
    }

    // .upload-mask {
    //   position: absolute;
    //   top: 0;
    //   bottom: 0;
    //   left: 0;
    //   right: 0;
    //   background-color: rgba($color: #000000, $alpha: 0.6);
    //   opacity: 0;
    //   transition: opacity 0.1s linear;

    //   .iconfont {
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     color: #fff;
    //     transform: translate(-50%, -50%);
    //   }
    // }

    // .upload-box {
    //   &:hover {
    //     .upload-mask {
    //       opacity: 1;
    //     }
    //   }
    // }
  }
</style>
