/**
 * 定价管理
 */
import {
  Axios
} from '@/utils/from-common';

// 曲库列表
// export const Price_LibraryList = (param) => Axios('POST', '/bms/price/list', param);

// 获取成本价
export const Price_GetCost = (param) => Axios('POST', '/bms/price/getCost', param);

// 设置成本价
export const Price_SetCost = (param) => Axios('POST', '/bms/price/setCost', param);

// 获取 通用售价信息
export const Price_PriceCommonInfo = (param) => Axios('POST', '/bms/price/commonInfo', param);

// 获取 自定义售价信息
export const Price_PriceCustomInfo = (param) => Axios('POST', '/bms/price/customInfo', param);

// 使用通用售价
export const Price_UseCommonPrice = (param) => Axios('POST', '/bms/price/useComnPrice', param);

// 修改通用场景
export const Price_ChangeCommonScene = (param) => Axios('POST', '/bms/price/saveCommonScene', param);

// 修改自定义场景
export const Price_ChangeCustomScene = (param) => Axios('POST', '/bms/price/saveCustomScene', param);

// 修改通用场景状态
export const Price_ChangeCommonSceneStatus = (param) => Axios('POST', '/bms/price/upComnSceneStat', param);

// 修改自定义场景状态
export const Price_ChangeCustomSceneStatus = (param) => Axios('POST', '/bms/price/upCstmSceneStat', param);

// 修改通用授权
export const Price_ChangeCommonAuth = (param) => Axios('POST', '/bms/price/saveCommonAuth', param);

// 修改自定义授权
export const Price_ChangeCustomAuth = (param) => Axios('POST', '/bms/price/saveCustomAuth', param);

// 修改通用授权状态
export const Price_ChangeCommonAuthStatus = (param) => Axios('POST', '/bms/price/upComnAuthStat', param);

// 修改自定义授权状态
export const Price_ChangeCustomAuthStatus = (param) => Axios('POST', '/bms/price/upCstmAuthStat', param);

// 获取默认自定义信息
export const Price_DefaultCustomeInfo = (param) => Axios('POST', '/bms/price/initedCstmInfo', param);


// === 商品类别
// 商品类别列表
export const Good_categ_list = (param) => Axios('POST', '/bms/goods/cateList', param);

// 商品类别 详情
export const Good_categ_info = (param) => Axios('POST', '/bms/goods/cateInfo', param);

// 商品类别 添加
export const Good_categ_add = (param) => Axios('POST', '/bms/goods/addCate', param);

// 商品类别 修改
export const Good_categ_edit = (param) => Axios('POST', '/bms/goods/saveCate', param);

// 商品类别 删除
export const Good_categ_del = (param) => Axios('POST', '/bms/goods/delCate', param);

// 商品类别 场景
export const Good_cate_scene = (param) => Axios('POST', '/bms/goods/cateScene', param);


// ===== 商品
// 商品列表
export const Good_list = (param) => Axios('POST', '/bms/goods/goodsList', param);

// 商品详情
export const Good_info = (param) => Axios('POST', '/bms/goods/goodsInfo', param);

// 添加商品
export const Good_add = (param) => Axios('POST', '/bms/goods/addGoods', param);

// 修改商品
export const Good_edit = (param) => Axios('POST', '/bms/goods/saveGoods', param);

// 修改商品 状态
export const Good_status = (param) => Axios('POST', '/bms/goods/upGoodsStat', param);

// 删除商品
export const Good_del = (param) => Axios('POST', '/bms/goods/delGoods', param);