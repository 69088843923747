import Vue from 'vue';
import {
  Avatar,
  Button,
  Icon,
  Row,
  Checkbox,
  Col,
  Card,
  Badge,
  Breadcrumb,
  Dropdown,
  Menu,
  Modal,
  PageHeader,
  Pagination,
  Popover,
  // DatePicker,
  Form,
  FormModel,
  Input,
  InputNumber,
  Layout,
  Radio,
  Spin,
  Select,
  Switch,
  // TimePicker,
  // Table,
  Tag,
  Tabs,
  Tooltip,
  Message,
  Divider,
  Empty,
  Descriptions
} from 'ant-design-vue';

Vue.prototype.$message = Message;
Vue.prototype.$info = Modal.info;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$success = Modal.success;
Vue.prototype.$warning = Modal.warning;

Vue.use(Avatar);
Vue.use(Button);
Vue.use(Badge);
Vue.use(Checkbox);
Vue.use(Card);
Vue.use(Menu);
Vue.use(Input);
Vue.use(Form);
Vue.use(Layout);
Vue.use(Select);
Vue.use(Modal);
Vue.use(Radio);
Vue.use(Descriptions);
// Vue.use(DatePicker);
Vue.use(Tabs);

Vue.component(Icon.name, Icon)
Vue.component(Row.name, Row)
Vue.component(Col.name, Col)
Vue.component(Breadcrumb.name, Breadcrumb)
Vue.component(Dropdown.name, Dropdown)
Vue.component(PageHeader.name, PageHeader)
Vue.component(Pagination.name, Pagination)
Vue.component(Popover.name, Popover)
Vue.component(FormModel.name, FormModel)
Vue.component(InputNumber.name, InputNumber)
Vue.component(Switch.name, Switch)
Vue.component(Spin.name, Spin)
// Vue.component(TimePicker.name, TimePicker)
// Vue.component(Table.name, Table)
Vue.component(Tag.name, Tag)
Vue.component(Tooltip.name, Tooltip)
Vue.component(Divider.name, Divider)
Vue.component(Empty.name, Empty)

import {
  NgBreadcrumb,
  TableSwitch,
  NgTable,
  NgInput,
  NgTextarea,
  FormSelect,
  FormUpload,
  FormUploadFiles,
  FormCountry,
  FormMinMax,
  NgDetail,
  NgImage,
} from '~/components/component.c';


Vue.component(NgBreadcrumb.name, NgBreadcrumb);
Vue.component(TableSwitch.name, TableSwitch);
Vue.component(NgTable.name, NgTable);
Vue.component(NgInput.name, NgInput);
Vue.component(NgTextarea.name, NgTextarea);
Vue.component(FormSelect.name, FormSelect);
Vue.component(FormUpload.name, FormUpload);
Vue.component(FormUploadFiles.name, FormUploadFiles);
Vue.component(FormCountry.name, FormCountry);
Vue.component(FormMinMax.name, FormMinMax);
Vue.component(NgDetail.name, NgDetail);
Vue.component(NgImage.name, NgImage);