<template>
  <a-modal
    width="640px"
    class="add-tags-type"
    title="编辑合同信息"
    v-model="show"
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
    :bodyStyle="bodyStyle"
  >
    <a-form
      :form="form"
      layout="horizontal"
      :label-col="{ xs: { span: 5 } }"
      :wrapper-col="{ xs: { span: 17 } }"
      class="ng-form m-top-base"
    >
      <ng-detail title="公司信息">
        <a-form-item label="公司名称">
          <a-input
            placeholder="请输入公司名称"
            v-decorator="[ 'company[name]', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'}]"
          ></a-input>
        </a-form-item>

        <a-form-item label="公司地址">
          <a-input
            placeholder="请输入公司地址"
            v-decorator="[ 'company[address]', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'}]"
          ></a-input>
        </a-form-item>

        <a-form-item label="联系人">
          <a-input
            placeholder="请输入联系人姓名"
            v-decorator="[ 'company[contacter]', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'}]"
          ></a-input>
        </a-form-item>

        <a-form-item label="联系电话">
          <a-input
            placeholder="请输入联系电话"
            v-decorator="[ 'company[phone]', { rules: [$validate.required, $validate.phone], initialValue: '', validateTrigger: 'blur'}]"
          ></a-input>
        </a-form-item>

        <a-form-item label="联系邮箱">
          <a-input
            placeholder="请输入联系邮箱"
            v-decorator="[ 'company[email]', { rules: [$validate.required, $validate.email], initialValue: '', validateTrigger: 'blur'}]"
          ></a-input>
        </a-form-item>
      </ng-detail>

      <ng-detail title="收件信息">
        <a-form-item label="联系人">
          <a-input
            placeholder="请输入收件人姓名"
            v-decorator="[ 'express[name]', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'}]"
          ></a-input>
        </a-form-item>

        <a-form-item label="联系人电话">
          <a-input
            placeholder="请输入联系人电话"
            v-decorator="[ 'express[phone]', { rules: [$validate.required, $validate.phone], initialValue: '', validateTrigger: 'blur'}]"
          ></a-input>
        </a-form-item>

        <a-form-item label="联系人地址">
          <a-input
            placeholder="请输入联系人地址"
            v-decorator="[ 'express[address]', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'}]"
          ></a-input>
        </a-form-item>
      </ng-detail>

      <ng-detail title="授权信息">
        <a-form-item label="项目名称">
          <a-input
            placeholder="请输入项目名称"
            v-decorator="[ 'project', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'}]"
          ></a-input>
        </a-form-item>

        <a-form-item label="投放渠道">
          <a-input
            placeholder="请输入投放渠道"
            v-decorator="[ 'put_path', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'}]"
          ></a-input>
        </a-form-item>

        <a-form-item label="项目描述">
          <ng-textarea
            placeholder="请输入项目描述"
            v-decorator="['desc', { rules: [$validate.required], initialValue: '' }]"
            style="height: 120px"
          ></ng-textarea>
        </a-form-item>
      </ng-detail>
    </a-form>
  </a-modal>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import {BusinessSceneOptions} from '@/config/options'

  let newSceneOptions = BusinessSceneOptions.filter(item => item.value != '0');

  export default {
    data() {
      return {
        show: false,

        BusinessSceneOptions: newSceneOptions,

        confirmLoading: false,

        info: {}
      }
    },

    props: {
      handle: String,
      options: Object,
      id: String,
      defaultValue: {
        type: Object,
        default() {
          return {}
        }
      },
    },

    beforeCreate() {
      this.form = this.$form.createForm(this);
    },

    watch: {
      visible(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.setDefaultValue(this.defaultValue);
          })
        }
      }
    },

    mixins: [dialogMixins],

    methods: {

      handleOk() {
        this.form.validateFieldsAndScroll((errors, values) => {
          if (!errors) {
            this.editComtract(values);
          }
        })
      },

      handleCancel() {
        this.$emit('update:visible', false);
      },

      setDefaultValue(values) {
        this.form.resetFields();

        if (values) {
          this.form.setFieldsValue({
            'company[name]': values.company.name,
            'company[address]': values.company.address,
            'company[contacter]': values.company.contacter,
            'company[phone]': values.company.phone,
            'company[email]': values.company.email,
            'express[name]': values.express.name,
            'express[phone]': values.express.phone,
            'express[address]': values.express.address,
            'project': values.project,
            'put_path': values.project_path,
            'desc': values.project_desc,
          });
        }
      },

      editComtract(params) {
        this.confirmLoading = true;

        params.contract_id = this.id;

        this.$axios.BuyManage_contractEdit(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('编辑成功');
            this.$emit('success');

            this.$emit('update:visible', false);
          }

          this.confirmLoading = false;
        }).catch(() => {
          this.confirmLoading = false;
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .info-list {
    line-height: 2.2;
  }
</style>
