<template>
  <div class="h-100">
    <ng-table
      class="ng-table"
      childrenColumnName="child"
      :columns="columns"
      :params="params"
      :rowKey="row => row.music_id"
      :request="$axios.Enterlibrary_musicList"
      :load.sync="loadTable"
      :reload.sync="reloadTable"
      @dataChange="(data) => onTableDataChange(data, null, false, true)"
      :scroll="{y: scrollyHeight - 20}"
    >
      <template slot="name" slot-scope="text, scope">
        <a href="javascript:;" @click="onClickMusic(scope)" :title="text">{{text}}</a>
      </template>
      <template slot="type" slot-scope="text">
        <span>{{text | toMusicType}}</span>
      </template>
      <template slot="operation" slot-scope="text, scope">
        <a-button
          v-if="$root.$checkAuth($auth[authName] && $auth[authName].edit)"
          class="m-right-ms"
          type="link"
          @click="onEdit(scope)"
        >修改</a-button>
        <a-button
          v-if="$root.$checkAuth($auth[authName] && $auth[authName].del)"
          class="ant-btn-error"
          type="link"
          @click="onDelete(scope)"
        >删除</a-button>
      </template>
    </ng-table>

    <dialog-edit-music
      :visible.sync="showDialog"
      :music_id="currentData.music_id"
      :type="$route.params.type"
      :info="currentData"
      @success="onSuccess"
    ></dialog-edit-music>
  </div>
</template>

<script>
  import DialogEditMusic from './DialogEditMusic.vue';
  import MusicTableMixins from '../musicTableMixins'
  import {tableMixins} from '@/mixins';

  import * as TabCol from '@/config/columns';

  const columns = [
    TabCol.commonKey('', 'expand', '40px'),
    TabCol.colIdFunc((text, index) => !text.parent_id ? index + 1 : ''),
    TabCol.commonKey('音乐名称', 'name', {ellipsis: true, isSlot: true}),
    TabCol.commonKey('音乐原名', 'original_name', {ellipsis: true}),
    TabCol.commonKey('音乐类型', 'type', '', true),
    TabCol.commonKey('ISRC', 'isrc'),
    TabCol.commonKey('ISWC', 'iswc'),
    TabCol.commonKey('BPM', 'bpm'),
    TabCol.commonKey('比特率', 'bitrate'),
    TabCol.commonKey('采样率', 'samples'),
    TabCol.commonKey('时长(s)', 'duration'),
    TabCol.commonKey('文件大小(M)', 'size', '', true),
    TabCol.operation('100px')
  ]

  export default {
    data() {
      return {
        columns,
        params: {},
        showDialog: false,
        currentData: {},
      }
    },

    props: {
      authName: String,
      scrollyHeight: {
        type: Number,
        default: 400
      },
    },

    components: {
      DialogEditMusic
    },

    mounted() {
      let {query: {page}, params: {task_id, type}} = this.$route;

      this.params = {page: page || 1, task_id: task_id, music_type: type};
    },

    mixins: [
      tableMixins, MusicTableMixins
    ],

    methods: {

      onClickMusic(scope) {
        let {music_url: url} = scope;

        this.$store.dispatch('onPlayGlobalVideo', Object.assign(scope, {url}));
      },

      onEdit(scope) {
        this.currentData = scope;
        this.showDialog = true;
      },

      onSuccess() {
        this.loadTableData();
      },

      // onTableDataChange(data) {
      //   this.$store.commit('changeVideoList', data.map(item => Object.assign(item, {url: item.music_url})));
      // },

      onDelete(scope) {
        let vm = this;

        this.$confirm({
          width: '480px',
          title: '确认要删除该音乐吗？',
          // content: '删除该角色，关联该角色的成员账号，会失去对应的权限；',
          onOk() {
            return new Promise((resolve, reject) => {
              vm.deleteFunc(scope, resolve, reject);
            });
          }
        });
      },

      deleteFunc(scope, next, reject) {
        this.$axios.Music_del({music_id: scope.music_id}).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success('已成功删除该音乐');
            next();

            this.loadTableData();
          } else {
            reject()
          }
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .ant-table td {
    white-space: nowrap;
  }
</style>
