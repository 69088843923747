<template>
  <a-form class="ng-form-style ng-filter" layout="inline" :form="form">
    <!-- <a-form-item label="部门类型">
      <form-department
        v-decorator="['dept_id', { initialValue: '0' }]"
        changeOnSelect
        expandTrigger="hover"
        placeholder="请选择"
        all
      ></form-department>
    </a-form-item>

    <a-form-item label="角色类型">
      <form-roles v-decorator="['role_id']"></form-roles>
    </a-form-item>-->

    <a-form-item label="状态">
      <form-member-status
        v-decorator="['is_enabled', {initialValue: defaultData.is_enabled || ''}]"
        placeholder="请选择"
      ></form-member-status>
    </a-form-item>
  </a-form>
</template>

<script>
  // import {FormDepartment} from '@/components/index';
  // import {FormRoles} from '@/components/index';
  import {FormMemberStatus} from '@/components/index'

  export default {
    data() {
      return {
      }
    },

    props: {
      defaultData: Object
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        onValuesChange: (props, values) => {
          this.$root.$goSearch(values);
          this.$emit('change', values);
        }
      });
    },

    components: {
      // FormDepartment, FormRoles, 
      FormMemberStatus
    },

    methods: {
    }
  }
</script>

<style lang="scss" scoped>
</style>
