import {
  Axios
} from '@/utils/from-common';

/* ***********  入库管理  *********** */
// 待服务列表
export const Enterlibrary_waitServeList = (param) => Axios('POST', '/bms/batch/unStoreList', param);

// 服务中列表
export const Enterlibrary_servingList = (param) => Axios('POST', '/bms/batch/storingList', param);

// 已完成列表
export const Enterlibrary_doneServeList = (param) => Axios('POST', '/bms/batch/storeDoneList', param);

// 指派
export const Enterlibrary_taskToAnyone = (param) => Axios('POST', '/bms/task/addStore', param);

// 交付
export const Enterlibrary_TaskSubmit = (param) => Axios('POST', '/bms/batch/storeDeliver', param);

// 查看进度
export const Enterlibrary_storeProgress = (param) => Axios('POST', '/bms/batch/storeProgress', param);

// 发起标注
export const Enterlibrary_goRemark = (param) => Axios('POST', '/bms/batch/launchTag', param);


/***  入库任务 */

// 入库任务 待服务 列表
export const EnterlibraryTask_waitingList = (param) => Axios('POST', '/bms/task/unStoreList', param);

// 入库任务 服务中 列表
export const EnterlibraryTask_servingList = (param) => Axios('POST', '/bms/task/storingList', param);

// 入库任务 已完成 列表
export const EnterlibraryTask_servedList = (param) => Axios('POST', '/bms/task/storeDoneList', param);

// 入库任务 领取任务
export const EnterlibraryTask_getTask = (param) => Axios('POST', '/bms/task/storeClaim', param);

// 入库任务 交付任务
export const EnterlibraryTask_pushTask = (param) => Axios('POST', '/bms/task/storeDeliver', param);

// 入库任务 上传音乐
export const EnterlibraryTask_importMusic = (param) => Axios('POST', '/bms/music/import', param);