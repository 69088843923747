<template>
  <ng-table
    class="ng-table"
    :columns="columns"
    :params="params"
    :rowKey="row => row.task_id"
    :request="$axios.EnterlibraryTask_servedList"
    :load.sync="loadTable"
    :reload.sync="reloadTable"
    :scroll="{y: scrollyHeight - 20}"
  >
    <!-- 头像 -->
    <template slot="lib_logo_url" slot-scope="text, scope">
      <ng-image
        class="table-header"
        :src="scope.lib_logo_url ? `${scope.lib_logo_url}?x-oss-process=style/thumb_10` : $var.defaultHeader"
        @error="({target}) => target.src = $var.defaultHeader"
      />
    </template>

    <template slot="number" slot-scope="text">
      <span>{{`批次${text}`}}</span>
    </template>

    <template slot="operation" slot-scope="text, scope">
      <router-link
        :to="`/libraryenter/musicmanage/${scope.task_id}/${scope.music_type}/${$var.Common.second}`"
      >查看</router-link>
    </template>
  </ng-table>
</template>

<script>
  import {tableMixins} from '@/mixins';

  import * as TabCol from '@/config/columns';

  const columns = [
    TabCol.colId,
    TabCol.portrait('封面', 'lib_logo_url'),
    TabCol.commonKey('曲库名称', 'lib_name', {ellipsis: true}),
    TabCol.commonKey('曲库简称', 'lib_short_name'),
    TabCol.commonKey('所属供应商', 'suplr_name', {ellipsis: true}),

    TabCol.commonKey('音乐数量', 'music_num'),
    TabCol.commonKey('服务周期', 'serve_cycle', false, true),

    TabCol.operation('100px')
  ]

  export default {
    data() {
      return {
        columns,
        tableList: [],
        // params: {},
      }
    },

    props: {
      authName: String,
      scrollyHeight: {
        type: Number,
        default: 400
      },
      params:Object,
    },

    mounted() {
      // this.params = {page: this.$route.query.page || 1};
    },

    mixins: [
      tableMixins
    ],

    methods: {
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .ant-table td {
    white-space: nowrap;
  }
</style>
