<template>
    <main-structure class="ng-library" dir="ver">
        <template slot="header">
            <span class="title">主题歌单</span>
            <div class="header-content header-search-content">
                <a-input-search class="ng-input-search" placeholder="请输入歌单名称" :default-value="$route.query.name || ''"
                    @search="onSearch" allowClear v-model="keyword" >
                    <a-button slot="enterButton" type="sub" @click="onSearch">
                        <a-icon type="search" />
                    </a-button>
                </a-input-search>

                <a-button v-if="$root.$checkAuth($auth[name] && $auth[name].add)" type="sub"
                    @click="handleFunc($var.Handle.add)">添加歌单</a-button>
            </div>
        </template>
        <a-form class="ng-form-style ng-filter" :form="form"  layout="inline" >
            <a-radio-group class="f-l" v-model="value" button-style="solid" @change="onChange">

                <a-radio-button v-for="(item, i) in labels" :value = item.label_id :key=item.label_id style="margin-top: 4px">{{item.name}}</a-radio-button>
            </a-radio-group>
            <a-form-item label="排序方式：" style="margin-left: 20px">
                <a-select
                :options="SortOptions"
                :style="{width: '120px'}"
                v-decorator="['sort']"
                @change="onChangeSort"
                ></a-select>
            </a-form-item>
        </a-form>
            <div class="flex-1 table-box" ref="ngTableBox">
                <p></p>
                <playlist-table v-if="$root.$checkAuth($auth[name] && $auth[name].list)"
                    :scrolly-height="mainContentScrollMaxHeight" :auth-name="name" :params="tableParams"
                    @operate="onOperate" ref="ngTable"></playlist-table>
            </div>
        <song-list-add
            :visible.sync="showHandleDialog"
            :handle="handleType"
            :default-value="handleData"
            @success="handleSuccess"
        ></song-list-add>
    </main-structure>
</template>
<script>
    import PlaylistTable from './PlaylistTable.vue';
    import SongListAdd from '../songList/components/SongListAdd.vue';
    import { mainContentMixin } from '@/mixins';
    import {AddDefaultToOptions,SortOptions} from '@/config/options'
    export default {
        data() {
            return {
                form: this.$form.createForm(this, "nodify"),
                name: `home_manage`,
                value: '76008c9417a1400c00',
                showHandleDialog: false,
                handleType: this.$var.Handle.add,
                tableParams:{},
                keyword : "",
                handleData: {},
                labels:[],
                SortOptions: AddDefaultToOptions(SortOptions),
                sort:"DESC",
            }
        },
        created() {
            let params= {type:2}
            this.$axios.GetCommonLabels(params).then(res => {
                const data = res.data;
                if (data && data.code == 0) {
                    this.labels = data.data ? data.data.list[0].child.slice(0,6) : [];
                    this.value = this.labels[0].label_id;
                    this.tableParams.label_id = this.value;
                    // this.$refs.ngTable.loadTableData();
                }

            })
        },
        mounted() {
            let {query} = this.$route;
            this.tableParams = Object.assign({}, this.tableParams, {
                page: query.page || 1
            });
            this.tableParams = Object.assign({}, this.tableParams, {label_id:this.value,sort:this.sort});
            this.$nextTick(() => {
                this.form.setFieldsValue({ sort : this.sort })
            })
        },
        components: {
            PlaylistTable,
            SongListAdd
        },
        mixins: [
            mainContentMixin
        ],
        methods: {
            onChange() {
                this.tableParams = Object.assign({}, this.tableParams, {label_id:this.value});
                this.$refs.ngTable.loadTableData();
            },
            handleFunc() {
                this.handleType = this.$var.Handle.add;
                this.handleData = {};
                this.showHandleDialog = true;
            },
            onSearch() {
                this.tableParams = Object.assign({}, this.tableParams, {title:this.keyword});
                this.$refs.ngTable.loadTableData();
            },
            onChangeSort(value){
                // console.log(value)
                this.sort = value;
                // console.log(this.form.getFieldsValue());
                this.tableParams = Object.assign({}, this.tableParams, {sort:this.sort});
                this.$refs.ngTable.loadTableData();
            },
            onOperate(scope) {
                if (scope.type == 1){
                    this.handleType = this.$var.Handle.edit;
                    this.handleData = scope;
                    this.showHandleDialog = true;
                    
                }else if(scope.type == 2){
                    this.$axios.TopicDel({playlist_id : scope.playlist_id}).then(res => {
                        const data = res.data;
            
                        if (data && data.code == 0) {
                            this.$message.success("删除成功");
                            this.$refs.ngTable.loadTableData();
                        }
                    });
                }else if(scope.type == 3){
                    this.$axios.Song_status({playlist_id : scope.playlist_id,sale_status:scope.sale_status}).then(res => {
                        const data = res.data;
                        console.log(data)
                        if (data && data.code == 0) {
                            this.$message.success("更新成功");
                            this.$refs.ngTable.loadTableData();
                        }
                    });
                }else if(scope.type == 4){
                    this.$axios.PlayListSaveSort({playlist_id : scope.playlist_id,sort:scope.sort}).then(res => {
                        const data = res.data;
            
                        if (data && data.code == 0) {
                            this.$message.success("更新成功");
                            this.$refs.ngTable.loadTableData();
                        }
                    });
                }
            },
            handleSuccess(isLoad) {
                this.showHandleDialog = false;
                this.showCoopHandleDialog = false;
                if (isLoad){
                    this.$refs.ngTable.reloadTableData();
                }else{
                    this.handleType = this.$var.Handle.add;
                    this.handleData = {};
                }
                // isLoad !== false ? this.$refs.ngTable.reloadTableData() : this.$refs.ngTable.loadTableData();
            },
        }
    }
</script>
<style lang="scss" scoped>
</style>