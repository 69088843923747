<template>
  <main-structure class="ng-library" dir="ver" :loading="$store.state.contentLoading">
    <template slot="header">
      <span class="title">搜索记录</span>

      <div class="header-content header-search-content">
        <a-input-search
          class="ng-input-search"
          placeholder="请输入搜索内容或用户名称或用户id"
          :default-value="$route.query.keyword || ''"
          @search="onSearch"
          allowClear
        >
          <a-button slot="enterButton" type="sub">
            <a-icon type="search" />
          </a-button>
        </a-input-search>
      </div>
    </template>
    
    <template>
      <filter-table @change="onFilterChange" :defaultData="$route.query">
        <template v-slot:extra>
          <a-button type="sub" style="margin-top: 4px;float:right;" v-if="$root.$checkAuth($auth[name] && $auth[name].download)"
              @click="onDownload()" icon="download"> 下载搜索数据
          </a-button>
        </template>
      </filter-table>

      <div class="m-top-base flex-1" ref="ngTableBox">
        <manage-table
          v-if="$root.$checkAuth($auth[name] && $auth[name].list)"
          :scrolly-height="mainContentScrollMaxHeight"
          :auth-name="name"
          :table-params="tableParams"
          @detail="onLookDetail"
          ref="ngTable"
        ></manage-table>
      </div>
    </template>

  </main-structure>
</template>

<script>
  import ManageTable from './components/ManageTable.vue';
  import FilterTable from './components/FilterTable.vue'

  import {mainContentMixin, filterReq} from '@/mixins';
  import * as $config from '~/config/config.c';
  import $storage from '~/utils/storage'
  import * as $var from '~/utils/variables.c'
  let baseURL = $config.ServeBaseHost;

  export default {
    data() {
      return {
        name: `search_manage`,
        isReloadTable: false,
        tableParams: {},
        showDialogInfo: false,
        currentData: {}
      }
    },

    mounted() {
      let {time = [], page = 1, keyword} = this.$route.query;

      let params = {time, page, keyword};

      params = this.parseArrParams({...params});

      this.tableParams = params;
    },

    components: {
      ManageTable, 
      FilterTable,
    },

    mixins: [
      mainContentMixin, filterReq
    ],

    methods: {
      onDownload() {
        let token = encodeURIComponent($storage.get($var.STORAGE.TOKEN));
        let url = `${baseURL}/bms/search/downSearch?token=${token}`
        window.open(url)
      },
      onFilterChange(values) {
        let params = Object.assign({}, this.tableParams, values);

        params = this.parseArrParams(params);

        this.tableParams = this.filterCommon(params);
      },

      onSearch(keyword) {
        typeof keyword === 'string' && (keyword = keyword.trim());

        let search = {keyword, page: 1};

        this.$root.$goSearch(search);

        this.tableParams = search;
      },

      onLookDetail(option) {
        this.showDialogInfo = true;
        this.currentData = option;
      },

      onSuccess() {
        let ngTable = this.$refs.ngTable;

        ngTable && typeof ngTable.loadTableData === 'function' && ngTable.loadTableData();
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
